import * as types from "../constants";

const initialState = {
  isOpen: false,
  title: "",
  hint: null,
  onConfirm: null,
  onCancel: null,
  nomeSenhaValidar: null,
  modalSize: "sm",
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SHOW_SENHA_MODAL:
      return {
        isOpen: true,
        title: actions.title,
        hint: actions.hint,
        onConfirm: actions.onConfirm,
        onCancel: actions.onCancel,
        nomeSenhaValidar: actions.nomeSenhaValidar,
        modalSize: actions.modalSize || "sm",
      };
    case types.CLOSE_SENHA_MODAL:
      return initialState;
    default:
      return state;
  }
}
