import React, { useRef, useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FormButton,
  ModalBase,
  Table,
  TableDelete,
} from "../../../../../components";
import { apiDeleteV2, apiGetV2, apiPostV2 } from "../../../../../apiV2";

const moedas = [
  { label: "Boleto", value: "BOLE" },
  //   { label: "Cartão", value: "CART" },
  { label: "Cheque", value: "CHEQ" },
  { label: "Dinheiro", value: "DINH" },
  { label: "PIX", value: "PIX" },
  { label: "Rotativo", value: "ROTA" },
];

const URL_BASE = "/cadastro/formas_recebimento_padrao";

export const FormasRecPadraoModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dados, setDados] = useState([]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const limparDados = () => {
    setDados([]);
  };

  const carregarDados = async () => {
    const [ok, ret] = await apiGetV2(`${URL_BASE}/listar/`);
    setDados(ok ? ret : []);
  };

  const handleDelete = async (id) => {
    const [ok] = await apiDeleteV2(`${URL_BASE}/excluir/${id}/`);
    if (ok) {
      carregarDados();
    }
  };

  const handleAdd = async (dados) => {
    const payload = {
      moeda: dados.moeda,
      id_cond_pag: dados.id_cond_pag,
    };

    const [ok] = await apiPostV2(`${URL_BASE}/incluir/`, payload);
    if (ok) {
      carregarDados();
    }
    return ok;
  };

  const columns = [
    {
      dataField: "desc_moeda",
      text: "Moeda",
      align: "center",
      sortable: true,
    },
    {
      dataField: "nome_cond_pag",
      text: "Condição de Pagamento",
      align: "left",
      sortable: true,
    },
    {
      dataField: "parcelas",
      text: "Parcelas",
      align: "center",
      sortable: true,
    },
    {
      dataField: "del",
      isDummyField: true,
      text: "Ações",
      align: "",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.id)} />
      ),
    },
  ];

  return (
    <>
      <FormButton md="auto" onClick={toggle}>
        Formas de Recebimento Padrão
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Formas de Recebimento Padrão"
        number="0022_2"
        onBeforeOpen={carregarDados}
        onClosed={limparDados}
      >
        <IncluirFormaRecForm handleSubmit={handleAdd} />
        <Table columns={columns} data={dados} paginated={false} pageSize={10} />
      </ModalBase>
    </>
  );
};

export const IncluirFormaRecForm = ({ handleSubmit }) => {
  const [moeda, setMoeda] = useState("DINH");
  const [idCondPag, setIdCondPag] = useState(null);
  const condPagRef = useRef();
  const [loadingAdd, setLoadingAdd] = useState(false);

  const handleSelMoeda = (selected) => {
    setMoeda(selected.value);
    if (selected.value === "DINH") setIdCondPag(null);
  };

  const handleSubmitInternal = async () => {
    const payload = {
      moeda: moeda,
      id_cond_pag: idCondPag,
    };

    setLoadingAdd(true);
    const ok = await handleSubmit(payload);
    if (ok) {
      setIdCondPag(null);
      if (condPagRef.current) {
        condPagRef.current.clearValue();
      }
    }

    setLoadingAdd(false);
  };

  return (
    <>
      <Row>
        <ComboBox
          md={3}
          label="Moeda"
          options={moedas}
          onChange={handleSelMoeda}
          autoFocus
          defaultValue={moeda}
        />
        {!["DINH", "PIX", "CHEQ"].includes(moeda) && (
          <AsyncComboBox
            isClearable
            isConcatField
            concatModelName="cond_pag"
            defaultOptions
            md={6}
            label="Condição de pagamento"
            onChange={(s) => setIdCondPag(s?.value)}
            ref={condPagRef}
          />
        )}
        <FormButton
          color="info"
          onClick={handleSubmitInternal}
          loading={loadingAdd}
          disabled={
            idCondPag == null && !["DINH", "PIX", "CHEQ"].includes(moeda)
          }
          disabledHint="Informe a condição de pagamento no campo à esquerda"
        >
          Adicionar
        </FormButton>
      </Row>
    </>
  );
};
