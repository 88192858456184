import moment from "moment";
import unidecode from "unidecode";
import {
  layout240BancoDoBrasil,
  nomeArq240BancoDoBrasil,
} from "./layouts/240BancoDoBrasil";
import { layout240Banrisul, nomeArq240Banrisul } from "./layouts/240Banrisul";
import { layout240Cresol, nomeArq240Cresol } from "./layouts/240Cresol";
import {
  layout240Santander,
  nomeArq240Santander,
} from "./layouts/240Santander";
import { layout240Sicoob, nomeArq240Sicoob } from "./layouts/240Sicoob";
import { layoutBradesco, nomeArqBradesco } from "./layouts/400Bradesco";
import { layoutCaixa, nomeArqCaixa } from "./layouts/400Caixa";
import { layoutItau, nomeArqItau } from "./layouts/400Itau";
import { layoutSicredi, nomeArqSicredi } from "./layouts/400Sicredi";
import { layoutGrafeno, nomeArqGrafeno } from "./layouts/400Grafeno";

// =========== FUNÇÕES DE FORMATAÇÃO E PREENCHIMENTO DE CAMPOS ===========

const formatField = (data = "", start, end) =>
  (typeof data === "string"
    ? unidecode(data).padEnd(end - start + 1, " ")
    : (!isNaN(data) ? data : 0).toString().padStart(end - start + 1, "0")
  ).substring(0, end - start + 1);

export const formatRow = (...fields) =>
  "".concat(...fields.map((f) => formatField(f[0], f[1], f[2]))) + "\r\n";

export const formatNumberField = (v) => parseInt(v?.replace(/\D/g, ""));

export const formatDateDDMMAA = (v) => moment(v).format("DDMMYY");

export const formatDateDDMMAAAA = (v) => moment(v).format("DDMMYYYY");

export const verificaPessoa = (v) =>
  v.replace(/\D/g, "").length === 14 ? "2" : "1";

export const dataHojeYYYYMMDD = moment(new Date()).format("YYYYMMDD");

export const removeSpecialChars = (v) => v.replace(/[&$%+\-/:();.,]/g, "");

// ===================== IMPRESSÃO PROPRIAMENTE DITA =====================

export const gerarArquivoRemessa = (dadosConta, duplicatas) => {
  if (!dadosConta || !duplicatas) return;
  let content = "";
  let fileName = "REMESSA.TXT";

  switch (dadosConta.layout) {
    case "SIC":
      content = layoutSicredi(dadosConta, duplicatas);
      fileName = nomeArqSicredi(dadosConta);
      break;
    case "BRA":
      content = layoutBradesco(dadosConta, duplicatas);
      fileName = nomeArqBradesco(dadosConta);
      break;
    case "CAI":
      content = layoutCaixa(dadosConta, duplicatas);
      fileName = nomeArqCaixa(dadosConta);
      break;
    case "SIB":
      content = layout240Sicoob(dadosConta, duplicatas);
      fileName = nomeArq240Sicoob(dadosConta);
      break;
    case "BDB":
      content = layout240BancoDoBrasil(dadosConta, duplicatas);
      fileName = nomeArq240BancoDoBrasil(dadosConta);
      break;
    case "BAN":
      content = layout240Banrisul(dadosConta, duplicatas);
      fileName = nomeArq240Banrisul(dadosConta);
      break;
    case "SAN":
      content = layout240Santander(dadosConta, duplicatas);
      fileName = nomeArq240Santander(dadosConta);
      break;
    case "ITA":
      content = layoutItau(dadosConta, duplicatas);
      fileName = nomeArqItau(dadosConta);
      break;
    case "CRE":
      content = layout240Cresol(dadosConta, duplicatas);
      fileName = nomeArq240Cresol(dadosConta);
      break;
    case "GRA":
      content = layoutGrafeno(dadosConta, duplicatas);
      fileName = nomeArqGrafeno(dadosConta);
      break;
    default:
      break;
  }

  return [new Blob([content], { type: "text/plain" }), fileName];
};
