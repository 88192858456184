import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Card, Fade, Row } from "reactstrap";
import { AsyncComboBox, Table } from "../../../../components";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { CardTotais, CardTotaisItem } from "../../../../components/CardTotais";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import { PageContainer } from "../../../../components/PageContainer";
import { formatarValor } from "../../../../coreUtils";
import ComissaoGerenciaService from "../../../../services/relatorios/comissionamento/ComissaoGerenciaService";
import TableSegundoNivel from "./components/TableSegundoNivel";

const columnsPrimeiroNivel = [
  {
    dataField: "desc",
    text: "Descrição",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "qtd_vendas",
    text: "Quantidade de Vendas",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatarValor(c, 0, 0, true),
  },
  {
    dataField: "vlr_total_prod",
    text: "Total Vendido",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatarValor(c, 2, 2, true),
  },
  {
    dataField: "vlr_tot_comissao",
    text: "Comissão (R$)",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatarValor(c, 2, 2, true),
  },
];

export const ComissaoGerencia = () => {
  const [supervisor, setSupervisor] = useState(null);
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dados, setDados] = useState({});

  const handleDate = (dataIni, dataFim) => {
    setDataIni(dataIni);
    setDataFim(dataFim);
  };

  const buscarDados = async () => {
    if (!supervisor) {
      toastr.warning(
        "Supervisor não informado.",
        "Por favor, selecione o supervisor que terá a comissão detalhada."
      );
      return;
    }

    setIsLoading(true);
    try {
      const [ok, ret] = await ComissaoGerenciaService.buscarComissao(
        supervisor,
        {
          data_ini: dataIni,
          data_fim: dataFim,
        }
      );
      if (ok) {
        setDados(ret);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageContainer title="Gerência" number="5012" canGoBack>
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <AsyncComboBox
            md={3}
            label="Supervisor"
            concatModelName="gerenc_supervisor_cab"
            defaultOptions
            onChange={(s) => setSupervisor(s?.value)}
          />
          <BotaoPesquisar onClick={buscarDados} loading={isLoading} />
        </Row>
      </Card>
      {dados?.detalhes?.length > 0 && (
        <Fade in={true}>
          <CardTotais>
            <CardTotaisItem
              label="Quantidade de vendas"
              value={dados.qtd_vendas}
              checkFloat
            />
            <CardTotaisItem label="Total Vendido" value={dados.vlr_tot_venda} />
            <CardTotaisItem
              label="Total Comissão"
              value={dados.vlr_tot_comissao}
            />
          </CardTotais>
          <Card body>
            <Table
              data={dados.detalhes ?? []}
              columns={columnsPrimeiroNivel}
              keyField="desc"
              paginated={false}
              growIntoPageSize
              canExpand
              expandedAreaBuilder={(row) => (
                <TableSegundoNivel
                  dados={
                    row.tipo === "pronta_entrega" ? row.rotas : row.vendedores
                  }
                  tipo={row.tipo}
                />
              )}
            />
          </Card>
        </Fade>
      )}
    </PageContainer>
  );
};
