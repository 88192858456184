import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/venda/frente_venda";
const URL_BASE_VENDAS_SALVAS = `${URL_BASE}/vendas_salvas`;

const FrenteVendaService = {
  alterarItem: (payload) =>
    apiPutV2(`${URL_BASE}/alterar_item/`, payload, {
      successMessage: false,
    }),
  buscarItens: (idVenda) => apiGetV2(`${URL_BASE}/buscar_itens/${idVenda}/`),
  buscarLocalEntrega: (idVenda) =>
    apiGetV2(`${URL_BASE}/buscar_local_entrega/${idVenda}/`),
  buscarPrecoListaPreco: (idProduto, idCliente) =>
    apiGetV2(
      `/cadastro/produto/buscar_preco_lista_preco/${idProduto}/${idCliente}/`
    ),
  excluirItem: (tipo, idItem) =>
    apiDeleteV2(`${URL_BASE}/excluir_item/${tipo}/${idItem}/`),
  salvarCab: (payload, successMessage) =>
    apiPutV2(`${URL_BASE}/salvar_cab/`, payload, {
      successMessage: successMessage,
    }),
  incluirCab: (payload) =>
    apiPostV2(`${URL_BASE}/incluir_cab/`, payload, {
      successMessage: false,
    }),
  incluirItem: (payload, successMessage) =>
    apiPostV2(`${URL_BASE}/incluir_item/`, payload, {
      successMessage: successMessage,
    }),
  finalizar: (payload) => apiPutV2(`${URL_BASE}/finalizar/`, payload),
  listarOrcamentos: (params) =>
    apiGetV2(`${URL_BASE}/listar_orcamentos/`, params),
  verificarDadosCliente: (idCliente) =>
    apiGetV2(`${URL_BASE}/verificar_dados_cliente/${idCliente}/`),

  vendasSalvas: {
    listar: (params) => apiGetV2(`${URL_BASE_VENDAS_SALVAS}/listar/`, params),
    buscar: (idVendaSalva) =>
      apiGetV2(`${URL_BASE_VENDAS_SALVAS}/buscar/${idVendaSalva}/`),
  },
};

export default FrenteVendaService;
