import React from "react";
import { useState } from "react";
import { modalTitleNaturezaOperNfse } from "../NaturezaOperNfse";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { Row } from "reactstrap";
import { ComboBox, NumberInput, TextInput } from "../../../../../components";
import { showWarning } from "../../../../../components/AlertaModal";
import NotaFiscalServicoService from "../../../../../services/docs_eletron/NotaFiscalServicoService";

const sitTribProvedorOptions = [
  { label: "0 - Tributado Integralmente", value: "0" },
  {
    label: "1 - Tributado com Retenção na Fonte - ISS + IR - TIRF IPM",
    value: "1",
  },
  { label: "2 - Tributado com Retenção", value: "2" },
];

export const CadastroNaturezaOperNfseModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [descricao, setDescricao] = useState("");
  const [sitTribProvedor, setSitTribProvedor] = useState(
    sitTribProvedorOptions[0].value
  );
  const [aliqIss, setAliqIss] = useState(0);
  const [aliqInss, setAliqInss] = useState(0);
  const [aliqRetenIrpj, setAliqRetenIrpj] = useState(0);

  const limparDados = () => {
    setDescricao("");
    setSitTribProvedor(sitTribProvedorOptions[0].value);
    setAliqIss(0);
    setAliqInss(0);
    setAliqRetenIrpj(0);
  };

  const fetchData = (data) => {
    setDescricao(data.descricao);
    setSitTribProvedor(data.sit_trib_provedor ?? "");
    setAliqIss(parseFloat(data.aliq_iss));
    setAliqInss(parseFloat(data.aliq_inss));
    setAliqRetenIrpj(parseFloat(data.aliq_reten_irpj));
  };

  const submitPayload = (action) => {
    if (["", null, undefined].includes(descricao)) {
      showWarning(
        "Por favor, informe a Descrição",
        null,
        null,
        "input-desc-natureza"
      );
      return;
    }

    const payload = {
      descricao: descricao,
      sit_trib_provedor: sitTribProvedor,
      aliq_iss: aliqIss,
      aliq_inss: aliqInss,
      aliq_reten_irpj: aliqRetenIrpj,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleNaturezaOperNfse}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={NotaFiscalServicoService.naturezaOperacao.urlBase}
      number="0127_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          md={12}
          label="Descricao"
          value={descricao}
          onChange={(e, v) => setDescricao(v)}
          maxLength={60}
          name={"input-desc-natureza"}
        />
      </Row>
      <Row>
        <ComboBox
          md={7}
          label="CST no Provedor"
          options={sitTribProvedorOptions}
          defaultValue={sitTribProvedor}
          onChange={(s) => setSitTribProvedor(s?.value)}
        />
      </Row>
      <Row>
        <NumberInput
          md={4}
          label="Alíq. ISS"
          value={aliqIss}
          onChange={setAliqIss}
          decimalPlaces={4}
          isPercentage
        />
        <NumberInput
          md={4}
          label="Alíq. INSS"
          value={aliqInss}
          onChange={setAliqInss}
          isPercentage
        />
        <NumberInput
          md={4}
          label="Alíq. Ret. IRPJ"
          value={aliqRetenIrpj}
          onChange={setAliqRetenIrpj}
          isPercentage
        />
      </Row>
    </ModalCadastroV2>
  );
};
