import React from "react";
import { MdOutlineHomeRepairService } from "react-icons/md";
import { BsBoxSeam } from "react-icons/bs";
import { UncontrolledTooltip } from "reactstrap";
import { formatarValor } from "../../../../../coreUtils";
import { Table } from "../../../../../components";

export const ItensOsOrcamentoGrid = ({ itens }) => {
  const columns = [
    {
      dataField: "tipo",
      text: "T",
      align: "left",
      headerAlign: "left",
      editable: false,
      colWidth: "30px",
      formatter: (c, row) => {
        const iconId = `icon-tipo-${row.id}-${row.id_item}`;
        if (c === "Serviço") {
          return (
            <>
              <MdOutlineHomeRepairService id={iconId} size={16} />
              <UncontrolledTooltip target={iconId}>{c}</UncontrolledTooltip>
            </>
          );
        } else {
          return (
            <>
              <BsBoxSeam id={iconId} size={14} />
              <UncontrolledTooltip target={iconId}>{c}</UncontrolledTooltip>
            </>
          );
        }
      },
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
      headerAlign: "center",
      colWidth: "75px",
    },
    {
      dataField: "id_item",
      text: "#",
      align: "center",
      headerAlign: "center",
      colWidth: "60px",
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      colWidth: "350px",
    },
    {
      dataField: "vlr_custo",
      text: "Custo",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2),
      colWidth: "60px",
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
      headerAlign: "center",
      colWidth: "30px",
    },
    {
      dataField: "vlr_unit",
      text: "V Unit.",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2),
      colWidth: "60px",
    },
    {
      dataField: "observ",
      text: "Observação",
      align: "left",
      headerAlign: "left",
      colWidth: "280px",
    },
    {
      dataField: "quantidade",
      text: "Qtde",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatarValor(c, 4, 0),
      colWidth: "75px",
    },
    {
      dataField: "vlr_item",
      text: "Valor Item",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 4),
      colWidth: "75px",
    },
    {
      dataField: "perc_desc",
      text: "% Desc.",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatarValor(c, 2),
      colWidth: "75px",
    },
    {
      dataField: "vlr_total",
      text: "Valor Total",
      align: "right",
      headerAlign: "right",
      formatter: (c, row) => formatarValor(c, 2),
      colWidth: "75px",
    },
  ];

  return (
    <Table
      columns={columns}
      data={itens}
      paginated={false}
      pageSize={7}
      growIntoPageSize
    />
  );
};
