import React from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  IntegerFormInput,
  TextInput,
} from "../../../../../components";
import { RadioGroup, RadioItem } from "../../../../../components/RadioGroup";
import { meses } from "../../../../../coreUtils";

export const CadastroMetaForm = ({
  nome,
  setNome,
  metaPor,
  setMetaPor,
  idMetaPor,
  setIdMetaPor,
  mes,
  setMes,
  ano,
  setAno,
}) => {
  return (
    <>
      <Row className="mb-2">
        <TextInput
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={100}
          md={8}
          name={'input-nome-meta'}
        />
      </Row>
      <Row className="mb-2">
        <RadioGroup
          label="Meta Por"
          value={metaPor}
          onChange={setMetaPor}
          divClassName="pr-0"
        >
          <RadioItem label="Rota" value={"ROTA"} />
        </RadioGroup>
        <AsyncComboBox
          className="pl-0"
          isConcatField
          concatModelName="rota_mob"
          isSearchable
          md={4}
          label=" "
          onChange={(s) => setIdMetaPor(s?.value)}
          defaultValue={idMetaPor}
          defaultOptions
          inputName={'async-rota-meta'}
        />
        <ComboBox
          md={2}
          label="Vigência - Mês"
          options={meses}
          defaultValue={mes}
          onChange={(s) => setMes(s?.value)}
          isClearable={false}
          isSearchable={false}
        />
        <IntegerFormInput
          label="Ano"
          defaultValue={ano}
          onChange={(v) => setAno(v)}
          md={1}
        />
      </Row>
    </>
  );
};
