import React, { useRef, useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormButton,
  NumberInput,
  Table,
  ModalBase,
  TableDelete,
} from "../../../../../components";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import RegrasComissaoService from "../../../../../services/cadastro/RegrasComissaoService";

export const DetalharRegraComissaoCompostaModal = ({
  isOpen,
  toggle,
  selected,
}) => {
  const [nome, setNome] = useState("");
  const [regraPara1, setRegraPara1] = useState("");
  const [regraPara2, setRegraPara2] = useState("");
  const [itemAtual1, setItemAtual1] = useState(null);
  const [itemAtual2, setItemAtual2] = useState(null);
  const [itens, setItens] = useState([]);
  const [percComissao, setPercComissao] = useState(0);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const select1Ref = useRef();
  const select2Ref = useRef();

  const limparDados = () => {
    setNome("");
    setRegraPara1("");
    setRegraPara2("");
    setItemAtual1(null);
    setItemAtual2(null);
    setItens([]);
    setPercComissao(0);
  };

  const onOpen = async () => {
    await buscarDados();
    await carregarItens();
  };

  const carregarItens = async () => {
    const [ok, ret] = await RegrasComissaoService.movComposta.listar(selected);
    setItens(ok ? ret : []);
  };

  const buscarDados = async () => {
    const [ok, ret] = await RegrasComissaoService.buscar(selected);
    if (!ok) return;
    setNome(ret.nome);
    switch (ret.tipo) {
      case "GRUVE":
        setRegraPara1("grupo");
        setRegraPara2("colaborador");
        break;
      default:
        break;
    }
  };

  const handleAdd = async () => {
    const payload = {
      id_cab: selected,
      id_mov1: itemAtual1.value,
      id_mov2: itemAtual2.value,
      perc_comissao: percComissao,
    };

    setLoadingAdd(true);
    const [ok] = await RegrasComissaoService.movComposta.incluir(payload);

    if (ok) {
      setPercComissao(0);
      await carregarItens();
      if (select1Ref.current) {
        select1Ref.current.clearValue();
        select1Ref.current.setFocus();
      }
      if (select2Ref.current) {
        select2Ref.current.clearValue();
      }
    }
    setLoadingAdd(false);
  };

  const handleDelete = async (idItemRegra) => {
    const [ok] = await RegrasComissaoService.movComposta.excluir(idItemRegra);
    if (ok) {
      carregarItens();
    }
  };

  const columns = [
    {
      dataField: "id_mov1",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_mov1",
      text: regraPara1 === "grupo" ? "Grupo" : "",
      align: "left",
      sortable: true,
    },
    {
      dataField: "id_mov2",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_mov2",
      text: regraPara2 === "colaborador" ? "Vendedor" : "",
      align: "left",
      sortable: true,
    },
    {
      dataField: "perc_comissao",
      text: "Perc. Comissão",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2) + " %",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "delete_action",
      isDummyField: true,
      text: "Ações",
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.id)} />
      ),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title={`${nome}: Detalhes`}
      number="0038_3"
      onBeforeOpen={onOpen}
      onClosed={limparDados}
      hideCancelButton
    >
      <Row className="mb-2">
        {regraPara1 !== "" && (
          <AsyncComboBox
            label={regraPara1.charAt(0).toUpperCase() + regraPara1.slice(1)}
            isConcatField
            concatModelName={regraPara1}
            defaultOptions
            isSearchable
            md={6}
            onChange={(selected) => setItemAtual1(selected)}
            autoFocus
            ref={select1Ref}
          />
        )}
        {regraPara2 !== "" && (
          <AsyncComboBox
            label={regraPara2.charAt(0).toUpperCase() + regraPara2.slice(1)}
            isConcatField
            concatModelName={regraPara2}
            defaultOptions
            isSearchable
            md={6}
            onChange={(selected) => setItemAtual2(selected)}
            autoFocus
            ref={select2Ref}
          />
        )}
      </Row>
      <Row>
        <NumberInput
          label="Perc. Comissão"
          md={2}
          onChange={setPercComissao}
          value={percComissao}
          isPercentage
        />
        <FormButton
          md="auto"
          color="info"
          onClick={handleAdd}
          disabled={
            [0, null, undefined].includes(itemAtual1) ||
            [0, null, undefined].includes(itemAtual2)
          }
          disabledHint="Preencha os dois campos acima"
          loading={loadingAdd}
        >
          Incluir
        </FormButton>
      </Row>
      <Table columns={columns} data={itens} paginated={false} pageSize={10} />
    </ModalBase>
  );
};
