import React from "react";
import {
  ModalBase,
  Table,
  TableCheck,
  TableDelete,
} from "../../../../components";
import { formatarValor } from "../../../../coreUtils";
import { BiUndo } from "react-icons/bi";
import { showConfirmation } from "../../../../components/ConfirmationModal";
import { apiPostV2 } from "../../../../apiV2";
import { showSenha } from "../../../../components/SenhaModalV2";

const DetalhesAdiantamentosModal = ({
  isOpen,
  toggle,
  idOS,
  notifyEvent,
  adiantamentos,
}) => {
  const estornarRecebimento = (idAdiantamento) => {
    showConfirmation(
      "Você tem certeza que deseja estonar o recebimento deste adiantamento?",
      () => solicitarSenhaEstorno(idAdiantamento),
      () => {}
    );
  };

  const solicitarSenhaEstorno = (id) => {
    showSenha({
      title: "Estorno de Ordem de Serviço",
      onConfirm: (senha) => confirmarEstornoRecebimento(id, senha),
      hint: (
        <p>
          Este processo irá reabrir o Adiantamento no Caixa Loja e excluir os
          recebimentos vinculados a ele.
        </p>
      ),
    });
  };

  const confirmarEstornoRecebimento = async (idAdiantEstornar, senha) => {
    const [ok] = await apiPostV2(
      "/ordem_servico/adiantamento/estornar_rec_cxa_loja/",
      {
        id_adiantamento: idAdiantEstornar,
        senha_estorno: senha,
      }
    );
    if (ok) {
      notifyEvent();
    }

    return ok;
  };

  const columns = [
    {
      dataField: "desc_forma_pag",
      text: "Forma de Pagamento",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "desc_cond_pag",
      text: "Condição de Pagamento",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "data_adiantamento",
      text: "Data",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "hora_adiantamento",
      text: "Hora",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "recebido",
      text: "Recebido",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
    {
      dataField: "det",
      dummy: true,
      align: "center",
      formatter: (c, row) => (
        <TableDelete
          disabled={!row.recebido}
          disabledHint="Esse adiantamento ainda não foi recebido no Caixa Loja"
          icon={BiUndo}
          hint="Estornar recebimento adiantamento"
          onClick={() => estornarRecebimento(row.id)}
          tooltipPlacement="left"
        />
      ),
    },
  ];

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title={`Adiantamentos da O.S. Nº ${idOS}`}
        number="0006_8"
      >
        <Table
          columns={columns}
          data={adiantamentos ?? []}
          paginated={false}
          pageSize={6}
          growIntoPageSize
          keyField="id"
        />
      </ModalBase>
    </>
  );
};

export default DetalhesAdiantamentosModal;
