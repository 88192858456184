import React from "react";
import { useState } from "react";
import {
  AsyncComboBox,
  BaseInfoCard,
  BotaoImprimir,
  ComboBox,
  FiltroPeriodoDatas,
  FormButton,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { docPrintReport } from "../../../../pdf";
import { dateRangeAsTitle } from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";
import XLSX from "xlsx";

const FORMAS_REC = {
  DINHEIRO: "DI",
  CARTAO: "CC",
  TRANSF_BANCARIA: "TB",
  CREDIARIO: "CI",
  CRED_CLIENTE: "CL",
  CHEQUE: "CQ",
  CRED_ROTATIVO: "CR",
};

const formasRecOptions = [
  { label: "Dinheiro", value: FORMAS_REC.DINHEIRO },
  { label: "Cartão", value: FORMAS_REC.CARTAO },
  { label: "Transferência Bancária", value: FORMAS_REC.TRANSF_BANCARIA },
  { label: "Crediário", value: FORMAS_REC.CREDIARIO },
  { label: "Crédito Cliente", value: FORMAS_REC.CRED_CLIENTE },
  { label: "Cheque", value: FORMAS_REC.CHEQUE },
  { label: "Crédito Rotativo", value: FORMAS_REC.CRED_ROTATIVO },
];

const tiposCartaoOptions = [
  { label: "Débito", value: "D" },
  { label: "Crédito", value: "C" },
  { label: "Voucher", value: "V" },
  { label: "A Vista Pré Datado", value: "A" },
  { label: "Débito Parcelado", value: "R" },
];

export const VendaOSFormaRecNF = () => {
  const [dataFim, setDataFim] = useState(new Date());
  const [dataIni, setDataIni] = useState(new Date());
  const [idVendedor, setIdVendedor] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [formasRec, setFormasRec] = useState([]);
  const [tiposCartao, setTiposCartao] = useState([]);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_vendedor: idVendedor,
      id_cliente: idCliente,
      forma_rec: formasRec,
      tipo_cartao: tiposCartao,
    };

    return await apiGetV2(
      `/relatorios/venda/vendas_forma_recebimento/`,
      params
    );
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();

    if (ok) {
      const payload = {
        dados: ret,
        data_ini: dataIni,
        data_fim: dataFim,
      };

      await docPrintReport(
        "/relatorios/venda/venda_os_forma_rec_nf/",
        payload,
        "5056"
      );
    }

    setLoadingImprimir(false);
  };

  const exportarRelatorio = async () => {
    setLoadingExportar(true);
    const [ok, ret] = await buscarDados();

    if (ok) {
      const vendasOs = ret.vendas_os;
      const totais = ret.totais;
      const data = [];

      data.push(["Tipo", "Número", "Data", "Cliente", "Nome", "Valor Total"]);

      vendasOs.forEach((row) => {
        const valoresLinha = [
          row["tipo_reg"],
          row["id"],
          row["data_reg"],
          row["id_cliente"],
          row["nome_cliente"],
          parseFloat(row["vlr_total"]),
        ];
        data.push(valoresLinha);

        const dadosNota = row["dados_nota"];

        if (dadosNota.length > 0) {
          data.push(["Tipo NF-e", "Número", "Emissão", "Chave de Acesso"]);
          dadosNota.forEach((nota) => {
            data.push([
              nota["tipo"],
              nota["numero"],
              nota["emissao"],
              nota["chave_acesso"],
            ]);
          });
        }

        const formasRec = row["formas_rec"];

        if (formasRec && formasRec.length > 0) {
          data.push([
            "Forma Rec.",
            "Tipo",
            "Número",
            "Parcelas",
            "Bandeira",
            "Meio Pag",
            "Vencimento",
            "Valor",
          ]);

          formasRec.forEach((item) => {
            data.push([
              item["moeda"],
              item["tipo"],
              item["parcela"]
                ? `${item["numero"]}/${item["parcela"]}`
                : item["numero"],
              item["qtd_parcelas"],
              item["bandeira"],
              item["meio_pag"],
              item["vencimento"],
              parseFloat(item["valor"]),
            ]);
          });
        }

        data.push([]);
      });

      data.push(
        [],
        ["Total de Vendas/OS:", parseFloat(totais.total_geral_vendas_os)],
        [],
        ...totais.formas_rec.reduce((acc, forma_rec) => {
          let res = [[forma_rec.moeda, parseFloat(forma_rec.valor)]];
          if (forma_rec.tipos) {
            res = [
              ...res,
              ...forma_rec.tipos.map((tipo) => [
                `- ${tipo.tipo}`,
                parseFloat(tipo.valor),
              ]),
            ];
          }
          return [...acc, ...res];
        }, []),
        [
          "Total de Formas de Recebimento:",
          parseFloat(totais.total_geral_formas_rec),
        ]
      );

      let ws = XLSX.utils.book_new();

      XLSX.utils.sheet_add_aoa(ws, data);
      const wb = { Sheets: { Vendas: ws }, SheetNames: ["Vendas"] };

      XLSX.writeFile(
        wb,
        `Venda/O.S. com Forma de Recebimento e NF - ${dateRangeAsTitle(
          dataIni,
          dataFim
        )}.xlsx`
      );
    }
    setLoadingExportar(false);
  };

  return (
    <PageContainer
      title="Venda/O.S. com Forma de Recebimento e NF"
      number="5056"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas
            onChange={handleDate}
            disabled={loadingExportar || loadingImprimir}
          />
          <ComboBox
            md={3}
            label="Formas de Recebimento"
            options={formasRecOptions}
            isClearable
            isSearchable
            isMulti
            onChange={(s) => setFormasRec((s ?? [])?.map((e) => e.value))}
            isDisabled={loadingExportar || loadingImprimir}
          />
          {formasRec.includes(FORMAS_REC.CARTAO) && (
            <ComboBox
              md={3}
              label="Tipo de Cartão"
              options={tiposCartaoOptions}
              isClearable
              isSearchable
              isMulti
              onChange={(s) => setTiposCartao((s ?? [])?.map((e) => e.value))}
              isDisabled={loadingExportar || loadingImprimir}
            />
          )}
          <AsyncComboBox
            md={3}
            label="Cliente"
            concatModelName="cliente"
            onChange={(s) => setIdCliente(s?.value)}
            hideShortcut
            disabled={loadingExportar || loadingImprimir}
          />
          <AsyncComboBox
            md={3}
            label="Vendedor"
            concatModelName="colaborador"
            onChange={(s) => setIdVendedor(s?.value)}
            hideShortcut
            disabled={loadingExportar || loadingImprimir}
          />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
          <FormButton
            md="auto"
            color="primary"
            onClick={exportarRelatorio}
            loading={loadingExportar}
          >
            Exportar
          </FormButton>
        </Row>
      </Card>
      <BaseInfoCard
        baseInfo={["Vendas e O.S. realizadas pelo sistema da ADM"]}
      />
    </PageContainer>
  );
};
