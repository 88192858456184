import React, { useState } from "react";
import { ComboBox, FormButton, ModalBase } from "../../../../../components";
import OrdemServicoService from "../../../../../services/ordem_servico/OrdemServicoService";
import { toastr } from "react-redux-toastr";
import PesqCliente from "../../../../../components/form/pesq_cliente/PesqCliente";
import CadastroLigacaoVeicCliService from "../../../../../services/cadastro/CadastroLigacaoVeicCliService";
import { PiUserSwitchLight } from "react-icons/pi";
import { Row } from "reactstrap";

export const TrocarVeicCli = ({
  idOS,
  notifyEvent,
  sistAutomotivo,
  padded,
  showButtonText,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idCliente, setIdCliente] = useState(null);
  const [nomeCliente, setNomeCliente] = useState("");
  const [veiculosCliente, setVeiculosCliente] = useState([]);
  const [idVeiculo, setIdVeiculo] = useState(null);
  const [placa, setPlaca] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setIdCliente(null);
    setNomeCliente("");
    setVeiculosCliente([]);
    setPlaca("");
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idCliente)) {
      toastr.warning("Atenção", "Por favor, selecione o Cliente");
      return false;
    }

    setLoading(true);
    const payload = {
      id_os: idOS,
      id_cliente: idCliente,
      nome_cliente: nomeCliente,
      placa: placa || "",
    };
    const [ok] = await OrdemServicoService.alterarCliente(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSetCliente = async (v) => {
    if (v === idCliente) return;
    setIdCliente(v);
    if (sistAutomotivo) {
      const [ok, ret] =
        await CadastroLigacaoVeicCliService.buscarVeiculosCliente(v);
      const veiculos = (ok ? ret : []).map((veic) => ({
        label: veic.nome,
        value: veic.id,
        placa: veic.placa,
      }));
      setVeiculosCliente(veiculos);
      if (placa === "" && veiculos.length > 0) {
        setIdVeiculo(veiculos[0].value);
      }
    }
  };

  return (
    <>
      <FormButton
        md="auto"
        color="info"
        onClick={toggle}
        disabledHint="Selecione o Cliente"
        padded={padded}
        hint={`Alterar ${sistAutomotivo ? "Veículo/Cliente" : "Cliente"}`}
        divClassName={!showButtonText && "pr-0"}
      >
        <PiUserSwitchLight size={20} className={showButtonText && "mr-2"} />
        {showButtonText &&
          `Alterar ${sistAutomotivo ? "Veículo/Cliente" : "Cliente"}`}
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title={
          sistAutomotivo
            ? "Alterar Veículo e Cliente Somente da Ordem de Serviço"
            : "Alterar Cliente da O.S."
        }
        onConfirm={handleSubmit}
        loadingConfirm={loading}
        onClosed={limparDados}
        number="0006_11"
      >
        <Row>
          <PesqCliente
            md={12}
            onConfirm={({ id, nome }) => {
              setNomeCliente(nome);
              handleSetCliente(id);
            }}
            selectNextField={() => {}}
            onChangeDescricao={setNomeCliente}
            idCliente={idCliente}
            nomeCliente={nomeCliente}
            sistAutomotivo={sistAutomotivo}
            divClassName="pr-0"
          />
        </Row>
        {sistAutomotivo && (
          <Row>
            <ComboBox
              md={12}
              label="Veículo"
              defaultValue={idVeiculo}
              options={veiculosCliente}
              onChange={(s) => {
                setIdVeiculo(s?.value);
                setPlaca(s?.placa);
              }}
              isClearable
              noOptionsMessage={() => "Selecione o cliente"}
            />
          </Row>
        )}
      </ModalBase>
    </>
  );
};
