import React from "react";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
  naturalSortDate,
} from "../../../../../../coreUtils";
import { Table } from "../../../../../../components";

const columns = [
  {
    dataField: "id_pedido_ext_cab",
    text: "Pedido",
    align: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "id_venda_cab",
    text: "Venda",
    align: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "data_pedido",
    text: "Emissão",
    align: "center",
    sortable: true,
    sortFunc: naturalSortDate,
  },
  {
    dataField: "data_venda",
    text: "Faturamento",
    align: "center",
    sortable: true,
    sortFunc: naturalSortDate,
  },
  {
    dataField: "nome_cliente",
    text: "Cliente",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    sortable: true,
  },
  {
    dataField: "nome_vendedor",
    text: "Vendedor",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_vendedor),
    sortable: true,
  },
  {
    dataField: "desc_forma_pag",
    text: "Forma Pag",
    align: "center",
    sortable: true,
  },
  {
    dataField: "valor_pend",
    text: "Valor Pendente",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
];

export const ConferValoresRecMaosGrid = ({
  data,
  selected,
  handleOnSelect,
  handleOnSelectAll,
}) => {
  return (
    <Table
      data={data}
      columns={columns}
      multiselect
      selected={selected}
      onSelect={handleOnSelect}
      onSelectAll={handleOnSelectAll}
      paginated
    />
  );
};
