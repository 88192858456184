import React, { useRef, forwardRef, useImperativeHandle } from "react";
import {
  MODAL_ACTIONS,
  dateFromLocaleString,
  formatDateISO,
  formatNumber,
  roundFloat,
} from "../../../../coreUtils";
import {
  AsyncComboBox,
  DatePicker,
  Divider,
  FixedField,
  FormButton,
  LabelButton,
  Loader,
  PageContainer,
  UnlockToEdit,
} from "../../../../components";
import { useState } from "react";
import { Card, Col, Label, Row, UncontrolledTooltip } from "reactstrap";
import { ItensNFGrid } from "./components/ItensNFGrid";
import moment from "moment";
import { useEffect, useCallback } from "react";
import { notaFiscalEletronicaRoute } from "../../../../routes/modules/docsEletron";
import { Provider } from "react-redux";
import { storeCab } from "./store";
import { useDispatch, useSelector } from "react-redux";
import {
  setDataEmi,
  setIdCliente,
  setNomeCliente,
  setIdTipoDoc,
  setInscricaoEstadualDestinatario,
  setUfCidadeDestinatario,
  setCpfCnpjDestinatario,
  setEnderecoDestinatario,
  setCepDestinatario,
  setCidadeDestinatario,
  setIdNfe,
  init,
  setFinalidadeOperTipoDoc,
  setNumero,
  setSerie,
  setFretePorConta,
  setIdTransportadoraFrete,
  setPlacaVeicFrete,
  setUfVeicFrete,
  setRntrcFrete,
  setQtdeVolumesFrete,
  setEspecieFrete,
  setMarcaFrete,
  setNumeroVolumeFrete,
  setObrigInfoOrdemCompraNfeDestinatario,
  setFone1Destinatario,
  setPesoBrutoFrete,
  setPesoLiquidoFrete,
  setTotaisNfe,
  setFaturas,
  setExigeInfoFatura,
  setTipoNf,
  setOrigem,
  setFormasPag,
  setAVista,
  setNfImportacao,
} from "./store/cabNFSlice";
import DadosFreteNf from "./components/DadosFreteNf";
import { apiGetV2 } from "../../../../apiV2";
import NotaFiscalService from "../../../../services/docs_eletron/NotaFiscalService";
import { showError } from "../../../../components/AlertaModal";
import { useHistory } from "react-router-dom";
import { ChavesReferenciadasNfModal } from "./components/ChavesReferenciadasModal";
import { IncluirAlterarItemNFModal } from "./incluir_alterar_item/IncluirAlterarItemNFModal";
import { InfoAdicNfModal } from "./components/InfoAdicNfModal";
import { FaturasNfForm } from "./components/FaturasNfForm";
import { IncluirItemIcmsNFModal } from "./incluir_item_icms/IncluirItemIcmsNfModal";
import UteisService from "../../../../services/uteis/UteisService";
import { routesBaseNumeracaoNfe } from "../../../cadastro/tributacao/numeracao_nfe/NumeracaoNfe";
import PesqCliente from "../../../../components/form/pesq_cliente/PesqCliente";
import { RiInformationLine } from "react-icons/ri";
import { showConfirmation } from "../../../../components/ConfirmationModal";
import { DadosImportacaoItemNfeModal } from "./components/DadosImportacaoItemNfeModal";
import CadastroClienteService from "../../../../services/cadastro/CadastroClienteService";

const IncluirAlterarNFContainer = forwardRef(
  ({ selected, action = MODAL_ACTIONS.ADD }, ref) => {
    const history = useHistory();
    const stateCab = useSelector((state) => state.cabNF);
    const stateTotaisNfe = useSelector((state) => state.cabNF.totaisNfe);
    const dispatch = useDispatch();

    const [itens, setItens] = useState([]);
    // Variáveis de Controle
    const [loading, setLoading] = useState(true);
    const [loadingFinalizar, setLoadingFinalizar] = useState(false);
    const [loadingIncluirItem, setLoadingIncluirItem] = useState(false);
    const [loadingIncluirItemIcms, setLoadingIncluirItemIcms] = useState(false);
    const [itemOpen, setItemOpen] = useState(false);
    const [itemAction, setItemAction] = useState(MODAL_ACTIONS.EDIT);
    const [dadosImportacaoItemOpen, setDadosImportacaoItemOpen] =
      useState(false);
    const [itemIcmsOpen, setItemIcmsOpen] = useState(false);
    const [idItemDadosImp, setIdItemDadosImp] = useState(null);
    const [idItemAlterar, setIdItemAlterar] = useState(null);
    const [mudouQtdeVolumes, setMudouQtdeVolumes] = useState(false);
    const [atualizarValoresItens, setAtualizarValoresItens] = useState(false);
    const semCliente = [0, null, undefined].includes(stateCab.idCliente);
    const setmTipoDoc = [0, null, undefined].includes(stateCab.idTipoDoc);

    // Parâmetros
    const [identificaCor, setIdentificaCor] = useState(false);
    const [sugerirAutoQtdeVolumes, setSugerirAutoQtdeVolumes] = useState(false);
    const [identFaturaPor, setIdentFaturaPor] = useState("CON");
    const [formaSistemaTribut, setFormaSistemaTribut] = useState("CFOP");
    const [focarDescricaoProd, setFocarDescricaoProd] = useState(false);

    const carregarParametros = async () => {
      const [ok, ret] = await apiGetV2("/tela/inc_alt_prod_nfe/");

      if (ok) {
        setIdentificaCor(ret.identifica_cor);
        setSugerirAutoQtdeVolumes(ret.sugerir_auto_qtde_volumes);
        setIdentFaturaPor(ret.ident_fatura_por);
        setFormaSistemaTribut(ret.forma_sistema_tribut);
        setFocarDescricaoProd(ret.focar_descricao_prod);
      }
      return ok;
    };

    const carregarDados = async (inicial) => {
      const [ok, ret] = await NotaFiscalService.inclusao.buscarCab(selected);
      if (ok) {
        dispatch(setIdNfe(selected));
        dispatch(setIdTipoDoc(ret.id_tipo_doc));
        dispatch(setIdCliente(ret.id_cliente));
        dispatch(setNumero(ret.numero));
        dispatch(setSerie(ret.serie));
        dispatch(setDataEmi(moment(ret.emissao, "DD/MM/YYYY").toDate()));
        dispatch(setOrigem(ret.origem));
        dispatch(setAVista(ret.a_vista));
        dispatch(
          setFaturas(
            ret.faturas.map((e) => ({
              ...e,
              data_vcto: e.vencimento,
              valor: parseFloat(e.valor),
            }))
          )
        );

        dispatch(
          setFormasPag(
            ret.formas_pag.map((e) => ({
              ...e,
              valor: parseFloat(e.valor),
            }))
          )
        );

        const frete = ret.frete;
        dispatch(setFretePorConta(frete.transp_por_conta));
        dispatch(setIdTransportadoraFrete(frete.id_transportadora));
        dispatch(setPlacaVeicFrete(frete.placa));
        dispatch(setUfVeicFrete((frete.uf_veiculo ?? "").trim()));
        dispatch(setRntrcFrete(frete.rntrc));
        dispatch(setQtdeVolumesFrete(frete.quantidade));
        dispatch(setEspecieFrete(frete.especie));
        dispatch(setMarcaFrete(frete.marca));
        dispatch(setNumeroVolumeFrete(frete.numero));
        dispatch(setPesoBrutoFrete(parseFloat(frete.peso_bruto)));
        dispatch(setPesoLiquidoFrete(parseFloat(frete.peso_liquido)));

        await carregarDadosItens(selected, inicial);
      }
      return ok;
    };

    const carregarDadosItens = async (id, inicial) => {
      if (stateCab.idNfe ?? id) {
        const [ok, ret] = await NotaFiscalService.inclusao.buscarItens(
          stateCab.idNfe ?? id
        );
        if (ok) {
          setItens(
            ret.itens.map((e) => ({
              ...e,
              quantidade: parseFloat(e.quantidade),
              vlr_unit: parseFloat(e.vlr_unit),
              vlr_item: parseFloat(e.vlr_item),
              perc_desc: parseFloat(e.perc_desc),
              vlr_desc: parseFloat(e.vlr_desc),
              vlr_acre: parseFloat(e.vlr_acre),
              vlr_total: parseFloat(e.vlr_total),
              vlr_frete: parseFloat(e.vlr_frete),
              vlr_seguro: parseFloat(e.vlr_seguro),
              vlr_outros: parseFloat(e.vlr_outros),
              vlr_tot_prod: parseFloat(e.vlr_tot_prod),
              qtd_tributavel: parseFloat(e.qtd_tributavel),
              nro_ord_compra: e.nro_ord_compra ?? "",
            }))
          );

          const totaisNfe = ret.totais_nfe;
          const statePayload = {
            baseCalcIcms: parseFloat(totaisNfe?.base_calc_icms),
            vlrTotIcms: parseFloat(totaisNfe?.vlr_tot_icms),
            baseCalcIcmsSt: parseFloat(totaisNfe?.base_calc_icms_st),
            vlrTotIcmsSt: parseFloat(totaisNfe?.vlr_tot_icms_st),
            vlrTotIpi: parseFloat(totaisNfe?.vlr_tot_ipi),
            vlrFrete: parseFloat(totaisNfe?.vlr_frete),
            vlrSeguro: parseFloat(totaisNfe?.vlr_seguro),
            vlrOutros: parseFloat(totaisNfe?.vlr_outros),
            vlrTotDesc: parseFloat(totaisNfe?.vlr_tot_desc),
            vlrTotFatura: parseFloat(totaisNfe?.vlr_tot_fatura),
            vlrTotProd: parseFloat(totaisNfe?.vlr_tot_prod),
            vlrTotNf: parseFloat(totaisNfe?.vlr_tot_nf),
          };

          dispatch(setTotaisNfe(statePayload));

          if (sugerirAutoQtdeVolumes && !inicial && !mudouQtdeVolumes) {
            dispatch(setQtdeVolumesFrete(parseFloat(ret.sugestao_qtd_volumes)));
          }
        }
      }
    };

    const iniciarTela = async () => {
      // Evita que fique algum valor perdido de anteriormente
      dispatch(init());

      if (!(await carregarParametros())) {
        history.goBack();
        return;
      }

      if (action === MODAL_ACTIONS.EDIT) {
        if (!(await carregarDados(true))) {
          history.goBack();
          return;
        }
      }
      setLoading(false);
    };

    const onActivate = () => {
      iniciarTela();
    };

    useEffect(onActivate, []);

    const carregarDadosCliente = useCallback(async () => {
      if (stateCab.idCliente) {
        const [ok, ret] = await CadastroClienteService.buscarResumo(
          stateCab.idCliente
        );
        if (ok) {
          dispatch(setNomeCliente(ret.razao_soc_nfe || ret.nome));
          dispatch(setCpfCnpjDestinatario(ret.cpf_cnpj));
          dispatch(setInscricaoEstadualDestinatario(ret.insc_est));
          dispatch(setEnderecoDestinatario(ret.endereco_cliente));
          dispatch(setCepDestinatario(ret.cep));
          dispatch(setCidadeDestinatario(ret.cidade_cliente));
          dispatch(setUfCidadeDestinatario(ret.uf_cidade));
          dispatch(setFone1Destinatario(ret.fone1));
          dispatch(
            setObrigInfoOrdemCompraNfeDestinatario(
              ret.obrig_info_ordem_compra_nfe
            )
          );
        }
      }
    }, [stateCab.idCliente, dispatch]);

    useEffect(() => {
      carregarDadosCliente();
    }, [carregarDadosCliente]);

    const toggleItem = () => setItemOpen(!itemOpen);

    const toggleItemIcms = () => setItemIcmsOpen(!itemIcmsOpen);

    const toggleDadosImportacaoItem = () =>
      setDadosImportacaoItemOpen(!dadosImportacaoItemOpen);

    const onChangeExigeInfoFatura = () => {
      if (!stateCab.exigeInfoFatura) {
        dispatch(setFaturas([]));
        dispatch(setFormasPag([]));
      }
    };

    useEffect(onChangeExigeInfoFatura, [stateCab.exigeInfoFatura]);

    const verificarIncluirCab = async () => {
      if (!stateCab.idNfe) {
        if (!moment(stateCab.dataEmi).isValid()) {
          showError("Por favor, revise a data de emissão", "ValidFront001", []);
          return false;
        }

        const payload = {
          data_emi: moment(stateCab.dataEmi).format("YYYY-MM-DD"),
          origem: "MAN",
          empr_origem: null,
          id_origem: 0,
          id_tipo_doc: stateCab.idTipoDoc,
          id_cliente: stateCab.idCliente,
          nome_cliente: stateCab.nomeCliente,
          info_adic_1: stateCab.infoAdic,
          importacao: false,
          numero_imp: 0,
          serie_imp: 0,
          chave_acesso_imp: "",
          frete: {
            id_transportadora: stateCab.frete.idTransportadora,
            placa_veic: stateCab.frete.placa ?? "",
            uf_veic: stateCab.frete.uf ?? "",
            especie_frete: stateCab.frete.especie ?? "",
            marca_frete: stateCab.frete.marca ?? "",
            numero_volume: stateCab.frete.numero ?? "",
            frete_por_conta: stateCab.frete.porConta ?? null,
            qtd_volumes: stateCab.frete.qtdeVolumes ?? 0,
            peso_bruto: stateCab.frete.pesoBruto ?? 0,
            peso_liquido: stateCab.frete.pesoLiquido ?? 0,
          },
        };

        const [ok, ret] = await NotaFiscalService.inclusao.incluirCab(payload);
        if (!ok) {
          return false;
        }

        dispatch(setIdNfe(ret.id_nota_fiscal));
        return ret.id_nota_fiscal;
      } else {
        return stateCab.idNfe;
      }
    };

    const handleIncluirItem = async () => {
      setLoadingIncluirItem(true);
      const ok = await verificarIncluirCab();
      setLoadingIncluirItem(false);
      if (!ok) return;

      setItemAction(MODAL_ACTIONS.ADD);
      setTimeout(() => {
        toggleItem();
      }, 1);
    };

    const handleIncluirItemIcms = async () => {
      setLoadingIncluirItemIcms(true);
      const ok = await verificarIncluirCab();
      setLoadingIncluirItemIcms(false);
      if (!ok) return;

      toggleItemIcms();
    };

    const abrirDadosImportacaoItem = (idItem) => {
      setIdItemDadosImp(idItem);
      setTimeout(() => {
        toggleDadosImportacaoItem();
      }, 1);
    };

    const alterarItem = (idItem) => {
      setIdItemAlterar(idItem);
      setItemAction(MODAL_ACTIONS.EDIT);
      setTimeout(() => {
        toggleItem();
      }, 1);
    };

    const calcularImpostosAlteracao = async (row) => {
      const dentroEstab = ["JOR", "JRC", "MOB", "JOS", "CDV"].includes(
        stateCab.origem
      )
        ? false
        : true;
      const trib = await UteisService.calcularImpostosProduto(
        row.id_produto,
        stateCab.idCliente,
        stateCab.finalidadeOperTipoDoc,
        55,
        dentroEstab,
        formaSistemaTribut === "CFOP" ? row.cfop?.trim() : "",
        parseFloat(row.quantidade),
        parseFloat(row.vlr_tot_prod),
        parseFloat(row.vlr_frete)
      );

      if (trib && Object.keys(trib).length > 0) {
        const vTotal =
          parseFloat(trib.vlr_total_com_impostos) +
          (parseFloat(row.vlr_outros) || 0);

        const res = {
          vlr_icms_st: parseFloat(trib.vlr_icms_st),
          vlr_ipi: parseFloat(trib.vlr_ipi),
          vlr_icms: parseFloat(trib.vlr_icms),
          vlr_total: vTotal,
          impostos: trib,
        };
        return [true, res];
      } else {
        return [false, null];
      }
    };

    const recalcularValoresGrade = async (coluna, novoValor, row) => {
      // Se o valor não for alterado, não recalcula nada
      const alterouValorNumerico = !["descricao", "unidade", "ncm"].includes(
        coluna
      );
      let novoValorTest;
      let valorAntesTest;
      if (alterouValorNumerico) {
        const casasDecTest =
          coluna === "quantidade"
            ? row.qtd_casas_decimais_qtd
            : coluna === "vlr_item"
            ? row.qtd_casas_decimais_vlrs
            : 2;
        novoValorTest = roundFloat(parseFloat(novoValor), casasDecTest);
        valorAntesTest = roundFloat(parseFloat(row[coluna]), casasDecTest);
      } else {
        novoValorTest = novoValor;
        valorAntesTest = row[coluna];
      }

      if (novoValorTest === valorAntesTest) {
        return false;
      }

      if (coluna === "descricao") {
        row.descricao = novoValor;
      } else if (coluna === "unidade") {
        row.unidade = novoValor;
      } else if (coluna === "ncm") {
        row.ncm = novoValor;
      } else if (coluna === "quantidade") {
        const quantidade = parseFloat(novoValor);
        const vlrItem = parseFloat(row.vlr_item);
        const vlrUnit = parseFloat(row.vlr_unit);
        const vlrTotBruto = vlrUnit * quantidade;

        let novoVlrDesc;
        let novoPercDesc;
        let novoVlrAcre;
        if (vlrUnit > vlrItem) {
          novoVlrDesc = quantidade * (vlrUnit - vlrItem);
          novoPercDesc = roundFloat((novoVlrDesc / vlrTotBruto) * 100, 2);
          novoVlrAcre = 0;
        } else {
          novoVlrDesc = 0;
          novoPercDesc = 0;
          if (vlrItem > vlrUnit) {
            novoVlrAcre = quantidade * (vlrItem - vlrUnit);
          } else {
            novoVlrAcre = 0;
          }
        }
        const vlrTotProd = vlrItem * quantidade;
        row.quantidade = quantidade;
        row.perc_desc = novoPercDesc;
        row.vlr_desc = novoVlrDesc;
        row.vlr_acre = novoVlrAcre;
        row.vlr_tot_prod = vlrTotProd;
      } else if (coluna === "vlr_item") {
        const quantidade = parseFloat(row.quantidade);
        const vlrItem = parseFloat(novoValor);
        const vlrUnit = parseFloat(row.vlr_unit);
        const vlrTotBruto = vlrUnit * quantidade;

        let novoVlrDesc;
        let novoPercDesc;
        let novoVlrAcre;
        if (vlrUnit > vlrItem) {
          novoVlrDesc = quantidade * (vlrUnit - vlrItem);
          novoPercDesc = roundFloat((novoVlrDesc / vlrTotBruto) * 100, 2);
          novoVlrAcre = 0;
        } else {
          novoVlrDesc = 0;
          novoPercDesc = 0;
          if (vlrItem > vlrUnit) {
            novoVlrAcre = quantidade * (vlrItem - vlrUnit);
          } else {
            novoVlrAcre = 0;
          }
        }
        const vlrTotProd = vlrItem * quantidade;
        row.perc_desc = novoPercDesc;
        row.vlr_desc = novoVlrDesc;
        row.vlr_acre = novoVlrAcre;
        row.vlr_item = vlrItem;
        row.vlr_tot_prod = vlrTotProd;
      }

      if (alterouValorNumerico) {
        const [ok, ret] = await calcularImpostosAlteracao(row);
        if (!ok) return false;
        row = { ...row, ...ret };
      }

      setAtualizarValoresItens(true);
      setItens(
        itens.map((element) => {
          if (element.id === row.id) {
            return { ...row };
          } else {
            return element;
          }
        })
      );
      return true;
    };

    const atualizarDadosItem = async (row) => {
      if (!atualizarValoresItens) return false;

      if (!row.impostos) {
        const [ok, ret] = await calcularImpostosAlteracao(row);
        if (!ok) return false;
        row = { ...row, ...ret };
      }

      const impostos = row.impostos;

      let idRegraTribut =
        formaSistemaTribut === "R_TRIB" ? impostos.id_regra : 0;

      const vlrUnit = parseFloat(row.vlr_unit);
      const vlrItem = parseFloat(row.vlr_item);
      const quantidade = parseFloat(row.quantidade);

      let percDesc;
      let vlrAcre;
      if (vlrItem > vlrUnit) {
        percDesc = 0;
        vlrAcre = (vlrItem - vlrUnit) * quantidade;
      } else {
        percDesc = 100 - (vlrItem / vlrUnit) * 100;
        vlrAcre = 0;
      }

      const commonPayload = {
        id_item: row.id,
        id_produto: row.id_produto,
        descricao: row.descricao,
        cfop: row.cfop,
        quantidade: quantidade,
        ncm: row.ncm,
        vlr_unit: vlrUnit,
        vlr_item: vlrItem,
        perc_desc: percDesc,
        vlr_desc: row.vlr_desc,
        vlr_acre: vlrAcre,
        vlr_total: row.vlr_total,
        vlr_frete: row.vlr_frete,
        vlr_seguro: row.vlr_seguro,
        vlr_outros: row.vlr_outros,
        vlr_tot_prod: row.vlr_tot_prod,
        mostrar_desc: row.ver_desc_nfe,
        nro_ord_compra: row.nro_ord_compra,
        item_ordem_compra: row.item_ordem_compra ?? 0,
        id_regra_tribut: idRegraTribut,
        qtd_tributavel: row.qtd_tributavel ?? "",
        unidade: row.unidade,
        unidade_tributavel: row.unidade_tributavel ?? 0,
        cor: row.cor,
        impostos: {
          base_calc_icms: parseFloat(impostos.base_calc_icms),
          sit_trib_icms: impostos.sit_trib_icms,
          origem: impostos.origem,
          modal_bc_icms: impostos.modal_bc_icms,
          perc_icms: parseFloat(impostos.perc_icms),
          perc_red_bc_icms: parseFloat(impostos.perc_red_bc_icms),
          vlr_icms: parseFloat(impostos.vlr_icms),
          base_calc_icms_st: parseFloat(impostos.base_calc_icms_st),
          perc_red_icms_st: parseFloat(impostos.perc_red_icms_st),
          modal_bc_icms_st: impostos.modal_bc_icms_st,
          perc_icms_st: parseFloat(impostos.perc_icms_st),
          mva_icms_st: parseFloat(impostos.mva_icms_st),
          vlr_icms_st: parseFloat(impostos.vlr_icms_st),
          vlr_cred_icms: parseFloat(impostos.vlr_cred_icms),
          perc_cred_icms: parseFloat(impostos.perc_cred_icms),
          vlr_icms_oper: parseFloat(impostos.vlr_icms_oper),
          vlr_difer_icms: parseFloat(impostos.vlr_difer_icms),
          perc_difer_icms: parseFloat(impostos.perc_difer_icms),
          base_calc_ipi: parseFloat(impostos.base_calc_ipi),
          sit_trib_ipi: impostos.sit_trib_ipi,
          enquad_ipi: impostos.enquad_ipi,
          perc_ipi: parseFloat(impostos.perc_ipi),
          vlr_ipi: parseFloat(impostos.vlr_ipi),
          base_calc_pis: parseFloat(impostos.base_calc_pis),
          sit_trib_pis: impostos.sit_trib_pis,
          perc_pis: parseFloat(impostos.perc_pis),
          vlr_pis: parseFloat(impostos.vlr_pis),
          base_calc_cofins: parseFloat(impostos.base_calc_cofins),
          sit_trib_cofins: impostos.sit_trib_cofins,
          perc_cofins: parseFloat(impostos.perc_cofins),
          vlr_cofins: parseFloat(impostos.vlr_cofins),
          perc_fcp: parseFloat(impostos.perc_fcp),
          vlr_fcp: parseFloat(impostos.vlr_fcp),
          base_calc_fcp: parseFloat(impostos.base_calc_fcp),
          perc_deson_icms: parseFloat(impostos.perc_deson_icms),
          vlr_deson_icms: parseFloat(impostos.vlr_deson_icms),
          base_calc_st_ret: parseFloat(impostos.base_calc_st_ret),
          vlr_icms_substituto: parseFloat(impostos.vlr_icms_substituto),
          vlr_icms_st_ret: parseFloat(impostos.vlr_icms_st_ret),
          perc_red_bc_icms_efet: parseFloat(impostos.perc_red_bc_icms_efet),
          perc_icms_efet: parseFloat(impostos.perc_icms_efet),
          base_calc_icms_efet: parseFloat(impostos.base_calc_icms_efet),
          vlr_icms_efet: parseFloat(impostos.vlr_icms_efet),
          c_benef: impostos.c_benef,
          base_calc_icms_uf_dest: parseFloat(impostos.base_calc_icms_uf_dest),
          base_calc_fcp_uf_dest: parseFloat(impostos.base_calc_fcp_uf_dest),
          perc_fcp_uf_dest: parseFloat(impostos.perc_fcp_uf_dest),
          aliq_icms_uf_dest: parseFloat(impostos.aliq_icms_uf_dest),
          aliq_icms_inter: parseFloat(impostos.aliq_icms_inter),
          aliq_icms_inter_partilha: parseFloat(
            impostos.aliq_icms_inter_partilha
          ),
          vlr_fcp_uf_dest: parseFloat(impostos.vlr_fcp_uf_dest),
          vlr_icms_uf_dest: parseFloat(impostos.vlr_icms_uf_dest),
          vlr_icms_uf_remet: parseFloat(impostos.vlr_icms_uf_remet),
          qtd_bc_icms_mono_ret: parseFloat(
            impostos.qtd_bc_icms_mono_ret ?? "0"
          ),
          aliq_ad_rem_icms_mono_ret: parseFloat(
            impostos.aliq_ad_rem_icms_mono_ret ?? "0"
          ),
          vlr_icms_mono_ret: parseFloat(impostos.vlr_icms_mono_ret ?? "0"),
          vlr_aduaneiro: parseFloat(impostos.vlr_aduaneiro),
        },
      };

      const [ok] = await NotaFiscalService.inclusao.alterarItem(commonPayload);
      carregarDadosItens();
      return ok;
    };

    const handleExcluirItem = (idItem, nomeItem) => {
      const onConfirm = async () => {
        const [ok] = await NotaFiscalService.inclusao.excluirItem(idItem);
        if (ok) {
          carregarDadosItens();
        }
      };

      showConfirmation(
        <>Tem certeza de que deseja excluir o produto {nomeItem}?</>,
        () => onConfirm()
      );
    };

    const finalizarNfe = async (faseValid = 1) => {
      if (!moment(stateCab.dataEmi).isValid()) {
        showError("Por favor, revise a data de emissão", "ValidFront001", []);
        return;
      }

      if (stateCab.dataEmi > new Date()) {
        showError(
          "A Data de Emissão não pode ser posterior ao dia de hoje.",
          "ValidFront002",
          []
        );
        return;
      }

      if (["", null, undefined].includes(stateCab.frete.porConta)) {
        showError(
          "Por favor, informe por conta de quem é o frete.",
          "ValidFront003",
          []
        );
        return;
      }

      if (faseValid === 1) {
        if (formatDateISO(stateCab.dataEmi) !== formatDateISO(new Date())) {
          showConfirmation(
            <>
              A Data de Emissão informada é anterior ao dia de hoje.
              <br />
              Deseja continuar?
            </>,
            () => finalizarNfe(2)
          );
          return;
        }
      }

      setLoadingFinalizar(true);
      const payload = {
        id_nfe: stateCab.idNfe,
        id_tipo_doc: stateCab.idTipoDoc,
        id_cliente: stateCab.idCliente,
        nome_cliente: stateCab.nomeCliente,
        data_emi: formatDateISO(stateCab.dataEmi),
        a_vista: stateCab.aVista,
        faturas: (stateCab.faturas ?? []).map((e) => ({
          numero: e.numero,
          parcela: e.parcela,
          data_vcto: moment(dateFromLocaleString(e.data_vcto)).format(
            "YYYY-MM-DD"
          ),
          valor: e.valor,
        })),
        formas_pag: (stateCab.formasPag ?? []).map((e) => ({
          forma_pag: e.forma_pag,
          valor: e.valor,
        })),
        frete: {
          frete_por_conta: stateCab.frete.porConta ?? "",
          id_transportadora: stateCab.frete.idTransportadora,
          placa_veic: stateCab.frete.placa ?? "",
          uf_veic: stateCab.frete.uf ?? "",
          qtd_volumes: stateCab.frete.qtdeVolumes ?? 0,
          especie_frete: stateCab.frete.especie ?? "",
          marca_frete: stateCab.frete.marca ?? "",
          numero_volume: stateCab.frete.numero ?? "",
          peso_bruto: stateCab.frete.pesoBruto ?? 0,
          peso_liquido: stateCab.frete.pesoLiquido ?? 0,
        },
      };

      const [ok] = await NotaFiscalService.inclusao.finalizarInclusao(payload);
      if (ok) {
        setLoadingFinalizar(false);
        dispatch(init());
        history.replace(notaFiscalEletronicaRoute.path);
      }
      setLoadingFinalizar(false);
    };

    const handleSetTipoDoc = async (v, s) => {
      if (action === MODAL_ACTIONS.ADD) {
        if (v && v !== stateCab.idTipoDoc) {
          const [ok, ret] = await apiGetV2(
            `${routesBaseNumeracaoNfe}/busca_prox_nro_nfe/${s?.serie}/`
          );
          if (ok) {
            dispatch(setNumero(ret.nro_prox_nfe));
          } else {
            return false;
          }
        }
        dispatch(setSerie(s?.serie));
      }
      dispatch(setIdTipoDoc(v));
      dispatch(setFinalidadeOperTipoDoc(s?.finalidade));
      dispatch(setExigeInfoFatura(s?.exige_info_fatura));
      dispatch(setTipoNf(s?.tipo_nf));
      dispatch(setNfImportacao(s?.nf_importacao));
    };

    useImperativeHandle(ref, () => ({
      onBeforeGoingBack: () => dispatch(init()),
    }));

    return loading ? (
      <Loader />
    ) : (
      <>
        <Card body>
          <Row>
            <AsyncComboBox
              md={5}
              isConcatField
              concatModelName="tipo_documento"
              defaultOptions
              label="Tipo de Documento"
              isSearchable
              onChange={(s) => handleSetTipoDoc(s?.value, s)}
              defaultValue={stateCab.idTipoDoc}
              autoFocus
            />
            {!["", null, undefined].includes(stateCab.tipoNf) && (
              <FixedField
                label="Operação"
                value={
                  stateCab.tipoNf === "SAI"
                    ? "Saída"
                    : stateCab.tipoNf === "ENT"
                    ? "Entrada"
                    : stateCab.tipoNf
                }
                horizontal
                divClassName="pt-4"
              />
            )}
            <FixedField
              label="Série"
              value={stateCab.serie}
              horizontal
              divClassName="pt-4"
            />
            <FixedField
              label="Número"
              value={stateCab.numero}
              horizontal
              divClassName="pt-4"
            />
            <UnlockToEdit>
              <DatePicker
                md={2}
                label="Emissão"
                value={stateCab.dataEmi}
                onChange={(v) =>
                  dispatch(setDataEmi(moment.isMoment(v) ? v.toDate() : v))
                }
                style={{ textAlign: "center" }}
              />
            </UnlockToEdit>
          </Row>
        </Card>
        <Row>
          <Col md={null} className="pr-1">
            <Card body>
              <Divider className="mt-0">Informações do Destinatário</Divider>
              <Row className="mb-2">
                <PesqCliente
                  md={7}
                  mdIdent={4}
                  onConfirm={({ id, nome }) => {
                    dispatch(setIdCliente(id));
                    dispatch(setNomeCliente(nome));
                  }}
                  selectNextField={() => {}}
                  onChangeDescricao={(v) => dispatch(setNomeCliente(v))}
                  idCliente={stateCab.idCliente}
                  nomeCliente={stateCab.nomeCliente}
                  showShortcut
                />
              </Row>
              <Row className="mb-2">
                <FixedField
                  label="CNPJ"
                  value={stateCab.dadosDest.cpfCnpj}
                  horizontal
                />
                <FixedField
                  label="Inscrição Estadual"
                  value={stateCab.dadosDest.inscricaoEstadual}
                  horizontal
                />
                <FixedField
                  label="Endereço"
                  value={stateCab.dadosDest.endereco}
                  horizontal
                />
              </Row>
              <Row>
                <FixedField
                  label="CEP"
                  value={stateCab.dadosDest.cep}
                  horizontal
                />
                <FixedField
                  label="Cidade"
                  value={
                    stateCab.dadosDest.cidade
                      ? stateCab.dadosDest.cidade +
                        " - " +
                        stateCab.dadosDest.ufCidade
                      : ""
                  }
                  horizontal
                />
                <FixedField
                  label="Fone"
                  value={stateCab.dadosDest.fone1}
                  horizontal
                />
              </Row>
            </Card>
          </Col>
          <Col
            md={"auto"}
            className="no-gutters"
            style={{ alignSelf: "stretch", display: "flex" }}
          >
            <div style={{ display: "flex" }} className="pb-2">
              <FormButton
                md="auto"
                divClassName="mt-auto"
                padded={false}
                color="primary"
                disabled={semCliente || setmTipoDoc}
                disabledHint={
                  semCliente
                    ? "Selecione o Destinatário da Nota Fiscal"
                    : "Selecione o Tipo de Documento"
                }
                onClick={handleIncluirItem}
                loading={loadingIncluirItem}
              >
                Incluir Produto
              </FormButton>
            </div>
          </Col>
        </Row>
        <Card body>
          <Divider className="mt-0">Informações dos Produtos</Divider>
          <IncluirAlterarItemNFModal
            isOpen={itemOpen}
            toggle={toggleItem}
            action={itemAction}
            idItemAlterar={idItemAlterar}
            idCliente={stateCab.idCliente}
            finalidadeOperacao={stateCab.finalidadeOperTipoDoc}
            idNfe={stateCab.idNfe}
            obrigInfoOrdemCompraNfe={stateCab.dadosDest.obrigInfoOrdemCompraNfe}
            identificaCor={identificaCor}
            notifyEvent={carregarDadosItens}
            itens={itens}
            formaSistemaTribut={formaSistemaTribut}
            focarDescricaoProd={focarDescricaoProd}
          />
          <IncluirItemIcmsNFModal
            isOpen={itemIcmsOpen}
            toggle={toggleItemIcms}
            idNfe={stateCab.idNfe}
            notifyEvent={carregarDadosItens}
          />
          <DadosImportacaoItemNfeModal
            isOpen={dadosImportacaoItemOpen}
            toggle={toggleDadosImportacaoItem}
            idItemNfe={idItemDadosImp}
          />
          <ItensNFGrid
            dados={itens}
            excluirItem={handleExcluirItem}
            recalcularValoresGrade={recalcularValoresGrade}
            atualizarDadosItem={atualizarDadosItem}
            abrirDadosImportacaoItem={abrirDadosImportacaoItem}
            alterarItem={alterarItem}
          />
        </Card>
        <Card body>
          <Divider className="mt-0">Totais da Nota Fiscal</Divider>
          <Row style={{ justifyContent: "space-between" }}>
            <FixedField
              md="auto"
              horizontal
              label="ICMS"
              value={
                <div style={{ display: "flex" }}>
                  <span>
                    {formatNumber(stateTotaisNfe.baseCalcIcms, true, 2)} |{" "}
                    {formatNumber(stateTotaisNfe.vlrTotIcms, true, 2)}
                  </span>
                </div>
              }
            />
            <FixedField
              md="auto"
              horizontal
              label="ICMS ST"
              value={
                <div style={{ display: "flex" }}>
                  <span>
                    {formatNumber(stateTotaisNfe.baseCalcIcmsSt, true, 2)} |{" "}
                    {formatNumber(stateTotaisNfe.vlrTotIcmsSt, true, 2)}
                  </span>
                </div>
              }
            />
            <FixedField
              md="auto"
              horizontal
              label="IPI"
              value={formatNumber(stateTotaisNfe.vlrTotIpi, true, 2)}
            />
            <FixedField
              md="auto"
              horizontal
              label="Frete"
              value={formatNumber(stateTotaisNfe.vlrFrete, true, 2)}
            />
            <FixedField
              md="auto"
              horizontal
              label="Outras Desp."
              value={formatNumber(stateTotaisNfe.vlrOutros, true, 2)}
            />
            <FixedField
              md="auto"
              horizontal
              label="Desconto"
              value={formatNumber(stateTotaisNfe.vlrTotDesc, true, 2)}
            />
            <FixedField
              md="auto"
              horizontal
              label="Total Produtos"
              value={formatNumber(stateTotaisNfe.vlrTotProd, true, 2)}
            />
            <FixedField
              md="auto"
              horizontal
              label="Total NF"
              value={formatNumber(stateTotaisNfe.vlrTotNf, true, 2)}
            />
          </Row>
        </Card>
        <Card body>
          <Row>
            <Col md={6}>
              {stateCab.exigeInfoFatura && (
                <FaturasNfForm identFaturaPor={identFaturaPor} />
              )}
            </Col>
            <Col md={6}>
              <DadosFreteNf setMudouQtdeVolumes={setMudouQtdeVolumes} />
            </Col>
          </Row>
        </Card>
        <Card body>
          <Row>
            <ChavesReferenciadasNfModal idNFe={stateCab.idNfe} />
            <InfoAdicNfModal />

            <LabelButton
              style={{ marginTop: "1.1rem" }}
              md="auto"
              disabled={semCliente || setmTipoDoc}
              disabledHint={
                semCliente
                  ? "Selecione o Destinatário da Nota Fiscal"
                  : "Selecione o Tipo de Documento"
              }
              onClick={handleIncluirItemIcms}
              loading={loadingIncluirItemIcms}
            >
              Item de Ajuste ICMS
            </LabelButton>
            <Col md={"auto"} className="pl-0">
              <Label>&#8205;</Label>
              <RiInformationLine
                size={20}
                id="hint-icms"
                style={{ display: "block", marginTop: "6px" }}
              />
            </Col>
            <UncontrolledTooltip target="hint-icms">
              Opção utilizada para inclusão de ICMS e/ou Ressarcimento de ICMS
              ST
            </UncontrolledTooltip>
            <FormButton
              color="success"
              onClick={() => finalizarNfe(1)}
              divClassName="ml-auto"
              loading={loadingFinalizar}
              disabled={[0, null, undefined].includes(stateCab.idNfe)}
              disabledHint="A NF não foi salva. Inclua um item ou salve-a primeiro."
            >
              Finalizar NFe
            </FormButton>
          </Row>
        </Card>
      </>
    );
  }
);

export const IncluirAlterarNF = ({ location }) => {
  const selected = location.state.selected;
  const action = location.state.action ?? MODAL_ACTIONS.ADD;

  const refContainer = useRef();

  return (
    <PageContainer
      title={`${
        action === MODAL_ACTIONS.ADD ? "Inclusão" : "Alteração"
      } de Nota Fiscal`}
      number="0055_1"
      canGoBack
      onBeforeGoingBack={() => {
        if (refContainer.current) {
          refContainer.current.onBeforeGoingBack();
        }
      }}
    >
      <Provider store={storeCab}>
        <IncluirAlterarNFContainer
          selected={selected}
          action={action}
          ref={refContainer}
        />
      </Provider>
    </PageContainer>
  );
};
