import React from "react";
import { IconButton, MoreDropdown, Table } from "../../../../components";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
  naturalSortDate,
} from "../../../../coreUtils";
import { GoArrowRight } from "react-icons/go";
import { DropdownItem } from "reactstrap";

export const EntradaNfXmlGrid = ({
  dados,
  prosseguirEntradaNf,
  naoImportar,
  nomeOutraEmpresa,
  enviarXmlOutraEmpresa,
}) => {
  const columns = [
    {
      dataField: "data_emi",
      text: "Emissão",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
    },
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "serie",
      text: "S",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_fornecedor",
      text: "Fornecedor",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) =>
        row.id_fornecedor ? formatValueFromAPI(c, row.id_fornecedor) : c,
      sortable: true,
    },
    {
      dataField: "natureza_operacao",
      text: "Natureza Operação",
      align: "left",
      sortable: true,
      formatter: (c) => (c ?? "").substring(0, 30),
    },
    {
      dataField: "qtd_produtos",
      text: "Qtd. Produtos",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      sortable: true,
    },
    {
      dataField: "vlr_total",
      text: "Valor Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "act2",
      text: "",
      align: "center",
      selectOnClick: false,
      fixedColWidth: true,
      colWidth: "40px",
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={() => naoImportar(row.id)}>
            Não importar
          </DropdownItem>
          {nomeOutraEmpresa && (
            <DropdownItem onClick={() => enviarXmlOutraEmpresa(row.id)}>
              Enviar XML para {nomeOutraEmpresa}
            </DropdownItem>
          )}
        </MoreDropdown>
      ),
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <IconButton
          icon={GoArrowRight}
          onClick={() => prosseguirEntradaNf(row.id)}
        />
      ),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      keyField="id"
      onRowDoubleClick={(id) => prosseguirEntradaNf(id)}
      paginated={false}
    />
  );
};
