import { Clipboard } from "react-feather";
import { CadastroVistoriaTecnica } from "../../pages/ordens_servico/vistoria_tecnica/cadastro/CadastroVistoriaTecnica";
import { VistoriaTecnica } from "../../pages/ordens_servico/vistoria_tecnica/VistoriaTecnica";
import { CentralOS } from "../../pages/ordens_servico/central_os/CentralOS";
import { IncluirAlterarOS } from "../../pages/ordens_servico/central_os/incluir_alterar/IncluirAlterarOS";
import { ManutencaoOS } from "../../pages/ordens_servico/central_os/manutencao/ManutencaoOS";
import { OrcamentosOS } from "../../pages/ordens_servico/central_os/orcamentos/OrcamentosOS";
import { IncluirAlterarOrcamentoOS } from "../../pages/ordens_servico/central_os/orcamentos/incluir_alterar/IncluirAlterarOrcamentoOS";
import { OrcRapidoOS } from "../../pages/ordens_servico/central_os/orc_rapido_os/OrcRapidoOS";
import { OrdensServico } from "../../pages/ordens_servico/OrdensServico";
import { HistoricoVeiculo } from "../../pages/ordens_servico/central_os/historico_veiculo/HistoricoVeiculo";
import CentralProjetos from "../../pages/ordens_servico/central_projetos/CentralProjetos";
import ProgramacaoExecucao from "../../pages/ordens_servico/central_projetos/programacao_projeto/ProgramacaoExecucao";
import ValidacaoProgramacaoExecucao from "../../pages/ordens_servico/central_projetos/validacao_programacao_execucao/ValidacaoProgramacaoExecucao";
import { GerenciamentoOS } from "../../pages/ordens_servico/gerenciamento_os/GerenciamentoOS";

const cadastroVistoriaTecnicaRoute = {
  path: "/ordem_servico/vistoria_tecnica/cadastro/",
  name: "Cadastro Vistoria Técnica",
  id: "AcSkWeb0027_1",
  nroTela: "0027",
  component: CadastroVistoriaTecnica,
};

export const incluirAlterarOSRoute = {
  path: "/ordem_servico/central_os/abertura",
  name: "Inclusão de OS",
  id: "AcSkWeb0006_1",
  nroTela: "0006",
  component: IncluirAlterarOS,
};

export const manutencaoOSRoute = {
  path: "/ordem_servico/central_os/manutencao",
  name: "Inclusão de OS",
  id: "AcSkWeb0006_1",
  nroTela: "0006",
  component: ManutencaoOS,
};

export const orcamentosOSRoute = {
  path: "/ordem_servico/orcamentos/",
  name: "Orçamentos de Ordens de Serviço",
  id: "AcSkWeb0006",
  nroTela: "0006",
  component: OrcamentosOS,
};

export const incluirAlterarOrcamentoOSRoute = {
  path: "/ordem_servico/atendimento/orcamento",
  name: "Orçamentos de Ordens de Serviço",
  id: "AcSkWeb0077",
  nroTela: "0077",
  component: IncluirAlterarOrcamentoOS,
};

export const orcRapidoOSRoute = {
  path: "/ordem_servico/atendimento/orcamento_rapido",
  name: "Orçamento Rápido",
  id: "AcSkWeb0077",
  nroTela: "0077",
  component: OrcRapidoOS,
};

export const programacaoProjetoRoute = {
  path: "/ordem_servico/atendimento/programacao_projeto",
  name: "Programação de Projeto",
  id: "AcSkWeb0027_1",
  nroTela: "0027",
  component: ProgramacaoExecucao,
};

export const validarProgramacaoExecucaoRoute = {
  path: "/ordem_servico/atendimento/validar_programacao_execucao",
  name: "Validar Programação de Execução",
  id: "AcSkWeb0027_1",
  nroTela: "0027",
  component: ValidacaoProgramacaoExecucao,
};

export const centralProjetosRoute = {
  path: "/ordem_servico/atendimento/central_projetos",
  name: "Central de Projetos",
  id: "AcSkWeb0027",
  nroTela: "0027",
  component: CentralProjetos,
};

export const centralOSRoute = {
  path: "/ordem_servico/atendimento/central_os",
  name: "Central de O.S.",
  id: "AcSkWeb0006",
  nroTela: "0006",
  component: CentralOS,
};

export const vistoriaTecnicaRoute = {
  path: "/ordem_servico/atendimento/vistoria_tecnica",
  name: "Vistoria Técnica",
  id: "AcSkWeb0027",
  nroTela: "0027",
  component: VistoriaTecnica,
};

export const historicoVeiculoRoute = {
  path: "/ordem_servico/atendimento/central_os/historico_veiculo",
  name: "Histórico do Veículo",
  id: "AcSkWeb0006",
  nroTela: "0006",
  component: HistoricoVeiculo,
};

export const gerenciamentoOsRoute = {
  path: "/ordem_servico/gerenciamento/gerenciamento_os",
  name: "Gerenciamento de O.S.",
  id: "AcSkWeb0107",
  nroTela: "0107",
  component: GerenciamentoOS,
};

export const atendimentoOSRoute = [
  centralOSRoute,
  vistoriaTecnicaRoute,
  orcRapidoOSRoute,
  centralProjetosRoute,
  gerenciamentoOsRoute,
];

const OsRoutes = {
  path: "/ordem_servico",
  name: "Ordens de Serviço",
  icon: Clipboard,
  component: OrdensServico,
};

export { OsRoutes, cadastroVistoriaTecnicaRoute };
