import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id_mov_receber: 0,
  id_mov_receber: "",
  nome_cliente: "",
  cpf_cnpj_cliente: "",
  vlr_troco_dinh: 0,
  vlr_troco_cred: 0,
  moedas: [],
};

export const moedasRecSlice = createSlice({
  name: "moedas_rec_slice",
  initialState: initialState,
  reducers: {
    iniciarNovoRecebimento: (state, action) => {
      state.id_mov_receber = action.payload.id_mov_receber;
      state.nome_cliente = action.payload.nome_cliente;
      state.cpf_cnpj_cliente = action.payload.cpf_cnpj_cliente;
      state.vlr_troco_dinh = 0;
      state.vlr_troco_cred = 0;
      state.moedas = [];
    },
    addRecebDinheiro: (state, action) => {
      state.moedas.push({
        moeda: "DI",
        valor: action.payload,
      });
    },
    addRecebCartao: (state, action) => {
      state.moedas.push({
        moeda: "CC",
        valor: action.payload.valor,
        cartoes: action.payload.dados_rec,
      });
    },
    addRecebTrasnfBanc: (state, action) => {
      state.moedas.push({
        moeda: "TB",
        valor: action.payload.valor,
        transf_banc: action.payload.dados_rec,
      });
    },
    addRecebCrediario: (state, action) => {
      state.moedas.push({
        moeda: "CI",
        valor: action.payload.valor,
        crediario: action.payload.dados_rec,
      });
    },
    addRecebCreditoCliente: (state, action) => {
      state.moedas.push({
        moeda: "CL",
        valor: action.payload.valor,
        credito_cli: action.payload.dados_rec,
      });
    },
    addRecebCheque: (state, action) => {
      state.moedas.push({
        moeda: "CQ",
        valor: action.payload.valor,
        cheques: action.payload.dados_rec,
      });
    },
    addRecebCreditoRotativo: (state, action) => {
      state.moedas.push({
        moeda: "CR",
        valor: action.payload.valor,
        credito_rot: action.payload.dados_rec,
      });
    },
    setValorTrocoDinheiro: (state, action) => {
      state.vlr_troco_dinh = action.payload;
      state.vlr_troco_cred = 0;
    },
    setValorTrocoCredito: (state, action) => {
      state.vlr_troco_cred = action.payload;
      state.vlr_troco_dinh = 0;
    },
  },
});

export const {
  addRecebCartao,
  addRecebCheque,
  addRecebCrediario,
  addRecebCreditoCliente,
  addRecebCreditoRotativo,
  addRecebDinheiro,
  addRecebTrasnfBanc,
  iniciarNovoRecebimento,
  setValorTrocoCredito,
  setValorTrocoDinheiro,
} = moedasRecSlice.actions;

export default moedasRecSlice.reducer;
