import React from "react";
import { IconLink } from "../../../../components";
import { BsFillCartCheckFill } from "react-icons/bs";
import { validarProgramacaoExecucaoRoute } from "../../../../routes/modules/ordem_servico";

const AcoesGridValidarProgramacaoExecucaoProjeto = ({ row }) => {
  return (
    <IconLink
      pathname={validarProgramacaoExecucaoRoute.path}
      state={{ selected: row.id, row: row }}
      icon={BsFillCartCheckFill}
      size={16}
      tooltipPlacement="left"
      hint={"Validar Programação de Execução"}
    />
  );
};

export default AcoesGridValidarProgramacaoExecucaoProjeto;
