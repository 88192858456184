import { stob64, hextorstr, KEYUTIL, KJUR } from "jsrsasign";
import qz from "qz-tray";
import { showError } from "../components/AlertaModal";
import { apiGetV2 } from "../apiV2";

export const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const setupSigning = () => {
  qz.security.setCertificatePromise((resolve, _) => {
    resolve(
      "-----BEGIN CERTIFICATE-----\n" +
        "MIIECzCCAvOgAwIBAgIGAYmT9W5fMA0GCSqGSIb3DQEBCwUAMIGiMQswCQYDVQQG\n" +
        "EwJVUzELMAkGA1UECAwCTlkxEjAQBgNVBAcMCUNhbmFzdG90YTEbMBkGA1UECgwS\n" +
        "UVogSW5kdXN0cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMx\n" +
        "HDAaBgkqhkiG9w0BCQEWDXN1cHBvcnRAcXouaW8xGjAYBgNVBAMMEVFaIFRyYXkg\n" +
        "RGVtbyBDZXJ0MB4XDTIzMDcyNTIwNDgwMloXDTQzMDcyNTIwNDgwMlowgaIxCzAJ\n" +
        "BgNVBAYTAlVTMQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYD\n" +
        "VQQKDBJRWiBJbmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMs\n" +
        "IExMQzEcMBoGCSqGSIb3DQEJARYNc3VwcG9ydEBxei5pbzEaMBgGA1UEAwwRUVog\n" +
        "VHJheSBEZW1vIENlcnQwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQCu\n" +
        "9dPHf9g9s7yX1F8pahnXeWgAtyus+kDOl/wmiSM4n9Bhb+NRJfDmasmYTQBmclwk\n" +
        "q9Pyy5lQ8oVXzp3MDHG5EzTtbKsaoO9VCiZuGxGMITudiA58Q1Y37s8/FRjnojQB\n" +
        "SNWmAKcKYV6RBIbrOvyFnHnd9ESkXq32ObdRePa+/gCWXSCsGuxfA0/OOrf+ijaW\n" +
        "Cgt2IgGNhgjKT302gf1njEQKRbSTyAvDrSmE4dxmrtvAwYEMVBZwOXW9Ct0zMuYg\n" +
        "yE1faHMjp7NS1Mey9d395JQvw9IslMz0iMqWBWO9ck+ssIWDnt+wwRjRaRMDjQrP\n" +
        "kXV+cvwibJLKejYL92K1AgMBAAGjRTBDMBIGA1UdEwEB/wQIMAYBAf8CAQEwDgYD\n" +
        "VR0PAQH/BAQDAgEGMB0GA1UdDgQWBBSok3QjbhFTPM863QHOS9/muOFAojANBgkq\n" +
        "hkiG9w0BAQsFAAOCAQEAZOw4jgOmUcTjMTxyNCHKTJ6ucMoW/1mSyDaZ+sNSS0+9\n" +
        "+Gnq3eZ50ifrJAkh6EECLysT9cfKR4J0LaNThqOwSzX9rggEqDMLPqGv+rmlFN7X\n" +
        "S2aFxwD+hGLudnLZmLC9CThpzseWYhrMyHKIY+6n1u2FWpIqFyIR8DzW7va7B5z2\n" +
        "8CoKf1YBY4TL/DFIRlGGagJCcXozYro6oI+ZtPIK0GPjGlzhaE/I9lS+F5jDDqH9\n" +
        "91uzg+paQfXqtdMeHYiNg1gjBTRHo09nog1POypWbJJuNPLcttT/1HpfvHdwjAC1\n" +
        "GfwuqGYq2l6RFRqolroRZAnKw7mMrlr7CQPlxa/l6A==\n" +
        "-----END CERTIFICATE-----\n"
    );
  });
  const privateKey =
    "-----BEGIN PRIVATE KEY-----\n" +
    "MIIEuwIBADANBgkqhkiG9w0BAQEFAASCBKUwggShAgEAAoIBAQCu9dPHf9g9s7yX\n" +
    "1F8pahnXeWgAtyus+kDOl/wmiSM4n9Bhb+NRJfDmasmYTQBmclwkq9Pyy5lQ8oVX\n" +
    "zp3MDHG5EzTtbKsaoO9VCiZuGxGMITudiA58Q1Y37s8/FRjnojQBSNWmAKcKYV6R\n" +
    "BIbrOvyFnHnd9ESkXq32ObdRePa+/gCWXSCsGuxfA0/OOrf+ijaWCgt2IgGNhgjK\n" +
    "T302gf1njEQKRbSTyAvDrSmE4dxmrtvAwYEMVBZwOXW9Ct0zMuYgyE1faHMjp7NS\n" +
    "1Mey9d395JQvw9IslMz0iMqWBWO9ck+ssIWDnt+wwRjRaRMDjQrPkXV+cvwibJLK\n" +
    "ejYL92K1AgMBAAECgf8K71JNJbBeKt2en6dS706uKOhXIxuXtAxY3SblQazx3Qsp\n" +
    "C9UpPTXRMNtoqWdc/8jCgfq9MfxjEsVCt6clGaIB7zFfpWsMPwK2TewY9ZgjKfCP\n" +
    "5/D/0zWQSVyqlKb50yls6yBvP5sxCuTeK2pDSWx+7UiwlpOzsjctLG5IqUbCqQKi\n" +
    "BSyeZl/2aeOIx7IqipN8X36/C2+jG6UsVY4tVvINxsvG7WEpz+W7bR7n1xnW/RjM\n" +
    "2ZWtnjxNRCdts8qtu7G0ERHAc5L8fc62qwL4reMYC5QdEpAGOlqHDGqqqwboD8fb\n" +
    "REp5V+Xe0fRa7eapRV4ov+IzxpVRh7OR2OGU5MECgYEA9VK4Rmp6EPazpZqQ8DTN\n" +
    "r8q31o2Hd4vwRZf19L7uYzX/mUQjOKUPLjMaVC4CXIWVH9KIwqsl5/NgvLq8S/iW\n" +
    "E48YvLIQ0x6+6ZI9oiHAuw9Fn1RdhYHOSA8L0G/M9m+UHvNd1x9zlmgYspQK5d9E\n" +
    "bAfiJJBLyQNt3wIpTDa/bWMCgYEAtpMn8Ahw9ZuyCwj2GKXwplwigv/3nxjhPrKt\n" +
    "4ElBo+X/EeqP+nun5NtlMAd/ZP+qv08Sle3vj8TRbeSbCyI225WDM5UCaQPcFnj7\n" +
    "i1YnZo+0MlD7ex4geM8N3rTdVBxzMgiQUooFVd0rM286E7GIJ+XOobOaoZ0uYi+J\n" +
    "svjSlwcCgYEA1TtlOK9sEjqcw6Cpwl/jOJ03VaIG0X4tBhCjlGwwwMM6u3liM+Yk\n" +
    "X7/Dbs/e9ZSWneZdQPeKJyaAOwsEh/Q2XiHPGpSb0yWyQ0r9j2NPJkiL94VZFKZT\n" +
    "KocJec7zlHA/ivd15XpM+brZXL8ZCd0al9vyUF2rwbjdUy2Jv9kGYokCgYA9t50G\n" +
    "jTPyMsGK7WqThnTFJ9wawIBqbbSmxyvz54+i4frJiatGF0r4GBoqlD5CXBLSPN6h\n" +
    "hB85UGUjauXma5hzviTt6O85pK5fgmI2Bden2egmIi4AvnxnmkasBCPVbRCb1z1C\n" +
    "SZnoHq2d43mUcqz+H7cr/lTFUSb+1ecMcwRtPQKBgFtHwhisfGlI7Nxc/dl8QsJ5\n" +
    "gBUG3Exk9RWHTCCOfPH3F3xuhfzNS0Gc6PzdY8K6EXQz6pVQdgP9reUlqfvdkg3V\n" +
    "8KNtIFnnRleKj6N9GAfiX/g2sKB4lsqStUWKQwH37Zg3KY6FHOHwFS7fU1W6l6PF\n" +
    "5YsW8sM8qnX1rtQZV5Sr\n" +
    "-----END PRIVATE KEY-----\n";
  qz.security.setSignatureAlgorithm("SHA512");
  qz.security.setSignaturePromise(function (toSign) {
    return function (resolve, reject) {
      try {
        var pk = KEYUTIL.getKey(privateKey);
        var sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" });
        sig.init(pk);
        sig.updateString(toSign);
        var hex = sig.sign();
        resolve(stob64(hextorstr(hex)));
      } catch (err) {
        console.error(err);
        reject(err);
      }
    };
  });
};

export const printFileFromBlob = async (blob, printer) => {
  setupSigning();

  if (!qz.websocket.isActive()) {
    await qz.websocket.connect({ host: "localhost", retries: 5, delay: 1 });
  }

  if (printer === "") {
    printer = await qz.printers.getDefault();
  }
  const config = qz.configs.create(printer);
  var data = [
    {
      type: "pixel",
      format: "pdf",
      flavor: "base64",
      data: (await blobToBase64(blob)).split(",")[1],
    },
  ];
  qz.print(config, data).catch(function (e) {
    showError(String(e), "ErroImpQZTray");
  });
};

export const printLabel = async (data, printer = "") => {
  setupSigning();

  if (!qz.websocket.isActive()) {
    await qz.websocket.connect({ host: "localhost", retries: 5, delay: 1 });
  }

  if (printer === "") {
    printer = await qz.printers.getDefault();
  }

  const config = qz.configs.create(printer);
  qz.print(config, data).catch(function (e) {
    showError("Erro ao Imprimir Etiquetas", String(e));
  });
};

// Função foi criada apenas para que o nome faça mais sentido na hora de enviar comandos diretos para a impressora
export const sendCommandToPrinter = async (data, printer = "") =>
  printLabel(data, printer);

export const abrirGaveta = async () => {
  const [okImpressora, retImpressora] = await apiGetV2(`/tela/abre_gaveta/`);

  let nomeImpressora = retImpressora.nome_porta_impressora;
  if (nomeImpressora.startsWith("RAW:")) {
    nomeImpressora = nomeImpressora.replace("RAW:", "");
  }

  if (okImpressora) {
    await sendCommandToPrinter(["\x1B\x40", "\x1B\x70\x00"], nomeImpressora);
  }
};
