import React, { useState } from "react";
import { FixedField, ModalBase, TextInput } from "../../../../../../components";
import { Col, Row } from "reactstrap";
import { formatNumber } from "../../../../../../coreUtils";
import { useDispatch } from "react-redux";
import { addRecebCreditoRotativo } from "../store/moedas_rec_slice";
import CaixaLojaService from "../../../../../../services/financeiro/CaixaLojaService";

export const RecCreditoRotativoModal = ({
  idMovCaixaLoja,
  vlrCreditoRotativo,
  vlrSaldoDevRotativo,
  isOpen,
  toggle,
}) => {
  const [nomeCliente, setNomeCliente] = useState("");
  const [idCliente, setIdCliente] = useState(0);
  const [enderecoCliente, setEnderecoCliente] = useState("");
  const [foneCliente, setFoneCliente] = useState("");
  const [cpfCnpjCli, setCpfCnpjCli] = useState("");
  const [observ, setObserv] = useState("");

  const carregarDados = async () => {
    const [ok, ret] = await CaixaLojaService.buscarDadosMovReceber(
      idMovCaixaLoja
    );

    if (ok) {
      setNomeCliente(ret.nome_cli);
      setIdCliente(ret.id_cli);
      let enderecoCli = `${ret.endereco_cli}, ${ret.numero_cli}`;
      if (ret.complemento_cli) {
        enderecoCli = `${enderecoCli}, ${ret.complemento_cli}`;
      }
      if (ret.bairro_cli) {
        enderecoCli = `${enderecoCli}, ${ret.bairro_cli}`;
      }
      if (ret.cidade_cli) {
        enderecoCli = `${enderecoCli} - ${ret.cidade_cli}/${ret.uf_cli}`;
      }
      setEnderecoCliente(enderecoCli);
      setFoneCliente(ret.telefone_cli);
      setCpfCnpjCli(ret.cpf_cnpj_cli);
    }
  };

  const dispatch = useDispatch();

  const onConfirm = () => {
    const payload = {
      id_cliente: idCliente,
      observ: observ,
    };
    dispatch(
      addRecebCreditoRotativo({ valor: vlrCreditoRotativo, dados_rec: payload })
    );
    toggle();
  };

  const limparTela = () => {};

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Recebimento em Crédito Rotativo"
      number="0063_15"
      onConfirm={onConfirm}
      onClosed={limparTela}
      bodyStyle={{ paddingTop: "0.3rem" }}
      onBeforeOpen={carregarDados}
    >
      <Row>
        <Col md="auto">
          <Row className="mb-2">
            <FixedField
              label="Cliente"
              value={`${nomeCliente} (${idCliente})`}
              horizontal
            />
          </Row>
          <Row className="mb-2">
            <FixedField label="Endereço" value={enderecoCliente} horizontal />
          </Row>
          <Row className="mb-2">
            <FixedField label="CPF/CNPJ" value={cpfCnpjCli} horizontal />
            <FixedField label="Telefone" value={foneCliente} horizontal />
          </Row>
        </Col>
      </Row>
      <Row className="mb-2" style={{ justifyContent: "space-between" }}>
        <FixedField
          label="Valor"
          value={formatNumber(vlrCreditoRotativo, true, 2)}
          horizontal
        />
        <FixedField
          label="Saldo Devedor no Rotativo"
          value={formatNumber(vlrSaldoDevRotativo, true, 2)}
          horizontal
        />
      </Row>
      <Row>
        <TextInput
          md={12}
          label="Observação"
          value={observ}
          onChange={(_, v) => setObserv(v)}
          autoFocus
        />
      </Row>
    </ModalBase>
  );
};
