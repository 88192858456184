import React from "react";
import { useSelector } from "react-redux";
import { Row } from "reactstrap";
import { IntegerFormInput, TextInput } from "../../../../../components";
import { BtnGravar } from "./BtnGravar";

export const DadosOS = () => {
  const vistoriaTecnica = useSelector((state) => state.vistoriaTecnica);

  return (
    <Row className="mb-2">
      <IntegerFormInput
        label="O.S."
        md={2}
        defaultValue={vistoriaTecnica.idOS}
        disabled
      />
      <TextInput
        disabled
        label="Tipo de Cliente"
        md={2}
        value={vistoriaTecnica.tipoCliente === "F" ? "Físico" : "Jurídico"}
      />
      <TextInput
        disabled
        label="Nome do Cliente"
        md={6}
        value={vistoriaTecnica.nomeCliente}
      />
      <BtnGravar />
    </Row>
  );
};
