import React, { useState } from "react";
import {
  BotaoPesquisar,
  IntegerFormInput,
  PageContainer,
} from "../../../components";
import { Card, Row } from "reactstrap";
import CentralProjetosGrid from "./components/CentralProjetosGrid";

const CentralProjetos = () => {
  const [nroOS, setNroOS] = useState(null);

  return (
    <PageContainer title="Central de Projetos" number="0000" canGoBack>
      <Card body>
        <Row>
          <IntegerFormInput
            md={2}
            label="Nº O.S."
            value={nroOS}
            onChange={setNroOS}
          />
          <BotaoPesquisar />
        </Row>
      </Card>
      <CentralProjetosGrid />
    </PageContainer>
  );
};

export default CentralProjetos;
