import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  for_obrig_cpf: false,
  for_obrig_cnpj: false,
};

export const cadastroSlice = createSlice({
  name: "cadastro_slice",
  initialState: initialState,
  reducers: {
    setupCadastro: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setForObrigCpf: (state, action) => {
      state.for_obrig_cpf = action.payload;
    },
    setForObrigCnpj: (state, action) => {
      state.for_obrig_cnpj = action.payload;
    },
  },
});

export const { setupCadastro, setForObrigCpf, setForObrigCnpj } =
  cadastroSlice.actions;

export default cadastroSlice.reducer;
