import React from "react";
import { Table, TableCheck } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { Badge } from "reactstrap";

export const SupervisorGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "nome_colab_supervisor",
      text: "Supervisor",
      align: "left",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "responsabilidades",
      text: "Responsabilidades",
      align: "left",
      headerAlign: "center",
      formatter: (c) => (
        <>
          {c.map((e) => (
            <Badge
              color="gray"
              className="ml-1"
              style={{
                transform: "translateY(-1px)",
                backgroundColor: "#aaa",
                paddingTop: "4px",
              }}
            >
              {e}
            </Badge>
          ))}
        </>
      ),
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      formatter: (cell) => <TableCheck value={cell} />,
      sortable: true,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      clearSelectionOnUpdate
      onRowDoubleClick={alterar}
    />
  );
};
