import React from "react";
import { useState } from "react";
import { FormCheckbox, TextInput } from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { modalTitleGrupo, routesBaseGrupo } from "../Grupo";
import { ModalCadastroV2 } from "../../../../../components/cadastro";

export const CadastroGrupoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
  concatShortcut,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [nome, setNome] = useState("");
  const [destacaFcpConsFinal, setDestacaFcpConsFinal] = useState(false);

  const limparDados = () => {
    setAtivo(true);
    setNome("");
    setDestacaFcpConsFinal(false);
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setAtivo(data.ativo);
    setDestacaFcpConsFinal(data.destaca_fcp);
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
      destaca_fcp_cons_final: destacaFcpConsFinal,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ativo: ativo, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleGrupo}
      size="sm"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={
        action === MODAL_ACTIONS.EDIT && {
          value: ativo,
          toggle: () => setAtivo(!ativo),
        }
      }
      routesBase={routesBaseGrupo}
      number="0064_1"
      selected={selected}
      notifyEvent={notifyEvent}
      concatShortcut={concatShortcut}
      concatModelName="grupo"
    >
      <Row>
        <TextInput
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={40}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Destaca FCP se Consumidor Final"
          checked={destacaFcpConsFinal}
          onChange={() => setDestacaFcpConsFinal(!destacaFcpConsFinal)}
        />
      </Row>
    </ModalCadastroV2>
  );
};
