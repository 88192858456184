import React, { useState } from "react";
import {
  AsyncComboBox,
  DatePicker,
  FormCheckbox,
  ModalBase,
  NumberInput,
  TextInput,
} from "../../../../../components";
import {
  dateFromLocaleString,
  formatDateISO,
  MODAL_ACTIONS,
} from "../../../../../coreUtils";
import { Row } from "reactstrap";
import moment from "moment";
import GerencChequesClientesService from "../../../../../services/financeiro/GerencChequesClientesService";
import { showWarning } from "../../../../../components/AlertaModal";

export const IncluirAlterarChequeClienteModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [idBanco, setIdBanco] = useState(null);
  const [nroCheque, setNroCheque] = useState("");
  const [idCliente, setIdCliente] = useState(null);
  const [agencia, setAgencia] = useState("");
  const [nroConta, setNroConta] = useState("");
  const [dataEmi, setDataEmi] = useState(null);
  const [dataVc, setDataVc] = useState(null);
  const [cmc7, setCmc7] = useState("");
  const [chequeAvi, setChequeAvi] = useState(false);
  const [observ, setObserv] = useState("");
  const [valor, setValor] = useState(0);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setIdBanco(null);
    setNroCheque("");
    setIdCliente(null);
    setAgencia("");
    setNroConta("");
    setDataEmi(null);
    setDataVc(null);
    setCmc7("");
    setChequeAvi(false);
    setObserv("");
    setValor(0);
  };

  const onBeforeOpen = async () => {
    if (action === MODAL_ACTIONS.EDIT) {
      const [ok, ret] = await GerencChequesClientesService.buscar(selected);
      if (ok) {
        setIdBanco(ret.id_banco);
        setNroCheque(ret.nro_cheque);
        setIdCliente(ret.id_cliente);
        setAgencia(ret.agencia);
        setNroConta(ret.nro_conta ?? "");
        setDataEmi(dateFromLocaleString(ret.emissao));
        setDataVc(dateFromLocaleString(ret.bom_para));
        setCmc7(ret.cmc7 ?? "");
        setChequeAvi(ret.cheque_avi);
        setObserv(ret.observ ?? "");
        setValor(parseFloat(ret.valor));
      } else {
        toggle();
      }
    }
  };

  const handleSubmit = async () => {
    if (["", null, undefined].includes(nroCheque)) {
      showWarning(
        "Por favor, informe o Número do Cheque",
        null,
        null,
        "input-nro-cheque"
      );
      return false;
    }

    if ([0, null, undefined].includes(idBanco)) {
      showWarning(
        "Por favor, informe o Banco do Cheque",
        null,
        null,
        "async-banco-cheque"
      );
      return false;
    }

    if ([0, null, undefined].includes(idCliente)) {
      showWarning(
        "Por favor, informe o Cliente do Cheque",
        null,
        null,
        "async-cliente-cheque"
      );
      return false;
    }

    if (!(dataEmi instanceof Date)) {
      showWarning(
        "Por favor, informe a Data de Emissão do Cheque",
        null,
        null,
        "input-data-emi"
      );
      return false;
    }

    if (!(dataVc instanceof Date)) {
      showWarning(
        "Por favor, informe a Data de Vencimento (Bom Para) do Cheque",
        null,
        null,
        "input-data-venc"
      );
      return false;
    }

    if ([0, null, undefined].includes(valor)) {
      showWarning(
        "Por favor, informe o Valor do Cheque",
        null,
        null,
        "input-valor-cheque"
      );
      return false;
    }

    const payload = {
      id_banco: idBanco,
      nro_cheque: nroCheque,
      id_cliente: idCliente,
      agencia: agencia,
      nro_conta: nroConta,
      data_emi: formatDateISO(dataEmi),
      data_vc: formatDateISO(dataVc),
      cmc7: cmc7,
      cheque_avi: chequeAvi,
      observ: observ,
      valor: valor,
    };

    let ok;

    setLoading(true);
    if (action === MODAL_ACTIONS.EDIT) {
      [ok] = await GerencChequesClientesService.alterar({
        id: selected,
        ...payload,
      });
    } else {
      [ok] = await GerencChequesClientesService.incluir(payload);
    }

    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title={
        (action === MODAL_ACTIONS.EDIT ? "Alteração" : "Inclusão") +
        " de Cheque de Cliente"
      }
      number="0131_1"
      onClosed={limparDados}
      onConfirm={handleSubmit}
      onBeforeOpen={onBeforeOpen}
      loadingConfirm={loading}
    >
      <Row>
        <TextInput
          label="Nº Cheque"
          md={2}
          value={nroCheque}
          onChange={(e, v) => setNroCheque(v)}
          maxLength={8}
          name={"input-nro-cheque"}
        />
      </Row>
      <Row>
        <AsyncComboBox
          label="Banco"
          md={5}
          concatModelName="banco"
          defaultOptions
          defaultValue={idBanco}
          onChange={(s) => setIdBanco(s?.value)}
          inputName={"async-banco-cheque"}
        />
        <TextInput
          label="Agência"
          md={2}
          value={agencia}
          onChange={(e, v) => setAgencia(v)}
          maxLength={5}
        />
        <TextInput
          label="Número da Conta"
          md={3}
          value={nroConta}
          onChange={(e, v) => setNroConta(v)}
          maxLength={20}
        />
      </Row>
      <Row>
        <AsyncComboBox
          label="Cliente"
          md={8}
          concatModelName="cliente"
          defaultValue={idCliente}
          onChange={(s) => setIdCliente(s?.value)}
          inputName={"async-cliente-cheque"}
        />
      </Row>
      <Row>
        <FormCheckbox
          label="Cheque À Vista"
          checked={chequeAvi}
          onChange={() => setChequeAvi(!chequeAvi)}
          name="a-vista"
        />
        <TextInput
          label="CMC7"
          md={6}
          value={cmc7}
          onChange={(e, v) => setCmc7(v)}
          maxLength={35}
        />
      </Row>
      <Row>
        <TextInput
          label="Observação"
          md={12}
          value={observ}
          onChange={(e, v) => setObserv(v)}
          maxLength={150}
          colClassName="pr-0"
        />
      </Row>
      <Row className="mb-3">
        <DatePicker
          label="Emissão"
          md={3}
          value={dataEmi}
          onChange={(v) => setDataEmi(moment.isMoment(v) ? v.toDate() : v)}
          name={"input-data-emi"}
        />
        <DatePicker
          label="Bom Para"
          md={3}
          value={dataVc}
          onChange={(v) => setDataVc(moment.isMoment(v) ? v.toDate() : v)}
          name={"input-data-venc"}
        />
        <NumberInput
          label="Valor do Cheque"
          md={3}
          value={valor}
          onChange={setValor}
          name={"input-valor-cheque"}
        />
      </Row>
    </ModalBase>
  );
};
