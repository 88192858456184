import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroCidadeModal } from "./components/CadastroCidadeModal";
import { CidadeGrid } from "./components/CidadeGrid";
import {
  MODAL_ACTIONS,
  defaultDebounceTime,
  useDebounce,
} from "../../../../coreUtils";
import {
  BotaoConfirmarVoltar,
  BotaoPesquisar,
  PageContainer,
  SearchInput,
} from "../../../../components";
import CadastroCidadeService from "../../../../services/cadastro/CadastroCidadeService";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";

export const modalTitleCidade = "Cidade";
export const routesBaseCidade = "/cadastro/cidade";

export const Cidade = () => {
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async (p) => {
    const [ok, ret] = await CadastroCidadeService.listar({ pista: p });

    return ok ? ret : [];
  };

  const carregarDados = async () => {
    setLoading(true);
    setDados(await buscarDados(pista));
    setLoading(false);
  };

  const handlePista = useDebounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    setDados(await buscarDados(v));
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Cidades" number="0060" canGoBack>
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroCidadeModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleCidade}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0060_2"
            selected={selected}
            routeBase={routesBaseCidade}
            notifyEvent={notifyEvent}
          />
          <BotaoConfirmarVoltar
            selected={selected}
            carregarDados={carregarDados}
          />
        </Row>
      </Card>
      <Card body>
        <CidadeGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
