import React, { useEffect, useMemo, useState } from "react";
import { ModalBase } from "../ModalBase";
import { Row } from "reactstrap";
import SearchInput from "../SearchInput";
import AsyncComboBox from "../AsyncComboBox";
import { BotaoPesquisar } from "../BotaoPesquisar";
import { apiGetV2, useSingleRequest } from "../../apiV2";
import { PesqKitProdutoGrid } from "./PesqKitProdutoGrid";
import { showInfo } from "../AlertaModal";
import { defaultDebounceTime, useDebounce } from "../../coreUtils";
import NumberInput from "../NumberInput";

const PesqKitProdutoModal = ({ isOpen, toggle, onConfirm }) => {
  // Controle
  const singleRequestWrapper = useSingleRequest();
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingPesq, setLoadingPesq] = useState(false);

  // Filtros
  const [idProduto, setIdProduto] = useState();

  // Dados
  const [kits, setKits] = useState([]);
  const [kitSelecionado, setKitSelecionado] = useState({});
  const [quantidadeKits, setQuantidadeKits] = useState(1);

  useEffect(() => {
    if (!isOpen) {
      setKitSelecionado({});
      setQuantidadeKits(1);
    }
  }, [isOpen]);

  const handlePista = useDebounce(async (v) => {
    await carregarPesquisa(v);
  }, defaultDebounceTime);

  const carregarPesquisa = async (pista) => {
    setLoadingPesq(true);
    try {
      const params = {
        pista: pista,
        id_produto: idProduto,
      };

      const [ok, ret] = await singleRequestWrapper((options) =>
        apiGetV2("cadastro/kit/pesquisar/", params, options)
      );
      if (ok) {
        setKits(ret);
      }
    } finally {
      setLoadingPesq(false);
    }
  };

  const _onConfirmInternal = async () => {
    setLoadingConfirm(true);
    try {
      if (!kitSelecionado) {
        showInfo("Por favor, selecione um kit da lista e tente novamente");
        return;
      }

      if (!quantidadeKits) {
        showInfo("Por favor, informe quantos kits serão incluídos");
        return;
      }

      const payload = kitSelecionado.itens.map((item) => {
        const ret = {
          idKit: kitSelecionado.id,
          idProduto: item.id_produto,
          nomeProduto: item.nome_produto,
          quantidade: parseFloat(item.qtd_prod),
          vlrUnit: parseFloat(item.vlr_unit),
          vlrItem: parseFloat(item.vlr_item),
          percDesc: parseFloat(item.perc_desc),
          vlrTotDesc: parseFloat(item.vlr_desc),
          vlrTotal: parseFloat(item.vlr_total),
          casasDecimaisVlrs: item.casas_decimais_vlrs,
          casasDecimaisQtd: item.casas_decimais_qtd,
          cfop: item.cfop_produto,
        };

        if (quantidadeKits > 1) {
          const qtdeIncluir = parseFloat(item.qtd_prod) * quantidadeKits;

          ret.quantidade = qtdeIncluir;
          ret.vlrTotal = qtdeIncluir * parseFloat(item.vlr_item);
          ret.vlr_desc = qtdeIncluir * parseFloat(item.vlr_desc);
        }

        return ret;
      });

      if (await onConfirm(payload)) {
        toggle();
      }
    } finally {
      setLoadingConfirm(false);
    }
  };

  const totalKits = useMemo(() => {
    return parseFloat(kitSelecionado?.vlr_total ?? 0) * (quantidadeKits ?? 0);
  }, [kitSelecionado, quantidadeKits]);

  return (
    <ModalBase
      title="Pesquisa de Kit de Produtos"
      number="PesqKit"
      isOpen={isOpen}
      toggle={toggle}
      onOpened={carregarPesquisa}
      onConfirm={_onConfirmInternal}
      onCancel={toggle}
      loadingConfirm={loadingConfirm}
      size="xl"
      paddedButtons
      numberStyle={{ marginRight: "7px" }}
      footerActions={
        <Row className="mr-auto">
          <NumberInput
            label="Quantide de Kits"
            value={quantidadeKits}
            onChange={setQuantidadeKits}
            md="auto"
          />
          <NumberInput label="Total" value={totalKits} md="auto" disabled />
        </Row>
      }
    >
      <Row>
        <SearchInput
          md={5}
          onChange={handlePista}
          loading={loadingPesq}
          placeholder="Pesquise pelo nome do kit"
        />
        <AsyncComboBox
          md={3}
          label="Produto"
          concatModelName="produto"
          defaultValue={idProduto}
          onChange={(s) => setIdProduto(s?.value)}
          hideShortcut
        />
        <BotaoPesquisar onClick={carregarPesquisa} loading={loadingPesq} />
      </Row>
      <PesqKitProdutoGrid
        data={kits}
        setSelected={(_, __, row) => {
          setKitSelecionado(row);
        }}
      />
    </ModalBase>
  );
};

export default PesqKitProdutoModal;
