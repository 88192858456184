import React, { useState } from "react";
import {
  FixedField,
  ModalBase,
  NumberInput,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import CaixaLojaService from "../../../../../services/financeiro/CaixaLojaService";

export const SangriaCaixaModal = ({
  isOpen,
  toggle,
  idCaixa,
  saldoDinheiro,
  nomeOperador,
  mostraSaldoFechamento,
}) => {
  const [vlrSangria, setVlrSangria] = useState(0);
  const [historico, setHistorico] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setVlrSangria(0);
    setHistorico("");
  };

  const _internalToggle = () => {
    if (isOpen) {
      limparDados();
    }
    toggle();
  };

  const handleSubmit = async () => {
    setLoading(true);

    const payload = {
      nro_caixa: idCaixa,
      historico: historico,
      valor: vlrSangria,
    };

    const [ok, ret] = await CaixaLojaService.sangria(payload);
    if (ok) {
      await CaixaLojaService.imprimirSangria(ret.id_mov_cxa_loja);
      _internalToggle();
    }
    setLoading(false);
  };

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={_internalToggle}
        size="md"
        title="Sangria de Caixa"
        number="0063_7"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row className="mb-2">
          <FixedField horizontal label="Nº Caixa" value={idCaixa} />
          <FixedField horizontal label="Operador" value={nomeOperador} />
          {mostraSaldoFechamento && (
            <FixedField
              horizontal
              label="Saldo em Dinheiro"
              value={saldoDinheiro}
            />
          )}
        </Row>
        <Row>
          <NumberInput
            md={4}
            label="Valor de Sangria"
            value={vlrSangria}
            onChange={setVlrSangria}
          />
        </Row>
        <Row>
          <TextInput
            md={12}
            label="Motivo"
            value={historico}
            onChange={(e, v) => setHistorico(v)}
            maxLength={200}
          />
        </Row>
      </ModalBase>
    </>
  );
};
