import React, { useMemo } from "react";
import {
  Divider,
  MenuGroup,
  MenuGroupButton,
  PageContainer,
} from "../../../components";
import { RiFolderReceivedLine } from "react-icons/ri";
import { BiBarcodeReader } from "react-icons/bi";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaSackDollar } from "react-icons/fa6";
import { Send } from "react-feather";
import {
  gerenciamentoBancRoute,
  gerenciamentoCobrancaBancRoute,
  gerenciamentoCobrancaSkillRoute,
  impressaoBoletosRoute,
  remessaBancariaRoute,
  retornoCobrancaBancariaRoute,
} from "../../../routes/modules/financeiro";
import { GiWallet } from "react-icons/gi";

export const CobrancaBanc = () => {
  const licenca = useMemo(() => parseInt(localStorage.getItem("licenca")), []);

  return (
    <PageContainer title="Gerenciamento">
      <Divider className="pl-3 pr-5">Gerenciamento Bancário</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Gerenciamento Bancário"
          icon={GiWallet}
          pathname={gerenciamentoBancRoute.path}
          nroTela={gerenciamentoBancRoute.nroTela}
        />
      </MenuGroup>
      <Divider className="pl-3 pr-5">Cobrança Bancária via API</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Gerenciamento Cobrança Bancária"
          icon={FaFileInvoiceDollar}
          pathname={gerenciamentoCobrancaBancRoute.path}
          nroTela={gerenciamentoCobrancaBancRoute.nroTela}
        />
      </MenuGroup>
      <Divider className="pl-3 pr-5">Cobrança Bancária via CNAB</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Impressão de Boletos"
          icon={BiBarcodeReader}
          pathname={impressaoBoletosRoute.path}
          nroTela={impressaoBoletosRoute.nroTela}
        />
        <MenuGroupButton
          label="Remessa Bancária"
          icon={Send}
          pathname={remessaBancariaRoute.path}
          nroTela={remessaBancariaRoute.nroTela}
        />
        <MenuGroupButton
          label="Retorno Bancário"
          icon={RiFolderReceivedLine}
          pathname={retornoCobrancaBancariaRoute.path}
          nroTela={retornoCobrancaBancariaRoute.nroTela}
        />
        {licenca === 0 && (
          <MenuGroupButton
            label="Gerenciamento Cobrança Skill"
            icon={FaSackDollar}
            pathname={gerenciamentoCobrancaSkillRoute.path}
            nroTela={gerenciamentoCobrancaSkillRoute.nroTela}
          />
        )}
      </MenuGroup>
    </PageContainer>
  );
};
