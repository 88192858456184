import React, { useState } from "react";
import { IconButton, ModalBase, Table } from "../../../../components";
import { Row } from "reactstrap";
import NotaFiscalService from "../../../../services/docs_eletron/NotaFiscalService";
import { EmitirCartaCorrecaoModal } from "./EmitirCartaCorrecaoModal";
import { BsDownload, BsPrinter } from "react-icons/bs";

const columns = [
  { dataField: "seq_evento", text: "Evento", align: "center" },
  { dataField: "nro_protocolo", text: "Protocolo", align: "center" },
  { dataField: "descricao", text: "Descrição", align: "left", colWidth: "65%" },

  {
    dataField: "print",
    text: "",
    align: "center",
    formatter: (_, row) => (
      <IconButton
        icon={BsPrinter}
        onClick={() => NotaFiscalService.cartaCorrecao.imprimir(row.id)}
        hint="Imprimir Carta de Correção"
        tooltipPlacement="left"
      />
    ),
  },
  {
    dataField: "download",
    text: "",
    align: "center",
    formatter: (_, row) => (
      <IconButton
        icon={BsDownload}
        onClick={() => NotaFiscalService.cartaCorrecao.downloadXML(row.id)}
        hint="Download XML Carta de Correção"
        tooltipPlacement="left"
      />
    ),
  },
];

export const CartaCorrecaoNfModal = ({ isOpen, toggle, selected }) => {
  const [dados, setDados] = useState([]);

  const limparDados = () => setDados([]);

  const carregarDados = async () => {
    const [ok, ret] = await NotaFiscalService.cartaCorrecao.listar(selected);
    setDados(ok ? ret : []);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Carta de Correção da Nota Fiscal"
      number="0055_5"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
    >
      <Row className="mb-2">
        <EmitirCartaCorrecaoModal idNf={selected} notifyEvent={carregarDados} />
      </Row>
      <Table data={dados} columns={columns} pageSize={5} paginated={false} />
    </ModalBase>
  );
};
