import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { AiOutlineFieldTime } from "react-icons/ai";
import {
  BsPersonLinesFill,
  BsPersonFillGear,
  BsClipboardData,
} from "react-icons/bs";
import {
  relHistoricoAnaliticoOsRoute,
  relOsHorasClienteRoute,
  relOsHorasVendidasTrabalhadasRoute,
  relOsRendimentoTecnicoRoute,
  relOsValorHorasTecnicoRoute,
  relRelacaoOsGeralRoute,
} from "../../../routes/modules/relatorios";
import { HiDocumentChartBar } from "react-icons/hi2";

export const OrdemServico = () => {
  return (
    <PageContainer title="Relatórios - Ordens de Serviço">
      <MenuGroup>
        <MenuGroupButton
          label="Horas por Cliente"
          icon={BsPersonLinesFill}
          pathname={relOsHorasClienteRoute.path}
          nroTela={relOsHorasClienteRoute.nroTela}
        />
        <MenuGroupButton
          label="Horas Vendidas X Trabalhadas"
          icon={AiOutlineFieldTime}
          pathname={relOsHorasVendidasTrabalhadasRoute.path}
          nroTela={relOsHorasVendidasTrabalhadasRoute.nroTela}
        />
        <MenuGroupButton
          label="Rendimento de Trabalho por Técnico"
          icon={BsPersonFillGear}
          pathname={relOsRendimentoTecnicoRoute.path}
          nroTela={relOsRendimentoTecnicoRoute.nroTela}
        />
        <MenuGroupButton
          label="Valor de Horas por Técnico"
          icon={BsPersonFillGear}
          pathname={relOsValorHorasTecnicoRoute.path}
          nroTela={relOsValorHorasTecnicoRoute.nroTela}
        />
      </MenuGroup>
      <MenuGroup>
        <MenuGroupButton
          label="Histórico Analítico de O.S."
          icon={BsClipboardData}
          pathname={relHistoricoAnaliticoOsRoute.path}
          nroTela={relHistoricoAnaliticoOsRoute.nroTela}
        />
        <MenuGroupButton
          label="Relação O.S. Geral"
          icon={HiDocumentChartBar}
          pathname={relRelacaoOsGeralRoute.path}
          nroTela={relRelacaoOsGeralRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
