import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import {
  init,
  setAtivo,
  setCBenef,
  setCfopNf,
  setCobrarNaFatura,
  setCodigo,
  setCstsIcms,
  setDescricao,
  setMensagAdicNfe,
  setup,
} from "../store/cadCfopSlice";
import {
  Divider,
  FormButton,
  FormCheckbox,
  Loader,
  PageContainer,
  TextInput,
} from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { TabICMSCfop } from "./components/TabICMSCfop";
import { TabICMSSTCfop } from "./components/TabICMSSTCfop";
import { TabIPICfop } from "./components/TabIPICfop";
import { TabPisCofinsCfop } from "./components/TabPisCofinsCfop";
import { TabDesonerICMSCfop } from "./components/TabDesonerICMSCfop";
import { TabDiferICMSCfop } from "./components/TabDiferICMSCfop";
import { TabCredICMSCfop } from "./components/TabCredICMSCfop";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CadastroCfopService from "../../../../../services/cadastro/CadastroCfopService";
import store from "../store";
import { cadastroCfopRoute } from "../../../../../routes/modules/cadastro";
import { TabICMSNFCeCfop } from "./components/TabICMSNFCeCfop";
import TributacaoService from "../../../../../services/cadastro/TributacaoService";
import { showWarning } from "../../../../../components/AlertaModal";
import { TabIcmsMonoCfop } from "./components/TabIcmsMonoCfop";

export const ContainerIncluirAlterarCfop = forwardRef(({ location }, ref) => {
  const opener = window.opener;
  const history = useHistory();
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const windowParameters = window?.parameters
    ? JSON.parse(window?.parameters)
    : {};

  const action =
    location?.state?.action ?? windowParameters.action ?? MODAL_ACTIONS.ADD;

  const selected = location?.state?.selected ?? windowParameters.selected;

  const store = useSelector((state) => state.cadCfop);
  const dispatch = useDispatch();

  const limparDados = () => {
    dispatch(init());
  };

  const iniciarTela = async () => {
    const [ok, ret] = await TributacaoService.listarCsts();

    if (!ok) return false;

    dispatch(setCstsIcms(ret));

    if (action === MODAL_ACTIONS.EDIT) {
      await carregarDados();
    }
    setLoading(false);
    return true;
  };

  const carregarDados = async () => {
    const [ok, res] = await CadastroCfopService.buscar(selected);

    if (!ok) return false;

    const data = {
      codigo: res.codigo,
      descricao: res.descricao,
      cfopNf: res.cfop_nf,
      sitTribIcms: res.sit_trib_icms,
      modBcIcms: res.mod_bc_icms,
      percIcms: parseFloat(res.perc_icms),
      tipoRedBaseIcms: res.tipo_red_base_icms,
      percRedBaseIcmsEm: parseFloat(res.perc_red_base_icms_em),
      percRedBaseIcmsPara: parseFloat(res.perc_red_base_icms_para),
      sitTribIcmsNfce: res.sit_trib_icms_nfce,
      modBcIcmsNfce: res.mod_bc_icms_nfce,
      percIcmsNfce: parseFloat(res.perc_icms_nfce),
      percRedBaseIcmsNfce: parseFloat(res.perc_red_base_icms_nfce),
      modBcIcmsSt: res.mod_bc_icms_st ?? "",
      margValorAdicIcmsSt: parseFloat(res.marg_valor_adic_icms_st),
      percIcmsSt: parseFloat(res.perc_icms_st),
      percReduIcmsSt: parseFloat(res.perc_redu_icms_st),
      sitTribIpi: (res.sit_trib_ipi ?? "").trim(),
      percIpi: parseFloat(res.perc_ipi),
      enquadIpi: res.enquad_ipi,
      somaVlrIpiBcIcmsSt: res.soma_vlr_ipi_bc_icms_st,
      sitTribPis: res.sit_trib_pis,
      percPis: parseFloat(res.perc_pis),
      percRetenPis: parseFloat(res.perc_reten_pis),
      sitTribCofins: res.sit_trib_cofins,
      percCofins: parseFloat(res.perc_cofins),
      percRetenCofins: parseFloat(res.perc_reten_cofins),
      geraCredIcms: res.gera_cred_icms,
      aliqCredIcms: parseFloat(res.aliq_cred_icms),
      percDesonerIcms: parseFloat(res.perc_desoner_icms),
      motivoDesonerIcms: res.motivo_desoner_icms ?? "",
      percRetenCsll: parseFloat(res.perc_reten_csll),
      percRetenIrpj: parseFloat(res.perc_reten_irpj),
      cobrarNaFatura: res.cobrar_na_fatura,
      mensagAdicNfe: res.mensag_adic_nfe ?? "",
      percIcmsDifer: parseFloat(res.perc_icms_difer),
      deducVlrIcmsBcPisCofins: res.deduc_vlr_icms_bc_pis_cofins,
      cBenef: res.c_benef ?? "",
      somaVlrIpiBcIcms: res.soma_vlr_ipi_bc_icms,
      somaVlrOutrosBcIcms: res.soma_vlr_outros_bc_icms,
      somaVlrFreteBcIpi: res.soma_vlr_frete_bc_ipi,
      somaVlrFreteBcIcms: res.soma_vlr_frete_bc_icms,
      aliqAdRem: parseFloat(res.aliq_ad_rem),
      ativo: res.ativo,
    };
    dispatch(setup(data));
  };

  const handleSubmit = async () => {
    if (["", null, undefined].includes(store.codigo)) {
      showWarning(
        "Por favor, informe o código da CFOP",
        null,
        null,
        "input-cod-cfop"
      );
      return;
    }

    if (["", null, undefined].includes(store.descricao)) {
      showWarning(
        "Por favor, informe a descrição da CFOP",
        null,
        null,
        "input-desc-cfop"
      );
      return;
    }

    if (["", null, undefined].includes(store.cfopNf)) {
      showWarning(
        "Por favor, informe o código da CFOP que será considerado na nota fiscal",
        null,
        null,
        "input-cod-cfop-nf"
      );
      return;
    }

    if (
      ["", null, undefined].includes(store.cfopNf) ||
      store.cfopNf.length !== 4
    ) {
      showWarning(
        "O código da CFOP da nota fiscal deve ser composto por 4 números",
        null,
        null,
        "input-cod-cfop-nf"
      );
      return;
    }

    if (
      store.geraCredIcms &&
      [0, null, undefined].includes(store.aliqCredIcms)
    ) {
      showWarning(
        "Você identificou que esta CFOP gera crédito de ICMS. " +
          "Por Favor, Informe a alíquota correspondente",
        null,
        null,
        "input-cred-icms"
      );
      return;
    }

    const payload = {
      codigo: store.codigo,
      descricao: store.descricao,
      cfop_nf: store.cfopNf,
      sit_trib_icms: store.sitTribIcms,
      mod_bc_icms: store.modBcIcms,
      perc_icms: store.percIcms,
      tipo_red_base_icms: store.tipoRedBaseIcms,
      perc_red_base_icms_em: store.percRedBaseIcmsEm,
      perc_red_base_icms_para: store.percRedBaseIcmsPara,
      sit_trib_icms_nfce: store.sitTribIcmsNfce,
      mod_bc_icms_nfce: store.modBcIcmsNfce,
      perc_icms_nfce: store.percIcmsNfce,
      perc_red_base_icms_nfce: store.percRedBaseIcmsNfce,
      mod_bc_icms_st: store.modBcIcmsSt,
      marg_valor_adic_icms_st: store.margValorAdicIcmsSt,
      perc_icms_st: store.percIcmsSt,
      perc_redu_icms_st: store.percReduIcmsSt,
      sit_trib_ipi: store.sitTribIpi,
      perc_ipi: store.percIpi,
      enquad_ipi: store.enquadIpi,
      soma_vlr_ipi_bc_icms_st: store.somaVlrIpiBcIcmsSt,
      sit_trib_pis: store.sitTribPis,
      perc_pis: store.percPis,
      perc_reten_pis: store.percRetenPis,
      sit_trib_cofins: store.sitTribCofins,
      perc_cofins: store.percCofins,
      perc_reten_cofins: store.percRetenCofins,
      gera_cred_icms: store.geraCredIcms,
      aliq_cred_icms: store.aliqCredIcms,
      perc_desoner_icms: store.percDesonerIcms,
      motivo_desoner_icms: store.motivoDesonerIcms,
      perc_reten_csll: store.percRetenCsll,
      perc_reten_irpj: store.percRetenIrpj,
      cobrar_na_fatura: store.cobrarNaFatura,
      mensag_adic_nfe: store.mensagAdicNfe,
      perc_icms_difer: store.percIcmsDifer,
      deduc_vlr_icms_bc_pis_cofins: store.deducVlrIcmsBcPisCofins,
      c_benef: store.cBenef,
      soma_vlr_ipi_bc_icms: store.somaVlrIpiBcIcms,
      soma_vlr_outros_bc_icms: store.somaVlrOutrosBcIcms,
      soma_vlr_frete_bc_ipi: store.somaVlrFreteBcIpi,
      soma_vlr_frete_bc_icms: store.somaVlrFreteBcIcms,
      aliq_ad_rem: store.aliqAdRem,
      ativo: store.ativo,
    };
    setLoadingSubmit(true);

    const submitFunc =
      action === MODAL_ACTIONS.ADD
        ? CadastroCfopService.incluir
        : CadastroCfopService.alterar;

    const [ok, ret] = await submitFunc(payload);

    // se não houve erro no POST/PUT
    if (ok) {
      // se a janela foi aberta pelo ConcatShortcut
      if (opener !== null && history.length === 1) {
        // se inclusão, passa o id incluído. Se alteração, passa o selecionado
        const idRet =
          action === MODAL_ACTIONS.ADD ? ret.id_cadastrado : selected;

        opener.postMessage(
          { id: JSON.parse(window.parameters).id, selected: idRet },
          window.location.origin
        );
        window.close();
      } else {
        // se não, volta para a tela anterior (Cadastro de CFOP)
        setRedirect(true);
      }
    }

    setLoadingSubmit(false);
  };

  const onKeyDown = (e) => {
    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (e.key === "F9") handleSubmit();
    }
  };

  useImperativeHandle(ref, () => ({
    onKeyDown: onKeyDown,
  }));

  const onActivateExit = () => {
    iniciarTela();
    return () => limparDados();
  };

  useEffect(onActivateExit, []);

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: cadastroCfopRoute.path,
          state: { refresh: true },
        }}
      />
    );
  }

  return loading ? (
    <Loader />
  ) : (
    <>
      <Card body>
        <Row>
          <TextInput
            md={2}
            label="Código da CFOP"
            value={store.codigo}
            onChange={(e, v) => dispatch(setCodigo(v))}
            disabled={action === MODAL_ACTIONS.EDIT}
            maxLength={6}
            name={"input-cod-cfop"}
          />
          <TextInput
            md={8}
            label="Descrição"
            value={store.descricao}
            onChange={(e, v) => dispatch(setDescricao(v))}
            maxLength={60}
            name={"input-desc-cfop"}
          />
          <FormCheckbox
            label="Ativo"
            md={2}
            onChange={() => dispatch(setAtivo(!store.ativo))}
            checked={store.ativo}
          />
        </Row>
        <Row>
          <TextInput
            md={2}
            label="Código da CFOP na NF"
            value={store.cfopNf}
            onChange={(e, v) => dispatch(setCfopNf(v))}
            maxLength={4}
            name={"input-cod-cfop-nf"}
          />
        </Row>
      </Card>
      <Card body>
        <Row>
          <Col md={6}>
            <TabICMSCfop />
            <TabDesonerICMSCfop />
            <TabIcmsMonoCfop />
            <TabICMSNFCeCfop />
            <TabIPICfop />
            <Divider className="mt-4 mb-0">
              Retorno de beneficiamento/industrialização
            </Divider>
            <Row>
              <FormCheckbox
                padded={false}
                label="Cobrar, nas faturas da NF, valores dos produtos identificados"
                md="auto"
                onChange={() =>
                  dispatch(setCobrarNaFatura(!store.cobrarNaFatura))
                }
                checked={store.cobrarNaFatura}
              />
            </Row>
            <Row className="mt-3">
              <TextInput
                label="Informações Complementares de Interesse do Contribuinte"
                type="textarea"
                md={12}
                onChange={(e, v) => dispatch(setMensagAdicNfe(v))}
                value={store.mensagAdicNfe}
                rows={3}
                colClassName="pr-0"
              />
            </Row>
          </Col>
          <Col md={6}>
            <TabICMSSTCfop />
            <TabCredICMSCfop />
            <TabPisCofinsCfop />
            <Divider className="mt-4">Benefício Fiscal</Divider>
            <Row>
              <TextInput
                md={4}
                label="Código (cBenef)"
                value={store.cBenef}
                onChange={(e, v) => dispatch(setCBenef(v))}
                maxLength={15}
                divClassName="pr-0"
              />
            </Row>
            <TabDiferICMSCfop />
          </Col>
        </Row>
      </Card>
      <Card body>
        <Row>
          <FormButton
            divClassName="ml-auto"
            md="auto"
            color="success"
            onClick={handleSubmit}
            loading={loadingSubmit}
            padded={false}
          >
            F9 - Confirmar
          </FormButton>
          <FormButton
            md="auto"
            color="danger"
            onClick={() => history.goBack()}
            padded={false}
          >
            Esc - Sair
          </FormButton>
        </Row>
      </Card>
    </>
  );
});

export const IncluirAlterarCfop = ({ location }) => {
  const windowParameters = window?.parameters
    ? JSON.parse(window?.parameters)
    : {};
  const action =
    location?.state?.action ?? windowParameters.action ?? MODAL_ACTIONS.ADD;

  const refContainer = useRef();

  return (
    <PageContainer
      title={
        (action === MODAL_ACTIONS.ADD ? "Inclusão" : "Alteração") + " de CFOP"
      }
      number="0119_1"
      onKeyDown={(e) => {
        if (refContainer.current) {
          refContainer.current.onKeyDown(e);
        }
      }}
      canGoBack
    >
      <Provider store={store}>
        <ContainerIncluirAlterarCfop location={location} ref={refContainer} />
      </Provider>
    </PageContainer>
  );
};
