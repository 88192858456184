import React, { useEffect } from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { GerencCredRotativoGrid } from "./components/GerencCredRotativoGrid";
import CredRotativoService from "../../../../services/financeiro/CredRotativoService";
import { AbrirContaCredRotativoModal } from "./components/AbrirContaCredRotativoModal";
import { DetalhesContaModal } from "./components/DetalhesContaModal";
import { AlterarLimiteCredModal } from "./components/AlterarLimiteCredModal";
import { LancamentoManualCreditoModal } from "./components/LancamentoManualCreditoModal";

export const GerencCredRotativo = () => {
  const [msgTotal, setMsgTotal] = useState("");
  const [idCliente, setIdCliente] = useState(null);
  const [nomeCliente, setNomeCliente] = useState("");
  const [somenteComSaldoDevedor, setSomenteComSaldoDevedor] = useState(false);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const [alterarLimiteCredOpen, setAlterarLimiteCredOpen] = useState(false);
  const [lancamentoManualOpen, setLancamentoManualOpen] = useState(false);
  const [detalhesOpen, setDetalhesOpen] = useState(false);

  const carregarDados = async () => {
    setLoading(true);
    try {
      const params = {
        id_cliente: idCliente,
        somente_com_saldo_devedor: somenteComSaldoDevedor,
      };

      const [ok, ret] = await CredRotativoService.gerenciamento.listarContas(
        params
      );
      if (ok) {
        setDados(ret.contas);
        setMsgTotal(
          `Exibindo primeiras ${ret.contas.length} contas de rotativo de ${ret.qtd_total_contas} abertas`
        );
      } else {
        setDados([]);
        setMsgTotal("");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (v) => {
    setSelected(v);
  };

  const toggleAlterarLimiteCred = () => {
    setAlterarLimiteCredOpen(!alterarLimiteCredOpen);
  };

  const toggleLancamentoManual = () => {
    setLancamentoManualOpen(!lancamentoManualOpen);
  };

  const toggleDetalhes = () => {
    setDetalhesOpen(!detalhesOpen);
  };

  const alterarLimiteCred = (idCli) => {
    setSelected(idCli);
    toggleAlterarLimiteCred();
  };

  const lancamentoManual = (idCli) => {
    setSelected(idCli);
    toggleLancamentoManual();
  };

  const abrirDetalhesConta = (idCli) => {
    setSelected(idCli);
    toggleDetalhes();
  };

  const excluirConta = async (idCli) => {
    const [ok] = await CredRotativoService.gerenciamento.excluirConta(idCli);
    if (ok) {
      carregarDados();
    }
  };

  const onChangeSelected = () => {
    if (selected) {
      setNomeCliente(
        dados.find((e) => e.id_cliente === selected)?.nome_cliente ?? ""
      );
    }
  };

  useEffect(onChangeSelected, [selected]);

  return (
    <PageContainer title="Gerenciamento de Crédito Rotativo" number="0068">
      <Card body>
        <Row>
          <AsyncComboBox
            md={5}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdCliente(s?.value)}
          />
          <FormCheckbox
            label="Somente Clientes com Saldo Devedor"
            checked={somenteComSaldoDevedor}
            onChange={() => setSomenteComSaldoDevedor(!somenteComSaldoDevedor)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
        </Row>
        <Row>
          <AbrirContaCredRotativoModal notifyEvent={carregarDados} />
        </Row>
      </Card>
      <Card body>
        <GerencCredRotativoGrid
          dados={dados}
          onSelect={handleSelect}
          alterarLimiteCred={alterarLimiteCred}
          lancamentoManualCredito={lancamentoManual}
          abrirDetalhesConta={abrirDetalhesConta}
          msgTotal={msgTotal}
          excluirConta={excluirConta}
        />
        <AlterarLimiteCredModal
          isOpen={alterarLimiteCredOpen}
          toggle={toggleAlterarLimiteCred}
          notifyEvent={carregarDados}
          selected={selected}
          nomeCliente={nomeCliente}
        />
        <LancamentoManualCreditoModal
          isOpen={lancamentoManualOpen}
          toggle={toggleLancamentoManual}
          notifyEvent={carregarDados}
          selected={selected}
          nomeCliente={nomeCliente}
        />
        <DetalhesContaModal
          isOpen={detalhesOpen}
          toggle={toggleDetalhes}
          notifyEvent={carregarDados}
          selected={selected}
          nomeCliente={nomeCliente}
        />
      </Card>
    </PageContainer>
  );
};
