import React from "react";
import { Card, Row } from "reactstrap";
import {
  FixedField,
  FormButton,
  FormCheckbox,
  IconButton,
  PageContainer,
} from "../../../../components";
import { OrcamentosOSGrid } from "./components/OrcamentosOSGrid";
import { ItensOrcamentoOSGrid } from "./components/ItensOrcamentoOSGrid";
import { useState } from "react";
import { BiCopy } from "react-icons/bi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  centralOSRoute,
  incluirAlterarOrcamentoOSRoute,
} from "../../../../routes/modules/ordem_servico";
import {
  MODAL_ACTIONS,
  formatValueFromAPI,
  roundFloat,
} from "../../../../coreUtils";
import { toastr } from "react-redux-toastr";
import { useEffect } from "react";
import OrcamentosService from "../../../../services/orcamentos/OrcamentosService";
import { Redirect } from "react-router-dom";

export const OrcamentosOS = ({ location }) => {
  const idOs = location?.state?.idOs;

  const [placa, setPlaca] = useState("");
  const [idCliente, setIdCliente] = useState(0);
  const [nomeCliente, setNomeCliente] = useState("");
  const [fone1Cliente, setFone1Cliente] = useState("");
  const [whatsCliente, setWhatsCliente] = useState("");
  const [status, setStatus] = useState("");
  const [descStatus, setDescStatus] = useState("");
  const [emissao, setEmissao] = useState("");
  const [idVeiculo, setIdVeiculo] = useState(0);
  const [nomeEquipamento, setNomeEquipamento] = useState("");
  const [modeloVeic, setModeloVeic] = useState("");
  const [veiculo, setVeiculo] = useState("");
  const [anoVeic, setAnoVeic] = useState("");
  const [chassiVeic, setChassiVeic] = useState("");
  const [loadingFetch, setLoadingFetch] = useState(true);
  const [loadingConfirmarOrc, setLoadingConfirmarOrc] = useState(false);
  const [somenteSituAberta, setSomenteSituAberta] = useState(true);
  const [orcamentos, setOrcamentos] = useState([]);
  const [itens, setItens] = useState([]);
  const [selected, setSelected] = useState(null);
  const [situacaoSelected, setSituacaoSelected] = useState(null);
  const [redirect, setRedirect] = useState(false);

  // Parâmetros
  const [sistAutomotivo, setSistAutomotivo] = useState(false);
  const [identificaEquipamento, setIdentificaEquipamento] = useState(false);

  const history = useHistory();

  const carregarParametros = async () => {
    const [ok, ret] = await OrcamentosService.buscarParametros();

    if (ok) {
      setSistAutomotivo(ret.tipo_sist === "MECANICA");
      setIdentificaEquipamento(ret.abrir_os_identifica_equipamento);
    }
  };

  const carregarDados = async () => {
    const [ok, ret] = await OrcamentosService.buscarOrcamentosOS(
      idOs,
      somenteSituAberta
    );

    if (ok) {
      const os = ret.os;
      setPlaca(os.placa);
      setIdCliente(os.id_cliente);
      setNomeCliente(os.nome_cliente);
      setFone1Cliente(os.id_cliente__fone1);
      setWhatsCliente(os.id_cliente__whatsapp);
      setStatus(os.status);
      setDescStatus(os.desc_status);
      setEmissao(os.emissao);
      setIdVeiculo(os.id_veiculo);
      setModeloVeic(os.modelo_veic);
      setVeiculo(os.veiculo);
      setAnoVeic(os.ano_veic);
      setChassiVeic(os.chassi_veic);
      setNomeEquipamento(os.nome_equipamento);
      setOrcamentos(ret.orcamentos);
    }
  };

  const carregarItensOrcamento = async (id) => {
    const [ok, ret] = await OrcamentosService.buscarDetalhesOrcOS(id);

    if (!ok) return false;

    setItens(
      ret.map((e) => ({
        ...e,
        vlr_item: parseFloat(e.vlr_item),
        quantidade: parseFloat(e.quantidade),
        qtd_aprovada: parseFloat(e.qtd_aprovada),
      })) ?? []
    );
  };

  const handleSelectOrcamento = (id, isSelected, row) => {
    if (!isSelected) {
      setSelected(null);
      setSituacaoSelected(null);
      setItens([]);
      return;
    }

    if (id !== selected) {
      setSelected(id);
      setSituacaoSelected(row.situacao);
      carregarItensOrcamento(id);
    }
  };

  const alterarOrcamento = (idOrcamento) => {
    history.push(incluirAlterarOrcamentoOSRoute.path, {
      idOrcamento: idOrcamento,
      action: MODAL_ACTIONS.EDIT,
      idCliente: idCliente,
      placa: placa,
      idOs: idOs,
      idVeiculo: idVeiculo,
    });
  };

  const confirmarOrcamento = async () => {
    setLoadingConfirmarOrc(true);
    const payload = { id_orcamento: selected };
    const [ok] = await OrcamentosService.ordServ.fecharOrcamento(payload);

    if (ok) {
      setRedirect(true);
    }
    setLoadingConfirmarOrc(false);
  };

  const setQtdAprovadaItem = async (v, tipo, idItem, i = 0) => {
    const itensAtualizados = itens.map((e) =>
      e.tipo === tipo && e.id === idItem
        ? {
            ...e,
            qtd_aprovada: v,
            vlr_aprovado: roundFloat(v * e.vlr_item, 2),
          }
        : e
    );
    setItens(itensAtualizados);

    const item = itensAtualizados.find((e) => {
      return e.tipo === tipo && e.id === idItem;
    });

    if (item) {
      const payload = {
        id_orcamento: selected,
        tipo: item.tipo[0],
        id_item: item.id,
        qtd_aprov: item.qtd_aprovada,
        vlr_aprov: parseFloat(item.vlr_aprovado),
      };

      await OrcamentosService.ordServ.aprovarItem(payload);
    }
  };

  const autorizarNegarTudo = (autorizar) => {
    setItens(
      itens.map((e) => ({
        ...e,
        qtd_aprovada: autorizar ? e.quantidade ?? 0 : 0,
        vlr_aprovado: autorizar ? parseFloat(e.vlr_total ?? 0) : 0,
      }))
    );
  };

  const iniciarTela = async () => {
    await carregarParametros();
    await carregarDados();
    setLoadingFetch(false);
  };

  const onActivate = () => {
    iniciarTela();
  };

  useEffect(onActivate, []);

  const handleSetSomenteSituAberta = (v) => {
    setSomenteSituAberta(v);
  };

  const onChangeSomenteSituAberta = () => {
    carregarDados();
  };

  useEffect(onChangeSomenteSituAberta, [somenteSituAberta]);

  if (redirect) {
    return (
      <Redirect
        to={{ pathname: centralOSRoute.path, state: { refresh: true } }}
      />
    );
  }

  return (
    <PageContainer
      title="Orçamentos"
      number="0006_5"
      canGoBack
      loading={loadingFetch}
    >
      <Card body>
        <Row>
          <FixedField horizontal label="Nº O.S." value={idOs} />
          {sistAutomotivo && (
            <FixedField horizontal label="Placa" value={placa} />
          )}
          <FixedField horizontal label="Emissão" value={emissao} />
          <FixedField horizontal label="Status" value={descStatus} />
        </Row>
        <Row>
          <FixedField
            horizontal
            label="Cliente"
            value={formatValueFromAPI(nomeCliente, idCliente)}
          />
          <FixedField horizontal label="Telefone" value={fone1Cliente} />
          <FixedField horizontal label="WhatsApp" value={whatsCliente} />
        </Row>
        {sistAutomotivo && (
          <>
            <Row>
              <FixedField horizontal label="Veículo" value={veiculo} />
              <FixedField horizontal label="Modelo" value={modeloVeic} />
              <FixedField horizontal label="Ano" value={anoVeic} />
              <FixedField
                horizontal
                label="Chassi"
                value={
                  <>
                    {chassiVeic}{" "}
                    {(chassiVeic ?? "").length > 0 && (
                      <IconButton
                        size={12}
                        icon={BiCopy}
                        onClick={() => {
                          navigator.clipboard.writeText(chassiVeic);
                          toastr.info("", "Chassi copiado.");
                        }}
                      />
                    )}
                  </>
                }
              />
            </Row>
          </>
        )}
        {identificaEquipamento && (
          <Row>
            <FixedField
              horizontal
              label="Equipamento"
              value={nomeEquipamento}
            />
          </Row>
        )}
        <Row>
          <FormCheckbox
            padded={false}
            label="Mostrar somente orçamentos na situação Em Aberto"
            checked={somenteSituAberta}
            onChange={() => handleSetSomenteSituAberta(!somenteSituAberta)}
          />
          {status !== "F" && (
            <FormButton
              divClassName="ml-auto"
              padded={false}
              onClick={() =>
                history.push(incluirAlterarOrcamentoOSRoute.path, {
                  action: MODAL_ACTIONS.ADD,
                  idCliente: idCliente,
                  placa: placa,
                  idOs: idOs,
                  idVeiculo: idVeiculo,
                })
              }
              md="auto"
              color="info"
            >
              Incluir Orçamento
            </FormButton>
          )}
        </Row>
      </Card>
      <Card body>
        <OrcamentosOSGrid
          dados={orcamentos}
          onSelect={handleSelectOrcamento}
          alterar={alterarOrcamento}
          autorizarNegarTudo={autorizarNegarTudo}
        />
      </Card>
      <Card body>
        <ItensOrcamentoOSGrid
          itens={itens}
          setQtdAprovadaItem={setQtdAprovadaItem}
          orcamentoAberto={situacaoSelected === "Aberto"}
        />
        <Row>
          {status !== "F" && (
            <FormButton
              divClassName="ml-auto"
              padded={false}
              md="auto"
              color="success"
              disabled={
                [0, null, undefined].includes(selected) ||
                situacaoSelected === "Fechado"
              }
              onClick={confirmarOrcamento}
              loading={loadingConfirmarOrc}
            >
              F9 - Confirmar e Incluir na O.S.
            </FormButton>
          )}
          <FormButton padded={false} md="auto" color="danger">
            Esc - Sair
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
