import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";
import { docPrintReport } from "../../pdf";

const URL_BASE = "/pedido";

const URL_BASE_CENTRAL = `${URL_BASE}/central`;

const URL_BASE_ENTREGA = `${URL_BASE_CENTRAL}/entrega`;

const PedidoInternoService = {
  central: {
    estornarRecebimento: (payload) =>
      apiPutV2(`${URL_BASE_CENTRAL}/estornar_recebimento/`, payload),
    listar: (params) => apiGetV2(`${URL_BASE_CENTRAL}/listar/`, params),
    imprimir: async (idsPedidos, viasImprimir) => {
      let pedidosImprimir = [];
      let dados;

      for (let i = 0; i < idsPedidos.length; i++) {
        const [ok, ret] = await apiGetV2(
          `${URL_BASE_CENTRAL}/buscar_dados_impressao/${idsPedidos[i]}/`
        );
        if (ok) {
          pedidosImprimir.push(ret);
          dados = ret;
        } else {
          return false;
        }
      }

      const layoutsNovos = [9];
      const layout = dados.layout_impressao;
      var rota = "";
      var novoMs = false;

      if (layoutsNovos.includes(layout)) {
        rota = `/ped_interno/layouts/${dados.layout_impressao}/`;
        novoMs = true;
      } else {
        rota = "/pedido/pedido_interno/";
      }

      if (pedidosImprimir.length > 0) {
        return await docPrintReport(
          rota,
          {
            layout: pedidosImprimir[0].layout_impressao,
            params: pedidosImprimir[0].params,
            pedidos: pedidosImprimir,
            vias_imprimir: viasImprimir,
          },
          '0100_1',
          novoMs
        );
      } else {
        return false;
      }
    },
    imprimirFicha: async (idsPedidos, viasImprimir) => {
      let pedidosImprimir = [];

      for (let i = 0; i < idsPedidos.length; i++) {
        const [ok, ret] = await apiGetV2(
          `${URL_BASE_CENTRAL}/buscar_dados_impressao/${idsPedidos[i]}/`
        );
        if (ok) {
          pedidosImprimir.push(ret);
        } else {
          return false;
        }
      }

      if (pedidosImprimir.length > 0) {
        return await docPrintReport(
          "/pedido_interno/ficha_entrega/layout_1/",
          {
            layout: pedidosImprimir[0].layout_impressao,
            params: pedidosImprimir[0].params,
            pedidos: pedidosImprimir,
            vias_imprimir: viasImprimir,
          },
          "0100_1",
          true
        );
      } else {
        return false;
      }
    },
  },
  entrega: {
    verificaPodeEntregar: (idPedido) =>
      apiGetV2(`${URL_BASE_ENTREGA}/verif_pode_entregar_pedido/${idPedido}/`),
    buscarDadosEntrega: (idPedido) =>
      apiGetV2(`${URL_BASE_ENTREGA}/buscar_dados/${idPedido}/`),
    entregarPedido: (payload) =>
      apiPostV2(`${URL_BASE_ENTREGA}/entregar_pedido/`, payload),
  },
};

export default PedidoInternoService;
