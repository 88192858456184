import React from "react";
import { Table, TableDelete } from "../../../../../components";
import { formatarValor, naturalSort } from "../../../../../coreUtils";

export const ItensKitGrid = ({
  data,
  excluir,
  atualizarQtdItem,
  atualizarValorItem,
}) => {
  const columns = [
    {
      dataField: "id_produto",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_produto",
      text: "Produto",
      align: "left",
      sortable: true,
    },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      formatter: (c, row) =>
        formatarValor(c, row.casas_decimais_qtd ?? 2, 0, true),
      sortable: true,
      sortFunc: naturalSort,
      editable: true,
      onBlur: atualizarQtdItem,
      editorType: "number",
      decimalPlaces: (r) => r.casas_decimais_qtd ?? 2,
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_unit",
      text: "Vlr. Unitário",
      align: "right",
      formatter: (c, row) =>
        formatarValor(
          c,
          row.casas_decimais_vlrs ?? 4,
          row.casas_decimais_vlrs ?? 4,
          true
        ),
    },
    {
      dataField: "perc_desc",
      text: "% Desconto",
      align: "center",
      formatter: (c) => `${formatarValor(c, 2, 2, true)}%`,
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_item",
      text: "Vlr. Item",
      align: "right",
      formatter: (c, row) =>
        formatarValor(
          c,
          row.casas_decimais_vlrs ?? 4,
          row.casas_decimais_vlrs ?? 4,
          true
        ),
      editable: true,
      onBlur: atualizarValorItem,
      editorType: "number",
      decimalPlaces: (r) => r.casas_decimais_vlrs ?? 2,
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dataField: "act",
      isDummyField: true,
      align: "center",
      formatter: (c, row) => <TableDelete onClick={() => excluir(row.id)} />,
    },
  ];

  return <Table data={data} columns={columns} pageSize={7} paginated={false} />;
};
