import React from "react";
import moment from "moment";
import {
  DatePicker,
  FormButton,
  IntegerFormInput,
  ModalBase,
  NumberInput,
  TextInput,
} from "../../../../../../components";
import { useState } from "react";
import { Row } from "reactstrap";
import ConferValoresRecMaosService from "../../../../../../services/pedidos/ConferValoresRecMaosService";
import { formatDateISO, sumDataField } from "../../../../../../coreUtils";
import { showWarning } from "../../../../../../components/AlertaModal";

export const ConferirValoresDinheiroModal = ({ selected, notifyEvent }) => {
  const vlrCompensar = sumDataField(selected, "valor_pend");

  const [isOpen, setIsOpen] = useState(false);
  const [dataComp, setDataComp] = useState(new Date());
  const [observ, setObserv] = useState("");
  const [vlrConferido, setVlrConferido] = useState(vlrCompensar);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setDataComp(new Date());
    setObserv("");
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    setVlrConferido(vlrCompensar);
  };

  const handleSubmit = async () => {
    if (!(dataComp instanceof Date)) {
      showWarning(
        "Data incorreta",
        "Por favor, verifique a data de compensação",
        null,
        null,
        "input-data"
      );
      return;
    }

    const payload = {
      observ_conf: observ,
      data_compensacao: formatDateISO(dataComp),
      vlr_conferido: vlrConferido,
      ids_conferir: selected.map((e) => ({ id_pendencia: e.id })),
    };

    setLoading(true);
    const [ok] = await ConferValoresRecMaosService.conferirDinheiro(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  const somenteDinheiro = !selected.some(
    (e) => e.desc_forma_pag !== "Dinheiro"
  );

  return (
    <>
      <FormButton
        md="auto"
        color="success"
        onClick={toggle}
        disabled={selected.length === 0 || !somenteDinheiro}
        disabledHint={
          !somenteDinheiro
            ? "Selecione somente Pedidos recebidos em Dinheiro"
            : "Selecione ao menos um Pedido da lista"
        }
      >
        Confirmar Valores
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        onClosed={limparDados}
        size="sm"
        title="Conferir Valores"
        number="0054_1"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <IntegerFormInput
            md={6}
            label="Vendas Selecionadas"
            defaultValue={selected.length}
            disabled
          />
          <NumberInput
            md={6}
            label="Valor Selecionado"
            value={vlrCompensar}
            disableGroupSeparators={false}
            disabled
          />
        </Row>
        <Row>
          <DatePicker
            md={6}
            label="Compensação"
            value={dataComp}
            onChange={(v) => setDataComp(moment.isMoment(v) ? v.toDate() : v)}
            inputId="data-comp"
            name={"input-data"}
          />
          <NumberInput
            md={6}
            label="Valor Conferido"
            value={vlrConferido}
            onChange={setVlrConferido}
            disableGroupSeparators={false}
            disabled={selected.length > 1}
            id="vlrConferido"
            name="vlrConferido"
            hint={
              selected.length > 1
                ? "Caso deseje alterar o valor conferido, faça a conferência individual da venda onde ocorreu diferença entre o valor recebido e o valor total"
                : undefined
            }
          />
        </Row>
        <Row>
          <TextInput
            md={12}
            type="textarea"
            rows={4}
            label="Observação"
            value={observ}
            onChange={(e, v) => setObserv(v)}
          />
        </Row>
      </ModalBase>
    </>
  );
};
