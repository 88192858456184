import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  impr_emis_os: "P",
  layout_impr_emis_os: 1,
  impr_fech_os: "P",
  layout_impr_fech_os: 1,
  identifica_tecnico: false,
  adiciona_item_grid: "R",
  mensagem_fech_os: "",
  mec_utiliza_control_pleno_os: false,
  imprime_valor_os_previa: true,
  add_vlr_tot_bruto_impr_fech_os: false,
  abrir_os_identifica_equipamento: false,
  abrir_os_usa_taxa_armazenamento: false,
  abrir_os_usa_taxa_orcamento: false,
  abrir_os_identifica_data_hora_agendamento: false,
  utiliza_status_adic_conc_serv: false,
  abrir_os_identifica_contato_servico: false,
  abrir_os_identifica_tecnico_resp: false,
  utiliza_observ_item: false,
  imprime_refer_fech: true,
  mensagem_fech_os_somente_caixa: "",
  bloq_inc_servico_sem_tecnico: false,
  permite_alt_nome_item_manut_os: false,
  permite_manipular_vlr_unitario: false,
  permite_manipular_referencia: false,
};

const fieldsToEmptyString = ["mensagem_fech_os_somente_caixa"];

export const osSlice = createSlice({
  name: "os_slice",
  initialState: initialState,
  reducers: {
    setupOs: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (fieldsToEmptyString.includes(key) && value === null) {
          value = "";
        }

        state[key] = value;
      });
      return state;
    },
    setImprEmisOs: (state, action) => {
      state.impr_emis_os = action.payload;
    },
    setLayoutImprEmisOs: (state, action) => {
      state.layout_impr_emis_os = action.payload;
    },
    setImprFechOs: (state, action) => {
      state.impr_fech_os = action.payload;
    },
    setLayoutImprFechOs: (state, action) => {
      state.layout_impr_fech_os = action.payload;
    },
    setIdentificaTecnico: (state, action) => {
      state.identifica_tecnico = action.payload;
    },
    setAdicionaItemGrid: (state, action) => {
      state.adiciona_item_grid = action.payload;
    },
    setMensagemFechOs: (state, action) => {
      state.mensagem_fech_os = action.payload;
    },
    setMecUtilizaControlPlenoOs: (state, action) => {
      state.mec_utiliza_control_pleno_os = action.payload;
    },
    setImprimeValorOsPrevia: (state, action) => {
      state.imprime_valor_os_previa = action.payload;
    },
    setAddVlrTotBrutoImprFechOs: (state, action) => {
      state.add_vlr_tot_bruto_impr_fech_os = action.payload;
    },
    setAbrirOsIdentificaEquipamento: (state, action) => {
      state.abrir_os_identifica_equipamento = action.payload;
    },
    setAbrirOsUsaTaxaArmazenamento: (state, action) => {
      state.abrir_os_usa_taxa_armazenamento = action.payload;
    },
    setAbrirOsUsaTaxaOrcamento: (state, action) => {
      state.abrir_os_usa_taxa_orcamento = action.payload;
    },
    setAbrirOsIdentificaDataHoraAgendamento: (state, action) => {
      state.abrir_os_identifica_data_hora_agendamento = action.payload;
    },
    setUtilizaStatusAdicConcServ: (state, action) => {
      state.utiliza_status_adic_conc_serv = action.payload;
    },
    setAbrirOsIdentificaContatoServico: (state, action) => {
      state.abrir_os_identifica_contato_servico = action.payload;
    },
    setAbrirOsIdentificaTecnicoResp: (state, action) => {
      state.abrir_os_identifica_tecnico_resp = action.payload;
    },
    setUtilizaObservItem: (state, action) => {
      state.utiliza_observ_item = action.payload;
    },
    setImprimeReferFech: (state, action) => {
      state.imprime_refer_fech = action.payload;
    },
    setMensagemFechOsSomenteCaixa: (state, action) => {
      state.mensagem_fech_os_somente_caixa = action.payload;
    },
    setBloqIncServicoSemTecnico: (state, action) => {
      state.bloq_inc_servico_sem_tecnico = action.payload;
    },
    setPermiteAltNomeItemManutOs: (state, action) => {
      state.permite_alt_nome_item_manut_os = action.payload;
    },
    setPermiteManipularVlrUnitario: (state, action) => {
      state.permite_manipular_vlr_unitario = action.payload;
    },
    setPermiteManipularReferencia: (state, action) => {
      state.permite_manipular_referencia = action.payload;
    },
  },
});

export const {
  setupOs,
  setImprEmisOs,
  setLayoutImprEmisOs,
  setImprFechOs,
  setLayoutImprFechOs,
  setIdentificaTecnico,
  setAdicionaItemGrid,
  setMensagemFechOs,
  setMecUtilizaControlPlenoOs,
  setImprimeValorOsPrevia,
  setAddVlrTotBrutoImprFechOs,
  setAbrirOsIdentificaEquipamento,
  setAbrirOsUsaTaxaArmazenamento,
  setAbrirOsUsaTaxaOrcamento,
  setAbrirOsIdentificaDataHoraAgendamento,
  setUtilizaStatusAdicConcServ,
  setAbrirOsIdentificaContatoServico,
  setAbrirOsIdentificaTecnicoResp,
  setUtilizaObservItem,
  setImprimeReferFech,
  setMensagemFechOsSomenteCaixa,
  setBloqIncServicoSemTecnico,
  setPermiteAltNomeItemManutOs,
  setPermiteManipularVlrUnitario,
  setPermiteManipularReferencia,
} = osSlice.actions;

export default osSlice.reducer;
