import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/nfe_entrada/entrada_via_xml";

const EntradaNfXmlService = {
  alterarDadosItem: async (payload) =>
    await apiPutV2(`${URL_BASE}/alterar_dados_item/`, payload, {
      successMessage: false,
    }),
  alterarValoresNf: async (payload) =>
    await apiPutV2(`${URL_BASE}/alterar_valores_nf/`, payload, {
      successMessage: false,
    }),
  atualizarNotasJaImportadas: async () =>
    await apiPostV2("/nfe_entrada/fechar_notas_ja_importadas/"),
  buscarDadosNf: async (idNfEntradaXml) =>
    await apiGetV2(`${URL_BASE}/buscar_dados_nf/${idNfEntradaXml}/`),
  enviarXmlOutraEmpresa: (idNfEntradaXml) =>
    apiPostV2(`${URL_BASE}/enviar_xml_outra_empresa/`, {
      id_nf_entrada_xml: idNfEntradaXml,
    }),
  gerarDadosItens: async (idNfEntradaXml) =>
    await apiPostV2(
      `${URL_BASE}/gerar_dados_itens/`,
      {
        id_nf_entrada_xml: idNfEntradaXml,
      },
      { successMessage: false }
    ),
  importarXml: async (payload) =>
    await apiPostV2(`${URL_BASE}/importar_xml/`, payload),
  importar: async (payload) =>
    await apiPostV2(`${URL_BASE}/importar/`, payload),
  listarNfs: async (numeroNf, idFornecedor, chaveAcesso) =>
    await apiGetV2(`${URL_BASE}/listar_nfs/`, {
      numero: numeroNf,
      id_fornecedor: idFornecedor,
      chave_acesso: chaveAcesso,
    }),
  naoImportar: async (idNfEntradaXml) =>
    await apiPutV2(`${URL_BASE}/definir_nao_importar/`, {
      id_nf_entrada_xml: idNfEntradaXml,
    }),
  recalcularValoresItem: async (idNfEntradaXmlItem, alterouProduto) =>
    await apiPutV2(
      `${URL_BASE}/recalcular_valores_item/`,
      {
        id_nf_entrada_xml_item: idNfEntradaXmlItem,
        alterou_produto: alterouProduto,
      },
      { successMessage: false }
    ),
};

export default EntradaNfXmlService;
