import React, { useState } from "react";
import {
  AsyncComboBox,
  ComboBox,
  DatePicker,
  Divider,
  FormButton,
  IntegerFormInput,
  LabelButton,
  ModalBase,
  PesqPlanoCtaCentroCusto,
  TextInput,
} from "../../../../../components";
import moment from "moment";
import { Row } from "reactstrap";
import GerencBancService from "../../../../../services/financeiro/GerencBancService";
import { formatDateISO } from "../../../../../coreUtils";

const opcoesOrigem = [
  { label: "Caixa ADM", value: "CADM" },
  { label: "Conta Pessoal", value: "CP" },
];
const opcoesDestino = [
  { label: "Caixa ADM", value: "CADM" },
  { label: "Caixa Loja", value: "CLJ" },
  { label: "Conta Pessoal", value: "CP" },
];

export const TransfBancModal = (notifyEvent) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [valorTransf, setValorTransf] = useState(null);
  const [obs, setObs] = useState("");

  const [idConta, setIdConta] = useState(null);
  const [planoConta, setPlanoConta] = useState(null);
  const [dataMov, setDataMov] = useState(new Date());

  const [idContaDestino, setIdContaDestino] = useState(null);
  const [planoContaDestino, setPlanoContaDestino] = useState(null);
  const [dataMovDestino, setDataMovDestino] = useState(new Date());

  const [tipoContaOrigem, setTipoContaOrigem] = useState("CP");
  const [tipoContaDestino, setTipoContaDestino] = useState("CP");

  const limparDados = () => {
    setIdConta(null);
    setPlanoConta(null);
    setDataMov(new Date());
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    const payload = {
      id_conta_banc: idConta,
      data_mov: formatDateISO(dataMov),
      id_plano_conta: planoConta,
      // ------------ALTERAR PAYLOAD-------------
      id_conta_banc_dest: idContaDestino,
      data_mov_dest: formatDateISO(dataMovDestino),
      id_plano_conta_dest: planoContaDestino,
      // ------------ALTERAR PAYLOAD-------------
      observ: obs,
      vlr_transf: valorTransf,
    };
    setLoadingSubmit(true);
    // ALTERAR A ROTA
    const [ok] = await GerencBancService.incluir();
    if (ok) {
      toggle();
    }
    setLoadingSubmit(false);
  };

  return (
    <>
      <LabelButton md="auto" color="info" onClick={toggle}>
        Transferência Entre Contas
      </LabelButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Transferência Entre Contas"
        number="0132_5"
        onConfirm={handleSubmit}
        loadingConfirm={loadingSubmit}
        onClosed={limparDados}
        notifyEvent={notifyEvent}
      >
        <Divider>Origem</Divider>
        <Row>
          <ComboBox
            options={opcoesOrigem}
            label={"Tipo de Conta"}
            value={tipoContaOrigem}
            onChange={(s) => setTipoContaOrigem(s?.value)}
            defaultValue={"CP"}
          />
          <DatePicker
            md={2}
            label={"Data"}
            value={dataMov}
            onChange={(v) => setDataMov(moment.isMoment(v) ? v.toDate() : v)}
            tabIndex={100}
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={6}
            label="Conta"
            isConcatField
            concatModelName="conta_banc"
            defaultValue={idConta}
            onChange={(s) => setIdConta(s?.value)}
            disabled={tipoContaOrigem === "CP" ? false : true}
          />
          <PesqPlanoCtaCentroCusto
            md={6}
            label="Plano de Contas"
            mode="plano_contas"
            onChange={(v) => setPlanoConta(v)}
            value={planoConta}
          />
        </Row>
        <Divider>Destino</Divider>
        <Row>
          <ComboBox
            options={opcoesDestino}
            label={"Tipo de Conta"}
            value={tipoContaDestino}
            onChange={(s) => setTipoContaDestino(s?.value)}
            defaultValue={"CP"}
          />
          <DatePicker
            md={2}
            label={"Data"}
            value={dataMovDestino}
            onChange={(v) =>
              setDataMovDestino(moment.isMoment(v) ? v.toDate() : v)
            }
            tabIndex={100}
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={6}
            label="Conta"
            isConcatField
            concatModelName="conta_banc"
            defaultValue={idContaDestino}
            onChange={(s) => setIdContaDestino(s?.value)}
            disabled={tipoContaDestino === "CP" ? false : true}
          />
          <PesqPlanoCtaCentroCusto
            md={6}
            label="Plano de Contas"
            mode="plano_contas"
            onChange={(v) => setPlanoContaDestino(v)}
            value={planoContaDestino}
          />
        </Row>
        <Divider>Transferência</Divider>
        <Row>
          <TextInput
            label={"Observação"}
            onChange={(s) => setObs(s?.value)}
            value={obs}
            md={10}
          />
          <IntegerFormInput
            label={"Valor Transferido"}
            value={valorTransf}
            onChange={(s) => setValorTransf(s?.value)}
            md={2}
          />
        </Row>
      </ModalBase>
    </>
  );
};
