import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import ImgsViewer from "react-images-viewer";

export const CarrosselFotosOSDetalhes = ({ fotos = [] }) => {
  const [index, setIndex] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const temFotos = fotos.length > 0;

  if (!temFotos) return <></>;

  const lado = "170px";

  return (
    <div
      style={{
        width: lado,
        height: lado,
        marginBottom: "-10px",
      }}
    >
      <div
        style={{
          marginLeft: "auto",
          height: "88%",
          width: "95%",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#eee",
          border: "1px solid #dee2e6",
        }}
        className="mb-2"
      >
        <img
          src={fotos[index]?.base64}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            cursor: "zoom-in",
          }}
          alt={fotos[index]?.observ ?? ""}
          onClick={() => setIsViewerOpen(true)}
        />
      </div>
      <div
        className="ml-3"
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <div style={{ display: "flex" }}>
          <ChevronLeft
            size={20}
            onClick={() => index > 0 && setIndex(index - 1)}
            style={index > 0 ? { cursor: "pointer" } : { color: "#DDD" }}
          />
          <label style={{ marginInline: "1rem" }}>
            {index + 1} / {fotos.length}
          </label>
          <ChevronRight
            size={20}
            onClick={() => index < fotos.length - 1 && setIndex(index + 1)}
            style={
              index < fotos.length - 1
                ? { cursor: "pointer" }
                : { color: "#DDD" }
            }
          />
        </div>
      </div>

      <ImgsViewer
        imgs={fotos.map((e) => ({
          src: e.base64,
          caption: e.observ,
        }))}
        isOpen={isViewerOpen}
        currImg={index}
        backdropCloseable={true}
        onClose={() => setIsViewerOpen(false)}
        onClickNext={() => setIndex(index + 1)}
        onClickPrev={() => setIndex(index - 1)}
        spinnerDisabled
        width={700}
        backgroundStyle={{ backgroundColor: "#00000085" }}
      />
    </div>
  );
};
