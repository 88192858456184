import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tipoSist: "PADRAO",
  identificaEquipamento: false,
  identificaDataHoraAgendamento: false,
  identificaContatoServico: false,
  identificaTecnicoResp: false,
  imprFech: "",
  modoAdicaoItemGrid: "S",
  priorizaPesqProd: "COD",
  identificaTecnico: false,
  utilizaObservItem: false,
  imprimeReferFech: true,
  utilizaStatusAdicConcServ: true,
  layoutImprEmis: 1,
  layoutImprFech: 1,
  permiteAltNomeItemManutOs: false,
  permiteManipularVlrUnitario: false,
  permiteManipularReferencia: false,
};

export const itensOsSlice = createSlice({
  name: "itens_os_slice",
  initialState: initialState,
  reducers: {
    loadParams: (state, action) => {
      const payload = action.payload ?? {};

      state.tipoSist = payload?.tipoSist ?? initialState.tipoSist;
      state.identificaEquipamento =
        payload?.identificaEquipamento ?? initialState.identificaEquipamento;
      state.identificaDataHoraAgendamento =
        payload?.identificaDataHoraAgendamento ??
        initialState.identificaDataHoraAgendamento;
      state.identificaContatoServico =
        payload?.identificaContatoServico ??
        initialState.identificaContatoServico;
      state.identificaTecnicoResp =
        payload?.identificaTecnicoResp ?? initialState.identificaTecnicoResp;
      state.imprFech = payload?.imprFech ?? initialState.imprFech;
      state.modoAdicaoItemGrid =
        payload?.modoAdicaoItemGrid ?? initialState.modoAdicaoItemGrid;
      state.priorizaPesqProd =
        payload?.priorizaPesqProd ?? initialState.priorizaPesqProd;
      state.identificaTecnico =
        payload?.identificaTecnico ?? initialState.identificaTecnico;
      state.utilizaObservItem =
        payload?.utilizaObservItem ?? initialState.utilizaObservItem;
      state.imprimeReferFech =
        payload?.imprimeReferFech ?? initialState.imprimeReferFech;
      state.utilizaStatusAdicConcServ =
        payload?.utilizaStatusAdicConcServ ??
        initialState.utilizaStatusAdicConcServ;
      state.layoutImprEmis =
        payload?.layoutImprEmis ?? initialState.layoutImprEmis;
      state.layoutImprFech =
        payload?.layoutImprFech ?? initialState.layoutImprFech;
      state.permiteAltNomeItemManutOs =
        payload?.permiteAltNomeItemManutOs ??
        initialState.permiteAltNomeItemManutOs;
      state.permiteManipularVlrUnitario =
        payload?.permiteManipularVlrUnitario ??
        initialState.permiteManipularVlrUnitario;
      state.permiteManipularReferencia =
        payload?.permiteManipularReferencia ??
        initialState.permiteManipularReferencia;
    },
  },
});

export const { loadParams } = itensOsSlice.actions;

export default itensOsSlice.reducer;
