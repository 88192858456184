import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  banco: 0,
  agencia: "",
  nro_conta: "",
  endereco: "",
  nro_ender: "",
  bairro: "",
  cidade: 0,
  cep: "",
  gerente: "",
  fone: "",
  considera_fluxo_cxa: true,
  chave_pix: null,
  nome_beneficiario_pix: null,
  status: true,
  cobranca: {
    posto: "",
    layout: "NAO",
    padrao: "400",
    modo_integracao: "CNAB",
    cod_cedente: "",
    nome_empr_impr_boleto: "",
    nro_ult_rem: 0,
    seq_ult_nosso_nro: 0,
    protestar: false,
    dias_protesto: 0,
    perc_multa: 0,
    dias_multa: 0,
    tipo_juros: "P",
    cobrar_juros_mes_dia: "MES",
    perc_juros: 0,
    vlr_juros: 0,
    dias_juros: 0,
    nome_avalista: "",
    cnpj_avalista: "",
    mensagem_1: "",
    mensagem_2: "",
    mensagem_3: "",
    mensagem_4: "",
    tipo_carteira: null,
    variacao_carteira: "",
    cod_transmissao: null,
    impr_observ_duplic_rec_mov: false,
    forma_inadimplencia: "PRO",
    dias_negativacao: 0,
    endereco_avalista: "",
    id_cidade_avalista: 0,
    cep_avalista: "",
    especie_tit: "",
    tipo_multa: "PER",
    vlr_multa: 0,
    api_authorization_code: "",
    api_client_id: "",
    api_client_secret: "",
    api_username: "",
    api_password: "",
    id_plano_conta_baixa_cancel_boleto: null,
    integra_api_webhook_config: false,
    habilita_api_pix: false,
    chave_api_pix: null,
  },
  arq_certificado_api: null,
  arq_key_api: null,
  api_certif_config: false,
  api_key_config: false,
};

export const cadastroContaBanc = createSlice({
  name: "cadastro_conta_banc",
  initialState: initialState,
  reducers: {
    init: () => initialState,
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setBanco: (state, action) => {
      state.banco = action.payload;
    },
    setAgencia: (state, action) => {
      state.agencia = action.payload;
    },
    setNroConta: (state, action) => {
      state.nro_conta = action.payload;
    },
    setEndereco: (state, action) => {
      state.endereco = action.payload;
    },
    setNroEnder: (state, action) => {
      state.nro_ender = action.payload;
    },
    setBairro: (state, action) => {
      state.bairro = action.payload;
    },
    setCidade: (state, action) => {
      state.cidade = action.payload;
    },
    setCep: (state, action) => {
      state.cep = action.payload;
    },
    setGerente: (state, action) => {
      state.gerente = action.payload;
    },
    setFone: (state, action) => {
      state.fone = action.payload;
    },
    setConsideraFluxoCxa: (state, action) => {
      state.considera_fluxo_cxa = action.payload;
    },
    setChavePix: (state, action) => {
      state.chave_pix = action.payload ?? null;
    },
    setNomeBeneficiarioPix: (state, action) => {
      state.nome_beneficiario_pix = action.payload ?? null;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setPosto: (state, action) => {
      state.cobranca.posto = action.payload;
    },
    setLayout: (state, action) => {
      state.cobranca.layout = action.payload;
    },
    setModoIntegracao: (state, action) => {
      state.cobranca.modo_integracao = action.payload;
      if (action.payload === "API") {
        state.cobranca.cod_cedente = "";
        state.cobranca.nome_empr_impr_boleto = "";
        state.cobranca.nro_ult_rem = 0;
        state.cobranca.seq_ult_nosso_nro = 0;
        state.cobranca.protestar = false;
        state.cobranca.dias_protesto = 0;
        state.cobranca.tipo_carteira = null;
        state.cobranca.variacao_carteira = "";
        state.cobranca.cod_transmissao = null;
        state.cobranca.impr_observ_duplic_rec_mov = false;
        state.cobranca.dias_negativacao = 0;
        state.cobranca.endereco_avalista = "";
        state.cobranca.id_cidade_avalista = 0;
        state.cobranca.cep_avalista = "";
        state.cobranca.especie_tit = "";
      }
    },
    setPadrao: (state, action) => {
      state.cobranca.padrao = action.payload;
    },
    setCodCedente: (state, action) => {
      state.cobranca.cod_cedente = action.payload;
    },
    setNomeEmprImprBoleto: (state, action) => {
      state.cobranca.nome_empr_impr_boleto = action.payload;
    },
    setNroUltRem: (state, action) => {
      state.cobranca.nro_ult_rem = action.payload;
    },
    setSeqUltNossoNro: (state, action) => {
      state.cobranca.seq_ult_nosso_nro = action.payload;
    },
    setProtestar: (state, action) => {
      if (action.payload !== true) {
        state.cobranca.dias_protesto = 0;
      }
      state.cobranca.protestar = action.payload;
    },
    setDiasProtesto: (state, action) => {
      state.cobranca.dias_protesto = action.payload;
    },
    setPercMulta: (state, action) => {
      state.cobranca.perc_multa = action.payload;
    },
    setDiasMulta: (state, action) => {
      state.cobranca.dias_multa = action.payload;
    },
    setTipoJuros: (state, action) => {
      if (action.payload === "P") {
        state.cobranca.vlr_juros = 0;
        if (state.cobranca.tipo_juros === "V") {
          state.cobranca.cobrar_juros_mes_dia = "MES";
        }
      } else {
        state.cobranca.perc_juros = 0;
        state.cobranca.cobrar_juros_mes_dia = "DIA";
      }
      state.cobranca.tipo_juros = action.payload;
    },
    setCobrarJurosMesDia: (state, action) => {
      state.cobranca.cobrar_juros_mes_dia = action.payload;
    },
    setPercJuros: (state, action) => {
      state.cobranca.perc_juros = action.payload;
    },
    setVlrJuros: (state, action) => {
      state.cobranca.vlr_juros = action.payload;
    },
    setDiasJuros: (state, action) => {
      state.cobranca.dias_juros = action.payload;
    },
    setNomeAvalista: (state, action) => {
      state.cobranca.nome_avalista = action.payload;
    },
    setCnpjAvalista: (state, action) => {
      state.cobranca.cnpj_avalista = action.payload;
    },
    setMensagem1: (state, action) => {
      state.cobranca.mensagem_1 = action.payload;
    },
    setMensagem2: (state, action) => {
      state.cobranca.mensagem_2 = action.payload;
    },
    setMensagem3: (state, action) => {
      state.cobranca.mensagem_3 = action.payload;
    },
    setMensagem4: (state, action) => {
      state.cobranca.mensagem_4 = action.payload;
    },
    setTipoCarteira: (state, action) => {
      state.cobranca.tipo_carteira = action.payload;
    },
    setVariacaoCarteira: (state, action) => {
      state.cobranca.variacao_carteira = action.payload;
    },
    setCodTransmissao: (state, action) => {
      state.cobranca.cod_transmissao = action.payload;
    },
    setImprObservDuplicRecMov: (state, action) => {
      state.cobranca.impr_observ_duplic_rec_mov = action.payload;
    },
    setFormaInadimplencia: (state, action) => {
      state.cobranca.forma_inadimplencia = action.payload;
    },
    setDiasNegativacao: (state, action) => {
      state.cobranca.dias_negativacao = action.payload;
    },
    setEnderecoAvalista: (state, action) => {
      state.cobranca.endereco_avalista = action.payload;
    },
    setIdCidadeAvalista: (state, action) => {
      state.cobranca.id_cidade_avalista = action.payload;
    },
    setCepAvalista: (state, action) => {
      state.cobranca.cep_avalista = action.payload;
    },
    setEspecieTit: (state, action) => {
      state.cobranca.especie_tit = action.payload;
    },
    setTipoMulta: (state, action) => {
      if (action.payload === "PER") {
        state.cobranca.vlr_multa = 0;
      } else {
        state.cobranca.perc_multa = 0;
      }
      state.cobranca.tipo_multa = action.payload;
    },
    setVlrMulta: (state, action) => {
      state.cobranca.vlr_multa = action.payload;
    },
    setApiAuthorizationCode: (state, action) => {
      state.cobranca.api_authorization_code = action.payload;
    },
    setApiClientId: (state, action) => {
      state.cobranca.api_client_id = action.payload;
    },
    setApiClientSecret: (state, action) => {
      state.cobranca.api_client_secret = action.payload;
    },
    setApiUsername: (state, action) => {
      state.cobranca.api_username = action.payload;
    },
    setApiPassword: (state, action) => {
      state.cobranca.api_password = action.payload;
    },
    setIdPlanoContaBaixaCancelBoleto: (state, action) => {
      state.cobranca.id_plano_conta_baixa_cancel_boleto = action.payload;
    },
    setArqCertificadoApi: (state, action) => {
      state.arq_certificado_api = action.payload;
    },
    setArqKeyApi: (state, action) => {
      state.arq_key_api = action.payload;
    },
    setIntegraApiWebhookConfig: (state, action) => {
      state.integra_api_webhook_config = action.payload;
    },
    setHabilitaApiPix: (state, action) => {
      debugger;
      state.cobranca.habilita_api_pix = action.payload;
    },
    setChaveApiPix: (state, action) => {
      state.cobranca.chave_api_pix = action.payload;
    },
  },
});

export const {
  init,
  setup,
  setBanco,
  setAgencia,
  setNroConta,
  setPosto,
  setEndereco,
  setNroEnder,
  setBairro,
  setCidade,
  setCep,
  setGerente,
  setFone,
  setConsideraFluxoCxa,
  setChavePix,
  setNomeBeneficiarioPix,
  setStatus,
  setLayout,
  setModoIntegracao,
  setPadrao,
  setCodCedente,
  setNomeEmprImprBoleto,
  setNroUltRem,
  setSeqUltNossoNro,
  setProtestar,
  setDiasProtesto,
  setPercMulta,
  setDiasMulta,
  setTipoJuros,
  setPercJuros,
  setVlrJuros,
  setDiasJuros,
  setNomeAvalista,
  setCnpjAvalista,
  setMensagem1,
  setMensagem2,
  setMensagem3,
  setMensagem4,
  setTipoCarteira,
  setVariacaoCarteira,
  setCodTransmissao,
  setImprObservDuplicRecMov,
  setFormaInadimplencia,
  setDiasNegativacao,
  setEnderecoAvalista,
  setIdCidadeAvalista,
  setCepAvalista,
  setEspecieTit,
  setTipoMulta,
  setVlrMulta,
  setCobrarJurosMesDia,
  setApiAuthorizationCode,
  setApiClientId,
  setApiClientSecret,
  setApiPassword,
  setApiUsername,
  setIdPlanoContaBaixaCancelBoleto,
  setArqCertificadoApi,
  setArqKeyApi,
  setIntegraApiWebhookConfig,
  setHabilitaApiPix,
  setChaveApiPix,
} = cadastroContaBanc.actions;

export default cadastroContaBanc.reducer;
