import moment from "moment";
import React from "react";
import { Card, Col } from "reactstrap";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  DatePicker,
  FixedField,
  FormButton,
  NumberInput,
  PageContainer,
  TextInput,
  Divider,
} from "../../../../../../components";
import {
  dateFromLocaleString,
  formatDateISO,
  formatNumber,
  MODAL_ACTIONS,
  roundFloat,
  sumDataField,
  useStateWithRef,
} from "../../../../../../coreUtils";
import CentralPedidosService from "../../../../../../services/pedidos/CentralPedidosService";
import { PedidoItensGrid } from "./components/PedidoItensGrid";
import { useState } from "react";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  IncluirAlterarProdutoModal,
  TIPO_ITEM_VENDA,
} from "./components/IncluirAlterarProdutoModal";
import { useRef } from "react";
import { centralPedidosRoute } from "../../../../../../routes/modules/pedidos";
import CadastroClienteService from "../../../../../../services/cadastro/CadastroClienteService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PesqKitProdutoModal from "../../../../../../components/kit_produto/PesqKitProdutoModal";
import { showWarning } from "../../../../../../components/AlertaModal";
import UteisService from "../../../../../../services/uteis/UteisService";

const formasPag = [
  { label: "Em Mãos", value: "MAOS" },
  { label: "Boleto", value: "BOLE" },
  { label: "Transferência Bancária", value: "TBAN" },
  { label: "Cheque", value: "CHEQ" },
  { label: "Cartão", value: "CART" },
];

const corStatus = (v) => {
  switch (v) {
    case "Pendente de Liberação":
      return "#51a86c";
    case "Aguardando Faturamento":
      return "#a180da";

    default:
      return "silver";
  }
};

export const IncluirAlterarPedidoExt = ({ location }) => {
  const idPedido = location.state.id;
  const action = location.state.action ?? MODAL_ACTIONS.ADD;
  const idPedidoClonar = location.state.id_pedido_clonar;

  const [params, setParams] = useState({});

  // Dados do Pedido
  const [idCliente, setIdCliente] = useState(null);
  const [obrigInfoOrdemCompraNfe, setObrigInfoOrdemCompraNfe] = useState(false);
  const [modeloNfeCliente, setModeloNfeCliente] = useState(false);
  const [idColaborador, setIdColaborador] = useState(null);
  const [vlrTotProd, setVlrTotProd] = useState(null);
  const [vlrTotIcmsSt, setVlrTotIcmsSt] = useState(null);
  const [vlrTotal, setVlrTotal] = useState(null);
  const [vlrTotDesconto, setVlrTotDesconto] = useState(null);
  const [vlrFrete, setVlrFrete] = useState(null);
  const [vlrTotTroca, setVlrTotTroca] = useState(null);
  const [vlrTotBonificacao, setVlrTotBonificacao] = useState(null);
  const [obs, setObs] = useState(null);
  const [vlrTotIpi, setVlrTotIpi] = useState(null);
  const [emissao, setEmissao] = useState(null);
  const [previsaoEntrega, setPrevisaoEntrega] = useState(null);
  const [descricaoStatus, setDescricaoStatus] = useState(null);
  const [formaPagto, setFormaPagto] = useState(null);
  const [idCondPag, setIdCondPag] = useState(null);
  const [representacao, setRepresentacao] = useState(null);
  const [vlrDescSobreTotal, setVlrDescSobreTotal] = useState(0);
  const [itens, setItens, itensRef] = useStateWithRef([]);

  const [clienteTemLimiteContasRec, setClienteTemLimiteContasRec] =
    useState(false);
  const [limiteClienteContasRec, setLimiteClienteContasRec] = useState(0);
  const [limiteDispClienteContasRec, setLimiteDispClienteContasRec] =
    useState(0);

  // Variáveis de Controle
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingImpostos, setLoadingImpostos] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const incluirAlterarRef = useRef(null);
  const [modalKitsOpen, setModalKitsOpen] = useState(false);

  const temItemVenda = itens.some((e) => e.tipo === TIPO_ITEM_VENDA);

  const toggleKits = () => setModalKitsOpen(!modalKitsOpen);

  const buscarParametros = async () => {
    const [ok, ret] = await CentralPedidosService.buscarParametros();
    if (ok) {
      setParams(ret);
    }
    return ok;
  };

  const carregarDados = async (clonar) => {
    const [ok, ret] = await CentralPedidosService.buscar(
      clonar ? idPedidoClonar : idPedido
    );
    if (ok) {
      setIdCliente(ret.id_cliente);
      setObrigInfoOrdemCompraNfe(ret.obrig_info_ordem_compra_nfe);
      setVlrTotProd(parseFloat(ret.vlr_tot_prod));
      setVlrTotIcmsSt(parseFloat(ret.vlr_tot_icms_st));
      setVlrTotal(parseFloat(ret.vlr_total));
      setVlrTotDesconto(parseFloat(ret.vlr_tot_desconto));
      setVlrFrete(parseFloat(ret.vlr_frete));
      setVlrTotIpi(parseFloat(ret.vlr_tot_ipi));
      setVlrTotTroca(parseFloat(ret.vlr_tot_troca));
      setVlrTotBonificacao(parseFloat(ret.vlr_tot_bonificacao));
      setRepresentacao(ret.id_representacao);
      if (!clonar) {
        setEmissao(dateFromLocaleString(ret.emissao));
        setPrevisaoEntrega(dateFromLocaleString(ret.previsao_entrega));
      }
      setDescricaoStatus(ret.descricao_status);
      setFormaPagto(ret.forma_pagto);
      setIdCondPag(ret.id_cond_pag);
      setObs(ret.obs);
      setVlrDescSobreTotal(parseFloat(ret.vlr_desc_sob_total));
      if (!clonar) {
        setIdColaborador(ret.id_colab_vendedor);
      }

      setItens(
        ret.itens.map((e) => {
          // Garante que produtos originais e adicionados sigam o mesmo esquema

          // ATENÇÃO: Ainda que nem todos os campos sejam exibidos na grade,
          // eles são esperados no esquema dos itens da rota de alteração
          return {
            tipo: e.tipo,
            desc_tipo: e.desc_tipo,
            id_produto: e.id_produto,
            nome_produto: e.nome_produto,
            revenda: e.revenda,
            cfop: e.cfop,
            quantidade: parseFloat(e.quantidade),
            vlr_item: parseFloat(e.vlr_item),
            vlr_tot_desc: parseFloat(e.vlr_tot_desc),
            vlr_tot_icms_st: parseFloat(e.vlr_tot_icms_st),
            vlr_tot_ipi: parseFloat(e.vlr_tot_ipi),
            vlr_tot_prod: parseFloat(e.vlr_tot_prod),
            vlr_total: parseFloat(e.vlr_total),
            vlr_tot_troca: parseFloat(e.vlr_tot_troca),
            vlr_tot_bonif: parseFloat(e.vlr_tot_bonificacao),
            vlr_unit: parseFloat(e.vlr_unit),
            perc_desc: parseFloat(e.perc_desc),
            vlr_tot_acre: parseFloat(e.vlr_tot_acre),
            vlr_icms_st_unit: parseFloat(e.vlr_icms_st_unit),
            perc_ipi: parseFloat(e.perc_ipi),
            nro_ord_compra: e.nro_ord_compra,
            casas_decimais_vlrs: e.casas_decimais_vlrs,
            casas_decimais_qtd: e.casas_decimais_qtd,
            id_kit: e.id_kit,
          };
        })
      );
    } else {
      setItens([]);
    }
    return ok;
  };

  const iniciarInclusao = () => {
    setIdCliente(0);
    setRepresentacao(null);
    setObrigInfoOrdemCompraNfe(false);
    setVlrTotProd(0);
    setVlrTotIcmsSt(0);
    setVlrTotal(0);
    setVlrTotDesconto(0);
    setVlrTotTroca(0);
    setVlrTotBonificacao(0);
    setVlrFrete(0);
    setObs("");
    setVlrTotIpi(0);
    setEmissao(new Date());
    setPrevisaoEntrega(new Date());
    setFormaPagto(formasPag[0].value);
    setIdCondPag(0);
    setVlrDescSobreTotal(0);
  };

  const handleSetVlrDescSobreTotal = (v) => {
    setVlrDescSobreTotal(v);

    setVlrTotal(roundFloat(sumDataField(itens, "vlr_total") - v, 2));
  };

  const atualizarValoresCab = () => {
    setVlrTotProd(
      roundFloat(sumDataField(itensRef.current, "vlr_tot_prod"), 2)
    );
    setVlrTotIcmsSt(
      roundFloat(sumDataField(itensRef.current, "vlr_tot_icms_st"), 4)
    );
    setVlrTotal(
      roundFloat(
        sumDataField(itensRef.current, "vlr_total") - vlrDescSobreTotal,
        2
      )
    );
    setVlrTotDesconto(
      roundFloat(sumDataField(itensRef.current, "vlr_tot_desc"), 2)
    );
    setVlrTotIpi(roundFloat(sumDataField(itensRef.current, "vlr_tot_ipi"), 2));
    setVlrTotTroca(
      roundFloat(sumDataField(itensRef.current, "vlr_tot_troca"), 2)
    );
    setVlrTotBonificacao(
      roundFloat(sumDataField(itensRef.current, "vlr_tot_bonif"), 2)
    );
  };

  const handleDeleteItem = (i) => {
    setItens(itensRef.current.filter((e, index) => index !== i));
    atualizarValoresCab();
  };

  const handleAddItem = (item) => {
    setItens([...itensRef.current, item]);
    atualizarValoresCab();
  };

  const handleEditItem = (item) => {
    let prods = itensRef.current;
    const index = prods.findIndex(
      (e) => e.id_produto === item.id_produto && e.tipo === item.tipo
    );
    prods[index] = item;
    setItens(prods);
    atualizarValoresCab();
  };

  const toggleEdit = (idProd, tipoItem) =>
    incluirAlterarRef.current.toggle(MODAL_ACTIONS.EDIT, idProd, tipoItem);

  const handleSetFormaPagto = (v) => {
    setFormaPagto(v);
    if (v !== "BOLE") {
      setIdCondPag(null);
    }
  };

  const handleSubmit = async () => {
    if (loadingSubmit) return;
    setLoadingSubmit(true);

    // Campos em comum na alteração e na inclusão
    const payloadIntersec = {
      id_cliente: idCliente,
      previsao_entrega: formatDateISO(previsaoEntrega),
      vlr_tot_prod: vlrTotProd,
      vlr_tot_icms_st: vlrTotIcmsSt,
      vlr_tot_desconto: vlrTotDesconto,
      vlr_tot_ipi: vlrTotIpi,
      vlr_desc_sobre_total: vlrDescSobreTotal ?? 0,
      vlr_total: vlrTotal,
      vlr_tot_troca: vlrTotTroca,
      vlr_tot_bonificacao: vlrTotBonificacao,
      vlr_tot_flex: 0,
      id_vendedor: idColaborador,
      observ: obs,
      forma_pagto: [
        {
          moeda: formaPagto,
          id_cond_pag: idCondPag,
          valor: vlrTotal,
        },
      ],
      id_representacao: representacao,
      itens: itens.map((e) => {
        let item = {
          tipo: e.tipo,
          id_produto: e.id_produto,
          nome_produto: e.nome_produto,
          revenda: e.revenda,
          quantidade: e.quantidade,
          vlr_item: e.vlr_item,
          vlr_tot_desc: e.vlr_tot_desc,
          vlr_tot_icms_st: e.vlr_tot_icms_st,
          vlr_tot_ipi: e.vlr_tot_ipi,
          vlr_tot_prod: e.vlr_tot_prod,
          vlr_total: e.vlr_total,
          vlr_tot_troca: e.vlr_tot_troca,
          vlr_tot_bonif: e.vlr_tot_bonif,
          vlr_unit: e.vlr_unit,
          perc_desc: e.perc_desc,
          vlr_tot_acre: e.vlr_tot_acre,
          vlr_icms_st_unit: e.vlr_icms_st_unit,
          perc_ipi: e.perc_ipi,
          nro_ord_compra: e.nro_ord_compra ?? null,
          id_kit: e.id_kit ?? null,
        };

        if (action === MODAL_ACTIONS.ADD) {
          item = {
            ...item,
            vlr_tot_flex: 0,
            tipo_desc: "NORM",
            id_regra_desc_mov: null,
          };
        }

        return item;
      }),
    };

    const payload =
      action === MODAL_ACTIONS.ADD
        ? {
            ...payloadIntersec,
            id_pedido_app: 0,
            data_emissao: formatDateISO(emissao),
            data_hora_ini_app: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            data_hora_fim_app: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
            vlr_tot_flex: 0,
          }
        : {
            ...payloadIntersec,
            id_pedido: idPedido,
          };

    const submitFunc =
      action === MODAL_ACTIONS.ADD
        ? CentralPedidosService.incluir
        : CentralPedidosService.alterar;

    const [ok] = await submitFunc(payload);
    if (ok) {
      setRedirect(true);
    }

    setLoadingSubmit(false);
  };

  const iniciarTela = async () => {
    if (!(await buscarParametros())) {
      history.goBack();
      return;
    }

    if (action === MODAL_ACTIONS.EDIT) {
      if (!(await carregarDados())) {
        history.goBack();
        return;
      }
    } else {
      setIdColaborador(parseInt(localStorage.getItem("id_colaborador")));
      iniciarInclusao();
      if (![0, null, undefined].includes(idPedidoClonar)) {
        if (!(await carregarDados(true))) {
          history.goBack();
          return;
        }
      }
    }
    setLoading(false);
  };

  const onActivate = () => {
    iniciarTela();
  };

  useEffect(onActivate, []);

  const onKeyDown = (e) => {
    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (e.key === "F9" && !loadingImpostos) handleSubmit();
    }
  };

  const buscarLimiteContasReceberCliente = async () => {
    const [ok, ret] = await CadastroClienteService.buscarLimites(idCliente);
    if (ok) {
      setClienteTemLimiteContasRec(ret.tem_limite_contas_receber);
      setLimiteClienteContasRec(parseFloat(ret.limite_contas_receber));
      setLimiteDispClienteContasRec(parseFloat(ret.limite_disponivel));
    } else {
      setClienteTemLimiteContasRec(false);
      setLimiteClienteContasRec(0);
      setLimiteDispClienteContasRec(0);
    }
  };

  const configLimitesContasReceberCliente = () => {
    if (idCliente) {
      buscarLimiteContasReceberCliente();
    } else {
      setClienteTemLimiteContasRec(false);
      setLimiteClienteContasRec(0);
      setLimiteDispClienteContasRec(0);
    }
  };

  useEffect(configLimitesContasReceberCliente, [idCliente]);

  const handleIncluirKit = async (itensKit) => {
    const itensIncluir = [];

    for (const item of itensKit) {
      const itemJaExiste = itens.find((e) => {
        return (
          e.id_produto === item.idProduto &&
          e.tipo === TIPO_ITEM_VENDA &&
          e.id_kit === item.idKit
        );
      });
      if (itemJaExiste) {
        showWarning(
          "O kit já foi incluído no pedido. Se precisar adicionar mais " +
            "unidades, remova do pedido todos os itens que compõem " +
            "este kit e tente novamente, ou altere manualmente as " +
            "quantidades de cada um dos itens"
        );
        return false;
      }

      if (item.quantidade <= 0) {
        showWarning(
          `A quantidade do produto ${item.nomeProduto} está ` +
            "zerada. Por favor, ajuste o cadastro do kit e tente novamente"
        );
        return false;
      }

      if (item.vlrItem < 0) {
        showWarning(
          `Por favor, verifique o valor do item ${item.nomeProduto}.`
        );
        return false;
      }

      const _vlrTotProd = item.vlrTotal;
      let modeloNf = 55;

      if (
        params.cad_cli_permite_identif_mod_nfe === true &&
        modeloNfeCliente === "NFC"
      ) {
        modeloNf = 65;
      }

      const [okTrib, trib] = await UteisService.calcularImpostosProdutoV2(
        item.idProduto,
        idCliente,
        "VENDA",
        modeloNf,
        true,
        item.cfop,
        item.quantidade,
        item.vlrTotal,
        0
      );

      if (!okTrib) {
        showWarning(
          `Erro ao calcular os impostos do produto ${item.nomeProduto}`
        );
        return false;
      }

      const _vlrIcmsSt = parseFloat(trib.vlr_icms_st);
      const _vlrIpi = parseFloat(trib.vlr_ipi);
      const _percIpi = parseFloat(trib.perc_ipi);
      const _vlrTotal = parseFloat(trib.vlr_total_com_impostos);

      item.vlrIcmsStUnit = roundFloat(_vlrIcmsSt / item.quantidade, 2);
      item.vlrIcmsSt = roundFloat(_vlrIcmsSt, 2);
      item.percIpi = roundFloat(_percIpi, 2);
      item.vlrIpi = roundFloat(_vlrIpi, 2);
      item.vlrTotProd = roundFloat(_vlrTotProd, 2);
      item.vlrTotal = roundFloat(_vlrTotal, 2);
      item.vlrTotTroca = 0;
      item.vlrTotBonificacao = 0;

      itensIncluir.push({
        tipo: TIPO_ITEM_VENDA,
        desc_tipo: "Venda",
        id_produto: item.idProduto,
        nome_produto: item.nomeProduto,
        revenda: true,
        cfop: item.cfop,
        quantidade: item.quantidade,
        vlr_unit: item.vlrUnit,
        vlr_item: item.vlrItem,
        perc_desc: item.percDesc,
        vlr_tot_desc: item.vlrTotDesc,
        vlr_tot_acre: 0, // Kit não tem acréscimo
        vlr_icms_st_unit: item.vlrIcmsStUnit,
        vlr_tot_icms_st: item.vlrIcmsSt,
        perc_ipi: item.percIpi,
        vlr_tot_ipi: item.vlrIpi,
        vlr_tot_prod: item.vlrTotProd,
        vlr_total: item.vlrTotal,
        vlr_tot_troca: item.vlrTotTroca,
        vlr_tot_bonif: item.vlrTotBonificacao,
        nro_ord_compra: null,
        casas_decimais_vlrs: item.casasDecimaisVlrs,
        casas_decimais_qtd: item.casasDecimaisQtd,
        id_kit: item.idKit,
      });
    }

    for (const itemIncluir of itensIncluir) {
      handleAddItem(itemIncluir);
    }

    return true;
  };

  const verifTemItemVenda = () => {
    if ((itens ?? []).length > 0) {
      // Se nenhum item do pedido for de venda, bloqueia o campo de forma
      // de pagamento e define a forma de pagamento como EM MÃOS
      if (!itens.some((e) => e.tipo === TIPO_ITEM_VENDA)) {
        handleSetFormaPagto("MAOS");
      }
    }
  };

  useEffect(verifTemItemVenda, [itens]);

  return (
    <PageContainer
      title={
        action === MODAL_ACTIONS.ADD
          ? "Inclusão de Pedido"
          : `Alteração do Pedido Nº ${idPedido}`
      }
      number="0034_4"
      canGoBack
      onKeyDown={onKeyDown}
      loading={loading}
    >
      {redirect && (
        <Redirect
          to={{
            pathname: centralPedidosRoute.path,
            state: { refresh: true },
          }}
        />
      )}
      <Card body>
        <Row>
          <AsyncComboBox
            md={4}
            isConcatField
            concatModelName="cliente"
            defaultOptions={action === MODAL_ACTIONS.EDIT}
            label="Cliente"
            isSearchable
            onChange={(s) => {
              setIdCliente(s?.value);
              setObrigInfoOrdemCompraNfe(
                s?.obrig_info_ordem_compra_nfe ?? false
              );
              setModeloNfeCliente(s?.modelo_nfe ?? "NFE");
            }}
            defaultValue={idCliente}
          />
          {params.habilita_identif_representacao === true && (
            <AsyncComboBox
              label={"Representação"}
              concatModelName={"representacao"}
              isConcatField
              isSearchable
              onChange={(v) => setRepresentacao(v?.value)}
              defaultValue={representacao}
            />
          )}
          <DatePicker md={2} disabled label="Emissão" value={emissao} />
          <DatePicker
            label="Previsão de Entrega"
            md={2}
            value={previsaoEntrega}
            onChange={(v) =>
              setPrevisaoEntrega(moment.isMoment(v) ? v.toDate() : v)
            }
          />
          {action === MODAL_ACTIONS.EDIT && (
            <>
              <NumberInput md={2} disabled label="Frete" value={vlrFrete} />
              <Col style={{ display: "flex" }} md={2}>
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    height: "2rem",
                    alignSelf: "end",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: corStatus(descricaoStatus),
                      width: "0.75rem",
                      height: "0.75rem",
                      borderRadius: "50%",
                      marginRight: "0.5rem",
                    }}
                  />
                  <span style={{ height: "min-content", fontWeight: "bold" }}>
                    {descricaoStatus}
                  </span>
                </div>
              </Col>
            </>
          )}
        </Row>
        <Row>
          <AsyncComboBox
            md={4}
            isConcatField
            concatModelName="colaborador"
            defaultOptions
            label="Vendedor"
            isSearchable
            onChange={(s) => setIdColaborador(s?.value)}
            defaultValue={idColaborador}
          />
        </Row>
      </Card>
      <Card body>
        <Row className="mb-2">
          <FormButton
            md="auto"
            divClassName="ml-auto"
            color="secondary"
            padded={false}
            onClick={toggleKits}
            disabled={[0, null, undefined].includes(idCliente)}
            disabledHint="Selecione o cliente do pedido"
          >
            Adicionar Kit
          </FormButton>
          <IncluirAlterarProdutoModal
            action={MODAL_ACTIONS.ADD}
            idCliente={idCliente}
            vlrFrete={vlrFrete}
            handleAdd={handleAddItem}
            handleEdit={handleEditItem}
            itens={itens}
            ref={incluirAlterarRef}
            permiteManipularVlrUnitario={params.permite_manipular_vlr_unitario}
            obrigInfoOrdemCompraNfe={obrigInfoOrdemCompraNfe}
            usaModeloNfeCliente={params.cad_cli_permite_identif_mod_nfe}
            modeloNfeCliente={modeloNfeCliente}
            usaPrecoAtacado={params.usa_preco_atacado}
          />
        </Row>
        <PedidoItensGrid
          data={itens}
          identificaProdutoRevenda={params.identifica_produto_revenda}
          handleDelete={handleDeleteItem}
          toggleEdit={toggleEdit}
          idCliente={idCliente}
          vlrFrete={vlrFrete}
          atualizarValoresCab={atualizarValoresCab}
          setLoadingImpostos={setLoadingImpostos}
        />
      </Card>
      <Card body>
        <Divider className="mt-1">Pagamento</Divider>
        <Row>
          <ComboBox
            md={3}
            label="Forma de Pagamento"
            options={formasPag}
            defaultValue={formaPagto}
            onChange={(s) => handleSetFormaPagto(s?.value)}
            isDisabled={!temItemVenda}
            isSearchable={false}
          />
          {formaPagto === "BOLE" && (
            <>
              <AsyncComboBox
                md={3}
                label="Condição de Pagamento"
                isConcatField
                concatModelName="cond_pag"
                defaultOptions
                isSearchable
                defaultValue={idCondPag}
                onChange={(s) => setIdCondPag(s?.value ?? null)}
              />
              {clienteTemLimiteContasRec === true && (
                <Row>
                  <FixedField
                    horizontal
                    padded
                    divStyle={{ marginBottom: "11px" }}
                    label="Limite Cliente"
                    value={formatNumber(limiteClienteContasRec, false, 2)}
                  />
                  <FixedField
                    horizontal
                    padded
                    divStyle={{ marginBottom: "11px" }}
                    label="Limite Disponível"
                    value={formatNumber(limiteDispClienteContasRec, false, 2)}
                    textStyle={
                      limiteDispClienteContasRec < 0 ||
                      limiteDispClienteContasRec < vlrTotal
                        ? { color: "red" }
                        : {}
                    }
                  />
                </Row>
              )}
            </>
          )}
        </Row>
      </Card>
      <Card body>
        <Row>
          <TextInput
            name="obs"
            label="Observação"
            type="textarea"
            inputStyle={{ resize: "none" }}
            rows={4}
            md={8}
            value={obs}
            onChange={(e, v) => setObs(v)}
          />
          <NumberInput
            md={2}
            label="Desconto Sobre Total"
            value={vlrDescSobreTotal}
            onChange={handleSetVlrDescSobreTotal}
          />
          <FormButton
            md="auto"
            color="success"
            divClassName="ml-auto mt-auto"
            onClick={handleSubmit}
            loading={loadingSubmit}
            disabled={loadingImpostos}
          >
            Confirmar
          </FormButton>
        </Row>
      </Card>
      <PesqKitProdutoModal
        isOpen={modalKitsOpen}
        toggle={toggleKits}
        onConfirm={handleIncluirKit}
        usaPrecoAtacado={params.usa_preco_atacado}
      />
    </PageContainer>
  );
};
