import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { apiGet } from "../api";
import FormButton from "./FormButton";
import ModalHeaderActionArea from "./ModalHeaderActionArea";
import ModalHeaderActionCheckbox from "./ModalHeaderActionCheckbox";
import NumberPageModal from "./NumberPageModal";
import { filterFormFocusableElements } from "../coreUtils";

export const ModalBase = forwardRef(
  (
    {
      isOpen,
      toggle,
      size = "md",
      confirmButtonText = "Confirmar",
      cancelButtonText = "Sair",
      title,
      number,
      footerActions,
      autoFocus = false,
      centered = true,
      unmountOnClose = true,
      returnFocusAfterClose,
      headless = false,
      hideCancelButton,
      parameterized,
      headerCheck,
      paramsName,
      onOpened,
      onClosed,
      onConfirm,
      onKeyDown,
      onBeforeOpen,
      onBeforeClose,
      onCancel,
      loadingConfirm,
      bodyStyle,
      headerStyle,
      footerStyle,
      contentClassName,
      modalStyle = {},
      numberStyle = {},
      name,
      children,
      paddedButtons = false,
      disableOnConfirm,
    },
    ref
  ) => {
    const formRef = useRef();
    const modalRef = useRef();
    const confirmarRef = useRef();

    const onOpenedInternal = () => {
      if (!document.body.className.includes("modal-open")) {
        document.body.className =
          `${document.body.className} modal-open`.trim();
      }
      if (!formRef.current) return;
      const focusableElements = filterFormFocusableElements(
        formRef.current?.elements
      );

      if (
        !autoFocus &&
        focusableElements.length > 0 &&
        focusableElements[0]?.focus
      ) {
        focusableElements[0].focus();
      }

      if (onOpened) onOpened();
    };

    const onClosedInternal = () => {
      if (document.getElementsByClassName('modal show').length === 0){
        document.body.className = document.body.className
          .replace("modal-open", "")
          .trim();  
      }
      if (onClosed) onClosed();
    };

    const onKeyDownInternal = (e) => {
      const modals = Array.from(document.getElementsByClassName("modal"));

      if (modals.indexOf(modalRef.current) !== modals.length - 1) return;

      if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
        if (e.key === "F9" && !disableOnConfirm && !loadingConfirm) onConfirm();
      }

      if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
        if (e.key === "Escape" && onCancel && !loadingConfirm) onCancel();
      }

      if (onKeyDown) onKeyDown(e);
    };

    const onBeforeOpenInternal = async () => {
      if (onBeforeOpen) {
        let params = undefined;
        if (paramsName) {
          params = await apiGet(`/tela/${paramsName}/`);
        }
        onBeforeOpen(params);
      }
    };

    const onBeforeCloseInternal = () => {
      if (onBeforeClose) onBeforeClose();
    };

    useImperativeHandle(ref, () => ({
      innerRef: modalRef,
    }));

    useEffect(() => {
      if (isOpen) {
        onBeforeOpenInternal();
      } else {
        onBeforeCloseInternal();
      }
    }, [isOpen]);

    useEffect(() => {
      if (isOpen) {
        document.addEventListener("keydown", onKeyDownInternal);
      }

      return () => document.removeEventListener("keydown", onKeyDownInternal);
    }, [isOpen, onKeyDownInternal]);

    return (
      <Modal
        isOpen={isOpen}
        autoFocus={autoFocus}
        size={size}
        centered={centered}
        toggle={toggle}
        onOpened={onOpenedInternal}
        onClosed={onClosedInternal}
        backdrop="static"
        innerRef={modalRef}
        name={name}
        unmountOnClose={unmountOnClose}
        style={modalStyle}
        contentClassName={contentClassName}
        returnFocusAfterClose={returnFocusAfterClose}
      >
        {!headless && (
          <ModalHeader toggle={toggle} style={headerStyle}>
            <span name="modal-title">{title}</span>
            {headerCheck && (
              <ModalHeaderActionArea>
                <ModalHeaderActionCheckbox
                  isActive={headerCheck.value}
                  label={headerCheck.title ?? "Ativo"}
                  toggleCheck={() => headerCheck.toggle()}
                />
              </ModalHeaderActionArea>
            )}
          </ModalHeader>
        )}
        <form onSubmit={(e) => e.preventDefault()} ref={formRef}>
          <ModalBody style={bodyStyle}>{children}</ModalBody>
          {(number || footerActions || onConfirm) && (
            <ModalFooter style={footerStyle}>
              {number && (
                <NumberPageModal
                  parametrizado={parameterized}
                  style={numberStyle}
                >
                  {number}
                </NumberPageModal>
              )}
              {footerActions}
              {onConfirm && (
                <FormButton
                  padded={paddedButtons}
                  divClassName="pr-0"
                  md="auto"
                  color="success"
                  loading={loadingConfirm}
                  onClick={onConfirm}
                  innerRef={confirmarRef}
                  disabled={disableOnConfirm}
                >
                  F9 - {confirmButtonText}
                </FormButton>
              )}
              {!hideCancelButton && (
                <FormButton
                  padded={paddedButtons}
                  divClassName="pr-0"
                  md="auto"
                  color="danger"
                  onClick={() => {
                    if (onCancel) {
                      onCancel();
                    }
                    if (toggle) {
                      toggle();
                      if (returnFocusAfterClose && onClosed) {
                        onClosed();
                      }
                    }
                  }}
                >
                  Esc - {cancelButtonText}
                </FormButton>
              )}
            </ModalFooter>
          )}
        </form>
      </Modal>
    );
  }
);
