import React from "react";
import { IconButton, Table, TableDelete } from "../../../../../../components";
import { TrocarProprietarioVeicModal } from "../../../../../ordens_servico/central_os/incluir_alterar/components/TrocarProprietarioVeicModal";
import {
  combustivelOptions,
  MASK_PLACA_AUTOMOTIVA_VEIC_CLI,
  MASK_PLACA_NAUTICA_VEIC_CLI,
} from "../LigacaoVeicCli";
import { formatValueFromAPI } from "../../../../../../coreUtils";
import { LuPencilLine } from "react-icons/lu";

export const LigacaoVeicCliGrid = ({
  dados,
  excluir,
  notifyTrocarProprietario,
  alterarVeiculo,
  atualizarVeiculo,
  trocarVeiculo,
}) => {
  const columns = [
    { dataField: "id", text: "#", align: "center" },
    { dataField: "nome_montadora", text: "Montadora", align: "left" },
    {
      dataField: "nome_veiculo",
      text: "Veículo",
      align: "left",
      formatter: (c, row) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {formatValueFromAPI(c, row.id_veiculo)}{" "}
          <div className="ml-auto">
            <IconButton
              icon={LuPencilLine}
              color="#dba400"
              size={16}
              hint="Trocar Veículo"
              tooltipPlacement="right"
              onClick={() => trocarVeiculo(row)}
            />
          </div>
        </div>
      ),
    },
    {
      dataField: "placa",
      text: "Placa",
      align: "center",
      editable: true,
      editorType: "masked",
      editorMask: (row) =>
        row.placa_nautica
          ? MASK_PLACA_NAUTICA_VEIC_CLI
          : MASK_PLACA_AUTOMOTIVA_VEIC_CLI,
      alwaysShowEditor: true,
      onChange: alterarVeiculo,
      onBlur: atualizarVeiculo,
      colWidth: "70px",
      editorMaxLength: 10,
    },
    {
      dataField: "modelo",
      text: "Modelo",
      align: "left",
      editable: true,
      editorType: "text",
      alwaysShowEditor: true,
      onChange: alterarVeiculo,
      onBlur: atualizarVeiculo,
      colWidth: "120px",
      editorMaxLength: 60,
    },
    {
      dataField: "cor",
      text: "Cor",
      align: "left",
      editable: true,
      editorType: "text",
      alwaysShowEditor: true,
      onChange: alterarVeiculo,
      onBlur: atualizarVeiculo,
      colWidth: "90px",
      editorMaxLength: 30,
    },
    {
      dataField: "ano",
      text: "Ano",
      align: "center",
      editable: true,
      editorType: "text",
      alwaysShowEditor: true,
      onChange: alterarVeiculo,
      onBlur: atualizarVeiculo,
      colWidth: "80px",
      editorMaxLength: 10,
    },
    {
      dataField: "combustivel",
      text: "Combustível",
      align: "left",
      editorType: "combobox",
      editable: true,
      alwaysShowEditor: true,
      onChange: alterarVeiculo,
      onBlur: atualizarVeiculo,
      colWidth: "80px",
      options: combustivelOptions,
    },
    {
      dataField: "chassi",
      text: "Chassi",
      align: "center",
      editable: true,
      editorType: "text",
      alwaysShowEditor: true,
      onChange: alterarVeiculo,
      onBlur: atualizarVeiculo,
      colWidth: "150px",
      editorMaxLength: 30,
    },
    {
      dataField: "tp",
      isDummyField: true,
      align: "center",
      formatter: (cell, row) => (
        <TrocarProprietarioVeicModal
          placa={row.placa}
          notifyEvent={notifyTrocarProprietario}
          grid
        />
      ),
    },
    {
      dataField: "del",
      isDummyField: true,
      align: "center",
      formatter: (cell, row) => <TableDelete onClick={() => excluir(row.id)} />,
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      pageSize={10}
      readOnly
      paginated={false}
    />
  );
};
