import React, { useState, useEffect } from "react";
import { ModalBase } from "../../../../../components";
import { Col, Row, Spinner } from "reactstrap";
import { LuXOctagon } from "react-icons/lu";
import { BsCheckLg } from "react-icons/bs";
import { PiWarningDiamondFill } from "react-icons/pi";
import useWebSocket from "react-use-websocket";
import NotaFiscalConsumidorService from "../../../../../services/docs_eletron/NotaFiscalConsumidorService";
import { isDevEnv } from "../../../../../coreUtils";
import settings from "../../../../../settings";
import CaixaLojaService from "../../../../../services/financeiro/CaixaLojaService";

const STATUS = {
  LOADING: 0,
  SUCCESS: 1,
  ERROR: 2,
  WARNING: 3,
  FATURAR: 4,
};

export const TentarProcPagTefNovamenteModal = ({
  isOpen,
  toggle,
  notifyEvent,
  idMovCxaLoja,
}) => {
  const [status, setStatus] = useState(STATUS.LOADING);
  const [msgStatus, setMsgStatus] = useState("Processando Recebimento");
  const [socketUrl, setSocketUrl] = useState(null);
  const [nfeImpressa, setNfeImpressa] = useState(false);
  const { lastJsonMessage } = useWebSocket(socketUrl);

  useEffect(() => {
    setStatus(STATUS.LOADING);
    setMsgStatus("Processando Recebimento");
    setSocketUrl(null);
  }, [isOpen, setStatus]);

  const imprimirNFCe = async (idNFCe) => {
    if (nfeImpressa) return;

    try {
      setMsgStatus("Gerando Impressão da NFC-e");
      await NotaFiscalConsumidorService.imprimir(idNFCe);
      setNfeImpressa(true);
    } finally {
      setStatus(STATUS.SUCCESS);
      setMsgStatus("Recebimento Processado");
      setTimeout(() => {
        toggle();
        setTimeout(() => {
          notifyEvent();
        }, 300);
      }, 2500);
    }
  };

  useEffect(() => {
    if (lastJsonMessage) {
      if (lastJsonMessage.status === "APPROVED") {
        // Verificar retorno do WebSocket
        // 1 - Se houver mais pagamentos TEF, conecta no próximo socket
        // 2 - Se não houverem mais pagamentos, imprime a NFC-e se foi emitida
        //     com sucesso
        if (lastJsonMessage.tem_mais_pags_tef === true) {
          let wsAddress;
          if (isDevEnv()) {
            wsAddress = settings.WS_TEF_DEV;
          } else {
            wsAddress = settings.WS_TEF;
          }
          setSocketUrl(
            `${wsAddress}/tef/status/${lastJsonMessage.correlation_id_prox_pag}/`
          );
        } else {
          if (lastJsonMessage.tem_nfce === true) {
            if (lastJsonMessage.erro_faturar_nf === true) {
              setStatus(STATUS.WARNING);
              setMsgStatus(
                "Ocorreu uma falha ao faturar a NFC-e. Por favor, faça o " +
                  "faturamento da venda/O.S. manualmente"
              );
              setTimeout(() => {
                toggle();
                setTimeout(() => {
                  notifyEvent();
                }, 300);
              }, 5000);
            } else {
              if (lastJsonMessage.nf_emitida === true) {
                imprimirNFCe(lastJsonMessage.id_nf);
              } else {
                setStatus(STATUS.WARNING);
                setMsgStatus(
                  "NFC-e faturada, mas ocorreu um erro durante a emissão. Por favor, " +
                    "faça a emissão manualmente"
                );
                setTimeout(() => {
                  toggle();
                  setTimeout(() => {
                    notifyEvent();
                  }, 300);
                }, 5000);
              }
            }
          } else {
            setStatus(STATUS.SUCCESS);
            setMsgStatus(
              "Não foram identificados produtos na Venda/O.S., portanto não " +
                "é necessária emissão de NFC-e"
            );
            setTimeout(() => {
              toggle();
              setTimeout(() => {
                notifyEvent();
              }, 300);
            }, 2500);
          }
        }
      } else {
        setStatus(STATUS.ERROR);
        setMsgStatus(lastJsonMessage.msg);
      }
    }
  }, [lastJsonMessage]);

  const handleSubmit = async () => {
    setStatus(STATUS.LOADING);
    setMsgStatus("Processando Recebimento");

    const [ok, ret] = await CaixaLojaService.processarRecebimentoTefNovamente(
      idMovCxaLoja
    );
    if (ok) {
      let wsAddress;
      if (isDevEnv()) {
        wsAddress = settings.WS_TEF_DEV;
      } else {
        wsAddress = settings.WS_TEF;
      }
      setSocketUrl(`${wsAddress}/tef/status/${ret.res.id_correlacao}/`);
      setMsgStatus("Processando transação TEF");
    } else {
      setStatus(STATUS.ERROR);
      setMsgStatus(ret);
    }
  };

  let contentClassName = "";
  if (status === STATUS.ERROR) {
    contentClassName = "modal-erro";
  } else if (status === STATUS.LOADING) {
    contentClassName = "modal-loading";
  } else if (status === STATUS.WARNING) {
    contentClassName = "modal-aviso";
  } else if (status === STATUS.SUCCESS) {
    contentClassName = "modal-sucesso";
  }

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={[STATUS.ERROR].includes(status) ? toggle : () => {}}
      size="md"
      title="Recebimento do Caixa Loja"
      number="0063_17"
      hideCancelButton={true}
      onBeforeOpen={handleSubmit}
      confirmButtonText="Ok"
      headless
      footerStyle={{ padding: "0.5rem 1rem" }}
      contentClassName={contentClassName}
    >
      <>
        <Row>
          <Col md={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {status === STATUS.LOADING && (
                <Spinner
                  size="md"
                  style={{
                    width: "75px",
                    height: "75px",
                    border: "0.8rem solid #248086",
                    borderRightColor: "transparent",
                  }}
                  color="#248086"
                />
              )}
              {status === STATUS.SUCCESS && (
                <BsCheckLg
                  size={30}
                  color="green"
                  style={{ width: "75px", height: "75px" }}
                />
              )}
              {status === STATUS.WARNING && (
                <PiWarningDiamondFill
                  size={5}
                  color="gold"
                  style={{ width: "75px", height: "75px" }}
                />
              )}
              {status === STATUS.ERROR && (
                <LuXOctagon
                  size={30}
                  color="#b3281f"
                  style={{ width: "75px", height: "75px" }}
                />
              )}
            </div>
          </Col>
          <Col md="9">
            <h5
              style={{
                marginTop: "1rem",
                marginBottom: "0.25rem",
              }}
            >
              {msgStatus}
              {status === STATUS.LOADING ? "..." : ""}
            </h5>
          </Col>
        </Row>
      </>
    </ModalBase>
  );
};
