import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, CustomInput, FormGroup, Label, Row, TabPane } from "reactstrap";
import { NumberInput, TextInput } from "../../../../../components";
import {
  setDistanciaInversorMedidor,
  setDistanciaModuloStringBox,
  setDistanciaStringBoxInversor,
  setLocalFixacaoInversor,
  setLocalStringBox,
  setObservInversorStringBox,
  setPontoInternet,
  setTipoComunicacaoInternet,
} from "../store/vistoria_slice";

export const TabInversorStringBox = () => {
  const vistoriaTecnica = useSelector((state) => state.vistoriaTecnica);
  const dispatch = useDispatch();
  return (
    <TabPane tabId="6" className="p-2">
      <Row className="mb-3">
        <TextInput
          label="Local para Fixação do Inversor"
          md={6}
          value={vistoriaTecnica.localFixacaoInversor}
          onChange={({ target }) => {
            dispatch(setLocalFixacaoInversor(target?.value));
          }}
          maxLength={100}
        />
      </Row>
      <Row className="mb-3">
        <TextInput
          label="Local para String Box"
          md={6}
          value={vistoriaTecnica.localStringBox}
          onChange={({ target }) => {
            dispatch(setLocalStringBox(target?.value));
          }}
          maxLength={100}
        />
      </Row>
      <Row>
        <NumberInput
          label="Distância Módulos / String Box"
          md="auto"
          mdInput="8"
          placeholder="Metros"
          value={vistoriaTecnica.distanciaModuloStringBox}
          onChange={(v) => dispatch(setDistanciaModuloStringBox(v))}
          decimalPlaces={4}
        />
        <NumberInput
          label="Distância String Box / Inversor"
          md="auto"
          mdInput="8"
          placeholder="Metros"
          value={vistoriaTecnica.distanciaStringBoxInversor}
          onChange={(v) => dispatch(setDistanciaStringBoxInversor(v))}
          decimalPlaces={4}
        />
        <NumberInput
          label="Distância Inversor / Medidor"
          md="auto"
          mdInput="8"
          placeholder="Metros"
          value={vistoriaTecnica.distanciaInversorMedidor}
          onChange={(v) => dispatch(setDistanciaInversorMedidor(v))}
          decimalPlaces={4}
        />
      </Row>
      <Row className="mb-3">
        <Col md="auto">
          <FormGroup
            onChange={({ target }) => {
              dispatch(setPontoInternet(target?.value));
            }}
          >
            <Label>Ponto de Internet</Label>
            <div>
              <CustomInput
                type="radio"
                name="ponto_internet"
                id="nao_ponto_internet"
                label="Não"
                value="NAO"
                inline
                checked={vistoriaTecnica.pontoInternet === "NAO"}
              />
              <CustomInput
                type="radio"
                name="ponto_internet"
                id="sim_ponto_internet"
                label="Sim"
                value="SIM"
                inline
                checked={vistoriaTecnica.pontoInternet === "SIM"}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      {vistoriaTecnica.pontoInternet === "SIM" && (
        <Row className="mb-3">
          <Col md="auto">
            <FormGroup
              onChange={({ target }) =>
                dispatch(setTipoComunicacaoInternet(target?.value))
              }
            >
              <Label>Tipo de Comunicação</Label>
              <div>
                <CustomInput
                  type="radio"
                  name="tipo_comunicacao_internet"
                  id="nao_def_tipo_comunicacao_internet"
                  label="Não Definido"
                  value="NAO"
                  inline
                  checked={vistoriaTecnica.tipoComunicacaoInternet === "NAO"}
                />
                <CustomInput
                  type="radio"
                  name="tipo_comunicacao_internet"
                  id="cabo_tipo_comunicacao_internet"
                  label="Cabo"
                  value="CABO"
                  inline
                  checked={vistoriaTecnica.tipoComunicacaoInternet === "CABO"}
                />
                <CustomInput
                  type="radio"
                  name="tipo_comunicacao_internet"
                  id="wifi_tipo_comunicacao_internet"
                  label="WI-FI"
                  value="WIFI"
                  inline
                  checked={vistoriaTecnica.tipoComunicacaoInternet === "WIFI"}
                />
                <CustomInput
                  type="radio"
                  name="tipo_comunicacao_internet"
                  id="fibra_tipo_comunicacao_internet"
                  label="Fibra"
                  value="FIBR"
                  inline
                  checked={vistoriaTecnica.tipoComunicacaoInternet === "FIBR"}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <TextInput
          md={12}
          label="Observação"
          value={vistoriaTecnica.observInversorStringBox}
          onChange={({ target }) =>
            dispatch(setObservInversorStringBox(target?.value))
          }
        />
      </Row>
    </TabPane>
  );
};
