import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  PageContainer,
  RadioGroup,
} from "../../../../components";
import { apiGetV2 } from "../../../../apiV2";
import { Card, Row } from "reactstrap";
import { RadioItem } from "../../../../components/RadioGroup";
import { ProdutosCustoVsPrecoVendaGrid } from "./components/ProdutosCustoVsPrecoVendaGrid";
import { docPrintReport } from "../../../../pdf";
import { formatDateISO } from "../../../../coreUtils";

const ordenarPorOptions = [
  { label: "Nome", value: "NOM" },
  { label: "Custo", value: "CUS" },
  { label: "Vlr. Venda", value: "VDA" },
  { label: "Vlr. Lucro", value: "LUC" },
  { label: "ID Produto", value: "IDP" },
  { label: "Referência", value: "REF" },
];

export const ProdutosCustoVsPrecoVenda = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [idsTipoCliente, setIdTipoCliente] = useState(null);
  const [idGrupo, setIdGrupo] = useState([]);
  const [idFabricante, setIdFabricante] = useState([]);
  const [idProduto, setIdProduto] = useState([]);
  const [tipoCusto, setTipoCusto] = useState("VDA");
  const [ordenarPor, setOrdenarPor] = useState("NOM");
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimr] = useState(false);

  const limparDados = () => {
    setDados([]);
    setTotais({});
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_tipo_cliente: idsTipoCliente,
      id_grupo: idGrupo,
      id_fabricante: idFabricante,
      id_produto: idProduto,
      tipo_custo: tipoCusto,
      ordenar_por: ordenarPor,
    };
    return apiGetV2("/relatorios/venda/produtos_custo_vs_preco_venda/", params);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      setDados(ret.produtos);
      setTotais(ret.totais);
    } else {
      limparDados();
    }
    setLoading(false);
  };

  const handleImprimir = async () => {
    setLoadingImprimr(true);
    const [ok, ret] = await buscarDados();

    const payload = {
      produtos: ret.produtos,
      totais: ret.totais,
      data_ini: formatDateISO(dataIni),
      data_fim: formatDateISO(dataFim),
    };

    if (ok) {
      docPrintReport(
        "/relatorios/venda/produtos_custo_vs_preco_venda/",
        payload,
        "5068",
        true
      );
      setLoadingImprimr(false);
    }
  };

  useEffect(() => {
    limparDados();
  }, [
    dataIni,
    dataFim,
    idsTipoCliente,
    idGrupo,
    idFabricante,
    idProduto,
    tipoCusto,
  ]);

  return (
    <PageContainer title="Produtos - Custo vs Preço Venda" number="5068">
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            label="Tipo de Cliente"
            concatModelName="tipo_cliente"
            isMulti
            hideShortcut
            defaultOptions
            onChange={(s) => setIdTipoCliente(s?.map((e) => e.value) ?? [])}
          />
          <AsyncComboBox
            md={3}
            label="Grupo"
            concatModelName="grupo"
            isMulti
            hideShortcut
            onChange={(s) => setIdGrupo(s?.map((e) => e.value) ?? [])}
          />
          <AsyncComboBox
            md={3}
            label="Fabricante"
            concatModelName="fabricante"
            isMulti
            hideShortcut
            onChange={(s) => setIdFabricante(s?.map((e) => e.value) ?? [])}
          />
          <AsyncComboBox
            md={3}
            label="Produto"
            concatModelName="produto"
            isMulti
            hideShortcut
            onChange={(s) => setIdProduto(s?.map((e) => e.value) ?? [])}
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <RadioGroup
            label="Custo a ser considerado"
            value={tipoCusto}
            onChange={setTipoCusto}
            divClassName="mb-2"
          >
            <RadioItem label="Custo na Data da Venda" value="VDA" />
            <RadioItem label="Custo Atual do Produto" value="CAD" />
          </RadioGroup>
          <ComboBox
            md={2}
            label="Ordenar Por"
            options={ordenarPorOptions}
            defaultValue={ordenarPor}
            onChange={(s) => setOrdenarPor(s?.value)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoImprimir onClick={handleImprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem label="Quantidade" value={totais?.qtd_total} />
        <CardTotaisItem label="Custo" value={totais?.vlr_custo_total} />
        <CardTotaisItem label="Vlr. Venda" value={totais?.vlr_venda_total} />
        <CardTotaisItem label="Vlr. Lucro" value={totais?.vlr_lucro_total} />
      </CardTotais>
      <Card body>
        <ProdutosCustoVsPrecoVendaGrid dados={dados} />
      </Card>
    </PageContainer>
  );
};
