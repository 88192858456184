import { docPost } from "../../../../pdf";

export const imprimirEtqL18 = async (etiquetas) => {
  try {
    let etq = etiquetas.reduce(
      (acc, e) => [...acc, ...Array(e.qtd_etiquetas).fill(e)],
      []
    );
    
    const ret = await docPost(
      "/etiquetas/layout_18/",
      etq,
      '0073',
      true
    )

    return [true, ret];
  } catch (err) {
    return [false, err.message];
  }
};
