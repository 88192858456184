import React, { useEffect, useState } from "react";
import {
  BotaoImprimir,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  FiltroPeriodoDatas,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { apiGetV2 } from "../../../../apiV2";
import { Card, Row } from "reactstrap";
import { ProducaoPeriodoGrid } from "./components/ProducaoPeriodoGrid";
import { docPrintReport } from "../../../../pdf";

export const ProducaoPeriodo = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [incluirInsumosPerdas, setIncluirInsumosPerdas] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      mostrar_insumos_perdas: true,
    };
    return apiGetV2("/relatorios/industria/producao_periodo/", params);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      setDados(ret.produtos_finais);
      setTotais(ret.totais);
    } else {
      setDados([]);
      setTotais({});
    }
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      const payload = {
        data_ini: dataIni,
        data_fim: dataFim,
        produtos_finais: ret.produtos_finais,
        totais: ret.totais,
        incluir_insumos_perdas: incluirInsumosPerdas,
      };
      await docPrintReport(
        "/relatorios/industria/producao_periodo/",
        payload,
        "5071",
        true
      );
    }
    setLoadingImprimir(false);
  };

  const onChangeFiltros = () => {
    setDados([]);
    setTotais({});
  };

  useEffect(onChangeFiltros, [dataIni, dataFim]);

  return (
    <PageContainer title="Produção por Período" number="5071">
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <FormCheckbox
            label="Incluir Insumos e Perdas"
            checked={incluirInsumosPerdas}
            onChange={() => setIncluirInsumosPerdas(!incluirInsumosPerdas)}
          />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <CardTotais md={6}>
        <CardTotaisItem
          label="Custo Total de Insumos"
          value={totais?.vlr_custo_tot_insumos}
        />
        <CardTotaisItem
          label="Valor de Venda Produzido"
          value={totais?.vlr_tot_venda_produzido}
        />
      </CardTotais>
      <Card body>
        <ProducaoPeriodoGrid dados={dados} />
      </Card>
    </PageContainer>
  );
};
