import React, { useState } from "react";
import {
  DatePicker,
  FormButton,
  ModalBase,
  TextInput,
} from "../../../../components";
import moment from "moment";
import OrdemServicoService from "../../../../services/ordem_servico/OrdemServicoService";
import { Row } from "reactstrap";
import { formatDateISO } from "../../../../coreUtils";

export const ConcluirServOSModal = ({ isOpen, toggle, idOs, notifyEvent }) => {
  const [data, setData] = useState(new Date());
  const [hora, setHora] = useState(moment(new Date()).format("HH:mm"));
  const [loading, setLoading] = useState(false);

  const resetarDataHora = () => {
    setData(new Date());
    setHora(moment(new Date()).format("HH:mm"));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      id: idOs,
      data_conc_serv: formatDateISO(data),
      hora_conc_serv: hora,
    };

    if (await OrdemServicoService.concluirServico(payload)) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title={`Concluir Serviço da O.S. Nº ${idOs}`}
      number="0006_4"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      onClosed={() => resetarDataHora()}
    >
      <Row>
        <DatePicker
          label="Data de Conclusão"
          md={5}
          value={data}
          onChange={(v) => setData(moment.isMoment(v) ? v.toDate() : v)}
        />
        <TextInput
          md={4}
          type="time"
          label="Hora"
          value={hora}
          onChange={(e, v) => setHora(v)}
        />
      </Row>
    </ModalBase>
  );
};

export const ConcluirServOSButton = ({ onClick, disabled, padded }) => (
  <FormButton
    onClick={onClick}
    md="auto"
    color="info"
    disabled={disabled}
    padded={padded}
  >
    Concluir Serviço
  </FormButton>
);
