import React, { useState } from "react";
import imagem5 from "../../../../assets/img/layouts_etq_est/5.png";
import imagem6 from "../../../../assets/img/layouts_etq_est/6.png";
import imagem7 from "../../../../assets/img/layouts_etq_est/7.png";
import imagem8 from "../../../../assets/img/layouts_etq_est/8.png";
import imagem9 from "../../../../assets/img/layouts_etq_est/9.png";
import imagem10 from "../../../../assets/img/layouts_etq_est/10.png";
import imagem11 from "../../../../assets/img/layouts_etq_est/11.png";
import imagem12 from "../../../../assets/img/layouts_etq_est/12.png";
import imagem13 from "../../../../assets/img/layouts_etq_est/13.png";
import imagem18 from "../../../../assets/img/layouts_etq_est/18.png";
import imagem21 from "../../../../assets/img/layouts_etq_est/21.png";
import imagem16 from "../../../../assets/img/layouts_etq_est/16.jpg";
import imagem17 from "../../../../assets/img/layouts_etq_est/17.jpg";
import imagem28 from "../../../../assets/img/layouts_etq_est/28.jpg";
import imagem19 from "../../../../assets/img/layouts_etq_est/19.png";
import imagem22 from "../../../../assets/img/layouts_etq_est/22.jpg";
import imagem20 from "../../../../assets/img/layouts_etq_est/20.jpg";
import imagem23 from "../../../../assets/img/layouts_etq_est/23.jpg";
import imagem31 from "../../../../assets/img/layouts_etq_est/31.png";
import imagem32 from "../../../../assets/img/layouts_etq_est/32.png";
import imagem34 from "../../../../assets/img/layouts_etq_est/34.png";
import imagem35 from "../../../../assets/img/layouts_etq_est/35.png";
import imagem36 from "../../../../assets/img/layouts_etq_est/36.png";
import imagem37 from "../../../../assets/img/layouts_etq_est/37.png";
import imagem38 from "../../../../assets/img/layouts_etq_est/38.png";
import imagem39 from "../../../../assets/img/layouts_etq_est/39.png";
import imagem40 from "../../../../assets/img/layouts_etq_est/40.png";
import imagem41 from "../../../../assets/img/layouts_etq_est/41.png";
import imagem42 from "../../../../assets/img/layouts_etq_est/42.png";
import imagem43 from "../../../../assets/img/layouts_etq_est/43.png";
import ImageViewer from "react-simple-image-viewer";
import { Divider } from "../../../../components/Divider";

const layouts = [
  {
    id: 5,
    imagem: imagem5,
    descricao: (
      <>
        <ul>
          <li>2 colunas</li>
          <li>8 linhas</li>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    imagem: imagem6,
    descricao: (
      <>
        <ul>
          <li>3 colunas</li>
          <li>10 linhas</li>
        </ul>
      </>
    ),
  },
  {
    id: 7,
    imagem: imagem7,
    descricao: (
      <>
        <ul>
          <li>3 colunas</li>
          <li>11 linhas</li>
        </ul>
      </>
    ),
  },
  {
    id: 8,
    imagem: imagem8,
    descricao: (
      <>
        <ul>
          <li>4 colunas</li>
          <li>4cm X 1,5cm</li>
        </ul>
      </>
    ),
  },
  {
    id: 9,
    imagem: imagem9,
    descricao: (
      <>
        <ul>
          <li>4 colunas</li>
        </ul>
      </>
    ),
  },
  {
    id: 10,
    imagem: imagem10,
    descricao: (
      <>
        <ul>
          <li>5 colunas</li>
          <li>13 linhas</li>
        </ul>
      </>
    ),
  },
  {
    id: 11,
    imagem: imagem11,
    descricao: (
      <>
        <ul>
          <li>2 colunas</li>
          <li>11 linhas</li>
        </ul>
      </>
    ),
  },
  {
    id: 12,
    imagem: imagem12,
    descricao: (
      <>
        <ul>
          <li>3 colunas</li>
          <li>10 linhas</li>
        </ul>
      </>
    ),
  },
  {
    id: 13,
    imagem: imagem13,
    descricao: (
      <>
        <ul>
          <li>5 colunas</li>
          <li>13 linhas</li>
        </ul>
      </>
    ),
  },
  {
    id: 18,
    imagem: imagem18,
    descricao: (
      <>
        <ul>
          <li>3 colunas</li>
          <li>10 linhas</li>
        </ul>
      </>
    ),
  },
  {
    id: 21,
    imagem: imagem21,
    descricao: (
      <>
        <ul>
          <li>3 colunas</li>
          <li>10 linhas</li>
          <li>preço em 90 graus</li>
        </ul>
      </>
    ),
  },
  {
    id: 16,
    imagem: imagem16,
    descricao: (
      <>
        <ul>
          <li>3 colunas</li>
          <li>3,30 X 2,00</li>
        </ul>
      </>
    ),
  },
  {
    id: 17,
    imagem: imagem17,
    descricao: (
      <>
        <ul>
          <li>2 colunas</li>
          <li>5,00 X 2,00</li>
        </ul>
      </>
    ),
  },
  {
    id: 28,
    imagem: imagem28,
    descricao: (
      <>
        <ul>
          <li>2 colunas</li>
          <li>4,00x3,50cm</li>
        </ul>
      </>
    ),
  },
  {
    id: 19,
    imagem: imagem19,
    descricao: (
      <>
        <ul>
          <li>2 colunas</li>
          <li>Impressão pelo Sistema Web</li>
          <li>Furada com preço destacável</li>
        </ul>
      </>
    ),
  },
  {
    id: 22,
    imagem: imagem22,
    descricao: (
      <>
        <ul>
          <li>Gôndola</li>
          <li>9,00 X 3,00</li>
        </ul>
      </>
    ),
  },
  {
    id: 20,
    imagem: imagem20,
    descricao: (
      <>
        <ul>
          <li>Gôndola</li>
          <li>9,00 X 3,00</li>
        </ul>
      </>
    ),
  },
  {
    id: 23,
    imagem: imagem23,
    descricao: (
      <>
        <ul>
          <li>2 colunas</li>
          <li>4,00x2,50cm</li>
        </ul>
      </>
    ),
  },
  {
    id: 31,
    imagem: imagem31,
    descricao: (
      <>
        <ul>
          <li>Gondola</li>
          <li>9,00x3,00cm</li>
        </ul>
      </>
    ),
  },
  {
    id: 32,
    imagem: imagem32,
    descricao: (
      <>
        <ul>
          <li>2 colunas</li>
          <li>5,00x2,50cm</li>
        </ul>
      </>
    ),
  },
  {
    id: 34,
    imagem: imagem34,
    descricao: (
      <>
        <ul>
          <li>5 colunas</li>
          <li>13 linhas</li>
        </ul>
      </>
    ),
  },
  {
    id: 35,
    imagem: imagem35,
    descricao: (
      <>
        <ul>
          <li>1 coluna</li>
          <li>6,00x4,00cm</li>
          <li>Nome Produto, Id Produto, Valor Produto, Cód. Barras e Mensagem de troca</li>
        </ul>
      </>
    ),
  },
  {
    id: 36,
    imagem: imagem36,
    descricao: (
      <>
        <ul>
          <li>1 coluna</li>
          <li>15,00x10,00cm</li>
          <li>Dados da Empresa</li>
          <li>Nome do Cliente</li>
          <li>
            Quantidade/unidade e data de fabricação preenchíveis conforme
            necessário
          </li>
          <li>Frases de Perigo/Precaução FIXAS</li>
        </ul>
      </>
    ),
  },
  {
    id: 37,
    imagem: imagem37,
    descricao: (
      <>
        <ul>
          <li>5 colunas</li>
          <li>13 linhas</li>
          <li>Código de Barras, Nome, Referência e Unidade</li>
        </ul>
      </>
    ),
  },
  {
    id: 38,
    imagem: imagem38,
    descricao: (
      <>
        <ul>
          <li>2 colunas</li>
          <li>5 linhas</li>
          <li>Informações da Nota Fiscal</li>
        </ul>
      </>
    ),
  },
  {
    id: 39,
    imagem: imagem39,
    descricao: (
      <>
        <ul>
          <li>5 colunas</li>
          <li>13 linhas</li>
          <li>Cód. Barras, Nome Prod, Localização, Referência, Unidade.</li>
        </ul>
      </>
    ),
  },
  {
    id: 40,
    imagem: imagem40,
    descricao: (
      <>
        <ul>
          <li>2 colunas</li>
          <li>5,00x2,50cm</li>
          <li>Nome Produto, Cód. Barras, Cód. Produto, Valor Produto</li>
        </ul>
      </>
    ),
  },
  {
    id: 41,
    imagem: imagem41,
    descricao: (
      <>
        <ul>
          <li>3 colunas</li>
          <li>3,30x2,20cm</li>
          <li>Mega Sul Escrito no Topo, Nome Produto, Cód. Barras Gerado Com ID Do Produto, ID Produto, Valor Produto</li>
        </ul>
      </>
    ),
  },
  {
    id: 42,
    imagem: imagem42,
    descricao: (
      <>
        <ul>
          <li>1 Coluna</li>
          <li>50mmx30mm</li>
          <li>Impressora ESC/POS</li>
          <li>Força Diesel escrito no topo, Telefone, Código, Nome Produto, Cód. Barras e Localização.</li>
        </ul>
      </>
    ),
  },
  {
    id: 43,
    imagem: imagem43,
    descricao: (
      <>
        <ul>
          <li>5 Colunas, 13 Linhas</li>
          <li>Folha A4</li>
          <li>Nome Produto, Cód. Barras, Cód. Produto, Valor Produto</li>
        </ul>
      </>
    ),
  },
];

const PrintDisplay = ({ layoutsList, openImageViewer }) => (
  <div className="print-display">
    {layoutsList.map((layout) => (
      <div className="print-item">
        <img
          src={layout.imagem}
          className="print-thumb"
          onClick={() => openImageViewer(layout.id)}
          alt=""
        />
        <div style={{ height: "min-content", marginLeft: "0.75rem" }}>
          <h5 className="mb-1">Layout {layout.id}</h5>
          <span>{layout.descricao}</span>
        </div>
      </div>
    ))}
  </div>
);

export const ImpressaoEtiquetaEstoque = () => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [idImagem, setIndexImagem] = useState(0);

  const openImageViewer = (index) => {
    setIndexImagem(index);
    setIsViewerOpen(true);
  };

  const onCloseImageViewer = () => {
    setIndexImagem(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <Divider className="mt-2">Folha A4</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) =>
          [5, 6, 7, 8, 9, 10, 11, 12, 13, 21, 18, 34, 37, 38, 39, 43].includes(item.id)
        )}
        openImageViewer={openImageViewer}
      />
      <Divider className="mt-2">Impressora Argox</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => [16, 17, 28].includes(item.id))}
        openImageViewer={openImageViewer}
      />
      <Divider className="mt-2">Impressora Zebra</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => [19, 35].includes(item.id))}
        openImageViewer={openImageViewer}
      />
      <Divider className="mt-2">Impressora Elgin L42</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => [22, 31].includes(item.id))}
        openImageViewer={openImageViewer}
      />
      <Divider className="mt-2">Impressora Elgin L42 Pro</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => [20, 23, 32, 40, 41].includes(item.id))}
        openImageViewer={openImageViewer}
      />
      <Divider className="mt-2">Impressora Zetex Z50X</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => [36].includes(item.id))}
        openImageViewer={openImageViewer}
      />
      <Divider className="mt-2">Mini impressora PT260</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => [42].includes(item.id))}
        openImageViewer={openImageViewer}
      />
      {isViewerOpen && (
        <ImageViewer
          src={[layouts.find((layout) => layout.id === idImagem)?.imagem]}
          currentIndex={0}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={onCloseImageViewer}
          backgroundStyle={{ backgroundColor: "#00000085" }}
        />
      )}
    </>
  );
};
