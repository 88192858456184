import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, TabPane } from "reactstrap";
import { NumberInput } from "../../../../../../../components";
import {
  setPercRetenCsll,
  setPercRetenIrpj,
  setPercRetenCofins,
  setPercRetenPis,
} from "../../../store/cadRegraTributacaoSlice";

export const TabRetencao = () => {
  const store = useSelector((state) => state.cadRegraTributacao);
  const dispatch = useDispatch();

  return (
    <TabPane tabId="7" className="p-2">
      <Row className="mb-2">
        <NumberInput
          label="% Retenção PIS"
          md={3}
          onChange={(v) => dispatch(setPercRetenPis(v))}
          value={store.percRetenPis}
          isPercentage
          clearOnDisable
          decimalPlaces={4}
          divClassName="pr-2"
        />
        <NumberInput
          label="% Retenção COFINS"
          md={3}
          onChange={(v) => dispatch(setPercRetenCofins(v))}
          value={store.percRetenCofins}
          isPercentage
          clearOnDisable
          decimalPlaces={4}
          divClassName="pr-2"
        />
        <NumberInput
          label="% Retenção CSLL"
          md={3}
          onChange={(v) => dispatch(setPercRetenCsll(v))}
          value={store.percRetenCsll}
          isPercentage
          clearOnDisable
          decimalPlaces={4}
          divClassName="pr-2"
        />
        <NumberInput
          label="% Retenção IRPJ"
          md={3}
          onChange={(v) => dispatch(setPercRetenIrpj(v))}
          value={store.percRetenIrpj}
          isPercentage
          clearOnDisable
          decimalPlaces={4}
          divClassName="pr-2"
        />
      </Row>
    </TabPane>
  );
};
