import React, { useState } from "react";
import { Col, ListGroup, Row } from "reactstrap";
import {
  CardTotaisItem,
  Divider,
  FixedField,
  ModalBase,
  Table,
  TableCheck,
} from "../../../../components";
import OrdemServicoService from "../../../../services/ordem_servico/OrdemServicoService";
import {
  formatNumber,
  formatValueFromAPI,
  formatarValor,
} from "../../../../coreUtils";
import { ProdServIcone } from "../../../../components/ProdServIcone";
import { CarrosselFotosOSDetalhes } from "./CarroselFotosOsDetalhes";

const headerRowClassName = "mb-1";

const DetalhesOSGrid = ({ itens, params, mecanica }) => {
  const columns = [
    {
      dataField: "tipo",
      text: "T",
      align: "left",
      colWidth: "30px",
      formatter: (c) => <ProdServIcone value={c} />,
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
      colWidth: "75px",
    },
    {
      dataField: "id_item",
      text: "#",
      align: "center",
      colWidth: "60px",
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      colWidth: params.utiliza_observ_item ? "200px" : "250px",
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
      colWidth: "30px",
    },
    {
      dataField: "vlr_unit",
      text: "V Unit.",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      colWidth: "60px",
    },
    {
      dataField: "observ",
      text: "Observação",
      align: "left",
      colWidth: "280px",
      hidden: !params.utiliza_observ_item,
    },
    {
      dataField: "tecnico",
      text: "Técnico",
      align: "left",
      colWidth: "100px",
      hidden: !params.identifica_tecnico,
    },
    {
      dataField: "data_item",
      text: "Data Trab.",
      align: "center",
      colWidth: "100px",
      hidden: mecanica,
    },
    {
      dataField: "horas_servico",
      text: "Horas Serv.",
      align: "center",
      colWidth: "100px",
      hidden: !params.identifica_tecnico,
    },
    {
      dataField: "garantia",
      text: "G",
      align: "center",
      formatter: (c) => {
        if (c) {
          return <TableCheck value={c} />;
        } else {
          return <></>;
        }
      },
      colWidth: "30px",
    },
    {
      dataField: "quantidade",
      text: "Qtde",
      align: "center",
      formatter: (c) => formatarValor(c, 2, 0),
      colWidth: "75px",
    },
    {
      dataField: "vlr_item",
      text: "Valor Item",
      align: "right",
      formatter: (c) => formatarValor(c, 4),
      colWidth: "75px",
    },
    {
      dataField: "perc_desc",
      text: "% Desc.",
      align: "center",
      formatter: (c) => formatarValor(c, 2),
      colWidth: "75px",
    },
    {
      dataField: "vlr_total",
      text: "Valor Total",
      align: "right",
      formatter: (c) => formatarValor(c, 2),
      colWidth: "75px",
    },
  ];

  return (
    <Table
      columns={columns}
      data={itens}
      paginated={false}
      showRegisterCount={false}
      growIntoPageSize
    />
  );
};

export const DetalhesOsAdiantamentosGrid = ({ adiantamentos }) => {
  const columns = [
    {
      dataField: "desc_forma_pag",
      text: "Forma de Pagamento",
      align: "left",
    },
    {
      dataField: "desc_cond_pag",
      text: "Condição de Pagamento",
      align: "left",
    },
    {
      dataField: "data_adiantamento",
      text: "Data",
      align: "center",
    },
    {
      dataField: "hora_adiantamento",
      text: "Hora",
      align: "center",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "recebido",
      text: "Recebido",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
  ];

  return (
    <Table
      columns={columns}
      data={adiantamentos ?? []}
      paginated={false}
      pageSize={6}
      growIntoPageSize
      keyField="id"
    />
  );
};

export const DetalheOSModal = ({ isOpen, toggle, idOS }) => {
  const [params, setParams] = useState({});
  const [dadosOs, setDadosOs] = useState({});
  const [totais, setTotais] = useState({});
  const [itens, setItens] = useState([]);
  const [fotos, setFotos] = useState([]);

  const mecanica = params.tipo_sist === "MECANICA";

  const limparDados = () => {
    setParams({});
    setDadosOs({});
    setTotais({});
    setItens([]);
    setFotos([]);
  };

  const carregarDados = async () => {
    const retParams = await OrdemServicoService.buscarParametros();
    if (retParams) {
      setParams(retParams);
    }

    const [ok, ret] = await OrdemServicoService.buscar(idOS);
    if (ok) {
      setDadosOs(ret.os);
      setTotais(ret.totais);
      setItens(ret.itens);

      const [okFotos, retFotos] = await OrdemServicoService.buscarFotos(idOS);
      if (okFotos) {
        setFotos(
          retFotos.map((e) => ({
            id: e.id,
            base64: e.base64_foto,
            observ: e.obs,
          }))
        );
      }
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      title="Detalhamento de Ordem de Serviço"
      number="0107_3"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
      footerActions
      autoFocus
    >
      <Row>
        <Col>
          <Row className={headerRowClassName}>
            <FixedField label="Nro. O.S." value={idOS} horizontal boldValue />
            {mecanica && (
              <FixedField label="Placa" value={dadosOs.placa} horizontal />
            )}
            <FixedField
              label="Cliente"
              md={5}
              value={formatValueFromAPI(
                dadosOs.nome_cliente,
                dadosOs.id_cliente
              )}
              horizontal
            />
            <FixedField
              label="Emissão"
              value={`${dadosOs.data_emissao} - ${dadosOs.hora_emissao}`}
              horizontal
            />
            {params.identifica_data_hora_agendamento && (
              <>
                <FixedField
                  label="Agendado Para"
                  value={
                    dadosOs.data_agendada
                      ? `${dadosOs.data_agendada}` +
                        (dadosOs.hora_agendada
                          ? ` - ${dadosOs.hora_agendada}`
                          : "")
                      : "Não Especificado"
                  }
                  horizontal
                />
              </>
            )}
            <FixedField
              label="Previsão de Entrega"
              value={
                dadosOs.data_pre
                  ? `${dadosOs.data_pre}` +
                    (dadosOs.hora_pre ? ` - ${dadosOs.hora_pre}` : "")
                  : "Não Especificado"
              }
              horizontal
            />
          </Row>
          <Row className={headerRowClassName}>
            <FixedField
              md={7}
              label="Endereço"
              value={`${dadosOs.endereco_cliente} - ${dadosOs.cidade_cliente}`}
              horizontal
            />
            <FixedField
              md={3}
              label="CPF/CNPJ"
              value={dadosOs.cpf_cnpj_cliente}
              horizontal
            />
          </Row>
          <Row className={headerRowClassName}>
            {params.identifica_contato_servico && (
              <FixedField
                md={3}
                label="Contato"
                value={dadosOs.contato_serv}
                horizontal
              />
            )}
            <FixedField
              md={4}
              label="Telefone(s)"
              value={[
                dadosOs.fone1_cliente,
                dadosOs.fone2_cliente,
                dadosOs.fone3_cliente,
              ]
                .filter((e) => !["", null, undefined].includes(e))
                .join(" - ")}
              horizontal
            />
            {params.identifica_tecnico_resp && (
              <FixedField
                md={4}
                label="Téc. Responsável"
                value={dadosOs.nome_tecnico}
                horizontal
              />
            )}
          </Row>
          {mecanica ? (
            <>
              <Row className={headerRowClassName}>
                <FixedField
                  label="Veículo"
                  value={dadosOs.veiculo}
                  horizontal
                />
                <FixedField
                  label="Modelo"
                  value={dadosOs.modelo_veic}
                  horizontal
                />
                <FixedField label="Cor" value={dadosOs.cor_veic} horizontal />
                <FixedField label="Ano" value={dadosOs.ano_veic} horizontal />
                <FixedField
                  label="Chassi"
                  value={dadosOs.chassi_veic}
                  horizontal
                />
                <FixedField
                  label="Combustível"
                  value={dadosOs.combustivel_veic}
                  horizontal
                />
                <FixedField label="KM" value={dadosOs.km} horizontal />
              </Row>
            </>
          ) : (
            <></>
          )}
          {params.identifica_equipamento && (
            <Row className={headerRowClassName}>
              <FixedField
                md={4}
                label="Equipamento"
                value={dadosOs.nome_equipamento}
                horizontal
              />
              <FixedField
                md={3}
                label="Marca"
                value={dadosOs.marca_equipamento}
                horizontal
              />
              <FixedField
                md={3}
                label="Capacidade"
                value={dadosOs.capacidade_equipamento}
                horizontal
              />
            </Row>
          )}
        </Col>
        {fotos.length > 0 && (
          <Col md="auto">
            <CarrosselFotosOSDetalhes fotos={fotos} />
          </Col>
        )}
      </Row>
      <Row>
        <FixedField
          md={6}
          label="Problema"
          value={dadosOs.defeito}
          horizontal
        />
      </Row>
      <Row>
        <FixedField label="Observação" value={dadosOs.observ} horizontal />
      </Row>
      {dadosOs.status === "F" && (
        <Row>
          <FixedField
            label="Observação de Fechamento"
            value={dadosOs.observ_fec}
            horizontal
          />
        </Row>
      )}

      <Row className="my-3">
        <ListGroup style={{ width: "100%" }} horizontal>
          <CardTotaisItem label="Produtos" value={totais?.total_produtos} />
          <CardTotaisItem label="Servicos" value={totais?.total_servicos} />
          <CardTotaisItem
            label="Terceirização"
            value={totais?.total_terceirizacao}
          />
          {params.identifica_tecnico ? (
            <CardTotaisItem
              label="Horas"
              value={totais?.total_horas}
              format={false}
            />
          ) : (
            <></>
          )}
          <CardTotaisItem label="Desconto" value={totais?.total_desconto} />
          <CardTotaisItem label="Geral" value={totais?.total_geral} />
        </ListGroup>
      </Row>
      <DetalhesOSGrid itens={itens} params={params} mecanica={mecanica} />
      {(dadosOs?.adiantamentos ?? []).length > 0 && (
        <>
          <Divider>Adiantamentos</Divider>
          <DetalhesOsAdiantamentosGrid
            adiantamentos={dadosOs?.adiantamentos ?? []}
          />
        </>
      )}
    </ModalBase>
  );
};
