import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AsyncComboBox,
  FormCheckbox,
  IntegerFormInput,
  RadioGroup,
  TextInput,
} from "../../../../components";
import { Row, Col } from "reactstrap";
import {
  setIdentRepresentante,
  setInfoDadosAdic1,
  setInfoDadosAdic2,
  setInfoDadosAdic3,
  setInfoDadosAdic4,
  setInfoDadosAdic5,
  setCopiaEmailEmitente,
  setEmailCopiaEmitente,
  setFocarDescricaoProd,
  setPermAltUnidMed,
  setCaminSalvarXml,
  setDefineDataHoraSaida,
  setVisualizarDanfeDepEmi,
  setImprCodProd,
  setIncProdSuframa,
  setIdTipoDocPadrao,
  setIdentCodigoDestinatarioNfe,
  setIdentificaCor,
  setEmiteNfeCreditoDiferimento,
  setIdProdutoNfeCreditoDiferimento,
  setIdClienteNfeCreditoDiferimento,
  setIdTipoDocNfeCreditoDiferimento,
  setSugerirAutoQtdeVolumes,
} from "../store/nf_eletronica_slice";
import { RadioItem } from "../../../../components/RadioGroup";

const labelStyle = { marginTop: "auto", lineHeight: 3.5 };

export const PersonNfEletronica = () => {
  const store = useSelector((state) => state.nf_eletronica);
  const dispatch = useDispatch();

  const idTipoDocNfeCreditoDiferimentoRef = useRef();
  const idProdutoNfeCreditoDiferimentoRef = useRef();
  const idClienteNfeCreditoDiferimentoRef = useRef();

  const onChangeCopiaEmailEmitente = () => {
    if (!store.copia_email_emitente) {
      dispatch(setEmailCopiaEmitente(""));
    }
  };
  useEffect(onChangeCopiaEmailEmitente, [store.copia_email_emitente]);

  const onChangeEmiteNfeCreditoDiferimento = () => {
    if (!store.emite_nfe_credito_diferimento) {
      dispatch(setIdTipoDocNfeCreditoDiferimento(null));
      if (idTipoDocNfeCreditoDiferimentoRef.current) {
        idTipoDocNfeCreditoDiferimentoRef.current.clearValue();
      }

      dispatch(setIdProdutoNfeCreditoDiferimento(null));
      if (idProdutoNfeCreditoDiferimentoRef.current) {
        idProdutoNfeCreditoDiferimentoRef.current.clearValue();
      }

      dispatch(setIdClienteNfeCreditoDiferimento(null));
      if (idClienteNfeCreditoDiferimentoRef.current) {
        idClienteNfeCreditoDiferimentoRef.current.clearValue();
      }
    }
  };
  useEffect(onChangeEmiteNfeCreditoDiferimento, [
    store.emite_nfe_credito_diferimento,
  ]);

  return (
    <>
      <Row>
        <AsyncComboBox
          md={6}
          label="Tipo de Documento Padrão"
          concatModelName="tipo_documento"
          defaultValue={store.id_tipo_doc_padrao}
          onChange={(s) => dispatch(setIdTipoDocPadrao(s?.value ?? null))}
        />
      </Row>
      <Row>
        <TextInput
          md={7}
          label="Salvar XML da NFe em"
          value={store.camin_salvar_xml}
          onChange={(e, v) => dispatch(setCaminSalvarXml(v))}
          maxLength={255}
          forceUppercase={false}
        />
        <span style={labelStyle}>Utilizado Somente no Sistema Antigo</span>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Definir Data/Hora de Saída"
          checked={store.define_data_hora_saida}
          onChange={() =>
            dispatch(setDefineDataHoraSaida(!store.define_data_hora_saida))
          }
        />
        <span style={{ lineHeight: 1.7 }}>
          Se marcado e a data e hora não for informada, será impressa a data e
          hora do momento da emissão
        </span>
      </Row>
      <Row>
        <RadioGroup
          label="Visualizar DANFE Após Emissão"
          value={store.visualizar_danfe_dep_emi}
          onChange={(v) => dispatch(setVisualizarDanfeDepEmi(v))}
        >
          <RadioItem label="Sim" value="S" />
          <RadioItem label="Não" value="N" />
          <RadioItem label="Perguntar" value="P" />
        </RadioGroup>
        <span style={{ ...labelStyle, lineHeight: 2.6 }}>
          Utilizado Somente no Sistema Antigo
        </span>
      </Row>
      <Row>
        <RadioGroup
          label="Imprimir no Campo Código da DANFE"
          value={store.impr_cod_prod}
          onChange={(v) => dispatch(setImprCodProd(v))}
        >
          <RadioItem label="Código" value="COD" />
          <RadioItem label="Referência" value="REF" />
        </RadioGroup>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Identificar Representante na NFe"
          checked={store.ident_representante}
          onChange={() =>
            dispatch(setIdentRepresentante(!store.ident_representante))
          }
        />
      </Row>
      <Row>
        <Col className="no-gutters">
          <TextInput
            md={10}
            label="Mensagem Padrão nos Dados Adicionais"
            value={store.info_dados_adic_1}
            onChange={(e, v) => dispatch(setInfoDadosAdic1(v))}
            maxLength={120}
          />
          <TextInput
            md={10}
            value={store.info_dados_adic_2}
            onChange={(e, v) => dispatch(setInfoDadosAdic2(v))}
            maxLength={120}
          />
          <TextInput
            md={10}
            value={store.info_dados_adic_3}
            onChange={(e, v) => dispatch(setInfoDadosAdic3(v))}
            maxLength={120}
          />
          <TextInput
            md={10}
            value={store.info_dados_adic_4}
            onChange={(e, v) => dispatch(setInfoDadosAdic4(v))}
            maxLength={120}
          />
          <TextInput
            md={10}
            value={store.info_dados_adic_5}
            onChange={(e, v) => dispatch(setInfoDadosAdic5(v))}
            maxLength={120}
          />
        </Col>
      </Row>
      <Row>
        <FormCheckbox
          label="Enviar Cópia de E-mail para Emitente"
          checked={store.copia_email_emitente}
          onChange={() =>
            dispatch(setCopiaEmailEmitente(!store.copia_email_emitente))
          }
        />
        <TextInput
          md={6}
          label="E-Mail de Cópia"
          value={store.email_copia_emitente}
          onChange={(e, v) => dispatch(setEmailCopiaEmitente(v))}
          maxLength={255}
          forceUppercase={false}
          disabled={!store.copia_email_emitente}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Focar na Descrição na Inclusão de Produtos"
          checked={store.focar_descricao_prod}
          onChange={() =>
            dispatch(setFocarDescricaoProd(!store.focar_descricao_prod))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permitir Alterar UN na Emissão da NFe Avulsa"
          checked={store.perm_alt_unid_med}
          onChange={() => dispatch(setPermAltUnidMed(!store.perm_alt_unid_med))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Informar Cor do Produto na NFe"
          checked={store.identifica_cor}
          onChange={() => dispatch(setIdentificaCor(!store.identifica_cor))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Habilitar emissão de NFe com SUFRAMA"
          checked={store.inc_prod_suframa}
          onChange={() => dispatch(setIncProdSuframa(!store.inc_prod_suframa))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Identificar código do destinatário (Cliente) nas informações da NFe"
          checked={store.ident_codigo_destinatario_nfe}
          onChange={() =>
            dispatch(
              setIdentCodigoDestinatarioNfe(
                !store.ident_codigo_destinatario_nfe
              )
            )
          }
        />
      </Row>
      <Row>
        <IntegerFormInput
          md={2}
          label="Último Nº de Lote"
          defaultValue={store.ult_nro_lote}
          disabled
        />
        <span style={labelStyle}>Utilizado Somente no Sistema Antigo</span>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Emitir NFe de Crédito de Diferimento"
          checked={store.emite_nfe_credito_diferimento}
          onChange={() =>
            dispatch(
              setEmiteNfeCreditoDiferimento(
                !store.emite_nfe_credito_diferimento
              )
            )
          }
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Tipo de Documento da NFe de Crédito de Diferimento"
          concatModelName="tipo_documento"
          defaultValue={store.id_tipo_doc_nfe_credito_diferimento}
          onChange={(s) =>
            dispatch(setIdTipoDocNfeCreditoDiferimento(s?.value ?? null))
          }
          disabled={!store.emite_nfe_credito_diferimento}
          ref={idTipoDocNfeCreditoDiferimentoRef}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Produto da NFe de Crédito de Diferimento"
          concatModelName="produto"
          defaultValue={store.id_produto_nfe_credito_diferimento}
          onChange={(s) =>
            dispatch(setIdProdutoNfeCreditoDiferimento(s?.value ?? null))
          }
          disabled={!store.emite_nfe_credito_diferimento}
          ref={idProdutoNfeCreditoDiferimentoRef}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={6}
          label="Cliente da NFe de Crédito de Diferimento"
          concatModelName="cliente"
          defaultValue={store.id_cliente_nfe_credito_diferimento}
          onChange={(s) =>
            dispatch(setIdClienteNfeCreditoDiferimento(s?.value ?? null))
          }
          disabled={!store.emite_nfe_credito_diferimento}
          ref={idClienteNfeCreditoDiferimentoRef}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Sugerir a Quantidade de Volumes Automaticamente na Inclusão Manual de NFe"
          checked={store.sugerir_auto_qtde_volumes}
          onChange={() =>
            dispatch(
              setSugerirAutoQtdeVolumes(!store.sugerir_auto_qtde_volumes)
            )
          }
        />
      </Row>
    </>
  );
};
