import React from "react";
import { TableOcorrencias } from "./TableOcorrencias";
import { Table } from "../../../../../components";

export const TableTipos = ({ data }) => {
  const columns = [
    {
      dataField: "tipo",
      text: "Tipo",
      align: "left",
      headerAlign: "left",
      sort: false,
    },
    {
      dataField: "funcionalidade",
      text: "Funcionalidade",
      align: "left",
      headerAlign: "left",
      sort: false,
    },
    {
      dataField: "num_ocorrencias",
      text: "Nº de Ocorrências",
      align: "center",
      headerAlign: "center",
      sort: false,
    },
    {
      dataField: "valor_ocorrencias",
      text: "Valor das Ocorrências",
      align: "right",
      headerAlign: "right",
      sort: false,
    },
  ];

  return (
    <Table
      keyField="tipo"
      data={data}
      columns={columns}
      canExpand
      expandedAreaBuilder={(row) => <TableOcorrencias data={row.ocorrencias} />}
      rowStyle={{ cursor: "pointer" }}
      growIntoPageSize
      showRegisterCount={false}
    />
  );
};
