import React from "react";
import { Table } from "../../../../../components";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
} from "../../../../../coreUtils";

const columns = [
  {
    dataField: "nome_produto",
    text: "Produto",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_produto),
    sortable: true,
  },
  {
    dataField: "referencia",
    text: "Referência",
    align: "center",
    headerAlign: "center",
    sortable: true,
  },
  {
    dataField: "qtd_total",
    text: "Quantidade",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_custo_total",
    text: "Custo",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_venda_total",
    text: "Vlr. Venda",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_lucro_total",
    text: "Vlr. Lucro",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
];

export const ProdutosCustoVsPrecoVendaGrid = ({ dados }) => {
  return <Table data={dados} columns={columns} />;
};
