import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroRamoAtividadeModal } from "./components/CadastroRamoAtividadeModal";
import { RamoAtividadeGrid } from "./components/RamoAtividadeGrid";
import {
  BotaoPesquisar,
  FormCheckbox,
  PageContainer,
  SearchInput,
} from "../../../../components";
import {
  MODAL_ACTIONS,
  defaultDebounceTime,
  useDebounce,
} from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";

export const modalTitleRamoAtividade = "Ramo de Atividade";
export const routesBaseRamoAtividade = "/cadastro/ramo_atividade";

export const RamoAtividade = () => {
  const [pista, setPista] = useState("");
  const [somenteAtivos, setSomenteAtivos] = useState(true);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async (p) => {
    const [ok, ret] = await apiGetV2(`${routesBaseRamoAtividade}/listar/`, {
      pista: p,
      somente_ativos: somenteAtivos,
    });

    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = useDebounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer
      title="Cadastro de Ramos de Atividade"
      number="0080"
      canGoBack
    >
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <FormCheckbox
            label="Somente Ativos"
            checked={somenteAtivos}
            onChange={() => setSomenteAtivos(!somenteAtivos)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroRamoAtividadeModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleRamoAtividade}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0080_2"
            selected={selected}
            routeBase={routesBaseRamoAtividade}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <RamoAtividadeGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
