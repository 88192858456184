import moment from "moment";
import { apiGetV2, apiPutV2 } from "../../apiV2";
import { viewDownloadPDF } from "../../coreUtils";
import { docPost } from "../../pdf";

const URL_BASE = "/venda";
const URL_BASE_GERENCIAMENTO = `${URL_BASE}/gerenciamento`;

const GerenciamentoVendasService = {
  gerenciamento: {
    listar: async (params) =>
      await apiGetV2(`${URL_BASE_GERENCIAMENTO}/listar/`, params),
  },
  buscar: async (id) => await apiGetV2(`${URL_BASE}/buscar/${id}/`),
  buscarLogs: async (id) => await apiGetV2(`${URL_BASE}/buscar_logs/${id}/`),
  estornarRecebimento: async (payload) =>
    await apiPutV2(`${URL_BASE}/estornar_recebimento/`, payload),
  excluir: (payload) => apiPutV2(`${URL_BASE_GERENCIAMENTO}/excluir/`, payload),
  imprimir: async (id, tipoValor) => {
    const [ok, ret] = await apiGetV2(`${URL_BASE}/impressao/buscar/${id}/`);
    if (ok) {
      let rota = ''
      if (tipoValor){
        rota = `/venda/layouts/${tipoValor}`
      }else{
        rota = `/venda/layouts/${ret.layout}/`
      }
      const arq = await docPost(
        rota,
        ret,
        {
          errorMessage: "Erro ao Imprimir a Venda",
        },
        true
      );

      if (arq) {
        viewDownloadPDF(
          arq,
          `5019_${moment(new Date()).format("DDMMYYYY_hhmmss")}`
        );
        return true;
      } else {
        return false;
      }
    }
  },
  verificaPodeExcluirVenda: (idVenda) =>
    apiGetV2(`${URL_BASE_GERENCIAMENTO}/verif_pode_excluir_venda/${idVenda}/`),
};

export default GerenciamentoVendasService;
