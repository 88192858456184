import React, { useCallback, useState } from "react";
import SolicitacaoRecargaService from "../../../../../services/pronta_entrega/SolicitacaoRecargaService";
import {
  FixedField,
  LabelButton,
  ModalBase,
  SearchInput,
  Table,
  TableDelete,
} from "../../../../../components";
import { formatNumber, useDebounce } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { showWarning } from "../../../../../components/AlertaModal";
import { apiPutV2 } from "../../../../../apiV2";
import { showConfirmation } from "../../../../../components/ConfirmationModal";

export const ConferirRecargaModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [nomeRotaMob, setNomeRotaMob] = useState(null);
  const [dataHoraSolicitacao, setDataHoraSolicitacao] = useState(null);
  const [itens, setItens] = useState([]);
  const [itensFiltrados, setItensFiltrados] = useState([]);

  const [loadingConferir, setLoadingConferir] = useState(false);

  const limparDados = () => {
    setNomeRotaMob(null);
    setDataHoraSolicitacao(null);
    setItens([]);
    setItensFiltrados([]);
  };

  const carregarDados = async () => {
    const [ok, ret] = await SolicitacaoRecargaService.buscar(selected);
    if (ok) {
      setDataHoraSolicitacao(ret.data_hora_solicitacao);
      setNomeRotaMob(ret.nome_rota_mob);
      setItens(
        ret.itens.map((e) => ({
          ...e,
          qtd_solic: parseFloat(e.qtd_solic),
          qtd_confer: parseFloat(e.qtd_solic),
          conferido: false,
        }))
      );
      setItensFiltrados(
        ret.itens.map((e) => ({
          ...e,
          qtd_solic: parseFloat(e.qtd_solic),
          qtd_confer: parseFloat(e.qtd_solic),
          conferido: false,
        }))
      );
    } else {
      toggle();
    }
  };

  const handleSelect = (idRow, isSelected) => {
    const idxItemAlterado = itens.findIndex((e) => e.id === idRow);
    const idxFiltItemAlterado = itensFiltrados.findIndex((e) => e.id === idRow);

    const itensNovos = [...itens];
    const itensFiltradosNovos = [...itensFiltrados];

    itensNovos[idxItemAlterado].conferido = isSelected;
    itensFiltradosNovos[idxFiltItemAlterado].conferido = isSelected;

    setItens(itensNovos);
    setItensFiltrados(itensFiltradosNovos);
  };

  const verifExcluirItem = (idItem) => {
    showConfirmation(
      "Você tem certeza que deseja excluir esse item?",
      () => excluirItem(idItem),
      () => {}
    );
  };

  const excluirItem = async (idItem) => {
    const [ok] = await SolicitacaoRecargaService.excluirItem(idItem);
    if (ok) {
      setItens([...itens.filter((e) => e.id !== idItem)]);
      setItensFiltrados([...itensFiltrados.filter((e) => e.id !== idItem)]);
    }
  };

  const conferirRecarga = async () => {
    setLoadingConferir(true);
    try {
      if (itens.some((e) => !e.conferido)) {
        showWarning(
          "Todos os itens devem ser conferidos para liberar a recarga para carregamento!"
        );
        return;
      }

      const [ok] = await apiPutV2("/jornada/carga_prod/conferir/", {
        id_solicitacao: selected,
        itens: itens.map((e) => ({
          id_item: e.id,
          qtd_solic_confer: e.qtd_confer,
        })),
      });
      if (ok) {
        notifyEvent();
        toggle();
      }
    } finally {
      setLoadingConferir(false);
    }
  };

  const atualizarQtdConferidaRecarga = (row, qtdNova) => {
    setItens([
      ...itens.map((e) => {
        if (e.id !== row.id) {
          return e;
        }

        return { ...e, qtd_confer: qtdNova };
      }),
    ]);
    setItensFiltrados([
      ...itensFiltrados.map((e) => {
        if (e.id !== row.id) {
          return e;
        }

        return { ...e, qtd_confer: qtdNova };
      }),
    ]);
  };

  const handlePista = useDebounce((pista) => {
    if (pista) {
      setItensFiltrados([
        ...itens.filter((e) =>
          e.nome_produto.toUpperCase().includes(pista.toUpperCase())
        ),
      ]);
    } else {
      setItensFiltrados([...itens]);
    }
  }, 350);

  const columns = [
    {
      dataField: "id_produto",
      text: "Produto",
      align: "center",
    },
    {
      dataField: "nome_produto",
      text: "nome",
      align: "left",
    },
    {
      dataField: "unidade_produto",
      text: "Unidade",
      align: "Center",
    },
    {
      dataField: "qtd_solic",
      text: "Quantidade",
      align: "center",
      formatter: (c, r) =>
        formatNumber(c, false, r.qtd_casas_decimais_qtd ?? 2, true),
    },
    {
      dataField: "qtd_confer",
      text: "Quantidade Conferida",
      align: "center",
      formatter: (c, r) =>
        formatNumber(c, true, r.qtd_casas_decimais_qtd ?? 2, true),
      editable: true,
      onBlur: atualizarQtdConferidaRecarga,
      editorType: "number",
      decimalPlaces: (r) => r.qtd_casas_decimais_qtd ?? 2,
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dataField: "act",
      dummyField: true,
      text: "",
      align: "center",
      formatter: (_, row) => (
        <TableDelete onClick={() => verifExcluirItem(row.id)} />
      ),
    },
    {
      dataField: "act2",
      dummyField: true,
      text: "",
      align: "center",
      formatter: (_, row) => (
        <input
          type={"checkbox"}
          name={`table-sel-inpt-${row.id}`}
          id={`table-sel-inpt-${row.id}`}
          checked={row.conferido}
          onChange={({ target: { checked } }) => handleSelect(row.id, checked)}
          htmlFor={`table-sel-inpt-${row.id}`}
          style={{
            display: "flex",
            margin: "auto",
            width: "16px",
            height: "16px",
          }}
        />
      ),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title={`Conferir Solicitação de Recarga Nº ${selected}`}
      number="0104_2"
      onClosed={limparDados}
      onBeforeOpen={carregarDados}
      onConfirm={conferirRecarga}
      loadingConfirm={loadingConferir}
      numberStyle={{ marginRight: "10px" }}
      footerActions={
        <div className="mr-auto">
          <LabelButton
            disabled={itens.every((e) => e.conferido)}
            onClick={() => {
              setItens(itens.map((e) => ({ ...e, conferido: true })));
              setItensFiltrados(
                itensFiltrados.map((e) => ({ ...e, conferido: true }))
              );
            }}
            disabledHint="Todos os itens já estão marcados"
          >
            Marcar Todos
          </LabelButton>
          <LabelButton
            disabled={itens.every((e) => !e.conferido)}
            onClick={() => {
              setItens(itens.map((e) => ({ ...e, conferido: false })));
              setItensFiltrados(
                itensFiltrados.map((e) => ({ ...e, conferido: false }))
              );
            }}
            disabledHint="Nenhum item está marcado"
          >
            Desmarcar Todos
          </LabelButton>
        </div>
      }
    >
      <Row className="mb-2" style={{ justifyContent: "space-between" }}>
        <FixedField label="Rota" value={nomeRotaMob} horizontal />
        <FixedField label="Solicitado" value={dataHoraSolicitacao} horizontal />
      </Row>
      <Row className="mb-2">
        <SearchInput onChange={handlePista} />
      </Row>
      <Table
        data={itensFiltrados}
        columns={columns}
        paginated={false}
        pageSize={10}
        multiselect
        hideSelectAll
      />
    </ModalBase>
  );
};
