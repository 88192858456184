import React from "react";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";
import { DropdownItem } from "reactstrap";

export const NaturezaOperNfseGrid = ({
  data,
  setSelected,
  alterar,
  excluir,
  mostrarLogs,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
    },
    {
      dataField: "sit_trib_provedor",
      text: "CST no Provedor",
      align: "center",
    },
    {
      dataField: "aliq_iss",
      text: "Alíq. ISS",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true) + "%",
    },
    {
      dataField: "aliq_inss",
      text: "Alíq. INSS",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true) + "%",
    },
    {
      dataField: "aliq_reten_irpj",
      text: "Alíq. Retenção IRPJ",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true) + "%",
    },
    iconeAlterar(alterar),
    dropdownAcoes({
      excluir: excluir,
      outrasAcoes: (id) => (
        <>
          <DropdownItem onClick={() => mostrarLogs(id)}>Logs</DropdownItem>
        </>
      ),
    }),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
