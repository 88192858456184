import React, { useState } from "react";
import { FormButton, ModalBase, Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";
import { Col, Row } from "reactstrap";
import CaixaLojaService from "../../../../../services/financeiro/CaixaLojaService";
import { SenhaModal } from "../../../../../components/SenhaModal";

const columns = [
  {
    dataField: "desc_moeda",
    text: "Moeda",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "desc_tipo",
    text: "Tipo",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "qtd_parcelas",
    text: "Parcelas",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "valor",
    text: "Valor",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const VerifProcPagTefNovamenteModal = ({
  isOpen,
  toggle,
  idMovCaixa,
  notifyEvent,
}) => {
  const [dados, setDados] = useState([]);
  const [senhaEstornarOpen, setSenhaEstornarOpen] = useState(false);

  const toggleSenhaEstornar = () => setSenhaEstornarOpen(!senhaEstornarOpen);

  const carregarDados = async () => {
    const [ok, ret] = await CaixaLojaService.verifTemRecTefPendente(idMovCaixa);
    if (ok) {
      setDados(ret.recs_pendentes);
    } else {
      toggle();
    }
  };

  const refazerRecebimento = async (senha) => {
    const payload = { id_mov_cxa: idMovCaixa, senha_estorno: senha };
    const [ok] = await CaixaLojaService.refazerRecebimento(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    return ok;
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      number="0063_19"
      title="Pagamentos TEF Pendentes"
      onBeforeOpen={carregarDados}
      onClosed={() => setDados([])}
      onConfirm={() => toggle(true)}
      footerActions={
        <>
          <FormButton
            padded={false}
            color="info"
            onClick={toggleSenhaEstornar}
            divClassName="mr-auto"
          >
            Refazer Recebimento
          </FormButton>
        </>
      }
      numberStyle={{ marginRight: "3px" }}
      autoFocus
    >
      <Row className="mb-2">
        <Col>
          Existem pagamentos TEF pendentes. Você deseja tentar processá-los
          novamente?
        </Col>
      </Row>
      <Table
        data={dados ?? []}
        columns={columns}
        paginated={false}
        showRegisterCount={false}
        pageSize={5}
        growIntoPageSize
      />
      <Row className="mt-2">
        <Col>
          <p style={{ marginBottom: "0px" }}>
            Em caso de múltiplos recebimentos em TEF, sendo que um dos
            recebimentos foi autorizado, é necessário cancelar TODAS as
            operações via Checkout Payer e receber a venda novamente de forma
            integral no sistema Skillsoft.
          </p>
        </Col>
      </Row>
      <SenhaModal
        isOpen={senhaEstornarOpen}
        toggle={toggleSenhaEstornar}
        title="Estorno de Venda"
        onConfirm={refazerRecebimento}
      />
    </ModalBase>
  );
};
