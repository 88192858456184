import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { BsPerson } from "react-icons/bs";
import { AiOutlineFileText } from "react-icons/ai";
import {
  relCadCfopRoute,
  relCadClienteRoute,
  relCadClientesPorVendedorRoute,
  relCadProdutosBalancaRoute,
  relProdSemMovRoute,
} from "../../../routes/modules/relatorios";
import { RiScales2Line } from "react-icons/ri";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";

export const Cadastrais = () => {
  return (
    <PageContainer title="Relatórios Cadastrais">
      <MenuGroup>
        <MenuGroupButton
          label="Clientes"
          icon={BsPerson}
          pathname={relCadClienteRoute.path}
          nroTela={relCadClienteRoute.nroTela}
        />
        <MenuGroupButton
          label="Clientes por Vendedor"
          icon={BsPerson}
          pathname={relCadClientesPorVendedorRoute.path}
          nroTela={relCadClientesPorVendedorRoute.nroTela}
        />
        <MenuGroupButton
          label="CFOP"
          icon={AiOutlineFileText}
          pathname={relCadCfopRoute.path}
          nroTela={relCadCfopRoute.nroTela}
        />
        <MenuGroupButton
          label="Produtos de Balança"
          icon={RiScales2Line}
          pathname={relCadProdutosBalancaRoute.path}
          nroTela={relCadProdutosBalancaRoute.nroTela}
        />
        <MenuGroupButton
          label="Produtos Sem Mov."
          icon={MdOutlineRemoveShoppingCart}
          pathname={relProdSemMovRoute.path}
          nroTela={relProdSemMovRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
