import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toastr } from "react-redux-toastr";
import Row from "reactstrap/lib/Row";
import {
  FormButton,
  IntegerFormInput,
  NumberInput,
  TextInput,
} from "../../../../../components";
import { RadioGroup, RadioItem } from "../../../../../components/RadioGroup";
import {
  MODAL_ACTIONS,
  roundFloat,
  sumDataField,
} from "../../../../../coreUtils";
import { ParcelasCondPagGrid } from "./ParcelasCondPagGrid";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { modalTitleCondPag, routesBaseCondPag } from "../CondPag";
import { Col } from "reactstrap";

export const CadastroCondPagModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [descricao, setDescricao] = useState("");
  const [tipo, setTipo] = useState("DD");
  const [multiplicador, setMultiplicador] = useState(0);
  const [parcelas, setParcelas] = useState(0);
  const [entrada, setEntrada] = useState(0);
  const [percTaxaAcreFinanc, setPercTaxaAcreFinanc] = useState(0);
  const [percDescMaxSobreTotal, setPercDescMaxSobreTotal] = useState(0);
  const [listaParc, setListaParc] = useState([]);
  const [percTotal, setPercTotal] = useState(0);

  const limparDados = () => {
    setDescricao("");
    setTipo("DD");
    setMultiplicador(0);
    setParcelas(0);
    setEntrada(0);
    setPercTaxaAcreFinanc(0);
    setPercDescMaxSobreTotal(0);
    setListaParc([]);
    setPercTotal(0);
  };

  const handleSetTipo = (v) => {
    setTipo(v);
    if (v === "DF") {
      setParcelas(1);
      setEntrada(0);
    }
  };

  const gerarParcelas = () => {
    let res = [];
    const perc = roundFloat((100 - entrada) / parcelas, 2);
    const diff = roundFloat(100 - entrada - perc * parcelas, 2);

    for (let i = 1; i <= parcelas; i++) {
      res.push({
        id: i,
        prazo: tipo === "DD" ? multiplicador * i : multiplicador,
        perc: perc,
      });
    }

    res[0]["perc"] = roundFloat(perc + diff, 2);

    setListaParc(res);
  };

  const calcularPercTotal = (parcelas, entrada) =>
    roundFloat(sumDataField(parcelas, "perc") + entrada, 2);

  const atualizarPercTotal = () => {
    setPercTotal(calcularPercTotal(listaParc, entrada));
  };

  const alterarParcela = (coluna, novoValor, row) => {
    if (coluna === "prazo") {
      row.prazo = parseInt(novoValor);
    } else if (coluna === "perc") {
      row.perc = parseFloat(novoValor);
    }

    let parcs = listaParc.map((e) => (e.id === row.id ? row : e));

    setListaParc(parcs);
  };

  const fetchData = (data) => {
    setDescricao(data.descricao);
    setTipo(data.tipo);
    setMultiplicador(data.multiplicador);
    setParcelas(data.parcelas);
    setEntrada(parseFloat(data.entrada));
    setPercTaxaAcreFinanc(parseFloat(data.perc_taxa_acre_financ ?? 0));
    setPercDescMaxSobreTotal(parseFloat(data.perc_desc_max_sobre_total ?? 0));

    let parcLista = [];

    for (let i = 1; i <= 15; i++) {
      const prazoData = data[`prazo${i}`];
      const percData = parseFloat(data[`perc${i}`]);

      if (prazoData > 0 || percData > 0) {
        parcLista.push({
          id: i,
          prazo: prazoData,
          perc: percData,
        });
      }
    }

    setListaParc(parcLista);
  };

  const submitPayload = (action) => {
    if (listaParc.length === 0) {
      toastr.warning("Atenção", "Por favor, calcule as parcelas.");
      return false;
    }

    if (calcularPercTotal(listaParc, entrada) !== 100) {
      toastr.warning(
        "Atenção",
        "O somatório dos percentuais " +
          "das parcelas + entrada deve ser igual à 100 (cem)."
      );
      return false;
    }

    const payload = {
      descricao: descricao,
      tipo: tipo,
      multiplicador: multiplicador,
      parcelas: parcelas,
      entrada: entrada,
      perc_taxa_acre_financ: percTaxaAcreFinanc,
      perc_desc_max_sobre_total: percDescMaxSobreTotal,
    };

    for (let i = 1; i <= 15; i++) {
      const itemLista = listaParc[i - 1];
      payload[`prazo${i}`] = itemLista ? itemLista["prazo"] : 0;
      payload[`perc${i}`] = itemLista ? itemLista["perc"] : 0;
    }

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  const validarGeracao = () => {
    if (multiplicador === 0) {
      if (tipo === "DD") {
        return [false, "Por favor, informe o intervalo."];
      } else {
        return [false, "Por favor, informe o dia fixo."];
      }
    }
    if (parcelas <= 0 || parcelas > 15) {
      return [false, "O número de parcelas deve maior que 0 e inferior a 15."];
    }

    if (entrada > 100) {
      return [false, "O percentual de entrada não deve ser superior a 100."];
    }

    return [true, ""];
  };

  const [gerarEnabled, gerarHint] = validarGeracao();

  useEffect(() => {
    setListaParc([]);
  }, [tipo, multiplicador, parcelas]);

  useEffect(() => {
    atualizarPercTotal();
  }, [listaParc, entrada]);

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleCondPag}
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseCondPag}
      number="0051_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row className="mb-3">
        <TextInput
          label="Descrição"
          value={descricao}
          onChange={(e, v) => setDescricao(v)}
          maxLength={40}
        />
      </Row>
      <Row className="mb-3">
        <RadioGroup
          divClassName="pr-0"
          label="Tipo"
          value={tipo}
          onChange={handleSetTipo}
        >
          <RadioItem label="Dias da Data" value="DD" />
          <RadioItem label="Dias Fixos" value="DF" />
        </RadioGroup>
        <IntegerFormInput
          md={2}
          label={tipo === "DD" ? "Intervalo (dias)" : "Dia Fixo"}
          defaultValue={multiplicador}
          onChange={setMultiplicador}
        />
        <IntegerFormInput
          md={2}
          label="Parcelas"
          defaultValue={parcelas}
          onChange={setParcelas}
          disabled={tipo === "DF"}
        />
        <NumberInput
          md={2}
          label="Entrada"
          value={entrada}
          onChange={setEntrada}
          isPercentage
          disabled={tipo === "DF"}
        />
      </Row>
      <Row className="mb-3">
        <NumberInput
          md={2}
          label="Perc. Acrés. Financ."
          value={percTaxaAcreFinanc}
          onChange={setPercTaxaAcreFinanc}
          isPercentage
        />
        <NumberInput
          md={3}
          label="Perc. Desc. Max. sobre Total"
          value={percDescMaxSobreTotal}
          onChange={setPercDescMaxSobreTotal}
          isPercentage
        />
      </Row>
      <div style={{ display: "flex" }}>
        <Col md={9} className="pl-0">
          <ParcelasCondPagGrid
            data={listaParc}
            alterarParcela={alterarParcela}
          />
        </Col>
        <div style={{ flexGrow: 1 }}>
          <FormButton
            md="auto"
            color="info"
            padded={false}
            onClick={gerarParcelas}
            disabled={!gerarEnabled}
            disabledHint={gerarHint}
          >
            Gerar Parcelas
          </FormButton>
          <NumberInput
            md={8}
            label="Percentual Total"
            value={percTotal}
            disabled
          />
        </div>
      </div>
    </ModalCadastroV2>
  );
};
