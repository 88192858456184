import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { relIndustriaProducaoPeriodoRoute } from "../../../routes/modules/relatorios";
import { TbReportAnalytics } from "react-icons/tb";

export const Industria = () => {
  return (
    <PageContainer title="Relatórios - Indústria">
      <MenuGroup>
        <MenuGroupButton
          label="Produção por Período"
          icon={TbReportAnalytics}
          pathname={relIndustriaProducaoPeriodoRoute.path}
          nroTela={relIndustriaProducaoPeriodoRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
