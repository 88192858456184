import React, { useState } from "react";
import {
  BotaoPesquisar,
  FormCheckbox,
  PageContainer,
  SearchInput,
} from "../../../../components";
import {
  defaultDebounceTime,
  MODAL_ACTIONS,
  useDebounce,
} from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";
import { Card, Row } from "reactstrap";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { CadastroTransportadoraModal } from "./components/CadastroTransportadoraModal";
import { TransportadoraGrid } from "./components/TransportadoraGrid";

export const routesBaseTransportadora = "/cadastro/transportadora";
export const modalTitleTransportadora = "Transportadora";

export const Transportadora = () => {
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const [action, setAction] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);
  const [somenteAtivos, setSomenteAtivos] = useState(false);

  const buscarDados = async (p) => {
    const [ok, ret] = await apiGetV2(`${routesBaseTransportadora}/listar/`, {
      pista: p,
      somente_ativos: somenteAtivos,
    });

    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = useDebounce(async (v) => {
    setPista(v);
    setLoading(true);
    await buscarDados(v);
    setLoading(false);
  }, defaultDebounceTime);

  const toggle = (action) => {
    setAction(action);
    setIsModalOpen(!isModalOpen);
  };

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggle(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Transportadora" number="0117" canGoBack>
      <Card body>
        <Row>
          <SearchInput
            md={4}
            placeholder="Descrição"
            onChange={handlePista}
            loading={loading}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggle} />
          <FormCheckbox
            label="Somente Ativos"
            checked={somenteAtivos}
            onChange={() => setSomenteAtivos(!somenteAtivos)}
          />
          <CadastroTransportadoraModal
            isOpen={isModalOpen}
            toggle={toggle}
            action={action}
            selected={selected}
            notifyEvent={carregarDados}
          />
          <ModalExcluirV2
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            selected={selected}
            number="0117_2"
            notifyEvent={notifyEvent}
            routeBase={routesBaseTransportadora}
          />
        </Row>
      </Card>
      <Card body>
        <TransportadoraGrid
          dados={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
