import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/bancario/gerenciamento";

const GerencBancService = {
  listar: async (params) =>
    await apiGetV2(`${URL_BASE}/listar/`, params),
  incluir: async (payload) =>
    await apiPostV2(`${URL_BASE}/incluir_mov_manual/`, payload),
  buscar_saldo: async (id_conta_banc) =>
    await apiGetV2(`${URL_BASE}/buscar_saldo_conta/${id_conta_banc}/`),
  buscar: async (id_mov) =>
    await apiGetV2(`${URL_BASE}/buscar_mov/${id_mov}/`),
  alterar: async (payload) =>
    await apiPutV2(`${URL_BASE}/alterar_mov_manual/`, payload),
  excluir: async (id_movimento) =>
    await apiPutV2(`${URL_BASE}/excluir_movimento/${id_movimento}/`),
};

export default GerencBancService;
