import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, TabPane } from "reactstrap";
import { NumberInput, TextInput } from "../../../../../components";
import {
  setAlturaModulo,
  setComprimentoModulo,
  setLarguraModulo,
  setModeloInversor,
  setPotenciaInversor,
  setPotenciaModulo,
  setQuantidadeInversor,
  setQuantidadeModulos,
} from "../store/vistoria_slice";

export const TabProposta = () => {
  const vistoriaTecnica = useSelector((state) => state.vistoriaTecnica);
  const dispatch = useDispatch();

  return (
    <TabPane tabId="7" className="p-2">
      <Row>
        <NumberInput
          onChange={(v) => dispatch(setQuantidadeModulos(v))}
          label="Quantidade de Módulos"
          md="auto"
          mdInput={6}
          value={vistoriaTecnica.quantidadeModulos}
        />
      </Row>
      <Row>
        <NumberInput
          onChange={(v) => dispatch(setPotenciaModulo(v))}
          label="Potência do Módulo (W)"
          md="auto"
          mdInput={6}
          value={vistoriaTecnica.potenciaModulo}
        />
        <NumberInput
          disabled
          label="Potência total (kW)"
          md="auto"
          mdInput={6}
          value={(
            (vistoriaTecnica.potenciaModulo *
              vistoriaTecnica.quantidadeModulos) /
            1000
          ).toFixed(3)}
        />
      </Row>
      <Row>
        <NumberInput
          onChange={(v) => dispatch(setLarguraModulo(v))}
          label="Largura do Módulo (cm)"
          md="auto"
          value={vistoriaTecnica.larguraModulo}
        />
        <NumberInput
          onChange={(v) => dispatch(setComprimentoModulo(v))}
          label="Comprimento do Módulo (cm)"
          md="auto"
          value={vistoriaTecnica.comprimentoModulo}
        />
        <NumberInput
          onChange={(v) => dispatch(setAlturaModulo(v))}
          label="Altura do Módulo (cm)"
          md="auto"
          value={vistoriaTecnica.alturaModulo}
        />
      </Row>
      <Row>
        <NumberInput
          onChange={(v) => dispatch(setQuantidadeInversor(v))}
          label="Quantidade do Inversor"
          md="auto"
          mdInput={6}
          value={vistoriaTecnica.quantidadeInversor}
        />
        <NumberInput
          onChange={(v) => dispatch(setPotenciaInversor(v))}
          label="Potência do Inversor (kW)"
          md="auto"
          mdInput={6}
          value={vistoriaTecnica.potenciaInversor}
        />
      </Row>
      <Row>
        <TextInput
          label="Marca do Inversor"
          md={3}
          onChange={({ target }) => dispatch(setModeloInversor(target?.value))}
          value={vistoriaTecnica.modeloInversor}
          maxLength={40}
        />
      </Row>
    </TabPane>
  );
};
