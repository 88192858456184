import unidecode from "unidecode";
import { viewDownloadPDF } from "../../coreUtils";
import { docPost } from "../../pdf";
import moment from "moment";
import { apiGetV2, apiPostV2, apiPutV2, buscarLogoBase64 } from "../../apiV2";

const URL_BASE = "/orcamento";
const URL_BASE_GERENCIAMENTO = `${URL_BASE}/gerenciamento`;
const URL_BASE_ORD_SERV = `${URL_BASE}/ord_serv`;
const URL_BASE_ORCAMENTO_RAPIDO = `${URL_BASE}/orcamento_rapido`;

const OrcamentosService = {
  buscar: async (idOrcamento) =>
    await apiGetV2(`${URL_BASE}/buscar/${idOrcamento}/`),
  buscarParametros: async () => await apiGetV2(`/tela/orcamento/`),
  buscarOrcamentosOS: async (idOs, somenteSituAberta) =>
    await apiGetV2(`${URL_BASE}/buscar_orcamentos_os/${idOs}/`, {
      somente_situ_aberta: somenteSituAberta,
    }),
  buscarDetalhesOrcOS: async (idOrcamento) =>
    await apiGetV2(`${URL_BASE}/buscar_detalhes_orc_os/${idOrcamento}/`),
  alterar: async (payload) => await apiPutV2(`${URL_BASE}/alterar/`, payload),
  imprimir: async (idOrcamento, obedecerParamImp) => {
    const [ok, ret] = await apiGetV2(
      `${URL_BASE}/impressao/buscar/${idOrcamento}/`
    );

    if (!ok) return false;

    if (obedecerParamImp && !["S", "V", "P"].includes(ret.params.imprimir)) {
      return false;
    }

    const layoutsNovos = [11];
    const layout = ret.params.layout
    var rota = ''
    var novoMs = false

    if (layoutsNovos.includes(layout)){
      rota = `/orcamento/layouts/${ret.params.layout}/`
      novoMs = true
    }else{
      rota = "/orcamento/"
    }

    const arq = await docPost(
       rota,
      { ...ret, empresa: { ...ret.empresa, logo: await buscarLogoBase64() } },
      {
        errorMessage: "Erro ao Imprimir Orçamento",
      },
      novoMs
    );

    if (!arq) return false;

    const fileName = `ORC_${idOrcamento}_${unidecode(
      ret.dados_orc.nome_cliente.replaceAll(" ", "_")
    )}_${moment(new Date()).format("YYYYMMDD")}`;

    viewDownloadPDF(arq, fileName);
  },
  imprimirFrenteVenda: async (idOrcamento, obedecerParamImp, extras) => {
    const [ok, ret] = await apiGetV2(
      `${URL_BASE}/impressao/buscar/${idOrcamento}/`
    );

    if (!ok) return false;

    if (obedecerParamImp && !["S", "V", "P"].includes(ret.params.imprimir)) {
      return false;
    }

    const arq = await docPost(
      "/orcamento/",
      {
        ...ret,
        empresa: { ...ret.empresa, logo: await buscarLogoBase64() },
        extras: extras ?? {},
      },
      {
        errorMessage: "Erro ao Imprimir Orçamento",
      }
    );

    if (!arq) return false;

    const fileName = `ORC_${idOrcamento}_${unidecode(
      ret.dados_orc.nome_cliente.replaceAll(" ", "_")
    )}_${moment(new Date()).format("YYYYMMDD")}`;

    viewDownloadPDF(arq, fileName);
    return true;
  },
  incluir: async (payload) => await apiPostV2(`${URL_BASE}/incluir/`, payload),
  enviarEmail: async (idOrcamento) =>
    await apiPostV2(`${URL_BASE}/enviar_email/`, { id_orcamento: idOrcamento }),

  gerenciamento: {
    excluir: async (payload) =>
      await apiPutV2(`${URL_BASE_GERENCIAMENTO}/excluir/`, payload),
    listar: async (params) =>
      await apiGetV2(`${URL_BASE_GERENCIAMENTO}/listar/`, params),
    rejeitar: async (payload) =>
      await apiPutV2(`${URL_BASE_GERENCIAMENTO}/rejeitar/`, payload),
  },
  orcamentoRapido: {
    listar: async (params) =>
      await apiGetV2(`${URL_BASE_ORCAMENTO_RAPIDO}/listar/`, params),
  },
  ordServ: {
    aprovarItem: async (payload) =>
      await apiPutV2(`${URL_BASE_ORD_SERV}/aprovar_item/`, payload),
    fecharOrcamento: async (payload) =>
      await apiPutV2(`${URL_BASE_ORD_SERV}/fechar_orcamento/`, payload),
    transformarEmOS: async (idOrcamento) =>
      await apiPostV2(`${URL_BASE_ORD_SERV}/transformar_em_os/`, {
        id_orcamento: idOrcamento,
      }),
  },
};

export default OrcamentosService;
