import React, { useState } from "react";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import {
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { modalTitleRepresentacaoComercial, routesBaseRepresentacaoComercial } from "../RepresentacaoComerical";


export const RepresentacaoComercialModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [nome, setNome] = useState("");
  const [ativo, setAtivo] = useState(true);

  const limparDados = () => {
    setNome("");
    setAtivo(true);
  };

  const fetchData = (ret) => {
    setNome(ret.nome);
    setAtivo(ret.ativo);
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ativo: ativo, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleRepresentacaoComercial}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseRepresentacaoComercial}
      headerCheck={
        action === MODAL_ACTIONS.EDIT && {
          value: ativo,
          toggle: () => setAtivo(!ativo),
        }
      }
      number="0140_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={60}
        />
      </Row>
    </ModalCadastroV2>
  );
};
