import React from "react";
import { Table, TableCheck } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const EquipamentoGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    { dataField: "nome", text: "Nome", align: "left", headerAlign: "left" },
    { dataField: "marca", text: "Marca", align: "left", headerAlign: "left" },
    {
      dataField: "capacidade",
      text: "Capacidade",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      formatter: (cell) => <TableCheck value={cell} />,
      sortable: true,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return <Table data={data} columns={columns} onSelect={setSelected} />;
};
