import { apiGet, apiPost } from "../../api";
import { toastr } from "react-redux-toastr";
import unidecode from "unidecode";
import moment from "moment";
import { viewDownloadPDF } from "../../coreUtils";
import { docPost } from "../../pdf";
import { apiGetV2, apiPostV2, apiPutV2, apiV2 } from "../../apiV2";

const gerarBoleto = async (dados, id_duplicata) => {
  if (!dados.layout) return false;

  const fileName = `${unidecode(dados.nome_pagador.replaceAll(" ", "_"))}_${
    dados.id_pagador
  }_${moment(dados.dt_vencimento, "DD/MM/YYYY").format("YYYYMMDD")}.pdf`;
  let arq;
  if (dados.imprime_via_api === true) {
    // Impressão via API Banco
    arq = await apiV2
      .post(
        `${URL_BASE_INTEGRA_API}/gerar_pdf_boleto/`,
        { id_duplicata: id_duplicata },
        { responseType: "blob" }
      )
      .then((ret) => ret.data)
      .catch(async (err) => {
        toastr.error(
          "Erro",
          err.response ? await err.response.data.text() : err.message
        );
        return false;
      });

    if (!arq) return false;

    if (arq.type === "application/json") {
      const jsonRet = JSON.parse(await arq.text());
      toastr.error("Erro", jsonRet.msg);
      return false;
    }
  } else {
    // Impressão via microserviço Skillsoft
    arq = await docPost("/boleto/", dados, {
      errorMessage: "Erro ao Gerar Boleto",
    });

    if (!arq) return false;

    if (arq.type === "application/json") {
      const jsonRet = JSON.parse(await arq.text());
      toastr.error("Erro", jsonRet.msg);
      return false;
    }
  }

  viewDownloadPDF(arq, fileName);
};

const URL_BASE = "/bancario/cobranca/boleto";
const URL_BASE_INTEGRA_API = "/bancario/cobranca/integracao_api";

export const BoletoService = {
  listarTituloImprimir: (params) =>
    apiGetV2(`/receber/duplic_rec/listar_titulos_impr_boleto/`, params),
  gerarDadosImpressao: async (payload) =>
    await apiPostV2(`${URL_BASE}/gerar_dados_impressao/`, payload, {
      successMessage: false,
    }).then(async ([ok, res]) => {
      if (ok) {
        await gerarBoleto(res, payload.id_duplicata);
      }
    }),
  gerarRegistroBoleto: async (payload) =>
    await apiPostV2(`${URL_BASE}/gerar_dados_impressao/`, payload, {
      successMessage: false,
    }),
  enviarBoletoEmail: async (idDuplic) =>
    await apiPost(`${URL_BASE}/email/${idDuplic}/`),
  imprimirBoleto: async (idDuplic) =>
    await apiGet(`${URL_BASE}/buscar_dados_impressao/${idDuplic}/`).then(
      async (res) => await gerarBoleto(res, idDuplic)
    ),
  cancelarBoleto: async (payload) =>
    await apiPutV2(`${URL_BASE_INTEGRA_API}/cancelar_boleto/`, payload),
};
