import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import { BotaoPesquisar, SearchInput } from "../../../../components";
import { LinkButton } from "../../../../components/LinkButton";
import { ModalExcluir } from "../../../../components/ModalExcluir";
import { PageContainer } from "../../../../components/PageContainer";
import {
  defaultDebounceTime,
  MODAL_ACTIONS,
  useDebounce,
} from "../../../../coreUtils";
import GrupoRegraTributacaoService from "../../../../services/cadastro/GrupoRegraTributacaoService";
import { GrupoRegraTribGrid } from "./components/GrupoRegraTribGrid";
import { cadastroGrupoRegraTribRoute } from "../../../../routes/modules/cadastro";

export const GrupoRegraTributacao = ({ location }) => {
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [pista, setPista] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);

  const carregarDados = async () => {
    setLoading(true);
    setDados(await GrupoRegraTributacaoService.listar(pista));
    setLoading(false);
  };

  const handlePista = useDebounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    setDados(await GrupoRegraTributacaoService.listar(v));
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = () => {
    carregarDados();
  };

  const onActivate = () => {
    if (location.state?.refresh) {
      carregarDados();
    }
  };
  useEffect(onActivate, []);

  return (
    <PageContainer
      title="Cadastro de Grupos de Regras de Tributação"
      number="0047"
      canGoBack
    >
      <Card body>
        <Row body>
          <SearchInput md={6} onChange={handlePista} loading={loadingPista} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <LinkButton
            pathname={cadastroGrupoRegraTribRoute.path}
            state={{ action: MODAL_ACTIONS.ADD }}
            md="auto"
            color="info"
          >
            Incluir
          </LinkButton>
          <LinkButton
            pathname={cadastroGrupoRegraTribRoute.path}
            state={{ action: MODAL_ACTIONS.EDIT, selected: selected }}
            md="auto"
            color="warning"
            disabled={[0, null, undefined].includes(selected)}
            id="bt-alterar"
          >
            Alterar
          </LinkButton>
          <ModalExcluir
            title="Grupo de Regras de Tributação"
            number="0047_2"
            selected={selected}
            route={`/cadastro/regra_tributacao/grupo_reg/excluir/${selected}/`}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <GrupoRegraTribGrid data={dados} setSelected={setSelected} />
    </PageContainer>
  );
};
