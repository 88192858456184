import classNames from "classnames";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CurrencyInput from "react-currency-input-field";
import { Col, FormFeedback, FormGroup } from "reactstrap";
import { handleFocus } from "../coreUtils";
import { HintLabel } from "./HintLabel";

const NumberInput = forwardRef(
  (
    {
      required = false,
      onChange,
      md,
      mdInput = 12,
      label,
      style,
      disabled = false,
      defaultValue,
      value,
      placeholder = "",
      clearOnDisable = false,
      isPercentage = false,
      autoFocus,
      tabOrder,
      onBlur,
      table,
      decimalPlaces = 2,
      allowNegativeValue = false,
      id,
      divClassName,
      disableGroupSeparators = false,
      name,
      hint,
      labelStyle,
      formGroupClassName,
      onKeyDown,
      additionalButton,
      additionalElement,
      debug = false,
      inline = false,
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(defaultValue ?? null);
    const [invalid, setInvalid] = useState(false);
    const [onFocus, setOnFocus] = useState(false);
    const [triggerOnBlur, setTriggerOnBlur] = useState(false);
    const [internalId] = useState(
      id ?? "ni-" + Math.floor(Math.random() * Date.now())
    );
    const inputRef = useRef();

    const preparaValor = (v) =>
      typeof v === "string"
        ? parseFloat((v || "0.0").replace(".", "").replace(",", "."))
        : v;

    useEffect(() => {
      if (defaultValue) setInputValue(defaultValue);
    }, []);

    useEffect(() => {
      if (value !== preparaValor(inputValue)) {
        setInputValue(value);
      }
    }, [value, onFocus]);

    useEffect(() => {
      if (disabled && clearOnDisable) {
        handleOnChange(0);
      }
    }, [disabled, clearOnDisable]);

    const handleOnChange = (v) => {
      debug && console.log(v);
      setInputValue(v);
      setInvalid(false);
      setTriggerOnBlur(true);
      if (onChange) onChange(preparaValor(v ?? 0));
    };

    const onFocusInternal = (e) => {
      setOnFocus(true);
      e.target.select();
    };

    const onBlurInternal = (e) => {
      setOnFocus(false);
      if (onBlur && triggerOnBlur) onBlur(e, preparaValor(e.target.value ?? 0));
      setTriggerOnBlur(false);
    };

    const onKeyDownInternal = (e) => {
      if (!table) {
        if (onKeyDown) onKeyDown(e);
        if (e.key === "ArrowUp") e.preventDefault();
        handleFocus(e);
      } else if (e.key === "Enter") {
        e.preventDefault();
        onBlurInternal(e);
      }
    };

    useImperativeHandle(ref, () => ({
      floatValue: () => preparaValor(inputValue),
      clear: () => {
        setInputValue(null);
      },
      focus: () => inputRef.current.focus(),
    }));

    return (
      <Col
        md={md}
        className={classNames(table ? "px-0" : undefined, divClassName)}
      >
        <FormGroup
          className={table ? "mb-0" : formGroupClassName}
          style={
            inline
              ? {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "0",
                }
              : {}
          }
        >
          {label && (
            <HintLabel
              for={internalId}
              hintText={hint}
              label={label}
              labelStyle={labelStyle}
              required={required}
              className={inline ? "mr-2" : ""}
            />
          )}
          <Col md={mdInput} className="no-gutters">
            <div style={{ display: "flex" }}>
              <CurrencyInput
                className={"form-control" + (invalid ? " is-invalid" : "")}
                style={{
                  textAlign: isPercentage ? "center" : "right",
                  ...style,
                }}
                onValueChange={handleOnChange}
                onBlur={onBlurInternal}
                onFocus={onFocusInternal}
                value={inputValue !== null ? inputValue : ""}
                invalid={invalid}
                required={required}
                disabled={disabled}
                defaultValue={defaultValue}
                decimalsLimit={decimalPlaces}
                suffix={isPercentage ? " %" : ""}
                decimalScale={decimalPlaces}
                disableAbbreviations
                disableGroupSeparators={disableGroupSeparators}
                allowNegativeValue={allowNegativeValue}
                placeholder={placeholder}
                autoFocus={autoFocus || table}
                onKeyDown={onKeyDownInternal}
                tabIndex={tabOrder}
                ref={inputRef}
                id={internalId}
                name={name}
              />
              {additionalButton && additionalButton}
            </div>
          </Col>
          {additionalElement && additionalElement}
          <FormFeedback valid={false}>Este campo é obrigatório</FormFeedback>
        </FormGroup>
      </Col>
    );
  }
);

NumberInput.propTypes = {
  required: PropTypes.bool,
  onChange: PropTypes.func,
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

export default NumberInput;
