import React, { useState } from "react";
import { MODAL_ACTIONS } from "../../coreUtils";
import { ModalBase } from "../ModalBase";
import { ConcatShortcut } from "../ConcatShortcut";
import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";
import Loader from "../Loader";

export const ModalCadastroV2 = ({
  isOpen,
  toggle,
  action,
  title = "Sem Título",
  size = "lg",
  onOpen,
  onClose,
  fetchData,
  submitPayload,
  headerCheck,
  routesBase,
  number = "Sem Número",
  selected,
  notifyEvent,
  autoTitle = true,
  onKeyDown,
  paramsName,
  footerActions,
  concatShortcut,
  concatModelName,
  bodyStyle,
  footerStyle,
  headerStyle,
  numberStyle,
  children,
  editMethod = "PUT",
  paddedButtons = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingFetchInternal, setLoadingFetchInternal] = useState(false);

  const innerFetchData = async () => {
    const [ok, data] = await apiGetV2(
      `${routesBase}/buscar/${selected}/`,
      null,
      { errorMessage: concatModelName !== "unidade" }
    );
    if (ok) {
      fetchData(data);
      return true;
    } else {
      return false;
    }
  };

  const buscarParametros = async () => {
    if (paramsName) {
      setLoadingFetchInternal(true);
      return await apiGetV2(`/tela/${paramsName}/`);
    } else {
      return [true, undefined];
    }
  };

  const onBeforeOpen = async () => {
    setLoadingFetchInternal(true);
    const [paramOk, paramRet] = await buscarParametros();

    if (action === MODAL_ACTIONS.EDIT && fetchData) {
      if (!(await innerFetchData())) {
        if (concatShortcut) {
          // Exceção no comportamento para o cadastro de unidade
          if (concatModelName === "unidade") {
            action = MODAL_ACTIONS.ADD;
          }
        } else {
          setLoadingFetchInternal(false);
          return false;
        }
      }
    }
    setLoadingFetchInternal(false);
    if (onOpen) onOpen(action, paramOk ? paramRet : undefined);
  };

  const handleSubmit = async () => {
    const payload = submitPayload(action);

    if (payload) {
      setLoading(true);

      const submitFunc =
        action === MODAL_ACTIONS.ADD || editMethod === "POST"
          ? apiPostV2
          : apiPutV2;
      const submitUrl = `${routesBase}/${
        action === MODAL_ACTIONS.ADD ? "incluir" : "alterar"
      }/`;

      let [ok, ret] = await submitFunc(submitUrl, payload);

      if (ok) {
        if (notifyEvent) {
          if (concatShortcut) {
            if (concatModelName === "unidade") {
              ret = payload.codigo;
            } else {
              if (ret.id_cadastrado) {
                ret = ret.id_cadastrado;
              }
            }
            notifyEvent(action === MODAL_ACTIONS.ADD ? ret : selected);
          } else {
            notifyEvent(action);
          }
        }

        toggle(action);
      }
      setLoading(false);
    }
  };

  return (
    <>
      {concatShortcut && concatModelName && (
        <ConcatShortcut
          concatModelName={concatModelName}
          onClick={() =>
            toggle(
              [0, "", null, undefined].includes(selected)
                ? MODAL_ACTIONS.ADD
                : MODAL_ACTIONS.EDIT
            )
          }
        />
      )}
      <ModalBase
        isOpen={isOpen}
        size={size}
        toggle={toggle}
        title={
          <>
            {autoTitle &&
              (action === MODAL_ACTIONS.ADD
                ? "Inclusão de "
                : "Alteração de ") + " "}
            {title}
          </>
        }
        headerCheck={headerCheck}
        number={number}
        onConfirm={handleSubmit}
        loadingConfirm={loading}
        onKeyDown={onKeyDown}
        paramsName={paramsName}
        footerActions={footerActions}
        bodyStyle={bodyStyle}
        headerStyle={headerStyle}
        footerStyle={footerStyle}
        onBeforeOpen={onBeforeOpen}
        onClosed={onClose}
        numberStyle={numberStyle}
        paddedButtons={paddedButtons}
      >
        {loadingFetchInternal ? <Loader /> : children}
      </ModalBase>
    </>
  );
};
