import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  FixedField,
  ModalBase,
} from "../../../../../components";
import GerencBancService from "../../../../../services/financeiro/GerencBancService";

export const ExcluirMovModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [loading, setLoading] = useState(false)
  
  const handleSubmit = async () => {
    setLoading(true);
    const [ok] = await GerencBancService.excluir(selected);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="sm"
        title="Exclusão de Movimento Manual"
        number="0132_3"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <FixedField 
            label={'Tem certeza que deseja excluir este movimento?'}
          />
        </Row>
      </ModalBase>
    </>
  );
};
