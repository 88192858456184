import React, { useEffect, useState } from "react";
import {
  FixedField,
  FormButton,
  NumberInput,
  PageContainer,
  LabelButton,
  FormCheckbox,
} from "../../../../components";
import EntradaNfXmlService from "../../../../services/compras/EntradaNfXmlService";
import { useHistory } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { ProdutosEntradaNfXmlGrid } from "./components/ProdutosEntradaNfXmlGrid";
import {
  MODAL_ACTIONS,
  dateFromLocaleString,
  formatNumber,
  formatValueFromAPI,
  roundFloat,
} from "../../../../coreUtils";
import { ComprasVendasProdutoModal } from "./components/ComprasVendasProdutoModal";
import { v4 as uuidv4 } from "uuid";
import { apiGetV2 } from "../../../../apiV2";
import { AlterarVolumeModal } from "./components/AlterarVolumeModal";
import { IdentificarProdutoModal } from "./components/IdentificarProdutoModal";
import { ProgressoEntradaNfXml } from "../components/ProgressoEntradaNfXml";
import { financeiroEntradaNfXmlRoute } from "../../../../routes/modules/compras";
import { HistComprasProdutoGrid } from "./components/HistComprasProdutoGrid";
import moment from "moment";
import { incluirAlterarProdutoRoute } from "../../../../routes/modules/cadastro";
import { ConfSemAlterarPrecosModal } from "./components/ConfSemAlterarPrecosModal";

export const ProdutosEntradaNfXml = ({ location }) => {
  const selected = location?.state?.selected;
  // Parâmetros
  const [usaPrecoAtacado, setUsaPrecoAtacado] = useState(false);

  // Dados da NF
  const [numero, setNumero] = useState(null);
  const [serie, setSerie] = useState(null);
  const [dataEmi, setDataEmi] = useState(null);
  const [naturezaOperacao, setNaturazaOperacao] = useState(null);
  const [chaveAcesso, setChaveAcesso] = useState(null);
  const [fornecedorCadastrado, setFornecedorCadastrado] = useState(false);
  const [idFornecedor, setIdFornecedor] = useState("");
  const [nomeFornecedor, setNomeFornecedor] = useState("");
  const [fornecedorSimplesNacional, setFornecedorSimplesNacional] =
    useState(false);
  const [vlrOutrosForaNf, setVlrOutrosForaNf] = useState(0);
  const [considerarDesconto, setConsiderarDesconto] = useState(true);
  const [itens, setItens] = useState([]);

  // Dados do produto selecionado
  const [selectedDados, setSelectedDados] = useState({});
  const [selectedUuid, setSelectedUuid] = useState(null);
  const [selectedIdProduto, setSelectedIdProduto] = useState(null);
  const [selectedLigacaoEncontrada, setSelectedLigacaoEncontrada] =
    useState(null);
  const [selectedIdLigacaoProdFornec, setSelectedIdLigacaoProdFornec] =
    useState(null);
  const [selectedCodProdNf, setSelectedCodProdNf] = useState(null);
  const [selectedEanProdNf, setSelectedEanProdNf] = useState(null);
  const [selectedDescProdNf, setSelectedDescProdNf] = useState(null);
  const [selectedUnidProdNf, setSelectedUnidProdNf] = useState(null);
  const [selectedNomeProduto, setSelectedNomeProduto] = useState(null);
  const [selectedUnidMedProd, setSelectedUnidMedProd] = useState(null);
  const [selectedHistCompra, setSelectedHistCompra] = useState([]);

  // Variáveis de Controle
  const [loading, setLoading] = useState(true);
  const [loadingDesconto, setLoadingDesconto] = useState(false);
  const [atualizarValoresItens, setAtualizarValoresItens] = useState(false);
  const [comprasVendasOpen, setComprasVendasOpen] = useState(false);
  const [confSemAlterarPrecosOpen, setConfSemAlterarPrecosOpen] =
    useState(false);
  const [todosItensMarcados, setTodosItensMarcados] = useState(false);

  const history = useHistory();

  const carregarDados = async () => {
    const [ok, ret] = await EntradaNfXmlService.buscarDadosNf(selected);
    if (ok) {
      setNumero(ret.numero);
      setSerie(ret.serie);
      setDataEmi(ret.data_emi);
      setChaveAcesso(ret.chave_acesso);
      setFornecedorCadastrado(ret.fornecedor_cadastrado);
      setIdFornecedor(ret.id_fornecedor);
      setNomeFornecedor(ret.nome_fornecedor);
      setFornecedorSimplesNacional(ret.fornecedor_simples_nacional);
      setVlrOutrosForaNf(parseFloat(ret.vlr_outros_fora_nf));
      setConsiderarDesconto(ret.considera_desconto);
      setNaturazaOperacao(ret.natureza_operacao);
      setItens(
        ret.itens.map((e) => ({
          ...e,
          uuid: uuidv4(),
          vlr_unit_prod: parseFloat(e.vlr_unit_prod),
          perc_lucro: parseFloat(e.perc_lucro),
          preco_venda: parseFloat(e.preco_venda),
          perc_luctro_atacado: parseFloat(e.perc_luctro_atacado),
          preco_atacado: parseFloat(e.preco_atacado),
          vlr_custo_unit: parseFloat(e.vlr_custo_unit),
          vlr_outros_fora_nf: parseFloat(e.vlr_outros_fora_nf),
        }))
      );
    }
    return ok;
  };

  const buscarParams = async () => {
    const [ok, ret] = await apiGetV2("/tela/entrada_nf_xml/");
    if (ok) {
      setUsaPrecoAtacado(ret.usa_preco_atacado);
    }
    return ok;
  };

  const recalcularValoresGrade = (coluna, novoValor, row) => {
    const valorAnterior = roundFloat(parseFloat(row[coluna]), 2);
    const novoValorTest = roundFloat(parseFloat(novoValor), 2);
    if (novoValorTest === valorAnterior) {
      return false;
    }

    if (coluna === "preco_venda") {
      row.preco_venda = parseFloat(novoValor);

      if (row.vlr_custo_unit > 0) {
        row.perc_lucro = roundFloat(
          (row.preco_venda * 100) / row.vlr_custo_unit - 100,
          2
        );
      }
    } else if (coluna === "perc_lucro") {
      row.perc_lucro = parseFloat(novoValor);

      if (row.vlr_custo_unit > 0) {
        row.preco_venda = roundFloat(
          row.vlr_custo_unit + row.vlr_custo_unit * (row.perc_lucro / 100),
          2
        );
      }
    } else if (coluna === "preco_atacado") {
      row.preco_atacado = parseFloat(novoValor);

      if (row.vlr_custo_unit > 0) {
        row.perc_lucro_atacado = roundFloat(
          (row.preco_atacado * 100) / row.vlr_custo_unit - 100,
          2
        );
      }
    } else if (coluna === "perc_lucro_atacado") {
      row.perc_lucro_atacado = parseFloat(novoValor);

      if (row.vlr_custo_unit > 0) {
        row.preco_atacado = roundFloat(
          row.vlr_custo_unit +
            row.vlr_custo_unit * (row.perc_lucro_atacado / 100),
          2
        );
      }
    } else if (coluna === "vlr_outros_fora_nf") {
      row.vlr_outros_fora_nf = parseFloat(novoValor);

      const vlrOutrosForaUnit = roundFloat(
        row.vlr_outros_fora_nf / row.qtd_prod_entrada,
        2
      );
      row.vlr_custo_unit = roundFloat(row.vlr_unit_prod + vlrOutrosForaUnit, 2);

      if (row.vlr_custo_unit > 0) {
        if (row.preco_venda > 0) {
          row.perc_lucro = roundFloat(
            (row.preco_venda * 100) / row.vlr_custo_unit - 100,
            2
          );
        }

        if (usaPrecoAtacado && row.preco_atacado > 0) {
          row.perc_lucro_atacado = roundFloat(
            (row.preco_atacado * 100) / row.vlr_custo_unit - 100,
            2
          );
        }
      }
    }

    row.atualiza_preco_venda = true;

    setAtualizarValoresItens(true);
    setItens(itens.map((e) => (e.uuid === row.uuid ? row : e)));
  };

  const marcarDesmarcarItem = (row, buscarDados = true) => {
    setAtualizarValoresItens(true);

    const payload = {
      ...row,
      atualiza_preco_venda: !row.atualiza_preco_venda,
    };
    atualizarDadosItem(payload, true, buscarDados);
  };

  const toggleMarcarTodos = () => {
    itens.forEach((e) => {
      if (todosItensMarcados) {
        if (e.atualiza_preco_venda) marcarDesmarcarItem(e, false);
      } else {
        if (!e.atualiza_preco_venda) marcarDesmarcarItem(e, false);
      }
    });

    carregarDados();
  };

  const onChangeItens = () => {
    const todosMarcados = itens.every((e) => e.atualiza_preco_venda);

    setTodosItensMarcados(todosMarcados);
  };

  useEffect(onChangeItens, [itens]);

  const atualizarDadosItem = async (row, forcarAtu, buscarDados = true) => {
    if (
      (atualizarValoresItens || forcarAtu) &&
      row.id_nf_entrada_xml_item > 0
    ) {
      const payload = {
        id_nf_entrada_xml_item: row.id_nf_entrada_xml_item,
        perc_lucro: parseFloat(row.perc_lucro),
        preco_venda: parseFloat(row.preco_venda),
        perc_lucro_atacado: parseFloat(row.perc_lucro_atacado),
        preco_atacado: parseFloat(row.preco_atacado),
        atualiza_preco_venda: row.atualiza_preco_venda,
        vlr_outros_fora_nf: parseFloat(row.vlr_outros_fora_nf),
        vlr_custo_unit: parseFloat(row.vlr_custo_unit),
      };

      await EntradaNfXmlService.alterarDadosItem(payload);

      if (buscarDados) carregarDados();
    }
  };

  const atualizarValoresNf = async (buscarDados = true, considerarDesc) => {
    const payload = {
      id_nf_entrada_xml: selected,
      vlr_outros_fora_nf: vlrOutrosForaNf,
      considerar_desconto: considerarDesc,
    };

    await EntradaNfXmlService.alterarValoresNf(payload);

    if (buscarDados) carregarDados();
  };

  const handleSelect = (uuid, is, row) => {
    setSelectedUuid(uuid);
    setSelectedDados(is ? row : {});
    if (is) {
      setSelectedLigacaoEncontrada(row.ligacao_encontrada);
      setSelectedIdLigacaoProdFornec(row.id_ligacao_prod_fornec);
      setSelectedIdProduto(row.id_produto);
      setSelectedCodProdNf(row.cod_prod_nf);
      setSelectedEanProdNf(row.ean_prod_nf);
      setSelectedDescProdNf(row.desc_prod_nf);
      setSelectedUnidProdNf(row.unid_prod_nf);
      setSelectedNomeProduto(row.nome_produto);
      setSelectedUnidMedProd(row.unid_med_prod);
      setSelectedHistCompra(row.hist_compra);
    } else {
      setSelectedLigacaoEncontrada(null);
      setSelectedIdLigacaoProdFornec(null);
      setSelectedIdProduto(null);
      setSelectedCodProdNf(null);
      setSelectedEanProdNf(null);
      setSelectedDescProdNf(null);
      setSelectedUnidProdNf(null);
      setSelectedNomeProduto(null);
      setSelectedUnidMedProd(null);
      setSelectedHistCompra([]);
    }
  };

  const toggleComprasVendas = () => setComprasVendasOpen(!comprasVendasOpen);

  const toggleConfSemAlterarPrecos = () =>
    setConfSemAlterarPrecosOpen(!confSemAlterarPrecosOpen);

  const comprasVendasProd = (idProd) => {
    setSelectedIdProduto(idProd);
    setTimeout(() => {
      toggleComprasVendas();
    }, 1);
  };

  const continuar = () => {
    if (!itens.some((e) => e.atualiza_preco_venda)) {
      toggleConfSemAlterarPrecos();
      return;
    }

    confirmarContinuar();
  };

  const confirmarContinuar = () => {
    history.push(financeiroEntradaNfXmlRoute.path, { selected: selected });
  };

  const iniciarTela = async () => {
    if (!(await buscarParams())) {
      history.goBack();
    }

    if (!(await carregarDados())) {
      history.goBack();
    }
    setLoading(false);
  };

  const cadastrarNovoProduto = () => {
    const id = Math.floor(Math.random() * Date.now());
    const cadastro = window.open(incluirAlterarProdutoRoute.path);

    cadastro.window.parameters = JSON.stringify({
      id: id,
      action: ![0, null, undefined, ""].includes(selectedIdProduto)
        ? MODAL_ACTIONS.EDIT
        : MODAL_ACTIONS.ADD,
      selected: selectedIdProduto,
      dadosPreCarregarInclusao: {
        nome: (selectedDados.desc_prod_nf ?? "").substring(0, 80),
        codBar: selectedDados.ean_prod_nf ?? "",
        ncm: selectedDados.ncm_prod_nf ?? "",
        cest: selectedDados.cest_prod_nf ?? "",
      },
      permiteAlterarQtd: false,
      permiteAlterarPrecos: false,
      cadastrarLigacaoProduto: [0, null, undefined, ""].includes(
        selectedIdProduto
      ),
      idFornecedorLigacao: idFornecedor,
      codProdFornecLigacao: selectedDados.cod_prod_nf ?? "",
      eanProdFornecLigacao: selectedDados.ean_prod_nf ?? "",
    });

    const func = (event) => {
      if (event.origin !== window.location.origin && !event.data?.selected) {
        return;
      }

      if (event.data.id !== id) {
        return;
      }

      EntradaNfXmlService.recalcularValoresItem(
        selectedDados.id_nf_entrada_xml_item,
        true
      ).then(([ok, _]) => {
        if (ok) carregarDados();
      });

      window.removeEventListener("message", func);
    };

    window.addEventListener("message", func);
  };

  const onActivate = () => {
    iniciarTela();
  };

  useEffect(onActivate, []);

  const handleConsiderarDesconto = async () => {
    setLoadingDesconto(true);
    try {
      setConsiderarDesconto(!considerarDesconto);

      await atualizarValoresNf(false, !considerarDesconto);

      carregarDados();
    } finally {
      setLoadingDesconto(false);
    }
  };

  const temProdutosNaoIdentificados = itens.some((e) => !e.ligacao_encontrada);

  const onKeyDown = (e) => {
    if (e.key === "F9") {
      continuar();
    }
  };

  return (
    <PageContainer
      title="Entrada de Nota Fiscal via XML"
      number="0101_1"
      loading={loading}
      onKeyDown={onKeyDown}
    >
      <Card body>
        <Row>
          <Col md={8}>
            <Row className="mb-2">
              <FixedField horizontal label="Número" value={numero} />
              <FixedField horizontal label="Série" value={serie} />
              <FixedField horizontal label="Emissão" value={dataEmi} />
              <FixedField
                horizontal
                label="Natureza Operação"
                value={naturezaOperacao}
              />
            </Row>
            <Row className="mb-2">
              <FixedField
                horizontal
                label="Fornecedor"
                value={
                  fornecedorCadastrado ? (
                    <>
                      {formatValueFromAPI(nomeFornecedor, idFornecedor)}
                      {fornecedorSimplesNacional && (
                        <span
                          style={{
                            marginLeft: "0.5rem",
                            background: "rgb(67, 201, 144)",
                            paddingInline: "0.5rem",
                            borderRadius: "0.3rem",
                            color: "white",
                          }}
                        >
                          Simples Nacional
                        </span>
                      )}
                    </>
                  ) : (
                    nomeFornecedor
                  )
                }
              />
            </Row>
            <Row>
              <FixedField horizontal label="Chave Acesso" value={chaveAcesso} />
            </Row>
          </Col>
          <ProgressoEntradaNfXml activeStep={1} md={4} />
        </Row>
        <ComprasVendasProdutoModal
          isOpen={comprasVendasOpen}
          toggle={toggleComprasVendas}
          selected={selectedIdProduto}
        />
        <Row>
          <FormButton
            color="info"
            onClick={cadastrarNovoProduto}
            disabled={[0, null, undefined].includes(selectedUuid)}
            divClassName="pr-0"
          >
            Cadastrar Produto
          </FormButton>
          <IdentificarProdutoModal
            selectedIdNfEntradaXmlItem={selectedDados.id_nf_entrada_xml_item}
            idFornecedorNf={idFornecedor}
            nomeFornecedorNf={nomeFornecedor}
            selectedUuid={selectedUuid}
            selectedLigacaoEncontrada={selectedLigacaoEncontrada}
            selectedIdLigacaoProdFornec={selectedIdLigacaoProdFornec}
            selectedCodProdNf={selectedCodProdNf}
            selectedEanProdNf={selectedEanProdNf}
            selectedDescProdNf={selectedDescProdNf}
            selectedUnidProdNf={selectedUnidProdNf}
            notifyEvent={carregarDados}
          />
          <AlterarVolumeModal
            selectedIdNfEntradaXmlItem={selectedDados.id_nf_entrada_xml_item}
            selectedUuid={selectedUuid}
            selectedLigacaoEncontrada={selectedLigacaoEncontrada}
            selectedIdLigacaoProdFornec={selectedIdLigacaoProdFornec}
            selectedIdProduto={selectedIdProduto}
            selectedUnidProdNf={selectedUnidProdNf}
            selectedNomeProduto={selectedNomeProduto}
            selectedUnidMedProd={selectedUnidMedProd}
            notifyEvent={carregarDados}
          />
          <FormButton
            color="primary"
            onClick={toggleComprasVendas}
            disabled={[0, null, undefined].includes(selectedIdProduto)}
            disabledHint="Selecione um produto com ligação identificada"
            divClassName="mx-5"
          >
            Compras & Vendas
          </FormButton>
          <NumberInput
            md={2}
            label="Outras Desp. Fora NF"
            value={vlrOutrosForaNf}
            onChange={setVlrOutrosForaNf}
            onBlur={() =>
              setTimeout(async () => {
                await atualizarValoresNf(true, considerarDesconto);
              }, 1)
            }
            hint="Este Valor será rateado sobre o custo unitário dos produtos"
            disabled={temProdutosNaoIdentificados}
          />
          <FormCheckbox
            label="Considerar Desconto"
            name="considerar_desconto"
            checked={considerarDesconto}
            onChange={handleConsiderarDesconto}
            disabled={loadingDesconto}
          />
          <ConfSemAlterarPrecosModal
            isOpen={confSemAlterarPrecosOpen}
            toggle={toggleConfSemAlterarPrecos}
            onConfirm={confirmarContinuar}
          />
        </Row>
      </Card>
      <Card body>
        <ProdutosEntradaNfXmlGrid
          usaPrecoAtacado={usaPrecoAtacado}
          dados={itens}
          onSelect={handleSelect}
          comprasVendasProd={comprasVendasProd}
          handleEditItem={recalcularValoresGrade}
          atualizarValoresItens={(row) => atualizarDadosItem(row, false, false)}
          marcarDesmarcarItem={marcarDesmarcarItem}
        />
      </Card>
      <Card body>
        <Row className="pr-0">
          <Col md={8} className="pr-0">
            <Row>
              <Col className="pr-0">
                <HistComprasProdutoGrid
                  dados={selectedHistCompra}
                  usaPrecoAtacado={usaPrecoAtacado}
                />
              </Col>
            </Row>
          </Col>
          <Col md={4} className="pr-0">
            <Row className="mb-2">
              <FixedField
                md={4}
                label="Preço Venda Atual"
                textStyle={{ display: "flex", justifyContent: "space-between" }}
                value={
                  <>
                    <span>
                      {formatNumber(
                        selectedDados?.perc_lucro_prod ?? 0,
                        true,
                        2,
                        true
                      )}
                      %
                    </span>
                    <span>
                      R${" "}
                      {formatNumber(
                        selectedDados?.preco_venda_prod ?? 0,
                        true,
                        2
                      )}
                    </span>
                  </>
                }
                divStyle={{
                  borderRightColor: "#ccc",
                  borderRightStyle: "solid",
                  borderRightWidth: "1px",
                }}
              />
              {usaPrecoAtacado && (
                <FixedField
                  md={4}
                  label="Preço Atacado"
                  textStyle={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  value={
                    <>
                      <span>
                        {formatNumber(
                          selectedDados?.perc_lucro_atacado_prod ?? 0,
                          true,
                          2,
                          true
                        )}
                        %
                      </span>
                      <span>
                        R${" "}
                        {formatNumber(
                          selectedDados?.preco_atacado_prod ?? 0,
                          true,
                          2
                        )}
                      </span>
                    </>
                  }
                  divStyle={{
                    borderRightColor: "#ccc",
                    borderRightStyle: "solid",
                    borderRightWidth: "1px",
                  }}
                />
              )}
              <FixedField
                md={4}
                label="Estoque"
                value={formatNumber(
                  selectedDados?.quantidade_prod ?? 0,
                  true,
                  2,
                  true
                )}
                divClassName="pr-0"
              />
            </Row>
            <Row
              className="mb-2 pt-2"
              style={{
                borderTopColor: "#ccc",
                borderTopStyle: "solid",
                borderTopWidth: "1px",
              }}
            >
              <FixedField
                md={4}
                label="Custo Médio"
                value={
                  "R$ " +
                  formatNumber(selectedDados?.custo_med_prod ?? 0, true, 2)
                }
                divClassName="pr-0"
                divStyle={{
                  borderRightColor: "#ccc",
                  borderRightStyle: "solid",
                  borderRightWidth: "1px",
                }}
              />
              <FixedField
                md={4}
                label="Custo Máx."
                value={
                  "R$ " +
                  formatNumber(selectedDados?.custo_max_prod ?? 0, true, 2)
                }
                divClassName="pr-0"
                divStyle={{
                  borderRightColor: "#ccc",
                  borderRightStyle: "solid",
                  borderRightWidth: "1px",
                }}
              />
              <FixedField
                md={4}
                label="Última Entrada"
                textStyle={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                value={
                  <>
                    {selectedDados?.data_ue_prod && (
                      <>
                        <span>
                          {moment(
                            dateFromLocaleString(selectedDados?.data_ue_prod)
                          ).format("DD/MM/YY")}
                        </span>
                        <span>
                          R${" "}
                          {formatNumber(
                            selectedDados?.custo_ue_prod ?? 0,
                            true,
                            2
                          )}
                        </span>
                      </>
                    )}
                  </>
                }
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <LabelButton onClick={toggleMarcarTodos}>
            {!todosItensMarcados ? "" : "Não "}Atualizar Todos
          </LabelButton>
          <FormButton
            padded={false}
            color="success"
            divClassName="ml-auto pr-0"
            onClick={continuar}
          >
            F9 - Continuar
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
