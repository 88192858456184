import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  utiliza_romaneio: false,
  gera_estoque_inc_manual_nfe: false,
  layout_relatorio_nf_contab: 1,
  usa_preco_atacado: false,
  priorizar_pesq_prod: "COD",
  id_plano_contas_pad_locacao: null,
  utiliza_centro_custo: false,
  forma_sistema_tribut: "CFOP",
  respons_por_envio_de_email: "SKILL",
  forma_geracao_pdf_web: "VISUALIZAR",
  utiliza_organizacao: false,
  id_organizacao_padrao: null,
  ordenar_impr_pesq_prod_por: "NOM",
  lado_coluna_selecao_grades: "ESQ",
  tem_dev_outsourcing: false,
  habilita_identif_representacao: false,
};

export const geralSlice = createSlice({
  name: "geral_slice",
  initialState: initialState,
  reducers: {
    setupGeral: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setUtilizaRomaneio: (state, action) => {
      state.utiliza_romaneio = action.payload;
    },
    setGeraEstoqueIncManualNfe: (state, action) => {
      state.gera_estoque_inc_manual_nfe = action.payload;
    },
    setLayoutRelatorioNfContab: (state, action) => {
      state.layout_relatorio_nf_contab = action.payload;
    },
    setUsaPrecoAtacado: (state, action) => {
      state.usa_preco_atacado = action.payload;
    },
    setPriorizarPesqProd: (state, action) => {
      state.priorizar_pesq_prod = action.payload;
    },
    setIdPlanoContasPadLocacao: (state, action) => {
      state.id_plano_contas_pad_locacao = action.payload;
    },
    setUtilizaCentroCusto: (state, action) => {
      state.utiliza_centro_custo = action.payload;
    },
    setFormaSistemaTribut: (state, action) => {
      state.forma_sistema_tribut = action.payload;
    },
    setResponsPorEnvioDeEmail: (state, action) => {
      state.respons_por_envio_de_email = action.payload;
    },
    setFormaGeracaoPdfWeb: (state, action) => {
      state.forma_geracao_pdf_web = action.payload;
    },
    setUtilizaOrganizacao: (state, action) => {
      state.utiliza_organizacao = action.payload;
      if (action.payload !== true) {
        state.id_organizacao_padrao = null;
      }
    },
    setIdOrganizacaoPadrao: (state, action) => {
      state.id_organizacao_padrao = action.payload ?? null;
    },
    setOrdenarImprPesqProdPor: (state, action) => {
      state.ordenar_impr_pesq_prod_por = action.payload;
    },
    setLadoColunaSelecaoGrades: (state, action) => {
      state.lado_coluna_selecao_grades = action.payload;
    },
    setTemDevOutsourcing: (state, action) => {
      state.tem_dev_outsourcing = action.payload;
    },
    setTemRepresentacao: (state, action) => {
      state.habilita_identif_representacao = action.payload;
    },
  },
});

export const {
  setupGeral,
  setUtilizaRomaneio,
  setGeraEstoqueIncManualNfe,
  setLayoutRelatorioNfContab,
  setUsaPrecoAtacado,
  setPriorizarPesqProd,
  setIdPlanoContasPadLocacao,
  setUtilizaCentroCusto,
  setFormaSistemaTribut,
  setResponsPorEnvioDeEmail,
  setFormaGeracaoPdfWeb,
  setUtilizaOrganizacao,
  setIdOrganizacaoPadrao,
  setOrdenarImprPesqProdPor,
  setLadoColunaSelecaoGrades,
  setTemDevOutsourcing,
  setTemRepresentacao,
} = geralSlice.actions;

export default geralSlice.reducer;
