import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import ListagemValorEstoqueService from "../../../../services/relatorios/estoque/ListagemValorEstoqueService";
import {
  AsyncComboBox,
  BotaoImprimir,
  ComboBox,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { docPrintReport } from "../../../../pdf";

const ordenarPorOptions = [
  { label: "Código", value: "CODIGO" },
  { label: "Nome", value: "NOME" },
  { label: "Referência", value: "REFERENCIA" },
];

export const ListagemValorEstoque = () => {
  const [somenteAtivos, setSomenteAtivos] = useState(true);
  const [somenteComEst, setSomenteComEst] = useState(false);
  const [idGrupo, setIdGrupo] = useState([]);
  const [idSubGrupo, setIdSubGrupo] = useState([]);
  const [idFabricante, setIdFabricante] = useState([]);
  const [idTipoProd, setIdTipoProd] = useState([]);
  const [ordenarPor, setOrdenarPor] = useState(ordenarPorOptions[0].value);
  const [loading, setLoading] = useState(false);

  const buscarListagem = () =>
    ListagemValorEstoqueService.listar({
      somente_ativos: somenteAtivos,
      somente_com_est: somenteComEst,
      id_grupo: idGrupo?.map((e) => e.value),
      id_sub_grupo: idSubGrupo?.map((e) => e.value),
      id_fabricante: idFabricante?.map((e) => e.value),
      id_tipo_prod: idTipoProd?.map((e) => e.value),
      ordenar_por: ordenarPor,
    });

  const imprimirListagem = async () => {
    setLoading(true);
    const [ok, ret] = await buscarListagem();
    if (ok) {
      const payload = {
        dados: ret,
      };
      await docPrintReport(
        "/relatorios/estoque/listagem_valor_estoque/",
        payload,
        "5063",
        true
      );
      setLoading(false);
    }
  };

  return (
    <PageContainer title="Listagem de Valor em Estoque" number="5063" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            label="Grupo"
            onChange={(v) => setIdGrupo(v)}
            value={idGrupo}
            md={3}
            concatModelName="grupo"
            isMulti
          />
          <AsyncComboBox
            label="Sub-Grupo"
            onChange={(v) => setIdSubGrupo(v)}
            value={idSubGrupo}
            concatModelName="sub_grupo"
            md={3}
            isMulti
          />
          <AsyncComboBox
            label="Fabricante"
            onChange={(v) => setIdFabricante(v)}
            value={idFabricante}
            concatModelName="fabricante"
            md={3}
            isMulti
          />
          <AsyncComboBox
            label="Tipo de Produto"
            onChange={(v) => setIdTipoProd(v)}
            value={idTipoProd}
            concatModelName="tipo_prod"
            defaultOptions
            md={3}
            isMulti
          />
        </Row>
        <Row>
          <FormCheckbox
            label="Somente Ativos"
            onChange={() => setSomenteAtivos(!somenteAtivos)}
            checked={somenteAtivos}
          />
          <FormCheckbox
            label="Somente Produtos com Estoque"
            onChange={() => setSomenteComEst(!somenteComEst)}
          />
          <ComboBox
            options={ordenarPorOptions}
            label="Ordenar Por"
            onChange={(s) => setOrdenarPor(s?.value)}
            defaultValue={ordenarPor}
            md={2}
          />
          <BotaoImprimir onClick={imprimirListagem} loading={loading} />
        </Row>
      </Card>
    </PageContainer>
  );
};
