import React from "react";
import { FormCheckbox } from "../../../../components";
import { Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setForObrigCpf, setForObrigCnpj } from "../store/cadastro_slice";

export const PersonCadastro = () => {
  const store = useSelector((state) => state.cadastro);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <FormCheckbox
          padded={false}
          label="Obrigar Informação de CPF no Cadastro de Fornecedor"
          checked={store.for_obrig_cpf}
          onChange={() => dispatch(setForObrigCpf(!store.for_obrig_cpf))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Obrigar Informação de CNPJ no Cadastro de Fornecedor"
          checked={store.for_obrig_cnpj}
          onChange={() => dispatch(setForObrigCnpj(!store.for_obrig_cnpj))}
        />
      </Row>
    </>
  );
};
