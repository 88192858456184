import React, { useState } from "react";
import {
  AsyncComboBox,
  FormButton,
  IntegerFormInput,
  ModalBase,
} from "../../../../../components";
import { Row } from "reactstrap";
import { showWarning } from "../../../../../components/AlertaModal";
import ClienteExcecaoInadBoletoService from "../../../../../services/cadastro/ClienteExcecaoInadBoletoService";

export const IncluirExcecaoInadModal = ({ notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idCliente, setIdCliente] = useState(null);
  const [numDiasBloqueio, setNumDiasBloqueio] = useState(null);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setIdCliente(null);
    setNumDiasBloqueio(null);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idCliente)) {
      showWarning("Por favor, informe o Cliente", null, null, 'async-nome-cli');
      return;
    }

    if ([null, undefined].includes(numDiasBloqueio)) {
      showWarning("Por favor, informe o número de Dias de Bloqueio", null, null, 'input-num-dias-bloq');
      return;
    }

    const payload = {
      id_cliente: idCliente,
      num_dias_bloqueio: numDiasBloqueio,
    };
    setLoading(true);
    const [ok] = await ClienteExcecaoInadBoletoService.incluir(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton md="auto" onClick={toggle}>
        Incluir
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Incluir Exceção de Inadimplência"
        number="0113_1"
        onClosed={limparDados}
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <AsyncComboBox
            md={12}
            label="Cliente"
            concatModelName="cliente"
            defaultValue={idCliente}
            onChange={(s) => setIdCliente(s?.value)}
            inputName={'async-nome-cli'}
          />
        </Row>
        <Row>
          <IntegerFormInput
            md={4}
            label="Dias de Bloqueio"
            defaultValue={numDiasBloqueio}
            onChange={setNumDiasBloqueio}
            name={'input-num-dias-bloq'}
          />
        </Row>
      </ModalBase>
    </>
  );
};
