import React, { useEffect, useRef, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import {
  AsyncComboBox,
  DatePicker,
  ModalBase,
  TextInput,
} from "../../../../../../components";
import moment from "moment";
import { formatDateISO } from "../../../../../../coreUtils";
import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import { addRecebTrasnfBanc } from "../store/moedas_rec_slice";
import { apiGetV2 } from "../../../../../../apiV2";

const rowStyle = { marginBottom: "0.5rem" };

export const RecTransfBancModal = ({
  vlrTransfBanc,
  isOpen,
  toggle,
  pixTefHabilitado,
  idContaBancPix,
}) => {
  const [idContaBanc, setIdContaBanc] = useState(null);
  const [observ, setObserv] = useState("");
  const [nroComprovante, setNroComprovante] = useState("");
  const [tipoTransf, setTipoTransf] = useState("PXT");
  const [dataPrevRec, setDataPrevRec] = useState(new Date());

  const radioGroupRef = useRef();

  const dispatch = useDispatch();

  const limparDados = () => {
    setIdContaBanc(null);
    setNroComprovante("");
    setObserv("");
    setDataPrevRec(new Date());
    if (pixTefHabilitado) {
      setTipoTransf("PXT");
    } else {
      setTipoTransf("PIX");
    }
  };

  const carregarParametros = async () => {
    const [ok, ret] = await apiGetV2("/tela/caixa_loja/receb_transf_banc/");

    if (ok) {
      setIdContaBanc(ret.id_conta_banc_receb_transf_banc);
    }
  };

  const handleSetContaBanc = (s) => {
    setIdContaBanc(s?.value);
  };

  useEffect(() => {
    if (pixTefHabilitado) {
      setTipoTransf("PXT");
    } else {
      setTipoTransf("PIX");
    }
  }, [pixTefHabilitado]);

  const onConfirm = () => {
    let payload;
    if (pixTefHabilitado && tipoTransf === "PXT") {
      payload = {
        id_conta: idContaBancPix,
        nro_comprovante: "",
        data_mov: formatDateISO(new Date()),
        obs: "",
        tipo_transf: "PXT",
      };
    } else {
      if ([0, null, undefined].includes(idContaBanc)) {
        toastr.warning(
          "Atenção",
          "Por favor, informe a Conta Bancária da Transferência"
        );
        return false;
      }
      if (!(dataPrevRec instanceof Date)) {
        toastr.warning(
          "Atenção",
          "Por favor, informe a Data de Previsão de Recebimento"
        );
        return false;
      }
      payload = {
        id_conta: idContaBanc,
        nro_comprovante: nroComprovante,
        data_mov: formatDateISO(dataPrevRec),
        obs: observ,
        tipo_transf: tipoTransf,
      };
    }
    dispatch(addRecebTrasnfBanc({ valor: vlrTransfBanc, dados_rec: payload }));
    toggle();
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Recebimento em Transferência Bancária"
      number="0063_14"
      onBeforeOpen={carregarParametros}
      onConfirm={onConfirm}
      onClosed={limparDados}
      bodyStyle={{ paddingRight: "0" }}
    >
      <Row
        style={{
          ...rowStyle,
          paddingRight: "15px",
          paddingLeft: "15px",
        }}
      >
        <span style={{ fontSize: "1rem" }}>
          Selecione o Tipo de Transferência
        </span>
      </Row>
      <FormGroup
        onChange={({ target }) => setTipoTransf(target.value)}
        ref={radioGroupRef}
        onKeyPress={(event) => {
          event.stopPropagation();
          event.preventDefault();
          if (event.key === "1" && pixTefHabilitado) {
            setTipoTransf("PXT");
          } else if (event.key === "2") {
            setTipoTransf("PIX");
          } else if (event.key === "3") {
            setTipoTransf("TED");
          } else if (event.key === "4") {
            setTipoTransf("DEP");
          }
        }}
      >
        <Row style={rowStyle}>
          <Col md={null}>
            <FormGroup
              check
              size="lg"
              onFocus={() => radioGroupRef.current?.focus()}
            >
              <Label check size="lg" style={{ fontWeight: "bolder" }}>
                <Input
                  type="radio"
                  inline
                  value="PXT"
                  checked={tipoTransf === "PXT"}
                  disabled={!pixTefHabilitado}
                />
                1 - PIX TEF
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col md={null}>
            <FormGroup
              check
              size="lg"
              onFocus={() => radioGroupRef.current?.focus()}
            >
              <Label check size="lg" style={{ fontWeight: "bolder" }}>
                <Input
                  type="radio"
                  inline
                  value="PIX"
                  checked={tipoTransf === "PIX"}
                />
                2 - PIX Sem TEF
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col md={null}>
            <FormGroup
              check
              size="lg"
              onFocus={() => radioGroupRef.current?.focus()}
            >
              <Label check size="lg" style={{ fontWeight: "bolder" }}>
                <Input
                  type="radio"
                  inline
                  value="TED"
                  checked={tipoTransf === "TED"}
                />
                3 - TED
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col md={null}>
            <FormGroup
              check
              size="lg"
              onFocus={() => radioGroupRef.current?.focus()}
            >
              <Label check size="lg" style={{ fontWeight: "bolder" }}>
                <Input
                  type="radio"
                  inline
                  value="DEP"
                  checked={tipoTransf === "DEP"}
                />
                4 - Depósito Bancário
              </Label>
            </FormGroup>
          </Col>
        </Row>
      </FormGroup>
      {tipoTransf !== "PXT" && (
        <>
          <Row>
            <AsyncComboBox
              label="Conta Bancária"
              md={12}
              isConcatField
              concatModelName="conta_banc"
              defaultOptions
              isSearchable
              defaultValue={idContaBanc}
              onChange={handleSetContaBanc}
            />
          </Row>
          <Row>
            <TextInput
              label="Observação"
              md={12}
              value={observ}
              onChange={(_, v) => setObserv(v)}
              maxLength={150}
            />
          </Row>
          <Row
            className="mb-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <TextInput
              label="Nº Comprovante"
              md={5}
              value={nroComprovante}
              onChange={(_, v) => setNroComprovante(v)}
              maxLength={150}
            />
            <DatePicker
              label="Prev. Recebimento"
              md={5}
              value={dataPrevRec}
              onChange={(v) =>
                setDataPrevRec(moment.isMoment(v) ? v.toDate() : v)
              }
            />
          </Row>
        </>
      )}
    </ModalBase>
  );
};
