import React, { useRef } from "react";
import { useState } from "react";
import { formatNumber, roundFloat } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  Divider,
  FormButton,
  ModalBase,
  NumberInput,
  RadioGroup,
  Table,
  TableDelete,
} from "../../../../../components";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";
import { showWarning } from "../../../../../components/AlertaModal";
import { v4 as uuidv4 } from "uuid";
import OrdemProducaoService from "../../../../../services/industria/OrdemProducaoService";
import { RadioItem } from "../../../../../components/RadioGroup";
import { TIPOS_INSUMO_FICHA_TECNICA } from "../../ficha_tecnica/flags";

export const FinalizarOrdemProducaoModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [qtdProduzida, setQtdProduzida] = useState(null);
  const [perdas, setPerdas] = useState([]);
  const [tipoInsumo, setTipoInsumo] = useState(
    TIPOS_INSUMO_FICHA_TECNICA.INSUMO
  );
  const [idProdutoInsumo, setIdProdutoInsumo] = useState(null);
  const [nomeProdutoInsumo, setNomeProdutoInsumo] = useState("");
  const [unidadeInsumo, setUnidadeInsumo] = useState("");
  const [quantidadeInsumo, setQuantidadeInsumo] = useState(0);
  const [vlrCustoUnitInsumo, setVlrCustoUnitInsumo] = useState(0);
  const [loading, setLoading] = useState(false);

  const pesqProdInsumoRef = useRef();
  const prodIntermediarioRef = useRef();
  const quantidadeInsumoRef = useRef();

  const limparDadosInsumo = () => {
    setIdProdutoInsumo(null);
    setNomeProdutoInsumo("");
    setUnidadeInsumo("");
    setQuantidadeInsumo(0);
    setVlrCustoUnitInsumo(0);
  };

  const limparDados = () => {
    setQtdProduzida(null);
    setPerdas([]);
    limparDadosInsumo();
  };

  const handleSetTipoInsumo = (v) => {
    setTipoInsumo(v);
    limparDadosInsumo();
  };

  const verificarCustoUnitInsumo = async (idProdInsumo) => {
    const params = {
      tipo_insumo: tipoInsumo,
      id_produto_insumo: idProdInsumo,
    };
    const [ok, ret] = await OrdemProducaoService.buscarCustoInsumoPerda(
      selected,
      params
    );
    setVlrCustoUnitInsumo(ok ? parseFloat(ret.vlr_custo_unit) : 0);
    return ok;
  };

  const handleSelectProdIntermediario = async (v, s) => {
    if (v) {
      if (await verificarCustoUnitInsumo(v)) {
        setIdProdutoInsumo(v);
        setNomeProdutoInsumo(s?.nome_produto);
        setUnidadeInsumo(s?.unidade);
      } else {
        limparDadosInsumo();
        if (prodIntermediarioRef.current) {
          prodIntermediarioRef.current.clearValue();
        }
      }
    } else {
      limparDadosInsumo();
    }
  };

  const handleSelectProdutoInsumo = async ({
    idProduto: id,
    nomeProduto: nome,
    referencia,
    unidade,
    custoUe,
  }) => {
    if (idProdutoInsumo !== id) {
      if (await verificarCustoUnitInsumo(id)) {
        setIdProdutoInsumo(id);
        setNomeProdutoInsumo(nome);
        setUnidadeInsumo(unidade);
        setVlrCustoUnitInsumo(custoUe);
        if (pesqProdInsumoRef.current) {
          pesqProdInsumoRef.current.setDescricao(nome);
          pesqProdInsumoRef.current.setId(String(id));
          pesqProdInsumoRef.current.setReferencia(String(referencia));
        }
      }
    } else {
      limparDadosInsumo();
      if (pesqProdInsumoRef.current) {
        pesqProdInsumoRef.current.clear();
      }
    }
  };

  const selectNextFieldProdutoInsumo = () =>
    quantidadeInsumoRef.current && quantidadeInsumoRef.current.focus();

  const handleAddInsumo = () => {
    if ([0, null, undefined].includes(idProdutoInsumo)) {
      showWarning(
        "Por favor, selecione um Produto",
        null,
        null,
        "input-produto"
      );
      return;
    }

    if (perdas.findIndex((e) => e.id_produto === idProdutoInsumo) > -1) {
      showWarning(
        "O Produto informado já existe entre os insumos.",
        null,
        null,
        "input-produto"
      );
      return;
    }

    const payload = {
      uuid: uuidv4(),
      tipo: tipoInsumo,
      desc_tipo:
        tipoInsumo === TIPOS_INSUMO_FICHA_TECNICA.INSUMO
          ? "Insumo"
          : "Produto Intermediário",
      id_produto_insumo: idProdutoInsumo,
      nome_produto_insumo: nomeProdutoInsumo,
      unidade: unidadeInsumo,
      qtd_perda: quantidadeInsumo,
      vlr_custo_unit: vlrCustoUnitInsumo,
    };
    setPerdas([...perdas, payload]);
    limparDadosInsumo();
    if (pesqProdInsumoRef.current) {
      pesqProdInsumoRef.current.clear();
      pesqProdInsumoRef.current.focus();
    }
  };

  const handleDeleteInsumo = (uuid) =>
    setPerdas(perdas.filter((e) => e.uuid !== uuid));

  const handleAlterarInsumo = (coluna, novoValor, row) => {
    const casasDecTest = coluna === "qtd_perda" ? 3 : 2;
    const valorAntesTest = roundFloat(parseFloat(row[coluna]), casasDecTest);
    const novoValorTest = roundFloat(parseFloat(novoValor), casasDecTest);
    if (valorAntesTest === novoValorTest) {
      return;
    }

    if (coluna === "qtd_perda") {
      row.qtd_perda = parseFloat(novoValor);
    }

    setPerdas(perdas.map((e) => (e.uuid === row.uuid ? row : e)));
  };

  const handleSubmit = async () => {
    const payload = {
      id_ordem_producao: selected,
      qtd_produzida: qtdProduzida,
      perdas: perdas.map((e) => ({
        tipo: e.tipo,
        id_produto_insumo: e.id_produto_insumo,
        nome_produto_insumo: e.nome_produto_insumo,
        qtd_perda: e.qtd_perda,
        vlr_custo_unit: e.vlr_custo_unit,
      })),
    };
    setLoading(true);
    const [ok] = await OrdemProducaoService.finalizar(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  const columns = [
    {
      dataField: "desc_tipo",
      text: "Tipo",
      align: "center",
    },
    { dataField: "id_produto_insumo", text: "Produto", align: "center" },
    { dataField: "nome_produto_insumo", text: "Nome", align: "left" },
    {
      dataField: "qtd_perda",
      text: "Qtd Perda",
      align: "center",
      formatter: (c) => formatNumber(c, true, 3, true),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
      editable: true,
      editorType: "number",
      onChange: handleAlterarInsumo,
      decimalPlaces: 3,
      alwaysShowEditor: true,
    },
    { dataField: "unidade", text: "UN", align: "center" },
    {
      dataField: "del",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDeleteInsumo(row.uuid)} />
      ),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      title="Finalizar Ordem de Produção"
      size="lg"
      onClosed={limparDados}
      number="0095_1"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <Row className="mb-2">
        <NumberInput
          md={3}
          label="Qtd. Produzida"
          value={qtdProduzida}
          onChange={setQtdProduzida}
          decimalPlaces={3}
        />
      </Row>
      <Divider>Perdas de Insumos</Divider>
      <Row>
        <RadioGroup
          label="Tipo"
          value={tipoInsumo}
          onChange={handleSetTipoInsumo}
        >
          <RadioItem label="Insumo" value={TIPOS_INSUMO_FICHA_TECNICA.INSUMO} />
          <RadioItem
            label="Prod. Intermediário"
            value={TIPOS_INSUMO_FICHA_TECNICA.PROD_INTERMEDIARIO}
          />
        </RadioGroup>
        {tipoInsumo === TIPOS_INSUMO_FICHA_TECNICA.INSUMO ? (
          <PesqProduto
            mdIdent={3}
            mdDesc={7}
            md={8}
            onConfirm={handleSelectProdutoInsumo}
            ref={pesqProdInsumoRef}
            selectNextField={selectNextFieldProdutoInsumo}
            onChangeDescricao={setNomeProdutoInsumo}
            divClassName="px-0"
            name={"input-produto"}
            mostrarAux={false}
          />
        ) : (
          <AsyncComboBox
            md={8}
            label="Produto Intermediário"
            concatModelName="produto_intermediario"
            defaultValue={idProdutoInsumo}
            onChange={(s) => handleSelectProdIntermediario(s?.value, s)}
            className="px-0"
            inputName={"input-produto"}
            ref={prodIntermediarioRef}
          />
        )}
      </Row>
      <Row className="mb-2">
        <NumberInput
          md={3}
          label="Custo Unitário"
          value={vlrCustoUnitInsumo}
          disabled
        />
        <NumberInput
          md={3}
          label="Quantidade"
          value={quantidadeInsumo}
          onChange={setQuantidadeInsumo}
          ref={quantidadeInsumoRef}
          decimalPlaces={3}
        />
        <FormButton md="auto" color="info" onClick={handleAddInsumo}>
          Incluir Perda
        </FormButton>
      </Row>
      <Table
        data={perdas}
        columns={columns}
        paginated={false}
        pageSize={8}
        keyField="uuid"
      />
    </ModalBase>
  );
};
