import React, { useState } from "react";
import {
  Divider,
  FixedField,
  ModalBase,
  ModalDetalhesIcone,
  MoreDropdown,
  Table,
} from "../../../../../components";
import CredRotativoService from "../../../../../services/financeiro/CredRotativoService";
import { formatarValor, formatValueFromAPI } from "../../../../../coreUtils";
import { DropdownItem } from "reactstrap";
import { DetalhesLoteCredRotativoModal } from "./DetalhesLoteCredRotativoModal";
import { SenhaModal } from "../../../../../components/SenhaModal";

export const DetalhesContaModal = ({
  isOpen,
  toggle,
  selected,
  nomeCliente,
}) => {
  const [lotes, setLotes] = useState([]);
  const [movs, setMovs] = useState([]);
  const [idEstornar, setIdEstornar] = useState(null);
  const [senhaEstornarOpen, setSenhaEstornarOpen] = useState(false);

  const [loteDetalhes, setLoteDetalhes] = useState(null);
  const [detalhesOpen, setDetalhesOpen] = useState(false);

  const toggleDetalhes = (idLoteDetalhar) => {
    setLoteDetalhes(idLoteDetalhar);
    setDetalhesOpen(!detalhesOpen);
  };

  const carregarDados = async () => {
    const [ok, ret] =
      await CredRotativoService.gerenciamento.listarDetalhesContas(selected);

    if (ok) {
      setLotes(ret.lotes);
      setMovs(ret.movs);
    }
  };

  const estornarRecebimento = async (senha) => {
    const payload = {
      id_lote: idEstornar,
      senha_estorno: senha,
    };
    const [ok] = await CredRotativoService.estornarRecebimento(payload);
    if (ok) {
      carregarDados();
      setIdEstornar(null);
    }
    return ok;
  };

  const toggleSenhaEstornar = () => setSenhaEstornarOpen(!senhaEstornarOpen);

  const solicSenhaEstornarRecebimento = (id) => {
    setIdEstornar(id);
    setTimeout(() => {
      toggleSenhaEstornar();
    }, 1);
  };

  const columnsLotes = [
    { dataField: "id_lote", text: "#", align: "center", headerAlign: "center" },
    {
      dataField: "nro_movs_lote",
      text: "Movimentos",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "data_hora_lote",
      text: "Data/Hora",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_desc_lote",
      text: "Desconto",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "valor_lote",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "recebido_caixa",
      text: "Rec. Caixa",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "det",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <ModalDetalhesIcone toggle={() => toggleDetalhes(row.id_lote)} />
      ),
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem
            onClick={() => solicSenhaEstornarRecebimento(row.id_lote)}
          >
            Estornar Fatura
          </DropdownItem>
        </MoreDropdown>
      ),
    },
  ];

  const columnsMovs = [
    {
      dataField: "natureza",
      text: "Natureza",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "desc_origem",
      text: "Origem",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "id_origem",
      text: "# Origem",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "historico",
      text: "Histórico",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "data_mov",
      text: "Data",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "id_caixa_loja",
      text: "Caixa",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "saldo",
      text: "Saldo",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
  ];

  return (
    <>
      <DetalhesLoteCredRotativoModal
        isOpen={detalhesOpen}
        toggle={toggleDetalhes}
        lote={loteDetalhes}
      />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Detalhes da Conta de Crédito Rotativo"
        number="0068_5"
        onBeforeOpen={carregarDados}
      >
        <FixedField
          horizontal
          divClassName="no-gutters"
          label="Cliente"
          value={formatValueFromAPI(nomeCliente, selected)}
        />
        <Divider>Lotes do Rotativo</Divider>
        <Table
          data={lotes}
          columns={columnsLotes}
          paginated={false}
          pageSize={7}
        />
        <Divider>Movimentações do Crédito do Cliente</Divider>
        <Table
          data={movs}
          columns={columnsMovs}
          paginated={false}
          pageSize={7}
        />
      </ModalBase>
      <SenhaModal
        isOpen={senhaEstornarOpen}
        toggle={toggleSenhaEstornar}
        title="Estorno de Recebimento"
        onConfirm={estornarRecebimento}
      />
    </>
  );
};
