import React, { useState } from "react";
import { ModalBase } from "../../../../../components";
import axios from "axios";
import Cookies from "js-cookie";
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import { Eye, EyeOff } from "react-feather";
import { kClientId, kClientSecret } from "../../../../../api";
import { isDevEnv } from "../../../../../coreUtils";
import settings from "../../../../../settings";
import { login } from "../../../../../auth/session";
import { showError, showWarning } from "../../../../../components/AlertaModal";

export const TrocarOperadorModal = ({ isOpen, toggle, trocarOperador }) => {
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");

  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const limparDados = () => {
    setUsuario("");
    setSenha("");
  };

  const onClosed = () => {
    limparDados();
  };

  const prepareNickname = (user) => {
    user = user.split("@")[0];
    const nickname = user.charAt(0).toUpperCase() + user.slice(1);
    return nickname;
  };

  const fazerLogin = async () => {
    // É gerado um URLSearchParams pois o content-type do POST precisa ser
    // x-www-form-urlencoded
    const data = new URLSearchParams({
      grant_type: "password",
      username: usuario,
      password: senha,
      application: "WEB",
    });

    Cookies.remove("access_token");

    return await axios
      .post(`${settings.AUTENTICADOR}/o/gerar_token/`, data, {
        timeout: 5000,
        auth: {
          username: kClientId,
          password: kClientSecret,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //Verifica se está no ambiente de desenvolvimento/teste
          if (isDevEnv()) {
            login(
              response.data.access_token,
              response.data.refresh_token,
              settings.API_ADDRESS
            );
          } else {
            login(
              response.data.access_token,
              response.data.refresh_token,
              response.data.acessos.api_address
            );
          }
          Cookies.set("nickname", prepareNickname(usuario));
          return true;
        } else {
          showWarning(response.data.msg);
          return false;
        }
      })
      .catch((err) => {
        if (err.response.data?.msg?.msg) {
          showError(err.response.data.msg.msg);
        } else {
          showError(
            err.response?.data?.error === "invalid_grant"
              ? "Usuário ou senha incorretos."
              : err.response?.data?.msg ??
                  "Falha ao tentar se conectar com o serviço de autenticação."
          );
        }

        return false;
      });
  };

  const handleClickLogin = async () => {
    const siglaEmpr = localStorage.getItem("sigla_empresa");
    if (!usuario.toLowerCase().includes(siglaEmpr)) {
      showWarning(
        "O usuário informado não parece ser da mesma empresa do usuário atual. Revise o campo usuário e tente novamente"
      );
      return false;
    }

    if (usuario && usuario.length >= 5 && senha) {
      const ok = await axios
        .get(
          `${settings.AUTENTICADOR}/verifica_redefine_senha/?nickname=${usuario}`,
          {
            timeout: 5000,
            auth: {
              username: kClientId,
              password: kClientSecret,
            },
            validateStatus: false,
          }
        )
        .then((response) => {
          if (response.status === 200 && response.data.resetar_senha) {
            showWarning(
              "Esse usuário deve redefinir sua senha antes de acessar o sistema"
            );
            return false;
          }

          return true;
        })
        .catch(() => {
          showError(
            "Falha ao tentar se conectar com o serviço de autenticação."
          );
          return false;
        });
      if (ok) {
        return await fazerLogin();
      }
    } else {
      showWarning("Revise o formulário e tente novamente.");
    }

    return false;
  };

  const onConfirm = async () => {
    setLoading(true);
    try {
      if (await handleClickLogin(usuario, senha)) {
        toggle();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Trocar Operador"
      number="0030_3"
      onClosed={onClosed}
      onConfirm={onConfirm}
      loadingConfirm={loading}
    >
      <FormGroup>
        <Label>Usuário</Label>
        <Input
          type="email"
          name="user"
          placeholder="usuario@empresa"
          value={usuario}
          onChange={(e) => setUsuario(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label>Senha</Label>
        <InputGroup>
          <Input
            type={passwordVisible ? "text" : "password"}
            name="password"
            placeholder="minha senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            required
          />
          <InputGroupAddon addonType="append">
            <InputGroupText
              style={{ cursor: "pointer", backgroundColor: "white" }}
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? <Eye size={15} /> : <EyeOff size={15} />}
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
    </ModalBase>
  );
};
