import React, {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  useCallback,
} from "react";
import {
  AsyncComboBox,
  ComboBox,
  ModalBase,
} from "../../../../../../components";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import { apiGet } from "../../../../../../api";
import { useDispatch } from "react-redux";
import { addRecebCartao } from "../store/moedas_rec_slice";
import { formatNumber } from "../../../../../../coreUtils";
import { toastr } from "react-redux-toastr";

const rowStyle = { marginBottom: "0.5rem" };

export const RecCartaoModal = ({
  vlrCartao,
  isOpen,
  toggle,
  toggleMultiplos,
}) => {
  const [idMeioPagto, setIdMeioPagto] = useState(null);
  const [meioPagtoTemTef, setMeioPagtoTemTef] = useState(false);
  const [idBandeiraCartao, setIdBandeiraCartao] = useState(null);
  const [tipoCartao, setTipoCartao] = useState("C");
  const [qtdParcelas, setQtdParcelas] = useState(1);
  const [parcelasDisponiveis, setParcelasDisponiveis] = useState([]);

  const [temTEF, setTemTEF] = useState(false);

  const radioGroupRef = useRef();
  const comboBoxParcelasRef = useRef();

  const dispatch = useDispatch();

  const limparDados = () => {
    setQtdParcelas(1);
    setTipoCartao("C");
  };

  const carregarParametros = async () => {
    const ret = await apiGet("/tela/caixa_loja/receb_cartao/", {});

    setTemTEF(ret?.tem_tef ?? false);
    if (ret?.tem_tef === true) {
      setIdMeioPagto(ret?.id_meio_pagto_pad_tef);
      setMeioPagtoTemTef(ret?.meio_pagto_tem_tef ?? false);
    }
  };

  const handleSetIdMeioPagto = (id, s) => {
    if (id === idMeioPagto) return;
    setIdMeioPagto(id);
    setMeioPagtoTemTef(s?.tem_tef ?? false);
    if (s?.tem_tef) setIdBandeiraCartao(null);
  };

  const gerarParcelas = useCallback(() => {
    return Array.from({ length: 24 }, (_, i) => {
      if (i === 0) {
        return {
          label: `1 Parcela de R$ ${formatNumber(vlrCartao)}`,
          value: 1,
        };
      }

      return {
        label: `${i + 1} Parcelas de R$ ${formatNumber(vlrCartao / (i + 1))}`,
        value: i + 1,
      };
    });
  }, [vlrCartao]);

  useEffect(() => {
    setParcelasDisponiveis(gerarParcelas());
  }, [gerarParcelas]);

  const onConfirm = () => {
    if ([0, null, undefined].includes(idMeioPagto)) {
      toastr.warning("Atenção", "Por favor, informe o Meio de Pagamento");
      return false;
    }

    const payloadCartoes = [
      {
        id_meio_pagto: idMeioPagto ?? 0,
        id_adm: idBandeiraCartao ?? 0,
        qtd_parcelas: qtdParcelas,
        valor: parseFloat(vlrCartao),
        tipo: tipoCartao,
      },
    ];

    dispatch(addRecebCartao({ valor: vlrCartao, dados_rec: payloadCartoes }));
    toggle();
  };

  useLayoutEffect(() => {
    if (radioGroupRef.current) {
      radioGroupRef.current.focus();
    }
  }, []);

  useLayoutEffect(() => {
    if (["C", "R"].includes(tipoCartao)) {
      if (comboBoxParcelasRef.current) comboBoxParcelasRef.current.setFocus();
    }
  }, [tipoCartao]);

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Recebimento em Cartão"
      number="0063_11"
      onBeforeOpen={carregarParametros}
      onConfirm={onConfirm}
      onClosed={limparDados}
      bodyStyle={{ paddingRight: "0" }}
    >
      <Row
        style={{
          ...rowStyle,
          paddingRight: "15px",
          paddingLeft: "15px",
        }}
      >
        <span style={{ fontSize: "1rem" }}>Selecione a Operação</span>
      </Row>
      <FormGroup
        onChange={({ target }) => setTipoCartao(target.value)}
        ref={radioGroupRef}
        onKeyPress={(event) => {
          event.stopPropagation();
          event.preventDefault();
          if (event.key === "1") {
            setTipoCartao("C");
          } else if (event.key === "2") {
            setTipoCartao("D");
          } else if (event.key === "3") {
            setTipoCartao("V");
          } else if (event.key === "4") {
            setTipoCartao("A");
          } else if (event.key === "5") {
            setTipoCartao("R");
          }
        }}
      >
        <Row style={rowStyle}>
          <Col md={null}>
            <FormGroup
              check
              size="lg"
              onFocus={() => radioGroupRef.current?.focus()}
            >
              <Label check size="lg" style={{ fontWeight: "bolder" }}>
                <Input
                  type="radio"
                  inline
                  value="C"
                  checked={tipoCartao === "C"}
                />
                1 - Crédito
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col md={null}>
            <FormGroup
              check
              size="lg"
              onFocus={() => radioGroupRef.current?.focus()}
            >
              <Label check size="lg" style={{ fontWeight: "bolder" }}>
                <Input
                  type="radio"
                  inline
                  value="D"
                  checked={tipoCartao === "D"}
                />
                2 - Débito
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col md={null}>
            <FormGroup
              check
              size="lg"
              onFocus={() => radioGroupRef.current?.focus()}
            >
              <Label check size="lg" style={{ fontWeight: "bolder" }}>
                <Input
                  type="radio"
                  inline
                  value="V"
                  checked={tipoCartao === "V"}
                />
                3 - Voucher
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col md={null}>
            <FormGroup
              check
              size="lg"
              onFocus={() => radioGroupRef.current?.focus()}
            >
              <Label check size="lg" style={{ fontWeight: "bolder" }}>
                <Input
                  type="radio"
                  inline
                  value="A"
                  checked={tipoCartao === "A"}
                />
                4 - Débito Pré-Datado
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col md={null}>
            <FormGroup
              check
              size="lg"
              onFocus={() => radioGroupRef.current?.focus()}
            >
              <Label check size="lg" style={{ fontWeight: "bolder" }}>
                <Input
                  type="radio"
                  inline
                  value="R"
                  checked={tipoCartao === "R"}
                />
                5 - Débito Parcelado
              </Label>
            </FormGroup>
          </Col>
        </Row>
      </FormGroup>
      <Row>
        <ComboBox
          md={null}
          options={parcelasDisponiveis}
          label="Parcelas"
          ref={comboBoxParcelasRef}
          isDisabled={["V", "A", "D"].includes(tipoCartao)}
          defaultValue={qtdParcelas}
          onChange={(s) => setQtdParcelas(s?.value)}
        />
      </Row>
      <Row style={{ ...rowStyle }}>
        <AsyncComboBox
          md={12}
          label="Meio de Pagamento"
          isConcatField
          concatModelName="meio_pagamento"
          defaultOptions
          isSearchable
          onChange={(s) => handleSetIdMeioPagto(s?.value, s)}
          defaultValue={idMeioPagto}
        />
      </Row>
      {(!temTEF || !meioPagtoTemTef) && (
        <Row style={{ ...rowStyle }}>
          <AsyncComboBox
            md={12}
            label="Bandeira do Cartão"
            isConcatField
            concatModelName="administradora_cartao"
            defaultOptions
            isSearchable
            onChange={(s) => {
              setIdBandeiraCartao(s?.value);
            }}
            defaultValue={idBandeiraCartao}
          />
        </Row>
      )}
      <Row style={{ paddingLeft: "15px" }}>
        <span
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => {
            toggle();
            toggleMultiplos();
          }}
        >
          Mais de um Cartão
        </span>
      </Row>
    </ModalBase>
  );
};
