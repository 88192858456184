import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  CadastroKitModal,
  CadastroLigacaoProdFornecModal,
} from "./components/CadastroKitModal";
import { KitGrid, LigacaoProdFornecGrid } from "./components/KitGrid";
import { apiGetV2 } from "../../../../apiV2";
import {
  AsyncComboBox,
  BotaoPesquisar,
  PageContainer,
  SearchInput,
} from "../../../../components";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { MODAL_ACTIONS } from "../../../../coreUtils";

export const modalTitleKitProdutos = "Kit de Produtos";
export const routesBaseKitsProdutos = "/cadastro/kit";

export const Kits = () => {
  const [idProduto, setIdProduto] = useState(null);
  const [pista, setPista] = useState(null);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async () => {
    const [ok, ret] = await apiGetV2(`${routesBaseKitsProdutos}/listar/`, {
      pista: pista,
      id_produto: idProduto,
    });
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Kits de Produtos" number="0139" canGoBack>
      <Card body>
        <Row>
          <SearchInput
            md={5}
            onChange={setPista}
            value={pista}
            loading={loading}
            placeholder="Pesquise pelo nome do kit"
          />
          <AsyncComboBox
            md={3}
            label="Produto"
            concatModelName="produto"
            defaultValue={idProduto}
            onChange={(s) => setIdProduto(s?.value)}
            hideShortcut
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroKitModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleKitProdutos}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0139_2"
            selected={selected}
            routeBase={routesBaseKitsProdutos}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <KitGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
