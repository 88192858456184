import React, { useState } from "react";
import {
  IntegerFormInput,
  FormButton,
  ModalBase,
} from "../../../../../../components";
import { Row } from "reactstrap";
import CadastroProdutosService from "../../../../../../services/cadastro/CadastroProdutosService";
import { showWarning } from "../../../../../../components/AlertaModal";

export const AlterarCodigoModal = ({ selected, setSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idNovo, setIdNovo] = useState(null);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idNovo)) {
      showWarning(
        "Por favor, informe o Novo Código do Produto",
        null,
        null,
        "input-cod-novo"
      );
      return false;
    }

    const payload = {
      id_atual: selected,
      id_novo: idNovo,
    };
    setLoading(true);
    const [ok] = await CadastroProdutosService.alterarId(payload);
    if (ok) {
      setSelected(idNovo);
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        md="auto"
        padded={false}
        onClick={toggle}
        disabled={[0, null, undefined].includes(selected)}
        color="warning"
      >
        Alterar Código do Produto
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        onClosed={() => setIdNovo(null)}
        size="md"
        title="Alterar Código do Produto"
        number="0029_11"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <p style={{ fontSize: "0.85rem" }}>
          Ao confirmar a alteração do código do produto, o sistema irá alterá-lo
          em todas as movimentações de:
        </p>
        <p style={{ fontSize: "0.85rem" }}>
          Cadastro de Produto, Pedidos Internos e Externos, Orçamentos, Vendas,
          Ordens de Serviço, Transações de Estoque, Regras Tributárias, Notas
          Fiscais de Entrada, Fotos do Produto, Listas de Preço, Vendas a Pronta
          Entrega.
        </p>
        <p style={{ fontSize: "0.85rem" }}>
          O Sistema não alterará o código do produto nas notas fiscais de saída
          já emitidas no SEFAZ.
        </p>
        <p
          style={{
            fontSize: "0.85rem",
            fontWeight: "bold",
            color: "red",
            textTransform: "uppercase",
          }}
        >
          Esta ação não é recomendada e deve ser analisada criteriosamente antes
          de ser realizada.
        </p>
        <Row>
          <IntegerFormInput
            md={5}
            label="Código Atual"
            defaultValue={selected}
            disabled
          />
          <IntegerFormInput
            md={5}
            label="Novo Código"
            defaultValue={idNovo}
            onChange={setIdNovo}
            name={'input-cod-novo'}
          />
        </Row>
      </ModalBase>
    </>
  );
};
