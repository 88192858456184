import React from "react";
import { Divider, Table } from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

const numberFormatter = (v, exportacao) =>
  exportacao ? parseFloat(v) : formatNumber(v, true, 2);

export const columnsRotativoRecebimentosMaos = (exportacao) => [
  { dataField: "id_cred_rota_lote", text: "# Lote", align: "center" },
  {
    dataField: "nome_cliente",
    text: "Cliente",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
  },
  { dataField: "rota", text: "Rota", align: "left" },
  { dataField: "recebimento", text: "Recebimento", align: "center" },
  {
    dataField: "forma_recebimento",
    text: "Forma de Recebimento",
    align: "left",
  },
  {
    dataField: "valor",
    text: "Vlr. Recebido",
    align: "right",
    formatter: (c) => numberFormatter(c, exportacao),
  },
];

export const RotativosRecebimentosMaosGrid = ({ dados }) => {
  return (
    <>
      <Divider>Rotativos</Divider>
      <Table
        data={dados}
        columns={columnsRotativoRecebimentosMaos(false)}
        growIntoPageSize
        pageSize={10}
        paginated={false}
      />
    </>
  );
};
