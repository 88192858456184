import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  IntegerFormInput,
  BotaoPesquisar,
  PageContainer,
  FiltroPeriodoDatas,
  ComboBox,
} from "../../../../../components";
import ConferValoresRecMaosService from "../../../../../services/pedidos/ConferValoresRecMaosService";
import { ConferValoresRecMaosGrid } from "./components/ConferValoresRecMaosGrid";
import { ConferirValoresDinheiroModal } from "./components/ConferirValoresDinheiroModal";
import { ConferirValoresChequeModal } from "./components/ConferirValoresChequeModal";

const periodoOptions = [
  { label: "Faturamento", value: "VDA" },
  { label: "Emissão", value: "PED" },
];

export const ConferValoresRecMaos = () => {
  const [idVendedor, setIdVendedor] = useState(null);
  const [idPedido, setIdPedido] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [periodo, setPeriodo] = useState(periodoOptions[0].value);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    const params = {
      id_vendedor: idVendedor,
      id_pedido: idPedido,
      id_cliente: idCliente,
      periodo: periodo,
      data_ini: dataIni,
      data_fim: dataFim,
    };
    setLoading(true);
    const [ok, ret] = await ConferValoresRecMaosService.listar(params);
    setDados(ok ? ret : []);
    setSelected([]);
    setLoading(false);
  };

  const handleOnSelect = (id, isSelect, row) => {
    setSelected(
      isSelect ? [...selected, row] : selected.filter((e) => e.id !== id)
    );
  };

  const handleOnSelectAll = (isSelect) => setSelected(isSelect ? dados : []);

  return (
    <PageContainer
      title="Conferência de Valores Recebidos Em Mãos"
      number="0054"
      canGoBack
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            label="Vendedor"
            concatModelName="colaborador"
            defaultOptions
            defaultValue={idVendedor}
            onChange={(s) => setIdVendedor(s?.value)}
            hideShortcut
          />
          <AsyncComboBox
            md={4}
            label="Cliente"
            concatModelName="cliente"
            defaultOptions
            defaultValue={idCliente}
            onChange={(s) => setIdCliente(s?.value)}
            hideShortcut
          />
          <IntegerFormInput
            md={2}
            label="Nro. Pedido"
            value={idPedido}
            onChange={setIdPedido}
          />
        </Row>
        <Row>
          <ComboBox
            md={2}
            label="Período"
            options={periodoOptions}
            defaultValue={periodo}
            onChange={(s) => setPeriodo(s?.value)}
          />
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <ConferirValoresDinheiroModal
            selected={selected}
            notifyEvent={carregarDados}
          />
          <ConferirValoresChequeModal
            selected={selected}
            notifyEvent={carregarDados}
          />
        </Row>
      </Card>
      <Card body>
        <ConferValoresRecMaosGrid
          data={dados}
          selected={selected.map((e) => e.id)}
          handleOnSelect={handleOnSelect}
          handleOnSelectAll={handleOnSelectAll}
        />
      </Card>
    </PageContainer>
  );
};
