import React, { useState } from "react";
import { Row, Card } from "reactstrap";
import { BotaoPesquisar, SearchInput } from "../../../../components";
import { BotaoConfirmarVoltar } from "../../../../components/BotaoConfirmarVoltar";
import { PageContainer } from "../../../../components/PageContainer";
import {
  defaultDebounceTime,
  MODAL_ACTIONS,
  useDebounce,
} from "../../../../coreUtils";
import { CadastroCondPagModal } from "./components/CadastroCondPagModal";
import { CondPagGrid } from "./components/CondPagGrid";
import { apiGetV2 } from "../../../../apiV2";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";

export const modalTitleCondPag = "Condição de Pagamento";
export const routesBaseCondPag = "/cadastro/cond_pag";

export const CondPag = () => {
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async (p = pista) => {
    const [ok, ret] = await apiGetV2(`${routesBaseCondPag}/listar/`, {
      pista: p,
    });

    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = useDebounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer
      title="Cadastro de Condições de Pagamento"
      number="0051"
      canGoBack
    >
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroCondPagModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleCondPag}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0051_2"
            selected={selected}
            routeBase={routesBaseCondPag}
            notifyEvent={notifyEvent}
          />
          <BotaoConfirmarVoltar
            selected={selected}
            carregarDados={carregarDados}
          />
        </Row>
      </Card>
      <Card body>
        <CondPagGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
