import React, { useRef } from "react";
import { FormButton, ModalBase } from "../../../../../components";
import { Row } from "reactstrap";
import GerenciamentoVendasService from "../../../../../services/vendas/GerenciamentoVendasService";

export const TipoDeValorModal = ({
  toggle,
  isOpen,
  idRegist,
  toggleFinalizar,
  notifyFinalizar,
}) => {
  const refBruto = useRef();
  const refLiquido = useRef();

  const onKeyDown = (e) => {
    if (e.key === "F4") {
      handleVlrBruto();
    }

    if (e.key === "F8") {
      e.preventDefault();
      handleVlrLiqui();
    }
  };
  const handleVlrBruto = async () => {
    await GerenciamentoVendasService.imprimir(idRegist, "bruto");
    toggle(!toggle);
  };
  const handleVlrLiqui = async () => {
    await GerenciamentoVendasService.imprimir(idRegist, "liquido");
    toggle(!toggle);
  };
  
  const onClose = () => {
    toggleFinalizar();
    notifyFinalizar();
  }

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        title={"Selecione o tipo de Impressão Desejada"}
        size="md"
        number="0030_13"
        onClosed={onClose}
        onKeyDown={onKeyDown}
      >
        <Row>
          <FormButton
            onClick={handleVlrBruto}
            divClassName="ml-auto"
            padded={false}
            ref={refBruto}
          >
            F4 - Imprimir Valor Bruto
          </FormButton>
          <FormButton
            onClick={handleVlrLiqui}
            divClassName="mr-auto"
            padded={false}
            color="success"
            ref={refLiquido}
          >
            F8 - Imprimir Valor Líquido
          </FormButton>
        </Row>
      </ModalBase>
    </>
  );
};
