import React, { useEffect, useState } from "react";
import {
  FixedField,
  ModalBase,
  ModalDetalhesIcone,
  Table,
  TableDelete,
} from "../../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../../coreUtils";
import { Row } from "reactstrap";
import RomaneioService from "../../../../../../services/pedidos/RomaneioService";
import { BiUndo } from "react-icons/bi";
import { showConfirmation } from "../../../../../../components/ConfirmationModal";
import { DetalheVendaModal } from "../../../../../vendas/gerenciamento/gerenciamento_vendas/components/DetalheVendaModal";

const headerRowClassName = "mb-1";

export const DetalheRomaneioRealizadoModal = ({
  isOpen,
  toggle,
  idRomaneio,
  notifyEvent,
}) => {
  const [dadosRomaneio, setDadosRomaneio] = useState({});
  const [detalheVendaOpen, setDetalheVendaOpen] = useState(false);
  const [idVendaDetalhe, setIdVendaDetalhe] = useState(null);

  const toggleDetalheVenda = () => setDetalheVendaOpen(!detalheVendaOpen);

  const abrirDetalheVenda = (idVenda) => {
    setIdVendaDetalhe(idVenda);
    toggleDetalheVenda();
  };

  useEffect(() => {
    if (!detalheVendaOpen) {
      setIdVendaDetalhe(null);
    }
  }, [detalheVendaOpen]);

  const _reabrirRomaneio = async (idReabrir) => {
    const [ok, ret] = await RomaneioService.reabrirRomaneio(idReabrir);
    if (ok) {
      if (ret.removeu_cab === true) {
        notifyEvent();
        toggle();
      } else {
        carregarDados();
      }
    }
  };

  const reabrirRomaneio = (idReabrir) =>
    showConfirmation(
      "Você tem certeza que deseja reabrir a venda no romaneio?",
      async () => await _reabrirRomaneio(idReabrir)
    );

  const columns = [
    {
      dataField: "tipo_pedido",
      text: "Origem",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "id_pedido_vda",
      text: "# Pedido",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "id_venda",
      text: "# Venda",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "id_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(row.nome_cliente, c),
    },
    {
      dataField: "nome_cidade",
      text: "Cidade",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "emissao",
      text: "Emissao",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_total",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "detalhes_act",
      isDummyField: true,
      align: "center",
      headerAlign: "center",
      selectOnClick: false,
      formatter: (_, row) => (
        <ModalDetalhesIcone toggle={() => abrirDetalheVenda(row.id_venda)} />
      ),
    },
    {
      dataField: "det",
      dummy: true,
      align: "center",
      formatter: (_, row) => (
        <TableDelete
          onClick={() => reabrirRomaneio(row.id)}
          icon={BiUndo}
          hint="Reabrir Romaneio"
        />
      ),
    },
  ];

  const carregarDados = async () => {
    const [ok, ret] = await RomaneioService.detalheRomaneioRealizado(
      idRomaneio
    );
    if (ok) {
      setDadosRomaneio(ret);
    } else {
      toggle();
    }
  };

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Detalhamento do Romaneio Realizado"
        number="0071_1"
        onBeforeOpen={carregarDados}
        onClosed={() => setDadosRomaneio({})}
        footerActions
        autoFocus
      >
        <Row className={headerRowClassName}>
          <FixedField
            label="Nro. Romaneio"
            value={idRomaneio ?? ""}
            horizontal
            boldValue
          />
          {dadosRomaneio?.nome_transportadora && (
            <FixedField
              label="Transportadora"
              md={7}
              value={formatValueFromAPI(
                dadosRomaneio?.nome_transportadora,
                dadosRomaneio?.id_transportadora
              )}
              horizontal
            />
          )}
          <FixedField
            label="Emissão"
            divClassName="ml-auto"
            value={dadosRomaneio?.data_hora_confirma ?? ""}
            horizontal
          />
        </Row>
        <Table
          data={dadosRomaneio?.movs ?? []}
          columns={columns}
          paginated={false}
          showRegisterCount={false}
          pageSize={12}
        />
      </ModalBase>
      <DetalheVendaModal
        isOpen={detalheVendaOpen}
        toggle={toggleDetalheVenda}
        idVenda={idVendaDetalhe}
      />
    </>
  );
};
