import moment, { isMoment } from "moment";
import React from "react";
import { Card, Col } from "reactstrap";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row } from "reactstrap";
import {
  PageContainer,
  AsyncComboBox,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  MaskedInput,
  NumberInput,
  TextInput,
  DatePicker,
  Divider,
  FixedField,
} from "../../../../../../components";
import {
  dateFromLocaleString,
  formatDateISO,
  formatDateLocal,
  formatValueFromAPI,
  MODAL_ACTIONS,
  roundFloat,
} from "../../../../../../coreUtils";
import CentralPedidosService from "../../../../../../services/pedidos/CentralPedidosService";
import UteisService from "../../../../../../services/uteis/UteisService";
import { ParcelasEntregaPedidoExtGrid } from "./components/ParcelasEntregaPedidoExtGrid";
import { ItensEntregaPedidoExtGrid } from "./components/ItensEntregaPedidoExtGrid";
import NotaFiscalService from "../../../../../../services/docs_eletron/NotaFiscalService";
import { centralPedidosRoute } from "../../../../../../routes/modules/pedidos";
import {
  TIPO_ITEM_BONIFICACAO,
  TIPO_ITEM_VENDA,
} from "../incluir_alterar/components/IncluirAlterarProdutoModal";
import { BoletoService } from "../../../../../../services/bancario/BoletoService";
import { showWarning } from "../../../../../../components/AlertaModal";
import {
  cadastroProdutoRoute,
  incluirAlterarClienteRoute,
} from "../../../../../../routes/modules/cadastro";
import NotaFiscalConsumidorService from "../../../../../../services/docs_eletron/NotaFiscalConsumidorService";
import { GerenciamentoCobrancaBancariaService } from "../../../../../../services/financeiro/GerenciamentoCobrancaBancariaService";
import { showConfirmation } from "../../../../../../components/ConfirmationModal";
import { MASK_PLACA_AUTOMOTIVA } from "../../../../../../flags";

export const EntregarPedidoExt = ({ location }) => {
  const history = useHistory();
  const idPedido = location.state.id;
  const [params, setParams] = useState({});
  const [infoPedido, setInfoPedido] = useState({});
  const [itens, setItens] = useState([]);
  const [idTransportadora, setIdTransportadora] = useState(null);
  const [qtdeVolume, setQtdeVolume] = useState(0);
  const [pesoBruto, setPesoBruto] = useState(0);
  const [pesoLiquido, setPesoLiquido] = useState(0);
  const [placa, setPlaca] = useState(null);
  const [ufVeiculo, setUfVeiculo] = useState(null);
  const [nroVolume, setNroVolume] = useState("");
  const [marca, setMarca] = useState("");
  const [especie, setEspecie] = useState("");
  const [obsEntrega, setObsEntrega] = useState("");
  const [dataPrevEntrega, setDataPrevEntrega] = useState(new Date());
  const [idContaBancFatBoleto, setIdContaBancFatBoleto] = useState(null);
  const [pagamento, setPagamento] = useState([]);
  const [emitirNFeAgora, setEmitirNFeAgora] = useState(false);
  const [mostrarDescontoNFe, setMostrarDescontoNFe] = useState(false);
  const [vlrEntrega, setVlrEntrega] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingParcelas, setLoadingParcelas] = useState(false);

  const infoPagamento = infoPedido?.pagamento ?? {};
  const condPag = infoPagamento?.id_cond_pag;

  const temVendaBonificacao = itens.some(
    (e) =>
      [TIPO_ITEM_BONIFICACAO, TIPO_ITEM_VENDA].includes(e.tipo) &&
      parseFloat(e.qt_entregar) > 0
  );

  const buscarParametros = async () => {
    const [ok, ret] = await CentralPedidosService.buscarParametros();
    if (ok) {
      setParams(ret);
    }
    return ok;
  };

  const carregarDados = async (carregarItens = true) => {
    const [ok, ret] = await CentralPedidosService.listarItensEntrega(idPedido);
    if (ok) {
      setInfoPedido(ret.pedido);
      setIdContaBancFatBoleto(
        ret.pedido?.pagamento?.id_conta_banc_fat_boleto ?? null
      );
      if (carregarItens) {
        setItens(ret.itens.map((e) => ({ ...e, qt_entregar: 0 })));
      }
    }
    return ok;
  };

  const verificaEntregaTotal = () =>
    !itens.some(
      (e) => parseFloat(e.qt_entregar) !== parseFloat(e.qt_saldo_dis)
    );

  const calcularParcelas = async () => {
    setLoadingParcelas(true);

    const aplicarDescSobreTotal = verificaEntregaTotal();

    const vlrDescSobreTotal = parseFloat(infoPedido.vlr_desc_sobre_total ?? 0);

    const vlrParcelar = aplicarDescSobreTotal
      ? vlrEntrega - vlrDescSobreTotal
      : vlrEntrega;

    const payload = {
      id_cond_pag: condPag,
      valor: vlrParcelar,
    };

    const [ok, ret] = await UteisService.calcularParcelasCondPag(payload);

    if (ok) {
      setPagamento(
        ret.map((e, index) => ({
          numero: e.numero,
          parcela: index + 1,
          data_vcto: formatDateLocal(e.data_vcto),
          entrada: e.entrada,
          valor: parseFloat(e.valor),
        }))
      );
    } else {
      setPagamento([]);
    }

    setLoadingParcelas(false);
  };

  const alterarParcela = (coluna, novoValor, row) => {
    if (coluna === "data_vcto") {
      if (isMoment(novoValor)) {
        row.data_vcto = formatDateLocal(novoValor);
      } else {
        return false;
      }
    }

    setPagamento(pagamento.map((e) => (e.parcela === row.parcela ? row : e)));
  };

  const atualizarVlrEntregaPesos = () => {
    let vEntrega = 0;
    let pBruto = 0;
    let pLiquido = 0;

    itens.forEach((item) => {
      const qtEntregar = parseFloat(item.qt_entregar);
      if (item.tipo === "VDA") {
        vEntrega += roundFloat(parseFloat(item.vlr_final) * qtEntregar, 2);
      }
      if (item.tipo !== "TRO") {
        pBruto += parseFloat(item.peso_unit_bruto ?? 0) * qtEntregar;
        pLiquido += parseFloat(item.peso_unit_liquido ?? 0) * qtEntregar;
      }
    });

    setVlrEntrega(roundFloat(vEntrega, 2));
    setPesoBruto(roundFloat(pBruto, 2));
    setPesoLiquido(roundFloat(pLiquido, 2));
  };

  useEffect(atualizarVlrEntregaPesos, [itens]);

  const onChangeQtdEntrega = (coluna, novoValor, row) => {
    row.qt_entregar = parseFloat(novoValor);

    setItens(itens.map((e) => (e.id === row.id ? row : e)));
  };

  const entregarTudo = () => {
    setItens(
      itens.map((e) => ({
        ...e,
        qt_entregar: parseFloat(e.qt_saldo_dis),
      }))
    );
  };

  const zerarEntrega = () => {
    setItens(itens.map((e) => ({ ...e, qt_entregar: 0 })));
  };

  const verificarQtdEntrega = (row) => {
    if (parseFloat(row.qt_entregar) > parseFloat(row.qt_saldo_dis)) {
      row.qt_entregar = parseFloat(row.qt_saldo_dis);

      setItens(itens.map((e) => (e.id === row.id ? row : e)));

      showWarning(
        "A quantidade de entrega não pode ser superior à restante",
        null,
        null,
        "input-qtd-entrega"
      );
    }
  };

  const alterarCadCliente = () => {
    const id = Math.floor(Math.random() * Date.now());
    const cadastro = window.open(incluirAlterarClienteRoute.path);

    cadastro.window.parameters = JSON.stringify({
      id: id,
      action: MODAL_ACTIONS.EDIT,
      selected: infoPedido.id_cliente,
    });

    const func = (event) => {
      if (event.origin !== window.location.origin && !event.data?.selected) {
        return;
      }

      if (event.data.id !== id) {
        return;
      }

      carregarDados(false);

      window.removeEventListener("message", func);
    };

    window.addEventListener("message", func);
  };

  const enviarPayloadEntregar = async (payload) => {
    const [ok, ret] = await CentralPedidosService.entregar(payload);
    if (ok) {
      if (ret?.nfes) {
        for (const nfe of ret.nfes) {
          if (nfe.nfe_emitida) {
            if (nfe.modelo_nf === "NFC") {
              await NotaFiscalConsumidorService.imprimir(nfe.id_nfe);
            } else {
              await NotaFiscalService.imprimir(nfe.id_nfe);
            }
          }
        }
      }

      if (ret?.titulos && ret?.gerar_boletos) {
        for (const titulo of ret.titulos) {
          await BoletoService.imprimirBoleto(titulo.id_titulo);
        }
      }

      history.replace(centralPedidosRoute.path, { refresh: true });
    }
  };

  const handleSubmit = async () => {
    if (loadingSubmit || loadingParcelas) return;

    if (!(dataPrevEntrega instanceof Date)) {
      showWarning(
        "Por favor, revise a Data de Entrega",
        null,
        null,
        "input-data-entrega"
      );
      return false;
    }

    const itensEntrega = itens.filter((item) => item.qt_entregar > 0);

    setLoadingSubmit(true);
    try {
      const temVendaBonifNaEntrega = itensEntrega.some((e) =>
        [TIPO_ITEM_BONIFICACAO, TIPO_ITEM_VENDA].includes(e.tipo)
      );

      const payload = {
        id_pedido: idPedido,
        id_transportadora: idTransportadora,
        obs_entrega: obsEntrega,
        data_prev_entrega: formatDateISO(dataPrevEntrega),
        entregar: itensEntrega.map((e) => ({
          id_item: e.id,
          qtd_entregar: e.qt_entregar,
        })),
        gerar_nfe_agora: emitirNFeAgora && temVendaBonifNaEntrega,
        id_conta_banc_fat_boleto: idContaBancFatBoleto,
        pagamento: pagamento.map((e) => ({
          numero: e.numero,
          parcela: e.parcela,
          data_vcto: formatDateISO(dateFromLocaleString(e.data_vcto)),
          entrada: e.entrada,
          valor: e.valor,
        })),
        placa: placa,
        uf_veiculo: ufVeiculo,
        qtde_volume: qtdeVolume,
        nro_volume: nroVolume,
        marca: marca,
        especie: especie,
        peso_bruto: pesoBruto,
        peso_liquido: pesoLiquido,
        mostrar_desconto_nfe: mostrarDescontoNFe && temVendaBonifNaEntrega,
      };

      if (
        params.gerar_boleto_automatico &&
        infoPagamento.cliente_gera_boleto_auto &&
        infoPagamento?.moeda === "Boleto"
      ) {
        const [ok, ret] =
          await GerenciamentoCobrancaBancariaService.verificaDadosClienteGerarBoleto(
            infoPedido.id_cliente
          );
        if (!ok) {
          // Foi encontrado algum problema
          showConfirmation(
            <h5 className="mb-0">
              {ret.msg}
              {ret.res?.length > 0 && (
                <ul style={{ paddingRight: "2rem", marginTop: "1rem" }}>
                  {ret.res.map((e) => (
                    <li>{e}</li>
                  ))}
                </ul>
              )}
              <b>
                Sem ajustar o cadastro do cliente, para esse pedido, o sistema
                irá gerar o título a receber, mas não será possível imprimir o
                boleto. Tem certeza que deseja continuar?
              </b>
            </h5>,
            () => enviarPayloadEntregar(payload),
            null,
            "md"
          );
          return;
        }
      }

      await enviarPayloadEntregar(payload);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const iniciarTela = async () => {
    if (!(await buscarParametros())) {
      history.goBack();
      return;
    }

    if (!(await carregarDados())) {
      history.goBack();
      return;
    }
    setLoading(false);
  };

  const onActivate = () => {
    iniciarTela();
  };

  useEffect(onActivate, []);

  const recalcularParcelas = () => {
    if (condPag) {
      calcularParcelas();
    }
  };

  useEffect(recalcularParcelas, [condPag, vlrEntrega]);

  const onKeyDown = (e) => {
    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (e.key === "F9") handleSubmit();
    }
  };

  return (
    <PageContainer
      title={`Entrega do Pedido Nº ${idPedido}`}
      number="0034_1"
      canGoBack
      onKeyDown={onKeyDown}
      loading={loading}
    >
      <Card body>
        <Row className="mb-2">
          <FixedField
            label="Cliente"
            value={formatValueFromAPI(
              infoPedido.nome_cliente,
              infoPedido.id_cliente
            )}
          />
          <FixedField label="Fone" value={infoPedido.fone_cliente} />
          <FixedField label="Whatsapp" value={infoPedido.whatsapp_cliente} />
          <FormButton
            md="auto"
            color="info"
            onClick={alterarCadCliente}
            padded={false}
            divStyle={{ paddingTop: "11px" }}
          >
            Cadastro Cliente
          </FormButton>
          <FixedField
            label="Vendedor"
            value={formatValueFromAPI(
              infoPedido.nome_vendedor,
              infoPedido.id_vendedor
            )}
          />
        </Row>
        <Row>
          <FixedField label="Endereço" value={infoPedido.endereco_cliente} />
          <FixedField label="Número" value={infoPedido.numero_cliente} />
          <FixedField label="Bairro" value={infoPedido.bairro_cliente} />
          <FixedField
            label="Cidade"
            value={`${infoPedido.cidade_cliente}-${infoPedido.uf_cidade_cliente}`}
          />
          <FixedField label="Emissão" value={infoPedido.data_emi} />
        </Row>
      </Card>
      <Card body>
        <Divider>Entrega</Divider>
        <Row>
          <AsyncComboBox
            md={4}
            isConcatField
            concatModelName="transportadora"
            defaultOptions
            label="Transportadora"
            isSearchable
            name="transportadora"
            onChange={(s) => setIdTransportadora(s?.value ?? null)}
            defaultValue={idTransportadora}
            autoFocus
          />
          <MaskedInput
            mask={MASK_PLACA_AUTOMOTIVA}
            label="Placa"
            md={2}
            onChange={(e, v) => setPlaca(v)}
            value={placa}
            maskChar={null}
          />
          <MaskedInput
            mask="aa"
            md={1}
            label="UF Veíc."
            onChange={(e, v) => setUfVeiculo(v)}
            value={ufVeiculo}
            maskChar={null}
          />
          <IntegerFormInput
            md={2}
            label="Qtde. Volume"
            onChange={setQtdeVolume}
            defaultValue={qtdeVolume}
            name={"input-qtd-entrega"}
          />
          <TextInput
            label="Número"
            md={2}
            value={nroVolume}
            onChange={(e, v) => setNroVolume(v)}
            maxLength={12}
          />
        </Row>
        <Row className="mb-2">
          <NumberInput
            label="Peso Bruto"
            md={2}
            value={pesoBruto}
            onChange={(v) => setPesoBruto(v)}
            style={{ textAlign: "center" }}
            hint={
              <>
                Peso é calculado automaticamente, necessário informar o peso
                unitário no cadastro do produto.
                <br />
                Sistema desconsidera quantidades de troca neste cálculo.
              </>
            }
          />
          <NumberInput
            label="Peso Líquido"
            md={2}
            value={pesoLiquido}
            onChange={(v) => setPesoLiquido(v)}
            style={{ textAlign: "center" }}
            hint={
              <>
                Peso é calculado automaticamente, necessário informar o peso
                unitário no cadastro do produto.
                <br />
                Sistema desconsidera quantidades de troca neste cálculo.
              </>
            }
          />
          <TextInput
            label="Espécie"
            md={2}
            value={especie}
            onChange={(e, v) => setEspecie(v)}
            maxLength={12}
          />
          <TextInput
            label="Marca"
            md={2}
            value={marca}
            onChange={(e, v) => setMarca(v)}
            maxLength={12}
          />
          <DatePicker
            label="Data de Entrega"
            md="2"
            value={dataPrevEntrega}
            onChange={(v) =>
              setDataPrevEntrega(moment.isMoment(v) ? v.toDate() : v)
            }
            name={"input-data-entrega"}
          />
        </Row>
        <Row className="mb-3">
          <FormButton
            md="auto"
            color="info"
            onClick={() => window.open(cadastroProdutoRoute.path)}
            padded={false}
          >
            Cadastro Produtos
          </FormButton>
          <FormButton
            md="auto"
            color="light"
            onClick={entregarTudo}
            divClassName="ml-auto"
            padded={false}
          >
            Entregar Tudo
          </FormButton>
          <FormButton
            md="auto"
            color="light"
            onClick={zerarEntrega}
            padded={false}
          >
            Zerar Entrega
          </FormButton>
        </Row>
        <ItensEntregaPedidoExtGrid
          data={itens}
          onChangeQtdEntrega={onChangeQtdEntrega}
          verificarQtdEntrega={verificarQtdEntrega}
          identificaProdutoRevenda={params.identifica_produto_revenda}
        />
      </Card>
      <Card body>
        <Divider>Pagamento</Divider>
        <Row>
          <Col md={7}>
            <Row className="mb-2">
              <NumberInput
                md={3}
                label="Valor da Entrega"
                value={
                  verificaEntregaTotal()
                    ? vlrEntrega - (infoPedido.vlr_desc_sobre_total ?? 0)
                    : vlrEntrega
                }
                disabled
              />
              <NumberInput
                md={4}
                label="Desconto Sobre Total"
                hint="Considerado somente em caso de entrega total do pedido"
                value={parseFloat(infoPedido.vlr_desc_sobre_total ?? 0)}
                disabled
              />
            </Row>
            <Row className="mb-2">
              <FixedField
                label="Forma de Pagamento"
                value={infoPagamento?.moeda}
              />
              {infoPagamento?.moeda === "Boleto" && (
                <AsyncComboBox
                  md={6}
                  label="Conta Bancária"
                  concatModelName="conta_banc"
                  defaultOptions
                  onChange={(s) => setIdContaBancFatBoleto(s?.value ?? null)}
                  defaultValue={idContaBancFatBoleto}
                  disabled={
                    params.gerar_boleto_automatico === false ||
                    infoPagamento.cliente_gera_boleto_auto === false
                  }
                  disabledHint={
                    params.gerar_boleto_automatico === false
                      ? "O Sistema está parametrizado para NÃO gerar boletos " +
                        "automaticamente ao Entregar Pedidos Externos"
                      : infoPagamento.cliente_gera_boleto_auto === false
                      ? "O Cliente está marcado para NÃO gerar boletos " +
                        "automaticamente"
                      : undefined
                  }
                />
              )}
            </Row>
            {infoPagamento?.moeda === "Boleto" && (
              <Row>
                <FixedField
                  label="Condição de Pagamento"
                  value={infoPagamento?.nome_cond_pag}
                />
              </Row>
            )}
          </Col>
          {infoPagamento?.moeda === "Boleto" && (
            <Col md={5}>
              <ParcelasEntregaPedidoExtGrid
                parcelas={pagamento}
                alterarParcela={alterarParcela}
              />
            </Col>
          )}
        </Row>
      </Card>
      <Card body>
        <Row>
          <TextInput
            name="obs"
            type="textarea"
            label="Observação"
            md={5}
            value={obsEntrega}
            onChange={(e, v) => setObsEntrega(v)}
          />
          <FormCheckbox
            label="Mostrar Desconto na NF-e"
            value={mostrarDescontoNFe && temVendaBonificacao}
            name="mostrar_desconto_nfe"
            onChange={() => setMostrarDescontoNFe(!mostrarDescontoNFe)}
            disabled={!temVendaBonificacao}
            disabledHint="Nenhum item de venda ou bonificação identificado na entrega. Não é possível gerar NF-e desse pedido"
          />
          <FormCheckbox
            label={`Emitir Nota Fiscal agora ${
              infoPedido.usa_modelo_nfe_cli === true
                ? `(Modelo
                  ${infoPedido.modelo_nfe_cli === "NFE" ? "NF-e" : "NFC-e"})`
                : ""
            }`}
            value={emitirNFeAgora && temVendaBonificacao}
            name="emitir_nfe_agora"
            onChange={() => setEmitirNFeAgora(!emitirNFeAgora)}
            disabled={!temVendaBonificacao}
            disabledHint="Nenhum item de venda ou bonificação identificado na entrega. Não é possível gerar NF-e desse pedido"
          />
          <FormButton
            md="auto"
            color="success"
            divClassName="ml-auto"
            onClick={handleSubmit}
            loading={loadingSubmit}
          >
            Confirmar
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
