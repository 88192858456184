import React from "react";
import { Provider } from "react-redux";
import { PageContainer } from "../../../components/PageContainer";
import { Filtros } from "./components/Filtros";
import { GridVistoriaTecnica } from "./components/GridVistoriaTecnica";
import store from "./store";

export const VistoriaTecnica = () => {
  return (
    <PageContainer title="Vistoria Técnica" number="0027" canGoBack>
      <Provider store={store}>
        <Filtros />
        <GridVistoriaTecnica />
      </Provider>
    </PageContainer>
  );
};
