import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { handleFocus } from "../coreUtils";

const TableTextInput = forwardRef(
  (
    {
      type = "text",
      name,
      onChange,
      value,
      className,
      maxLength,
      tabOrder,
      id,
      inputStyle,
      onBlur,
      autoFocus,
      size,
      forceUpperCase,
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState("");
    const [callOnBlur, setCallOnBlur] = useState(false);
    const inputRef = useRef("");
    const [internalId] = useState(
      id ?? "text-input-" + Math.floor(Math.random() * Date.now())
    );

    const onChangeValue = () => {
      if (value?.toUpperCase() !== internalValue?.toUpperCase()) {
        if (forceUpperCase) {
          setInternalValue(value?.toUpperCase());
        } else {
          setInternalValue(value);
        }
      }
    };
    useEffect(onChangeValue, [value]);

    const handleOnChange = (e) => {
      e.preventDefault();
      let inputValue = e.target.value || "";
      setInternalValue(inputValue);
      if (onChange) {
        setCallOnBlur(true);
        if (forceUpperCase) inputValue = inputValue.toUpperCase();
        onChange(e, inputValue);
      }
    };

    const onKeyDown = (e) => {
      if (type !== "textarea" || (e.key !== "Enter" && e.key !== "ArrowUp")) {
        handleFocus(e);
      }
    };

    const onBlurInternal = (e) => {
      if (onBlur) {
        let inputValue = e.target.value || "";
        if (forceUpperCase) inputValue = inputValue.toUpperCase();
        if (callOnBlur) onBlur(e, inputValue);
      }
      setCallOnBlur(false);
    };

    useImperativeHandle(ref, () => ({
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
      value: forceUpperCase
        ? internalValue?.toUpperCase()?.trim()
        : internalValue?.trim(),
      setValue: (val) => setInternalValue(val),
    }));

    return (
      <input
        id={internalId}
        autoFocus={autoFocus}
        type={type}
        name={name}
        onChange={handleOnChange}
        onBlur={onBlurInternal}
        innerRef={inputRef}
        className={className}
        value={internalValue}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        tabOrder={tabOrder}
        style={{
          textTransform: forceUpperCase ? "uppercase" : "none",
          ...inputStyle,
        }}
        size={size}
      />
    );
  }
);

export default TableTextInput;
