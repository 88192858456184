import React, { useEffect } from "react";
import { DadosCobrancaAPI } from "./DadosCobrancaAPI";
import { Row } from "reactstrap";
import {
  Divider,
  FixedField,
  RadioGroup,
  TextInput,
} from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { RadioItem } from "../../../../../components/RadioGroup";
import { bancosIntegracao } from "./TabDadosGerais";
import {
  setChaveApiPix,
  setHabilitaApiPix,
} from "../store/cadastroContaBancSlice";

const TabConexaoApi = () => {
  const store = useSelector((state) => state.cadastroContaBanc.cobranca);
  const controle = useSelector((state) => state.controleCampos);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!store.habilita_api_pix) {
      dispatch(setChaveApiPix(null));
    }
  }, [store.habilita_api_pix]);

  return (
    <>
      <Row className="mb-2">
        <FixedField
          label="Banco de Integração"
          boldValue
          value={
            bancosIntegracao.find((e) => e.value === store.layout)?.label ||
            "Não Especificado"
          }
        />
      </Row>
      <DadosCobrancaAPI />
      {controle.tem_integra_api_pix && (
        <>
          <Divider>API PIX</Divider>
          <Row>
            <RadioGroup
              label="Habilitar API PIX"
              value={store.habilita_api_pix ? "S" : "N"}
              onChange={(v) => dispatch(setHabilitaApiPix(v === "S"))}
            >
              <RadioItem label="Sim" value="S" />
              <RadioItem label="Não" value="N" />
            </RadioGroup>
            <TextInput
              label="Chave da API PIX"
              md={5}
              onChange={(_, v) => dispatch(setChaveApiPix(v))}
              value={store.chave_api_pix || ""}
              maxLength={50}
              disabled={!store.habilita_api_pix}
            />
          </Row>
        </>
      )}
    </>
  );
};

export default TabConexaoApi;
