import React from "react";
import classNames from "classnames";
import TableNumberInput from "../TableNumberInput";
import TableTextInput from "../TableTextInput";
import TableTextarea from "../TableTextarea";
import { handleTableFocus } from "../../coreUtils";
import { TableDatePicker } from "../TableDatePicker";
import TableMaskedInput from "../TableMaskedInput";
import { TableCombobox } from "../TableCombobox";

const EditableInputWrapper = ({ children, endEdit }) => (
  <div
    onBlur={() => {
      endEdit();
    }}
  >
    {children}
  </div>
);

const TableRow = ({
  tableId,
  row,
  keyField,
  hover,
  striped,
  handleRowClick,
  selectable,
  onSelect,
  multiselect,
  isSelected,
  index,
  checkOnEdit,
  columns,
  endEdit,
  setEditModeInfo,
  onRowEnterPress,
  onRowDoubleClick,
  selectColumnPosition,
  rowStyle,
}) => {
  const _startEdit = (rowKey, dataField) => {
    setEditModeInfo({
      onEdit: true,
      rowKeyField: rowKey,
      dataField: dataField,
    });
  };

  const buildRows = (row) => {
    return columns.map(
      ({
        dataField,
        align,
        formatter,
        editable,
        editorType,
        alwaysShowEditor,
        onChange,
        onBlur,
        decimalPlaces,
        isPercentage,
        borderless,
        hidden,
        colWidth,
        fixedColWidth,
        dummy,
        style,
        selectOnClick = true,
        editorMaxLength,
        editorMask,
        editorMaskChar,
        editorMaskPlaceholder,
        options,
        editorForceUpperCase = true,
        cellContentTag: CellContentTag = "span",
        optionsRenderer,
        editorNumberGroupSeparators = true,
        onClick,
      }) => {
        if (hidden) {
          return <td style={{ display: "hidden" }}></td>;
        }

        let child;
        let inputSize;
        let value = row[dataField];
        if (dummy) {
          value = formatter(undefined, row, index);
        }
        if (formatter) {
          value = formatter(row[dataField], row, index);
        }

        if (value?.length > 50) {
          inputSize = 50;
        } else {
          inputSize = value?.length + 5;
        }
        let isEditable;
        if (typeof editable === "function") {
          isEditable = editable(value, row);
        } else {
          isEditable = editable;
        }

        let internalDecimalPlaces;
        if (typeof decimalPlaces === "function") {
          internalDecimalPlaces = decimalPlaces(row);
        } else {
          internalDecimalPlaces = decimalPlaces;
        }

        let mask;
        if (typeof editorMask === "function") {
          mask = editorMask(row);
        } else {
          mask = editorMask;
        }

        if (isEditable && (checkOnEdit(row, dataField) || alwaysShowEditor)) {
          if (editorType === "number") {
            child = (
              <EditableInputWrapper endEdit={endEdit}>
                <TableNumberInput
                  autoFocus={!alwaysShowEditor}
                  name={dataField}
                  style={{ textAlign: align ?? "right" }}
                  value={parseFloat(row[dataField] ?? "0")}
                  size={value?.length + 1}
                  onChange={(v) => {
                    onChange && onChange(dataField, v, { ...row });
                  }}
                  onBlur={(_, v) => onBlur && onBlur(row, v, index)}
                  decimalPlaces={internalDecimalPlaces}
                  isPercentage={isPercentage}
                  disableGroupSeparators={!editorNumberGroupSeparators}
                />
              </EditableInputWrapper>
            );
          } else if (editorType === "textarea") {
            child = (
              <EditableInputWrapper endEdit={endEdit}>
                <TableTextarea
                  autoFocus={!alwaysShowEditor}
                  className={`table-textarea ${
                    borderless ? "table-input--borderless" : ""
                  }`}
                  name={dataField}
                  value={value}
                  rows={Math.ceil((value?.length ?? 1) / inputSize)}
                  type="textarea"
                  onBlur={(_, v) => onBlur && onBlur(row, v, index)}
                  onChange={(_, value) =>
                    onChange && onChange(dataField, value, { ...row })
                  }
                  forceUpperCase={editorForceUpperCase}
                />
              </EditableInputWrapper>
            );
          } else if (editorType === "date") {
            child = (
              <div>
                <TableDatePicker
                  autoFocus={!alwaysShowEditor}
                  name={dataField}
                  value={row[dataField]}
                  className={`${borderless ? "table-input--borderless" : ""}`}
                  onChange={(v) => {
                    onChange && onChange(dataField, v, { ...row });
                  }}
                  onBlur={(_, v) => {
                    if (onBlur) {
                      onBlur(row, v, index);
                    }
                  }}
                  endEdit={endEdit}
                />
              </div>
            );
          } else if (editorType === "masked") {
            child = (
              <EditableInputWrapper endEdit={endEdit}>
                <TableMaskedInput
                  autoFocus={!alwaysShowEditor}
                  className={`table-input ${
                    borderless ? "table-input--borderless" : ""
                  }`}
                  name={dataField}
                  value={value}
                  size={inputSize}
                  rows={Math.ceil((value?.length ?? 1) / inputSize)}
                  type="text"
                  onBlur={(e, v) => onBlur && onBlur(row, v, index)}
                  onChange={(_, value) => {
                    onChange && onChange(dataField, value, { ...row });
                  }}
                  mask={mask}
                  maskChar={editorMaskChar}
                  maskPlaceholder={editorMaskPlaceholder}
                  forceUpperCase={editorForceUpperCase}
                  inputStyle={{ textAlign: align }}
                />
              </EditableInputWrapper>
            );
          } else if (editorType === "combobox") {
            child = (
              <EditableInputWrapper endEdit={endEdit}>
                <TableCombobox
                  autoFocus={!alwaysShowEditor}
                  className={`table-input ${
                    borderless ? "table-input--borderless" : ""
                  }`}
                  name={dataField}
                  value={row[dataField]}
                  onChange={(v) => {
                    onChange && onChange(dataField, v, { ...row });
                  }}
                  options={options ?? []}
                  optionsRenderer={optionsRenderer}
                />
              </EditableInputWrapper>
            );
          } else {
            child = (
              <EditableInputWrapper endEdit={endEdit}>
                <TableTextInput
                  autoFocus={!alwaysShowEditor}
                  className={`table-input ${
                    borderless ? "table-input--borderless" : ""
                  }`}
                  name={dataField}
                  value={value}
                  size={inputSize}
                  rows={Math.ceil((value?.length ?? 1) / inputSize)}
                  type="text"
                  onBlur={(e, v) => onBlur && onBlur(row, v, index)}
                  onChange={(_, value) => {
                    onChange && onChange(dataField, value, { ...row });
                  }}
                  maxLength={editorMaxLength}
                  forceUpperCase={editorForceUpperCase}
                  inputStyle={{ textAlign: align }}
                />
              </EditableInputWrapper>
            );
          }
        } else {
          if (CellContentTag) {
            child = (
              <CellContentTag
                onClick={() => {
                  if (editable) {
                    _startEdit(row[keyField], dataField);
                  }
                }}
                className="table-editable-cell"
              >
                {value}
              </CellContentTag>
            );
          } else {
            child = value;
          }
        }
        const innerStyle = {};
        if (colWidth) {
          innerStyle["maxWidth"] = colWidth;
          innerStyle["minWidth"] = colWidth;
          if (fixedColWidth) {
            innerStyle["width"] = colWidth;
          }
        } else {
          innerStyle["width"] = "auto";
          innerStyle["whiteSpace"] = "nowrap";
        }
        if (align) {
          innerStyle["textAlign"] = align;
        }

        if (typeof style === "function") {
          style = style(row[dataField], row, index);
        }

        return (
          <td
            style={{ ...innerStyle, ...style }}
            onClick={(e) => {
              if (!selectOnClick) e.stopPropagation();

              if (onClick) onClick(row);
            }}
          >
            {child}
          </td>
        );
      }
    );
  };

  const _buildSelectInput = (row) => {
    return (
      <td
        style={{
          textAlign: "center",
          verticalAlign: "middle",
          paddingTop: "0px",
          paddingBottom: "0px",
        }}
      >
        <input
          type={multiselect === true ? "checkbox" : "radio"}
          name={`table-sel-inpt-${row[keyField]}`}
          id={`table-sel-inpt-${row[keyField]}`}
          checked={isSelected}
          onChange={({ target: { checked } }) =>
            onSelect(row[keyField], checked, row)
          }
          htmlFor={`table-sel-inpt-${row[keyField]}`}
          style={{
            display: "flex",
            margin: "auto",
            width: "16px",
            height: "16px",
          }}
        />
      </td>
    );
  };

  const rowStyleInternal =
    typeof rowStyle === "function" ? rowStyle(row, index) : rowStyle;

  return (
    <tr
      key={row.id}
      id={`${tableId}--row-${index}`}
      tabIndex={index}
      onKeyDown={(e) => {
        if (["ArrowUp", "ArrowDown"].includes(e.key)) {
          e.preventDefault();
          handleTableFocus(tableId, index, e.key);
        } else if (e.key === "Enter" && onRowEnterPress) {
          e.preventDefault();
          onRowEnterPress();
        }
      }}
      className={classNames({
        "table-row-hover": hover === true,
        "table-row-striped": striped === true && index % 2 === 1,
        "table-row-clickable": !multiselect,
      })}
      onClick={() => handleRowClick(row[keyField], row)}
      onDoubleClick={() =>
        onRowDoubleClick && onRowDoubleClick(row[keyField], row, index)
      }
      style={rowStyleInternal}
    >
      {selectable && selectColumnPosition !== "right" && _buildSelectInput(row)}
      {buildRows(row)}
      {selectable && selectColumnPosition === "right" && _buildSelectInput(row)}
    </tr>
  );
};

export default TableRow;
