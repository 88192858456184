import React, { useRef, forwardRef, useImperativeHandle } from "react";
import { MODAL_ACTIONS, formatNumber } from "../../../../coreUtils";
import {
  ComboBox,
  Divider,
  FixedField,
  FormButton,
  Loader,
  PageContainer,
  TextInput,
} from "../../../../components";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import { ItensNFConsGrid } from "./components/ItensNFConsGrid";
import { useEffect } from "react";
import { notaFiscalConsumidorRoute } from "../../../../routes/modules/docsEletron";
import { Provider } from "react-redux";
import { storeCab } from "./store";
import { useDispatch, useSelector } from "react-redux";
import {
  setIdCliente,
  setNomeCliente,
  setCpfCnpjDestinatario,
  setIdNfe,
  init,
  setTotaisNfe,
  setClienteCuringa,
  setFormaPag,
  setDescFormaPag,
  setFinalidadeOperTipoDoc,
} from "./store/cabNFConsSlice";
import NotaFiscalConsumidorService from "../../../../services/docs_eletron/NotaFiscalConsumidorService";
import { useHistory } from "react-router-dom";
import { IncluirItemNFConsForm } from "./components/IncluirItemNFConsForm";
import PesqCliente from "../../../../components/form/pesq_cliente/PesqCliente";
import { apiGetV2 } from "../../../../apiV2";
import { toastr } from "react-redux-toastr";

const formaPagOptions = [
  { label: "Dinheiro", value: "DI" },
  { label: "Cheque", value: "CQ" },
  { label: "Cartão de Crédito", value: "CC" },
  { label: "Cartão de Débito", value: "CD" },
  { label: "Crédito Rotativo", value: "CR" },
  { label: "Crediário", value: "CI" },
  { label: "Vale Alimentação", value: "VA" },
  { label: "Vale Refeição", value: "VR" },
  { label: "Vale Transporte", value: "VP" },
  { label: "Vale Combustível", value: "VC" },
  { label: "Boleto Bancário", value: "BB" },
  { label: "Depósito Bancário", value: "DB" },
  { label: "PIX", value: "PX" },
  { label: "TED", value: "TD" },
  { label: "Transferência Bancária", value: "TB" },
  { label: "Programa de Fidelidade/Cashback", value: "CA" },
  { label: "Sem Pagamento", value: "SE" },
  { label: "Outros", value: "OT" },
];

const IncluirAlterarNFConsContainer = forwardRef(
  ({ selected, action = MODAL_ACTIONS.ADD }, ref) => {
    // Parâmetros
    const [formaSistemaTribut, setFormaSistemaTribut] = useState("CFOP");
    const [regimeTribut, setRegimeTribut] = useState("");
    // Dados da NF
    const history = useHistory();
    const stateCab = useSelector((state) => state.cabNFCons);
    const stateTotaisNfe = useSelector((state) => state.cabNFCons.totaisNfe);
    const dadosCliente = stateCab.dadosDest;
    const dispatch = useDispatch();
    const [itens, setItens] = useState([]);
    // Variáveis de Controle
    const [loading, setLoading] = useState(true);
    const [loadingFinalizar, setLoadingFinalizar] = useState(false);
    const pesqClienteRef = useRef();
    const incluirProdRef = useRef();
    const semItens = itens.length === 0;

    const carregarParametros = async () => {
      const [ok, ret] = await apiGetV2(`/tela/inc_alt_nf_consumidor/`);
      if (ok) {
        setFormaSistemaTribut(ret.forma_sistema_tribut);
        setRegimeTribut(ret.regime_tribut);
        dispatch(setFinalidadeOperTipoDoc(ret.finalidade_oper_tipo_doc_padrao));
        if (
          action === MODAL_ACTIONS.ADD &&
          ![0, null, undefined].includes(ret.id_cliente_curinga)
        ) {
          dispatch(setIdCliente(ret.id_cliente_curinga));
        }
      }
      return ok;
    };

    const carregarDados = async () => {
      const [ok, ret] = await NotaFiscalConsumidorService.inclusao.buscarCab(
        selected
      );
      if (ok) {
        dispatch(setIdNfe(selected));
        dispatch(setIdCliente(ret.cod_cli));
        dispatch(setNomeCliente(ret.nome_cli));
        dispatch(setCpfCnpjDestinatario(ret.cpf_cnpj_cli));
        dispatch(setFormaPag(ret.forma_pag ?? ""));
        if (ret.forma_pag === "OT") {
          dispatch(setDescFormaPag(ret.histor ?? ""));
        }
        await carregarDadosItens(selected);
      }
      return ok;
    };

    const carregarDadosItens = async (id) => {
      if (stateCab.idNfe ?? id) {
        const [ok, ret] =
          await NotaFiscalConsumidorService.inclusao.buscarItens(
            stateCab.idNfe ?? id
          );
        if (ok) {
          setItens(ret.itens);

          const totaisNfe = ret.totais;

          const statePayload = {
            baseCalcIcms: parseFloat(totaisNfe?.base_calc_icms),
            vlrTotIcms: parseFloat(totaisNfe?.vlr_tot_icms),
            vlrTotProd: parseFloat(totaisNfe?.vlr_tot_prod),
            vlrTotNf: parseFloat(totaisNfe?.vlr_tot_nf),
          };

          dispatch(setTotaisNfe(statePayload));
        }
      }
    };

    const iniciarTela = async () => {
      // Evita que fique algum valor perdido de anteriormente
      dispatch(init());

      if (!(await carregarParametros())) {
        history.goBack();
        return false;
      }

      if (action === MODAL_ACTIONS.EDIT) {
        if (!(await carregarDados(true))) {
          history.goBack();
          return false;
        }
      }
      setLoading(false);
    };

    const onActivate = () => {
      iniciarTela();
    };

    useEffect(onActivate, []);

    const handleSelectCliente = ({ id, nome, curinga, cpfCnpj }) => {
      dispatch(setClienteCuringa(curinga));
      if (curinga && id === stateCab.idCliente) {
        if (
          nome !== stateCab.nomeCliente &&
          !["", null, undefined].includes(stateCab.nomeCliente)
        ) {
          return;
        }

        if (
          cpfCnpj !== dadosCliente.cpfCnpj &&
          !["", null, undefined].includes(dadosCliente.cpfCnpj)
        ) {
          return;
        }
      }
      dispatch(setIdCliente(id));
      dispatch(setNomeCliente(nome));
      dispatch(setCpfCnpjDestinatario(cpfCnpj));
    };

    const incluirCab = async () => {
      const payload = {
        id_cliente: stateCab.idCliente,
        nome_cliente: stateCab.nomeCliente,
        cpf_cnpj_cliente: dadosCliente.cpfCnp ?? "",
      };

      const [ok, ret] = await NotaFiscalConsumidorService.inclusao.incluirCab(
        payload
      );
      if (ok) {
        dispatch(setIdNfe(ret.id_nfce_cab));
        return ret.id_nfce_cab;
      } else {
        return null;
      }
    };

    const verificarIncluirCab = async () => {
      if (!stateCab.idNfe) {
        return await incluirCab();
      } else {
        return stateCab.idNfe;
      }
    };

    const handleSetFormaPag = (v) => {
      if (v === stateCab.formaPag) return;
      dispatch(setFormaPag(v));
      if (v !== "OT") {
        dispatch(setDescFormaPag(""));
      }
    };

    const finalizarNfe = async () => {
      if (["", null, undefined].includes(stateCab.formaPag)) {
        toastr.warning("Atenção", "Por favor, informe a Forma de Pagamento");
        return;
      }

      if (
        stateCab.formaPag === "OT" &&
        (stateCab.descFormaPag ?? "").trim() === ""
      ) {
        toastr.warning(
          "Atenção",
          "Quando a forma de pagamento for identificada como OUTROS, " +
            "é necessário informar a descrição da moeda."
        );
        return;
      }

      if (semItens) {
        toastr.warning("Atenção", "Por favor, inclua ao menos um item.");
        return;
      }

      setLoadingFinalizar(true);

      const payload = {
        id_nfce: stateCab.idNfe,
        id_cliente: stateCab.idCliente,
        nome_cliente: stateCab.nomeCliente,
        cpf_cnpj_cliente: dadosCliente.cpfCnpj ?? "",
        forma_pag: stateCab.formaPag,
        desc_forma_pag: stateCab.descFormaPag,
      };

      const [ok] = await NotaFiscalConsumidorService.inclusao.finalizarInclusao(
        payload
      );
      if (ok) {
        setLoadingFinalizar(false);
        dispatch(init());
        history.replace(notaFiscalConsumidorRoute.path);
      }
      setLoadingFinalizar(false);
    };

    const onKeyDown = (e) => {
      if (e.key === "F9") {
        if (loadingFinalizar) return;
        finalizarNfe();
      }

      if (e.key === "F6") {
        e.preventDefault();
        if (incluirProdRef.current) {
          incluirProdRef.current.handleSubmit();
        }
      }
    };

    useImperativeHandle(ref, () => ({
      onBeforeGoingBack: () => dispatch(init()),
      onKeyDown: onKeyDown,
    }));

    return loading ? (
      <Loader />
    ) : (
      <>
        <Card body>
          <Divider className="mt-0">Informações do Destinatário</Divider>
          <Row className="mb-2">
            <PesqCliente
              onConfirm={handleSelectCliente}
              onChangeDescricao={(v) => dispatch(setNomeCliente(v))}
              idCliente={stateCab.idCliente}
              nomeCliente={stateCab.nomeCliente}
              ref={pesqClienteRef}
              showShortcut
            />
            <TextInput
              md={2}
              label="CPF/CNPJ"
              value={dadosCliente.cpfCnpj}
              onChange={(e, v) => dispatch(setCpfCnpjDestinatario(v))}
              disabled={!dadosCliente.curinga}
              inputStyle={{ textAlign: "center" }}
            />
          </Row>
        </Card>
        <Card body>
          <Divider className="mt-0">Informações dos Produtos</Divider>
          <IncluirItemNFConsForm
            idCliente={stateCab.idCliente}
            finalidadeOperacao={stateCab.finalidadeOperTipoDoc}
            idNfe={stateCab.idNfe}
            notifyEvent={carregarDadosItens}
            verificarIncluirCab={verificarIncluirCab}
            formaSistemaTribut={formaSistemaTribut}
            regimeTribut={regimeTribut}
            ref={incluirProdRef}
          />
          <ItensNFConsGrid
            dados={itens}
            notifyExclusao={carregarDadosItens}
            regimeTribut={regimeTribut}
          />
        </Card>
        <Card body>
          <Divider className="mt-0">Totais da Nota Fiscal</Divider>
          <Row>
            {regimeTribut !== "SIM" && (
              <>
                <FixedField
                  md={2}
                  label="Base Cálc. ICMS"
                  value={formatNumber(stateTotaisNfe.baseCalcIcms, true, 2)}
                />
                <FixedField
                  md={2}
                  label="ICMS"
                  value={formatNumber(stateTotaisNfe.vlrTotIcms, true, 2)}
                />
              </>
            )}
            <FixedField
              md={2}
              label="Total Produtos"
              value={formatNumber(stateTotaisNfe.vlrTotProd, true, 2)}
            />
            <FixedField
              md={2}
              label="Total da Nota"
              value={formatNumber(stateTotaisNfe.vlrTotNf, true, 2)}
              labelStyle={{ color: "black" }}
              textStyle={{ fontSize: "0.9rem", fontWeight: "bold" }}
            />
          </Row>
        </Card>
        <Card body>
          <Row>
            <ComboBox
              md={3}
              label="Forma de Pagamento"
              options={formaPagOptions}
              defaultValue={stateCab.formaPag}
              onChange={(s) => handleSetFormaPag(s?.value)}
              menuPlacement="top"
            />
            {stateCab.formaPag === "OT" && (
              <TextInput
                md={4}
                label="Descrição"
                value={stateCab.descFormaPag}
                onChange={(e, v) => dispatch(setDescFormaPag(v))}
              />
            )}
            <FormButton
              md="auto"
              color="success"
              onClick={finalizarNfe}
              loading={loadingFinalizar}
              disabled={[0, null, undefined].includes(stateCab.idNfe)}
              disabledHint="A NF não foi salva. Inclua um item ou salve-a primeiro."
            >
              F9 - Finalizar NFCe
            </FormButton>
          </Row>
        </Card>
      </>
    );
  }
);

export const IncluirAlterarNFCons = ({ location }) => {
  const selected = location.state.selected;
  const action = location.state.action ?? MODAL_ACTIONS.ADD;

  const refContainer = useRef();

  return (
    <PageContainer
      title={`${
        action === MODAL_ACTIONS.ADD ? "Inclusão" : "Alteração"
      } de Nota Fiscal Consumidor`}
      number="0066_1"
      canGoBack
      onKeyDown={(e) => {
        if (refContainer.current) {
          refContainer.current.onKeyDown(e);
        }
      }}
      onBeforeGoingBack={() => {
        if (refContainer.current) {
          refContainer.current.onBeforeGoingBack();
        }
      }}
    >
      <Provider store={storeCab}>
        <IncluirAlterarNFConsContainer
          selected={selected}
          action={action}
          ref={refContainer}
        />
      </Provider>
    </PageContainer>
  );
};
