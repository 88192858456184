import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row } from "reactstrap";
import { AsyncComboBox, IntegerFormInput } from "../../../../components";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import service from "../../../../services/ordem_servico/VistoriaTecnicaService";
import {
  setIdCliente,
  setIdOS,
  setOsPesquisadas,
} from "../store/filtros_vistorias_slice";

export const Filtros = () => {
  const filtrosVistorias = useSelector((state) => state.filtrosVistorias);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const buscarDados = async () => {
    setLoading(true);
    dispatch(setOsPesquisadas(await service.buscarDados(filtrosVistorias)));
    setLoading(false);
  };

  return (
    <Card body>
      <Row>
        <AsyncComboBox
          label="Cliente"
          md={4}
          isConcatField
          concatModelName="cliente"
          isSearchable
          isClearable
          onChange={(selected) => dispatch(setIdCliente(selected?.value))}
        />
        <IntegerFormInput
          label="Nº O.S."
          md={2}
          onChange={(v) => dispatch(setIdOS(v))}
        />
        <BotaoPesquisar onClick={buscarDados} loading={loading} />
      </Row>
    </Card>
  );
};
