import React, { useState } from "react";
import Card from "reactstrap/lib/Card";
import { CadastroMetaVendaModal } from "./components/CadastroMetaVendaModal";
import { MetaVendaGrid } from "./components/MetaVendaGrid";
import { PageContainer } from "../../../../components/PageContainer";
import { Row } from "reactstrap";
import {
  BotaoPesquisar,
  FormCheckbox,
  LinkButton,
  SearchInput,
} from "../../../../components";
import {
  MODAL_ACTIONS,
  defaultDebounceTime,
  useDebounce,
} from "../../../../coreUtils";
import CadastroMetaVendaService from "../../../../services/cadastro/CadastroMetaVendaService";
import { ClonarMetaVendaModal } from "./components/ClonarMetaVendaModal";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { incluirMetaBaseadoVendasRoute } from "../../../../routes/modules/cadastro";

export const modalTitleMetaVenda = "Meta de Venda";
export const routesBaseMetaVenda = CadastroMetaVendaService.routesBase;

export const MetaVenda = () => {
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedTipoInc, setSelectedTipoInc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);
  const [somenteEmVigor, setSomenteEmVigor] = useState(true);

  const buscarDados = async (p) => {
    const [ok, ret] = await CadastroMetaVendaService.listar({
      pista: p,
      somente_em_vigor: somenteEmVigor,
    });
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = useDebounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const handleSelect = (id, is, row) => {
    setSelected(is ? id : null);
    setSelectedTipoInc(is ? row.tipo_inc : null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Metas de Venda" number="0056" canGoBack>
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <FormCheckbox
            label="Somente Metas em Vigor"
            name="somente_em_vigor"
            checked={somenteEmVigor}
            onChange={() => setSomenteEmVigor(!somenteEmVigor)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
        </Row>
        <Row>
          <BotaoIncluir toggle={toggleCadastro} text="Inclusão Manual" />
          <ClonarMetaVendaModal
            selected={selected}
            selectedTipoInc={selectedTipoInc}
            notifyEvent={notifyEvent}
          />
          <LinkButton
            pathname={incluirMetaBaseadoVendasRoute.path}
            md="auto"
            color="info"
          >
            Inclusão Baseada em Venda
          </LinkButton>
          <CadastroMetaVendaModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleMetaVenda}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0056_2"
            selected={selected}
            routeBase={routesBaseMetaVenda}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <MetaVendaGrid
          data={dados}
          handleSelect={handleSelect}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
