import React from "react";
import { useState } from "react";
import {
  AsyncComboBox,
  BotaoImprimir,
  FiltroPeriodoDatas,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { docPrintReport } from "../../../../pdf";
import { apiGetV2 } from "../../../../apiV2";

export const ValorPorCliente = () => {
  const [dataFim, setDataFim] = useState(new Date());
  const [dataIni, setDataIni] = useState(new Date());
  const [desconsideraColaborador, setDesconsideraColaborador] = useState();
  const [consideraColaborador, setConsideraColaborador] = useState();
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_vendedor_considerar: consideraColaborador,
      id_vendedor_desconsiderar: desconsideraColaborador,
    };

    console.log(consideraColaborador, desconsideraColaborador)

    return await apiGetV2(
      `/relatorios/venda/valores_vendidos_por_cliente/`,
      params
    );
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();

    if (ok) {
      const payload = {
        dados: ret,
        data_ini: dataIni,
        data_fim: dataFim,
      };

      await docPrintReport(
        "/relatorios/vendas/valores_vendidos_por_cliente/",
        payload,
        "5070",
        true
      );
    }
    setLoadingImprimir(false);
  };

  return (
    <PageContainer title="Valor Vendido por Cliente" number="5070" canGoBack>
      <Card body>
        <Row>
          <FiltroPeriodoDatas
            onChange={handleDate}
            disabled={loadingImprimir}
          />

          <AsyncComboBox
            md={3}
            label="Desconsiderar Colaborador"
            concatModelName="colaborador"
            onChange={(s) => setDesconsideraColaborador(s?.value)}
            isMulti={desconsideraColaborador}
          />
          <AsyncComboBox
            md={3}
            label="Considerar Apenas Colaborador"
            concatModelName="colaborador"
            onChange={(s) => setConsideraColaborador(s?.value)}
            isMulti={consideraColaborador}
          />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
    </PageContainer>
  );
};
