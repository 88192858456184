import React from "react";
import { useState } from "react";
import {
  AsyncComboBox,
  NumberInput,
  TextInput,
} from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { modalTitleSupervisor, routesBaseSupervisor } from "../Supervisor";
import { showWarning } from "../../../../../components/AlertaModal";

export const SupervisorModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [nome, setNome] = useState();
  const [colaborador, setColaborador] = useState("");
  const [percComissao, setPercComissao] = useState(0);
  const [ativo, setAtivo] = useState();

  const limparDados = () => {
    setNome("");
    setColaborador("");
    setPercComissao(0);
    setAtivo(true);
  };

  const fetchData = (dados) => {
    setNome(dados.nome);
    setColaborador(dados.id_colab_supervisor);
    setPercComissao(parseFloat(dados.perc_comissao));
    setAtivo(dados.ativo);
  };

  const submitPayload = (action) => {
    if (colaborador.length <= 0) {
      showWarning(
        "Atenção",
        "Por favor, informe o supervisor.",
        null,
        "async-id-supervisor"
      );
      return false;
    }

    const payload = {
      nome: nome,
      id_colab_supervisor: colaborador,
      perc_comissao: percComissao,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : {
          id_gerenc_superv_cab: selected,
          ativo: ativo,
          ...payload,
        };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleSupervisor}
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseSupervisor}
      number="0122_1"
      selected={selected}
      notifyEvent={notifyEvent}
      headerCheck={
        action === MODAL_ACTIONS.EDIT && {
          value: ativo,
          toggle: () => setAtivo(!ativo),
        }
      }
    >
      <Row>
        <TextInput
          md={10}
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={200}
        />
        <NumberInput
          md={2}
          label="% Comissão"
          value={percComissao}
          onChange={setPercComissao}
          isPercentage
        />
      </Row>
      <Row>
        <AsyncComboBox
          isConcatField
          concatModelName="colaborador"
          isSearchable
          isClearable
          name="supervisor"
          label="Supervisor"
          required
          onChange={(s) => {
            setColaborador(s?.value ?? "");
          }}
          defaultValue={colaborador}
          inputName={"async-id-supervisor"}
        />
      </Row>
    </ModalCadastroV2>
  );
};
