import React from "react";
import { formatNumber, sumDataField } from "../../../../../../coreUtils";
import { Table, TableDelete } from "../../../../../../components";
import { MdOutlineHomeRepairService } from "react-icons/md";
import { BsBoxSeam } from "react-icons/bs";
import { UncontrolledTooltip } from "reactstrap";

export const OrcamentoItensGrid = ({
  data,
  handleDelete,
  recalcularValoresGrade,
  utilizaObservItem,
}) => {
  const columns = [
    {
      dataField: "tipo",
      text: "T",
      align: "center",
      headerAlign: "center",
      editable: false,
      colWidth: "30px",
      formatter: (c, row) => {
        const iconId = `icon-tipo-${row.id}-${row.id_item}`;
        if (c === "S") {
          return (
            <>
              <MdOutlineHomeRepairService id={iconId} size={16} />
              <UncontrolledTooltip target={iconId}>{c}</UncontrolledTooltip>
            </>
          );
        } else {
          return (
            <>
              <BsBoxSeam id={iconId} size={14} />
              <UncontrolledTooltip target={iconId}>{c}</UncontrolledTooltip>
            </>
          );
        }
      },
    },
    {
      dataField: "id_item",
      text: "# Item",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "descricao",
      text: "Nome",
      align: "left",
      headerAlign: "left",
      editable: (_, row) => row.curinga,
      onChange: recalcularValoresGrade,
      editorType: "text",
      alwaysShowEditor: true,
      colWidth: utilizaObservItem ? "120px" : "350px",
    },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      editable: true,
      onChange: recalcularValoresGrade,
      editorType: "number",
      decimalPlaces: 2,
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_unit",
      text: "Vlr. Unit",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "observ",
      text: "Observação",
      align: "left",
      headerAlign: "left",
      editable: true,
      colWidth: "280px",
      editorType: "textarea",
      onChange: recalcularValoresGrade,
      borderless: true,
      hidden: !utilizaObservItem,
      editorForceUpperCase: false,
    },
    {
      dataField: "vlr_item",
      text: "Vlr. Item",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
      editable: true,
      onChange: recalcularValoresGrade,
      editorType: "number",
      decimalPlaces: 4,
      alwaysShowEditor: true,
    },
    {
      dataField: "perc_desc",
      text: "Perc. Desc",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
      editable: true,
      onChange: recalcularValoresGrade,
      editorType: "number",
      decimalPlaces: 2,
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_tot_desc",
      text: "Vlr. Desc",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
      footer: () => formatNumber(sumDataField(data, "vlr_total"), true, 2),
      editable: true,
      onChange: recalcularValoresGrade,
      editorType: "number",
      decimalPlaces: 2,
      alwaysShowEditor: true,
    },
    {
      dataField: "act_delete",
      isDummyField: true,
      align: "center",
      headerAlign: "center",
      formatter: (c, row, index) => (
        <TableDelete onClick={() => handleDelete(index)} />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      data={data}
      showRegisterCount={false}
      paginated={false}
      pageSize={10}
    />
  );
};
