import React, { useEffect, useState } from "react";
import { Card, Col, ListGroup, Row } from "reactstrap";
import {
  AsyncComboBox,
  BaseInfoTelaCard,
  BotaoImprimir,
  BotaoPesquisar,
  CardTotaisItem,
  FiltroPeriodoDatas,
  FormCheckbox,
  PageContainer,
  RadioGroup,
} from "../../../../components";
import { CustoVsPrecoVendaGrid } from "./components/CustoVsPrecoVendaGrid";
import {
  userPodeVerTotaisRelVenda,
  isArray,
  formatDateISO,
  formatNumber,
} from "../../../../coreUtils";
import { docPrintReport } from "../../../../pdf";
import { apiGetV2 } from "../../../../apiV2";
import { RadioItem } from "../../../../components/RadioGroup";

export const VendasCustoVsPrecoVenda = () => {
  const podeVerTotaisRelVenda = userPodeVerTotaisRelVenda();
  const idUsuario = parseInt(localStorage.getItem("id_colaborador"));
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [idCliente, setIdCliente] = useState([]);
  const [idTipoCliente, setIdTipoCliente] = useState([]);
  const [idCidade, setIdCidade] = useState([]);
  const [idRede, setIdRede] = useState(null);
  const [idVendedor, setIdVendedor] = useState(
    !podeVerTotaisRelVenda ? [idUsuario] : []
  );
  const [tipoCusto, setTipoCusto] = useState("VDA");
  const [imprimirDetalhes, setImprimirDetalhes] = useState(false);
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const limparDados = () => {
    setDados([]);
    setTotais({});
  };

  const buscarDados = () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_cliente: idCliente,
      id_tipo_cliente: idTipoCliente,
      id_cidade: idCidade,
      id_vendedor: idVendedor,
      id_rede: idRede,
      tipo_custo: tipoCusto,
    };

    return apiGetV2("/relatorios/venda/vendas_custo_vs_preco_venda/", params);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret.vendas : []);
    setTotais(ok ? ret.totais : {});
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      await docPrintReport(
        "/relatorios/venda/vendas_custo_vs_preco_venda/",
        {
          dados: ret.vendas,
          totais: ret.totais,
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
          imprimir_detalhes: imprimirDetalhes,
        },
        "5058",
        true
      );
    }
    setLoadingImprimir(false);
  };

  const handleSetVendedores = (selected) =>
    setIdVendedor(
      isArray(selected)
        ? selected?.map((e) => e.value)
        : selected
        ? [selected?.value]
        : []
    );

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  useEffect(() => {
    limparDados();
  }, [
    dataIni,
    dataFim,
    idCliente,
    idTipoCliente,
    idCidade,
    idVendedor,
    idRede,
  ]);

  return (
    <PageContainer
      title="Relatório de Vendas - Custo vs Preço Venda"
      number="5058"
      canGoBack
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            label="Cliente"
            concatModelName="cliente"
            isMulti
            hideShortcut
            onChange={(s) => setIdCliente(s?.map((e) => e.value) ?? [])}
          />
          <AsyncComboBox
            md={3}
            label="Tipo de Cliente"
            concatModelName="tipo_cliente"
            isMulti
            hideShortcut
            defaultOptions
            onChange={(s) => setIdTipoCliente(s?.map((e) => e.value) ?? [])}
          />
          <AsyncComboBox
            md={3}
            label="Rede de Cliente"
            concatModelName="rede_cliente"
            hideShortcut
            defaultOptions
            onChange={(s) => setIdRede(s?.value)}
          />
          <AsyncComboBox
            md={3}
            label="Vendedor"
            concatModelName="colaborador"
            hideShortcut
            defaultOptions
            isMulti={podeVerTotaisRelVenda}
            disabled={!podeVerTotaisRelVenda}
            defaultValue={!podeVerTotaisRelVenda ? idVendedor[0] : null}
            onChange={handleSetVendedores}
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={3}
            label="Cidade"
            concatModelName="cidade"
            isMulti
            hideShortcut
            onChange={(s) => setIdCidade(s?.map((e) => e.value) ?? [])}
          />
          <Col>
            <RadioGroup
              label="Custo a ser considerado"
              value={tipoCusto}
              onChange={setTipoCusto}
              divClassName="mb-2"
            >
              <RadioItem label="Custo na Data da Venda" value="VDA" />
              <RadioItem label="Custo Atual do Produto" value="CAD" />
            </RadioGroup>
          </Col>
        </Row>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <FormCheckbox
            label="Imprimir Detalhes"
            checked={imprimirDetalhes}
            onChange={() => setImprimirDetalhes(!imprimirDetalhes)}
          />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <Card body>
        <Row>
          <Col>
            <ListGroup horizontal>
              <CardTotaisItem
                label="Qtd. Vendas"
                value={totais?.n_registros}
                checkFloat
              />
              <CardTotaisItem
                label="Total Custo"
                value={totais?.vlr_tot_custo}
              />
              <CardTotaisItem
                label="Total Bruto"
                value={totais?.vlr_tot_bruto}
              />
              <CardTotaisItem
                label="Total Desconto"
                value={totais?.vlr_desconto}
              />
              <CardTotaisItem
                label="Total Desconto Financeiro"
                value={totais?.vlr_desconto_financ}
              />
            </ListGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <ListGroup horizontal>
              <CardTotaisItem
                label="Total Bonificação"
                value={totais?.vlr_tot_bonif}
              />
              <CardTotaisItem
                label="Saldo Flex"
                value={totais?.saldo_flex}
                hintText={
                  "Valores negativos representam descontos concedidos, enquanto " +
                  "valores positivos indicam vendas realizadas acima do preço de " +
                  "tabela"
                }
              />
              <CardTotaisItem label="Total Venda" value={totais?.vlr_total} />
              <CardTotaisItem
                label="Ticket Médio"
                value={totais?.ticket_medio}
              />
              <CardTotaisItem
                label="Resultado de Vendas"
                value={
                  <>
                    {formatNumber(totais?.perc_lucro ?? 0, true, 2)}% |{" "}
                    {formatNumber(totais?.resultado ?? 0, true, 2)}
                  </>
                }
                format={false}
              />
            </ListGroup>
          </Col>
        </Row>
      </Card>
      <Card body>
        <CustoVsPrecoVendaGrid dados={dados} />
      </Card>
      <BaseInfoTelaCard nroTela="5058" />
    </PageContainer>
  );
};
