import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Col,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { handleFocus } from "../coreUtils";
import { HintLabel } from "./HintLabel";

const TextInput = forwardRef(
  (
    {
      md,
      mdInput = 12,
      label,
      type = "text",
      name,
      onChange,
      value,
      className,
      inline = false,
      check = false,
      required = false,
      invalid = false,
      disabled = false,
      placeholder = "",
      maxLength,
      autoFocus,
      clearOnDisable = false,
      tabOrder,
      id,
      inputStyle,
      rows,
      onBlur,
      forceUppercase = true,
      colClassName,
      additionalButton,
      colStyle,
      onKeyDown,
      hiddenLabel = false,
      selectTextOnFocus = false,
      hint,
      frmGroupClassName,
      addonText,
      addonType,
    },
    ref
  ) => {
    const [innerValidation, setInnerValidation] = useState(true);
    const [internalValue, setInternalValue] = useState("");
    const inputRef = useRef("");
    const [internalId] = useState(
      id ?? "text-input-" + Math.floor(Math.random() * Date.now())
    );

    const validateInternally = (e) => {
      if (required && e.target.value === "") {
        setInnerValidation(false);
      } else {
        setInnerValidation(true);
      }
    };

    const updateInternalValue = () => {
      if (value?.toUpperCase() !== internalValue?.toUpperCase()) {
        if (forceUppercase) {
          setInternalValue(value?.toUpperCase());
        } else {
          setInternalValue(value);
        }
      }
    };
    useEffect(updateInternalValue, [value]);

    const handleOnChange = (e) => {
      e.preventDefault();
      const inputValue = e.target.value || "";
      setInternalValue(inputValue);
      if (onChange) {
        if (forceUppercase) {
          onChange(e, inputValue?.toUpperCase());
        } else {
          onChange(e, inputValue);
        }
      }
    };

    const onKeyDownInternal = (e) => {
      if (type !== "textarea" || (e.key !== "Enter" && e.key !== "ArrowUp")) {
        handleFocus(e);
      } else if (type === "textarea" && e.key === "Enter") {
        // Foca o próximo elemento quando detectar duas quebras de linhas em
        // sequência e o enter for pressionado.
        // Remove as quebras de linhas vazias no fim do texto
        if (/(^(\r|\n)$)|((\r|\n)(\r|\n)$)/.exec(e.target.value)) {
          handleFocus(e);
          e.target.value = (e.target.value ?? "").replace(/(\r|\n)*$/, "");

          handleOnChange(e);
        }
      }
      if (onKeyDown) {
        onKeyDown(e);
      }
    };

    const onBlurInternal = (e) => {
      if (onBlur) {
        const inputValue = e.target.value || "";
        if (forceUppercase) {
          onBlur(e, inputValue?.toUpperCase());
        } else {
          onBlur(e, inputValue);
        }
      }
      validateInternally(e);
    };

    useImperativeHandle(ref, () => ({
      isValid: () => innerValidation && !invalid,
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
      value: forceUppercase
        ? internalValue?.toUpperCase()?.trim()
        : internalValue?.trim(),
      setValue: (val) => setInternalValue(val),
    }));

    const handleClearOnDisable = () => {
      if (disabled && clearOnDisable) {
        setInternalValue("");
        if (onChange) {
          onChange(null, "");
        }
      }
    };
    useEffect(handleClearOnDisable, [disabled, clearOnDisable]);

    const internalInput = (
      <Input
        id={internalId}
        type={type}
        name={name}
        onChange={handleOnChange}
        onBlur={onBlurInternal}
        innerRef={inputRef}
        className={className}
        required={required}
        value={internalValue}
        invalid={!innerValidation || invalid}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={maxLength}
        autoFocus={autoFocus}
        onKeyDown={onKeyDownInternal}
        tabOrder={tabOrder}
        style={{
          textTransform: forceUppercase ? "uppercase" : "none",
          ...inputStyle,
        }}
        rows={rows}
        onFocus={selectTextOnFocus ? (e) => e.target.select() : () => {}}
      />
    );

    return (
      <Col md={md} className={colClassName} style={colStyle}>
        <FormGroup check={check} inline={inline} className={frmGroupClassName}>
          {label && (
            <HintLabel
              for={internalId}
              hintText={hint}
              label={label}
              hidden={hiddenLabel}
              required={required}
            />
          )}
          <Col md={mdInput} className="no-gutters">
            <div style={{ display: "flex", width: "100%" }}>
              {addonText ? (
                <InputGroup>
                  {addonType === "prepend" && (
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>{addonText}</InputGroupText>
                    </InputGroupAddon>
                  )}
                  {internalInput}
                  {addonType === "append" && (
                    <InputGroupAddon addonType="append">
                      <InputGroupText>{addonText}</InputGroupText>
                    </InputGroupAddon>
                  )}
                </InputGroup>
              ) : (
                <>
                  {internalInput}
                  {additionalButton && additionalButton}
                </>
              )}
            </div>
          </Col>
          <FormFeedback valid={false}>Esse campo é obrigatório</FormFeedback>
        </FormGroup>
      </Col>
    );
  }
);

export default TextInput;
