import React from "react";
import { ColorField, IconButton, MoreDropdown } from "../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../coreUtils";
import { Edit } from "react-feather";
import { orcamentosOSRoute } from "../../../../routes/modules/ordem_servico";
import { ClassifCli } from "../../../../components/ClassifCli";
import { Table } from "../../../../components/Table";
import TableItensCentralOS from "./TableItensCentralOS";
import { DropdownItem } from "reactstrap";
import { useHistory } from "react-router-dom";
import TableExecucoesCentralOS from "./TableExecucoesCentralOS";

const coresStatusAvanc = [
  { value: "A", hint: "Aberta", color: "#CF369A" },
  { value: "C", hint: "Ag. Checklist, Em Recepção", color: "#4BB51E" },
  { value: "K", hint: "Em Checklist/Orçamento", color: "#0080FF" },
  { value: "P", hint: "Pausada", color: "#FF8000" },
  { value: "E", hint: "Ag. Execução", color: "#DCBF1D" },
  { value: "U", hint: "Em Execução", color: "#7E24B7" },
  { value: "H", hint: "Ag. Definição", color: "#FA335F" },
  { value: "T", hint: "Concluída", color: "#004080" },
  { value: "F", hint: "Finalizada", color: "#448560" },
];

export const CentralOSGrid = ({
  dados,
  setSelected,
  mecanica,
  utilizaStatusAdicConcServ,
  identificaDataHoraAgendamento,
  identificaTecnicoResp,
  utilizaModuloAvancado,
  imprimirOS,
  exportarOS,
  reabrirConclusao,
  mostrarDetalhes,
  abrirManutencaoOS,
}) => {
  const history = useHistory();
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "placa",
      text: "Placa",
      align: "center",
      headerAlign: "center",
      hidden: !mecanica,
    },
    {
      dataField: "km",
      text: "KM",
      align: "center",
      headerAlign: "center",
      hidden: !mecanica,
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => (
        <div style={{ display: "flex" }}>
          <span className="mr-2">{formatValueFromAPI(c, row.id_cliente)}</span>
          {![0, null, undefined].includes(row.classificacao_cliente) && (
            <ClassifCli
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                marginBlock: "auto",
              }}
              value={row.classificacao_cliente}
              table
            />
          )}
        </div>
      ),
      sortable: true,
    },
    {
      dataField: "nome_veic",
      text: "Veículo",
      align: "left",
      headerAlign: "left",
      hidden: !mecanica,
    },
    {
      dataField: "modelo_veic",
      text: "Modelo",
      align: "left",
      headerAlign: "left",
      hidden: !mecanica,
    },
    {
      dataField: "ano_veic",
      text: "Ano",
      align: "center",
      headerAlign: "center",
      hidden: !mecanica,
    },
    {
      dataField: "nome_tecnico",
      text: "Responsável",
      align: "left",
      headerAlign: "left",
      hidden: !identificaTecnicoResp,
      sortable: true,
    },
    {
      dataField: "data_abertura",
      text: "Abertura",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "data_pre",
      text: "Prev. Entrega",
      align: "center",
      headerAlign: "center",
      hidden: !mecanica,
    },
    {
      dataField: "data_conclusao_serv",
      text: "Concluído em",
      align: "center",
      headerAlign: "center",
      hidden: !utilizaStatusAdicConcServ,
    },

    {
      dataField: "data_agend",
      text: "Agendado Para",
      align: "center",
      headerAlign: "center",
      hidden: !identificaDataHoraAgendamento,
    },
    {
      dataField: "desc_status",
      text: "Status",
      align: "center",
      headerAlign: "center",
      hidden: utilizaModuloAvancado,
    },
    {
      dataField: "status",
      text: "Stat",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <ColorField scheme={coresStatusAvanc} value={c} />,
      hidden: !utilizaModuloAvancado,
      style: { verticalAlign: "middle", padding: 0 },
      colWidth: "35px",
      fixedColWidth: true,
      sortable: true,
      hideSortIcon: true,
    },
    {
      dataField: "vlr_total",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "det",
      text: "Manut.",
      align: "center",
      headerAlign: "center",
      cellContentTag: null,
      formatter: (c, row) => (
        <IconButton
          icon={Edit}
          onClick={() => abrirManutencaoOS(row.id)}
          hint="Manutenção da O.S."
          disabled={row.status === "F"}
          disabledHint="A O.S. já se encontra fechada"
          tooltipPlacement="left"
        />
      ),
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      selectOnClick: false,
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={() => mostrarDetalhes(row.id)}>
            Detalhes
          </DropdownItem>
          <DropdownItem onClick={() => imprimirOS(row.id)}>
            Imprimir
          </DropdownItem>
          <DropdownItem onClick={() => exportarOS(row.id)}>
            Exportar
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              history.push(orcamentosOSRoute.path, { idOs: row.id })
            }
          >
            Orçamentos
          </DropdownItem>
          {utilizaModuloAvancado &&
            utilizaStatusAdicConcServ &&
            row.status === "T" && (
              <DropdownItem onClick={() => reabrirConclusao(row.id)}>
                Reabrir Conclusão
              </DropdownItem>
            )}
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table
      data={dados instanceof Array ? dados : []}
      columns={columns}
      onSelect={setSelected}
      paginated={false}
      pageSize={999999}
      growIntoPageSize
      canExpand
      onRowDoubleClick={(id, row) =>
        row.status !== "F" && abrirManutencaoOS(id)
      }
      expandedAreaBuilder={(row) => (
        <div className="m-0 p-0">
          <div
            style={{
              width: "100%",
              padding: "0.5rem",
              backgroundColor: "#248086",
              color: "white",
            }}
          >
            <p className="mb-1">
              <b>Descrição do Problema: </b>
              {row.defeito1}
            </p>
            {row.obs_emi1 && (
              <p className="mb-1">
                <b>Observação: </b>
                {row.obs_emi1}
              </p>
            )}
          </div>
          <TableItensCentralOS idOs={row.id} mecanica={mecanica} />
          {utilizaModuloAvancado && <TableExecucoesCentralOS idOs={row.id} />}
        </div>
      )}
    />
  );
};
