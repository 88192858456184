import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  modalTitleFichaTecnica,
  routesBaseFichaTecnica,
} from "../FichaTecnica";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import {
  MODAL_ACTIONS,
  formatNumber,
  formatarValor,
  roundFloat,
} from "../../../../../coreUtils";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  Divider,
  FixedField,
  FormButton,
  NumberInput,
  RadioGroup,
  Table,
  TableDelete,
} from "../../../../../components";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";
import { showWarning } from "../../../../../components/AlertaModal";
import { v4 as uuidv4 } from "uuid";
import { RadioItem } from "../../../../../components/RadioGroup";
import { TIPOS_INSUMO_FICHA_TECNICA } from "../flags";

export const CadastroFichaTecnicaModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [idProdutoFinal, setIdProdutoFinal] = useState(null);
  const [nomeProdutoFinal, setNomeProdutoFinal] = useState("");
  const [vlrCustoTotalProdPronto, setVlrCustoTotalProdPronto] = useState(0);
  const [unidade, setUnidade] = useState("");
  const [insumos, setInsumos] = useState([]);

  const pesqProdInsumoRef = useRef();
  const pesqProdFinalRef = useRef();

  const limparDados = () => {
    setAtivo(true);
    setIdProdutoFinal(null);
    setNomeProdutoFinal("");
    setUnidade("");
    setInsumos([]);
  };

  const fetchData = (data) => {
    setAtivo(data.ativo);
    setIdProdutoFinal(data.id_produto);
    setNomeProdutoFinal(data.nome_produto);
    setUnidade(data.unidade);
    setInsumos(
      data.insumos.map((e) => ({
        uuid: uuidv4(),
        tipo: e.tipo,
        desc_tipo: e.desc_tipo,
        id_produto: e.id_produto,
        nome_produto: e.nome_produto,
        unidade: e.unidade,
        quantidade: parseFloat(e.quantidade),
        vlr_custo_unit: parseFloat(e.vlr_custo_unit),
        vlr_custo_total: parseFloat(e.vlr_custo_total),
      }))
    );
    setVlrCustoTotalProdPronto(parseFloat(data.vlr_custo_total));
    setTimeout(() => {
      if (pesqProdFinalRef.current) {
        pesqProdFinalRef.current.setDescricao(data.nome_produto);
        pesqProdFinalRef.current.setId(String(data.id_produto));
        pesqProdFinalRef.current.setReferencia(String(data.referencia_produto));
      }
    }, 150);
  };

  const handleSelectProdutoFinal = ({
    idProduto: id,
    nomeProduto: nome,
    referencia,
    unidade,
  }) => {
    if (idProdutoFinal !== id) {
      setIdProdutoFinal(id);
      setNomeProdutoFinal(nome);
      setUnidade(unidade);
      if (pesqProdFinalRef.current) {
        pesqProdFinalRef.current.setDescricao(nome);
        pesqProdFinalRef.current.setId(String(id));
        pesqProdFinalRef.current.setReferencia(String(referencia));
      }
    }
  };

  const selectNextFieldProdutoFinal = () =>
    pesqProdInsumoRef.current && pesqProdInsumoRef.current.focus();

  const submitPayload = (action) => {
    const payload = {
      ativo: ativo,
      id_produto_final: idProdutoFinal,
      nome_produto_final: nomeProdutoFinal,
      unidade: unidade,
      insumos: insumos.map((e) => ({
        tipo: e.tipo,
        id_produto: e.id_produto,
        nome_produto: e.nome_produto,
        unidade: e.unidade,
        quantidade: e.quantidade,
        vlr_custo_unit: e.vlr_custo_unit,
      })),
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id_ficha: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleFichaTecnica}
      size="xl"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseFichaTecnica}
      number="0092_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row className="mb-2">
        <PesqProduto
          label="Produto Final"
          md={8}
          mdIdent={3}
          mdDesc={9}
          onConfirm={handleSelectProdutoFinal}
          ref={pesqProdFinalRef}
          selectNextField={selectNextFieldProdutoFinal}
          onChangeDescricao={setNomeProdutoFinal}
          mostrarAux={false}
        />
        {unidade && (
          <FixedField
            label={`Custo de 1 ${unidade}`}
            value={formatarValor(vlrCustoTotalProdPronto, 2, 2, true)}
            horizontal
            divClassName="pt-4"
          />
        )}
      </Row>
      <InsumosFichaTecnicaForm
        insumos={insumos}
        setInsumos={(novosInsumos) => {
          setVlrCustoTotalProdPronto(
            (novosInsumos ?? []).reduce(
              (cur, next) => cur + parseFloat(next.vlr_custo_total),
              0
            )
          );
          setInsumos(novosInsumos);
        }}
        pesqProdInsumoRef={pesqProdInsumoRef}
      />
    </ModalCadastroV2>
  );
};

export const InsumosFichaTecnicaForm = ({
  insumos,
  setInsumos,
  pesqProdInsumoRef,
}) => {
  const [tipoInsumo, setTipoInsumo] = useState(
    TIPOS_INSUMO_FICHA_TECNICA.INSUMO
  );
  const [idProdutoInsumo, setIdProdutoInsumo] = useState(null);
  const [nomeProdutoInsumo, setNomeProdutoInsumo] = useState("");
  const [unidadeInsumo, setUnidadeInsumo] = useState("");
  const [quantidadeInsumo, setQuantidadeInsumo] = useState(0);
  const [vlrCustoUnitInsumo, setVlrCustoUnitInsumo] = useState(0);
  const [vlrCustoTotalInsumo, setVlrCustoTotalInsumo] = useState(0);

  const quantidadeInsumoRef = useRef();

  const limparDadosInsumo = () => {
    setIdProdutoInsumo(null);
    setNomeProdutoInsumo("");
    setUnidadeInsumo("");
    setQuantidadeInsumo(0);
    setVlrCustoUnitInsumo(0);
    setVlrCustoTotalInsumo(0);
  };

  const handleSelectProdutoInsumo = ({
    idProduto: id,
    nomeProduto: nome,
    referencia,
    unidade,
    custoUe,
  }) => {
    if (idProdutoInsumo !== id) {
      setIdProdutoInsumo(id);
      setNomeProdutoInsumo(nome);
      setUnidadeInsumo(unidade);
      setVlrCustoUnitInsumo(custoUe);
      if (pesqProdInsumoRef.current) {
        pesqProdInsumoRef.current.setDescricao(nome);
        pesqProdInsumoRef.current.setId(String(id));
        pesqProdInsumoRef.current.setReferencia(String(referencia));
      }
    }
  };

  const handleSelectProdIntermediario = (v, s) => {
    if (v) {
      setIdProdutoInsumo(v);
      setNomeProdutoInsumo(s?.nome_produto);
      setUnidadeInsumo(s?.unidade);
      setVlrCustoUnitInsumo(parseFloat(s?.vlr_custo));
    } else {
      limparDadosInsumo();
    }
  };

  const handleSetTipoInsumo = (v) => {
    setTipoInsumo(v);
    limparDadosInsumo();
  };

  const selectNextFieldProdutoInsumo = () =>
    quantidadeInsumoRef.current && quantidadeInsumoRef.current.focus();

  useEffect(() => {
    setVlrCustoTotalInsumo(
      roundFloat(quantidadeInsumo * vlrCustoUnitInsumo, 2)
    );
  }, [quantidadeInsumo, vlrCustoUnitInsumo]);

  const handleAddInsumo = () => {
    if ([0, null, undefined].includes(idProdutoInsumo)) {
      showWarning(
        "Por favor, selecione um Produto",
        null,
        null,
        "async-produto"
      );
      return;
    }

    if (
      insumos.findIndex(
        (e) => e.id_produto === idProdutoInsumo && e.tipo === tipoInsumo
      ) > -1
    ) {
      showWarning(
        "O Produto informado já existe entre os insumos.",
        null,
        null,
        "async-produto"
      );
      return;
    }

    const payload = {
      uuid: uuidv4(),
      desc_tipo:
        tipoInsumo === TIPOS_INSUMO_FICHA_TECNICA.INSUMO
          ? "Insumo"
          : "Produto Intermediário",
      tipo: tipoInsumo,
      id_produto: idProdutoInsumo,
      nome_produto: nomeProdutoInsumo,
      unidade: unidadeInsumo,
      quantidade: quantidadeInsumo,
      vlr_custo_unit: vlrCustoUnitInsumo,
      vlr_custo_total: vlrCustoTotalInsumo,
    };
    setInsumos([...insumos, payload]);
    limparDadosInsumo();
    if (pesqProdInsumoRef.current) {
      pesqProdInsumoRef.current.clear();
      pesqProdInsumoRef.current.focus();
    }
  };

  const handleDeleteInsumo = (uuid) =>
    setInsumos(insumos.filter((e) => e.uuid !== uuid));

  const handleAlterarInsumo = (coluna, novoValor, row) => {
    const casasDecTest = coluna === "quantidade" ? 3 : 2;
    const valorAntesTest = roundFloat(parseFloat(row[coluna]), casasDecTest);
    const novoValorTest = roundFloat(parseFloat(novoValor), casasDecTest);
    if (valorAntesTest === novoValorTest) {
      return;
    }

    if (coluna === "quantidade") {
      row.quantidade = parseFloat(novoValor);
    } else if (coluna === "vlr_custo_unit") {
      row.vlr_custo_unit = parseFloat(novoValor);
    }

    row.vlr_custo_total = roundFloat(
      parseFloat(row.vlr_custo_unit) * parseFloat(row.quantidade),
      2
    );

    setInsumos(insumos.map((e) => (e.uuid === row.uuid ? row : e)));
  };

  const columns = [
    {
      dataField: "desc_tipo",
      text: "Tipo",
      align: "center",
    },
    { dataField: "id_produto", text: "Produto", align: "center" },
    { dataField: "nome_produto", text: "Nome", align: "left" },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      formatter: (c) => formatNumber(c, true, 3, true),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
      editable: true,
      editorType: "number",
      decimalPlaces: 3,
      onChange: handleAlterarInsumo,
      alwaysShowEditor: true,
    },
    { dataField: "unidade", text: "UN", align: "center" },
    {
      dataField: "vlr_custo_unit",
      text: "Custo Unit.",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
      editable: (c, row) => row.tipo === TIPOS_INSUMO_FICHA_TECNICA.INSUMO,
      editorType: "number",
      onChange: handleAlterarInsumo,
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_custo_total",
      text: "Custo Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      style: (c) => ({ color: parseFloat(c) <= 0 ? "red" : undefined }),
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDeleteInsumo(row.uuid)} />
      ),
    },
  ];

  return (
    <>
      <Divider>Insumos</Divider>
      <Row>
        <RadioGroup
          label="Tipo"
          value={tipoInsumo}
          onChange={handleSetTipoInsumo}
        >
          <RadioItem label="Insumo" value={TIPOS_INSUMO_FICHA_TECNICA.INSUMO} />
          <RadioItem
            label="Prod. Intermediário"
            value={TIPOS_INSUMO_FICHA_TECNICA.PROD_INTERMEDIARIO}
          />
        </RadioGroup>
        {tipoInsumo === TIPOS_INSUMO_FICHA_TECNICA.INSUMO ? (
          <PesqProduto
            mdIdent={3}
            mdDesc={7}
            md={8}
            onConfirm={handleSelectProdutoInsumo}
            ref={pesqProdInsumoRef}
            selectNextField={selectNextFieldProdutoInsumo}
            onChangeDescricao={setNomeProdutoInsumo}
            name={"async-produto"}
          />
        ) : (
          <AsyncComboBox
            md={8}
            label="Produto Intermediário"
            concatModelName="produto_intermediario"
            defaultValue={idProdutoInsumo}
            onChange={(s) => handleSelectProdIntermediario(s?.value, s)}
            defaultOptions
            inputName={"async-produto"}
          />
        )}
      </Row>
      <Row className="mb-2">
        <NumberInput
          md={2}
          label="Quantidade"
          value={quantidadeInsumo}
          onChange={setQuantidadeInsumo}
          ref={quantidadeInsumoRef}
          decimalPlaces={3}
        />
        <FixedField
          label="Unidade"
          value={unidadeInsumo}
          horizontal
          divClassName="pt-4"
        />
        <NumberInput
          md={2}
          label="Custo Unitário"
          value={vlrCustoUnitInsumo}
          onChange={setVlrCustoUnitInsumo}
        />
        <NumberInput
          md={2}
          label="Custo Total"
          value={vlrCustoTotalInsumo}
          disabled
        />
        <FormButton md="auto" color="info" onClick={handleAddInsumo}>
          Incluir
        </FormButton>
      </Row>
      <Table
        data={insumos}
        columns={columns}
        paginated={false}
        pageSize={8}
        keyField="uuid"
      />
    </>
  );
};
