import React, { useState } from "react";
import imagem1 from "../../../../assets/img/layouts_venda/1.png";
import imagem2 from "../../../../assets/img/layouts_venda/2.png";
import imagem3 from "../../../../assets/img/layouts_venda/3.png";
import imagem5 from "../../../../assets/img/layouts_venda/5.png";
import imagem6 from "../../../../assets/img/layouts_venda/6.png";
import imagem7 from "../../../../assets/img/layouts_venda/7.png";
import imagem8 from "../../../../assets/img/layouts_venda/8.png";
import imagem9 from "../../../../assets/img/layouts_venda/9.png";
import imagem10 from "../../../../assets/img/layouts_venda/10.png";
import imagem13 from "../../../../assets/img/layouts_venda/13.png";
import imagem14 from "../../../../assets/img/layouts_venda/14.png";
import imagem15 from "../../../../assets/img/layouts_venda/15.png";

import imagem100 from "../../../../assets/img/layouts_venda/100.png";
import imagem101 from "../../../../assets/img/layouts_venda/101.png";
import imagem102 from "../../../../assets/img/layouts_venda/102.png";
import imagem104 from "../../../../assets/img/layouts_venda/104.png";
import imagem105 from "../../../../assets/img/layouts_venda/105.png";
import imagem106 from "../../../../assets/img/layouts_venda/106.png";
import imagem107 from "../../../../assets/img/layouts_venda/107.png";
import imagem108 from "../../../../assets/img/layouts_venda/108.png";
import imagem109 from "../../../../assets/img/layouts_venda/109.png";
import imagem110 from "../../../../assets/img/layouts_venda/110.png";
import imagem111 from "../../../../assets/img/layouts_venda/111.png";
import imagem112 from "../../../../assets/img/layouts_venda/112.png";

import imagem200 from "../../../../assets/img/layouts_venda/200.png";
import imagem201 from "../../../../assets/img/layouts_venda/201.png";
import ImageViewer from "react-simple-image-viewer";
import { Divider } from "../../../../components/Divider";

const layouts = [
  {
    id: 1,
    imagem: imagem1,
    descricao: (
      <>
        <label>
          Layout de impressão de venda padrão. Único que considera ambientes.
        </label>
        <ul>
          <li>Mostra nome e telefone do atendente</li>
          <li>Mostra o desconto por produto</li>
          <li>Mostra o CNPJ e a I.E. da empresa</li>
          <li>
            Mostra o Valor Total de Serviços somente se a venda possuir serviços
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    imagem: imagem2,
    descricao: (
      <>
        <label>Layout de impressão de venda</label>
        <ul>
          <li>Mostra Referência do Produto</li>
          <li>Mostra o desconto total dos produtos</li>
          <li>Não mostra o desconto por produto</li>
          <li>Enfatiza a quantidade na primeira coluna</li>
          <li>Mostra o código do produto</li>
        </ul>
      </>
    ),
  },
  {
    id: 3,
    imagem: imagem3,
    descricao: (
      <>
        <label>Layout de impressão de venda simplificado</label>
        <ul>
          <li>Não mostra valor unitário/item dos produtos</li>
          <li>Enfatiza a quantidade na primeira coluna</li>
          <li>Espaço para assinatura do cliente</li>
        </ul>
      </>
    ),
  },
  {
    id: 5,
    imagem: imagem5,
    descricao: (
      <>
        <label>
          Layout utilizado para empresas que utilizam a impressão da venda para
          carregamento/expedição de produtos
        </label>
        <ul>
          <li>Sempre em 2 Vias</li>
          <li>Informações detalhadas da entrega e do cliente</li>
          <li>Não tem código do produto</li>
          <li>Não identifica nenhum tipo de desconto</li>
          <li>Mostra o Frete</li>
          <li>Quantidade do produto na primeira coluna</li>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    imagem: imagem6,
    descricao: (
      <>
        <label>
          Layout utilizado por empresas que necessitam evidenciar a referêcia do
          produto vendido
        </label>
        <ul>
          <li>Não imprime o logotipo da empresa</li>
          <li>Mostra o CNPJ e a I.E. do cliente</li>
          <li>Não identifica nenhum tipo de desconto</li>
          <li>Mostra o Frete</li>
          <li>Mostra a referência do produto</li>
          <li>Quantidade do produto na primeira coluna</li>
        </ul>
      </>
    ),
  },
  {
    id: 7,
    imagem: imagem7,
    descricao: (
      <>
        <label>Layout de impressão de venda</label>
        <ul>
          <li>Mostra o código do produto</li>
          <li>Mostra o CNPJ do cliente</li>
          <li>Não identifica nenhum tipo de desconto</li>
          <li>Quantidade do produto na primeira coluna</li>
          <li>Observações por item (quando houver)</li>
        </ul>
      </>
    ),
  },
  {
    id: 8,
    imagem: imagem8,
    descricao: (
      <>
        <label>Layout 8 de impressão de venda</label>
        <ul>
          <li>Mostra os dados detalhados da empresa</li>
          <li>Espaço para assinatura do cliente</li>
          <li>Mostra o desconto por produto</li>
          <li>Mostra o nome do atendente</li>
        </ul>
      </>
    ),
  },
  {
    id: 9,
    imagem: imagem9,
    descricao: (
      <>
        <label>Layout 9 de impressão de venda</label>
        <ul>
          <li>Mostra os dados detalhados da empresa</li>
          <li>Mostra a forma de pagamento da venda</li>
          <li>Mostra o nome do vendedor</li>
          <li>Mostra desconto por produto</li>
        </ul>
      </>
    ),
  },
  {
    id: 10,
    imagem: imagem10,
    descricao: (
      <>
        <label>Layout 10 de impressão de venda</label>
        <ul>
          <li>Mostra apenas o Logo da Empresa</li>
          <li>Dados do Cliente em fonte maior</li>
          <li>Mostra a forma de pagamento da venda</li>
          <li>Mostra desconto por produto</li>
        </ul>
      </>
    ),
  },
  {
    id: 13,
    imagem: imagem13,
    descricao: (
      <>
        <label>
          Layout utilizado por empresas que necessitam que o local de entrega
          esteja sempre visível na impressão da venda
        </label>
        <ul>
          <li>Mostra os dados detalhados da empresa</li>
          <li>Sempre imprime o local de entrega definido na venda</li>
          <li>Mostra espaço para assinatura do cliente</li>
        </ul>
      </>
    ),
  },
  {
    id: 14,
    imagem: imagem14,
    descricao: (
      <>
        <label>Layout 14 de impressão da venda</label>
        <ul>
          <li>Apresenta o desconto por produto e total</li>
          <li>Mostra o nome e telefone do atendente</li>
          <li>Mostra os dados detalhados da empresa</li>
        </ul>
      </>
    ),
  },
  {
    id: 15,
    imagem: imagem15,
    descricao: (
      <>
        <label>Layout 15 de impressão da venda</label>
        <ul>
          <li>Similar ao Layout 1.</li>
          <li>Mostra o CPF/CNPJ, Endereço, Nro, Complemento, Bairro, Cidade, UF do cliente</li>
          <li>Mostra a forma de pagamento e também Mensagens Fixas</li>
        </ul>
      </>
    ),
  },
  {
    id: 100,
    imagem: imagem100,
    descricao: (
      <>
        <label>Layout mais utilizado de impressão de venda em cupom</label>
        <ul>
          <li>Mostra toda a descrição do produto na venda</li>
          <li>Mostra o nome do vendedor</li>
          <li>Mostra os dados detalhados da empresa</li>
        </ul>
      </>
    ),
  },
  {
    id: 101,
    imagem: imagem101,
    descricao: (
      <>
        <label>Layout compacto de impressão de venda</label>
        <ul>
          <li>
            Tem limitações de espaço para identificação completa do nome do
            produto
          </li>
          <li>Não mostra os dados detalhados da empresa</li>
          <li>Não mostra o nome do vendedor</li>
          <li>Não mostra a forma de pagamento</li>
        </ul>
      </>
    ),
  },
  {
    id: 102,
    imagem: imagem102,
    descricao: (
      <>
        <label>Layout 102 de impressão de venda</label>
        <ul>
          <li>
            Tem limitações de espaço para identificação completa do nome do
            produto
          </li>
          <li>Mostra os dados detalhados da empresa</li>
          <li>Mostra o nome do vendedor</li>
          <li>Mostra a forma de pagamento</li>
        </ul>
      </>
    ),
  },
  {
    id: 104,
    imagem: imagem104,
    descricao: (
      <>
        <label>Layout 104 de impressão de venda</label>
        <ul>
          <li>
            Tem limitações de espaço para identificação completa do nome do
            produto
          </li>
          <li>Mostra os dados detalhados da empresa</li>
          <li>Mostra o nome do vendedor</li>
          <li>Não mostra a forma de pagamento</li>
        </ul>
      </>
    ),
  },
  {
    id: 105,
    imagem: imagem105,
    descricao: (
      <>
        <label>Layout 105 de impressão de venda</label>
        <ul>
          <li>Mostra toda a descrição do produto na venda</li>
          <li>Apresenta o desconto por produto e total</li>
          <li>Mostra o nome do vendedor</li>
          <li>Mostra a forma e moedas de pagamento</li>
          <li>Mostra total de itens, desconto e total geral</li>
        </ul>
      </>
    ),
  },
  {
    id: 106,
    imagem: imagem106,
    descricao: (
      <>
        <label>
          Layout 106 de impressão de venda, semelhante ao 105, adiciona somente
          a impressão do campo de assinatura do cliente
        </label>
        <ul>
          <li>Mostra toda a descrição do produto na venda</li>
          <li>Apresenta o desconto por produto e total</li>
          <li>Mostra o nome do vendedor</li>
          <li>Mostra a forma e moedas de pagamento</li>
          <li>Mostra total de itens, desconto e total geral</li>
          <li>Adiciona impressão do espaço para assinatura do cliente</li>
        </ul>
      </>
    ),
  },
  {
    id: 107,
    imagem: imagem107,
    descricao: (
      <>
        <label>Layout 107 de impressão de venda</label>
        <ul>
          <li>Mostra toda a descrição do produto na venda</li>
          <li>Mostra o nome do vendedor</li>
          <li>Mostra a forma e moedas de pagamento</li>
          <li>Não identifica nenhum tipo de desconto</li>
        </ul>
      </>
    ),
  },
  {
    id: 108,
    imagem: imagem108,
    descricao: (
      <>
        <label>Layout compacto de impressão de venda</label>
        <ul>
          <li>Não mostra os dados detalhados da empresa</li>
          <li>
            Tem limitações de espaço para identificação completa do nome do
            produto
          </li>
          <li>Mostra o nome do vendedor</li>
          <li>Mostra a forma e moedas de pagamento</li>
        </ul>
      </>
    ),
  },
  {
    id: 109,
    imagem: imagem109,
    descricao: (
      <>
        <label>
          Impressão extremamente compacta por conta do tamanho do papel
        </label>
      </>
    ),
  },
  {
    id: 110,
    imagem: imagem110,
    descricao: (
      <>
        <label>Layout 110 de impressão de venda</label>
        <ul>
          <li>Mostra os dados detalhados da empresa</li>
          <li>Mostra toda a descrição do produto na venda</li>
          <li>Mostra o nome do vendedor</li>
          <li>Não mostra a forma de pagamento</li>
          <li>Não identifica nenhum tipo de desconto</li>
        </ul>
      </>
    ),
  },
  {
    id: 111,
    imagem: imagem111,
    descricao: (
      <>
        <label>Layout 111 de impressão de venda</label>
        <ul>
          <li>Mostra os dados detalhados da empresa</li>
          <li>Mostra toda a descrição do produto na venda</li>
          <li>Mostra o nome do vendedor</li>
          <li>Mostra a forma de pagamento</li>
          <li>Sinaliza quando há itens devolvidos</li>
        </ul>
      </>
    ),
  },
  {
    id: 112,
    imagem: imagem112,
    descricao: (
      <>
        <label>Layout 112 de impressão de venda</label>
        <ul>
          <li>Mostra os dados detalhados da empresa</li>
          <li>Mostra toda a descrição do produto na venda</li>
          <li>Mostra o nome do vendedor</li>
          <li>Não mostra a forma de pagamento</li>
          <li>Não identifica nenhum tipo de desconto</li>
          <li>Mostra a NCM do Produto</li>
        </ul>
      </>
    ),
  },
  {
    id: 200,
    imagem: imagem200,
    descricao: (
      <>
        <label>
          Layout 200 de impressão de venda matricial em formulário contínuo
        </label>
        <ul>
          <li>Mostra os dados detalhados da empresa</li>
          <li>Não mostra a forma de pagamento</li>
          <li>Não identifica nenhum tipo de desconto</li>
        </ul>
      </>
    ),
  },
  {
    id: 201,
    imagem: imagem201,
    descricao: (
      <>
        <label>
          Layout 201 de impressão de venda matricial em formulário contínuo
        </label>
        <ul>
          <li>Não mostra dados detalhados da empresa</li>
          <li>Não mostra a forma de pagamento</li>
          <li>Mostra valor total de desconto</li>
          <li>Enfatiza informação da referência do produto</li>
          <li>Espaço para assinatura do cliente</li>
        </ul>
      </>
    ),
  },
];

const PrintDisplay = ({ layoutsList, openImageViewer }) => (
  <div className="print-display">
    {layoutsList.map((layout) => (
      <div className="print-item">
        <img
          src={layout.imagem}
          className="print-thumb"
          onClick={() => openImageViewer(layout.id)}
          alt=""
        />
        <div style={{ height: "min-content", marginLeft: "0.75rem" }}>
          <h5 className="mb-1">Layout {layout.id}</h5>
          <span>{layout.descricao}</span>
        </div>
      </div>
    ))}
  </div>
);

export const ImpressaoVenda = () => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [idImagem, setIndexImagem] = useState(0);

  const openImageViewer = (index) => {
    setIndexImagem(index);
    setIsViewerOpen(true);
  };

  const onCloseImageViewer = () => {
    setIndexImagem(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <Divider className="mt-2">Folha A4</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => item.id < 100)}
        openImageViewer={openImageViewer}
      />
      <Divider className="mt-2">Bobina 80mm</Divider>
      <PrintDisplay
        layoutsList={layouts.filter(
          (item) => item.id >= 100 && item.id < 200 && item.id !== 109
        )}
        openImageViewer={openImageViewer}
      />
      <Divider className="mt-2">Bobina 58mm</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => item.id === 109)}
        openImageViewer={openImageViewer}
      />
      <Divider className="mt-2">Matricial</Divider>
      <PrintDisplay
        layoutsList={layouts.filter(
          (item) => item.id >= 100 && item.id >= 200 && item.id < 300
        )}
        openImageViewer={openImageViewer}
      />
      {isViewerOpen && (
        <ImageViewer
          src={[layouts.find((layout) => layout.id === idImagem)?.imagem]}
          currentIndex={0}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={onCloseImageViewer}
          backgroundStyle={{ backgroundColor: "#00000085" }}
        />
      )}
    </>
  );
};
