import React, { useRef, useState } from "react";
import { ModalBase } from "./ModalBase";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_SENHA_MODAL, SHOW_SENHA_MODAL } from "../redux/constants";
import outsideStore from "../redux/store";
import { PasswordInput } from "./PasswordInput";
import { showWarning } from "./AlertaModal";
import { apiPostV2 } from "../apiV2";

export const showSenha = ({ title, onConfirm, hint }) => {
  outsideStore.dispatch({
    type: SHOW_SENHA_MODAL,
    title: title,
    hint: hint,
    onConfirm: onConfirm,
  });
};

export const validarSenha = ({ title, hint, nomeSenhaValidar, modalSize }) => {
  return new Promise((resolve) =>
    outsideStore.dispatch({
      type: SHOW_SENHA_MODAL,
      title: title,
      hint: hint,
      onConfirm: () => resolve(true),
      onCancel: () => resolve(false),
      nomeSenhaValidar: nomeSenhaValidar,
      modalSize: modalSize,
    })
  );
};

export const SenhaModalV2 = () => {
  const [senha, setSenha] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((state) => state.senha);
  const inputRef = useRef();

  const limparDados = () => setSenha("");

  const toggle = (cancelou = true) => {
    if (store.isOpen) {
      if (cancelou === true) {
        store.onCancel();
      }
      dispatch({ type: CLOSE_SENHA_MODAL });
    }
  };

  const onConfirmInternal = async () => {
    if (senha.length === 0) {
      showWarning(`Por favor, informe a Senha de ${store.title}`);
      return false;
    }
    setLoading(true);
    try {
      if (store.nomeSenhaValidar) {
        const payload = {
          nome_senha: store.nomeSenhaValidar,
          senha: senha,
        };
        const [ok] = await apiPostV2("cadastro/senha/validar_senha/", payload, {
          successMessage: false,
          focusOnAfterMessage: "input-senha-modal",
        });
        if (ok) {
          store.onConfirm();
          limparDados();
          toggle(false);
        } else {
          limparDados();
        }
      } else {
        if (await store.onConfirm(senha)) {
          toggle();
        } else {
          limparDados();
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBase
      isOpen={store.isOpen}
      toggle={toggle}
      size={store.modalSize || "sm"}
      title={`Informe a Senha de ${store.title}`}
      onConfirm={onConfirmInternal}
      onCancel={store.onCancel}
      loadingConfirm={loading}
      onClosed={limparDados}
      hideCancelButton
      onKeyDown={(e) => e.key === "Enter" && onConfirmInternal()}
    >
      {store.hint && store.hint}
      <PasswordInput
        value={senha}
        onChange={setSenha}
        ref={inputRef}
        name="input-senha-modal"
      />
    </ModalBase>
  );
};
