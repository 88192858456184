import { docPost } from "../../../../pdf";

export const imprimirEtqL42 = async (etiquetas) => {
  try {
    let etq = etiquetas.reduce(
      (acc, e) => [...acc, ...Array(e.qtd_etiquetas).fill(e)],
      []
    );

    const ret = await docPost(
      "/etiquetas/layout_42/",
      etq,
      '0073',
      true
    )
    if (!ret) {
      return [false, null];
    }

    return [true, ret];
  } catch (err) {
    return [false, err.message];
  }
};
