import React from "react";
import { Divider, Table } from "../../../../../components";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
} from "../../../../../coreUtils";

const columns = [
  {
    dataField: "nome_produto_final",
    text: "Produto Final",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_produto_final),
    sortable: true,
  },
  { dataField: "unidade", text: "UN", align: "center", headerAlign: "center" },
  {
    dataField: "qtd_total_produzida",
    text: "Qtd. Produzida",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_custo_insumos",
    text: "Custo de Insumos",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_venda_produzido",
    text: "Vlr. Venda Produzido",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
];

const columnsInsumos = [
  {
    dataField: "nome_produto_insumo",
    text: "Produto",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_produto_insumo),
  },
  { dataField: "unidade", text: "UN", align: "center", headerAlign: "center" },
  {
    dataField: "qtd_utilizada",
    text: "Qtd. Utilizada",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_custo_total",
    text: "Custo Total",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const columnsPerdas = [
  {
    dataField: "nome_produto_insumo",
    text: "Produto",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_produto_insumo),
  },
  { dataField: "unidade", text: "UN", align: "center", headerAlign: "center" },
  {
    dataField: "qtd_perda",
    text: "Qtd. Perda",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_custo_total",
    text: "Custo Total",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const ProducaoPeriodoGrid = ({ dados }) => {
  return (
    <Table
      keyField="id_produto_final"
      data={dados}
      columns={columns}
      canExpand
      expandedAreaBuilder={(row) => (
        <>
          <Divider
            className="mt-0"
            textStyle={{ color: "white" }}
            lineStyle={{ borderTop: "1px solid #dee2e6" }}
          >
            Insumos
          </Divider>
          <Table
            data={row.insumos}
            columns={columnsInsumos}
            pageSize={row.insumos.length}
            growIntoPageSize
            showRegisterCount={false}
          />
          <Divider
            textStyle={{ color: "white" }}
            lineStyle={{ borderTop: "1px solid #dee2e6" }}
          >
            Perdas
          </Divider>
          <Table
            data={row.perdas}
            columns={columnsPerdas}
            pageSize={row.perdas.length}
            growIntoPageSize
            showRegisterCount={false}
          />
        </>
      )}
    />
  );
};
