import moment from "moment";
import React, { useState } from "react";
import { Row } from "reactstrap";
import { RadioGroup, TextInput } from "../../../../../components";
import { DatePicker } from "../../../../../components/DatePicker";
import { MODAL_ACTIONS, formatDateISO } from "../../../../../coreUtils";
import {
  modalTitleRegraComissao,
  routesBaseRegraComissao,
} from "../RegrasComissao";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { RadioItem } from "../../../../../components/RadioGroup";
import { showWarning } from "../../../../../components/AlertaModal";

export const CadastroRegrasComissaoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [nome, setNome] = useState("");
  const [regraPara, setRegraPara] = useState("PRODU");
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());

  const limparDados = () => {
    setNome("");
    setRegraPara("PRODU");
    setDataIni(new Date());
    setDataFim(new Date());
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setRegraPara(data.tipo);
    setDataIni(moment(data.data_ini, "DD/MM/YYYY").toDate());
    setDataFim(moment(data.data_final, "DD/MM/YYYY").toDate());
  };

  const submitPayload = (action) => {
    if (!(dataIni instanceof Date)) {
      showWarning(
        "Por favor, revise a data de início.",
        null,
        null,
        "input-data-ini-regra"
      );
      return false;
    }
    if (!(dataFim instanceof Date)) {
      showWarning(
        "Por favor, revise a data de fim.",
        null,
        null,
        "input-data-fim-regra"
      );
      return false;
    }

    const payload = {
      nome: nome,
      data_inicio: formatDateISO(dataIni),
      data_fim: formatDateISO(dataFim),
    };

    return action === MODAL_ACTIONS.ADD
      ? { ...payload, tipo: regraPara }
      : { ...payload, id: parseInt(selected) };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleRegraComissao}
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseRegraComissao}
      number="0038_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row className="mb-3">
        <TextInput
          label="Nome"
          onChange={(e, v) => setNome(v)}
          value={nome}
          maxLength={60}
          autoFocus
        />
      </Row>
      <Row>
        <RadioGroup
          label="Regra Para:"
          value={regraPara}
          onChange={setRegraPara}
          divClassName="mb-2"
          disabled={action === MODAL_ACTIONS.EDIT}
        >
          <RadioItem label="Produto" value="PRODU" />
          <RadioItem label="Grupo" value="GRUPO" />
          <RadioItem label="Fabricante" value="FABRI" />
          <RadioItem label="Vendedor" value="VENDE" />
          <RadioItem label="Escalonada de Produto" value="ESCAL" />
          <RadioItem label="Grupo de Produtos + Vendedor" value="GRUVE" />
        </RadioGroup>
      </Row>
      <Row>
        <DatePicker
          label="Início"
          md={3}
          value={dataIni}
          onChange={(v) => setDataIni(moment.isMoment(v) ? v.toDate() : v)}
          name={"input-data-ini-regra"}
        />
        <DatePicker
          label="Fim"
          md={3}
          value={dataFim}
          onChange={(v) => setDataFim(moment.isMoment(v) ? v.toDate() : v)}
          name={"input-data-fim-regra"}
        />
      </Row>
    </ModalCadastroV2>
  );
};
