import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroFichaTecnicaModal } from "./components/CadastroFichaTecnicaModal";
import { FichaTecnicaGrid } from "./components/FichaTecnicaGrid";
import {
  BotaoPesquisar,
  FormCheckbox,
  PageContainer,
  SearchInput,
} from "../../../../components";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import {
  MODAL_ACTIONS,
  defaultDebounceTime,
  useDebounce,
} from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";
import { DuplicarFichaTecnicaModal } from "./components/DuplicarFichaTecnicaModal";

export const modalTitleFichaTecnica = "Ficha Técnica";
export const routesBaseFichaTecnica = "/industria/ficha_tecnica";

export const FichaTecnica = () => {
  const [pista, setPista] = useState("");
  const [mostrarInativas, setMostrarInativas] = useState(false);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);
  const [duplicarOpen, setDuplicarOpen] = useState(false);

  const buscarDados = async (p) => {
    const [ok, ret] = await apiGetV2(`${routesBaseFichaTecnica}/listar/`, {
      pista: p,
      mostrar_inativas: mostrarInativas,
    });
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = useDebounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const toggleDuplicar = () => setDuplicarOpen(!duplicarOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  const duplicar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleDuplicar();
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Ficha Técnica" number="0092" canGoBack>
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <FormCheckbox
            label="Mostrar Inativas"
            checked={mostrarInativas}
            onChange={() => setMostrarInativas(!mostrarInativas)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroFichaTecnicaModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleFichaTecnica}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0092_2"
            selected={selected}
            routeBase={routesBaseFichaTecnica}
            notifyEvent={notifyEvent}
          />
          <DuplicarFichaTecnicaModal
            isOpen={duplicarOpen}
            toggle={toggleDuplicar}
            selected={selected}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <FichaTecnicaGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
          duplicar={duplicar}
        />
      </Card>
    </PageContainer>
  );
};
