import classnames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Card, Nav, NavItem, NavLink, TabContent } from "reactstrap";
import { objectIsEmpty } from "../../../../../coreUtils";
import service from "../../../../../services/ordem_servico/VistoriaTecnicaService";
import {
  addLocalInstalacao,
  reset as resetLocalInst,
  setCondicao,
  setIdade,
  setLocalInstalacaoModulos,
  setMaterialEstrutura,
  setNivelamentoSolo,
} from "../store/local_instalacao_modulos_slice";
import {
  addFoto,
  reset as resetVistoria,
  setAlturaModulo,
  setAmperagemDisjuntorGeral,
  setAmperagemDisjuntorMedicao,
  setBairroCliente,
  setBitolaCabo,
  setCepCliente,
  setCidadeCliente,
  setCidadeUfCliente,
  setComplementoCliente,
  setComprimentoModulo,
  setConcessionaria,
  setCondicaoEntrada,
  setDataVisita,
  setDemandaContratada,
  setDistanciaInversorMedidor,
  setDistanciaModuloStringBox,
  setDistanciaStringBoxInversor,
  setEnderecoCliente,
  setFoneCliente,
  setFoneResponsavel,
  setFoneSolicitante,
  setHoraVisita,
  setIdCliente,
  setIdOS,
  setIdVistoria,
  setLarguraModulo,
  setLocalFixacaoInversor,
  setLocalStringBox,
  setModeloInversor,
  setNomeCliente,
  setNumEndCliente,
  setNumeroTransformador,
  setObservInversorStringBox,
  setPontoInternet,
  setPotenciaInversor,
  setPotenciaModulo,
  setQuantidadeInversor,
  setQuantidadeModulos,
  setReferenciaCliente,
  setResponsavel1Vistoria,
  setResponsavel2Vistoria,
  setResponsavelCliente,
  setSolicitanteCliente,
  setTensaoNominal,
  setTipoCliente,
  setTipoComunicacaoInternet,
  setTipoDemanda,
  setTipoLigacao,
  setTipoRamal,
  setUC,
  setUCCompensacao,
} from "../store/vistoria_slice";
import { DadosOS } from "./DadosOS";
import { TabConcessionaria } from "./TabConcessionaria";
import { TabDetalhamentoLocais } from "./TabDetalhamentoLocais";
import { TabFotos } from "./TabFotos";
import { TabInfoCliente } from "./TabInfoCliente";
import { TabInversorStringBox } from "./TabInversorStringBox";
import { TabLocalInstalacao } from "./TabLocalInstalacao";
import { TabPadraoEntrada } from "./TabPadraoEntrada";
import { TabProposta } from "./TabProposta";

export const CadastroVistoriaContainer = () => {
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const changeTab = (tab) => setActiveTab(tab);

  const carregarDadosInclusao = useCallback(async () => {
    const ret = await service.buscarDadosOS(location.state.idOS);
    if (objectIsEmpty(ret)) {
      history.goBack();
    } else {
      dispatch(setIdOS(location.state.idOS));
      dispatch(setIdCliente(ret["id_cliente"]));
      dispatch(setNomeCliente(ret["id_cliente__nome"]));
      dispatch(setTipoCliente(ret["id_cliente__pessoa"]));
      dispatch(setEnderecoCliente(ret["id_cliente__endereco"]));
      dispatch(setNumEndCliente(ret["id_cliente__numero"]));
      dispatch(setComplementoCliente(ret["id_cliente__complemento"]));
      dispatch(setBairroCliente(ret["id_cliente__bairro"]));
      dispatch(setCidadeCliente(ret["id_cliente__cidade__nome"]));
      dispatch(setCidadeUfCliente(ret["id_cliente__cidade__uf"]));
      dispatch(setCepCliente(ret["id_cliente__cep"]));
      dispatch(setReferenciaCliente(ret["id_cliente__referencia"]));
      dispatch(setFoneCliente(ret["id_cliente__fone1"]));
    }
  }, [location.state.idOS, dispatch, history]);

  const carregarDadosAlteracao = useCallback(async () => {
    const ret = await service.buscarDadosVistoria(location.state.idVistoria);
    if (objectIsEmpty(ret)) {
      history.goBack();
    } else {
      // Dados da O.S.
      dispatch(setIdOS(ret["id_ord_serv"]));
      const ret2 = await service.buscarDadosOS(ret["id_ord_serv"]);
      if (objectIsEmpty(ret)) {
        history.goBack();
      } else {
        dispatch(setIdOS(ret["id_ord_serv"]));
        dispatch(setIdCliente(ret2["id_cliente"]));
        dispatch(setNomeCliente(ret2["id_cliente__nome"]));
        dispatch(setTipoCliente(ret2["id_cliente__pessoa"]));
        dispatch(setEnderecoCliente(ret2["id_cliente__endereco"]));
        dispatch(setNumEndCliente(ret2["id_cliente__numero"]));
        dispatch(setComplementoCliente(ret2["id_cliente__complemento"]));
        dispatch(setBairroCliente(ret2["id_cliente__bairro"]));
        dispatch(setCidadeCliente(ret2["id_cliente__cidade__nome"]));
        dispatch(setCidadeUfCliente(ret2["id_cliente__cidade__uf"]));
        dispatch(setCepCliente(ret2["id_cliente__cep"]));
        dispatch(setReferenciaCliente(ret2["id_cliente__referencia"]));
        dispatch(setFoneCliente(ret2["id_cliente__fone1"]));
      }
      dispatch(setIdVistoria(location.state.idVistoria));

      // Dados do Cliente/Vistoria
      dispatch(setUC(ret["nro_uc"]));
      dispatch(setResponsavelCliente(ret["nome_resp"]));
      dispatch(setFoneResponsavel(ret["fone_resp"]));
      dispatch(setSolicitanteCliente(ret["nome_solic"]));
      dispatch(setFoneSolicitante(ret["fone_solic"]));
      dispatch(setUCCompensacao(ret["uc_compensacao"]));
      dispatch(setDemandaContratada(ret["demanda_contratada"] ? "SIM" : "NAO"));
      dispatch(setTipoDemanda(ret["tipo_demanda"]));
      if (ret["visitas"].length > 0) {
        const visitas = ret["visitas"];
        dispatch(setResponsavel1Vistoria(visitas[0]["id_tecnico"]));
        dispatch(setDataVisita(new Date(visitas[0]["data"])));
        dispatch(setHoraVisita(visitas[0]["hora"]));
        if (visitas.length > 1) {
          dispatch(setResponsavel2Vistoria(visitas[1]["id_tecnico"]));
        }
      }

      // Dados padrão de entrada
      if (ret["padroes_ent"].length > 0) {
        const padrao_entr = ret["padroes_ent"][0];
        dispatch(setConcessionaria(padrao_entr["concessionaria"]));
        dispatch(setTipoLigacao(padrao_entr["tipo_ligacao"]));
        dispatch(setTensaoNominal(padrao_entr["tensao_nominal"]));
        if (isNaN(parseFloat(padrao_entr["disjuntor_entr_medicao"]))) {
          dispatch(setAmperagemDisjuntorMedicao(0));
        } else {
          dispatch(
            setAmperagemDisjuntorMedicao(
              parseFloat(padrao_entr["disjuntor_entr_medicao"])
            )
          );
        }
        if (isNaN(parseFloat(padrao_entr["disjuntor_entr_geral"]))) {
          dispatch(setAmperagemDisjuntorGeral(0));
        } else {
          dispatch(
            setAmperagemDisjuntorGeral(
              parseFloat(padrao_entr["disjuntor_entr_geral"])
            )
          );
        }
        dispatch(setCondicaoEntrada(padrao_entr["condicao_entr"]));
        if (isNaN(parseFloat(padrao_entr["bitola_cabo_ramal"]))) {
          dispatch(setBitolaCabo(0));
        } else {
          dispatch(setBitolaCabo(parseFloat(padrao_entr["bitola_cabo_ramal"])));
        }
        dispatch(setTipoRamal(padrao_entr["tipo_ramal"]));
        dispatch(setNumeroTransformador(padrao_entr["nro_transformador"]));
      }

      // Dados local de instalação de módulos
      if (ret["locais_inst"].length > 0) {
        const detalhamento_locais = [];
        const local_inst_modulo = ret["locais_inst"][0];
        dispatch(setLocalInstalacaoModulos(local_inst_modulo["tipo"]));
        dispatch(setIdade(local_inst_modulo["idade"]));
        dispatch(setCondicao(local_inst_modulo["condicao_estrutura"]));
        dispatch(setMaterialEstrutura(local_inst_modulo["material_estrutura"]));
        dispatch(setNivelamentoSolo(local_inst_modulo["nivelamento_solo"]));

        detalhamento_locais.push(...local_inst_modulo["detalhamento"]);

        detalhamento_locais.forEach((det) =>
          dispatch(
            addLocalInstalacao({
              descricao: det["descricao"],
              orientacao: det["orientacao"],
              inclinacao: det["inclinacao"],
              largura: parseFloat(det["dimensao_larg"]),
              comprimento: parseFloat(det["dimensao_comp"]),
              tipoTelha: det["tipo_telha"],
              distanciaRipas: parseFloat(det["distanc_ripas"]),
              distanciaCaibros: parseFloat(det["distanc_caibros"]),
              distanciaTercas: parseFloat(det["distanc_tercas"]),
              distanciaVigasMetalicas: parseFloat(
                det["distanc_vigas_metalicas"]
              ),
              alturaTelhado: parseFloat(det["altura_telhado"]),
              acesso: det["acesso"],
              fotoCroqui: det["foto_croqui_base64"],
            })
          )
        );
      }

      // Inversor e String Box
      if (ret["inversores"].length > 0) {
        const inversor_string_box = ret["inversores"][0];
        dispatch(
          setLocalFixacaoInversor(inversor_string_box["local_fixa_inversor"])
        );
        dispatch(
          setLocalStringBox(inversor_string_box["local_fixa_stringbox"])
        );
        dispatch(
          setDistanciaModuloStringBox(
            parseFloat(inversor_string_box["distanc_modulos_stringbox"])
          )
        );
        dispatch(
          setDistanciaStringBoxInversor(
            parseFloat(inversor_string_box["distanc_stringbox_inversor"])
          )
        );
        dispatch(
          setDistanciaInversorMedidor(
            parseFloat(inversor_string_box["distanc_inversor_medidor"])
          )
        );
        dispatch(
          setPontoInternet(inversor_string_box["tem_ponto_net"] ? "SIM" : "NAO")
        );
        dispatch(
          setTipoComunicacaoInternet(inversor_string_box["tipo_conexao_net"])
        );
        dispatch(setObservInversorStringBox(inversor_string_box["observacao"]));
      }

      if (ret["sists_propostos"].length > 0) {
        const sist_proposto = ret["sists_propostos"][0];
        dispatch(setQuantidadeModulos(sist_proposto["qtd_modulos"]));
        dispatch(setPotenciaModulo(sist_proposto["potencia_modulo"]));
        dispatch(setLarguraModulo(parseFloat(sist_proposto["largura_modulo"])));
        dispatch(
          setComprimentoModulo(parseFloat(sist_proposto["comprimento_modulo"]))
        );
        dispatch(setAlturaModulo(parseFloat(sist_proposto["altura_modulo"])));
        dispatch(setQuantidadeInversor(sist_proposto["qtd_inversor"]));
        dispatch(setModeloInversor(sist_proposto["modelo_inversor"]));
        dispatch(setPotenciaInversor(sist_proposto["potencia_inversor"]));
      }

      const _fotos = ret["fotos"];
      _fotos.forEach((element) =>
        dispatch(
          addFoto({
            id: element.id,
            nome: element.nome_arquivo,
            obs: element.obs,
            base64: element.base64,
          })
        )
      );
    }
  }, [location.state, dispatch, history]);

  useEffect(() => {
    dispatch(resetVistoria());
    dispatch(resetLocalInst());
    if (location.state.action === "INC") {
      carregarDadosInclusao();
    } else {
      carregarDadosAlteracao();
    }
  }, [location.state, carregarDadosInclusao, carregarDadosAlteracao, dispatch]);

  return (
    <Card body>
      <DadosOS />
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => changeTab("1")}
          >
            Dados Cliente
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => changeTab("2")}
          >
            Concessionária
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => changeTab("3")}
          >
            Padrão de Entrada
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "4" })}
            onClick={() => changeTab("4")}
          >
            Instalação Módulos
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "5" })}
            onClick={() => changeTab("5")}
          >
            Detalhe Instalação
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "6" })}
            onClick={() => changeTab("6")}
          >
            Inversor e String Box
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "7" })}
            onClick={() => changeTab("7")}
          >
            Sistema Proposto
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "8" })}
            onClick={() => changeTab("8")}
          >
            Fotos
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabInfoCliente />
        <TabConcessionaria />
        <TabPadraoEntrada />
        <TabLocalInstalacao />
        <TabDetalhamentoLocais />
        <TabInversorStringBox />
        <TabProposta />
        <TabFotos />
      </TabContent>
    </Card>
  );
};
