import React from "react";
import { Divider, MoreDropdown, Table } from "../../../../../components";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import { DropdownItem } from "reactstrap";

export const GerencCredRotativoGrid = ({
  dados,
  onSelect,
  alterarLimiteCred,
  lancamentoManualCredito,
  abrirDetalhesConta,
  msgTotal,
  excluirConta,
}) => {
  const columns = [
    {
      dataField: "id_cliente",
      text: "Cliente",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "nome_cliente",
      text: "Nome",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "telefone_cliente",
      text: "Telefone",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_credito",
      text: "Crédito",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "limite_cred",
      text: "Limite de Crédito",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_tot_pend",
      text: "Total em Aberto",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_saldo_disp",
      text: "Saldo Disponível",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={() => abrirDetalhesConta(row.id_cliente)}>
            Detalhes
          </DropdownItem>
          <Divider />
          <DropdownItem onClick={() => alterarLimiteCred(row.id_cliente)}>
            Alterar Limite de Crédito
          </DropdownItem>
          <DropdownItem onClick={() => lancamentoManualCredito(row.id_cliente)}>
            Lançamento Manual
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => excluirConta(row.id_cliente)}>
            Excluir Conta
          </DropdownItem>
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table
      keyField="id_cliente"
      columns={columns}
      data={dados}
      onSelect={onSelect}
      recordCountFormatter={() => msgTotal}
    />
  );
};
