import unidecode from "unidecode";
import { apiGetV2, apiPostV2 } from "../../apiV2";
import { docPost } from "../../pdf";
import moment from "moment";
import { buscarDadosEmpresaCookies, viewDownloadPDF } from "../../coreUtils";

const URL_BASE = "/industria/ordem_producao";

const OrdemProducaoService = {
  urlBase: URL_BASE,
  buscar: (idOrdemProducao) =>
    apiGetV2(`${URL_BASE}/buscar/${idOrdemProducao}/`),
  buscarCustoInsumoPerda: (idOrdemProducao, params) =>
    apiGetV2(
      `${URL_BASE}/buscar_custo_insumo_perda/${idOrdemProducao}/`,
      params
    ),
  finalizar: (payload) => apiPostV2(`${URL_BASE}/finalizar/`, payload),
  gerarOrdemProducao: (payload) =>
    apiPostV2(`${URL_BASE}/gerar_ordem_producao/`, payload),
  imprimirSeparacao: async (idsOrdensProducao) => {
    const [ok, dados] = await apiGetV2(`${URL_BASE}/listar_dados_separacao/`, {
      id_ordem_producao: idsOrdensProducao,
    });

    if (!ok) {
      return false;
    }

    const arq = await docPost(
      "/industria/ordem_producao/separacao/",
      {
        dados_empresa: await buscarDadosEmpresaCookies(),
        dados: dados,
      },
      {
        errorMessage: "Erro ao Imprimir Separação da Ordem de Produção",
      }
    );

    if (!arq) return false;

    const fileName = `SEP_ORDEM_PRODUCAO_${moment(new Date()).format(
      "YYYYMMDD"
    )}`;

    viewDownloadPDF(arq, fileName);
  },
  imprimirTalao: async (idOrdemProducao) => {
    const [ok, dados] = await OrdemProducaoService.buscar(idOrdemProducao);

    if (!ok) {
      return false;
    }

    const arq = await docPost(
      "/industria/ordem_producao/talao/",
      {
        dados_empresa: await buscarDadosEmpresaCookies(),
        dados: dados,
      },
      {
        errorMessage: "Erro ao Imprimir Talão da Ordem de Produção",
      }
    );

    if (!arq) return false;

    const fileName = `ORDEM_PRODUCAO_${idOrdemProducao}_${unidecode(
      dados.nome_produto_final.replaceAll(" ", "_")
    )}_${moment(new Date()).format("YYYYMMDD")}`;

    viewDownloadPDF(arq, fileName);
  },
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  listarDadosSeparacao: (params) =>
    apiGetV2(`${URL_BASE}/listar_dados_separacao/`, params),
};

export default OrdemProducaoService;
