import React, { useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import {
  defaultDebounceTime,
  MODAL_ACTIONS,
  roundNumber,
  useDebounce,
} from "../../../../../coreUtils";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { showInfo } from "../../../../../components/AlertaModal";
import { Row } from "reactstrap";
import PesqProduto from "../../../../../components/form/pesq_produto/PesqProduto";
import {
  Divider,
  FormButton,
  NumberInput,
  TextInput,
  UnlockToEdit,
} from "../../../../../components";
import { ItensKitGrid } from "./ItensKitGrid";
import { modalTitleKitProdutos, routesBaseKitsProdutos } from "../Kit";
import UteisService from "../../../../../services/uteis/UteisService";

export const CadastroKitModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  // Dados do Kit
  const [descricao, setDescricao] = useState(null);
  const [itens, setItens] = useState([]);
  const [vlrTotalKit, setVlrTotalKit] = useState(0);

  // Inclusão de itens
  const [idProdutoInc, setIdProdutoInc] = useState(null);
  const [nomeProdutoInc, setNomeProdutoInc] = useState(null);
  const [qtdeItem, setQtdeItem] = useState(1);
  const [percDesc, setPercDesc] = useState(0);
  const [vlrUnitProd, setVlrUnitProd] = useState(0);
  const [vlrItemProd, setVlrItemProd] = useState(0);
  const [casasDecimaisQtdProd, setCasasDecimaisQtdProd] = useState(2);
  const [casasDecimaisVlrsProd, setCasasDecimaisVlrsProd] = useState(4);

  const pesqProdRef = useRef();

  const limparDados = () => {
    setDescricao("");
    setItens([]);
    setVlrTotalKit(0);
    limparIncluirItem(false);
  };

  const handleSelectProduto = ({
    idProduto,
    nomeProduto,
    referencia,
    precoVenda,
    casasDecimaisQtd,
    casasDecimaisVlrs,
  }) => {
    setIdProdutoInc(idProduto);
    setNomeProdutoInc(nomeProduto);
    setVlrItemProd(precoVenda);
    setVlrUnitProd(precoVenda);
    setCasasDecimaisQtdProd(casasDecimaisQtd);
    setCasasDecimaisVlrsProd(casasDecimaisVlrs);
    if (pesqProdRef.current) {
      pesqProdRef.current.setDescricao(nomeProduto);
      pesqProdRef.current.setId(String(idProduto));
      pesqProdRef.current.setReferencia(String(referencia));
    }
  };

  const fetchData = (data) => {
    setDescricao(data.descricao);
    const itensLoad = data.itens.map((e) => ({
      ...e,
      quantidade: parseFloat(e.qtd_prod),
      perc_desc: parseFloat(e.perc_desc),
      vlr_unit: parseFloat(e.vlr_unit),
      vlr_item: parseFloat(e.vlr_item),
    }));
    setItens(itensLoad);
    recalcularTotalKit(itensLoad);
  };

  const submitPayload = (action) => {
    const payload = {
      descricao: descricao,
      itens: itens.map((e) => ({
        id_produto: e.id_produto,
        quantidade: e.quantidade,
        perc_desc: e.perc_desc,
        vlr_unit: e.vlr_unit,
        vlr_item: e.vlr_item,
      })),
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id_kit: selected, ...payload };
  };

  const limparIncluirItem = (setarFoco) => {
    setIdProdutoInc(null);
    setNomeProdutoInc(null);
    setQtdeItem(1);
    setPercDesc(0);
    setVlrUnitProd(0);
    setVlrItemProd(0);
    setCasasDecimaisQtdProd(2);
    setCasasDecimaisVlrsProd(4);
    if (setarFoco && pesqProdRef.current) {
      pesqProdRef.current.clear();
      pesqProdRef.current.focus();
    }
  };

  const incluirItem = async () => {
    if ([0, null, undefined].includes(idProdutoInc)) {
      showInfo("Por favor, informe o Produto");
      pesqProdRef.current.focus();
      return false;
    }

    if ([0, null, undefined].includes(qtdeItem)) {
      showInfo("Por favor, informe a quantidade do produto no Kit");
      return false;
    }

    const item = itens.filter((e) => e.id_produto === idProdutoInc);

    if (item.length > 0) {
      showInfo("O produto informado já está presente no kit");
      pesqProdRef.current.focus();
      return;
    }

    const itensNovos = [
      ...itens,
      {
        id: String(uuidv4()),
        id_produto: idProdutoInc,
        nome_produto: nomeProdutoInc,
        quantidade: qtdeItem ?? 0,
        perc_desc: percDesc ?? 0,
        vlr_unit: vlrUnitProd ?? 0,
        vlr_item: vlrItemProd ?? 0,
        casas_decimais_qtd: casasDecimaisQtdProd || 2,
        casas_decimais_vlrs: casasDecimaisVlrsProd || 4,
      },
    ];
    setItens(itensNovos);
    recalcularTotalKit(itensNovos);
    limparIncluirItem(true);
  };

  const excluirItem = (idExcluir) => {
    const itensNovos = itens.filter((e) => e.id !== idExcluir);

    setItens([...itensNovos]);
    recalcularTotalKit(itensNovos);
  };

  const calcularValores = (campo, valor) => {
    if (campo === "P") {
      const vlrItemCalc = roundNumber(
        vlrUnitProd - vlrUnitProd * (valor / 100),
        casasDecimaisVlrsProd || 4
      );
      setVlrItemProd(vlrItemCalc);
    } else {
      if (valor >= vlrUnitProd) {
        setPercDesc(0);
        return;
      }

      const novoPercDesc = roundNumber((1 - valor / vlrUnitProd) * 100, 2);
      setPercDesc(novoPercDesc);
    }
  };

  const recalcularTotalKit = (itensNovos = []) => {
    setVlrTotalKit(
      roundNumber(
        itensNovos.reduce(
          (prev, cur) =>
            prev + parseFloat(cur.quantidade) * parseFloat(cur.vlr_item),
          0
        ),
        2
      )
    );
  };

  const calcularProporcionalItens = useDebounce(async (vlrTotal) => {
    const payload = {
      vlr_total: vlrTotal,
      itens: itens.map((e) => ({
        tipo: "P",
        id_item: e.id_produto,
        quantidade: e.quantidade,
        vlr_unit: e.vlr_unit,
      })),
    };
    const [ok, ret] = await UteisService.calcularVlrItensProporcionalTotal(
      payload
    );
    if (ok) {
      setItens(
        ret.map((item) => {
          const itemAtual = itens.find((e) => e.id_produto === item.id_item);

          return {
            ...itemAtual,
            perc_desc: parseFloat(item.perc_desc),
            vlr_item: parseFloat(item.vlr_item),
          };
        })
      );
    }
  }, defaultDebounceTime);

  const handleChangeTotalKit = (novoTotal) => {
    setVlrTotalKit(novoTotal);

    calcularProporcionalItens(novoTotal);
  };

  const atualizarQtdItem = (row, qtdNova) => {
    const novosItens = itens.map((item) => {
      if (row.id_produto === item.id_produto) {
        return {
          ...item,
          quantidade: qtdNova,
        };
      }
      return item;
    });
    setItens(novosItens);
    recalcularTotalKit(novosItens);
  };

  const atualizarValorItem = (row, valorNovo) => {
    const novosItens = itens.map((item) => {
      if (row.id_produto === item.id_produto) {
        let novoPercDesc = 0;
        if (row.vlr_unit > 0) {
          novoPercDesc = roundNumber((1 - valorNovo / item.vlr_unit) * 100, 2);
        }
        return {
          ...item,
          vlr_item: valorNovo,
          perc_desc: novoPercDesc,
        };
      }
      return item;
    });
    setItens(novosItens);
    recalcularTotalKit(novosItens);
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleKitProdutos}
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseKitsProdutos}
      number="0139_1"
      selected={selected}
      notifyEvent={notifyEvent}
      numberStyle={{ marginRight: "7px" }}
      paddedButtons
      footerActions={
        <UnlockToEdit
          locked={itens.length === 0}
          lockedHint="Por favor, informe ao menos um item no kit"
          isButton
          buttonLabel="Definir Total do Kit"
          hint=""
        >
          <NumberInput
            md={4}
            divClassName="mr-auto"
            label="Total do Kit"
            value={vlrTotalKit}
            onChange={handleChangeTotalKit}
          />
        </UnlockToEdit>
      }
    >
      <Row>
        <TextInput
          md={12}
          label="Descricao"
          value={descricao}
          onChange={(_, v) => setDescricao(v)}
        />
      </Row>
      <Divider>Itens</Divider>
      <Row>
        <PesqProduto
          md={12}
          mdIdent={3}
          mdDesc={7}
          mdIdentAux={2}
          onConfirm={handleSelectProduto}
          ref={pesqProdRef}
          podeAlterarNomeProdCuringa={false}
        />
      </Row>
      <Row>
        <NumberInput
          md={3}
          label="Quantidade"
          value={qtdeItem}
          onChange={setQtdeItem}
          decimalPlaces={casasDecimaisQtdProd || 2}
        />
        <NumberInput
          md={3}
          label="% Desconto"
          value={percDesc}
          onChange={(v) => {
            setPercDesc(v);
            calcularValores("P", v);
          }}
          decimalPlaces={2}
          isPercentage
        />
        <NumberInput
          md={3}
          label="Valor Item"
          value={vlrItemProd}
          onChange={(v) => {
            setVlrItemProd(v);
            calcularValores("V", v);
          }}
          decimalPlaces={casasDecimaisVlrsProd || 4}
        />
        <FormButton color="info" onClick={incluirItem}>
          Incluir Item ao Kit
        </FormButton>
      </Row>
      <ItensKitGrid
        data={itens}
        excluir={excluirItem}
        atualizarQtdItem={atualizarQtdItem}
        atualizarValorItem={atualizarValorItem}
      />
    </ModalCadastroV2>
  );
};
