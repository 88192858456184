import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  ComboBox,
  FormButton,
  ModalBase,
  NumberInput,
  RadioGroup,
  Table,
  TableDelete,
} from "../../../../../components";
import { Row } from "reactstrap";
import { RadioItem } from "../../../../../components/RadioGroup";
import CadastroRegrasComissaoOsService from "../../../../../services/cadastro/CadastroRegrasComissaoOsService";
import { formatValueFromAPI } from "../../../../../coreUtils";
import { showWarning } from "../../../../../components/AlertaModal";

const tipoGrupo = "GRUP";
const tipoFabricante = "FABR";
const tipoProduto = "PROD";
const tipoServico = "SERV";

const tipoOptions = [
  { label: "Grupo", value: tipoGrupo },
  { label: "Fabricante", value: tipoFabricante },
  { label: "Produto", value: tipoProduto },
  { label: "Serviço", value: tipoServico },
];

const tipoConfig = {
  [tipoGrupo]: {
    label: "Grupo",
    concatModelName: "grupo",
  },
  [tipoFabricante]: {
    label: "Fabricante",
    concatModelName: "fabricante",
  },
  [tipoProduto]: {
    label: "Produto",
    concatModelName: "produto",
  },
  [tipoServico]: {
    label: "Serviço",
    concatModelName: "servico",
  },
};

const tipoCalculoPerc = "P";
const tipoCalculoQtd = "Q";

export const DetalhesRegraComissaoOsModal = ({ selected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tipo, setTipo] = useState("");
  const [idTipo, setIdTipo] = useState(null);
  const [tipoCalculo, setTipoCalculo] = useState(tipoCalculoPerc);
  const [percComissao, setPercComissao] = useState(0);
  const [qtdComissao, setQtdComissao] = useState(0);
  const [qtdVlrComissao, setQtdVlrComissao] = useState(0);
  const [detalhes, setDetalhes] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(false);

  const limparDadosInclusao = () => {
    setTipo("");
    setIdTipo(null);
    setTipoCalculo(tipoCalculoPerc);
    setPercComissao(0);
    setQtdComissao(0);
    setQtdVlrComissao(0);
  };

  const limparDados = () => {
    limparDadosInclusao();
    setDetalhes([]);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const carregarDados = async () => {
    const [ok, ret] = await CadastroRegrasComissaoOsService.detalhe.listar(
      selected
    );
    setDetalhes(ok ? ret : []);
  };

  const onChangeTipo = () => setIdTipo(null);

  useEffect(onChangeTipo, [tipo]);

  const handleAdd = async () => {
    if (["", null, undefined].includes(tipo)) {
      showWarning(
        "Por favor, informe o Tipo do Item",
        null,
        null,
        "async-tipo-item"
      );
      return;
    }

    if ([0, null, undefined].includes(idTipo)) {
      showWarning(
        `Por favor, informe o ${tipoConfig[tipo].label}`,
        null,
        null,
        "async-tipo-item"
      );
      return;
    }

    if (tipoCalculo === tipoCalculoQtd) {
      if ([0, null, undefined].includes(qtdComissao)) {
        showWarning(
          "Por favor informe a Quantidade",
          null,
          null,
          "input-quantidade-comissao"
        );
        return;
      }
      if ([0, null, undefined].includes(qtdVlrComissao)) {
        showWarning(
          "Por favor informe o Valor por Quantidade",
          null,
          null,
          "input-vlr-por-qtd"
        );
        return;
      }
    } else {
      if ([0, null, undefined].includes(percComissao)) {
        showWarning(
          "Por favor informe o Percentual de Comissão",
          null,
          null,
          "input-percent-comissao"
        );
        return;
      }
    }

    const payload = {
      id_regra: selected,
      tipo: tipo,
      id_tipo: idTipo,
      tipo_calculo: tipoCalculo,
      perc_comissao: percComissao,
      qtd_comissao: qtdComissao,
      qtd_vlr_comissao: qtdVlrComissao,
    };

    setLoadingAdd(true);
    const [ok] = await CadastroRegrasComissaoOsService.detalhe.incluir(payload);
    if (ok) {
      limparDadosInclusao();
      carregarDados();
    }
    setLoadingAdd(false);
  };

  const handleDelete = async (idMov) => {
    const [ok] = await CadastroRegrasComissaoOsService.detalhe.excluir(idMov);
    if (ok) {
      carregarDados();
    }
  };

  const columns = [
    {
      dataField: "desc_tipo",
      text: "Tipo",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_tipo",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_tipo),
    },
    {
      dataField: "desc_comissao",
      text: "Comissão",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.id)} />
      ),
    },
  ];

  return (
    <>
      <FormButton
        md="auto"
        color="primary"
        onClick={toggle}
        disabled={[0, null, undefined].includes(selected)}
        padded={false}
      >
        Detalhes
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Detalhes da Regra de Comissão de OS"
        number="0135_3"
        onBeforeOpen={carregarDados}
        onClosed={limparDados}
      >
        <Row>
          <ComboBox
            options={tipoOptions}
            md={3}
            label="Tipo"
            onChange={(s) => setTipo(s?.value)}
            defaultValue={tipo}
          />
          {!["", null, undefined].includes(tipo) && (
            <AsyncComboBox
              md={7}
              key={`sel_${tipoConfig[tipo].concatModelName}`}
              label={tipoConfig[tipo].label}
              concatModelName={tipoConfig[tipo].concatModelName}
              value={idTipo}
              onChange={(s) => setIdTipo(s?.value)}
              inputName={"async-tipo-item"}
            />
          )}
        </Row>
        <Row>
          <RadioGroup
            label="Calcular por"
            value={tipoCalculo}
            onChange={setTipoCalculo}
          >
            <RadioItem label="Percentual" value={tipoCalculoPerc} />
            <RadioItem label="Quantidade" value={tipoCalculoQtd} />
          </RadioGroup>
          {tipoCalculo === tipoCalculoQtd ? (
            <>
              <NumberInput
                md={2}
                label="Quantidade"
                value={qtdComissao}
                onChange={setQtdComissao}
                name={"input-quantidade-comissao"}
              />
              <NumberInput
                md={2}
                label="Valor por Quantidade"
                value={qtdVlrComissao}
                onChange={setQtdVlrComissao}
                name={"input-vlr-por-qtd"}
              />
            </>
          ) : (
            <>
              <NumberInput
                md={2}
                label="% Comissão"
                value={percComissao}
                onChange={setPercComissao}
                isPercentage
                name={"input-percent-comissao"}
              />
            </>
          )}
          <FormButton color="info" onClick={handleAdd} loading={loadingAdd}>
            Incluir
          </FormButton>
        </Row>
        <Table
          data={detalhes}
          columns={columns}
          showRegisterCount={false}
          pageSize={10}
        />
      </ModalBase>
    </>
  );
};
