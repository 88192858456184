import React, { useState } from "react";
import {
  ComboBox,
  FiltroPeriodoDatas,
  ModalBase,
  SearchInput,
  Table,
} from "../../../../../components";
import CadastroProdutosService from "../../../../../services/cadastro/CadastroProdutosService";
import {
  defaultDebounceTime,
  formatValueFromAPI,
  naturalSortDate,
  useDebounce,
} from "../../../../../coreUtils";
import { Row } from "reactstrap";

const columns = [
  {
    dataField: "data_hora_log",
    text: "Data/Hora",
    align: "center",
    sortable: true,
    sortFunc: naturalSortDate,
  },
  { dataField: "log", text: "Descrição", align: "left", sortable: true },
  {
    dataField: "nome_colaborador",
    text: "Responsável",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_colaborador),
    sortable: true,
  },
];

const operacaoOptions = [
  { label: "Todas", value: "T" },
  { label: "Alt. Código Barras", value: "ACB" },
  { label: "Alt. Custo", value: "ACU" },
  { label: "Alt. Data Última Entrada", value: "ADE" },
  { label: "Alt. Fabricante", value: "AFP" },
  { label: "Alt. Grupo", value: "AGP" },
  { label: "Alt. ID", value: "AID" },
  { label: "Alt. Margem Lucro Atacado", value: "ALA" },
  { label: "Alt. Margem Lucro", value: "APL" },
  { label: "Alt. NCM", value: "ACF" },
  { label: "Alt. Nome", value: "ALN" },
  { label: "Alt. Preço Atacado", value: "APA" },
  { label: "Alt. Preço Venda", value: "APV" },
  { label: "Alt. Sub-grupo", value: "ASG" },
  { label: "Alt. Tipo produto", value: "ATP" },
  { label: "Alt. Unidade", value: "AUN" },
  { label: "Código Balança", value: "ABL" },
  { label: "Enviar Prod APP Venda", value: "AEM" },
  { label: "Grupo Trib. - Alterado", value: "AGT" },
  { label: "Grupo Trib. - Vinculado", value: "VGT" },
  { label: "Solic. Recarga - Qtd. Caixa Fec.", value: "AQC" },
  { label: "Solic. Recarga - Usa Caixa Fec.", value: "APC" },
];

export const LogProdutoModal = ({ isOpen, toggle, selected }) => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [pista, setPista] = useState(null);
  const [operacao, setOperacao] = useState("T");
  const [dados, setDados] = useState([]);
  const [loadingPista, setLoadingPista] = useState(false);

  const limparDados = () => {
    setPista(null);
    setOperacao("T");
    setDados([]);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
    carregarDados({
      data_ini: di,
      data_fim: df,
    });
  };

  const handleSetOperacao = (v) => {
    setOperacao(v);
    if (v === operacao) return;
    carregarDados({ operacao: v !== "T" ? v : null });
  };

  const buscarDados = async (outrosFiltros) => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      pista: pista,
      operacao: operacao !== "T" ? operacao : null,
      ...outrosFiltros,
    };
    const [ok, ret] = await CadastroProdutosService.buscarLogs(
      selected,
      params
    );
    setDados(ok ? ret : []);
  };

  const handlePista = useDebounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados({ pista: v });
    setLoadingPista(false);
  }, defaultDebounceTime);

  const carregarDados = async (outrosFiltros) => {
    setLoadingPista(true);
    await buscarDados(outrosFiltros);
    setLoadingPista(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Logs do Produto"
      number="0029_4"
      onClosed={limparDados}
    >
      <Row>
        <FiltroPeriodoDatas onChange={handleDate} defaultOption="MESATUANT" />
        <ComboBox
          md={4}
          label="Operação"
          options={operacaoOptions}
          defaultValue={operacao}
          isClearable
          onChange={(s) => handleSetOperacao(s?.value)}
        />
      </Row>
      <Row className="mb-2">
        <SearchInput
          value={pista}
          md={8}
          onChange={handlePista}
          loading={loadingPista}
        />
      </Row>
      <Table data={dados} columns={columns} paginated={false} pageSize={15} />
    </ModalBase>
  );
};
