import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FormCheckbox,
  TextInput,
} from "../../../../../components";
import MaskedInput from "../../../../../components/MaskedInput";
import {
  setAgencia,
  setBanco,
  setCep,
  setChavePix,
  setCidade,
  setCodCedente,
  setCodTransmissao,
  setConsideraFluxoCxa,
  setEspecieTit,
  setFone,
  setGerente,
  setLayout,
  setModoIntegracao,
  setNomeBeneficiarioPix,
  setNroConta,
  setPadrao,
  setPosto,
  setTipoCarteira,
  setVariacaoCarteira,
} from "../store/cadastroContaBancSlice";
import { initialFieldsState, setupFields } from "../store/controleCamposSlice";
import { apiGetV2 } from "../../../../../apiV2";

export const bancosIntegracao = [
  { label: "Não Especificado", value: "NAO" },
  { label: "Asaas", value: "ASA" },
  { label: "Banco do Brasil", value: "BDB" },
  { label: "Banco Inter", value: "INT" },
  { label: "Banco Safra", value: "SAF" },
  { label: "Banrisul", value: "BAN" },
  { label: "Bradesco", value: "BRA" },
  { label: "BTG Pactual", value: "BTG" },
  { label: "Caixa Econômica Federal", value: "CAI" },
  { label: "Cresol", value: "CRE" },
  { label: "Grafeno", value: "GRA" },
  { label: "Itaú", value: "ITA" },
  { label: "Santander", value: "SAN" },
  { label: "Sicoob", value: "SIB" },
  { label: "Sicredi", value: "SIC" },
];

export const TabDadosGerais = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.cadastroContaBanc);

  const configurarCampos = async (layout) => {
    let fields = initialFieldsState;

    if (!["NAO"].includes(layout)) {
      const [ok, ret] = await apiGetV2(
        `/cadastro/conta_banc/config_tela_web/${layout}/`
      );
      if (ok) {
        fields = ret;
      }
    }
    if (fields.padrao_remessa) {
      dispatch(setPadrao(fields.padrao_remessa));
    }

    if (fields.tem_integra_cnab && !fields.tem_integra_api) {
      dispatch(setModoIntegracao("CNAB"));
    } else if (fields.tem_integra_api && !fields.tem_integra_cnab) {
      dispatch(setModoIntegracao("API"));
    }

    dispatch(setupFields(fields));
  };

  const handleSetLayout = (v) => {
    if (v !== store.cobranca.layout) {
      dispatch(setPosto(""));
      dispatch(setCodCedente(""));
      dispatch(setTipoCarteira(null));
      dispatch(setVariacaoCarteira(""));
      dispatch(setCodTransmissao(null));
      dispatch(setEspecieTit(""));
      dispatch(setLayout(v));
    }
    configurarCampos(v);
  };

  return (
    <>
      <Row>
        <AsyncComboBox
          onChange={(s) => dispatch(setBanco(s?.value))}
          label="Banco"
          md={6}
          isConcatField
          concatModelName="banco"
          defaultOptions
          isSearchable
          isClearable
          defaultValue={store.banco}
        />
        <TextInput
          label="Agência"
          md={2}
          onChange={(e, v) => dispatch(setAgencia(v))}
          value={store.agencia}
          maxLength={10}
        />
        <TextInput
          label="Número da Conta"
          md={3}
          onChange={(e, v) => dispatch(setNroConta(v))}
          value={store.nro_conta}
          maxLength={20}
        />
      </Row>
      <Row>
        <AsyncComboBox
          onChange={(s) => dispatch(setCidade(s?.value ?? 0))}
          label="Cidade"
          md={5}
          isConcatField
          concatModelName="cidade"
          defaultOptions
          isSearchable
          isClearable
          defaultValue={store.cidade}
        />
        <MaskedInput
          mask="99999-999"
          name="cep"
          label="CEP"
          md={3}
          onChange={(e, v) => dispatch(setCep(v))}
          value={store.cep}
        />
        <TextInput
          label="Telefone"
          md={3}
          onChange={(e, v) => dispatch(setFone(v))}
          value={store.fone}
          maxLength={15}
        />
      </Row>
      <Row>
        <TextInput
          label="Gerente"
          md={6}
          onChange={(e, v) => dispatch(setGerente(v))}
          value={store.gerente}
          maxLength={60}
        />
        <FormCheckbox
          label="Considerar no Fluxo de Caixa"
          md="auto"
          name="considera_fluxo_cxa"
          id="considera_fluxo_cxa"
          onChange={() =>
            dispatch(setConsideraFluxoCxa(!store.considera_fluxo_cxa))
          }
          checked={store.considera_fluxo_cxa}
        />
      </Row>
      <Row>
        <TextInput
          label="Chave PIX"
          md={5}
          onChange={(e, v) => dispatch(setChavePix(v))}
          value={store.chave_pix}
          maxLength={50}
        />
        <TextInput
          label="Beneficiário PIX"
          md={7}
          onChange={(e, v) => dispatch(setNomeBeneficiarioPix(v))}
          value={store.nome_beneficiario_pix}
          maxLength={100}
          hint="Se o nome do beneficiário não for definido na conta bancária, o sistema irá utilizar a razão social da empresa"
        />
      </Row>
      <Row>
        <ComboBox
          options={bancosIntegracao}
          md={5}
          label="Banco de Integração"
          onChange={(s) => handleSetLayout(s?.value)}
          defaultValue={store.cobranca.layout}
          autoFocus
          isSearchable={false}
          isClearable={false}
        />
      </Row>
    </>
  );
};
