import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Card, Row } from "reactstrap";
import {
  PageContainer,
  AsyncComboBox,
  BotaoPesquisar,
  ComboBox,
  FiltroPeriodoDatas,
  FormButton,
  LinkButton,
} from "../../../../components";
import { downloadFileFromBlob } from "../../../../coreUtils";
import RemessaBancariaService from "../../../../services/bancario/RemessaBancariaService";
import { gerarArquivoRemessa } from "./components/GerarRemessa";
import { TitulosAddRemessaGrid } from "./components/TitulosAddRemessaGrid";
import { TitulosRemessaGrid } from "./components/TitulosRemessaGrid";
import { gerencRemessasRoute } from "../../../../routes/modules/financeiro";

const filtrarPorOptions = [
  { label: "Vencimento", value: "VCT" },
  { label: "Emissão", value: "EMI" },
  { label: "Impressão", value: "IMP" },
];

export const RemessaBancaria = () => {
  const [idContaBanc, setIdContaBanc] = useState(null);
  const [dupsEmRemessa, setDupsEmRemessa] = useState([]);
  const [duplicatas, setDuplicatas] = useState([]);
  const [loadingDups, setLoadingDups] = useState(false);
  const [loadingRemessa, setLoadingRemessa] = useState(false);
  const [dataIni, setDataIni] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDay() === 1
        ? new Date().getDate() - 3
        : new Date().getDate() - 1
    )
  );
  const [dataFim, setDataFim] = useState(new Date());
  const [currentAdd, setCurrentAdd] = useState([]);
  const [currentRemove, setCurrentRemove] = useState([]);
  const [tipoData, setTipoData] = useState(filtrarPorOptions[1].value);

  const limparDados = () => {
    setDupsEmRemessa([]);
    setCurrentAdd([]);
    setCurrentRemove([]);
    setDuplicatas([]);
  };

  const gerarRemessa = async () => {
    if (idContaBanc == null) {
      toastr.error("Erro", "Problemas ao identificar sua conta bancária.");
    } else {
      setLoadingRemessa(true);

      const payload = {
        id_conta_banc: idContaBanc,
        duplicatas: dupsEmRemessa,
      };

      const [ok1, res] = await RemessaBancariaService.gerarDadosRemessa(
        payload
      );

      if (ok1) {
        // Se gera arquivo TXT...
        if (res.gera_arquivo_txt) {
          //Busca dados para geração da remessa
          const [ok2, rem] = await RemessaBancariaService.buscarRemessa(
            res.id_remessa_cob
          );
          // Se os dados foram recebidos...
          if (ok2) {
            // Gera arquivo de remessa
            const [content, fileName] = gerarArquivoRemessa(
              rem.dados_conta,
              rem.duplicatas
            );

            // Baixa o arquivo
            downloadFileFromBlob(content, fileName);

            // Se deve salvar cópia...
            if (rem.dados_conta.salvar_copia_arquivo) {
              const json = {
                id_remessa_cob: res.id_remessa_cob,
                nome_arquivo: fileName,
              };

              let formData = new FormData();
              formData.append("arquivo_remessa", content, fileName);
              formData.append(
                "json",
                new Blob([JSON.stringify(json)], {
                  type: "application/json",
                })
              );

              // Envia cópia do arquivo
              await RemessaBancariaService.salvarCopia(formData);
            }

            toastr.success("Sucesso", "Arquivo de remessa gerado com sucesso");
            limparDados();
            carregarDados();
          }
          // Se não gera arquivo...
        } else if (res.gera_arquivo_txt === false) {
          toastr.success("Sucesso", "Remessa enviada para o banco com sucesso");
          limparDados();
          carregarDados();
        }
      }

      setLoadingRemessa(false);
    }
  };

  const carregarDados = async (limparRemessa = true) => {
    setLoadingDups(true);
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      tipo_data: tipoData,
    };
    const [ok, ret] = await RemessaBancariaService.listarTitulos(
      idContaBanc,
      params
    );

    if (ok) {
      setDuplicatas(ret);
      if (limparRemessa) {
        setDupsEmRemessa([]);
      }
    }

    setLoadingDups(false);
  };

  const addDups = () => {
    setDupsEmRemessa([...dupsEmRemessa, ...currentAdd]);
    setCurrentAdd([]);
  };

  const removeDups = () => {
    setDupsEmRemessa(dupsEmRemessa.filter((e) => !currentRemove.includes(e)));
    setCurrentRemove([]);
  };

  const handleOnSelectRemove = (id, isSelect) => {
    setCurrentRemove(
      isSelect
        ? [...currentRemove, id]
        : currentRemove.filter((item) => {
            return item !== id;
          })
    );
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
    limparDados();
  };

  const handleOnSelectAllRemove = (isSelect) => {
    setCurrentRemove(
      isSelect
        ? duplicatas
            .filter((e) => dupsEmRemessa.includes(e.id))
            .map((e) => e.id)
        : []
    );
  };

  const handleOnSelectAdd = (id, isSelect) => {
    setCurrentAdd(
      isSelect ? [...currentAdd, id] : currentAdd.filter((item) => item !== id)
    );
  };

  const handleOnSelectAllAdd = (isSelect) => {
    setCurrentAdd(
      isSelect
        ? duplicatas
            .filter((e) => !dupsEmRemessa.includes(e.id))
            .map((e) => e.id)
        : []
    );
  };

  const handleSetContaBanc = (s) => {
    setIdContaBanc(s?.value);
    limparDados();
  };

  const handleSetFiltrarData = (v) => {
    setTipoData(v);
    limparDados();
  };

  const naoEnviarTitRemessa = async (id) => {
    const [ok] = await RemessaBancariaService.naoEnviarTitRemessa(id);
    if (ok) {
      if (currentAdd.includes(id)) {
        setCurrentAdd(currentAdd.filter((e) => e !== id));
      }
      await carregarDados(false);
    }
  };

  return (
    <PageContainer title="Remessa Bancária" number="0002" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            md={5}
            isConcatField
            concatModelName="conta_banc"
            defaultOptions
            label="Conta Bancária"
            onChange={(s) => handleSetContaBanc(s)}
            isSearchable
            isClearable
          />
          <LinkButton
            tabIndex={100}
            pathname={gerencRemessasRoute.path}
            md="auto"
            color="info"
            divClassName="ml-auto"
          >
            Gerenciamento de Remessas
          </LinkButton>
        </Row>
        <Row>
          <ComboBox
            label="Filtrar Por"
            md={2}
            onChange={(s) => handleSetFiltrarData(s?.value)}
            options={filtrarPorOptions}
            defaultValue={filtrarPorOptions[1].value}
            divClassName="pr-0"
          />
          <FiltroPeriodoDatas
            onChange={handleDate}
            defaultOption={null}
            defaultStart={dataIni}
            defaultEnd={dataFim}
          />
          <BotaoPesquisar
            onClick={carregarDados}
            loading={loadingDups}
            disabled={!idContaBanc}
            disabledHint="Selecione uma conta bancária"
          />
          <FormButton
            md="auto"
            color="success"
            onClick={gerarRemessa}
            loading={loadingRemessa}
            disabled={dupsEmRemessa.length === 0}
            disabledHint="Nenhum título foi adicionado à remessa"
          >
            Gerar Remessa
          </FormButton>
        </Row>
      </Card>
      <TitulosAddRemessaGrid
        duplicatas={duplicatas.filter((e) => !dupsEmRemessa.includes(e.id))}
        handleOnSelect={handleOnSelectAdd}
        selected={currentAdd}
        handleOnSelectAll={handleOnSelectAllAdd}
        addDups={addDups}
        naoEnviarTitRemessa={naoEnviarTitRemessa}
        mode="add"
      />
      <TitulosRemessaGrid
        duplicatas={duplicatas.filter((e) => dupsEmRemessa.includes(e.id))}
        handleOnSelect={handleOnSelectRemove}
        selected={currentRemove}
        handleOnSelectAll={handleOnSelectAllRemove}
        removeDups={removeDups}
      />
      <Card body>
        <h5 className="mb-0">
          Os arquivos devem ser enviados ao Internet Banking da Conta Bancária
          conforme a ordem em que forem gerados.
        </h5>
      </Card>
    </PageContainer>
  );
};
