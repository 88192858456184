import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { FormCheckbox, SearchInput } from "../../../../components";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { PageContainer } from "../../../../components/PageContainer";
import { defaultDebounceTime, useDebounce } from "../../../../coreUtils";
import FormaRecClienteService from "../../../../services/cadastro/FormaRecClienteService";
import { FormaRecClienteGrid } from "./components/FormaRecClienteGrid";
import { FormaRecClienteModal } from "./components/FormaRecClienteModal";
import { FormasRecPadraoModal } from "./components/FormasRecPadraoModal";

export const FormaRecCliente = () => {
  const [pista, setPista] = useState("");
  const [apenasAtivos, setApenasAtivos] = useState(true);
  const [apenasEmiteNFe, setApenasEmiteNFe] = useState(true);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [selected, setSelected] = useState(null);
  const [nomeSelected, setNomeSelected] = useState("");
  const [formasRecOpen, setFormasRecOpen] = useState(false);

  const limparDados = () => setDados([]);

  const buscarDados = async (p) => {
    const params = {
      pista: p,
      apenas_ativos: apenasAtivos,
      apenas_emite_nfe: apenasEmiteNFe,
    };
    const [ok, ret] = await FormaRecClienteService.listarClientes(params);
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = useDebounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const handleApenasAtivos = () => {
    setApenasAtivos(!apenasAtivos);
    limparDados();
  };
  const handleApenasEmiteNFe = () => {
    setApenasEmiteNFe(!apenasEmiteNFe);
    limparDados();
  };

  const toggleFormasRec = () => setFormasRecOpen(!formasRecOpen);

  const alterarFormasRecCliente = (id, nome) => {
    setSelected(id);
    setNomeSelected(nome);
    setTimeout(() => {
      toggleFormasRec();
    }, 1);
  };

  return (
    <PageContainer
      title="Formas de Recebimento de Clientes"
      number="0022"
      canGoBack
    >
      <Card body>
        <Row>
          <SearchInput
            md={5}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Nome do Cliente"
          />
          <FormCheckbox
            label="Clientes ativos"
            checked={apenasAtivos}
            onChange={handleApenasAtivos}
            name="apenas_ativos"
          />
          <FormCheckbox
            label="Clientes que emitem NFe"
            checked={apenasEmiteNFe}
            onChange={handleApenasEmiteNFe}
            name="apenas_emite_nfe"
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <FormasRecPadraoModal />
          <FormaRecClienteModal
            isOpen={formasRecOpen}
            toggle={toggleFormasRec}
            idCliente={selected}
            nomeCliente={nomeSelected}
          />
        </Row>
      </Card>
      <FormaRecClienteGrid
        dadosGrid={dados}
        alterarFormasRecCliente={alterarFormasRecCliente}
      />
    </PageContainer>
  );
};
