import React, { useState } from "react";
import {
  FormButton,
  ModalBase,
  NumberInput,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import moment from "moment";
import CaixaLojaService from "../../../../../services/financeiro/CaixaLojaService";

export const AbrirCaixaLojaModal = ({
  notifyEvent,
  saldoAbertIgualUltFec,
  saldoAbertUltCaixa,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dataHoraAbertura, setDataHoraAbertura] = useState(new Date());
  const [saldoAbertura, setSaldoAbertura] = useState(0);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    if (!isOpen) {
      setDataHoraAbertura(new Date());
      if (saldoAbertIgualUltFec) {
        setSaldoAbertura(saldoAbertUltCaixa);
      }
    }
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (await CaixaLojaService.abrirCaixa({ saldo_abertura: saldoAbertura })) {
      toggle();
      setTimeout(() => {
        notifyEvent();
      }, 350);
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        md="auto"
        padded={false}
        color="info"
        onClick={toggle}
        id="bt-abrir-caixa"
      >
        F4 - Abrir Caixa
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        onClosed={() => setSaldoAbertura(0)}
        size="sm"
        title="Abrir Caixa Loja"
        number="0063_2"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row style={{ justifyContent: "center" }}>
          <TextInput
            md={6}
            label="Data/Hora da Abertura"
            value={moment(dataHoraAbertura).format("DD/MM/YYYY - HH:mm")}
            disabled
          />
        </Row>
        <Row style={{ justifyContent: "center" }}>
          <NumberInput
            md={6}
            label="Saldo de Abertura"
            value={saldoAbertura}
            onChange={setSaldoAbertura}
            disabled={saldoAbertIgualUltFec}
          />
        </Row>
      </ModalBase>
    </>
  );
};
