import React, { useCallback, useRef, useState } from "react";
import { toastr } from "react-redux-toastr";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { TextInput } from "../../../../../components";
import { MODAL_ACTIONS, useClickOutside } from "../../../../../coreUtils";
import { modalTitleListaPrecos, routesBaseListaPrecos } from "../ListaPrecos";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { HexColorPicker } from "react-colorful";
import { CgColorPicker } from "react-icons/cg";

export const CadastroListaPrecosModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [cor, setCor] = useState("#ffffff");
  const [descricao, setDescricao] = useState("");

  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const colorPickerWrapperRef = useRef();

  const toggleColorPicker = (state) => setIsColorPickerOpen(state);

  const closeColorPicker = useCallback(() => toggleColorPicker(false), []);
  useClickOutside(colorPickerWrapperRef, closeColorPicker);

  const limparDados = () => {
    setAtivo(true);
    setCor("#ffffff");
    setDescricao("");
  };

  const fetchData = (data) => {
    setDescricao(data.descricao);
    setAtivo(data.ativo);
    setCor(data.cor ?? "#ffffff");
  };

  const submitPayload = (action) => {
    if (descricao.length <= 0) {
      toastr.warning("Atenção", "Por favor, informe a descrição.");
      return false;
    }

    let data = {
      descricao: descricao,
      cor: cor,
    };

    if (action === MODAL_ACTIONS.EDIT) {
      data = {
        ...data,
        id_lista_preco: selected,
        ativo: ativo,
      };
    }
    return data;
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleListaPrecos}
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseListaPrecos}
      number="0013_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row form>
        <TextInput
          label="Nome da Lista"
          md={null}
          onChange={(e, v) => setDescricao(v)}
          value={descricao}
          maxLength={150}
          autoFocus
        />
        <Col md="auto">
          <FormGroup>
            <Label>Cor da Lista</Label>
            <div
              style={{ display: "flex", position: "relative", width: "100%" }}
            >
              <div
                style={{
                  background: cor,
                  height: "31px",
                  width: "56px",
                  border: `1px solid ${
                    cor.toLowerCase() === "#ffffff" ? "#ced4da" : cor
                  }`,
                  borderRadius: "0.2rem",
                  marginRight: "7.5px",
                  cursor: "pointer",
                }}
                onClick={() => toggleColorPicker(true)}
              />

              <div
                style={{
                  marginTop: "6px",
                  background: "none",
                  border: "none",
                  marginLeft: "auto",
                  padding: 0,
                  cursor: "pointer",
                  boxShadow: "none",
                }}
              >
                <CgColorPicker
                  color="black"
                  size={18}
                  onClick={() => toggleColorPicker(true)}
                />
              </div>

              {isColorPickerOpen && (
                <div
                  style={{
                    position: "absolute",
                    top: "calc(100% + 5px)",
                    left: 0,
                    borderRadius: "9px",
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                    zIndex: 9999,
                  }}
                  ref={colorPickerWrapperRef}
                >
                  <HexColorPicker color={cor} onChange={setCor} />
                </div>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
    </ModalCadastroV2>
  );
};
