import React, { useRef } from "react";
import moment from "moment";
import {
  AsyncComboBox,
  DatePicker,
  FixedField,
  FormButton,
  FormCheckbox,
  ModalBase,
  NumberInput,
  Table,
  TableDelete,
  TextInput,
} from "../../../../../../components";
import { useState } from "react";
import { Row } from "reactstrap";
import ConferValoresRecMaosService from "../../../../../../services/pedidos/ConferValoresRecMaosService";
import {
  formatDateISO,
  formatNumber,
  sumDataField,
} from "../../../../../../coreUtils";
import { showWarning } from "../../../../../../components/AlertaModal";

export const ConferirValoresChequeModal = ({ selected, notifyEvent }) => {
  const vlrCompensar = sumDataField(selected, "valor_pend");
  const [isOpen, setIsOpen] = useState(false);
  const [nroCheque, setNroCheque] = useState("");
  const [idBanco, setIdBanco] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [nomeBanco, setNomeBanco] = useState("");
  const [agencia, setAgencia] = useState("");
  const [nroConta, setNroConta] = useState("");
  const [aVista, setAVista] = useState(false);
  const [cmc7, setCmc7] = useState("");
  const [observ, setObserv] = useState("");
  const [dataEmi, setDataEmi] = useState(null);
  const [dataVcto, setDataVcto] = useState(null);
  const [valor, setValor] = useState(0);
  const [observConfer, setObservConfer] = useState("");
  const [cheques, setCheques] = useState([]);
  const [loading, setLoading] = useState(false);
  const idBancoRef = useRef(null);
  const idClienteRef = useRef(null);
  const numeroRef = useRef(null);

  const vlrLancado = sumDataField(cheques, "valor");

  const limparDadosInclusao = () => {
    setNroCheque("");
    setIdBanco(null);
    setIdCliente(null);
    setNomeBanco("");
    setAgencia("");
    setNroConta("");
    setAVista(false);
    setCmc7("");
    setObserv("");
    setDataEmi(null);
    setDataVcto(null);
    setValor(0);
    if (idBancoRef.current) {
      idBancoRef.current.clearValue();
    }
    if (idClienteRef.current) {
      idClienteRef.current.clearValue();
    }
  };

  const limparDados = () => {
    limparDadosInclusao();
    setObservConfer("");
    setCheques([]);
  };

  const handleSetBanco = (s) => {
    setIdBanco(s?.value);
    setNomeBanco(s?.label);
  };

  const handleAdd = () => {
    if (["", null, undefined].includes(nroCheque)) {
      showWarning(
        "Por favor, informe o Número do Cheque",
        null,
        null,
        "input-nro-cheque"
      );
      return false;
    }
    if ([0, null, undefined].includes(idBanco)) {
      showWarning(
        "Por favor, informe o Banco do Cheque",
        null,
        null,
        "async-banco-cheque"
      );
      return false;
    }
    if ([0, null, undefined].includes(idCliente)) {
      showWarning(
        "Por favor, informe o Cliente do Cheque",
        null,
        null,
        "async-cliente-cheque"
      );
      return false;
    }
    if (!(dataEmi instanceof Date)) {
      showWarning(
        "Por favor, informe a Data de Emissão do Cheque",
        null,
        null,
        "input-data-emi"
      );
      return false;
    }
    if (!(dataVcto instanceof Date)) {
      showWarning(
        "Por favor, informe a Data de Vencimento (Bom Para) do Cheque",
        null,
        null,
        "input-bom-para"
      );
      return false;
    }
    if ([0, null, undefined].includes(valor)) {
      showWarning(
        "Por favor, informe o Valor do Cheque",
        null,
        null,
        "input-valor-cheque"
      );
      return false;
    }

    const payload = {
      id_banco: idBanco,
      nome_banco: nomeBanco,
      id_cliente: idCliente,
      nro_cheque: nroCheque,
      agencia: agencia,
      nro_conta: nroConta,
      data_emi: formatDateISO(dataEmi),
      data_vcto: formatDateISO(dataVcto),
      observ: observ,
      cmc7: cmc7,
      a_vista: aVista,
      valor: valor,
    };
    setCheques([...cheques, payload]);
    limparDadosInclusao();
    numeroRef.current.focus();
    setTimeout(() => {
      setValor(0);
    }, 40);
  };

  const handleDelete = (nroCheque) => {
    setCheques(cheques.filter((e) => e.nro_cheque !== nroCheque));
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    const payload = {
      observ_conf: observConfer,
      cheques: cheques.map((e) => ({
        id_banco: e.id_banco,
        id_cliente: e.id_cliente,
        nro_cheque: e.nro_cheque,
        agencia: e.agencia,
        nro_conta: e.nro_conta,
        data_emi: e.data_emi,
        data_vcto: e.data_vcto,
        observ: e.observ,
        cmc7: e.cmc7,
        a_vista: e.a_vista,
        valor: e.valor,
      })),
      ids_conferir: selected.map((e) => ({ id_pendencia: e.id })),
    };

    setLoading(true);
    const [ok] = await ConferValoresRecMaosService.conferirCheque(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  const somenteCheque = !selected.some((e) => e.desc_forma_pag !== "Cheque");

  return (
    <>
      <FormButton
        md="auto"
        color="success"
        onClick={toggle}
        disabled={selected.length === 0 || !somenteCheque}
        disabledHint={
          !somenteCheque
            ? "Selecione somente Pedidos recebidos em Cheque"
            : "Selecione ao menos um Pedido da lista"
        }
      >
        Conferir Cheques
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        onClosed={limparDados}
        size="lg"
        title="Conferir Cheques"
        number="0054_1"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <TextInput
            label="Nº Cheque"
            md={2}
            value={nroCheque}
            onChange={(e, v) => setNroCheque(v)}
            maxLength={8}
            ref={numeroRef}
            name={"input-nro-cheque"}
          />
        </Row>
        <Row>
          <AsyncComboBox
            label="Banco"
            md={4}
            concatModelName="banco"
            defaultOptions
            defaultValue={idBanco}
            onChange={handleSetBanco}
            ref={idBancoRef}
            inputName={"async-banco-cheque"}
          />
          <TextInput
            label="Agência"
            md={2}
            value={agencia}
            onChange={(e, v) => setAgencia(v)}
            maxLength={5}
          />
          <TextInput
            label="Número da Conta"
            md={3}
            value={nroConta}
            onChange={(e, v) => setNroConta(v)}
            maxLength={20}
          />
        </Row>
        <Row>
          <AsyncComboBox
            label="Cliente"
            md={8}
            concatModelName="cliente"
            defaultValue={idCliente}
            onChange={(s) => setIdCliente(s?.value)}
            ref={idClienteRef}
            inputName={"async-cliente-cheque"}
          />
        </Row>
        <Row>
          <FormCheckbox
            label="Cheque À Vista"
            checked={aVista}
            onChange={() => setAVista(!aVista)}
            name="a-vista"
          />
          <TextInput
            label="CMC7"
            md={6}
            value={cmc7}
            onChange={(e, v) => setCmc7(v)}
            maxLength={35}
          />
        </Row>
        <Row>
          <TextInput
            label="Observação do Cheque"
            md={12}
            value={observ}
            onChange={(e, v) => setObserv(v)}
            maxLength={150}
            colClassName="pr-0"
          />
        </Row>
        <Row className="mb-3">
          <DatePicker
            label="Emissão"
            md={3}
            value={dataEmi}
            onChange={(v) => setDataEmi(moment.isMoment(v) ? v.toDate() : v)}
            name={"input-data-emi"}
          />
          <DatePicker
            label="Bom Para"
            md={3}
            value={dataVcto}
            onChange={(v) => setDataVcto(moment.isMoment(v) ? v.toDate() : v)}
            name={"input-bom-para"}
          />
          <NumberInput
            label="Valor do Cheque"
            md={3}
            value={valor}
            onChange={setValor}
            name={"input-valor-cheque"}
          />
          <FormButton md="auto" color="info" onClick={handleAdd}>
            F8 - Incluir
          </FormButton>
        </Row>
        <ChequesGrid cheques={cheques} handleDelete={handleDelete} />
        <Row>
          <TextInput
            md={12}
            label="Observação da Conferência"
            value={observConfer}
            onChange={(e, v) => setObservConfer(v)}
          />
        </Row>
        <Row>
          <FixedField
            label="Vendas Selecionadas"
            value={selected.length}
            horizontal
            divClassName="ml-auto"
          />
          <FixedField
            label="Valor a Compensar"
            value={formatNumber(vlrCompensar, true, 2)}
            horizontal
          />
          <FixedField
            label="Valor a Lançar"
            value={formatNumber(
              Math.max(vlrCompensar - vlrLancado, 0),
              true,
              2
            )}
            horizontal
          />
          <FixedField
            label="Valor Lançado"
            value={formatNumber(vlrLancado, true, 2)}
            horizontal
          />
        </Row>
      </ModalBase>
    </>
  );
};

const ChequesGrid = ({ cheques, handleDelete }) => {
  const columns = [
    {
      dataField: "nro_cheque",
      text: "Nº Cheque",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_banco",
      text: "Banco",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "agencia",
      text: "Agência",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nro_conta",
      text: "Conta Corrente",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "data_vcto",
      text: "Vencimento",
      align: "center",
      headerAlign: "center",
      formatter: (c) => moment(c).format("DD/MM/YYYY"),
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.nro_cheque)} />
      ),
    },
  ];

  return (
    <Table
      data={cheques}
      columns={columns}
      paginated={false}
      pageSize={7}
      showRegisterCount={false}
    />
  );
};
