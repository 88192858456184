import React from "react";
import { PageContainer } from "../../../../components/PageContainer";
import {
  MODAL_ACTIONS,
  defaultDebounceTime,
  useDebounce,
} from "../../../../coreUtils";
import { BotaoPesquisar, SearchInput } from "../../../../components";
import { Card, Row } from "reactstrap";
import { DescPorQuantidadeGrid } from "./components/DescPorQuantidadeGrid";
import { CadastroDescPorQuantidadeModal } from "./components/CadastroDescPorQuantidadeModal";
import { useState } from "react";
import { apiGetV2 } from "../../../../apiV2";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";

export const modalTitleDescPorQuantidade = "Desconto por Quantidade";
export const routesBaseDescPorQuantidade = "/cadastro/reg_desc_p_qtd";

export const DescPorQuantidade = () => {
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async (p) => {
    const [ok, ret] = await apiGetV2(`${routesBaseDescPorQuantidade}/listar/`, {
      pista: p,
    });

    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = useDebounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer
      title="Cadastro de Descontos por Quantidade"
      number="0017"
      canGoBack
    >
      <Card body>
        <Row>
          <SearchInput
            md={6}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Nome do Produto"
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroDescPorQuantidadeModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleDescPorQuantidade}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0017_2"
            selected={selected}
            routeBase={routesBaseDescPorQuantidade}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <DescPorQuantidadeGrid
        dados={dados}
        setSelected={setSelected}
        alterar={alterar}
        excluir={excluir}
      />
    </PageContainer>
  );
};
