import React from "react";
import { useState } from "react";
import { modalTitlePerfil, routesBasePerfil } from "../Perfil";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { Row } from "reactstrap";
import {
  FormCheckbox,
  TabController,
  TextInput,
} from "../../../../../components";
import { TabBody } from "../../../../../components/TabController";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { showWarning } from "../../../../../components/AlertaModal";

export const CadastroPerfilModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [descricao, setDescricao] = useState("");
  const [cadProdCuringa, setCadProdCuringa] = useState(false);
  const [cadCliCuringa, setCadCliCuringa] = useState(false);
  const [crExcluirDup, setCrExcluirDup] = useState(false);
  const [crReceberDup, setCrReceberDup] = useState(false);
  const [cpExcluirDup, setCpExcluirDup] = useState(false);
  const [cpPagarDup, setCpPagarDup] = useState(false);
  const [osReabrirRecebidaCaixa, setOsReabrirRecebidaCaixa] = useState(false);
  const [vdVisCustoPesqProd, setVdVisCustoPesqProd] = useState(false);
  const [vdPermExclusaoVenda, setVdPermExclusaoVenda] = useState(false);
  const [vdVisualHistorVendaCompra, setVdVisualHistorVendaCompra] =
    useState(false);
  const [vdAlteraNomeProdVenda, setVdAlteraNomeProdVenda] = useState(false);
  const [vdPermiteBuscaOrcamOutrosUsu, setVdPermiteBuscaOrcamOutrosUsu] =
    useState(false);
  const [vdExclusaoVendaSemSenha, setVdExclusaoVendaSemSenha] = useState(false);
  const [crInclusaoCreditoAvulsoSemSenha, setCrInclusaoCreditoAvulsoSemSenha] =
    useState(false);
  const [clPesqCaixaSemSenha, setClPesqCaixaSemSenha] = useState(false);
  const [cadCliModifObservEspecial, setCadCliModifObservEspecial] =
    useState(false);
  const [osPermiteVisualTotGerenciamento, setOsPermiteVisualTotGerenciamento] =
    useState(false);
  const [permiteAlterLimiteCreditoCli, setPermiteAlterLimiteCreditoCli] =
    useState(false);
  const [vdPermiteVerTotaisConsultas, setVdPermiteVerTotaisConsultas] =
    useState(false);
  const [osPermiteFinalizarOrdemServico, setOsPermiteFinalizarOrdemServico] =
    useState(false);
  const [esPermiteAjusteEstoque, setEsPermiteAjusteEstoque] = useState(true);
  const [pedExtPodeManipularPedidos, setPedExtPodeManipularPedidos] =
    useState(true);
  const [
    pedExtPodeVisuSomentePedIncUsuario,
    setPedExtPodeVisuSomentePedIncUsuario,
  ] = useState(false);
  const [
    vdPermiteLiberarVendaCliClassif8,
    setVdPermiteLiberarVendaCliClassif8,
  ] = useState(true);

  const limparDados = () => {
    setDescricao("");
    setCadProdCuringa(false);
    setCadCliCuringa(false);
    setCrExcluirDup(false);
    setCrReceberDup(false);
    setCpExcluirDup(false);
    setCpPagarDup(false);
    setOsReabrirRecebidaCaixa(false);
    setVdVisCustoPesqProd(false);
    setVdPermExclusaoVenda(false);
    setVdVisualHistorVendaCompra(false);
    setVdAlteraNomeProdVenda(false);
    setVdPermiteBuscaOrcamOutrosUsu(false);
    setVdExclusaoVendaSemSenha(false);
    setCrInclusaoCreditoAvulsoSemSenha(false);
    setClPesqCaixaSemSenha(false);
    setCadCliModifObservEspecial(false);
    setOsPermiteVisualTotGerenciamento(false);
    setPermiteAlterLimiteCreditoCli(false);
    setVdPermiteVerTotaisConsultas(false);
    setOsPermiteFinalizarOrdemServico(false);
    setEsPermiteAjusteEstoque(true);
    setPedExtPodeManipularPedidos(true);
    setPedExtPodeVisuSomentePedIncUsuario(false);
    setVdPermiteLiberarVendaCliClassif8(true);
  };

  const fetchData = (data) => {
    setDescricao(data.descricao);
    setCadProdCuringa(data.cad_prod_curinga);
    setCadCliCuringa(data.cad_cli_curinga);
    setCrExcluirDup(data.cr_excluir_dup);
    setCrReceberDup(data.cr_receber_dup);
    setCpExcluirDup(data.cp_excluir_dup);
    setCpPagarDup(data.cp_pagar_dup);
    setOsReabrirRecebidaCaixa(data.os_reabrir_recebida_caixa);
    setVdVisCustoPesqProd(data.vd_vis_custo_pesq_prod);
    setVdPermExclusaoVenda(data.vd_perm_exclusao_venda);
    setVdVisualHistorVendaCompra(data.vd_visual_histor_venda_compra);
    setVdAlteraNomeProdVenda(data.vd_altera_nome_prod_venda);
    setVdPermiteBuscaOrcamOutrosUsu(data.vd_permite_busca_orcam_outros_usu);
    setVdExclusaoVendaSemSenha(data.vd_exclusao_venda_sem_senha);
    setCrInclusaoCreditoAvulsoSemSenha(
      data.cr_inclusao_credito_avulso_sem_senha
    );
    setClPesqCaixaSemSenha(data.cl_pesq_caixa_sem_senha);
    setCadCliModifObservEspecial(data.cad_cli_modif_observ_especial);
    setOsPermiteVisualTotGerenciamento(
      data.os_permite_visual_tot_gerenciamento
    );
    setPermiteAlterLimiteCreditoCli(data.permite_alter_limite_credito_cli);
    setVdPermiteVerTotaisConsultas(data.vd_permite_ver_totais_consultas);
    setOsPermiteFinalizarOrdemServico(data.os_permite_finalizar_ordem_servico);
    setEsPermiteAjusteEstoque(data.es_permite_ajuste_estoque);
    setPedExtPodeManipularPedidos(data.ped_ext_pode_manipular_pedidos);
    setPedExtPodeVisuSomentePedIncUsuario(
      data.ped_ext_pode_visu_somente_ped_inc_usuario
    );
    setVdPermiteLiberarVendaCliClassif8(
      data.vd_permite_liberar_venda_cli_classif_8
    );
  };

  const submitPayload = (action) => {
    if (["", null, undefined].includes(descricao?.trim())) {
      showWarning("Por favor, informe a Descrição do Perfil");
      return false;
    }

    const payload = {
      descricao: descricao,
      cad_prod_curinga: cadProdCuringa,
      cad_cli_curinga: cadCliCuringa,
      cr_excluir_dup: crExcluirDup,
      cr_receber_dup: crReceberDup,
      cp_excluir_dup: cpExcluirDup,
      cp_pagar_dup: cpPagarDup,
      os_reabrir_recebida_caixa: osReabrirRecebidaCaixa,
      vd_vis_custo_pesq_prod: vdVisCustoPesqProd,
      vd_perm_exclusao_venda: vdPermExclusaoVenda,
      vd_visual_histor_venda_compra: vdVisualHistorVendaCompra,
      vd_altera_nome_prod_venda: vdAlteraNomeProdVenda,
      vd_permite_busca_orcam_outros_usu: vdPermiteBuscaOrcamOutrosUsu,
      vd_exclusao_venda_sem_senha: vdExclusaoVendaSemSenha,
      cr_inclusao_credito_avulso_sem_senha: crInclusaoCreditoAvulsoSemSenha,
      cl_pesq_caixa_sem_senha: clPesqCaixaSemSenha,
      cad_cli_modif_observ_especial: cadCliModifObservEspecial,
      os_permite_visual_tot_gerenciamento: osPermiteVisualTotGerenciamento,
      permite_alter_limite_credito_cli: permiteAlterLimiteCreditoCli,
      vd_permite_ver_totais_consultas: vdPermiteVerTotaisConsultas,
      os_permite_finalizar_ordem_servico: osPermiteFinalizarOrdemServico,
      es_permite_ajuste_estoque: esPermiteAjusteEstoque,
      ped_ext_pode_manipular_pedidos: pedExtPodeManipularPedidos,
      ped_ext_pode_visu_somente_ped_inc_usuario:
        pedExtPodeVisuSomentePedIncUsuario,
      vd_permite_liberar_venda_cli_classif_8: vdPermiteLiberarVendaCliClassif8,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitlePerfil}
      size="xl"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBasePerfil}
      number="0084_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row className="mb-2">
        <TextInput
          md={12}
          label="Descrição"
          value={descricao}
          onChange={(e, v) => setDescricao(v)}
        />
      </Row>
      <TabController contentStyle={{ minHeight: "228px" }}>
        <TabBody title="Cadastros">
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Transformar Produtos em Curinga"
              checked={cadProdCuringa}
              onChange={() => setCadProdCuringa(!cadProdCuringa)}
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Transformar Clientes em Curinga"
              checked={cadCliCuringa}
              onChange={() => setCadCliCuringa(!cadCliCuringa)}
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Modificar Observação Financeira Especial do Cadastro de Cliente"
              checked={cadCliModifObservEspecial}
              onChange={() =>
                setCadCliModifObservEspecial(!cadCliModifObservEspecial)
              }
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Acessar a Liberação e Alteração de Crédito de Cliente"
              checked={permiteAlterLimiteCreditoCli}
              onChange={() =>
                setPermiteAlterLimiteCreditoCli(!permiteAlterLimiteCreditoCli)
              }
            />
          </Row>
        </TabBody>
        <TabBody title="Contas a Receber">
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Excluír Títulos a Receber"
              checked={crExcluirDup}
              onChange={() => setCrExcluirDup(!crExcluirDup)}
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Efetuar o Recebimento de Títulos"
              checked={crReceberDup}
              onChange={() => setCrReceberDup(!crReceberDup)}
            />
          </Row>
        </TabBody>
        <TabBody title="Crédito Rotativo">
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Incluir Crédito Avulso Sem Senha"
              checked={crInclusaoCreditoAvulsoSemSenha}
              onChange={() =>
                setCrInclusaoCreditoAvulsoSemSenha(
                  !crInclusaoCreditoAvulsoSemSenha
                )
              }
            />
          </Row>
        </TabBody>
        <TabBody title="Contas a Pagar">
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Excluir Títulos a Pagar"
              checked={cpExcluirDup}
              onChange={() => setCpExcluirDup(!cpExcluirDup)}
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Efetuar o Pagamento de Títulos"
              checked={cpPagarDup}
              onChange={() => setCpPagarDup(!cpPagarDup)}
            />
          </Row>
        </TabBody>
        <TabBody title="Estoque">
          <FormCheckbox
            padded={false}
            label="Permite Efetuar Ajustes de Estoque"
            checked={esPermiteAjusteEstoque}
            onChange={() => setEsPermiteAjusteEstoque(!esPermiteAjusteEstoque)}
          />
        </TabBody>
        <TabBody title="Ordem de Serviço">
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Reabrir O.S. Recebida no Caixa Loja"
              checked={osReabrirRecebidaCaixa}
              onChange={() =>
                setOsReabrirRecebidaCaixa(!osReabrirRecebidaCaixa)
              }
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Visualizar Valores Totais no Gerenciamento de O.S."
              checked={osPermiteVisualTotGerenciamento}
              onChange={() =>
                setOsPermiteVisualTotGerenciamento(
                  !osPermiteVisualTotGerenciamento
                )
              }
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Finalizar O.S."
              checked={osPermiteFinalizarOrdemServico}
              onChange={() =>
                setOsPermiteFinalizarOrdemServico(
                  !osPermiteFinalizarOrdemServico
                )
              }
            />
          </Row>
        </TabBody>
        <TabBody title="Pedido Externo">
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Manipular Pedidos Externos (Alterar, Liberar, Entregar ou Cancelar)"
              checked={pedExtPodeManipularPedidos}
              onChange={() =>
                setPedExtPodeManipularPedidos(!pedExtPodeManipularPedidos)
              }
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Usuário pode visualizar SOMENTE pedidos incluídos por ele mesmo na Central de Pedidos"
              checked={pedExtPodeVisuSomentePedIncUsuario}
              onChange={() =>
                setPedExtPodeVisuSomentePedIncUsuario(
                  !pedExtPodeVisuSomentePedIncUsuario
                )
              }
            />
          </Row>
        </TabBody>
        <TabBody title="Vendas">
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Visualizar Custos na Pesquisa de Produto"
              checked={vdVisCustoPesqProd}
              onChange={() => setVdVisCustoPesqProd(!vdVisCustoPesqProd)}
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Exclusão de Vendas"
              checked={vdPermExclusaoVenda}
              onChange={() => setVdPermExclusaoVenda(!vdPermExclusaoVenda)}
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Visualizar o Histórico de Compras e Vendas do Produto"
              checked={vdVisualHistorVendaCompra}
              onChange={() =>
                setVdVisualHistorVendaCompra(!vdVisualHistorVendaCompra)
              }
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Alterar Nomes de Produtos na Frente de Venda"
              checked={vdAlteraNomeProdVenda}
              onChange={() => setVdAlteraNomeProdVenda(!vdAlteraNomeProdVenda)}
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Buscar Orçamentos de Outros Usuários"
              checked={vdPermiteBuscaOrcamOutrosUsu}
              onChange={() =>
                setVdPermiteBuscaOrcamOutrosUsu(!vdPermiteBuscaOrcamOutrosUsu)
              }
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Excluir Vendas Sem Senha"
              checked={vdExclusaoVendaSemSenha}
              onChange={() =>
                setVdExclusaoVendaSemSenha(!vdExclusaoVendaSemSenha)
              }
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Visualizar Totalizadores em Consultas e Relatórios de Vendas"
              checked={vdPermiteVerTotaisConsultas}
              onChange={() =>
                setVdPermiteVerTotaisConsultas(!vdPermiteVerTotaisConsultas)
              }
            />
          </Row>
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Liberar Venda para Clientes com Classificação 8"
              checked={vdPermiteLiberarVendaCliClassif8}
              onChange={() =>
                setVdPermiteLiberarVendaCliClassif8(
                  !vdPermiteLiberarVendaCliClassif8
                )
              }
            />
          </Row>
        </TabBody>
        <TabBody title="Caixa Loja">
          <Row>
            <FormCheckbox
              padded={false}
              label="Permite Efetuar Pesquisa de Caixas Sem Senha"
              checked={clPesqCaixaSemSenha}
              onChange={() => setClPesqCaixaSemSenha(!clPesqCaixaSemSenha)}
            />
          </Row>
        </TabBody>
      </TabController>
    </ModalCadastroV2>
  );
};
