import React, { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import {
  ModalBase,
  ModalDetalhesIcone,
  TabController,
  Table,
} from "../../../../../components";
import { TabBody } from "../../../../../components/TabController";

export const DetalhesCliInadRotaModal = ({ row }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const columnsTitulos = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sort: true,
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      sort: true,
      formatter: (cell, row) => (
        <>
          <span id={`nome_${row.id}`}>
            {formatValueFromAPI(cell, row.id_cliente)}
          </span>
          <UncontrolledTooltip target={`nome_${row.id}`}>
            {row.nome_fant_cliente}
          </UncontrolledTooltip>
        </>
      ),
    },
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      formatter: (cell, row) => `${cell}-${row.parcela}`,
    },
    {
      dataField: "nosso_nro",
      text: "Nosso Nro.",
      align: "center",
    },
    {
      dataField: "emissao",
      text: "Emissão",
      align: "center",
    },
    {
      dataField: "vencimento",
      text: "Vencimento",
      align: "center",
    },
    {
      dataField: "vlr_orig",
      text: "Vlr. Original",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "vlr_receber",
      text: "Vlr. Receber",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "vlr_recebido",
      text: "Vlr. Recebido",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
  ];

  const columnsRotativo = [
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      sort: true,
      formatter: (cell, row) => (
        <>
          <span id={`nome_${row.id}`}>
            {formatValueFromAPI(cell, row.id_cliente)}
          </span>
          {row.nome_fant_cliente && (
            <UncontrolledTooltip target={`nome_${row.id}`}>
              {row.nome_fant_cliente}
            </UncontrolledTooltip>
          )}
        </>
      ),
    },
    {
      dataField: "nome_colaborador",
      text: "Colaborador",
      align: "left",
      sort: true,
      formatter: (cell, row) => formatValueFromAPI(cell, row.id_colaborador),
    },
    {
      dataField: "desc_origem",
      text: "Origem",
      align: "left",
      formatter: (c, row) => `${c} - ${row.id_origem}`,
    },
    {
      dataField: "data_hora_inc",
      text: "Data Hora Inc.",
      align: "center",
    },
    {
      dataField: "valor",
      text: "Vlr. Original",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "v_pend",
      text: "Vlr. Receber",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
    {
      dataField: "v_rec_parc",
      text: "Vlr. Recebido",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
    },
  ];

  return (
    <>
      <ModalDetalhesIcone toggle={toggleModal} />
      <ModalBase
        isOpen={modalIsOpen}
        toggle={toggleModal}
        size="xl"
        title={
          <h5 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
            {`${row.nome} - Detalhamento de Valores Em Aberto`}
          </h5>
        }
        number={"5028_1"}
        autoFocus
        hideCancelButton
      >
        <TabController>
          <TabBody title="Contas a Receber">
            <Table
              columns={columnsTitulos}
              data={row.titulos}
              keyField="id"
              paginated={false}
            />
          </TabBody>
          <TabBody title="Crédito Rotativo">
            <Table
              columns={columnsRotativo}
              data={row.movs_rotativo}
              keyField="id"
              paginated={false}
            />
          </TabBody>
        </TabController>
      </ModalBase>
    </>
  );
};
