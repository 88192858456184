import React from "react";
import { Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";

export const MaisProdutosGrid = ({
  dados,
  selected,
  setSelected,
  onSelectAll,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "Produto",
      align: "center",
    },
    {
      dataField: "nome",
      text: "Nome do Produto",
      align: "left",
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
    },
    {
      dataField: "preco_venda",
      text: "Preço",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      colWidth: "75px",
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      selected={selected}
      onSelectAll={onSelectAll}
      onSelect={setSelected}
      multiselect={true}
    />
  );
};
