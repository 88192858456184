import React, { useState } from "react";
import {
  DatePicker,
  FixedField,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  LabelButton,
  ModalBase,
  Table,
  TextInput,
  UnlockToEdit,
} from "../../../../../../components";
import { Col, Row } from "reactstrap";
import moment from "moment";
import {
  formatDateISO,
  formatDateLocal,
  formatNumber,
  formatValueFromAPI,
  MODAL_ACTIONS,
  sumDataField,
} from "../../../../../../coreUtils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addRecebCrediario } from "../store/moedas_rec_slice";
import { apiGet } from "../../../../../../api";
import { showWarning } from "../../../../../../components/AlertaModal";
import UteisService from "../../../../../../services/uteis/UteisService";
import PesqCliente from "../../../../../../components/form/pesq_cliente/PesqCliente";
import CadastroClienteService from "../../../../../../services/cadastro/CadastroClienteService";
import { incluirAlterarClienteRoute } from "../../../../../../routes/modules/cadastro";

const rowStyle = { justifyContent: "center" };

export const RecCrediarioModal = ({
  vlrCrediario,
  isOpen,
  toggle,
  idClienteMov,
}) => {
  const [qtdParcelas, setQtdParcelas] = useState(0);
  const [dataBase, setDataBase] = useState(new Date());
  const [diaFixo, setDiaFixo] = useState(false);
  const [numDias, setNumDias] = useState(30);
  const [carteira, setCarteira] = useState(false);
  const [parcelas, setParcelas] = useState([]);
  const [numero, setNumero] = useState("");

  const [nomeCliente, setNomeCliente] = useState("");
  const [idCliente, setIdCliente] = useState(0);
  const [enderecoCliente, setEnderecoCliente] = useState("");
  const [foneCliente, setFoneCliente] = useState("");
  const [whatsappCliente, setWhatsappCliente] = useState("");
  const [cpfCnpjCli, setCpfCnpjCli] = useState("");
  const [temCrediario, setTemCrediario] = useState(false);
  const [temCredAberto, setTemCredAberto] = useState(false);
  const [qtdCredVencido, setQtdCredVencido] = useState(0);
  const [vlrTotCredVencido, setVlrTotCredVencido] = useState(0);

  const [aceitaCredSemCpfCnpj, setAceitaCredSemCpfCnpj] = useState(false);
  const [aplicaDiferencaParcela, setAplicaDiferencaParcela] = useState("P");
  const [loadingParcelas, setLoadingParcelas] = useState(false);
  const dispatch = useDispatch();

  const limparDadosCliente = () => {
    setNomeCliente("");
    setIdCliente(0);
    setEnderecoCliente("");
    setFoneCliente("");
    setWhatsappCliente("");
    setCpfCnpjCli("");
    setTemCrediario(false);
    setQtdCredVencido(0);
    setVlrTotCredVencido(0);
  };

  const limparDados = () => {
    limparDadosCliente();
    setParcelas([]);
    setQtdParcelas(0);
    setDataBase(new Date());
    setDiaFixo(false);
    setCarteira(false);
    setNumDias(30);
    setNumero("");
  };

  const carregarParametros = async () => {
    const ret = await apiGet("/tela/caixa_loja/receb_crediario/", {});

    setAceitaCredSemCpfCnpj(ret?.aceita_cred_sem_cpf_cnpj ?? false);
    setAplicaDiferencaParcela(ret?.aplica_diferenca_parcela ?? "P");
  };

  const carregarDados = async () => {
    handleSelectCliente(idClienteMov);
  };

  const handleSelectCliente = async (id) => {
    setIdCliente(id);
    const [ok, ret] = await CadastroClienteService.buscarResumoCrediario(id);
    if (ok) {
      setNomeCliente(ret.nome);
      setEnderecoCliente(
        `${ret.endereco_cliente} - ${ret.cidade_cliente}/${ret.uf_cidade}`
      );
      setFoneCliente(ret.fone1);
      setWhatsappCliente(ret.whatsapp);
      setCpfCnpjCli(ret.cpf_cnpj);
      setTemCrediario(ret.tem_crediario);
      setTemCredAberto(ret.tem_cred_aberto);
      setQtdCredVencido(ret.qtd_cred_vencido);
      setVlrTotCredVencido(parseFloat(ret.vlr_tot_cred_vencido));
    } else {
      limparDadosCliente();
    }
  };

  const alterarCadCliente = () => {
    const id = Math.floor(Math.random() * Date.now());
    const cadastro = window.open(incluirAlterarClienteRoute.path);

    cadastro.window.parameters = JSON.stringify({
      id: id,
      action: MODAL_ACTIONS.EDIT,
      selected: idCliente,
    });

    const func = (event) => {
      if (event.origin !== window.location.origin && !event.data?.selected) {
        return;
      }

      if (event.data.id !== id) {
        return;
      }

      handleSelectCliente(idCliente);

      window.removeEventListener("message", func);
    };

    window.addEventListener("message", func);
  };

  const calcularParcelas = async () => {
    if ([0, null, undefined].includes(qtdParcelas)) {
      showWarning(
        "Por favor, informe o número de Parcelas",
        null,
        null,
        "input-qtd-parcelas"
      );
      return false;
    }

    if ([0, null, undefined].includes(numDias)) {
      showWarning(
        `Por favor, informe o ${diaFixo ? "Dia Fixo" : "Intervalo de Dias"}`,
        null,
        null,
        diaFixo ? "dia-fixo" : "input-intervalo"
      );
      return false;
    }

    if (!(dataBase instanceof Date)) {
      showWarning(
        "Por favor, informe a Data Base",
        null,
        null,
        "input-data-base"
      );
      return false;
    }

    setLoadingParcelas(true);

    const payload = {
      qtd_parcelas: qtdParcelas,
      num_dias: numDias,
      dia_fixo: diaFixo,
      valor: vlrCrediario,
      data_base: formatDateISO(dataBase),
      aplicar_diferenca: aplicaDiferencaParcela,
    };

    const [ok, ret] = await UteisService.calcularParcelasPorQtdParcNumDias(
      payload
    );
    if (ok) {
      const faturasDepois = ret.map((e, index) => ({
        id: index,
        parcela: index + 1,
        vencimento: formatDateLocal(e.data_vcto),
        valor: parseFloat(e.valor),
      }));

      setParcelas(faturasDepois);
    }
    setLoadingParcelas(false);
  };

  const handleEditValorParc = (coluna, novoValor, row) => {
    row.valor = novoValor;
    setParcelas(parcelas.map((e) => (e.parcela === row.parcela ? row : e)));
  };

  const handleEditDataParc = (_, newValue, row) => {
    const value = moment(newValue, "DD/MM/YYYY", true);
    if (value.isValid()) {
      setParcelas(
        parcelas.map((e) =>
          e.parcela === row.parcela
            ? { ...row, vencimento: value.format("DD/MM/YYYY") }
            : e
        )
      );
    }
  };

  const onConfirm = () => {
    if (parcelas.length === 0) {
      showWarning(
        "Por favor, clique no botão Calcular Sugestão " +
          "para gerar as parcelas do crediário e tente novamente",
        null,
        null,
        "button-calc-sugest"
      );
      return;
    }

    const payload = {
      id_cliente: idCliente,
      carteira: carteira,
      numero: numero,
      parcelas: parcelas.map((e) => ({
        parcela: e.parcela,
        numero: numero,
        data_vcto: formatDateISO(
          moment(e.vencimento, "DD/MM/YYYY", true).toDate()
        ),
        valor: e.valor,
      })),
    };
    dispatch(addRecebCrediario({ valor: vlrCrediario, dados_rec: payload }));
    toggle();
  };

  const columns = [
    {
      dataField: "parcela",
      text: "Parcela",
      align: "center",
    },
    {
      dataField: "vencimento",
      text: "Vencimento",
      align: "center",
      editable: true,
      editorType: "date",
      onChange: handleEditDataParc,
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      editable: true,
      editorType: "number",
      decimalPlaces: 2,
      onChange: handleEditValorParc,
    },
  ];

  const onKeyDown = (e) => {
    if (e.key === "F4") {
      calcularParcelas();
    }
  };

  useEffect(() => {
    setParcelas([]);
  }, [qtdParcelas, dataBase, diaFixo, numDias]);

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Recebimento em Crediário"
      number="0063_13"
      onConfirm={onConfirm}
      onClosed={limparDados}
      bodyStyle={{ paddingTop: "0.3rem" }}
      onKeyDown={onKeyDown}
      onBeforeOpen={() => {
        carregarParametros();
        carregarDados();
      }}
    >
      <Row className="mb-1">
        <FixedField
          label="Cliente"
          value={formatValueFromAPI(nomeCliente, idCliente)}
          horizontal
        />
        <AlterarClienteModal
          idCliente={idCliente}
          handleSelectCliente={handleSelectCliente}
        />
        <LabelButton md="auto" onClick={alterarCadCliente} tabIndex={8}>
          Cadastro do Cliente
        </LabelButton>
      </Row>
      <Row className="mb-2">
        <FixedField label="Endereço" value={enderecoCliente} horizontal />
      </Row>
      <Row className="mb-2">
        <FixedField
          divStyle={{
            backgroundColor:
              !aceitaCredSemCpfCnpj && !cpfCnpjCli ? "yellow" : "transparent",
          }}
          label="CPF/CNPJ"
          value={cpfCnpjCli}
          horizontal
          md={!aceitaCredSemCpfCnpj && !cpfCnpjCli ? "4" : "auto"}
        />
        <FixedField label="Telefone" value={foneCliente} horizontal />
        <FixedField label="Whatsapp" value={whatsappCliente} horizontal />
      </Row>
      <Row className="mb-2">
        <FixedField
          label="Aviso"
          value={
            [0, null, undefined].includes(idCliente)
              ? ""
              : !temCrediario
              ? "Cliente nunca realizou venda a prazo"
              : !temCredAberto
              ? "Cliente possui crediários e todos estão liquidados"
              : qtdCredVencido <= 0
              ? "Cliente possui crediários em aberto e todos estão em dia"
              : `Cliente possui ${qtdCredVencido} crediários em aberto e atrasados 
                totalizando ${formatNumber(vlrTotCredVencido, true, 2)}`
          }
          textStyle={{ color: qtdCredVencido > 0 ? "red" : "green" }}
          horizontal
        />
      </Row>
      <Row>
        <Col md={6}>
          <Row style={rowStyle}>
            <TextInput
              md={4}
              label="Número"
              value={numero}
              onChange={(e, v) => setNumero(v)}
              maxLength={10}
              inputStyle={{ textAlign: "center" }}
            />
          </Row>
          <Row style={rowStyle}>
            <IntegerFormInput
              md={4}
              label="Parcelas"
              defaultValue={qtdParcelas}
              onChange={setQtdParcelas}
              name={"input-qtd-parcelas"}
            />
          </Row>
          <Row style={rowStyle}>
            <IntegerFormInput
              md={4}
              label={diaFixo ? "Dia Fixo" : "Intervalo de Dias"}
              defaultValue={numDias}
              onChange={setNumDias}
              name={"input-intervalo"}
            />
          </Row>
          <Row style={rowStyle}>
            <UnlockToEdit>
              <DatePicker
                label="Data Base"
                md={4}
                value={dataBase}
                onChange={(v) =>
                  setDataBase(moment.isMoment(v) ? v.toDate() : v)
                }
                name={"input-data-base"}
              />
            </UnlockToEdit>
          </Row>
          <Row style={rowStyle}>
            <FormCheckbox
              md={4}
              divClassName="mt-1 mb-3"
              padded={false}
              label="Dia Fixo"
              checked={diaFixo}
              onChange={() => setDiaFixo(!diaFixo)}
              name="dia-fixo"
            />
          </Row>
          <Row style={rowStyle}>
            <FormCheckbox
              md={4}
              divClassName="mt-1 mb-3"
              padded={false}
              label="Carteira"
              checked={carteira}
              onChange={() => setCarteira(!carteira)}
              name="carteira"
            />
          </Row>
          <Row style={rowStyle}>
            <FormButton
              md="auto"
              padded={false}
              color="primary"
              disabled={
                [0, null, undefined].includes(qtdParcelas) ||
                [0, null, undefined].includes(numDias) ||
                !(dataBase instanceof Date)
              }
              disabledHint={
                [0, null, undefined].includes(qtdParcelas)
                  ? "Informe a quantidade de Parcelas"
                  : [0, null, undefined].includes(numDias)
                  ? `Informe o ${diaFixo ? "Dia Fixo" : "Intervalo de Dias"}`
                  : !(dataBase instanceof Date) &&
                    "Informe a data do Primeiro Vencimento"
              }
              loading={loadingParcelas}
              onClick={calcularParcelas}
            >
              F4 - Calcular Sugestão
            </FormButton>
          </Row>
        </Col>
        <Col md={6}>
          <Table
            data={parcelas}
            columns={columns}
            paginated={false}
            pageSize={10}
            showRegisterCount={false}
          />
          <Row style={{ justifyContent: "space-between" }}>
            <FixedField
              label="Valor a Cobrar"
              value={formatNumber(vlrCrediario, true, 2)}
              horizontal
            />
            <FixedField
              label="Valor Lançado"
              value={formatNumber(sumDataField(parcelas, "valor"), true, 2)}
              horizontal
            />
          </Row>
        </Col>
      </Row>
    </ModalBase>
  );
};

const AlterarClienteModal = ({ idCliente, handleSelectCliente }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [idClienteInternal, setIdClienteInternal] = useState(null);
  const [nomeClienteInternal, setNomeClienteInternal] = useState("");
  const [curingaInternal, setCuringaInternal] = useState(false);

  const onBeforeOpen = () => setIdClienteInternal(idCliente);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = () => {
    if ([0, null, undefined].includes(idClienteInternal)) {
      showWarning("Por favor, informe o Cliente", null, null, "async-cliente");
      return;
    }

    if (curingaInternal) {
      showWarning("O Cliente do Crediário não pode ser Curinga");
      return;
    }

    handleSelectCliente(idClienteInternal);
    toggle();
  };

  return (
    <>
      <LabelButton
        md="auto"
        divClassName="ml-auto"
        onClick={toggle}
        tabIndex={7}
      >
        Mudar Cliente do Crediário
      </LabelButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Alterar Cliente do Recebimento em Crediário"
        onBeforeOpen={onBeforeOpen}
        onConfirm={handleSubmit}
      >
        <Row>
          <PesqCliente
            md={12}
            onConfirm={({ id, nome, curinga }) => {
              setIdClienteInternal(id);
              setNomeClienteInternal(nome);
              setCuringaInternal(curinga);
            }}
            onChangeDescricao={(v) => setNomeClienteInternal(v)}
            idCliente={idClienteInternal}
            nomeCliente={nomeClienteInternal}
          />
        </Row>
      </ModalBase>
    </>
  );
};
