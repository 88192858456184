import { apiGetV2 } from "../../apiV2";

const URL_BASE = "/cadastro/unidade_med";
const CadastroUnidadeService = {
  urlBase: URL_BASE,
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  buscarCasasDecimais: (unidade) =>
    apiGetV2(`${URL_BASE}/buscar_casas_decimais/${unidade}/`),
};

export default CadastroUnidadeService;
