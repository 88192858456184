import React from "react";
import { formatNumber } from "../../../../../../coreUtils";
import { Table } from "../../../../../../components";
import { Badge } from "reactstrap";

const columns = [
  {
    dataField: "id_cli",
    text: "# Cliente",
    align: "center",
    sortable: true,
  },
  {
    dataField: "nome_cli",
    text: "Cliente",
    align: "left",
    sortable: true,
  },
  {
    dataField: "numero",
    text: "Número",
    align: "center",
  },
  {
    dataField: "data_emi",
    text: "Emissão",
    align: "center",
  },
  {
    dataField: "data_vcto",
    text: "Vencimento",
    align: "center",
  },
  {
    dataField: "forma_recebimento",
    text: "Forma de Recebimento",
    align: "left",
    formatter: (c, row) => (
      <>
        {c}
        {row.receb_parcial === true && (
          <Badge color="success" className="ml-2 py-1">
            Recebimento PARCIAL
          </Badge>
        )}
      </>
    ),
  },
  {
    dataField: "vlr_recebido",
    text: "Valor Recebido",
    align: "right",
    formatter: (v) => formatNumber(v, true, 2),
  },
];

export const BoletosRecebidosGrid = ({ dados = [] }) => {
  return (
    <Table
      columns={columns}
      data={dados ?? []}
      keyField="id_venda"
      pageSize={dados.length > 10 ? 10 : dados.length}
      paginated={false}
      showRegisterCount={false}
    />
  );
};
