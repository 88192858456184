import React, { useState } from "react";
import {
  Divider,
  FixedField,
  FormButton,
  ModalBase,
  Table,
} from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { ProdServIcone } from "../../../../../components/ProdServIcone";
import GerenciamentoVendasService from "../../../../../services/vendas/GerenciamentoVendasService";
import { LogsVendaModal } from "./LogsVendaModal";

const columns = [
  {
    dataField: "desc_tipo",
    text: "Tipo",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "item",
    text: "Item",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "tipo_reg",
    text: "T",
    align: "left",
    headerAlign: "left",
    colWidth: "25px",
    formatter: (c) => <ProdServIcone value={c} />,
  },
  {
    dataField: "descricao",
    text: "Descrição",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "quantidade",
    text: "Quantidade",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_unit",
    text: "Vlr. Unit.",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_item",
    text: "Vlr. Item",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_desc",
    text: "Vlr. Desc.",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_acre",
    text: "Vlr. Acrésc.",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_total",
    text: "Vlr. Total",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "qtd_devol",
    text: "Qtde. Devol.",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_devol",
    text: "Vlr. Devol.",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_tot_liq",
    text: "Vlr. Líquido",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const columnsFormasRec = [
  {
    dataField: "desc_moeda",
    text: "Moeda",
    align: "left",
  },
  {
    dataField: "valor",
    text: "Valor",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const headerRowClassName = "mb-1";

export const DetalheVendaModal = ({
  isOpen,
  toggle,
  idVenda,
  mostrarFormasRec = true,
}) => {
  const [dadosVenda, setDadosVenda] = useState({});
  const [logsOpen, setLogsOpen] = useState(false);

  const carregarDados = async () => {
    const [ok, ret] = await GerenciamentoVendasService.buscar(idVenda);
    if (ok) {
      setDadosVenda(ret);
    } else {
      toggle();
    }
  };

  const toggleLogs = () => setLogsOpen(!logsOpen);

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Detalhamento da Venda"
        number="0067_2"
        onBeforeOpen={carregarDados}
        onClosed={() => setDadosVenda({})}
        autoFocus
        footerActions={
          <FormButton padded={false} onClick={toggleLogs}>
            Logs
          </FormButton>
        }
      >
        <Row className={headerRowClassName}>
          <FixedField label="Nro. Venda" value={idVenda} horizontal boldValue />
          <FixedField
            label="Cliente"
            md={7}
            value={formatValueFromAPI(
              dadosVenda.nome_cliente,
              dadosVenda.id_cliente
            )}
            horizontal
          />
          <FixedField
            label="Emissão"
            value={`${dadosVenda.data_emissao} - ${dadosVenda.hora_emissao}`}
            horizontal
          />
        </Row>
        <Row className={headerRowClassName}>
          <FixedField
            label="Vendedor"
            md={4}
            value={formatValueFromAPI(
              dadosVenda.nome_colab_vendedor,
              dadosVenda.id_colab_vendedor
            )}
            horizontal
          />
          <FixedField
            label="Operador"
            md={4}
            value={formatValueFromAPI(
              dadosVenda.nome_operador,
              dadosVenda.operador
            )}
            horizontal
          />
        </Row>
        <Row className={headerRowClassName}>
          <FixedField
            md={3}
            label="ICMS ST"
            value={formatNumber(dadosVenda.vlr_tot_icms_st, true, 2)}
            horizontal
          />
          <FixedField
            md={3}
            label="Desc."
            value={formatNumber(dadosVenda.vlr_desc, true, 2)}
            horizontal
          />
          <FixedField
            md={3}
            label="Frete"
            value={formatNumber(dadosVenda.vlr_frete, true, 2)}
            horizontal
          />
        </Row>
        <Row className={headerRowClassName}>
          <FixedField
            md={3}
            label="Produtos"
            value={formatNumber(dadosVenda.vlr_tot_prod_liq, true, 2)}
            horizontal
          />
          <FixedField
            md={3}
            label="Serviços"
            value={formatNumber(dadosVenda.vlr_tot_serv_liq, true, 2)}
            horizontal
          />
          <FixedField
            md={3}
            label="Total Geral"
            value={formatNumber(dadosVenda.vlr_tot_liq, true, 2)}
            horizontal
          />
        </Row>
        <Row className={headerRowClassName}>
          <FixedField
            md={3}
            label="Devolvido"
            value={formatNumber(dadosVenda.vlr_devol, true, 2)}
            horizontal
          />
          <FixedField
            md={3}
            label="Frete Devol."
            value={formatNumber(dadosVenda.vlr_devol_frete, true, 2)}
            horizontal
          />
        </Row>
        <Row className={headerRowClassName}>
          <FixedField
            label="Origem"
            md={4}
            value={dadosVenda.descr_origem}
            horizontal
          />
          <FixedField
            label="Nro. Origem"
            value={dadosVenda.nro_origem}
            horizontal
          />
        </Row>
        <Row className={headerRowClassName}>
          <FixedField
            label="Situação no Caixa"
            value={dadosVenda.recebido_caixa ? "Recebida" : "Não Recebida"}
            horizontal
          />
          <FixedField
            label="Nro. Caixa"
            value={(dadosVenda.caixa ?? 0).toString()}
            horizontal
          />
        </Row>
        <Row className={headerRowClassName}>
          <FixedField label="Observação" value={dadosVenda.obs} horizontal />
        </Row>
        <Table
          data={dadosVenda?.itens ?? []}
          columns={columns}
          paginated={false}
          showRegisterCount={false}
          pageSize={12}
        />
        {mostrarFormasRec && (
          <>
            <Divider>Formas de Recebimento</Divider>
            <Table
              columns={columnsFormasRec}
              data={dadosVenda?.formas_rec ?? []}
              paginated={false}
              pageSize={5}
              growIntoPageSize
              showRegisterCount={false}
            />
          </>
        )}
      </ModalBase>
      <LogsVendaModal
        isOpen={logsOpen}
        toggle={toggleLogs}
        selected={idVenda}
      />
    </>
  );
};
