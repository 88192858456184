import React from "react";
import { FormCheckbox } from "../../../../components";
import { Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setObrigCnpj,
  setObrigCpf,
  setPadRevendaMarcado,
  setPadGeraBoletoAutomaticamente,
  setPadEmiteNfeMobile,
} from "../store/cadastro_cliente_slice";

export const PersonCadastroCliente = () => {
  const store = useSelector((state) => state.cadastro_cliente);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <FormCheckbox
          padded={false}
          label="Obrigar Informação de CPF"
          checked={store.obrig_cpf}
          onChange={() => dispatch(setObrigCpf(!store.obrig_cpf))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Obrigar Informação de CNPJ"
          checked={store.obrig_cnpj}
          onChange={() => dispatch(setObrigCnpj(!store.obrig_cnpj))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Campo de Revenda marcado por padrão"
          checked={store.pad_revenda_marcado}
          onChange={() =>
            dispatch(setPadRevendaMarcado(!store.pad_revenda_marcado))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label={`Campo "Sempre gerar Impressão dos Boletos automaticamente" marcado por padrão`}
          checked={store.pad_gera_boleto_automaticamente}
          onChange={() =>
            dispatch(
              setPadGeraBoletoAutomaticamente(
                !store.pad_gera_boleto_automaticamente
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label={`Campo "Sempre emitir NF-e no ato da venda (APP Pronta Entrega)" marcado por padrão`}
          checked={store.pad_emite_nfe_mobile}
          onChange={() =>
            dispatch(setPadEmiteNfeMobile(!store.pad_emite_nfe_mobile))
          }
        />
      </Row>
    </>
  );
};
