import React, { useState } from "react";
import {
  AsyncComboBox,
  DatePicker,
  FormButton,
  ModalBase,
} from "../../../../../components";
import { formatDateISO } from "../../../../../coreUtils";
import { showWarning } from "../../../../../components/AlertaModal";
import GerencChequesClientesService from "../../../../../services/financeiro/GerencChequesClientesService";
import { DetalhesChequeClienteForm } from "./DetalhesChequeClienteModal";
import { Row } from "reactstrap";
import moment from "moment";

export const DepositarChequeClienteModal = ({
  selected,
  selectedStatus,
  notifyEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dadosCheque, setDadosCheque] = useState({});
  const [idContaBanc, setIdContaBanc] = useState(null);
  const [dataCompensa, setDataCompensa] = useState(null);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setDadosCheque({});
    setIdContaBanc(null);
    setDataCompensa(null);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const carregarDados = async () => {
    const [ok, ret] = await GerencChequesClientesService.buscar(selected);
    if (!ok) {
      toggle();
      return;
    }
    setDadosCheque(ret);
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idContaBanc)) {
      showWarning(
        "Por favor, informe a Conta Bancária",
        null,
        null,
        "async-conta-banc"
      );
      return;
    }

    if (!(dataCompensa instanceof Date)) {
      showWarning(
        "Por favor, revise a Data de Compensação",
        null,
        null,
        "input-data-compens"
      );
      return;
    }

    const payload = {
      id_cheque_rec: selected,
      id_conta_banc: idContaBanc,
      data_compensa: formatDateISO(dataCompensa),
    };

    setLoading(true);
    const [ok] = await GerencChequesClientesService.depositar(payload);
    if (ok) {
      toggle();
      notifyEvent();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        color="success"
        disabled={
          [0, null, undefined].includes(selected) || selectedStatus !== "A"
        }
        disabledHint={
          [0, null, undefined].includes(selected)
            ? undefined
            : "Somente cheques na situação Em Aberto podem ser depositados"
        }
        onClick={toggle}
      >
        Depositar
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Depositar Cheque de Cliente"
        number="0131_4"
        onBeforeOpen={carregarDados}
        onClosed={limparDados}
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <DetalhesChequeClienteForm
          dadosCheque={dadosCheque}
          mostrarCmc7={false}
        />
        <hr />
        <Row>
          <AsyncComboBox
            label="Conta Bancária"
            md={7}
            concatModelName="conta_banc"
            defaultOptions
            defaultValue={idContaBanc}
            onChange={(s) => setIdContaBanc(s?.value)}
            inputName={"async-conta-banc"}
          />
          <DatePicker
            label="Data de Compensação"
            md={3}
            value={dataCompensa}
            onChange={(v) =>
              setDataCompensa(moment.isMoment(v) ? v.toDate() : v)
            }
            name={"input-data-compens"}
          />
        </Row>
      </ModalBase>
    </>
  );
};
