import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/cred_rotativo";
const URL_BASE_GERENCIAMENTO = `${URL_BASE}/gerenciamento`;

const CredRotativoService = {
  listarMovsPendentes: (idCliente) =>
    apiGetV2(`${URL_BASE}/listar_movs_pendentes/${idCliente}/`),
  gerarFatura: (payload) => apiPostV2(`${URL_BASE}/gerar_fatura/`, payload),
  estornarFaturaRotativo: (idLote) =>
    apiDeleteV2(`${URL_BASE}/estornar_fatura_rotativo/${idLote}/`),
  estornarRecebimento: (payload) =>
    apiPutV2(`${URL_BASE}/estornar_rec_fatura/`, payload),
  gerenciamento: {
    abrirConta: (payload) =>
      apiPostV2(`${URL_BASE_GERENCIAMENTO}/abrir_conta/`, payload),
    alterarLimite: (payload) =>
      apiPutV2(`${URL_BASE_GERENCIAMENTO}/alterar_limite/`, payload),
    buscarDetalhesLote: (idLote) =>
      apiGetV2(`${URL_BASE_GERENCIAMENTO}/buscar_detalhes_fatura/${idLote}/`),
    buscarValoresConta: (idCliente) =>
      apiGetV2(`${URL_BASE_GERENCIAMENTO}/buscar_valores_conta/${idCliente}/`),
    excluirConta: (idCliente) =>
      apiDeleteV2(`${URL_BASE_GERENCIAMENTO}/excluir_conta/${idCliente}/`),
    lancamentoManual: (payload) =>
      apiPostV2(`${URL_BASE_GERENCIAMENTO}/lancamento_manual/`, payload),
    listarContas: (params) =>
      apiGetV2(`${URL_BASE_GERENCIAMENTO}/listar_contas/`, params),
    listarDetalhesContas: (idCliente) =>
      apiGetV2(
        `${URL_BASE_GERENCIAMENTO}/listar_detalhes_contas/${idCliente}/`
      ),
  },
};

export default CredRotativoService;
