import React, { useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import TextInput from "../../TextInput";
import IntegerFormInput from "../../IntegerFormInput";
import BotaoPesq from "../BotaoPesq";
import { ConcatShortcut } from "../../ConcatShortcut";
import { filterFormFocusableElements } from "../../../coreUtils";
import { FixedField } from "../../FixedField";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";

const CamposPesq = ({
  md = 5,
  mdIdent,
  mdDesc,
  mdIdentAux,
  labelIdentificador = "Código",
  labelDescricao = "Descrição",
  pesqPorReferencia = false,
  openModal,
  onExitIdentificador,
  onClickPesq,
  refDescricao,
  refIdentificador,
  refReferencia,
  refIdentificadorAux,
  refReferenciaAux,
  loadingPesq,
  disabledDescricao = true,
  onChangeDescricao,
  autoFocus,
  showShortcut,
  selected,
  disabled,
  disabledHint,
  auxAsLabel,
  pistaPesq,
  setPistaPesq,
  divClassName,
  mostrarAux,
  name
}) => {
  const [triggerOnExit, setTriggerOnExit] = useState(false);
  const shortcutRef = useRef();

  const handleChangeReferencia = (_, v) => {
    if (pistaPesq !== v) {
      setTriggerOnExit(true);
      setPistaPesq(v);
    }
  };

  const handleChangeId = (v) => {
    if (pistaPesq !== v) {
      setTriggerOnExit(true);
      setPistaPesq(v);
    }
  };

  const handleOnExit = async (_, v) => {
    if (triggerOnExit) {
      await onExitIdentificador(_, v);
      setTriggerOnExit(false);
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.key === "F3") {
      e.preventDefault();
      openModal(e.target.value);
    }
    if (e.key === "F4" && shortcutRef.current) shortcutRef.current.click();
    if (e.key === "Enter") {
      const formElements = filterFormFocusableElements(
        e.target.form.elements
      ).filter((el) => el !== e.target);

      if (
        formElements.length === 0 &&
        !["", null, undefined].includes(pistaPesq)
      ) {
        onExitIdentificador(null, pistaPesq);
      }
    }
  };

  const atalhoCadProduto = showShortcut && !disabled && (
    <ConcatShortcut
      selected={selected}
      concatModelName="produto"
      setSelected={(v) => onExitIdentificador(null, v)}
      ref={shortcutRef}
    />
  );

  const campoReferencia = (
    <TextInput
      label={labelIdentificador}
      onChange={handleChangeReferencia}
      onBlur={handleOnExit}
      ref={refReferencia}
      className="text-center"
      colClassName="pr-0"
      additionalButton={
        <>
          <BotaoPesq
            onClick={onClickPesq}
            loading={loadingPesq}
            disabled={disabled}
            disabledHint={disabledHint}
          />
          {atalhoCadProduto}
        </>
      }
      onKeyDown={handleOnKeyDown}
      autoFocus={autoFocus}
      disabled={disabled}
      name={name}
    />
  );

  const campoId = (
    <IntegerFormInput
      label={labelIdentificador}
      onChange={handleChangeId}
      onBlur={handleOnExit}
      ref={refIdentificador}
      colClassName="pr-0"
      additionalButton={
        <>
          <BotaoPesq
            onClick={onClickPesq}
            loading={loadingPesq}
            disabled={disabled}
            disabledHint={disabledHint}
          />
          {atalhoCadProduto}
        </>
      }
      onKeyDown={handleOnKeyDown}
      autoFocus={autoFocus}
      disabled={disabled}
      name={name}
    />
  );

  const campoIdBloq = auxAsLabel ? (
    <AuxLabel md={mdIdentAux} label="# ID" ref={refIdentificadorAux} />
  ) : (
    <IntegerFormInput
      md={mdIdentAux}
      label="# ID"
      disabled
      ref={refIdentificadorAux}
      colClassName="pr-0"
    />
  );
  const campoReferenciaBloq = auxAsLabel ? (
    <AuxLabel md={mdIdentAux} label="Referência" ref={refReferenciaAux} />
  ) : (
    <TextInput
      md={mdIdentAux}
      label="Referência"
      className="text-center"
      disabled
      ref={refReferenciaAux}
      colClassName="pr-0"
    />
  );

  return (
    <Col md={md} className={divClassName}>
      <Row>
        <Col md={mdIdent} style={{ paddingRight: "0.53rem" }}>
          <Row>{pesqPorReferencia ? campoReferencia : campoId}</Row>
        </Col>
        <TextInput
          md={mdDesc}
          label={labelDescricao}
          disabled={disabledDescricao}
          ref={refDescricao}
          colClassName="px-0"
          onChange={(_, v) => onChangeDescricao && onChangeDescricao(v)}
        />
        {mostrarAux && (pesqPorReferencia ? campoIdBloq : campoReferenciaBloq)}
      </Row>
    </Col>
  );
};

const AuxLabel = forwardRef(({ label, md }, ref) => {
  const [value, setValue] = useState("");

  useImperativeHandle(ref, () => ({
    setValue: (v) => setValue(v),
  }));

  return value ? (
    <FixedField
      divClassName="pt-4"
      label={label}
      md={md}
      value={value}
      horizontal
    />
  ) : (
    <></>
  );
});

export default CamposPesq;
