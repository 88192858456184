import React, { useEffect, useState } from "react";
import { Row, Col, Label } from "reactstrap";
import { ModalBase } from "../../ModalBase";
import { Table } from "../../Table";
import SearchInput from "../../SearchInput";
import CadastroProdutosService from "../../../services/cadastro/CadastroProdutosService";
import {
  MODAL_ACTIONS,
  defaultDebounceTime,
  formatarMsgTotalProdutoCliente,
  formatarValor,
  naturalSort,
  useDebounce,
  userPodeVisHistorVendaCompra,
} from "../../../coreUtils";
import TextInput from "../../TextInput";
import { incluirAlterarProdutoRoute } from "../../../routes/modules/cadastro";
import FormButton from "../../FormButton";
import { FormCheckbox } from "../../FormCheckbox";
import { ComprasVendasProdutoModal } from "../../../pages/compras/entrada_nf_xml/produtos/components/ComprasVendasProdutoModal";
import { connect } from "react-redux";
import { ContextMenu } from "../../../redux/reducers/menuReducer";
import { useSingleRequest } from "../../../apiV2";

const hintNaoLiberado =
  "Seu perfil ou plano não permite acesso ao Cadastro de Produtos";

const ModalPesqProduto = ({
  isOpen,
  toggle,
  selectProd,
  pistaPadrao,
  onClose,
  sistAutomotivo,
  utilizaCorProd,
  utilizaTamanhoProd,
  utilizaPrecoAtacado,
  tipoVendaCliente,
  menus,
}) => {
  const cadastroLiberado =
    (menus ?? []).find((e) => e.nroTela === "0029") !== undefined;
  const [pista, setPista] = useState("");
  const [mostrarCusto, setMostrarCusto] = useState(false);
  const [mostrarInativos, setMostrarInativos] = useState(false);
  const [somenteEmEstoque, setSomenteEmEstoque] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [msgTotal, setMsgTotal] = useState("");
  const [similares, setSimilares] = useState([]);
  const [aplicacoes, setAplicacoes] = useState([]);
  const [tableId] = useState("table-" + Math.floor(Math.random() * Date.now()));

  const [comprasVendasOpen, setComprasVendasOpen] = useState(false);

  // Piloto do useSingleRequest. Se funcionar, disseminar a outras telas
  const singleRequestWrapper = useSingleRequest();

  const internalToggle = (selecionouProd) => {
    setProdutos([]);
    setMsgTotal("");
    setSimilares([]);
    setAplicacoes([]);

    toggle(selecionouProd);
    setPista("");
    setMostrarCusto(false);
    setMostrarInativos(false);
    setSomenteEmEstoque(false);
  };

  const styleVendaCliente = (tipoVenda) =>
    utilizaPrecoAtacado &&
    tipoVendaCliente === tipoVenda && {
      fontWeight: "bold",
      color: "#002a57",
    };

  const _columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      colWidth: "60px",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
      colWidth: "120px",
      hidden: !sistAutomotivo,
      sortable: true,
    },
    {
      dataField: "nome",
      text: "Nome do Produto",
      align: "left",
      colWidth: sistAutomotivo ? "320px" : "330px",
      sortable: true,
    },
    {
      dataField: "tamanho",
      text: "Tam",
      align: "center",
      colWidth: "50px",
      sortable: true,
      hidden: sistAutomotivo ? true : !utilizaTamanhoProd,
    },
    {
      dataField: "cor",
      text: "Cor",
      align: "center",
      colWidth: "80px",
      sortable: true,
      hidden: sistAutomotivo ? true : !utilizaCorProd,
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
      colWidth: "30px",
      colHeaderStyle: { paddingInline: 0 },
      style: { paddingInline: 0 },
      sortable: true,
    },
    {
      dataField: "nome_grupo",
      text: "Grupo",
      align: "left",
      colWidth: sistAutomotivo ? "150px" : "160px",
      sortable: true,
    },
    {
      dataField: "nome_fabricante",
      text: "Fabricante",
      align: "left",
      colWidth:
        utilizaCorProd && utilizaTamanhoProd
          ? "135px"
          : sistAutomotivo
          ? "150px"
          : "160px",
      sortable: true,
    },
    {
      dataField: "custo_ue",
      text: "Custo",
      align: "right",
      formatter: (v) => formatarValor(v, 2),
      colWidth: "70px",
      sortable: true,
      sortFunc: naturalSort,
      hidden: !mostrarCusto,
    },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      formatter: (v) => formatarValor(v, 0),
      colWidth: "50px",
      style: (c) => parseFloat(c ?? 0) <= 0 && { color: "red" },
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "qtd_reserva",
      text: "Reserva",
      align: "center",
      formatter: (v) => formatarValor(v, 0),
      colWidth: "60px",
      sortable: true,
      sortFunc: naturalSort,
      hideSortIcon: true,
    },
    {
      dataField: "preco_venda",
      text: !utilizaPrecoAtacado || sistAutomotivo ? "Preço" : "Valor",
      align: "right",
      formatter: (v) => formatarValor(v, 2),
      colWidth: "70px",
      sortable: true,
      sortFunc: naturalSort,
      style: styleVendaCliente("V"),
    },
    {
      dataField: "preco_atacado",
      text: "Atacado",
      align: "right",
      formatter: (v) => formatarValor(v, 2),
      colWidth: "70px",
      sortable: true,
      sortFunc: naturalSort,
      hidden: !utilizaPrecoAtacado || sistAutomotivo,
      style: styleVendaCliente("A"),
    },
  ];

  const _columnsSimilar = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      colWidth: "60px",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
      colWidth: "120px",
      sortable: true,
    },
    {
      dataField: "nome",
      text: "Nome do Produto - Similar",
      align: "left",
      colWidth: "330px",
      sortable: true,
    },
    {
      dataField: "nome_grupo",
      text: "Grupo",
      align: "left",
      colWidth: "150px",
      sortable: true,
    },
    {
      dataField: "nome_fabricante",
      text: "Fabricante",
      align: "left",
      colWidth: "150px",
      sortable: true,
    },
    {
      dataField: "custo_ue",
      text: "Custo",
      align: "right",
      formatter: (v) => formatarValor(v, 2),
      colWidth: "70px",
      sortable: true,
      sortFunc: naturalSort,
      hidden: !mostrarCusto,
    },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      formatter: (v) => formatarValor(v, 0),
      colWidth: "50px",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "qtd_reserva",
      text: "Reserva",
      align: "center",
      formatter: (v) => formatarValor(v, 0),
      colWidth: "60px",
      sortable: true,
      sortFunc: naturalSort,
      hideSortIcon: true,
    },
    {
      dataField: "preco_venda",
      text: !utilizaPrecoAtacado ? "Preço" : "Valor",
      align: "right",
      formatter: (v) => formatarValor(v, 2),
      colWidth: "70px",
      sortable: true,
      sortFunc: naturalSort,
      style: styleVendaCliente("V"),
    },
    {
      dataField: "preco_atacado",
      text: "Atacado",
      align: "right",
      formatter: (v) => formatarValor(v, 2),
      colWidth: "70px",
      sortable: true,
      sortFunc: naturalSort,
      hidden: !utilizaPrecoAtacado,
      style: styleVendaCliente("A"),
    },
  ];

  const _columnsAplicacoes = [
    { dataField: "nome_aplic", text: "Modelo", align: "left" },
    { dataField: "ano_ini_aplic", text: "De", align: "center" },
    { dataField: "ano_fim_aplic", text: "Até", align: "center" },
  ];

  const handlePistaChange = useDebounce((v) => {
    setPista(v);
    carregarDados(v);
  }, defaultDebounceTime);

  const handleSelectProduto = (value, checked, row) => {
    if (checked) {
      setSelected(row);
      setSimilares(row?.similares ?? []);
      setAplicacoes(row?.aplicacoes ?? []);
    } else {
      setSelected(null);
      setSimilares([]);
      setAplicacoes([]);
    }
  };

  const handleSelectSimilar = (value, checked, row) => {
    if (checked) {
      setSelected(row);
    }
  };

  const carregarDados = async (p) => {
    setLoading(true);

    const params = {
      pista: p,
      mostrar_inativos: mostrarInativos,
      somente_em_estoque: somenteEmEstoque,
    };

    const getFunc = sistAutomotivo
      ? CadastroProdutosService.listarAutomotivo
      : CadastroProdutosService.listar;

    const [ok, ret] = await singleRequestWrapper((options) =>
      getFunc(params, options)
    );

    if (ok) {
      setProdutos(ret.produtos);
      setMsgTotal(
        formatarMsgTotalProdutoCliente({
          descricao: "produto",
          qtdEncontrada: ret.produtos.length,
          qtdTotal: ret.qtd_total,
          somenteAtivos: !mostrarInativos,
        })
      );
    } else {
      setProdutos([]);
      setMsgTotal("");
    }

    if (!ok) {
      if (ret.id_err === "ReqCancel") {
        setLoading(true);
      }
    }
    setLoading(false);
  };

  const onChangeFiltros = () => {
    if (isOpen) {
      carregarDados(pista);
    }
  };

  useEffect(onChangeFiltros, [mostrarInativos, somenteEmEstoque]);

  const onBeforeOpen = () => {
    if (pistaPadrao) {
      setPista(pistaPadrao);
    }
    carregarDados(pistaPadrao);
  };

  const handleConfirm = (value, row) => {
    internalToggle(true);
    if (selectProd) {
      if (value && row) {
        // Seleção por duplo clique na grade
        selectProd(
          value,
          row.nome,
          row.referencia,
          parseFloat(row.preco_venda),
          row.unidade,
          row?.curinga,
          parseFloat(row?.custo ?? row?.custo_ue),
          row?.id_cfop,
          row?.clas_fiscal,
          parseFloat(row?.quantidade),
          row?.cod_bar,
          row?.qtd_casas_decimais_qtd ?? 2,
          row?.qtd_casas_decimais_vlrs ?? 2,
          null,
          null,
          parseFloat(row?.preco_min_permitido),
          parseFloat(row?.preco_atacado),
          parseFloat(row?.perc_lucro),
          parseFloat(row?.perc_lucro_atacado),
          parseFloat(row?.perc_desc_max_vend)
        );
      } else {
        selectProd(
          selected.id,
          selected.nome,
          selected.referencia,
          parseFloat(selected.preco_venda),
          selected.unidade,
          selected?.curinga,
          parseFloat(selected?.custo ?? selected?.custo_ue),
          selected?.id_cfop,
          selected?.clas_fiscal,
          parseFloat(selected?.quantidade),
          selected?.cod_bar,
          selected?.qtd_casas_decimais_qtd ?? 2,
          selected?.qtd_casas_decimais_vlrs ?? 2,
          null,
          null,
          parseFloat(selected?.preco_min_permitido),
          parseFloat(selected?.preco_atacado),
          parseFloat(selected?.perc_lucro),
          parseFloat(selected?.perc_lucro_atacado),
          parseFloat(selected?.perc_desc_max_vend)
        );
      }
    }
  };

  const incluirAlterarProduto = (action) => {
    const id = Math.floor(Math.random() * Date.now());
    const cadastro = window.open(incluirAlterarProdutoRoute.path);
    cadastro.window.parameters = JSON.stringify({
      id: id,
      action: action,
      selected: selected.id,
    });

    const func = async (event) => {
      if (event.origin !== window.location.origin && !event.data?.selected) {
        return;
      }

      if (event.data.id !== id) {
        return;
      }

      await carregarDados(pista);

      window.removeEventListener("message", func);
    };

    window.addEventListener("message", func);
  };

  const toggleComprasVendas = () => setComprasVendasOpen(!comprasVendasOpen);

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={() => internalToggle(false)}
        size="xxl"
        centered={false}
        title="Pesquisa de Produtos"
        number="PesqProd"
        onConfirm={handleConfirm}
        onBeforeOpen={onBeforeOpen}
        onClosed={onClose}
        confirmButtonText="Importar"
        onKeyDown={(e) => {
          if (e.key === "F9") {
            handleConfirm();
          } else if (e.key === "F2" && selected.id) {
            toggleComprasVendas();
          }
        }}
        returnFocusAfterClose={false}
        footerActions={
          <div style={{ display: "flex", margin: "auto" }}>
            {userPodeVisHistorVendaCompra() && (
              <FormButton
                color="primary"
                onClick={toggleComprasVendas}
                disabled={[0, null, undefined].includes(selected?.id)}
                disabledHint="Selecione um produto"
                divClassName="mx-5"
                padded={false}
              >
                F2 - Compra / Venda
              </FormButton>
            )}
            <FormButton
              padded={false}
              onClick={() => incluirAlterarProduto(MODAL_ACTIONS.ADD)}
              md="auto"
              color="info"
              disabled={!cadastroLiberado}
              disabledHint={hintNaoLiberado}
            >
              Cadastrar Produto
            </FormButton>
            <FormButton
              padded={false}
              onClick={() => incluirAlterarProduto(MODAL_ACTIONS.EDIT)}
              md="auto"
              color="warning"
              disabled={
                !cadastroLiberado || [0, null, undefined].includes(selected?.id)
              }
              disabledHint={
                !cadastroLiberado
                  ? hintNaoLiberado
                  : "Selecione um produto da lista"
              }
            >
              Alterar Cadastro do Produto
            </FormButton>
          </div>
        }
      >
        <Row className="mb-2">
          <SearchInput
            label="Pista"
            onChange={handlePistaChange}
            loading={loading}
            value={pista}
          />
          <FormCheckbox
            label="Mostrar Custo"
            checked={mostrarCusto}
            onChange={() => setMostrarCusto(!mostrarCusto)}
            tabIndex={100}
          />
          <FormCheckbox
            label="Mostrar Inativos"
            checked={mostrarInativos}
            onChange={() => setMostrarInativos(!mostrarInativos)}
          />
          <FormCheckbox
            label="Com Estoque"
            checked={somenteEmEstoque}
            onChange={() => setSomenteEmEstoque(!somenteEmEstoque)}
          />
        </Row>
        <Table
          id={tableId}
          columns={_columns}
          data={produtos}
          paginated={false}
          pageSize={12}
          onSelect={handleSelectProduto}
          clipText
          clearSelectionOnUpdate
          onRowEnterPress={handleConfirm}
          onRowDoubleClick={handleConfirm}
          recordCountFormatter={() => msgTotal}
        />
        {sistAutomotivo && (
          <>
            <Table
              columns={_columnsSimilar}
              data={similares}
              paginated={false}
              pageSize={3}
              fixedSize
              onSelect={handleSelectSimilar}
              clipText
              clearSelectionOnUpdate
              showRegisterCount={false}
              onRowEnterPress={handleConfirm}
              onRowDoubleClick={handleConfirm}
            />
            <Col className="mt-2">
              <Row>
                <Col
                  md="auto"
                  className="pl-0 pr-0"
                  style={{ paddingTop: "5px" }}
                >
                  <Label>Referências</Label>
                </Col>
                <TextInput
                  md={2}
                  type="text"
                  inline
                  hiddenLabel
                  colClassName="pl-1 pr-1"
                  disabled
                  className="text-center"
                  value={selected?.ref_aux_1}
                />
                <TextInput
                  md={2}
                  type="text"
                  inline
                  hiddenLabel
                  colClassName="pl-1 pr-1"
                  disabled
                  className="text-center"
                  value={selected?.ref_aux_2}
                />
                <TextInput
                  md={2}
                  type="text"
                  inline
                  hiddenLabel
                  colClassName="pl-1 pr-1"
                  disabled
                  className="text-center"
                  value={selected?.ref_aux_3}
                />
                <TextInput
                  md={2}
                  type="text"
                  inline
                  hiddenLabel
                  colClassName="pl-1 pr-0"
                  disabled
                  className="text-center"
                  value={selected?.ref_aux_4}
                />
                <Col
                  md="auto"
                  className="pl-0 ml-auto pr-0"
                  style={{ paddingTop: "5px" }}
                >
                  <Label>Local</Label>
                </Col>
                <TextInput
                  md={2}
                  type="text"
                  inline
                  hiddenLabel
                  colClassName="pl-1 pr-0"
                  disabled
                  className="text-center"
                  value={selected?.local}
                />
              </Row>
            </Col>
          </>
        )}
        <Row>
          <TextInput
            md={7}
            type="textarea"
            value={selected?.observ}
            rows={5}
            label="Observação"
            disabled
          />
          {!sistAutomotivo && (
            <TextInput
              label="Local"
              md={2}
              type="text"
              colClassName="pl-1 pr-0"
              disabled
              className="text-center"
              value={selected?.local}
            />
          )}
          {sistAutomotivo && (
            <Col md={5}>
              <Label>Aplicações</Label>
              <Table
                columns={_columnsAplicacoes}
                data={aplicacoes}
                paginated={false}
                pageSize={3}
                fixedSize
                clipText
                showRegisterCount={false}
              />
            </Col>
          )}
        </Row>
      </ModalBase>
      <ComprasVendasProdutoModal
        isOpen={comprasVendasOpen}
        toggle={toggleComprasVendas}
        selected={selected}
      />
    </>
  );
};

export default connect((store) => ({ menus: store.menus }), null, null, {
  context: ContextMenu,
})(ModalPesqProduto);
