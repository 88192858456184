import React from "react";
import { formatNumber, naturalSort } from "../../../../../../../coreUtils";
import { Table } from "../../../../../../../components";

export const CompensacaoRecebGrid = ({
  dados,
  setSelected,
  selected
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "conta",
      text: "Conta",
      align: "left",
      sortable: true,
    },
    {
      dataField: "id_cliente",
      text: "Cliente",
      align: "left",
      sortable: true,
    },
    {
      dataField: "nome_cli",
      text: "Nome",
      align: "left",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "data_mov",
      text: "Data",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
  ];

  return (
    <Table
      columns={columns}
      data={dados}
      onSelect={setSelected}
      multiselect
      selected={selected}
      pageSize={15}
    />
  );
};

