import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  obrig_cpf: false,
  obrig_cnpj: false,
  pad_revenda_marcado: false,
  pad_gera_boleto_automaticamente: false,
  pad_emite_nfe_mobile: false,
};

export const cadastroClienteSlice = createSlice({
  name: "cadastro_cliente_slice",
  initialState: initialState,
  reducers: {
    setupCadastroCliente: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setObrigCpf: (state, action) => {
      state.obrig_cpf = action.payload;
    },
    setObrigCnpj: (state, action) => {
      state.obrig_cnpj = action.payload;
    },
    setPadRevendaMarcado: (state, action) => {
      state.pad_revenda_marcado = action.payload;
    },
    setPadGeraBoletoAutomaticamente: (state, action) => {
      state.pad_gera_boleto_automaticamente = action.payload;
    },
    setPadEmiteNfeMobile: (state, action) => {
      state.pad_emite_nfe_mobile = action.payload;
    },
  },
});

export const {
  setupCadastroCliente,
  setObrigCpf,
  setObrigCnpj,
  setPadRevendaMarcado,
  setPadGeraBoletoAutomaticamente,
  setPadEmiteNfeMobile,
} = cadastroClienteSlice.actions;

export default cadastroClienteSlice.reducer;
