import React from "react";
import { useState } from "react";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import {
  AsyncComboBox,
  IntegerFormInput,
  MaskedInput,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import { PesqModal } from "../../../../../components/PesqModal";
import { modalTitleCidade, routesBaseCidade } from "../Cidade";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { showWarning } from "../../../../../components/AlertaModal";

export const CadastroCidadeModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
  concatShortcut,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [nome, setNome] = useState("");
  const [regiao, setRegiao] = useState("");
  const [uf, setUf] = useState("");
  const [codIbge, setCodIbge] = useState(0);
  const [idPais, setIdPais] = useState(1);

  const limparDados = () => {
    setAtivo(true);
    setNome("");
    setRegiao("");
    setUf("");
    setCodIbge(0);
    setIdPais(1);
  };

  const fetchData = (data) => {
    setAtivo(data.ativo);
    setNome(data.nome);
    setRegiao(data.regiao ?? "");
    setUf(data.uf?.trim() ?? "");
    setCodIbge(data.cod_ibge);
    setIdPais(data.id_pais ?? 1);
  };

  const submitPayload = (action) => {
    if (["", null, undefined].includes(nome)) {
      showWarning(
        "Por favor, informe o Nome da Cidade",
        null,
        null,
        "input-nome-cidade"
      );
      return;
    }

    if (["", null, undefined].includes(uf)) {
      showWarning("Por favor, informe a UF", null, null, "input-uf-cidade");
      return;
    }

    if ([0, null, undefined].includes(idPais)) {
      showWarning("Por favor, informe o País", null, null, "async-pais-cidade");
      return;
    }

    const payload = {
      ativo: ativo,
      nome: nome,
      regiao: regiao,
      uf: uf,
      cod_ibge: codIbge ?? 0,
      id_pais: idPais,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleCidade}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseCidade}
      number="0060_1"
      selected={selected}
      notifyEvent={notifyEvent}
      concatShortcut={concatShortcut}
      concatModelName="cidade"
    >
      <Row>
        <TextInput
          md={12}
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          name="input-nome-cidade"
          maxLength={50}
        />
      </Row>
      <Row>
        <MaskedInput
          mask="aa"
          label="UF"
          md={2}
          value={uf}
          onChange={(e, v) => setUf(v)}
          maskChar={null}
          hint="Se Exterior, informar EX"
          name={"input-uf-cidade"}
        />
        <AsyncComboBox
          md={6}
          label="País"
          isConcatField
          concatModelName="pais"
          isSearchable
          onChange={(s) => setIdPais(s?.value)}
          defaultValue={idPais}
          defaultOptions
          inputName={"async-pais-cidade"}
        />
        <IntegerFormInput
          md={3}
          label="Cod. IBGE"
          defaultValue={codIbge}
          onChange={setCodIbge}
          hint="Se Exterior, informar 9999999"
          maxLength={7}
          additionalButton={
            <PesqModal
              route="/cadastro/ibge/listar/"
              keyField="codigo"
              labelField="nome_cid"
              title="Código IBGE"
              value={codIbge}
              onChange={(v) => setCodIbge(v)}
              terc="uf_cid"
              tercText="UF"
            />
          }
        />
      </Row>
      <Row>
        <TextInput
          md={7}
          label="Região"
          value={regiao}
          onChange={(e, v) => setRegiao(v)}
          maxLength={30}
        />
      </Row>
    </ModalCadastroV2>
  );
};
