import React, { useState } from "react";
import CadastroRegraPromocaoService from "../../../../../services/cadastro/CadastroRegraPromocaoService";
import { Row } from "reactstrap";
import moment from "moment";
import { DatePicker, ModalBase } from "../../../../../components";
import { dateFromLocaleString, formatDateISO } from "../../../../../coreUtils";
import { showWarning } from "../../../../../components/AlertaModal";

export const AlterarPeriodoRegraPromocaoModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setDataIni(null);
    setDataFim(null);
  };

  const carregarDados = async () => {
    const [ok, ret] = await CadastroRegraPromocaoService.buscarCab(selected);
    if (ok) {
      setDataIni(dateFromLocaleString(ret.data_inicio));
      setDataFim(dateFromLocaleString(ret.data_final));
    } else {
      toggle();
    }
  };

  const handleSubmit = async () => {
    if (!(dataIni instanceof Date)) {
      showWarning(
        "Por favor, revise a data de Início",
        null,
        null,
        "input-data-inicio"
      );
      return false;
    }

    if (!(dataFim instanceof Date)) {
      showWarning(
        "Por favor, revise a data de Fim",
        null,
        null,
        "input-data-fim"
      );
      return false;
    }

    const payload = {
      id_regra_desc: selected,
      data_ini: formatDateISO(dataIni),
      data_fim: formatDateISO(dataFim),
    };

    setLoading(true);
    const [ok] = await CadastroRegraPromocaoService.alterarPeriodo(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Alterar Período da Regra de Promoção"
      number="0078_4"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <Row>
        <DatePicker
          md={6}
          label="Início"
          value={dataIni}
          onChange={(v) => setDataIni(moment.isMoment(v) ? v.toDate() : v)}
          name={"input-data-inicio"}
        />
        <DatePicker
          md={6}
          label="Fim"
          value={dataFim}
          onChange={(v) => setDataFim(moment.isMoment(v) ? v.toDate() : v)}
          name={"input-data-fim"}
        />
      </Row>
    </ModalBase>
  );
};
