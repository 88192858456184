import { api } from "../../../api";
import { toastr } from "react-redux-toastr";
import { apiGetV2 } from "../../../apiV2";

const processaRetorno = (ret, returnType) => {
  if (ret.data.success || (ret.data.hasOwnProperty("err") && !ret.data.err)) {
    if (returnType) {
      return ret.data.res;
    } else {
      toastr.success("Sucesso", ret.data.msg);
      return true;
    }
  } else {
    toastr.info("", ret.data.msg);
    return returnType ?? false;
  }
};

const processaErro = (err, returnType) => {
  toastr.error("Erro", err.message);
  return returnType ?? false;
};

const ComissaoGerenciaService = {
  buscarGerentes: async () => {
    return await api
      .get("/comissao/regra_gerente/gerentes/?format_to_select=true")
      .then((ret) => processaRetorno(ret, []))
      .catch((err) => processaErro(err, []));
  },
  buscarComissao: async (supervisor, params) =>
    apiGetV2(`/relatorios/venda/comissao/gerente/${supervisor}/`, params),
};

export default ComissaoGerenciaService;
