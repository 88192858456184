import React, { useCallback, useEffect, useState } from "react";
import { Table } from "../../../../components/Table";
import { formatarValor } from "../../../../coreUtils";
import OrdemServicoService from "../../../../services/ordem_servico/OrdemServicoService";
import { ProdServIcone } from "../../../../components/ProdServIcone";
import { UncontrolledTooltip } from "reactstrap";

const TableItensCentralOS = ({ idOs, mecanica }) => {
  const [dados, setDados] = useState([]);

  const carregarDados = useCallback(async () => {
    const [ok, ret] = await OrdemServicoService.itens.resumo(idOs);
    setDados(ok ? ret : []);
  }, [idOs]);

  useEffect(() => {
    carregarDados();
  }, [carregarDados]);

  const columns = [
    {
      dataField: "tipo",
      text: "T",
      align: "left",
      headerAlign: "left",
      colWidth: "30px",
      formatter: (c) => <ProdServIcone value={c} />,
    },
    {
      dataField: "id_item",
      text: "#",
      align: "center",
      headerAlign: "center",
      colWidth: "60px",
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
      headerAlign: "center",
      hidden: !mecanica,
      colWidth: "75px",
      formatter: (c, row) => (
        <>
          <span
            id={`${row.tipo}-${row.id_item}`}
            style={
              row.eh_ref_especial ? { color: "blue", fontWeight: "bold" } : {}
            }
            className="px-1"
          >
            {c}
          </span>
          {row.eh_ref_especial === true && (
            <UncontrolledTooltip
              target={`${row.tipo}-${row.id_item}`}
              placement="right"
            >
              Referência Especial
            </UncontrolledTooltip>
          )}
        </>
      ),
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      colWidth: "200px",
    },
    {
      dataField: "observ",
      text: "Obs.",
      align: "left",
      headerAlign: "left",
      colWidth: "450px",
    },
    {
      dataField: "tecnico",
      text: "Técnico",
      align: "left",
      headerAlign: "left",
      colWidth: "250px",
    },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatarValor(c, 4, 0),
      colWidth: "75px",
    },
    {
      dataField: "vlr_item",
      text: "Vlr Item",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 4, 2),
      colWidth: "75px",
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2),
      colWidth: "75px",
    },
  ];
  return (
    <Table
      headerStyle={{ borderTop: "none" }}
      data={dados}
      columns={columns}
      paginated={false}
      pageSize={dados.length}
      fixedSize={false}
      bgStyle={{ borderRadius: "none" }}
    />
  );
};

export default TableItensCentralOS;
