import React from "react";
import { Table } from "../../components";
import { naturalSort } from "../../coreUtils";
import { formatarValor } from "../../coreUtils";

const ItensKitGrid = ({ data = [] }) => {
  const columns = [
    {
      dataField: "id_produto",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome_produto",
      text: "Produto",
      align: "left",
      sortable: true,
    },
    {
      dataField: "qtd_prod",
      text: "Quantidade",
      align: "center",
      formatter: (c, row) =>
        formatarValor(c, row.casas_decimais_qtd ?? 2, 0, true),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_unit",
      text: "Vlr. Unitário",
      align: "right",
      formatter: (c, row) =>
        formatarValor(
          c,
          row.casas_decimais_vlrs ?? 4,
          row.casas_decimais_vlrs ?? 2,
          true
        ),
    },
    {
      dataField: "perc_desc",
      text: "% Desconto",
      align: "center",
      formatter: (c) => `${formatarValor(c, 2, 2, true)}%`,
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_item",
      text: "Vlr. Item",
      align: "right",
      formatter: (c, row) =>
        formatarValor(
          c,
          row.casas_decimais_vlrs ?? 4,
          row.casas_decimais_vlrs ?? 2,
          true
        ),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      paginated={false}
      pageSize={data.length}
      showRegisterCount={false}
      growIntoPageSize
    />
  );
};

export const PesqKitProdutoGrid = ({ data, setSelected }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      sortable: true,
    },
    {
      dataField: "qtd_itens",
      text: "Qtde Itens",
      align: "center",
      sortable: true,
      colWidth: "90px",
      sortFunc: naturalSort,
      fixedColWidth: true,
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      clearSelectionOnUpdate
      pageSize={8}
      canExpand
      expandedAreaBuilder={(row) => <ItensKitGrid data={row.itens || []} />}
    />
  );
};
