import React from "react";
import {
  BotaoImprimir,
  FixedField,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { useState } from "react";
import OrdemServicoService from "../../../../services/ordem_servico/OrdemServicoService";
import { useEffect } from "react";
import { HistoricoVeiculoGrid } from "./components/HistoricoVeiculoGrid";
import { docPrintReport } from "../../../../pdf";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const HistoricoVeiculo = ({ location }) => {
  const windowParams = window.parameters ? JSON.parse(window.parameters) : {};
  const placa = location?.state?.placa ?? windowParams.placa ?? null;
  const [modelo, setModelo] = useState("");
  const [chassi, setChassi] = useState("");
  const [ano, setAno] = useState("");
  const [combustivel, setCombustivel] = useState("");
  const [cor, setCor] = useState("");
  const [nomeVeiculo, setNomeVeiculo] = useState("");
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [imprimirReferencia, setImprimirReferencia] = useState(false);
  const history = useHistory();

  const carregarDados = async () => {
    const [ok, ret] = await OrdemServicoService.historicoVeiculo(placa);
    if (!ok) {
      history.goBack();
      return false;
    }
    setModelo(ret.modelo);
    setChassi(ret.chassi);
    setAno(ret.ano);
    setCombustivel(ret.combustivel);
    setCor(ret.cor);
    setNomeVeiculo(ret.nome_veiculo);
    setDados(ret.ordens_serv);
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const payload = {
      dados: {
        placa: placa,
        modelo: modelo,
        chassi: chassi,
        ano: ano,
        combustivel: combustivel,
        cor: cor,
        nome_veiculo: nomeVeiculo,
        ordens_serv: dados,
      },
      imprimir_referencia: imprimirReferencia,
    };
    await docPrintReport(
      "/ordem_servico/historico_veiculo/",
      payload,
      "0006_7"
    );
    setLoadingImprimir(false);
  };

  const onActivate = () => {
    carregarDados();
  };

  useEffect(onActivate, []);

  return (
    <PageContainer
      title="Histórico do Veículo"
      number="0006_7"
      canGoBack
      loading={loading}
    >
      <Card body>
        <Row>
          <FixedField
            label="Placa"
            value={placa}
            horizontal
            divClassName="pt-1"
          />
          <FixedField
            label="Veículo"
            value={nomeVeiculo}
            horizontal
            divClassName="pt-1"
          />
          <FixedField
            label="Modelo"
            value={modelo}
            horizontal
            divClassName="pt-1"
          />
          <FixedField label="Cor" value={cor} horizontal divClassName="pt-1" />
          <FixedField label="Ano" value={ano} horizontal divClassName="pt-1" />
          <FixedField
            label="Chassi"
            value={chassi}
            horizontal
            divClassName="pt-1"
          />
          <FixedField
            label="Combustível"
            value={combustivel}
            horizontal
            divClassName="pt-1"
          />
          <BotaoImprimir
            padded={false}
            onClick={imprimir}
            loading={loadingImprimir}
          />
          <FormCheckbox
            padded={false}
            label="Imprimir Referências dos Produtos"
            checked={imprimirReferencia}
            onChange={() => setImprimirReferencia(!imprimirReferencia)}
          />
        </Row>
      </Card>
      <Card body>
        <HistoricoVeiculoGrid dados={dados} />
      </Card>
    </PageContainer>
  );
};
