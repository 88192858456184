import React, { useState } from "react";
import { Label, Row } from "reactstrap";
import {
  AsyncComboBox,
  DatePicker,
  FixedField,
  FormButton,
  FormCheckbox,
  ModalBase,
  NumberInput,
  Table,
  TableDelete,
  TextInput,
} from "../../../../../../components";
import moment from "moment";
import {
  formatDateISO,
  formatNumber,
  sumDataField,
} from "../../../../../../coreUtils";
import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import { addRecebCheque } from "../store/moedas_rec_slice";
import { useRef } from "react";

const ChequesGrid = ({ cheques, handleDelete }) => {
  const columns = [
    {
      dataField: "nro_cheque",
      text: "Nº Cheque",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_banco",
      text: "Banco",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "cpf_cnpj",
      text: "CPF/CNPJ",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "agencia",
      text: "Agência",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nro_conta",
      text: "Conta Corrente",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "data_vcto",
      text: "Vencimento",
      align: "center",
      headerAlign: "center",
      formatter: (c) => moment(c).format("DD/MM/YYYY"),
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
      footter: () => "TESTE 123",
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.nro_cheque)} />
      ),
    },
  ];

  return (
    <Table
      data={cheques}
      columns={columns}
      paginated={false}
      pageSize={7}
      showRegisterCount={false}
    />
  );
};

export const RecChequeModal = ({ idCliente, vlrCheque, isOpen, toggle }) => {
  const [nroCheque, setNroCheque] = useState("");
  const [idBanco, setIdBanco] = useState(null);
  const [nomeBanco, setNomeBanco] = useState("");
  const [agencia, setAgencia] = useState("");
  const [nroConta, setNroConta] = useState("");
  const [aVista, setAVista] = useState(false);
  const [cmc7, setCmc7] = useState("");
  const [observ, setObserv] = useState("");
  const [dataEmi, setDataEmi] = useState(null);
  const [dataVcto, setDataVcto] = useState(null);
  const [valor, setValor] = useState(0);
  const [cheques, setCheques] = useState([]);
  const bancoRef = useRef(null);
  const numeroRef = useRef(null);

  const vlrLancado = sumDataField(cheques, "valor");

  const dispatch = useDispatch();

  const limparDadosInclusao = () => {
    setNroCheque("");
    setIdBanco(null);
    setNomeBanco("");
    setAgencia("");
    setNroConta("");
    setAVista(false);
    setCmc7("");
    setObserv("");
    setDataEmi(null);
    setDataVcto(null);
    setValor(0);
    if (bancoRef.current) {
      bancoRef.current.clearValue();
    }
  };

  const limparDados = () => {
    limparDadosInclusao();
    setCheques([]);
  };

  const handleSetBanco = (s) => {
    setIdBanco(s?.value);
    setNomeBanco(s?.label);
  };

  const handleAdd = () => {
    if (["", null, undefined].includes(nroCheque)) {
      toastr.warning("Atenção", "Por favor, informe o Número do Cheque");
      return false;
    }
    if ([0, null, undefined].includes(idBanco)) {
      toastr.warning("Atenção", "Por favor, informe o Banco do Cheque");
      return false;
    }
    if (!(dataEmi instanceof Date)) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a Data de Emissão do Cheque"
      );
      return false;
    }
    if (!(dataVcto instanceof Date)) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a Data de Vencimento (Bom Para) do Cheque"
      );
      return false;
    }
    if ([0, null, undefined].includes(valor)) {
      toastr.warning("Atenção", "Por favor, informe o Valor do Cheque");
      return false;
    }

    const payload = {
      id_banco: idBanco,
      nome_banco: nomeBanco,
      id_cliente: idCliente,
      nro_cheque: nroCheque,
      agencia: agencia,
      nro_conta: nroConta,
      data_emi: formatDateISO(dataEmi),
      data_vcto: formatDateISO(dataVcto),
      observ: observ,
      cmc7: cmc7,
      a_vista: aVista,
      valor: valor,
    };
    setCheques([...cheques, payload]);
    limparDadosInclusao();
    numeroRef.current.focus();
    setTimeout(() => {
      setValor(0);
    }, 40);
  };

  const handleDelete = (nroCheque) => {
    setCheques(cheques.filter((e) => e.nro_cheque !== nroCheque));
  };

  const onConfirm = () => {
    if (sumDataField(cheques, "valor") !== parseFloat(vlrCheque)) {
      toastr.warning(
        "Atenção",
        "O Valor Lançado deve ser igual ao Valor a Cobrar"
      );
      return false;
    }

    dispatch(
      addRecebCheque({
        valor: vlrCheque,
        dados_rec: cheques.map((e) => {
          delete e.nome_banco;
          return e;
        }),
      })
    );
    toggle();
  };

  const onKeyDown = (e) => {
    if (e.key === "F8") {
      handleAdd();
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Recebimento em Cheque"
      number="0063_12"
      onConfirm={onConfirm}
      onClosed={limparDados}
      onKeyDown={onKeyDown}
    >
      <Row>
        <TextInput
          label="Nº Cheque"
          md={2}
          value={nroCheque}
          onChange={(e, v) => setNroCheque(v)}
          maxLength={8}
          ref={numeroRef}
        />
      </Row>
      <Row>
        <AsyncComboBox
          label="Banco"
          md={4}
          isConcatField
          concatModelName="banco"
          defaultOptions
          isSearchable
          defaultValue={idBanco}
          onChange={handleSetBanco}
          ref={bancoRef}
        />
        <TextInput
          label="Agência"
          md={2}
          value={agencia}
          onChange={(e, v) => setAgencia(v)}
          maxLength={5}
        />
        <TextInput
          label="Número da Conta"
          md={3}
          value={nroConta}
          onChange={(e, v) => setNroConta(v)}
          maxLength={20}
        />
      </Row>
      <Row>
        <FormCheckbox
          label="Cheque À Vista"
          checked={aVista}
          onChange={() => setAVista(!aVista)}
          name="a-vista"
        />
        <TextInput
          label="CMC7"
          md={6}
          value={cmc7}
          onChange={(e, v) => setCmc7(v)}
          maxLength={35}
        />
      </Row>
      <Row>
        <TextInput
          label="Observação"
          md={12}
          value={observ}
          onChange={(e, v) => setObserv(v)}
          maxLength={150}
          colClassName="pr-0"
        />
      </Row>
      <Row>
        <Label md={12} style={{ fontStyle: "italic" }}>
          Em caso de cheque de terceiro, utilize o campo Observação para
          identificar o nome do emissor original do cheque.
        </Label>
      </Row>
      <Row className="mb-3">
        <DatePicker
          label="Emissão"
          md={3}
          value={dataEmi}
          onChange={(v) => setDataEmi(moment.isMoment(v) ? v.toDate() : v)}
        />
        <DatePicker
          label="Bom Para"
          md={3}
          value={dataVcto}
          onChange={(v) => setDataVcto(moment.isMoment(v) ? v.toDate() : v)}
        />
        <NumberInput
          label="Valor do Cheque"
          md={3}
          value={valor}
          onChange={setValor}
        />
        <FormButton md="auto" color="info" onClick={handleAdd}>
          F8 - Incluir
        </FormButton>
      </Row>
      <ChequesGrid cheques={cheques} handleDelete={handleDelete} />
      <Row
        style={{
          justifyContent: "space-between",
          width: "60%",
          marginLeft: "auto",
        }}
      >
        <FixedField
          label="Valor a Cobrar"
          value={formatNumber(vlrCheque, true, 2)}
          horizontal
        />
        <FixedField
          label="Valor a Lançar"
          value={formatNumber(Math.max(vlrCheque - vlrLancado, 0), true, 2)}
          horizontal
        />
        <FixedField
          label="Valor Lançado"
          value={formatNumber(vlrLancado, true, 2)}
          horizontal
        />
      </Row>
    </ModalBase>
  );
};
