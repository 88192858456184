import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  PageContainer,
  CardTotais,
  CardTotaisItem,
  FormCheckbox,
} from "../../../../components";
import { ClientesInadRotaGrid } from "./components/ClientesInadRotaGrid";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";

export const ClientesInadimplentesRota = () => {
  const [idRota, setIdRota] = useState(null);
  const [idCidade, setIdCidade] = useState(null);
  const [considerarClientesSemRota, setConsiderarClientesSemRota] =
    useState(true);
  const [dados, setDados] = useState([]);
  const [totalGeralPendTitRec, setTotalGeralPendTitRec] = useState(0);
  const [totalGeralPendRotativo, setTotalGeralPendRotativo] = useState(0);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const limparDados = () => {
    setDados([]);
    setTotalGeralPendTitRec(0);
    setTotalGeralPendRotativo(0);
  };

  const buscarDados = async () => {
    const params = {
      id_rota: idRota,
      id_cidade: idCidade,
      considerar_clientes_sem_rota: considerarClientesSemRota,
    };
    return await apiGetV2(
      "/relatorios/venda_direta/inadimplencia_por_rota/",
      params
    );
  };

  const listarDados = async () => {
    setLoadingPesquisar(true);
    try {
      const [ok, ret] = await buscarDados();
      if (ok) {
        setDados(ret.rotas);
        setTotalGeralPendTitRec(ret.total_geral_pend_tit_rec);
        setTotalGeralPendRotativo(ret.total_geral_pend_rotativo);
      } else {
        setDados([]);
        setTotalGeralPendTitRec(0);
        setTotalGeralPendRotativo(0);
      }
    } finally {
      setLoadingPesquisar(false);
    }
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    try {
      const [ok, ret] = await buscarDados();
      if (ok) {
        await docPrintReport(
          "/pronta_entrega/demonstrativos/clientes_inad_por_rota/",
          { dados: ret },
          "5028_2",
          true
        );
      }
    } finally {
      setLoadingImprimir(false);
    }
  };

  useEffect(() => {
    limparDados();
  }, [idRota, idCidade]);

  return (
    <PageContainer
      title="Clientes Inadimplentes por Rota"
      number="5028"
      canGoBack
    >
      <Card body>
        <Row>
          <AsyncComboBox
            md={2}
            label="Rota"
            concatModelName="rota_mob"
            defaultOptions
            onChange={(s) => setIdRota(s?.value)}
          />
          <AsyncComboBox
            md={3}
            label="Cidade"
            concatModelName="cidade"
            defaultOptions
            onChange={(s) => setIdCidade(s?.value)}
            hideShortcut
          />
          <FormCheckbox
            hint="Clientes sem rota são clientes que não estão na roteirização de nenhuma rota"
            label="Considerar Clientes sem Rota"
            checked={considerarClientesSemRota}
            onChange={() =>
              setConsiderarClientesSemRota(!considerarClientesSemRota)
            }
          />
          <BotaoPesquisar onClick={listarDados} loading={loadingPesquisar} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
        </Row>
      </Card>
      <CardTotais md={12}>
        <CardTotaisItem
          label="Total a Receber de Contas a Receber"
          value={totalGeralPendTitRec}
        />
        <CardTotaisItem
          label="Total a Receber de Rotativos em Aberto"
          value={totalGeralPendRotativo}
        />
      </CardTotais>
      <ClientesInadRotaGrid dados={dados} />
    </PageContainer>
  );
};
