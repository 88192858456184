import React from "react";
import { Table, TableCheck } from "../../../../components";
import { formatNumber } from "../../../../coreUtils";
import { ProdServIcone } from "../../../../components/ProdServIcone";

export const ItensGerenciamentoOSGrid = ({ dados, mecanica }) => {
  const columns = [
    {
      dataField: "tipo",
      text: "Tipo",
      align: "center",
      formatter: (c) => <ProdServIcone value={c} />,
    },
    {
      dataField: "id_item",
      text: "#",
      align: "center",
    },
    { dataField: "descricao", text: "Descricao", align: "left" },
    {
      dataField: "referencia",
      text: "Ref",
      align: "center",
      hidden: !mecanica,
    },
    { dataField: "tecnico", text: "Tecnico", align: "left" },
    {
      dataField: "horas_servico",
      text: "Horas",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    {
      dataField: "retrabalho",
      text: "Retrab",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
    {
      dataField: "vlr_tot_bruto",
      text: "Total Orig",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    {
      dataField: "vlr_desc",
      text: "Tot Desc",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      pageSize={8}
      paginated={false}
      showRegisterCount={false}
    />
  );
};
