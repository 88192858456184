import React, { useState } from "react";
import { ModalBase, Table } from "../../../../../components";
import { naturalSortDate } from "../../../../../coreUtils";
import GerenciamentoVendasService from "../../../../../services/vendas/GerenciamentoVendasService";

const columns = [
  {
    dataField: "data_hora_log",
    text: "Data/Hora",
    align: "center",
    sortable: true,
    sortFunc: naturalSortDate,
  },
  { dataField: "log", text: "Descrição", align: "left", sortable: true },
  {
    dataField: "nome_colaborador",
    text: "Responsável",
    align: "left",
    sortable: true,
  },
];

export const LogsVendaModal = ({ isOpen, toggle, selected }) => {
  const [dados, setDados] = useState([]);

  const buscarDados = async () => {
    const [ok, ret] = await GerenciamentoVendasService.buscarLogs(selected);
    setDados(ok ? ret : []);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Logs da Venda"
      number="0120_3"
      onBeforeOpen={buscarDados}
    >
      <Table data={dados} columns={columns} paginated={false} pageSize={10} />
    </ModalBase>
  );
};
