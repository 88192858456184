import React, { useState } from "react";
import {
  AsyncComboBox,
  ComboBox,
  DatePicker,
  FormButton,
  IntegerFormInput,
  ModalBase,
  PesqPlanoCtaCentroCusto,
  TextInput,
} from "../../../../../components";
import moment from "moment";
import { Row } from "reactstrap";
import GerencBancService from "../../../../../services/financeiro/GerencBancService";
import { formatDateISO } from "../../../../../coreUtils";

const opcoes = [
  { label: "Entrada", value: "+" },
  { label: "Saída", value: "-" },
];

export const IncluirMovModal = (notifyEvent) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [idConta, setIdConta] = useState(null);
  const [planoConta, setPlanoConta] = useState(null);
  const [natureza, setNatureza] = useState("");
  const [dataMov, setDataMov] = useState(new Date());
  const [nroDoc, setNroDoc] = useState("");
  const [valor, setValor] = useState(null);
  const [historico, setHistorico] = useState("");

  const limparDados = () => {
    setIdConta(null);
    setPlanoConta(null);
    setNatureza("");
    setDataMov(new Date());
    setNroDoc("");
    setValor(null);
    setHistorico("");
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    const payload = {
      id_conta_banc: idConta,
      natureza: natureza,
      data_mov: formatDateISO(dataMov),
      nro_doc: nroDoc,
      id_plano_conta: planoConta,
      valor: valor,
      historico: historico,
    };
    setLoadingSubmit(true);
    const [ok] = await GerencBancService.incluir(payload);
    if (ok) {
      toggle();
    }
    setLoadingSubmit(false);
  };

  return (
    <>
      <FormButton md="auto" color="info" onClick={toggle} padded>
        Incluir
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Inclusão de Movimento Manual"
        number="0132_1"
        onConfirm={handleSubmit}
        loadingConfirm={loadingSubmit}
        onClosed={limparDados}
        notifyEvent={notifyEvent}
      >
        <Row>
          <AsyncComboBox
            md={6}
            label="Conta"
            isConcatField
            concatModelName="conta_banc"
            defaultValue={idConta}
            onChange={(s) => setIdConta(s?.value)}
          />
        </Row>
        <Row>
          <PesqPlanoCtaCentroCusto
            md={7}
            label="Plano de Contas"
            mode="plano_contas"
            onChange={(v) => setPlanoConta(v)}
            value={planoConta}
          />
        </Row>
        <Row>
          <TextInput
            md={3}
            label="Número do Documento"
            value={nroDoc}
            onChange={(e, v) => setNroDoc(v)}
          />
          <IntegerFormInput
            md={3}
            label="Valor"
            value={valor}
            onChange={setValor}
          />
          <DatePicker
            md={2}
            label={"Data"}
            value={dataMov}
            onChange={(v) => setDataMov(moment.isMoment(v) ? v.toDate() : v)}
            tabIndex={100}
          />
          <ComboBox
            md={3}
            label="Natureza"
            value={natureza}
            onChange={(s) => setNatureza(s?.value)}
            options={opcoes}
          />
        </Row>
        <Row>
          <TextInput
            md={12}
            label="Histórico"
            value={historico}
            onChange={(e, v) => setHistorico(v)}
          />
        </Row>
      </ModalBase>
    </>
  );
};
