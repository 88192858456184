import { Grid } from "react-feather";
import {
  BsBank,
  BsBox,
  BsGrid3X3Gap,
  BsPerson,
  BsPiggyBank,
} from "react-icons/bs";
import { FaBoxesPacking } from "react-icons/fa6";
import { FaDolly, FaToolbox } from "react-icons/fa";
import { AiOutlineCar } from "react-icons/ai";
import { MdOutlinePayments, MdOutlineLocationOn } from "react-icons/md";
import { CamposPersonalizados } from "../../pages/cadastro/campos_person/CamposPersonalizados";
import { Cliente } from "../../pages/cadastro/clientes/cliente/Cliente";
import { ExtratoCliente } from "../../pages/cadastro/clientes/cliente/extrato_cliente/ExtratoCliente";
import { CondPag } from "../../pages/cadastro/financeiro/cond_pag/CondPag";
import { ContaBanc } from "../../pages/cadastro/financeiro/conta_banc/ContaBanc";
import { TabFatNfDescVenda } from "../../pages/cadastro/outros/tabela_fat_nf_desc_venda/TabFatNfDescVenda";
import { Fornecedor } from "../../pages/cadastro/produtos/fornecedor/Fornecedor";
import CadastroUsoCashbackPage from "../../pages/cadastro/outros/cadastro_de_uso_cashback";
import { VendedorTecnicoServicos } from "../../pages/cadastro/outros/vendedor_tecnico_servico/VendedorTecnicoServicos";
import { DescPorQuantidade } from "../../pages/cadastro/vendas/desconto_por_quantidade/DescPorQuantidade";
import { ListaPrecos } from "../../pages/cadastro/vendas/lista_precos/ListaPrecos";
import { Produto } from "../../pages/cadastro/produtos/produto/Produto";
import { CadastroGrupoRegraTrib } from "../../pages/cadastro/tributacao/grupo_regra/cadastro/CadastroGrupoRegraTrib";
import { GrupoRegraTributacao } from "../../pages/cadastro/tributacao/grupo_regra/GrupoRegraTributacao";
import { ProdutosRegraTributacao } from "../../pages/cadastro/tributacao/regra_tributacao/produtos/ProdutosRegraTributacao";
import { RegraTributacao } from "../../pages/cadastro/tributacao/regra_tributacao/RegraTributacao";
import { Veiculo } from "../../pages/cadastro/outros/veiculo/Veiculo";
import { ClientesCamposPerson } from "../../pages/cadastro/clientes/campos_person/ClientesCamposPerson";
import { MetaVenda } from "../../pages/cadastro/vendas/meta_venda/MetaVenda";
import { Financeiro } from "../../pages/cadastro/financeiro/Financeiro";
import { Outros } from "../../pages/cadastro/outros/Outros";
import { Produtos } from "../../pages/cadastro/produtos/Produtos";
import { Clientes } from "../../pages/cadastro/clientes/Clientes";
import { Tributacao } from "../../pages/cadastro/tributacao/Tributacao";
import { Cidade } from "../../pages/cadastro/clientes/cidade/Cidade";
import { LigacaoVeicCli } from "../../pages/cadastro/clientes/cliente/ligacao_veic_cli/LigacaoVeicCli";
import { Grupo } from "../../pages/cadastro/produtos/grupo/Grupo";
import { HiOutlineSquares2X2 } from "react-icons/hi2";
import { Fabricante } from "../../pages/cadastro/produtos/fabricante/Fabricante";
import { TbBuildingFactory2 } from "react-icons/tb";
import { VeicCliente } from "../../pages/cadastro/servicos/veic_cliente/VeicCliente";
import { BiCar } from "react-icons/bi";
import { Servico } from "../../pages/cadastro/servicos/servico/Servico";
import { BsGear } from "react-icons/bs";
import { SiVolkswagen } from "react-icons/si";
import { ServicosCadastro } from "../../pages/cadastro/servicos/ServicosCadastro";
import { IncluirAlterarCliente } from "../../pages/cadastro/clientes/cliente/incluir_alterar/IncluirAlterarCliente";
import { Montadora } from "../../pages/cadastro/servicos/montadora/Montadora";
import { IncluirAlterarProduto } from "../../pages/cadastro/produtos/produto/incluir_alterar/IncluirAlterarProduto";
import { RegrasComissao } from "../../pages/cadastro/vendas/regras_comissao/RegrasComissao";
import { GrupoNaoVerifEstoque } from "../../pages/cadastro/outros/grupo_nao_verif_estoque/GrupoNaoVerifEstoque";
import { Terminal } from "../../pages/cadastro/vendas/terminal/Terminal";
import { Unidade } from "../../pages/cadastro/produtos/unidade/Unidade";
import { TfiRulerAlt } from "react-icons/tfi";
import { RegraPromocao } from "../../pages/cadastro/vendas/regra_promocao/RegraPromocao";
import { ItensRegraPromocao } from "../../pages/cadastro/vendas/regra_promocao/itens/ItensRegraPromocao";
import { CadVendas } from "../../pages/cadastro/vendas/CadVendas";
import { RamoAtividade } from "../../pages/cadastro/clientes/ramo_atividade/RamoAtividade";
import {
  LiaStoreAltSolid,
  LiaTruckMovingSolid,
  LiaUsersSolid,
} from "react-icons/lia";
import { MeioPagto } from "../../pages/cadastro/financeiro/meio_pagto/MeioPagto";
import { HiOutlineCreditCard } from "react-icons/hi2";
import { AcessosSistema } from "../../pages/cadastro/acessos_sistema/AcessosSistema";
import { Perfil } from "../../pages/cadastro/acessos_sistema/perfil/Perfil";
import { TipoDocumento } from "../../pages/cadastro/tributacao/tipo_documento/TipoDocumento";
import { IoDocumentTextOutline, IoWalletOutline } from "react-icons/io5";
import { NumeracaoNfe } from "../../pages/cadastro/tributacao/numeracao_nfe/NumeracaoNfe";
import { AvisoVincColaborador } from "../../pages/cadastro/acessos_sistema/aviso_vinc_colaborador/AvisoVincColaborador";
import { ControleAcesso } from "../../pages/cadastro/acessos_sistema/controle_acesso/ControleAcesso";
import { IncluirMetaBaseadoVendas } from "../../pages/cadastro/vendas/meta_venda/incluir_baseado_vendas/IncluirMetaBaseadoVendas";
import { Ambiente } from "../../pages/cadastro/vendas/ambiente/Ambiente";
import { Premiado } from "../../pages/cadastro/vendas/premiado/Premiado";
import { SubGrupo } from "../../pages/cadastro/produtos/sub_grupo/SubGrupo";
import { Terceirizacao } from "../../pages/cadastro/servicos/terceirizacao/Terceirizacao";
import { ClienteExcecaoInadBoleto } from "../../pages/cadastro/vendas/cliente_excecao_inad_boleto/ClienteExcecaoInadBoleto";
import { ClasseProduto } from "../../pages/cadastro/produtos/classe/Classe";
import { SiAwsorganizations } from "react-icons/si";
import { FlexRegraColaborador } from "../../pages/cadastro/vendas/flex_regra_colaborador/FlexRegraColaborador";
import { Transportadora } from "../../pages/cadastro/clientes/transportadora/Transportadora";
import { ProdutoInfoCliNf } from "../../pages/cadastro/produtos/produto_info_cli_nf/ProdutoInfoCliNf";
import { LuFileBox } from "react-icons/lu";
import { Cfop } from "../../pages/cadastro/tributacao/cfop/Cfop";
import { IncluirAlterarCfop } from "../../pages/cadastro/tributacao/cfop/incluir_alterar/IncluirAlterarCfop";
import { CadBanco } from "../../pages/cadastro/financeiro/banco/CadBanco";
import { FormaRecCliente } from "../../pages/cadastro/vendas/formas_rec_cliente/FormaRecCliente";
import { Supervisor } from "../../pages/cadastro/vendas/gerenc_supervisor_cab/Supervisor";
import { PlanoContas } from "../../pages/cadastro/financeiro/plano_contas/PlanoContas";
import { Motivo } from "../../pages/cadastro/financeiro/motivo/Motivo";
import { RiFileUserLine, RiMegaphoneLine } from "react-icons/ri";
import { Colaborador } from "../../pages/cadastro/acessos_sistema/colaborador/Colaborador";
import { NaturezaOperNfse } from "../../pages/cadastro/tributacao/natureza_oper_nfse/NaturezaOperNfse";
import { CentroCustos } from "../../pages/cadastro/financeiro/centro_custo/CentroCusto";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { TipoCliente } from "../../pages/cadastro/clientes/tipo_cliente/TipoCliente";
import { RedeClientes } from "../../pages/cadastro/clientes/rede_cliente/RedeCliente";
import { RegraComissaoOs } from "../../pages/cadastro/vendas/regras_comissao_os/RegraComissaoOs";
import { LigacaoProdFornec } from "../../pages/cadastro/produtos/ligacao_prod_fornec/LigacaoProdFornec";
import { CiBoxes } from "react-icons/ci";
import { Equipamento } from "../../pages/cadastro/servicos/equipamento/Equipamento";
import { Kits } from "../../pages/cadastro/produtos/kit/Kit";
import { RepresentacaoComercial } from "../../pages/cadastro/vendas/representante_comercial/RepresentacaoComerical";

export const camposPersonalizadosRoute = {
  path: "/cadastro/campos_person",
  name: "Campos Personalizados - Cliente",
  id: "AcSkWeb0040_4",
  nroTela: "0040",
  component: CamposPersonalizados,
};

export const extratoClienteRoute = {
  path: "/cadastro/clientes/cliente/extrato",
  name: "",
  id: "AcSkWeb0040_5",
  nroTela: "0040",
  component: ExtratoCliente,
};

export const produtosRegraTribRoute = {
  path: "/cadastro/tributacao/regra_tributacao/produtos",
  name: "",
  id: "AcSkWeb0031",
  nroTela: "0031",
  component: ProdutosRegraTributacao,
};

export const cadastroGrupoRegraTribRoute = {
  path: "/cadastro/tributacao/grupo_regra/cadastro",
  name: "",
  id: "AcSkWeb0047",
  nroTela: "0047",
  component: CadastroGrupoRegraTrib,
};

export const ligacaoVeicCliRoute = {
  path: "/cadastro/clientes/cliente/ligacao_veic_cli",
  name: "",
  id: "AcSkWeb0040",
  nroTela: "0040",
  component: LigacaoVeicCli,
};

export const cadastroCondPagRoute = {
  path: "/cadastro/financeiro/cond_pag/",
  name: "Condição de Pagamento",
  id: "AcSkWeb0051",
  nroTela: "0051",
  component: CondPag,
  concatModelName: "cond_pag",
  shortcutIcon: MdOutlinePayments,
};

export const cadastroContaBancRoute = {
  path: "/cadastro/financeiro/conta_banc/",
  name: "Conta Bancária",
  id: "AcSkWeb0045",
  nroTela: "0045",
  component: ContaBanc,
  concatModelName: "conta_banc",
  shortcutIcon: BsPiggyBank,
};

export const cadastroMeioPagtoRoute = {
  path: "/cadastro/financeiro/meio_pagto/",
  name: "Meio de Pagamento",
  id: "AcSkWeb0081",
  nroTela: "0081",
  component: MeioPagto,
  concatModelName: "meio_pagamento",
  shortcutIcon: HiOutlineCreditCard,
};

export const cadastroBancoRoute = {
  path: "/cadastro/financeiro/cad_banco/",
  name: "Cadastro de Bancos",
  id: "AcSkWeb0121",
  nroTela: "0121",
  component: CadBanco,
  concatModelName: "banco",
  shortcutIcon: BsBank,
};

export const cadastroPlanoContasRoute = {
  path: "/cadastro/financeiro/plano_contas/",
  name: "Plano de Contas",
  nroTela: "0125",
  component: PlanoContas,
  concatModelName: "plano_conta",
  shortcutIcon: IoWalletOutline,
};

export const cadastroMotivoRoute = {
  path: "/cadastro/financeiro/motivo/",
  name: "Motivo",
  nroTela: "0126",
  component: Motivo,
  concatModelName: "motivo",
  shortcutIcon: RiMegaphoneLine,
};

export const cadastroCentroCustoRoute = {
  path: "/cadastro/financeiro/centro_custo/",
  name: "Centro de Custos",
  nroTela: "0128",
  id: "AcSkWeb0128",
  component: CentroCustos,
  concatModelName: "centro_custo",
  shortcutIcon: FaMoneyBillTransfer,
};

export const cadastroFinanceiroRoutes = [
  cadastroCondPagRoute,
  cadastroContaBancRoute,
  cadastroMeioPagtoRoute,
  cadastroBancoRoute,
  cadastroPlanoContasRoute,
  cadastroMotivoRoute,
  cadastroCentroCustoRoute,
];

export const cadastroPermissoesCashbackRoute = {
  path: "/cadastro/permissoes",
  name: "Uso de Cashback",
  id: "AcSkWeb0008",
  nroTela: "0008",
  component: CadastroUsoCashbackPage,
};

export const cadastroVendedorTecnicoServicoRoute = {
  path: "/cadastro/vendedor_tecnico_servico",
  name: "Vendedor Técnico de Serviços",
  id: "AcSkWeb0020",
  nroTela: "0020",
  component: VendedorTecnicoServicos,
};

export const cadastroTabFatNfDescVendaRoute = {
  path: "/cadastro/outros/tab_fat_nf_desc_venda/",
  name: "Tabela Negociação",
  id: "AcSkWeb0050",
  nroTela: "0050",
  component: TabFatNfDescVenda,
};

export const cadastroVeiculoEmpresaRoute = {
  path: "/cadastro/outros/veiculo_empresa",
  name: "Veículo da Empresa",
  id: "AcSkWeb0012",
  nroTela: "0012",
  component: Veiculo,
  concatModelName: "veiculo_empresa",
  shortcutIcon: AiOutlineCar,
};

export const cadastroGrupoNaoVerifEstoqueRoute = {
  path: "/cadastro/outros/grupo_nao_verif_estoque",
  name: "Grupo Não Verif. Estoque",
  id: "AcSkWeb0074",
  nroTela: "0074",
  component: GrupoNaoVerifEstoque,
};

export const cadastroOutrosRoutes = [
  cadastroPermissoesCashbackRoute,
  cadastroVendedorTecnicoServicoRoute,
  cadastroTabFatNfDescVendaRoute,
  cadastroVeiculoEmpresaRoute,
  cadastroGrupoNaoVerifEstoqueRoute,
];

export const cadastroFabricanteRoute = {
  path: "/cadastro/produtos/fabricante",
  name: "Fabricante",
  id: "AcSkWeb0065",
  nroTela: "0065",
  component: Fabricante,
  concatModelName: "fabricante",
  shortcutIcon: TbBuildingFactory2,
};

export const cadastroKitProdutoRoute = {
  path: "/cadastro/produtos/kit/",
  name: "Kit de Produtos",
  id: "AcSkWeb0139",
  nroTela: "0139",
  component: Kits,
  concatModelName: "kit",
  shortcutIcon: FaBoxesPacking,
};

export const cadastroGrupoRoute = {
  path: "/cadastro/produtos/grupo",
  name: "Grupo",
  id: "AcSkWeb0064",
  nroTela: "0064",
  component: Grupo,
  concatModelName: "grupo",
  shortcutIcon: HiOutlineSquares2X2,
};

export const cadastroSubGrupoRoute = {
  path: "/cadastro/produtos/sub_grupo",
  name: "Subgrupo",
  id: "AcSkWeb0108",
  nroTela: "0108",
  component: SubGrupo,
  concatModelName: "sub_grupo",
  shortcutIcon: BsGrid3X3Gap,
};

export const cadastroProdutoRoute = {
  path: "/cadastro/produtos/produto",
  name: "Produto",
  id: "AcSkWeb0029",
  nroTela: "0029",
  component: Produto,
};

export const cadastroClasseProdutoRoute = {
  path: "/cadastro/produtos/classe_produto",
  name: "Classe de Produtos",
  id: "AcSkWeb0114",
  nroTela: "0114",
  component: ClasseProduto,
  shortcutIcon: SiAwsorganizations,
};

export const cadastroUnidadeRoute = {
  path: "/cadastro/produtos/unidade",
  name: "Unidade",
  id: "AcSkWeb0076",
  nroTela: "0076",
  component: Unidade,
  concatModelName: "unidade",
  shortcutIcon: TfiRulerAlt,
};

export const itensRegraPromocaoRoute = {
  path: "/cadastro/produtos/regra_promocao/itens",
  name: "Itens da Regra de Promoção",
  id: "AcSkWeb0078",
  nroTela: "0078",
  component: ItensRegraPromocao,
};

export const cadastroFornecedoresRoute = {
  path: "/cadastro/compras/fornecedores",
  name: "Fornecedor",
  id: "AcSkWeb0033",
  nroTela: "0033",
  component: Fornecedor,
  concatModelName: "fornecedor",
  shortcutIcon: FaDolly,
};

export const cadastroProdutoFaturamentoClienteRoute = {
  path: "/cadastro/produtos/produto_info_cli_nf",
  name: "Produto de Faturamento por Cliente",
  id: "AcSkWeb0118",
  nroTela: "0118",
  component: ProdutoInfoCliNf,
  shortcutIcon: LuFileBox,
};

export const cadastroLigacaoProdFornecRoute = {
  path: "/cadastro/produtos/ligacao_prod_fornec",
  name: "Ligação Produto-Fornecedor",
  nroTela: "0137",
  component: LigacaoProdFornec,
  shortcutIcon: CiBoxes,
};

export const cadastroProdutosRoutes = [
  cadastroFabricanteRoute,
  cadastroKitProdutoRoute,
  cadastroGrupoRoute,
  cadastroSubGrupoRoute,
  cadastroProdutoRoute,
  cadastroUnidadeRoute,
  cadastroFornecedoresRoute,
  cadastroClasseProdutoRoute,
  cadastroProdutoFaturamentoClienteRoute,
  cadastroLigacaoProdFornecRoute,
];

export const cadastroRelatorioCamposPersonRoute = {
  path: "/cadastro/clientes/relatorio_campos_person",
  name: "Campos Personalizados",
  id: "AcSkWeb5025",
  component: ClientesCamposPerson,
  nroTela: "5025",
};

export const cadastroClienteRoute = {
  path: "/cadastro/clientes/cliente",
  name: "Cliente",
  id: "AcSkWeb0040",
  nroTela: "0040",
  component: Cliente,
};

export const cadastroCidadeRoute = {
  path: "/cadastro/clientes/cidade",
  name: "Cidade",
  id: "AcSkWeb0060",
  nroTela: "0060",
  concatModelName: "cidade",
  shortcutIcon: MdOutlineLocationOn,
  component: Cidade,
};

export const cadastroRamoAtividadeRoute = {
  path: "/cadastro/clientes/ramo_atividade",
  name: "Ramo de Atividade",
  id: "AcSkWeb0080",
  nroTela: "0080",
  component: RamoAtividade,
  concatModelName: "ramo_atividade",
  shortcutIcon: LiaStoreAltSolid,
};

export const cadastroTransportadoraRoute = {
  path: "/cadastro/clientes/transportadora",
  name: "Transportadora",
  id: "AcSkWeb0117",
  nroTela: "0117",
  component: Transportadora,
  concatModelName: "transportadora",
  shortcutIcon: LiaTruckMovingSolid,
};

export const cadastroTipoClienteRoute = {
  path: "/cadastro/clientes/tipo_cliente",
  name: "Tipo de Cliente",
  id: "AcSkWeb0129",
  nroTela: "0129",
  component: TipoCliente,
  concatModelName: "tipo_cliente",
  shortcutIcon: RiFileUserLine,
};

export const cadastroRedeClienteRoute = {
  path: "/cadastro/clientes/rede_cliente/",
  name: "Tipo de Cliente",
  id: "AcSkWeb0133",
  nroTela: "0133",
  component: RedeClientes,
  concatModelName: "rede_cliente",
  shortcutIcon: LiaUsersSolid,
};

export const cadastroClientesRoutes = [
  cadastroRelatorioCamposPersonRoute,
  cadastroClienteRoute,
  cadastroCidadeRoute,
  cadastroRamoAtividadeRoute,
  cadastroTransportadoraRoute,
  cadastroTipoClienteRoute,
  cadastroRedeClienteRoute,
];

export const cadastroCartoesRoutes = [];

export const cadastroGrupoRegraTributacaoRoute = {
  path: "/cadastro/tributacao/grupo_regra",
  name: "Grupos de Regras de Tributação",
  id: "AcSkWeb0047",
  nroTela: "0047",
  component: GrupoRegraTributacao,
};

export const cadastroRegraTributacaoRoute = {
  path: "/cadastro/tributacao/regra_tributacao",
  name: "Regra de Tributação",
  id: "AcSkWeb0031",
  nroTela: "0031",
  component: RegraTributacao,
};

export const cadastroTipoDocumentoRoute = {
  path: "/cadastro/tributacao/tipo_documento",
  name: "Tipo de Documento",
  id: "AcSkWeb0086",
  nroTela: "0086",
  component: TipoDocumento,
  concatModelName: "tipo_documento",
  shortcutIcon: IoDocumentTextOutline,
};

export const cadastroNumeracaoNfeRoute = {
  path: "/cadastro/tributacao/numeracao_nfe",
  name: "Numeração de NFe",
  id: "AcSkWeb0089",
  nroTela: "0089",
  component: NumeracaoNfe,
};

export const cadastroCfopRoute = {
  path: "/cadastro/tributacao/cfop",
  name: "CFOP",
  nroTela: "0119",
  component: Cfop,
};

export const cadastroNaturezaOperNfseRoute = {
  path: "/cadastro/tributacao/natureza_oper_nfse",
  name: "Natureza de Operação de NFS-e",
  nroTela: "0127",
  component: NaturezaOperNfse,
};

export const cadastroTributacaoRoutes = [
  cadastroGrupoRegraTributacaoRoute,
  cadastroRegraTributacaoRoute,
  cadastroTipoDocumentoRoute,
  cadastroNumeracaoNfeRoute,
  cadastroCfopRoute,
  cadastroNaturezaOperNfseRoute,
];

export const cadastroVeicClienteRoute = {
  path: "/cadastro/servicos/veic_cliente",
  name: "Veículos de Clientes",
  id: "AcSkWeb0061",
  nroTela: "0061",
  component: VeicCliente,
  concatModelName: "veiculo",
  shortcutIcon: BiCar,
};

export const cadastroEquipamentoRoute = {
  path: "/cadastro/servicos/equipamento",
  name: "Equipamento",
  id: "AcSkWeb0138",
  nroTela: "0138",
  component: Equipamento,
  concatModelName: "equipamento",
  shortcutIcon: FaToolbox,
};

export const cadastroServicoRoute = {
  path: "/cadastro/servicos/servico",
  name: "Serviço",
  id: "AcSkWeb0062",
  nroTela: "0062",
  component: Servico,
  concatModelName: "servico",
  shortcutIcon: BsGear,
};

export const cadastroMontadoraRoute = {
  path: "/cadastro/servicos/montadora",
  name: "Montadora",
  id: "AcSkWeb0069",
  nroTela: "0069",
  component: Montadora,
  concatModelName: "montadora",
  shortcutIcon: SiVolkswagen,
};

export const cadastroTerceirizacaoRoute = {
  path: "/cadastro/servicos/terceirizacao",
  name: "Terceirização",
  id: "AcSkWeb0109",
  nroTela: "0109",
  component: Terceirizacao,
};

export const cadastroServicosRoutes = [
  cadastroVeicClienteRoute,
  cadastroServicoRoute,
  cadastroMontadoraRoute,
  cadastroTerceirizacaoRoute,
  cadastroEquipamentoRoute,
];

export const cadastroMetaVendaRoute = {
  id: "AcSkWeb0056",
  nroTela: "0056",
  path: "/cadastro/vendas/meta_venda",
  name: "Meta de Venda",
  component: MetaVenda,
};

export const incluirMetaBaseadoVendasRoute = {
  path: "/cadastro/vendas/meta_venda/incluir_baseado_vendas",
  name: "Incluir Meta Baseado em Vendas",
  nroTela: "0056",
  component: IncluirMetaBaseadoVendas,
};

export const cadastroListaPrecosRoute = {
  path: "/cadastro/vendas/lista_precos",
  name: "Lista de Preços",
  id: "AcSkWeb0013",
  nroTela: "0013",
  component: ListaPrecos,
};

export const cadastroRegrasComissaoRoute = {
  path: "/cadastro/vendas/regras_comissao",
  name: "Regras de Comissão",
  id: "AcSkWeb0038",
  nroTela: "0038",
  component: RegrasComissao,
};

export const cadastroRegraPromocaoRoute = {
  path: "/cadastro/vendas/regra_promocao",
  name: "Regra de Promoção",
  id: "AcSkWeb0078",
  nroTela: "0078",
  component: RegraPromocao,
};

export const cadastroAmbienteRoute = {
  id: "AcSkWeb0103",
  nroTela: "0103",
  path: "/cadastro/vendas/ambiente",
  name: "Ambiente",
  component: Ambiente,
};

export const cadastroPremiadoRoute = {
  id: "AcSkWeb0105",
  nroTela: "0105",
  path: "/cadastro/vendas/premiado",
  name: "Premiado",
  component: Premiado,
};

export const cadastroTerminalRoute = {
  path: "/cadastro/vendas/terminal",
  name: "Terminal",
  id: "AcSkWeb0075",
  nroTela: "0075",
  component: Terminal,
};

export const cadastroDescontoPorQuantidadeRoute = {
  path: "/cadastro/vendas/desconto_por_quantidade",
  name: "Desconto por Quantidade",
  id: "AcSkWeb0017",
  nroTela: "0017",
  component: DescPorQuantidade,
};

export const cadastroClienteExcecaoInadBoletoRoute = {
  path: "/cadastro/vendas/cliente_excecao_inad_boleto",
  name: "Dias de Exceção de Inadimplência",
  nroTela: "0113",
  component: ClienteExcecaoInadBoleto,
};

export const cadastroRegrasFlexColaboradorRoute = {
  path: "/cadastro/vendas/flex_regra_colab",
  name: "Regras de Flex por Colaborador",
  nroTela: "0116",
  component: FlexRegraColaborador,
};

export const cadastroGerencSupervisorCabRoute = {
  path: "/cadastro/vendas/gerenc_supervisor_cab",
  name: "Cadastro de Supervisor",
  nroTela: "0122",
  id: "AcSkWeb0122",
  component: Supervisor,
};

export const cadastroFormasRecClienteRoute = {
  path: "/cadastro/vendas/formas_rec_cliente",
  name: "Formas de Recebimentos de Clientes",
  id: "AcSkWeb0022",
  nroTela: "0022",
  component: FormaRecCliente,
};

export const cadastroRegrasComissaoOsRoute = {
  path: "/cadastro/vendas/regras_coomissao_os",
  name: "Formas de Recebimentos de Clientes",
  nroTela: "0135",
  component: RegraComissaoOs,
};

export const cadastroRepresentacaoComercialRoute = {
  path: "/cadastro/vendas/representante_comercial",
  name: "Cadastro de Representacao Comercial",
  id: "AcSkWeb0140",
  nroTela: "0140",
  component: RepresentacaoComercial,
  concatModelName: 'representacao'
};

export const cadastroVendasRoutes = [
  cadastroMetaVendaRoute,
  cadastroListaPrecosRoute,
  cadastroRegrasComissaoRoute,
  cadastroRegraPromocaoRoute,
  cadastroAmbienteRoute,
  cadastroPremiadoRoute,
  cadastroTerminalRoute,
  cadastroDescontoPorQuantidadeRoute,
  cadastroClienteExcecaoInadBoletoRoute,
  cadastroRegrasFlexColaboradorRoute,
  cadastroFormasRecClienteRoute,
  cadastroGerencSupervisorCabRoute,
  cadastroRegrasComissaoOsRoute,
  cadastroRepresentacaoComercialRoute,
];

export const cadastroPerfilRoute = {
  path: "/cadastro/acessos_sistema/perfil",
  name: "Perfil",
  id: "AcSkWeb0084",
  nroTela: "0084",
  component: Perfil,
};

export const avisoVincColaboradorRoute = {
  path: "/cadastro/acessos_sistema/aviso_vinc_colaborador",
  name: "Vinculação de Avisos e Colaboradores",
  id: "AcSkWeb0084",
  nroTela: "0090",
  component: AvisoVincColaborador,
};

export const controleAcessoRoute = {
  path: "/cadastro/acessos_sistema/controle_acesso",
  name: "Controle de Acesso",
  nroTela: "0088",
  component: ControleAcesso,
};

export const cadastroColaboradorRoute = {
  path: "/cadastro/acessos_sistema/colaborador",
  name: "Colaborador",
  nroTela: "0058",
  component: Colaborador,
};

export const cadastroAcessosSistemaRoutes = [
  cadastroPerfilRoute,
  avisoVincColaboradorRoute,
  controleAcessoRoute,
  cadastroColaboradorRoute,
];

export const incluirAlterarClienteRoute = {
  path: "/cadastro/clientes/cliente/cadastro",
  name: "Cliente",
  id: "AcSkWeb0040",
  nroTela: "0040",
  component: IncluirAlterarCliente,
  concatModelName: "cliente",
  shortcutIcon: BsPerson,
};

export const incluirAlterarProdutoRoute = {
  path: "/cadastro/produtos/produto/cadastro",
  name: "",
  id: "AcSkWeb0029",
  nroTela: "0029",
  component: IncluirAlterarProduto,
  concatModelName: "produto",
  shortcutIcon: BsBox,
};
export const incluirAlterarCfopRoute = {
  path: "/cadastro/tributacao/cfop/cadastro",
  name: "Inclusão/Alteração de Cadastro de CFOP",
  id: "AcSkWeb0119",
  nroTela: "0119",
  component: IncluirAlterarCfop,
};

const cadastroRoutes = {
  path: "/cadastro",
  name: "Cadastro",
  icon: Grid,
  children: [
    // {
    //   path: "/cadastro/cartoes",
    //   name: "Cartões",
    //   component: Cartoes,
    // },
    {
      name: "Acessos do Sistema",
      path: "/cadastro/acessos_sistema",
      component: AcessosSistema,
      canGoBack: false,
    },
    {
      name: "Clientes",
      path: "/cadastro/clientes",
      component: Clientes,
      canGoBack: false,
    },
    {
      path: "/cadastro/financeiro",
      name: "Financeiro",
      component: Financeiro,
      canGoBack: false,
    },
    {
      name: "Outros",
      path: "/cadastro/outros",
      component: Outros,
      canGoBack: false,
    },
    {
      name: "Produtos",
      path: "/cadastro/produtos",
      component: Produtos,
      canGoBack: false,
    },
    {
      name: "Serviços",
      path: "/cadastro/servicos",
      component: ServicosCadastro,
      canGoBack: false,
    },
    {
      path: "/cadastro/tributacao",
      name: "Tributação",
      component: Tributacao,
      canGoBack: false,
    },
    {
      path: "/cadastro/vendas",
      name: "Vendas",
      component: CadVendas,
      canGoBack: false,
    },
  ],
};

export default cadastroRoutes;
