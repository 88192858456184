import React from "react";
import { useState } from "react";
import { modalTitlePremiado, routesBasePremiado } from "../Premiado";
import {
  MODAL_ACTIONS,
  dateFromLocaleString,
  formatDateISO,
} from "../../../../../coreUtils";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { Row } from "reactstrap";
import { DatePicker, TextInput } from "../../../../../components";
import moment from "moment";
import { showWarning } from "../../../../../components/AlertaModal";

export const CadastroPremiadoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [nome, setNome] = useState("");
  const [fone1, setFone1] = useState("");
  const [fone2, setFone2] = useState("");
  const [dataNasc, setDataNasc] = useState("");
  const [email, setEmail] = useState("");
  const [observ, setObserv] = useState("");
  const [status, setStatus] = useState(true);

  const limparDados = () => {
    setNome("");
    setFone1("");
    setFone2("");
    setDataNasc("");
    setEmail("");
    setObserv("");
    setStatus(true);
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setFone1(data.fone1);
    setFone2(data.fone2);
    setDataNasc(dateFromLocaleString(data.data_nascimento));
    setEmail(data.email);
    setObserv(data.observ);
    setStatus(data.status);
  };

  const submitPayload = (action) => {
    if (["", null, undefined].includes(nome)) {
      showWarning(
        "Por favor, informe o Nome do Premiado",
        null,
        null,
        "input-nome-premiado"
      );
      return;
    }

    if (!(dataNasc instanceof Date)) {
      showWarning(
        "Por favor, informe uma Data de Nascimento válida para o Premiado",
        null,
        null,
        "input-data-aniver-premiado"
      );
      return;
    }

    const payload = {
      nome: nome,
      fone1: fone1,
      fone2: fone2,
      data_nasc: formatDateISO(dataNasc),
      email: email,
      observ: observ,
      status: status,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitlePremiado}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: status,
        toggle: () => setStatus(!status),
      }}
      routesBase={routesBasePremiado}
      number="0105_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          md={12}
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
          maxLength={60}
          name={"input-nome-premiado"}
        />
      </Row>
      <Row>
        <TextInput
          md={4}
          label="Fone 1"
          value={fone1}
          onChange={(e, v) => setFone1(v)}
          maxLength={15}
        />
        <TextInput
          md={4}
          label="Fone 2"
          value={fone2}
          onChange={(e, v) => setFone2(v)}
          maxLength={15}
        />
        <DatePicker
          md={4}
          label="Data de Nascimento"
          value={dataNasc}
          onChange={(v) => setDataNasc(moment.isMoment(v) ? v.toDate() : v)}
          name={"input-data-aniver-premiado"}
        />
      </Row>
      <Row>
        <TextInput
          md={12}
          label="E-Mail"
          value={email}
          onChange={(e, v) => setEmail(v)}
          maxLength={100}
          forceUppercase={false}
        />
      </Row>
      <Row>
        <TextInput
          md={12}
          label="Observação"
          value={observ}
          onChange={(e, v) => setObserv(v)}
          type="textarea"
          rows={2}
        />
      </Row>
    </ModalCadastroV2>
  );
};
