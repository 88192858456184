import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Row } from "reactstrap";
import { ComboBox, FormCheckbox, NumberInput } from "../../../../components";
import { HintLabel } from "../../../../components/HintLabel";
import { RadioGroup, RadioItem } from "../../../../components/RadioGroup";
import {
  setAdicionaItemGrid,
  setOperacaoPadrao,
  setFormaPagPadrao,
  setUtilizaAmbiente,
  setUtilizaVeiculo,
  setOperPadraoEntrega,
  setIdentifTipoImprFinalProc,
  setOperVisivelVenda,
  setOperVisivelPedido,
  setOperVisivelOrcamento,
  setOperVisivelOrdemServ,
  setGerarPontosPor,
  setUtilizaRegraPremiacao,
  setAvisaIdentifPremiado,
  setBloqIncProdQtdEstNegativo,
  setIdentificaFormaRec,
  setOperVisivelCondicional,
  setMostrarColVlrLiqGridFrenteVenda,
  setChequeClienteDefineLimitePor,
  setObrigaAutorizUsoProdCuringa,
  setBloqIncProdPor,
  setIdentificaVendedorFinal,
  setUtilizaNegociacaoFinanc,
  setFormaSistemaControlDesc,
  setMostrarValorLucro,
  setBloqPorPrecoMinimoControlDescLucro,
  setMostrarVlrLucroTotal,
  setBloqVendaClienteClassif8,
  setPercMinNegociacaoFinanc,
  setIdentificaPremiado,
  setInformarPercComisFrenteVenda,
  setBloqVendaClienteVlrPendRotativo,
} from "../store/frente_caixa_slice";

const operacoesPadrao = [
  { label: "Venda", value: "V" },
  { label: "Orçamento", value: "O" },
  { label: "Pedido", value: "P" },
];

const formasPagPadrao = [
  { label: "Dinheiro", value: "DIN" },
  { label: "Cheque a Vista", value: "CHV" },
  { label: "Cheque Pré-Datado", value: "CHP" },
  { label: "Cartão Débito", value: "CAD" },
  { label: "Cartão Crédito", value: "CAC" },
  { label: "Crediário", value: "CRE" },
  { label: "Crédito Rotativo", value: "ROT" },
];

const operacPadraoEntrega = [
  { label: "Empresa (Emitente)", value: "EMI" },
  { label: "Cliente (Destinatário)", value: "DES" },
  { label: "Sem Frete", value: "SEM" },
  { label: "Terceiros", value: "TER" },
];

export const PersonFrenteCaixa = () => {
  const store = useSelector((state) => state.frente_caixa);
  const dispatch = useDispatch();

  const onChangeUtilizaNegociacaoFinanc = () => {
    if (!store.utiliza_negociacao_financ) {
      dispatch(setPercMinNegociacaoFinanc(0));
    }
  };
  useEffect(onChangeUtilizaNegociacaoFinanc, [store.utiliza_negociacao_financ]);

  return (
    <>
      <Row>
        <RadioGroup
          label="Inclusão de Itens (Produtos e Serviços) na Grade"
          value={store.adiciona_item_grid}
          onChange={(v) => dispatch(setAdicionaItemGrid(v))}
        >
          <RadioItem label="Repetir" value="R" />
          <RadioItem label="Substituir" value="S" />
        </RadioGroup>
        <ComboBox
          options={operacoesPadrao}
          md={3}
          label="Operação Padrão"
          onChange={(s) => dispatch(setOperacaoPadrao(s?.value))}
          defaultValue={store.operacao_padrao}
        />
      </Row>
      <Row>
        <Label md="auto" className="pr-0 py-0">
          Operações Visíveis
        </Label>
        <FormCheckbox
          padded={false}
          label="Venda"
          checked={store.oper_visivel_venda}
          onChange={() =>
            dispatch(setOperVisivelVenda(!store.oper_visivel_venda))
          }
        />
        <FormCheckbox
          padded={false}
          label="Orçamento"
          checked={store.oper_visivel_orcamento}
          onChange={() =>
            dispatch(setOperVisivelOrcamento(!store.oper_visivel_orcamento))
          }
        />
        <FormCheckbox
          padded={false}
          label="Pedido"
          checked={store.oper_visivel_pedido}
          onChange={() =>
            dispatch(setOperVisivelPedido(!store.oper_visivel_pedido))
          }
        />
        <FormCheckbox
          padded={false}
          label="Ordem de Serviço"
          checked={store.oper_visivel_ordem_serv}
          onChange={() =>
            dispatch(setOperVisivelOrdemServ(!store.oper_visivel_ordem_serv))
          }
        />
        <FormCheckbox
          padded={false}
          label="Condicional"
          checked={store.oper_visivel_condicional}
          onChange={() =>
            dispatch(setOperVisivelCondicional(!store.oper_visivel_condicional))
          }
        />
      </Row>
      <Row>
        <ComboBox
          options={formasPagPadrao}
          md={4}
          label="Forma de Pagamento Padrão"
          onChange={(s) => dispatch(setFormaPagPadrao(s?.value))}
          defaultValue={store.forma_pag_padrao}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utiliza Identificação de Ambientes"
          checked={store.utiliza_ambiente}
          onChange={() => dispatch(setUtilizaAmbiente(!store.utiliza_ambiente))}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utiliza Identificação de Veículo"
          checked={store.utiliza_veiculo}
          onChange={() => dispatch(setUtilizaVeiculo(!store.utiliza_veiculo))}
        />
      </Row>
      <Row>
        <ComboBox
          options={operacPadraoEntrega}
          md={4}
          label="Operação Padrão de Entrega"
          onChange={(s) => dispatch(setOperPadraoEntrega(s?.value))}
          defaultValue={store.oper_padrao_entrega}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Identificar Tipo de Impressão ao Finalizar Processo no Frente de Caixa"
          checked={store.identif_tipo_impr_final_proc}
          onChange={() =>
            dispatch(
              setIdentifTipoImprFinalProc(!store.identif_tipo_impr_final_proc)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          label="Identifica Premiado"
          checked={store.identifica_premiado}
          onChange={() => {
            dispatch(setIdentificaPremiado(!store.identifica_premiado));
            if (store.identifica_premiado) {
              dispatch(setGerarPontosPor("V"));
              dispatch(setUtilizaRegraPremiacao(false));
              dispatch(setAvisaIdentifPremiado(false));
            }
          }}
        />
        <RadioGroup
          label="Gerar Pontos na Premiação Por"
          value={store.gerar_pontos_por}
          onChange={(v) => dispatch(setGerarPontosPor(v))}
          disabled={!store.identifica_premiado}
        >
          <RadioItem label="Valor" value="V" />
          <RadioItem label="Unidade Vendida" value="U" />
        </RadioGroup>
        <FormCheckbox
          label="Utilizar Regras de Pontuação Para Premiações"
          checked={store.utiliza_regra_premiacao}
          onChange={() =>
            dispatch(setUtilizaRegraPremiacao(!store.utiliza_regra_premiacao))
          }
          disabled={!store.identifica_premiado}
        />
        <FormCheckbox
          label="Avisar Caso Premiado Não For Preenchido"
          checked={store.avisa_identif_premiado}
          onChange={() =>
            dispatch(setAvisaIdentifPremiado(!store.avisa_identif_premiado))
          }
          disabled={!store.identifica_premiado}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Identificar Formas de Recebimento ao Finalizar a Venda"
          checked={store.identifica_forma_rec}
          onChange={() =>
            dispatch(setIdentificaFormaRec(!store.identifica_forma_rec))
          }
        />
      </Row>
      <Row>
        <RadioGroup
          label="Definir Limite de Cheque de Cliente Por"
          value={store.cheque_cliente_define_limite_por}
          onChange={(v) => dispatch(setChequeClienteDefineLimitePor(v))}
        >
          <RadioItem label="Situação" value="SITU" />
          <RadioItem label="Data de Bom Para" value="BOMP" />
        </RadioGroup>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Obrigar Autorização de Uso de Produtos Curinga"
          checked={store.obriga_autoriz_uso_prod_curinga}
          onChange={() =>
            dispatch(
              setObrigaAutorizUsoProdCuringa(
                !store.obriga_autoriz_uso_prod_curinga
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Bloquear Inclusão de Produto Sem Quantidade Suficiente Em Estoque"
          checked={store.bloq_inc_prod_qtd_est_negativo}
          onChange={() =>
            dispatch(
              setBloqIncProdQtdEstNegativo(
                !store.bloq_inc_prod_qtd_est_negativo
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          label={
            <>
              Mostrar Valor Líquido na Grid de frente de venda
              <HintLabel
                label=""
                hintText="Aconselhado para clientes que precisam considerar IPI e/ou ICMS ST no ato da venda"
              />
            </>
          }
          checked={store.mostrar_col_vlr_liq_grid_frente_venda}
          onChange={() =>
            dispatch(
              setMostrarColVlrLiqGridFrenteVenda(
                !store.mostrar_col_vlr_liq_grid_frente_venda
              )
            )
          }
          padded={false}
        />
      </Row>
      <Row>
        <RadioGroup
          label="Controla Valores de Itens Incluídos Por"
          value={store.bloq_inc_prod_por}
          onChange={(v) => dispatch(setBloqIncProdPor(v))}
        >
          <RadioItem label="% Desconto Máximo" value="DESC_MAX" />
          <RadioItem label="Preço Mínimo" value="PRECO_MIN" />
        </RadioGroup>
      </Row>
      <Row>
        <RadioGroup
          label="Controlar Desconto Por"
          value={store.forma_sistema_control_desc}
          onChange={(v) => dispatch(setFormaSistemaControlDesc(v))}
        >
          <RadioItem label="Desconto Máximo" value="SIM" />
          <RadioItem label="Margem de Lucro Mínima" value="PML" />
        </RadioGroup>
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Bloquear Inclusão de Itens Abaixo do Preço Mínimo"
          checked={store.bloq_por_preco_minimo_control_desc_lucro}
          onChange={() =>
            dispatch(
              setBloqPorPrecoMinimoControlDescLucro(
                !store.bloq_por_preco_minimo_control_desc_lucro
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Identificar Vendedor Final"
          checked={store.identifica_vendedor_final}
          onChange={() =>
            dispatch(
              setIdentificaVendedorFinal(!store.identifica_vendedor_final)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utilizar Desconto Controlado por Margem de Lucro (Somente Desktop)"
          checked={store.utiliza_negociacao_financ}
          onChange={() =>
            dispatch(
              setUtilizaNegociacaoFinanc(!store.utiliza_negociacao_financ)
            )
          }
        />
        <NumberInput
          md={3}
          label="% Mínimo de Negociação"
          value={store.perc_min_negociacao_financ}
          onChange={(v) => dispatch(setPercMinNegociacaoFinanc(v))}
          disabled={!store.utiliza_negociacao_financ}
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Mostrar Valor de Lucro"
          checked={store.mostrar_valor_lucro}
          onChange={() =>
            dispatch(setMostrarValorLucro(!store.mostrar_valor_lucro))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Mostrar Valor Total de Lucro"
          checked={store.mostrar_vlr_lucro_total}
          onChange={() =>
            dispatch(setMostrarVlrLucroTotal(!store.mostrar_vlr_lucro_total))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Bloquear Vendas para Clientes com Classificação 8"
          checked={store.bloq_venda_cliente_classif_8}
          onChange={() =>
            dispatch(
              setBloqVendaClienteClassif8(!store.bloq_venda_cliente_classif_8)
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Informa % de Comissao por Produto na Frente de Venda"
          checked={store.informar_perc_comis_frente_venda}
          onChange={() =>
            dispatch(
              setInformarPercComisFrenteVenda(
                !store.informar_perc_comis_frente_venda
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Bloquear Vendas para Clientes com Valores Pendentes no Rotativo"
          checked={store.bloq_venda_cliente_vlr_pend_rotativo}
          onChange={() =>
            dispatch(
              setBloqVendaClienteVlrPendRotativo(
                !store.bloq_venda_cliente_vlr_pend_rotativo
              )
            )
          }
        />
      </Row>
    </>
  );
};
