import React from "react";
import { formatValueFromAPI, formatarValor } from "../../../../../coreUtils";
import { Table, TableDelete } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { DropdownItem } from "reactstrap";

export const ItensNFGrid = ({
  dados,
  excluirItem,
  recalcularValoresGrade,
  atualizarDadosItem,
  abrirDadosImportacaoItem,
  alterarItem,
}) => {
  const columns = [
    {
      dataField: "item",
      text: "#",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "50px",
    },
    {
      dataField: "id_produto",
      text: "Produto",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "70px",
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      editable: (c, row) => !row.item_nf_icms,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      alwaysShowEditor: true,
      editorMaxLength: 120,
    },
    {
      dataField: "ncm",
      text: "NCM",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "80px",
    },
    {
      dataField: "sit_trib_icms",
      text: "CST",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "50px",
    },
    {
      dataField: "cfop",
      text: "CFOP",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "55px",
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "55px",
    },
    {
      dataField: "quantidade",
      text: "Qtd.",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "70px",
      formatter: (c, row) =>
        formatarValor(c, row.qtd_casas_decimais_qtd, 0, true),
      editable: (c, row) => !row.item_nf_icms,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: (row) => row.qtd_casas_decimais_qtd,
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_item",
      text: "Vlr. Item",
      align: "right",
      headerAlign: "right",
      fixedColWidth: true,
      colWidth: "80px",
      formatter: (c, row) =>
        formatarValor(
          c,
          row.qtd_casas_decimais_vlrs,
          row.qtd_casas_decimais_vlrs,
          true
        ),
      editable: (c, row) => !row.item_nf_icms,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: (row) => row.qtd_casas_decimais_vlrs,
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_icms",
      text: "ICMS",
      align: "right",
      headerAlign: "right",
      fixedColWidth: true,
      colWidth: "80px",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      headerAlign: "right",
      fixedColWidth: true,
      colWidth: "90px",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    iconeAlterar(alterarItem, {
      disabled: (id, row) => row.item_nf_icms,
      disabledHint: "Ação indisponível para itens de ICMS",
    }),
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      colHeaderStyle: { fontWeight: "normal" },
      fixedColWidth: true,
      colWidth: "40px",
      formatter: (c, row) => (
        <TableDelete
          onClick={() =>
            excluirItem(
              row.id,
              formatValueFromAPI(row.descricao, row.id_produto)
            )
          }
        />
      ),
    },
    dropdownAcoes({
      outrasAcoes: (id) => (
        <DropdownItem onClick={() => abrirDadosImportacaoItem(id)}>
          Dados de Importação
        </DropdownItem>
      ),
    }),
  ];

  return (
    <Table data={dados} columns={columns} pageSize={7} paginated={false} />
  );
};
