import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BaseInfoTelaCard,
  ComboBox,
  Loader,
} from "../../../../components";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { PageContainer } from "../../../../components/PageContainer";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import CadastroListaPrecosService from "../../../../services/cadastro/CadastroListaPrecosService";
import { DetalhesListaPara } from "./detalhes_para/DetalhesListaPara";
import { CadastroListaPrecosModal } from "./components/CadastroListaPrecosModal";
import { ListaPrecosGrid } from "./components/ListaPrecosGrid";
import { DetalhesListaDe } from "./detalhes_de/DetalhesListaDe";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { apiGetV2 } from "../../../../apiV2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const modalTitleListaPrecos = "Lista de Preços";
export const routesBaseListaPrecos = CadastroListaPrecosService.urlBase;

export const optionsListaPrecoPara = [
  { label: "Cliente", value: "CLIENTE" },
  { label: "Rota (APP Pronta Entrega)", value: "ROTA" },
  { label: "Vendedor (APP Pedidos)", value: "VENDEDOR" },
  { label: "Lista Selecionável Dinâmica", value: "LISTA_SELECT" },
];

export const optionsListaPrecoDe = [
  { label: "Todos os Produtos", value: "ALL" },
  { label: "Produto", value: "PRODUTO" },
  { label: "Grupo", value: "GRUPO" },
  { label: "Fabricante", value: "FABRICANTE" },
];

export const ListaPrecos = () => {
  const history = useHistory();
  const [loadingParams, setLoadingParams] = useState(true);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [nomeLista, setNomeLista] = useState(null);
  const [tipoListaDe, setTipoListaDe] = useState(null);
  const [tipoListaPara, setTipoListaPara] = useState(null);
  const [para, setPara] = useState(null);
  const [idPara, setIdPara] = useState(null);
  const [de, setDe] = useState(null);
  const [idDe, setIdDe] = useState(null);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarParams = async () => {
    try {
      const [ok, ret] = await apiGetV2("/tela/cad_lista_preco/");
      if (!ok) {
        history.goBack();
      }

      if (ret.tipo_lista_preco_utilizada === "DINAMICA") {
        optionsListaPrecoPara.splice(0, 3);
      } else {
        optionsListaPrecoPara.splice(3, 1);
        if (!ret.app_ped_vda_contratado) {
          optionsListaPrecoPara.splice(2, 1);
        }
        if (!ret.app_venda_dir_contratado) {
          optionsListaPrecoPara.splice(1, 1);
        }
      }
    } finally {
      setLoadingParams(false);
    }
  };

  useEffect(() => {
    buscarParams();
  }, []);

  const limparDados = () => {
    setDados([]);
  };

  const listarDados = async () => {
    setLoading(true);
    limparDados();
    try {
      const [ok, ret] = await CadastroListaPrecosService.listar({
        para: para,
        id_para: idPara,
        de: de,
        id_de: idDe,
      });
      if (ok) {
        setDados(ret);
      }
    } finally {
      setLoading(false);
    }
  };

  const notifyEvent = (action) => {
    listarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const handleSetIdPara = (selected) => {
    setIdPara(selected?.value);
    limparDados();
  };

  const handleSetIdDe = (selected) => {
    setIdDe(selected?.value);
    limparDados();
  };

  const handleSelect = (row) => {
    setSelected(row?.id);
    setNomeLista(row?.descricao);
    setTipoListaDe(row?.tipo_itens);
    setTipoListaPara(row?.tipo_lista);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  const labelPara = {
    CLIENTE: "Cliente",
    ROTA: "Rota",
    VENDEDOR: "Vendedor",
  }[para];
  const concatModelNamePara = {
    CLIENTE: "cliente",
    ROTA: "rota_mob",
    VENDEDOR: "colaborador",
  }[para];

  const labelDe = {
    ALL: "Todos os Produtos",
    PRODUTO: "Produto",
    GRUPO: "Grupo",
    FABRICANTE: "Fabricante",
  }[de];
  const concatModelNameDe = {
    ALL: null,
    PRODUTO: "produto",
    GRUPO: "grupo",
    FABRICANTE: "fabricante",
  }[de];

  if (loadingParams) {
    return <Loader />;
  }

  return (
    <PageContainer title="Cadastro de Listas de Preços" number="0013" canGoBack>
      <Card body>
        <Row>
          <ComboBox
            md={3}
            label="Para"
            options={optionsListaPrecoPara}
            onChange={(s) => {
              setPara(s?.value);
              setIdPara(null);
              limparDados();
            }}
            isSearchable={false}
            isClearable
          />
          <AsyncComboBox
            md={3}
            label={labelPara || "Selecione o tipo de filtro"}
            isConcatField
            concatModelName={concatModelNamePara}
            isSearchable
            isClearable
            onChange={handleSetIdPara}
            disabled={!para}
          />
          <ComboBox
            md={2}
            label="De"
            options={optionsListaPrecoDe}
            onChange={(s) => {
              setDe(s?.value);
              setIdDe(null);
              limparDados();
            }}
            isSearchable={false}
            isClearable
          />
          <AsyncComboBox
            md={3}
            label={labelDe || "Selecione o tipo de filtro"}
            isConcatField
            concatModelName={concatModelNameDe}
            isSearchable
            isClearable
            onChange={handleSetIdDe}
            disabled={!de || de === "ALL"}
          />
        </Row>
        <Row>
          <BotaoPesquisar onClick={listarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroListaPrecosModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleListaPrecos}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0013_2"
            selected={selected}
            routeBase={routesBaseListaPrecos}
            notifyEvent={notifyEvent}
          />
          <DetalhesListaPara
            idCab={selected}
            nomeLista={nomeLista}
            tipoListaPara={tipoListaPara}
            definirTipoListaPara={setTipoListaPara}
            notifyEvent={listarDados}
          />
          <DetalhesListaDe
            idCab={selected}
            nomeLista={nomeLista}
            tipoListaDe={tipoListaDe}
            tipoListaPara={tipoListaPara}
            definirTipoListaDe={setTipoListaDe}
            notifyEvent={listarDados}
          />
        </Row>
      </Card>
      <ListaPrecosGrid
        data={dados}
        onSelect={handleSelect}
        alterar={alterar}
        excluir={excluir}
      />
      <BaseInfoTelaCard nroTela="0013" />
    </PageContainer>
  );
};
