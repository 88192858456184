import React, { useMemo } from "react";
import { Table } from "../../../../../components";
import { formatarValor, formatValueFromAPI } from "../../../../../coreUtils";
import TableTerceiroNivel from "./TableTerceiroNivel";

const TableSegundoNivel = ({ tipo, dados }) => {
  const ehProntaEntrega = useMemo(() => tipo === "pronta_entrega", [tipo]);

  const columns = [
    {
      dataField: ehProntaEntrega ? "id_rota_mob" : "id_colab_vendedor",
      text: ehProntaEntrega ? "Rota" : "Vendedor",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) =>
        ehProntaEntrega
          ? formatValueFromAPI(row.nome_rota, c)
          : formatValueFromAPI(row.nome_vendedor, c),
    },
    {
      dataField: "qtd_vendas",
      text: "Quantidade de Vendas",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_tot_venda",
      text: "Total Venda",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "vlr_comissao",
      text: "Comissão",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
  ];

  return (
    <Table
      data={dados ?? []}
      columns={columns}
      keyField={ehProntaEntrega ? "id_rota_mob" : "id_colab_vendedor"}
      paginated={false}
      growIntoPageSize
      showRegisterCount={false}
      canExpand
      expandedAreaBuilder={(row) => <TableTerceiroNivel dados={row.vendas} />}
    />
  );
};

export default TableSegundoNivel;
