import React, { useState } from "react";
import {
  ModalBase,
  NumberInput,
  RadioGroup,
  TextInput,
} from "../../../../../components";
import { formatValueFromAPI } from "../../../../../coreUtils";
import { RadioItem } from "../../../../../components/RadioGroup";
import { Row } from "reactstrap";
import CredRotativoService from "../../../../../services/financeiro/CredRotativoService";
import { showSenha } from "../../../../../components/SenhaModalV2";

export const LancamentoManualCreditoModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
  nomeCliente,
}) => {
  const [exigeSenhaIncAltCredito, setExigeSenhaIncAltCredito] = useState(false);
  const [creditoAtual, setCreditoAtual] = useState(0);
  const [observ, setObserv] = useState("");
  const [natureza, setNatureza] = useState("+");
  const [valor, setValor] = useState(0);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setExigeSenhaIncAltCredito(false);
    setCreditoAtual(0);
    setObserv("");
    setNatureza("+");
    setValor(0);
  };

  const buscarDados = async (params) => {
    setExigeSenhaIncAltCredito(!params.cr_inclusao_credito_avulso_sem_senha);

    const [ok, ret] =
      await CredRotativoService.gerenciamento.buscarValoresConta(selected);
    if (ok) {
      setCreditoAtual(parseFloat(ret.vlr_credito));
    }
  };

  const confirmarLancamento = async (senha) => {
    const payload = {
      id_cliente: selected,
      observacao: observ ?? "",
      natureza: natureza ?? "",
      valor: valor ?? 0,
      senha_incluir_credito: senha ?? null,
    };

    const [ok] = await CredRotativoService.gerenciamento.lancamentoManual(
      payload
    );
    if (ok) {
      notifyEvent();
      toggle();
    }

    return ok;
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (exigeSenhaIncAltCredito) {
        showSenha({
          title: "Lançamento de Crédito",
          onConfirm: confirmarLancamento,
        });
        return;
      }

      await confirmarLancamento();
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Lançamento Manual de Crédito"
      number="0068_4"
      paramsName="lancamento_rotativo"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      onBeforeOpen={buscarDados}
      onClosed={limparDados}
    >
      <Row>
        <TextInput
          label="Cliente"
          value={formatValueFromAPI(nomeCliente, selected)}
          disabled
        />
      </Row>
      <Row>
        <NumberInput
          md={4}
          label="Crédito Atual do Cliente"
          value={creditoAtual}
          disabled
        />
      </Row>
      <Row>
        <TextInput
          label="Observação"
          value={observ}
          onChange={(e, v) => setObserv(v)}
        />
      </Row>
      <Row>
        <RadioGroup label="Operação" value={natureza} onChange={setNatureza}>
          <RadioItem label="Acréscimo" value="+" />
          <RadioItem label="Decréscimo" value="-" />
        </RadioGroup>
        <NumberInput md={3} label="Valor" value={valor} onChange={setValor} />
        <NumberInput
          md={3}
          label="Resultado"
          value={creditoAtual + valor * (natureza === "+" ? 1 : -1)}
          disabled
        />
      </Row>
    </ModalBase>
  );
};
