import React from "react";
import { Table } from "../../../../../components";
import { formatarValor, formatValueFromAPI } from "../../../../../coreUtils";

const TableTerceiroNivel = ({ dados }) => {
  const columns = [
    {
      dataField: "id_venda",
      text: "# Venda",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "id_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(row.nome_cliente, c),
    },
    {
      dataField: "data_emi",
      text: "Data",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "hora_emi",
      text: "Hora",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "vlr_tot",
      text: "Total",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "vlr_comissao",
      text: "Comissão",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
  ];

  return (
    <Table
      data={dados ?? []}
      columns={columns}
      keyField="desc"
      paginated={false}
      growIntoPageSize
      showRegisterCount={false}
      pageSize={7}
    />
  );
};

export default TableTerceiroNivel;
