import React, { useState } from "react";
import { Row } from "reactstrap";
import { AsyncComboBox, NumberInput } from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import {
  modalTitleDescPorQuantidade,
  routesBaseDescPorQuantidade,
} from "../DescPorQuantidade";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { showWarning } from "../../../../../components/AlertaModal";

export const CadastroDescPorQuantidadeModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [idProduto, setIdProduto] = useState(0);
  const [precoVendaProduto, setPrecoVendaProduto] = useState(0);
  const [percDesconto, setPercDesconto] = useState(0);
  const [qtdInicial, setQtdInicial] = useState(0);
  const [qtdFinal, setQtdFinal] = useState(0);
  const [precoAplicado, setPrecoAplicado] = useState(0);

  const limparDados = () => {
    setIdProduto(0);
    setPrecoVendaProduto(0);
    setPercDesconto(0);
    setQtdInicial(0);
    setQtdFinal(0);
    setPrecoAplicado(0);
  };

  const fetchData = (ret) => {
    setIdProduto(ret.id_produto);
    setPrecoAplicado(parseFloat(ret.vlr_preco_unit));
    setQtdInicial(ret.qtd_inicial);
    setQtdFinal(ret.qtd_final);
    setPercDesconto(parseFloat(ret.perc_desc));
  };

  const calcularValores = (__precoAplicado) => {
    setPrecoAplicado(__precoAplicado);
    if (__precoAplicado < precoVendaProduto) {
      let __percDesconto = 100 - (__precoAplicado * 100) / precoVendaProduto;
      setPercDesconto(__percDesconto);
    } else {
      setPercDesconto(0);
    }
  };

  const handleSelectProduto = (selected) => {
    const { value, preco_venda: precoVenda } = selected || {};
    setIdProduto(value);
    if (value) {
      setPrecoVendaProduto(precoVenda);
    }
  };

  const submitPayload = (action) => {
    if ([0, null, undefined].includes(idProduto)) {
      showWarning(
        "Selecione o produto e tente novamente.",
        null,
        null,
        "async-produto"
      );
      return false;
    }

    if ([0, null, undefined].includes(qtdInicial)) {
      showWarning(
        "Informe a quantidade inicial e tente novamente.",
        null,
        null,
        "input-qtd-inic"
      );
      return false;
    }

    if ([0, null, undefined].includes(qtdFinal)) {
      showWarning(
        "Informe a quantidade final e tente novamente.",
        null,
        null,
        "input-qtd-final"
      );
      return false;
    }
    if ([0, null, undefined].includes(precoAplicado)) {
      showWarning(
        "Informe o preço aplicado e tente novamente.",
        null,
        null,
        "input-preco-aplic"
      );
      return false;
    }

    return action === MODAL_ACTIONS.ADD
      ? {
          id_prod: idProduto,
          qtd_ini: qtdInicial,
          qtd_fin: qtdFinal,
          p_desc: percDesconto,
          v_unit: precoAplicado,
        }
      : {
          id: selected,
          id_produto: idProduto,
          qtd_ini: Number.parseFloat(qtdInicial),
          qtd_fin: Number.parseFloat(qtdFinal),
          p_desc: percDesconto,
          v_unit: precoAplicado,
        };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleDescPorQuantidade}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseDescPorQuantidade}
      number="0017_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <AsyncComboBox
          autoFocus
          md={9}
          isConcatField
          concatModelName="produto"
          defaultOptions
          label="Produto"
          isSearchable
          isClearable
          name="produto"
          onChange={handleSelectProduto}
          defaultValue={idProduto}
          inputName={"async-produto"}
        />
      </Row>
      <Row>
        <NumberInput
          required
          md={3}
          label="Quantidade Inicial"
          onChange={setQtdInicial}
          value={qtdInicial}
          name={"input-qtd-inic"}
        />
        <NumberInput
          required
          md={3}
          label="Quantidade Final"
          onChange={setQtdFinal}
          value={qtdFinal}
          name={"input-qtd-final"}
        />
      </Row>
      <Row>
        <NumberInput
          required
          md={3}
          label="Preço de Venda"
          disabled
          value={precoVendaProduto}
        />
        <NumberInput
          required
          md={3}
          label="Desconto (%)"
          disabled
          value={percDesconto}
          decimalPlaces={8}
        />
        <NumberInput
          required
          md={3}
          label="Preço Aplicado"
          onChange={calcularValores}
          value={precoAplicado}
          name={"input-preco-aplic"}
        />
      </Row>
    </ModalCadastroV2>
  );
};
