import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroUnidadeModal } from "./components/CadastroUnidadeModal";
import { UnidadeGrid } from "./components/UnidadeGrid";
import {
  BotaoPesquisar,
  PageContainer,
  SearchInput,
} from "../../../../components";
import {
  MODAL_ACTIONS,
  defaultDebounceTime,
  useDebounce,
} from "../../../../coreUtils";
import CadastroUnidadeService from "../../../../services/cadastro/CadastroUnidadeService";

export const Unidade = () => {
  const [pista, setPista] = useState("");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);

  const buscarDados = async (p) => {
    const params = { pista: p };
    const [ok, ret] = await CadastroUnidadeService.listar(params);
    return ok ? ret : [];
  };

  const carregarDados = async () => {
    setLoading(true);
    setDados(await buscarDados(pista));
    setLoading(false);
  };

  const handlePista = useDebounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    setDados(await buscarDados(v));
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  return (
    <PageContainer
      title="Cadastro de Unidades de Medida"
      number="0076"
      canGoBack
    >
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <CadastroUnidadeModal selected={selected} notifyEvent={notifyEvent} />
        </Row>
      </Card>
      <Card body>
        <UnidadeGrid data={dados} setSelected={setSelected} />
      </Card>
    </PageContainer>
  );
};
