import React from "react";
import { useState } from "react";
import { Provider } from "react-redux";
import { Card, Row } from "reactstrap";
import {
  BotaoConfirmarVoltar,
  BotaoPesquisar,
  FormCheckbox,
  PageContainer,
  SearchInput,
} from "../../../../components";
import {
  defaultDebounceTime,
  MODAL_ACTIONS,
  useDebounce,
} from "../../../../coreUtils";
import { CadastroFornecedorModal } from "./components/CadastroFornecedorModal";
import { FornecedorGrid } from "./components/FornecedorGrid";
import store from "./store";
import { apiGetV2 } from "../../../../apiV2";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";

export const modalTitleFornecedor = "Fornecedor";
export const routesBaseFornecedor = "/cadastro/fornecedor";

export const Fornecedor = () => {
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [pista, setPista] = useState("");
  const [somenteAtivos, setSomenteAtivos] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async (p) => {
    const params = { pista: p, somente_ativos: somenteAtivos };
    const [ok, ret] = await apiGetV2(`${routesBaseFornecedor}/listar/`, params);
    setDados(ok ? ret : []);
  };

  const handlePista = useDebounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const notifyEvent = (action) => {
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
    carregarDados();
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Fornecedores" number="0033" canGoBack>
      <Card body>
        <Row>
          <SearchInput
            md={6}
            value={pista}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Nome, CPF/CNPJ ou Cidade"
          />
          <FormCheckbox
            label="Somente Ativos"
            checked={somenteAtivos}
            onChange={() => setSomenteAtivos(!somenteAtivos)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <Provider store={store}>
            <CadastroFornecedorModal
              isOpen={cadastroOpen}
              toggle={toggleCadastro}
              action={action}
              selected={selected}
              notifyEvent={notifyEvent}
            />
          </Provider>
          <ModalExcluirV2
            title={modalTitleFornecedor}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0033_2"
            selected={selected}
            routeBase={routesBaseFornecedor}
            notifyEvent={notifyEvent}
          />
          <BotaoConfirmarVoltar
            selected={selected}
            carregarDados={carregarDados}
          />
        </Row>
      </Card>
      <FornecedorGrid
        data={dados}
        setSelected={setSelected}
        alterar={alterar}
        excluir={excluir}
      />
    </PageContainer>
  );
};
