import React, { useEffect, useMemo, useState } from "react";
import { Plus, X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Row } from "reactstrap";
import { apiGet } from "../../../../../api";
import {
  AsyncComboBox,
  ComboBox,
  FixedField,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  NumberInput,
  PesqPlanoCtaCentroCusto,
  TextInput,
} from "../../../../../components";
import { Divider } from "../../../../../components/Divider";
import { HintLabel } from "../../../../../components/HintLabel";
import MaskedInput from "../../../../../components/MaskedInput";
import { RadioGroup, RadioItem } from "../../../../../components/RadioGroup";
import {
  setCepAvalista,
  setCnpjAvalista,
  setCodCedente,
  setCodTransmissao,
  setDiasMulta,
  setEnderecoAvalista,
  setEspecieTit,
  setIdCidadeAvalista,
  setImprObservDuplicRecMov,
  setMensagem1,
  setMensagem2,
  setMensagem3,
  setMensagem4,
  setNomeAvalista,
  setNomeEmprImprBoleto,
  setPadrao,
  setPercJuros,
  setPercMulta,
  setPosto,
  setTipoCarteira,
  setTipoJuros,
  setTipoMulta,
  setVariacaoCarteira,
  setVlrJuros,
  setVlrMulta,
  setDiasJuros,
  setCobrarJurosMesDia,
  setModoIntegracao,
  setIdPlanoContaBaixaCancelBoleto,
  setIntegraApiWebhookConfig,
  setDiasNegativacao,
  setProtestar,
  setDiasProtesto,
  setFormaInadimplencia,
} from "../store/cadastroContaBancSlice";
import { initialFieldsState, setupFields } from "../store/controleCamposSlice";
import {
  DadosCobrancaCnab,
  formasInadimplenciaOptions,
} from "./DadosCobrancaCnab";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { apiPostV2 } from "../../../../../apiV2";
import { hintsEspecieTitulo } from "./HintsCampos";
import { bancosIntegracao } from "./TabDadosGerais";

const rowClassName = "mb-2";

const CampoMensagem = ({
  count,
  value,
  onChange,
  messageCount,
  setMessageCount,
  maxMessageCount,
}) => {
  return (
    (messageCount >= count || value !== "") && (
      <Row className={rowClassName}>
        <div style={{ width: "100%" }}>
          <TextInput
            md={12}
            label={`${count}a Mensagem`}
            value={value}
            onChange={(e, v) => onChange(v)}
            maxLength={100}
            autoFocus={messageCount > 1}
          />
          {messageCount < maxMessageCount &&
            messageCount === count &&
            value !== "" && (
              <span
                className="float-right mr-3"
                onClick={() => setMessageCount(messageCount + 1)}
                style={{ cursor: "pointer" }}
              >
                Adicionar nova mensagem
              </span>
            )}
        </div>
      </Row>
    )
  );
};

export const TabInfoCobranca = ({
  action,
  selected,
  messageCount,
  setMessageCount,
  maxMessageCount,
  setMaxMessageCount,
  temInfoSacaAval,
  setTemInfoSacaAval,
}) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.cadastroContaBanc.cobranca);
  const controle = useSelector((state) => state.controleCampos);

  const [loadingConfigWebhook, setLoadingConfigWebhook] = useState(false);

  const configurarWebhook = async () => {
    setLoadingConfigWebhook(true);
    try {
      const [ok] = await apiPostV2(
        "/bancario/cobranca/integracao_api/contratar_webhook/",
        { id_conta_banc: selected }
      );
      if (ok) {
        dispatch(setIntegraApiWebhookConfig(true));
      }
    } finally {
      setLoadingConfigWebhook(false);
    }
  };

  useEffect(() => {}, [store.layout]);

  const checkMessages = (padrao) => {
    const maxMsgCount =
      padrao === "400" || store.modo_integracao === "API" ? 4 : 2;
    if (maxMsgCount < messageCount) {
      dispatch(setMensagem3(""));
      dispatch(setMensagem4(""));
      setMessageCount(maxMsgCount);
    }
    setMaxMessageCount(maxMsgCount);
  };

  const limparSacadorAvalista = () => {
    dispatch(setNomeAvalista(""));
    dispatch(setCnpjAvalista(""));
    dispatch(setEnderecoAvalista(""));
    dispatch(setIdCidadeAvalista(0));
    dispatch(setCepAvalista(""));
    setTemInfoSacaAval(false);
  };

  const handleSetPadrao = (v) => {
    dispatch(setPadrao(v));
    checkMessages(v);
  };

  const disableModoIntegracao = useMemo(
    () => !controle.tem_integra_api || !controle.tem_integra_cnab,
    [controle.tem_integra_api, controle.tem_integra_cnab]
  );

  const handleSetFormaInadimplencia = (v) => {
    if (v === store.forma_inadimplencia) return;
    if (v === "PRO") {
      dispatch(setDiasNegativacao(0));
      dispatch(setProtestar(true));
    } else {
      dispatch(setDiasProtesto(0));
      dispatch(setProtestar(false));
    }
    dispatch(setFormaInadimplencia(v));
  };

  return (
    <>
      <Row className={rowClassName}>
        <RadioGroup
          label="Modo de Integração do Boleto"
          value={store.modo_integracao}
          onChange={(v) => {
            dispatch(setModoIntegracao(v));
          }}
          disabled={disableModoIntegracao}
        >
          <RadioItem label="CNAB" value="CNAB" />
          <RadioItem label="API" value="API" />
        </RadioGroup>
        {store.modo_integracao === "CNAB" && (
          <RadioGroup
            label="Padrão CNAB"
            value={store.padrao}
            onChange={(v) => handleSetPadrao(v)}
          >
            <RadioItem
              label="240 Posições"
              value="240"
              disabled={controle.padrao_remessa === "400"}
            />
            <RadioItem
              label="400 Posições"
              value="400"
              disabled={controle.padrao_remessa === "240"}
            />
          </RadioGroup>
        )}
        <FixedField
          label="Banco de Integração"
          boldValue
          value={
            bancosIntegracao.find((e) => e.value === store.layout)?.label ||
            "Não Especificado"
          }
        />
        {store.modo_integracao === "API" && action === MODAL_ACTIONS.EDIT && (
          <div className="ml-auto">
            {store.integra_api_webhook_config === true ? (
              <Badge
                color="success"
                className="py-2 px-3"
                style={{ fontSize: "0.85rem", marginTop: "19.6px" }}
                pill
              >
                Webhook Configurado
              </Badge>
            ) : (
              <FormButton
                color="secondary"
                onClick={configurarWebhook}
                loading={loadingConfigWebhook}
              >
                Configurar Webhook
              </FormButton>
            )}
          </div>
        )}
      </Row>
      {store.modo_integracao === "CNAB" ? (
        <DadosCobrancaCnab
          action={action}
          selected={selected}
          checkMessages={checkMessages}
        />
      ) : (
        <>
          {store.layout === "SIB" && (
            <Row className={rowClassName}>
              <MaskedInput
                label={
                  <HintLabel
                    label="Código do Cedente"
                    hintText={"Informar Acessório Escritural"}
                    enabled={store.layout === "BRA"}
                  />
                }
                md={3}
                onChange={(e, v) => dispatch(setCodCedente(v))}
                value={store.cod_cedente}
                placeholder={store.layout === "BRA" && "Acessório Escritural"}
                mask="999999999999999"
                maskChar={null}
              />
              <MaskedInput
                md={2}
                label={
                  <HintLabel
                    label="Espécie do Título"
                    hintText={hintsEspecieTitulo(store.layout)}
                    tooltipPlacement="right"
                  />
                }
                value={store.especie_tit}
                onChange={(e, v) => dispatch(setEspecieTit(v))}
                mask={store.layout === "SIC" ? "a" : "99"}
                maskChar={null}
              />
            </Row>
          )}
          {["SAF", "SIC"].includes(store.layout) && (
            <>
              <Row className={rowClassName}>
                {store.layout === "SIC" && (
                  <>
                    <MaskedInput
                      label={
                        <HintLabel
                          label="Código do Cedente"
                          hintText={"Informar Acessório Escritural"}
                          enabled={store.layout === "BRA"}
                        />
                      }
                      md={3}
                      onChange={(e, v) => dispatch(setCodCedente(v))}
                      value={store.cod_cedente}
                      placeholder={
                        store.layout === "BRA" && "Acessório Escritural"
                      }
                      mask="999999999999999"
                      maskChar={null}
                    />
                    <MaskedInput
                      md={2}
                      label="Posto"
                      value={store.posto}
                      onChange={(e, v) => dispatch(setPosto(v))}
                      mask="99999"
                      maskChar={null}
                    />
                  </>
                )}
                <MaskedInput
                  md={2}
                  label={
                    <HintLabel
                      label="Espécie do Título"
                      hintText={hintsEspecieTitulo(store.layout)}
                      tooltipPlacement="right"
                    />
                  }
                  value={store.especie_tit}
                  onChange={(e, v) => dispatch(setEspecieTit(v))}
                  mask={store.layout === "SIC" ? "a" : "99"}
                  maskChar={null}
                />
              </Row>
              {store.layout === "SIC" && (
                <Row className={rowClassName}>
                  <ComboBox
                    options={formasInadimplenciaOptions}
                    md={3}
                    label="Forma - inadimplência"
                    onChange={(s) => handleSetFormaInadimplencia(s?.value)}
                    defaultValue={store.forma_inadimplencia}
                  />
                  {store.forma_inadimplencia === "PRO" && (
                    <FormCheckbox
                      label="Protestar Títulos"
                      checked={store.protestar}
                      onChange={() => dispatch(setProtestar(!store.protestar))}
                      name="protestar"
                    />
                  )}
                  {(store.protestar || store.forma_inadimplencia !== "PRO") && (
                    <IntegerFormInput
                      md={3}
                      label={`Nº de Dias Para ${
                        store.forma_inadimplencia === "PRO"
                          ? "Protesto"
                          : "Negativação"
                      }`}
                      defaultValue={
                        store.forma_inadimplencia === "PRO"
                          ? store.dias_protesto
                          : store.dias_negativacao
                      }
                      onChange={(v) =>
                        dispatch(
                          store.forma_inadimplencia === "PRO"
                            ? setDiasProtesto(v)
                            : setDiasNegativacao(v)
                        )
                      }
                    />
                  )}
                </Row>
              )}
            </>
          )}
        </>
      )}
      <Row className={rowClassName}>
        <RadioGroup
          label="Multa - Tipo"
          value={store.tipo_multa}
          onChange={(v) => dispatch(setTipoMulta(v))}
        >
          <RadioItem label="Percentual" value="PER" />
          <RadioItem label="Valor" value="VAL" />
        </RadioGroup>
        <NumberInput
          label="Multa por Atraso"
          md={2}
          onChange={(v) =>
            dispatch(
              store.tipo_multa === "PER" ? setPercMulta(v) : setVlrMulta(v)
            )
          }
          value={
            store.tipo_multa === "PER" ? store.perc_multa : store.vlr_multa
          }
          isPercentage={store.tipo_multa === "PER"}
        />
        <IntegerFormInput
          md={3}
          label="Dias Corridos Após o Vencimento"
          defaultValue={store.dias_multa}
          onChange={(v) => dispatch(setDiasMulta(v))}
        />
      </Row>
      <Row className={rowClassName}>
        <RadioGroup
          label="Juros - Tipo"
          value={store.tipo_juros}
          onChange={(v) => dispatch(setTipoJuros(v))}
        >
          <RadioItem label="Percentual" value="P" />
          <RadioItem label="Valor" value="V" />
        </RadioGroup>
        <NumberInput
          label={
            store.tipo_juros === "P"
              ? "Percentual de Juros"
              : "Valor de Juros ao Dia"
          }
          md={3}
          onChange={(v) =>
            dispatch(
              store.tipo_juros === "P" ? setPercJuros(v) : setVlrJuros(v)
            )
          }
          value={store.tipo_juros === "P" ? store.perc_juros : store.vlr_juros}
          isPercentage={store.tipo_juros === "P"}
        />
        <RadioGroup
          label={
            <>
              Cobrar Juros
              <HintLabel
                hintText={
                  "Quando a opção AO DIA estiver marcada, o sistema fará " +
                  "a conversão do percentual de juros em valor no " +
                  "momento da geração da remessa, devido a limitações do padrão " +
                  "CNAB, que não permite percentual de juros ao dia"
                }
              />
            </>
          }
          value={store.cobrar_juros_mes_dia}
          onChange={(v) => dispatch(setCobrarJurosMesDia(v))}
          disabled={store.tipo_juros === "V"}
        >
          <RadioItem label="Ao Dia" value="DIA" />
          <RadioItem label="Ao Mês" value="MES" />
        </RadioGroup>
        <IntegerFormInput
          md={3}
          label="Dias Corridos Após o Vencimento"
          defaultValue={store.dias_juros}
          onChange={(v) => dispatch(setDiasJuros(v))}
        />
      </Row>
      <CampoMensagem
        count={1}
        value={store.mensagem_1}
        onChange={(v) => dispatch(setMensagem1(v))}
        messageCount={messageCount}
        setMessageCount={setMessageCount}
        maxMessageCount={maxMessageCount}
      />
      <CampoMensagem
        count={2}
        value={store.mensagem_2}
        onChange={(v) => dispatch(setMensagem2(v))}
        messageCount={messageCount}
        setMessageCount={setMessageCount}
        maxMessageCount={maxMessageCount}
      />
      <CampoMensagem
        count={3}
        value={store.mensagem_3}
        onChange={(v) => dispatch(setMensagem3(v))}
        messageCount={messageCount}
        setMessageCount={setMessageCount}
        maxMessageCount={maxMessageCount}
      />
      <CampoMensagem
        count={4}
        value={store.mensagem_4}
        onChange={(v) => dispatch(setMensagem4(v))}
        messageCount={messageCount}
        setMessageCount={setMessageCount}
        maxMessageCount={maxMessageCount}
      />
      {store.modo_integracao === "CNAB" && (
        <Row className={rowClassName}>
          <TextInput
            md={12}
            label="Nome da Empresa Impresso No boleto"
            value={store.nome_empr_impr_boleto}
            onChange={(e, v) => dispatch(setNomeEmprImprBoleto(v))}
            maxLength={60}
          />
        </Row>
      )}
      <Row className={rowClassName}>
        <FormCheckbox
          label="Imprimir Mensagem do Titulo a Receber no Boleto"
          checked={store.impr_observ_duplic_rec_mov}
          onChange={() =>
            dispatch(
              setImprObservDuplicRecMov(!store.impr_observ_duplic_rec_mov)
            )
          }
          name="impr_observ_duplic_rec_mov"
          padded={false}
        />
      </Row>
      <Row className={rowClassName}>
        <PesqPlanoCtaCentroCusto
          md={6}
          label="Plano de Contas para Baixa/Cancelamento de Boletos no Retorno Bancário"
          mode="plano_contas"
          isClearable
          onChange={(v) => dispatch(setIdPlanoContaBaixaCancelBoleto(v))}
          value={store.id_plano_conta_baixa_cancel_boleto}
          defaultOptions
        />
      </Row>
      {store.modo_integracao === "CNAB" && (
        <>
          <Divider>
            Informações do Sacador / Avalista{" "}
            {temInfoSacaAval ? (
              <X
                size={18}
                style={{ cursor: "pointer" }}
                onClick={() => limparSacadorAvalista()}
                color="#800909"
              />
            ) : (
              <Plus
                size={18}
                style={{ cursor: "pointer" }}
                onClick={() => setTemInfoSacaAval(true)}
                color="green"
              />
            )}
          </Divider>
          {temInfoSacaAval && (
            <>
              <Row className={rowClassName}>
                <TextInput
                  md={8}
                  label="Nome do Sacador Avalista"
                  value={store.nome_avalista}
                  onChange={(e, v) => dispatch(setNomeAvalista(v))}
                  maxLength={60}
                />
                <MaskedInput
                  mask="99.999.999/9999-99"
                  name="cnpj_avalista"
                  label="CNPJ"
                  md={4}
                  value={store.cnpj_avalista}
                  onChange={(e, v) => dispatch(setCnpjAvalista(v))}
                />
              </Row>
              <Row className={rowClassName}>
                <TextInput
                  md={8}
                  label="Endereço"
                  value={store.endereco_avalista}
                  onChange={(e, v) => dispatch(setEnderecoAvalista(v))}
                  maxLength={45}
                />
              </Row>
              <Row className={rowClassName}>
                <AsyncComboBox
                  onChange={(s) => dispatch(setIdCidadeAvalista(s?.value ?? 0))}
                  label="Cidade"
                  md={6}
                  isConcatField
                  concatModelName="cidade"
                  defaultOptions
                  isSearchable
                  isClearable
                  defaultValue={store.id_cidade_avalista}
                />
                <MaskedInput
                  mask="99999-999"
                  name="cep_avalista"
                  label="CEP"
                  md={3}
                  onChange={(e, v) => dispatch(setCepAvalista(v))}
                  value={store.cep_avalista}
                />
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};
