import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  BotaoImprimir,
  FormButton,
  FormCheckbox,
} from "../../../../components";
import { PageContainer } from "../../../../components/PageContainer";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";
import { exportDataToSheet } from "../../../../coreUtils";

export const ProdutoSemMovSaida = () => {
  const [loading, setLoading] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);
  const [somenteAtivos, setSomenteAtivos] = useState(false);

  const buscarDados = async () => {
    const params = {
      somente_ativos: somenteAtivos,
    };

    return await apiGetV2(
      `/relatorios/cadastrais/produtos/produtos_sem_mov_saida/`,
      params
    );
  };

  const exportar = async () => {
    setLoadingExportar(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      const columnsExportar = [
        { dataField: "id", text: "ID" },
        { dataField: "nome", text: "Nome" },
        { dataField: "cod_bar", text: "Cód. Barras" },
        { dataField: "ativo", text: "Ativo" },
        { dataField: "quantidade", text: "Estoque" },
        { dataField: "preco_venda", text: "Preço Venda" },
      ];

      exportDataToSheet(
        ret.produtos,
        "Relatório Cadastral de Produtos sem Mov. de Saída",
        {
          columns: columnsExportar,
        }
      );
    }
    setLoadingExportar(false);
  };

  const imprimir = async () => {
    setLoading(true);

    const [ok, ret] = await buscarDados();

    if (ok) {
      await docPrintReport(
        "/relatorios/cadastrais/produtos/prods_sem_mov_saida/",
        ret,
        "5069",
        true
      );
    }

    setLoading(false);
  };

  return (
    <PageContainer
      title="Relatório Cadastral de Produtos sem Mov. de Saída"
      number="5069"
      canGoBack
    >
      <Card body>
        <Row>
          <FormCheckbox
            label={"Somente ativos"}
            value={somenteAtivos}
            onChange={() => setSomenteAtivos(!somenteAtivos)}
            padded={false}
          />
          <BotaoImprimir padded={false} onClick={imprimir} loading={loading} />
          <FormButton
            md="auto"
            color="primary"
            onClick={exportar}
            loading={loadingExportar}
            padded={false}
          >
            Exportar
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
