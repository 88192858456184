import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  DatePicker,
  ModalBase,
  TextInput,
  ComboBox,
  IntegerFormInput,
  PesqPlanoCtaCentroCusto,
} from "../../../../../components";
import moment from "moment";
import { dateFromLocaleString } from "../../../../../coreUtils";
import GerencBancService from "../../../../../services/financeiro/GerencBancService";

const opcoes = [
  { label: "Entrada", value: "ENT" },
  { label: "Saída", value: "SAI" },
];

export const AlterarMovModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [loading, setLoading] = useState(false)
  
  const [idConta, setIdConta] = useState(null);
  const [planoConta, setPlanoConta] = useState(null);
  const [natureza, setNatureza] = useState("");
  const [dataMov, setDataMov] = useState(new Date());
  const [nroDoc, setNroDoc] = useState("");
  const [valor, setValor] = useState(0);
  const [historico, setHistorico] = useState();

  const limparDados = () => {
    setIdConta(null);
    setPlanoConta(null);
    setNatureza("");
    setDataMov(new Date());
    setNroDoc("");
    setValor(null);
    setHistorico("");
  };

  const buscarDados = async () => {
    const [ok, ret] = await GerencBancService.buscar(selected);
    if (ok) {
      setIdConta(ret.id_conta_banc);
      setNatureza(ret.natureza);
      setDataMov(dateFromLocaleString(ret.data_mov));
      setNroDoc(ret.nro_doc);
      setHistorico(ret.historico);
      setPlanoConta(ret.id_plano_conta);
      setValor(ret.valor);
    } 
  };

  const handleSubmit = async () => {
    const payload = {
      id_conta_banc: idConta,
      natureza: natureza,
      data_mov: dataMov,
      nro_doc: nroDoc,
      id_plano_conta: planoConta,
      valor: valor,
      historico: historico,
    };
    setLoading(true);
    const [ok] = await GerencBancService.alterar(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Alteração de Movimento Manual"
        number="0132_2"
        onClosed={limparDados}
        onBeforeOpen={buscarDados}
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row>
          <AsyncComboBox
            md={6}
            label="Conta"
            isConcatField
            concatModelName="conta_banc"
            defaultValue={idConta}
            onChange={(s) => setIdConta(s?.value)}
          />
          <ComboBox
            md={3}
            label="Natureza"
            value={natureza}
            onChange={(v) => setNatureza(v)}
            options={opcoes}
          />
          <TextInput
            md={3}
            label="Número do Documento"
            defaultValue={nroDoc}
            onChange={setNroDoc}
          />
        </Row>
        <Row>
          <DatePicker
            md={2}
            label={"Data"}
            value={dataMov}
            onChange={(v) => setDataMov(moment.isMoment(v) ? v.toDate() : v)}
            tabIndex={100}
          />
          <PesqPlanoCtaCentroCusto
            md={7}
            label="Plano de Contas"
            mode="plano_contas"
            onChange={(v) => setPlanoConta(v)}
            value={planoConta}
          />
          <IntegerFormInput
            md={3}
            label="Valor"
            defaultValue={valor}
            onChange={setValor}
          />
        </Row>
        <Row>
          <TextInput
            md={5}
            label="Histórico"
            defaultValue={historico}
            onChange={setHistorico}
          />
        </Row>
      </ModalBase>
    </>
  );
};
