import { apiGetV2, apiPostV2 } from "../../apiV2";

const URL_BASE = "/pedido_externo/central/confer_valores_rec_maos";
const ConferValoresRecMaosService = {
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  conferirCheque: (payload) =>
    apiPostV2(`${URL_BASE}/conferir_cheque/`, payload),
  conferirDinheiro: (payload) =>
    apiPostV2(`${URL_BASE}/conferir_dinheiro/`, payload),
};

export default ConferValoresRecMaosService;
