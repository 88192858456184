import React, { useState } from "react";
import { ModalBase, NumberInput, TextInput } from "../../../../../components";
import { formatValueFromAPI } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import CredRotativoService from "../../../../../services/financeiro/CredRotativoService";
import { showSenha } from "../../../../../components/SenhaModalV2";

export const AlterarLimiteCredModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
  nomeCliente,
}) => {
  const [exigeSenha, setExigeSenha] = useState(false);
  const [limiteCred, setLimiteCred] = useState(0);
  const [vlrTotPend, setVlrTotPend] = useState(0);
  const [novoLimiteCred, setNovoLimiteCred] = useState(0);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setLimiteCred(0);
    setVlrTotPend(0);
    setNovoLimiteCred(0);
  };

  const buscarDados = async (params) => {
    setExigeSenha(params.solicita_senha_abre_conta_altera_limite);

    const [ok, ret] =
      await CredRotativoService.gerenciamento.buscarValoresConta(selected);
    if (ok) {
      setLimiteCred(parseFloat(ret.limite_cred));
      setVlrTotPend(parseFloat(ret.vlr_tot_pend));
    }
  };

  const confirmarAlteracao = async (senha) => {
    const payload = {
      id_cliente: selected,
      novo_limite: novoLimiteCred,
      senha_alterar_limite: senha ?? null,
    };

    const [ok] = await CredRotativoService.gerenciamento.alterarLimite(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }

    return ok;
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (exigeSenha) {
        showSenha({
          title: "Alteração de Limite",
          onConfirm: confirmarAlteracao,
        });
        return;
      }

      await confirmarAlteracao();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Alterar Limite de Crédito no Crédito Rotativo"
        number="0068_2"
        paramsName="lancamento_rotativo"
        onConfirm={handleSubmit}
        onBeforeOpen={buscarDados}
        onClosed={limparDados}
        loadingConfirm={loading}
      >
        <Row>
          <TextInput
            label="Cliente"
            value={formatValueFromAPI(nomeCliente, selected)}
            disabled
          />
        </Row>
        <Row>
          <NumberInput
            label="Limite de Crédito Atual"
            value={limiteCred}
            disabled
          />
          <NumberInput
            label="Valor Total Pendente"
            value={vlrTotPend}
            disabled
          />
          <NumberInput
            label="Novo Limite de Crédito"
            value={novoLimiteCred}
            onChange={setNovoLimiteCred}
          />
        </Row>
      </ModalBase>
    </>
  );
};
