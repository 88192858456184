import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Card, Row } from "reactstrap";
import {
  BaseInfoTelaCard,
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  ComboBox,
  FiltroPeriodoDatas,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { formatDateISO } from "../../../../coreUtils";
import { PedidosPendentesEntregaGrid } from "./components/PedidosPendentesEntregaGrid";
import { ProdutosPendentesAgrupadoGrid } from "./components/ProdutosPendentesAgrupadoGrid";
import { docPrintReport } from "../../../../pdf";
import PedidosPendentesEntregaService from "../../../../services/relatorios/pedido_venda/PedidosPendentesService";

const filtrarPorOptions = [
  { label: "Emissão", value: "E" },
  { label: "Previsão de Entrega", value: "P" },
];

const agruparPorOptions = [
  { label: "Por Produto", value: "P" },
  { label: "Por Cliente", value: "C" },
  { label: "Analítico", value: "A" },
];

const mostrarOptions = [
  { label: "Todos", value: "TOD" },
  { label: "Pedidos Internos", value: "INT" },
  { label: "Pedidos Externos", value: "EXT" },
];

export const PedidosPendentesEntrega = () => {
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDados, setLoadingDados] = useState(false);
  const [incluirItens, setIncluirItens] = useState(false);
  const [idCliente, setIdCliente] = useState(null);
  const [idCidade, setIdCidade] = useState(null);
  const [dados, setDados] = useState({});
  const [contagem, setContagem] = useState(0);
  const [total, setTotal] = useState(0);
  const [filtrarPor, setFiltrarPor] = useState(filtrarPorOptions[0].value);
  const [agruparPor, setAgruparPor] = useState(agruparPorOptions[0].value);
  const [mostrar, setMostrar] = useState(mostrarOptions[0].value);

  const limparDados = () => {
    setTotal(0);
    setContagem(0);
    setIdCidade(null);
    setIdCliente(null);
    setDados([]);
    setIncluirItens(false);
  };

  const handleDate = async (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = async () => {
    let params;
    let data;
    if (agruparPor === "A") {
      const invalidOpts = [null, undefined, 0];
      if (invalidOpts.includes(dataIni)) {
        toastr.error(
          "Data de início inválida",
          "Verifique a data e tente novamente"
        );
        return [false, null];
      }
      if (invalidOpts.includes(dataFim)) {
        toastr.error(
          "Data de fim inválida",
          "Verifique a data e tente novamente"
        );
        return [false, null];
      }
      params = {
        data_ini: dataIni,
        data_fim: dataFim,
        incluir_itens: incluirItens,
        filtro_data: filtrarPor,
        id_cliente: idCliente,
        id_cidade: idCidade,
        mostrar: mostrar,
      };

      return await PedidosPendentesEntregaService.buscarDados(params);
    } else {
      params = {
        mostrar: mostrar,
        id_cidade: idCidade,
      };
      data =
        agruparPor === "P"
          ? await PedidosPendentesEntregaService.buscarAgrupadoProduto(params)
          : await PedidosPendentesEntregaService.buscarAgrupadoCliente(params);
      return data;
    }
  };

  const listarDados = async () => {
    setLoadingDados(true);
    if (agruparPor === "A") {
      const [ok, ret] = await buscarDados();

      if (ok) {
        setTotal(ret.total);
        setContagem(ret.contagem);
        setDados(ret.pedidos);
      } else {
        setTotal(0);
        setContagem(0);
        setDados([]);
      }
    } else {
      const params = {
        id_cliente: idCliente,
        id_cidade: idCidade,
        mostrar: mostrar,
      };

      const getFunc =
        agruparPor === "P"
          ? PedidosPendentesEntregaService.buscarAgrupadoProduto
          : PedidosPendentesEntregaService.buscarAgrupadoCliente;

      const [ok, ret] = await getFunc(params);
      setDados(ok ? ret : []);
    }

    setLoadingDados(false);
  };

  const gerarRelatorio = async () => {
    const [ok, ret] = await buscarDados();
    let payload;
    if (ok) {
      setLoading(true);
      if (agruparPor === "P" || agruparPor === "C") {
        payload = {
          dados: ret,
        };
      }
      if (agruparPor === "A") {
        payload = {
          pedidos: ret.pedidos,
          total: ret.total,
          contagem: ret.contagem,
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
          incluir_itens: incluirItens,
        };
      }

      let rota;
      if (agruparPor === "P") {
        rota = "/relatorios/pedido/prods_pendentes_entrega/";
      }
      if (agruparPor === "A") {
        rota = "/relatorios/pedido/pendentes_entrega/";
      }
      if (agruparPor === "C") {
        rota = "/relatorios/pedido/cli_pendentes_entrega/";
      }
      await docPrintReport(rota, payload, "5018", true);

      setLoading(false);
    }
  };

  const handleSetAgrupamento = (v) => {
    limparDados();
    setAgruparPor(v);
  };

  return (
    <PageContainer title="Pedidos Pendentes de Entrega" number="5018" canGoBack>
      <Card body>
        <Row>
          <ComboBox
            label="Agrupamento"
            options={agruparPorOptions}
            md={2}
            defaultValue={agruparPor}
            onChange={(s) => handleSetAgrupamento(s?.value)}
          />
          <ComboBox
            label="Mostrar"
            options={mostrarOptions}
            md={2}
            defaultValue={mostrar}
            onChange={(s) => setMostrar(s?.value)}
          />
          <AsyncComboBox
            isConcatField
            concatModelName="cidade"
            label="Cidade"
            md={3}
            onChange={(selected) => setIdCidade(selected?.value)}
            isSearchable
            isClearable
            defaultOptions
          />
          {agruparPor === "A" && (
            <AsyncComboBox
              isConcatField
              concatModelName="cliente"
              label="Cliente"
              md={5}
              onChange={(selected) => setIdCliente(selected?.value)}
              isSearchable
              isClearable
            />
          )}
        </Row>
        <Row>
          {agruparPor === "A" && (
            <>
              <ComboBox
                label="Filtrar Por"
                options={filtrarPorOptions}
                md={3}
                defaultValue={filtrarPor}
                onChange={(selected) => setFiltrarPor(selected?.value)}
              />
              <FiltroPeriodoDatas onChange={handleDate} />
            </>
          )}
          <BotaoPesquisar
            onClick={listarDados}
            loading={loadingDados}
            padded={agruparPor === "A"}
          />
          {agruparPor === "A" && (
            <FormCheckbox
              label="Incluir Itens"
              name="incluir_itens_checkbox"
              onChange={() => setIncluirItens(!incluirItens)}
              checked={incluirItens}
            />
          )}
          <BotaoImprimir
            loading={loading}
            onClick={gerarRelatorio}
            padded={agruparPor === "A"}
          />
        </Row>
      </Card>
      {dados.length > 0 &&
        (agruparPor === "A" ? (
          <PedidosPendentesEntregaGrid
            data={dados}
            total={total}
            contagem={contagem}
          />
        ) : (
          <ProdutosPendentesAgrupadoGrid
            dados={dados}
            agruparPor={agruparPor}
          />
        ))}
      <BaseInfoTelaCard nroTela="5018" />
    </PageContainer>
  );
};
