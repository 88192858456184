import React from "react";
import { useState } from "react";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { TextInput } from "../../../../../components";
import { Row } from "reactstrap";
import { modalTitleEquipamento, routesBaseEquipamento } from "../Equipamento";

export const CadastroEquipamentoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
  concatShortcut,
}) => {
  const [nome, setNome] = useState("");
  const [montadora, setMontadora] = useState("");
  const [capacidade, setCapacidade] = useState("");
  const [ativo, setAtivo] = useState(true);

  const limparDados = () => {
    setNome("");
    setMontadora("");
    setCapacidade("");
    setAtivo(true);
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setMontadora(data.marca);
    setCapacidade(data.capacidade);
    setAtivo(data.ativo);
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
      marca: montadora,
      capacidade: capacidade,
      ativo: ativo,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ativo: ativo, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleEquipamento}
      size="md"
      headerCheck={
        action === MODAL_ACTIONS.EDIT && {
          value: ativo,
          toggle: () => setAtivo(!ativo),
        }
      }
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      number="0138_1"
      selected={selected}
      notifyEvent={notifyEvent}
      concatShortcut={concatShortcut}
      concatModelName="equipamento"
      routesBase={routesBaseEquipamento}
    >
      <Row>
        <TextInput label="Nome" value={nome} onChange={(e, v) => setNome(v)} />
      </Row>
      <Row>
        <TextInput
          md={6}
          label="Montadora"
          value={montadora}
          onChange={(e, v) => setMontadora(v)}
        />
        <TextInput
          md={6}
          label="Capacidade"
          value={capacidade}
          onChange={(e, v) => setCapacidade(v)}
        />
      </Row>
    </ModalCadastroV2>
  );
};
