import React from "react";
import { Table } from "../../../../../components";

const columns = [
  {
    dataField: "data_hora_log",
    text: "Data Hora",
    align: "center",
  },
  {
    dataField: "log",
    text: "Log",
    align: "left",
    colWidth: "60%",
  },
  {
    dataField: "nome_colaborador",
    text: "Colaborador",
    align: "left",
  },
];

export const LogsGrid = ({ dados }) => {
  return (
    <Table
      columns={columns}
      data={dados}
      paginated={false}
      pageSize={dados.length > 5 ? 5 : dados.length}
      showRegisterCount={false}
    />
  );
};
