import React, { useEffect } from "react";
import { useState } from "react";
import { modalTitleRegraComissaoOs } from "../RegraComissaoOs";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import CadastroRegrasComissaoOsService from "../../../../../services/cadastro/CadastroRegrasComissaoOsService";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormCheckbox,
  NumberInput,
} from "../../../../../components";
import { showWarning } from "../../../../../components/AlertaModal";

export const CadastroRegraComissaoOsModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [idTecnico, setIdTecnico] = useState(null);
  const [aplicComissaoPadraoProd, setAplicComissaoPadraoProd] = useState(false);
  const [percComissaoPadraoProd, setPercComissaoPadraoProd] = useState(0);
  const [aplicComissaoPadraoServ, setAplicComissaoPadraoServ] = useState(false);
  const [percComissaoPadraoServ, setPercComissaoPadraoServ] = useState(0);

  const limparDados = () => {
    setIdTecnico(null);
    setAplicComissaoPadraoProd(false);
    setPercComissaoPadraoProd(0);
    setAplicComissaoPadraoServ(false);
    setPercComissaoPadraoServ(0);
  };

  const fetchData = (data) => {
    setIdTecnico(data.id_tecnico);
    setAplicComissaoPadraoProd(data.aplic_comissao_padrao_prod);
    setPercComissaoPadraoProd(parseFloat(data.perc_comissao_padrao_prod));
    setAplicComissaoPadraoServ(data.aplic_comissao_padrao_serv);
    setPercComissaoPadraoServ(parseFloat(data.perc_comissao_padrao_serv));
  };

  const submitPayload = (action) => {
    if ([0, null, undefined].includes(idTecnico)) {
      showWarning(
        "Por favor, selecione o Técnico",
        null,
        null,
        "async-id-tecnico"
      );
      return;
    }

    if (
      aplicComissaoPadraoProd &&
      [0, null, undefined].includes(percComissaoPadraoProd)
    ) {
      showWarning(
        "Por favor, informe o Percentual de Comissão Padrão de PRODUTOS",
        null,
        null,
        "input-percent-comissao-prod"
      );
      return;
    }

    if (
      aplicComissaoPadraoServ &&
      [0, null, undefined].includes(percComissaoPadraoServ)
    ) {
      showWarning(
        "Por favor, informe o Percentual de Comissão Padrão de SERVIÇOS",
        null,
        null,
        "input-percent-comissao-serv"
      );
      return;
    }

    const payload = {
      id_tecnico: idTecnico,
      aplic_comissao_padrao_prod: aplicComissaoPadraoProd,
      perc_comissao_padrao_prod: percComissaoPadraoProd,
      aplic_comissao_padrao_serv: aplicComissaoPadraoServ,
      perc_comissao_padrao_serv: percComissaoPadraoServ,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  const onChangeAplicComissaoPadraoProd = () => {
    if (!aplicComissaoPadraoProd) {
      setPercComissaoPadraoProd(0);
    }
  };

  useEffect(onChangeAplicComissaoPadraoProd, [aplicComissaoPadraoProd]);

  const onChangeAplicComissaoPadraoServ = () => {
    if (!aplicComissaoPadraoServ) {
      setPercComissaoPadraoServ(0);
    }
  };

  useEffect(onChangeAplicComissaoPadraoServ, [aplicComissaoPadraoServ]);

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleRegraComissaoOs}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={CadastroRegrasComissaoOsService.urlBase}
      number="0135_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <AsyncComboBox
          md={8}
          label="Técnico"
          defaultValue={idTecnico}
          concatModelName="tecnico"
          onChange={(s) => setIdTecnico(s?.value)}
          inputName={"async-id-tecnico"}
        />
      </Row>
      <Row>
        <FormCheckbox
          label="Aplicar Percentual de Comissão padrão para Produtos"
          checked={aplicComissaoPadraoProd}
          onChange={() => setAplicComissaoPadraoProd(!aplicComissaoPadraoProd)}
        />
        <NumberInput
          md={3}
          label="% Produtos"
          value={percComissaoPadraoProd}
          onChange={(v) => setPercComissaoPadraoProd(v)}
          isPercentage
          disabled={!aplicComissaoPadraoProd}
          name={"input-percent-comissao-prod"}
        />
      </Row>
      <Row>
        <FormCheckbox
          label="Aplicar Percentual de Comissão padrão para Serviços"
          checked={aplicComissaoPadraoServ}
          onChange={() => setAplicComissaoPadraoServ(!aplicComissaoPadraoServ)}
        />
        <NumberInput
          md={3}
          label="% Serviços"
          value={percComissaoPadraoServ}
          onChange={(v) => setPercComissaoPadraoServ(v)}
          isPercentage
          disabled={!aplicComissaoPadraoServ}
          name={"input-percent-comissao-serv"}
        />
      </Row>
    </ModalCadastroV2>
  );
};
