import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  AsyncComboBox,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FormButton,
  IntegerFormInput,
  NumberInput,
  PageContainer,
  RadioGroup,
  TextInput,
} from "../../../../../components";
import CadastroMetaVendaService from "../../../../../services/cadastro/CadastroMetaVendaService";
import {
  formatDateISO,
  meses,
  roundFloat,
  useStateWithRef,
} from "../../../../../coreUtils";
import { Divider } from "../../../../../components/Divider";
import { Card, Row } from "reactstrap";
import { cadastroMetaVendaRoute } from "../../../../../routes/modules/cadastro";
import { DetalhesMetaBaseadoVendaGrid } from "./components/DetalhesMetaBaseadoVendaGrid";
import { RadioItem } from "../../../../../components/RadioGroup";
import { HistProdutosInativosGrid } from "./components/HistProdutosInativosGrid";
import { showWarning } from "../../../../../components/AlertaModal";

export const IncluirMetaBaseadoVendas = () => {
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();
  const [nome, setNome] = useState("");
  const [metaPor, setMetaPor] = useState("ROTA");
  const [idMetaPor, setIdMetaPor] = useState(null);
  const [mes, setMes] = useState(new Date().getMonth() + 1);
  const [ano, setAno] = useState(new Date().getFullYear());
  const [pista, setPista] = useState("");
  const [dados, setDados, dadosRef] = useStateWithRef([]);
  const [produtosInativos, setProdutosInativos] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingBuscarValores, setLoadingBuscarValores] = useState(false);
  const [somaVlrVendaApuracao, setSomaVlrVendaApuracao] = useState(0);
  const [somaVlrMeta, setSomaVlrMeta] = useState(0);

  const [mesBuscarVendas, setMesBuscarVendas] = useState(
    new Date().getMonth() + 1
  );
  const [anoBuscarVendas, setAnoBuscarVendas] = useState(
    new Date().getFullYear() - 1
  );
  const [percMaxDevolTrocaPeriodo, setPercMaxDevolTrocaPeriodo] = useState(0);

  const handlePista = (v) => setPista(v);

  const handleAddMov = async (data) => {
    const payload = {
      ...data,
      qtd_venda_periodo_base: 0,
      vlr_faturado_periodo_base: 0,
      perc_diferenca: 0,
      meta_perc_max_devol_troca: percMaxDevolTrocaPeriodo,
      id: Math.max(...dados.map((e) => e.id)) + 1,
    };

    setDados([...dadosRef.current, payload]);
  };

  const handleDelete = (id) => {
    setDados(dadosRef.current.filter((e) => e.id !== id));
  };

  const validarDadosMeta = () => {
    if (!metaPor) {
      showWarning(
        "Por favor, selecione tipo de meta",
        null,
        null,
        "async-metapor"
      );
      return false;
    }

    if (!idMetaPor) {
      if (metaPor === "ROTA") {
        showWarning(
          "Por favor, selecione a rota da meta",
          null,
          null,
          "async-metapor"
        );
      }
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (["", null, undefined].includes(nome)) {
      showWarning(
        "Por favor, informe o Nome da Meta",
        null,
        null,
        "input-nome-meta-baseado-vendas"
      );
      return;
    }

    if (!validarDadosMeta()) return;

    setLoadingSubmit(true);

    const payload = {
      nome: nome,
      meta_por: metaPor,
      id_meta_por: idMetaPor,
      data_inicial: formatDateISO(new Date(ano, mes - 1, 1)),
      data_final: formatDateISO(new Date(ano, mes, 0)),
      tipo_inc: "BVD",
      movs: dados.map((e) => ({
        tipo: e.desc_tipo === "Produto" ? "PRODUTO" : "GRUPO_PROD", // Fixo por ora
        id_tipo: e.id_tipo,
        m_qtd_vda: e.meta_qtd_venda,
        m_vlr_faturado: e.meta_vlr_faturado,
        m_perc_max_dev_troca: e.meta_perc_max_devol_troca,
        perc_info_base_venda: e.perc_diferenca,
      })),
    };

    const [ok] = await CadastroMetaVendaService.incluir(payload);

    if (ok) {
      setRedirect(true);
    }

    setLoadingSubmit(false);
  };

  const handleEditDetalhe = async (coluna, novoValor, row) => {
    const valorAnterior = parseFloat(row[coluna]);
    if (coluna === "meta_qtd_venda") {
      row.meta_qtd_venda = parseInt(novoValor);
      row.preco_tipo = parseFloat(row.preco_tipo);
      row.meta_vlr_faturado = roundFloat(
        row.meta_qtd_venda * row.preco_tipo,
        2
      );
    }
    if (coluna === "perc_diferenca") {
      row = calcularQtdPorPercAumento(row, parseFloat(novoValor));
    }

    if (parseFloat(valorAnterior) !== parseFloat(novoValor)) {
      setDados(
        dados.map((e) => {
          if (e.id === row.id) {
            return { ...row };
          } else {
            return e;
          }
        })
      );
    }
  };

  const calcularQtdPorPercAumento = (row, percAumentar) => {
    const qtdVendaPeriodoBase = parseFloat(row.qtd_venda_periodo_base);

    const novaMetaQtdVenda =
      qtdVendaPeriodoBase + qtdVendaPeriodoBase * (percAumentar / 100);

    const percDiferenca =
      qtdVendaPeriodoBase > 0
        ? roundFloat(
            ((novaMetaQtdVenda - qtdVendaPeriodoBase) / qtdVendaPeriodoBase) *
              100,
            2
          )
        : 0;

    row.meta_qtd_venda = Math.round(novaMetaQtdVenda);
    row.preco_tipo = parseFloat(row.preco_tipo);

    row.meta_vlr_faturado = roundFloat(row.meta_qtd_venda * row.preco_tipo, 2);

    row.perc_diferenca = percDiferenca;

    return row;
  };

  const aumentarPorPerc = async (percAumentar) => {
    if (percAumentar > 0) {
      setDados(
        dados.map((row) => calcularQtdPorPercAumento(row, percAumentar))
      );
    }
  };

  const handleSetMetaPercMaxDevolTroca = (v) => {
    setPercMaxDevolTrocaPeriodo(v);
    setDados(dados.map((row) => ({ ...row, meta_perc_max_devol_troca: v })));
  };

  const buscarValoresPeriodo = async () => {
    setLoadingBuscarValores(true);
    try {
      if (!validarDadosMeta()) return;

      if (!mesBuscarVendas) {
        showWarning(
          "Por favor, selecione o mês para apuração dos valores",
          null,
          null,
          "combo-mes-buscar-vendas"
        );
      }

      if (!anoBuscarVendas) {
        showWarning(
          "Por favor, selecione o ano para apuração dos valores",
          null,
          null,
          "input-ano-buscar-vendas"
        );
      }

      const [ok, ret] = await CadastroMetaVendaService.buscarValoresPeriodo({
        mes: mesBuscarVendas,
        ano: anoBuscarVendas,
        tipo: metaPor,
        id_tipo: idMetaPor,
      });
      if (ok) {
        setDados(
          ret.produtos.map((e, i) => ({
            desc_tipo: "Produto",
            id: i,
            id_tipo: e.id_produto,
            meta_perc_max_devol_troca: percMaxDevolTrocaPeriodo,
            qtd_venda_periodo_base: parseFloat(e.meta_qtd_venda),
            vlr_faturado_periodo_base: parseFloat(e.meta_vlr_faturado),
            meta_qtd_venda: parseFloat(e.meta_qtd_venda),
            meta_vlr_faturado: parseFloat(e.meta_vlr_faturado),
            perc_diferenca: 0,
            nome_tipo: e.nome,
            preco_tipo: parseFloat(e.preco_venda),
          }))
        );
        setProdutosInativos(
          ret.produtos_inativos.map((e, i) => ({
            ...e,
            desc_tipo: "Produto",
            id_tipo: e.id_produto,
            nome_tipo: e.nome,
          }))
        );
      }
    } finally {
      setLoadingBuscarValores(false);
    }
  };

  useEffect(() => {
    let somaVlrVdaApuraTmp = 0;
    let somaVlrVdaMetaTmp = 0;
    dados.forEach((e) => {
      somaVlrVdaApuraTmp += parseFloat(e.vlr_faturado_periodo_base);
      somaVlrVdaMetaTmp += parseFloat(e.meta_vlr_faturado);
    });

    setSomaVlrVendaApuracao(somaVlrVdaApuraTmp);
    setSomaVlrMeta(somaVlrVdaMetaTmp);
  }, [dados]);

  const onKeyDown = (e) => {
    if (e.key === "F9") {
      handleSubmit();
    }
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: cadastroMetaVendaRoute.path,
          state: { refresh: true },
        }}
      />
    );
  }

  return (
    <PageContainer
      title="Incluir Meta Baseada em Vendas"
      number="0056_5"
      onKeyDown={onKeyDown}
      canGoBack
    >
      <Card body>
        <Row className="mb-3">
          <TextInput
            label="Nome"
            value={nome}
            onChange={(e, v) => setNome(v)}
            maxLength={100}
            md={4}
            name={"input-nome-meta-baseado-vendas"}
          />
          <RadioGroup
            label="Meta Por"
            value={metaPor}
            onChange={setMetaPor}
            divClassName="pr-0"
          >
            <RadioItem label="Rota" value={"ROTA"} />
          </RadioGroup>
          <AsyncComboBox
            className="pl-0"
            isConcatField
            concatModelName="rota_mob"
            isSearchable
            md={3}
            label="Rota"
            onChange={(s) => setIdMetaPor(s?.value)}
            defaultValue={idMetaPor}
            defaultOptions
            inputName={"async-metapor"}
          />
          <ComboBox
            md={2}
            label="Vigência - Mês"
            options={meses}
            defaultValue={mes}
            onChange={(s) => setMes(s?.value)}
            isClearable={false}
            isSearchable={false}
          />
          <IntegerFormInput
            md={1}
            label="Ano"
            defaultValue={ano}
            onChange={setAno}
          />
        </Row>
        <Divider>Período de Apuração de Vendas</Divider>
        <Row>
          <ComboBox
            md={2}
            label="Mês"
            options={meses}
            defaultValue={mesBuscarVendas}
            onChange={(s) => setMesBuscarVendas(s?.value)}
            isClearable={false}
            isSearchable={false}
            inputName={"combo-mes-buscar-venda"}
          />
          <IntegerFormInput
            md={2}
            label="Ano"
            defaultValue={anoBuscarVendas}
            onChange={setAnoBuscarVendas}
            name={"input-ano-buscar-vendas"}
          />
          <NumberInput
            md={2}
            label="% Máx. Devol. Troca"
            value={percMaxDevolTrocaPeriodo}
            onChange={handleSetMetaPercMaxDevolTroca}
          />
          <FormButton
            md="auto"
            loading={loadingBuscarValores}
            onClick={buscarValoresPeriodo}
          >
            Buscar valores de venda
          </FormButton>
        </Row>
        <Divider>Produtos da Meta</Divider>
        <DetalhesMetaBaseadoVendaGrid
          dados={
            !["", null, undefined].includes(pista?.trim())
              ? dados.filter((e) => e.nome_tipo.includes(pista))
              : dados
          }
          handleDelete={handleDelete}
          handleEditDetalhe={handleEditDetalhe}
          pista={pista}
          handlePista={handlePista}
          handleAumentarValorPorPerc={aumentarPorPerc}
          handleAddMov={handleAddMov}
        />
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Vlr. Venda na Apuração"
          value={somaVlrVendaApuracao}
          checkFloat={true}
          className="col-md-2 ml-auto"
        />
        <CardTotaisItem
          label="Meta de Valor de Vendas"
          value={somaVlrMeta}
          className="col-md-2"
        />
      </CardTotais>
      <Card body>
        <Divider>Histórico de Produtos Inativos</Divider>
        <HistProdutosInativosGrid dados={produtosInativos} />
        <Row>
          <FormButton
            divClassName="ml-auto"
            md="auto"
            color="success"
            onClick={handleSubmit}
            loading={loadingSubmit}
            padded={false}
          >
            F9 - Confirmar
          </FormButton>
          <FormButton
            md="auto"
            color="danger"
            onClick={() => history.goBack()}
            padded={false}
          >
            Esc - Sair
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
