import React, { useState } from "react";
import { Card, Col, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  FixedField,
  FormButton,
  IntegerFormInput,
  ModalBase,
  TextInput,
} from "../../../../../../components";
import GerencBancService from "../../../../../../services/financeiro/GerencBancService";
import { formatDateISO } from "../../../../../../coreUtils";
import { CompensacaoRecebGrid } from "./components/CompensacaoRecebGrid";

export const CompensacaoReceb = (notifyEvent) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(false);
  const [listSelected, setListSelected] = useState([]);

  const [dataIni, setDataIni] = useState(
    new Date(new Date().setDate(new Date().getDate()))
  );
  const [dataFim, setDataFim] = useState(
    new Date(new Date().setDate(new Date().getDate()))
  );

  const [dados, setDados] = useState([]);
  const [idConta, setIdConta] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [dataMov, setDataMov] = useState(new Date());
  const [nroDoc, setNroDoc] = useState("");
  const [nroOrigem, setNroOrigem] = useState(null);

  const limparDados = () => {
    setIdConta(null);
    setCliente(null);
    setDataMov(new Date());
    setNroDoc("");
    setNroOrigem(null);
  };

  const handleDateInput = (dIni, dFim) => {
    setDataIni(dIni);
    setDataFim(dFim);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const buscarDados = async () => {
    setLoading(true);
    try {
      const params = {
        id_conta_banc: idConta,
        data_ini: dataIni,
        data_fim: dataFim,
        cliente: cliente,
        id_origem: nroOrigem,
      };
      const [ok, ret] = await GerencBancService.listar(params);
      if (ok) {
        setSelected([]);
        setDados(ret);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormButton md="auto" color="success" onClick={toggle} padded={false}>
        Realizar Compensação
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Conferência de Recebimentos em Conta Bancária"
        number="0132_4"
        onClosed={limparDados}
      >
        <Row>
          <AsyncComboBox
            md={5}
            label="Cliente"
            concatModelName="cliente"
            onChange={(v) => setCliente(v)}
            value={cliente}
          />
          <AsyncComboBox
            md={5}
            label="Conta"
            isConcatField
            concatModelName="conta_banc"
            defaultValue={idConta}
            onChange={(s) => setIdConta(s?.value)}
          />
          <IntegerFormInput
            md={2}
            label="N° Origem"
            value={nroOrigem}
            onChange={setNroOrigem}
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas onChange={handleDateInput} />
          <BotaoPesquisar onClick={buscarDados} loading={loading} />
          <FormButton disabled={listSelected.length > 0 ? false : true}>
            Compensar
          </FormButton>
        </Row>
        <Card body>
          <CompensacaoRecebGrid
            dados={dados}
            setSelected={setSelected}
            selected={listSelected}
          />
        </Card>
        <Row>
            <FixedField label={"Total:"} divClassName={'pt-3'}/>
            <FixedField label={"Total Selecionado:"} divClassName={'pt-3'} />
        </Row>
      </ModalBase>
    </>
  );
};
