import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroPerfilModal } from "./components/CadastroPerfilModal";
import { PerfilGrid } from "./components/PerfilGrid";
import {
  MODAL_ACTIONS,
  defaultDebounceTime,
  useDebounce,
} from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";
import {
  AsyncComboBox,
  BotaoPesquisar,
  PageContainer,
  SearchInput,
} from "../../../../components";
import { BotaoIncluir } from "../../../../components/cadastro";

export const modalTitlePerfil = "Perfil";
export const routesBasePerfil = "/cadastro/perfil";

export const Perfil = () => {
  const [pista, setPista] = useState("");
  const [idColaborador, setIdColaborador] = useState(null);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);

  const buscarDados = async (p) => {
    const [ok, ret] = await apiGetV2(`${routesBasePerfil}/listar/`, {
      pista: p,
      id_colaborador: idColaborador,
    });
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = useDebounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Perfis" number="0084" canGoBack>
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <AsyncComboBox
            md={3}
            label="Colaborador"
            isConcatField
            concatModelName="colaborador"
            isSearchable
            isClearable
            onChange={(s) => setIdColaborador(s?.value)}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroPerfilModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <Card body>
        <PerfilGrid data={dados} setSelected={setSelected} alterar={alterar} />
      </Card>
    </PageContainer>
  );
};
