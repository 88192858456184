import { apiGetV2 } from "../../apiV2";

const URL_BASE = "/cadastro/representacao";

const CadastroRepresentacaoComercialService = {
  urlBase: URL_BASE,
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
};

export default CadastroRepresentacaoComercialService;
