import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroTomadorModal } from "./components/CadastroTomadorModal";
import { TomadorGrid } from "./components/TomadorGrid";
import {
  MODAL_ACTIONS,
  defaultDebounceTime,
  useDebounce,
} from "../../../../../coreUtils";
import {
  BotaoPesquisar,
  FormCheckbox,
  PageContainer,
  SearchInput,
} from "../../../../../components";
import { apiGetV2 } from "../../../../../apiV2";
import {
  BotaoIncluir,
  ModalExcluirV2,
} from "../../../../../components/cadastro";

export const modalTitleTomadorMDFe = "Tomador";
export const routesBaseTomadorMDFe = "/mdfe/cadastro/tomador";

export const Tomador = () => {
  const [selected, setSelected] = useState(null);
  const [pista, setPista] = useState("");
  const [somenteAtivos, setSomenteAtivos] = useState(true);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [cadastroOpen, setCadastroOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const buscarDados = async (p) => {
    const params = {
      pista: p,
      somente_ativos: somenteAtivos,
    };

    const [ok, ret] = await apiGetV2(
      `${routesBaseTomadorMDFe}/listar/`,
      params
    );
    setDados(ok ? ret : []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = useDebounce(async (p) => {
    setPista(p);
    setLoadingPista(true);
    await buscarDados(p);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) {
      setSelected(null);
    }
  };

  const toggleCadastro = (action) => {
    setAction(action);
    setCadastroOpen(!cadastroOpen);
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleCadastro(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Cadastro de Tomador" number="0042_8" canGoBack>
      <Card body>
        <Row className="mb-2">
          <SearchInput
            md={5}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Nome"
          />
          <FormCheckbox
            label="Somente Ativos"
            name="somente_ativos"
            onChange={() => setSomenteAtivos(!somenteAtivos)}
            checked={somenteAtivos}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggleCadastro} />
          <CadastroTomadorModal
            isOpen={cadastroOpen}
            toggle={toggleCadastro}
            action={action}
            selected={selected}
            notifyEvent={notifyEvent}
          />
          <ModalExcluirV2
            title={modalTitleTomadorMDFe}
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0044_2"
            selected={selected}
            routeBase={routesBaseTomadorMDFe}
            notifyEvent={notifyEvent}
          />
        </Row>
        <TomadorGrid
          data={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
