import React, { useState } from "react";
import {
  AsyncComboBox,
  FixedField,
  ModalBase,
} from "../../../../../../components";
import { Row } from "reactstrap";
import { showInfo } from "../../../../../../components/AlertaModal";

const TrocarVeiculoModal = ({
  isOpen,
  toggle,
  registroTrocaVeic,
  atualizarVeiculo,
}) => {
  const [novoVeiculo, setNovoVeiculo] = useState(null);
  const [montadoraNovoVeiculo, setMontadoraNovoVeiculo] = useState(null);

  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setNovoVeiculo(null);
    setMontadoraNovoVeiculo(null);
  };

  const handleSelectVeiculo = (s) => {
    setNovoVeiculo(s?.value);
    setMontadoraNovoVeiculo(s?.nome_montadora ?? "");
  };

  const onConfirm = async () => {
    if ([0, null, undefined].includes(novoVeiculo)) {
      showInfo(
        "Por favor, selecione o veículo e tente novamente",
        "",
        [],
        "async-veic"
      );
      return;
    }

    setLoading(true);
    try {
      registroTrocaVeic.id_veiculo = novoVeiculo;
      if (await atualizarVeiculo(registroTrocaVeic)) {
        toggle();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      title={<>Trocar Veículo da Placa {registroTrocaVeic?.placa ?? ""}</>}
      size="md"
      onConfirm={onConfirm}
      onClosed={limparDados}
      loadingConfirm={loading}
    >
      <Row className="mb-2">
        <FixedField
          label="Veículo Atual"
          value={registroTrocaVeic?.nome_veiculo ?? ""}
          horizontal
        />
        <FixedField
          label="Montadora"
          value={registroTrocaVeic?.nome_montadora ?? ""}
          horizontal
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={null}
          label="Novo Veículo"
          onChange={(s) => handleSelectVeiculo(s)}
          isSearchable
          isClearable
          isConcatField
          concatModelName="veiculo"
          defaultValue={novoVeiculo}
          inputName={"async-veic"}
          defaultOptions
        />
        <FixedField
          label="Montadora"
          value={montadoraNovoVeiculo}
          horizontal
          divClassName="pt-4"
        />
      </Row>
    </ModalBase>
  );
};

export default TrocarVeiculoModal;
