import React, { useEffect, useState } from "react";
import {
  BotaoPesquisar,
  PageContainer,
  SearchInput,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { ClasseGrid } from "./components/ClasseGrid";
import { apiGetV2 } from "../../../../apiV2";
import {
  defaultDebounceTime,
  MODAL_ACTIONS,
  useDebounce,
} from "../../../../coreUtils";
import { CadastroClasseModal } from "./components/CadastroClasseModal";

export const routesBaseCadastroClasseProd = "/cadastro/classe_prod";

export const ClasseProduto = () => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState();
  const [pista, setPista] = useState();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalExcluirOpen, setIsModalExcluirOpen] = useState(false);
  const [action, setAction] = useState();

  const toggle = (action) => {
    setAction(action);
    setIsModalOpen(!isModalOpen);
  };

  const toggleExcluir = () => setIsModalExcluirOpen(!isModalExcluirOpen);

  const carregarDados = async () => {
    setLoading(true);
    try {
      const params = { pista: pista };

      const [ok, ret] = await apiGetV2(
        `${routesBaseCadastroClasseProd}/listar/`,
        params
      );
      if (ok) {
        setData(ret);
      }
    } finally {
      setLoading(false);
    }
  };

  const alterar = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggle(MODAL_ACTIONS.EDIT);
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  const handlePista = useDebounce((v) => {
    setPista(v);
  }, defaultDebounceTime);

  useEffect(() => {
    carregarDados();
  }, [pista]);

  return (
    <PageContainer
      title="Cadastro de Classe de Produtos"
      canGoBack
      number="0114"
    >
      <Card body>
        <Row>
          <SearchInput
            md={4}
            placeholder="Descrição"
            onChange={handlePista}
            loading={loading}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={toggle} />
          <ModalExcluirV2 />
        </Row>
      </Card>
      <CadastroClasseModal
        isOpen={isModalOpen}
        toggle={toggle}
        action={action}
        selected={selected}
        notifyEvent={carregarDados}
      />
      <ModalExcluirV2
        isOpen={isModalExcluirOpen}
        toggle={toggleExcluir}
        selected={selected}
        notifyEvent={carregarDados}
        routeBase={routesBaseCadastroClasseProd}
      />
      <Card body>
        <ClasseGrid
          data={data}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
