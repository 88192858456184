import React from "react";
import { formatValueFromAPI } from "../../../../../coreUtils";
import { Badge, UncontrolledTooltip } from "reactstrap";
import { Table } from "../../../../../components";

export const TableOcorrencias = ({ data }) => {
  const columns = [
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      headerAlign: "center",
      sort: false,
    },
    {
      dataField: "nosso_nro",
      text: "Nosso Número",
      align: "center",
      headerAlign: "center",
      sort: false,
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
      sort: false,
      formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    },
    {
      dataField: "data_vcto",
      text: "Vencimento",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "data_ocorrencia",
      text: "Data da Ocorrência",
      align: "center",
      headerAlign: "center",
      sort: true,
    },
    {
      dataField: "descricao",
      text: "Descrição da Ocorrência",
      align: "left",
      headerAlign: "left",
      sort: false,
      formatter: (c, row) => {
        const idBadge = `pagamento-${row.nosso_nro}`;
        return (
          <>
            {c}{" "}
            {row.titulo_rec === true && (
              <>
                <Badge
                  color="info"
                  className="ml-1"
                  style={{ transform: "translateY(-1px)", paddingTop: "0.5em" }}
                  id={idBadge}
                >
                  Título já recebido
                </Badge>
                <UncontrolledTooltip target={idBadge} placement="left">
                  Será gerado apenas um log identificando que foi detectado
                  pagamento duplicado para esse título.
                  <br />
                  Não haverá registro duplo de liquidação.
                </UncontrolledTooltip>
              </>
            )}
          </>
        );
      },
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      sort: true,
    },
  ];
  return (
    <Table
      keyField="tipo"
      data={data}
      columns={columns}
      striped
      growIntoPageSize
    />
  );
};
