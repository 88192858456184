import React, { useState } from "react";
import {
  LabelButton,
  ModalBase,
  NumberInput,
  TextInput,
} from "../../../../../components";
import moment from "moment";
import { Label, Row } from "reactstrap";
import { useEffect } from "react";
import CaixaLojaService from "../../../../../services/financeiro/CaixaLojaService";

const rowStyle = { marginBottom: "0.5rem", justifyContent: "center" };

export const FecharCaixaLojaModal = ({
  notifyEvent,
  saldoDinh = 0,
  mostraSaldoFechamento,
  idCaixa,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dataHoraFechamento, setDataHoraFechamento] = useState(new Date());
  const [saldoFechamento, setSaldoFechamento] = useState(0);
  const [valorQuebra, setValorQuebra] = useState(0);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    const payload = {
      saldo_fechamento: saldoFechamento,
      saldo_fechamento_sis: saldoDinh,
      valor_quebra: valorQuebra,
    };
    setLoading(true);
    const [ok] = await CaixaLojaService.fecharCaixa(payload);
    if (ok) {
      CaixaLojaService.imprimirFechamento(idCaixa);
      toggle();
      setTimeout(() => {
        notifyEvent();
      }, 350);
    }
    setLoading(false);
  };

  useEffect(() => {
    setValorQuebra(saldoFechamento - saldoDinh);
  }, [saldoDinh, saldoFechamento]);

  return (
    <>
      <LabelButton onClick={toggle}>Fechar Caixa</LabelButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        onBeforeOpen={() => setDataHoraFechamento(new Date())}
        onClosed={() => setSaldoFechamento(0)}
        size="sm"
        title="Fechar Caixa Loja"
        number="0063_3"
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        <Row style={rowStyle}>
          <TextInput
            md={6}
            label="Data/Hora do Fechamento"
            value={moment(dataHoraFechamento).format("DD/MM/YYYY - HH:mm")}
            disabled
          />
        </Row>
        {mostraSaldoFechamento && (
          <>
            <Label style={{ width: "100%", textAlign: "center" }}>
              Somatório do Dinheiro em Caixa pelo Sistema
            </Label>
            <Row style={rowStyle}>
              <NumberInput
                md={6}
                divStyle={{ justifyContent: "center" }}
                value={saldoDinh}
                disabled
              />
            </Row>
          </>
        )}
        <hr />
        <Label style={{ width: "100%", textAlign: "center" }}>
          Saldo Real em Dinheiro no Caixa
        </Label>
        <Row style={rowStyle}>
          <NumberInput
            md={6}
            divStyle={{ justifyContent: "center" }}
            value={saldoFechamento}
            onChange={setSaldoFechamento}
          />
        </Row>
        {mostraSaldoFechamento && (
          <>
            <hr />
            <Row style={rowStyle}>
              <NumberInput
                md={6}
                label="(+) Sobra no Valor em Dinheiro"
                value={Math.max(valorQuebra, 0)}
                disabled
              />
            </Row>
            <Row style={rowStyle}>
              <NumberInput
                md={6}
                label="(-) Falta no Valor em Dinheiro"
                value={Math.abs(Math.min(valorQuebra, 0))}
                disabled
              />
            </Row>
          </>
        )}
      </ModalBase>
    </>
  );
};
