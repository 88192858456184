import React, { useRef, useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  Table,
  TableDelete,
} from "../../../../../components";
import FormButton from "../../../../../components/FormButton";
import { ModalBase } from "../../../../../components/ModalBase";
import CadastroSupervisorService from "../../../../../services/cadastro/CadastroSupervisorService";

const tipos = [
  { label: "ROTA", value: "ROTA" },
  { label: "COLABORADOR", value: "COLAB" },
];

export const DetalhesSupervisor = ({ idCab, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [listaCliente, setListaCliente] = useState([]);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [alterouAlgo, setAlterouAlgo] = useState(false);
  const selectRef = useRef();

  const [tipo, setTipo] = useState("ROTA");
  const [idTipo, setIdTipo] = useState();

  const columns = [
    {
      dataField: "desc_tipo",
      text: "Tipo",
      align: "left",
      sortable: true,
    },
    {
      dataField: "id_tipo",
      text: "#",
      align: "center",
      sortable: true,
    },
    {
      dataField: "nome_tipo",
      text: "Nome",
      align: "left",
      sortable: true,
    },
    {
      dataField: "act",
      isDummyField: true,
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.id)} />
      ),
    },
  ];

  const limparDados = () => {
    setListaCliente([]);
  };

  const toggle = () => {
    if (!isOpen) {
      buscarDados();
    } else {
      if (alterouAlgo) {
        notifyEvent();
      }
    }
    setIsOpen(!isOpen);
    setAlterouAlgo(false);
    limparDados();
  };

  const buscarDados = async () => {
    const [ok, ret] = await CadastroSupervisorService.tipo.listar(idCab);
    if (ok) {
      setListaCliente(ret);
    }
  };

  const handleDelete = async (id) => {
    const [ok] = await CadastroSupervisorService.tipo.excluir(id);
    if (ok) {
      buscarDados();
    }
  };

  const handleSubmit = async () => {
    if (idCab !== null) {
      let payload = {
        id_gerenc_supervisor_cab: idCab,
        tipo: tipo,
        id_tipo: idTipo,
      };

      setLoadingAdd(true);
      const [ok] = await CadastroSupervisorService.tipo.incluir(payload);

      if (ok) {
        buscarDados();
        setAlterouAlgo(true);
        selectRef.current.clearValue();
      }
      setLoadingAdd(false);
    }
  };

  return (
    <React.Fragment>
      <FormButton
        md="auto"
        color="primary"
        disabled={[0, null, undefined].includes(idCab)}
        onClick={toggle}
      >
        Detalhar Responsabilidades
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title={"Vinculação"}
        number="0122_3"
      >
        <Row>
          <ComboBox
            md={4}
            label="Tipo"
            options={tipos}
            onChange={(s) => {
              setTipo(s?.value);
            }}
            defaultValue={"ROTA"}
            isSearchable={false}
            isClearable={false}
          />
        </Row>
        <Row className="mb-3">
          {tipo === "ROTA" && (
            <AsyncComboBox
              label="Rota"
              md={7}
              isConcatField
              concatModelName="rota_mob"
              isSearchable
              isClearable
              onChange={(v) => {
                setIdTipo(v?.value);
              }}
              autoFocus
              hideShortcut
              clearOnDisable
              ref={selectRef}
            />
          )}
          {tipo === "COLAB" && (
            <AsyncComboBox
              label="Colaborador"
              md={7}
              isConcatField
              concatModelName="colaborador"
              isSearchable
              isClearable
              onChange={(v) => {
                setIdTipo(v?.value);
              }}
              autoFocus
              hideShortcut
              clearOnDisable
              ref={selectRef}
            />
          )}
          <FormButton
            color="success"
            loading={loadingAdd}
            onClick={handleSubmit}
            disabled={idTipo ? false : true}
            disabledHint="Selecione um item no campo à esquerda"
          >
            Adicionar à lista
          </FormButton>
        </Row>
        <Table data={listaCliente} columns={columns} paginated={false} />
      </ModalBase>
    </React.Fragment>
  );
};
