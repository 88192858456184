import moment from "moment";
import { sumDataField, formatarValor } from "../../../../../../coreUtils";
import {
  formatDateDDMMAAAA,
  formatNumberField,
  formatRow,
  removeSpecialChars,
  verificaPessoa,
} from "../GerarRemessa";

const rem240HeaderSantander = (dadosConta) => [
  ["033", 1, 3], // Código do Banco na Compensação: "033"
  [0, 4, 7], // Lote de Serviço: "0000"
  [0, 8, 8], // Tipo de Registro: "0"
  ["", 9, 16], // Uso Exclusivo FEBRABAN / CNAB: Brancos
  [2, 17, 17], // Tipo de Inscrição da Empresa: '1'  =  CPF '2'  =  CGC / CNPJ
  [formatNumberField(dadosConta.cnpj_empresa), 18, 32], // Número de Inscrição da Empresa
  [dadosConta.cod_transmissao, 33, 47], // Código de Transmissão
  ["", 48, 72], // Uso do Banco: Brancos
  [removeSpecialChars(dadosConta.nome_empresa), 73, 102], // Nome da Empresa
  ["Banco Santander", 103, 132], // Nome do Banco: Banco Santander
  ["", 133, 142], // Uso Exclusivo FEBRABAN / CNAB: Brancos
  [1, 143, 143], // Código Remessa / Retorno: "1"
  [formatDateDDMMAAAA(new Date()), 144, 151], // Data de Geração do Arquivo
  ["", 152, 157], // Uso do Banco: Brancos
  [dadosConta.nro_remessa, 158, 163], // Número Seqüencial do Arquivo
  [40, 164, 166], // No da Versão do Layout do Arquivo: "040"
  ["", 167, 240], // Uso do Banco: Brancos
];

const rem240HeaderLoteSantander = (dadosConta) => [
  ["033", 1, 3], // Código do Banco na Compensação: "033"
  [1, 4, 7], // Número Sequencial do Lote
  [1, 8, 8], // Tipo de Registro: "1"
  ["R", 9, 9], // Tipo de Operação: "R" - Remessa
  [1, 10, 11], // Tipo de Serviço: "01" - Cobrança
  ["", 12, 13], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [30, 14, 16], // Nº da Versão do Layout do Lote: "030"
  ["", 17, 17], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [2, 18, 18], // Tipo de Inscrição da Empresa: '1'  =  CPF '2'  =  CGC / CNPJ
  [formatNumberField(dadosConta.cnpj_empresa), 19, 33], // Nº de Inscrição da Empresa
  ["", 34, 53], // Código do Convênio no Banco: Brancos
  [dadosConta.cod_transmissao, 54, 68], // Código de Transmissão
  ["", 69, 73], // Dígito Verificador da Ag/Conta: Brancos
  [removeSpecialChars(dadosConta.nome_empresa), 74, 103], // Nome da Empresa
  ["", 104, 143], // Mensagem 1. Brancos
  ["", 144, 183], // Mensagem 2. Brancos
  [0, 184, 191], // Número Remessa/Retorno
  [formatDateDDMMAAAA(new Date()), 192, 199], // Data de Gravação Remessa/Retorno
  ["", 200, 240], // Uso do Banco: Brancos
];

const rem240SegPSantander = (
  dadosConta,
  dup,
  seuNumero,
  vlrPercJuros,
  temDesconto,
  seq
) => [
  ["033", 1, 3], // Código do Banco na Compensação: "033"
  [1, 4, 7], // Número Sequencial do Lote
  [3, 8, 8], // Tipo de Registro: "3"
  [seq, 9, 13], // Nº Sequencial do Registro no Lote
  ["P", 14, 14], // Cód. Segmento do Registro Detalhe: "P"
  ["", 15, 15], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [1, 16, 17], // Código de Movimento Remessa: 01 - Entrada de boleto, 02 - Pedido de baixa, 04 - Concessão de abatimento, 05 - Cancelamento de abatimento, 06 - Alteração de vencimento, 07 - Alteração da identificação do boleto na empresa (Controle Participante), 08 - Alteração seu número, 09 - Pedido de Protesto, 18 - Pedido de Sustação de Protesto, 10 - Concessão de Desconto, 11 - Cancelamento de desconto, 12 - Transferência de Titularidade automática, 15 - Transferência da carteira Simples para a carteira Cessão, 16 - Baixa de Cessão por Descaracterização***, 17 - Baixa de Cessão por Pagamento***, 31 - Alteração de outros dados*, 47 - Alteração do Valor Nominal do boleto, 48 - Alteração do Valor Mínimo ou Percentual Mínimo, 49 - Alteração do Valor Máximo ou Percentual Máximo, 98 - Não Protestar (Antes de iniciar o ciclo de protesto)
  [removeSpecialChars(dadosConta.agencia).padStart(5, "0"), 18, 22], // Agência
  [removeSpecialChars(dadosConta.nro_conta).padStart(10, "0"), 23, 32], // Conta Corrente
  [removeSpecialChars(dadosConta.nro_conta).padStart(10, "0"), 33, 42], // Conta cobrança Destinatária FIDC
  ["", 43, 44], // Uso do Banco: Brancos
  [dup.nosso_numero.padStart(13, "0"), 45, 57], // Nosso Número
  [parseInt(dadosConta.tipo_carteira), 58, 58], // Código da Carteira
  [1, 59, 59], // Forma de Cadastr. do Título no Banco:  1 - Cobrança Registrada (Rápida e Eletrônica com Registro), 2 - Cobrança sem Registro, 3 - Cobrança Simples (Sem Registro sem pré-impresso e com pré-impresso),
  [1, 60, 60], // Tipo de Documento: 1- Tradicional, 2- Escritural
  ["", 61, 61], // Uso do Banco: Brancos
  ["", 62, 62], // Uso do Banco: Brancos
  [seuNumero, 63, 77], // Número do Documento de Cobrança
  [formatDateDDMMAAAA(dup.vencimento), 78, 85], // Data de Vencimento do Título
  [formatNumberField(dup.vlr_titulo), 86, 100], // Valor Nominal do Título
  [0, 101, 104], // Agência encarregada da cobrança FIDC
  [0, 105, 105], // Dígito da Agência do Beneficiário FIDC
  ["", 106, 106], // Uso do Banco: Brancos
  [dadosConta.especie_tit, 107, 108], // Espécie do Título:  01 - Duplicata Mercantil, 02 - Nota Promissória, 03 - Duplicata de Prestação de Serviços, 04 - Cheque, 05 - Nota de Seguro, 06 - Letra de Câmbio, 07 - Duplicata Mercantil p/ Indicação, 08 - Nota de Crédito Comercial, 09 - Outros, 10 - Nota de Crédito Industrial, 11 - Nota de Crédito Rural, 12 - Duplicata de Serviço, 13 - Nota Promissória Rural, 14 - Triplicata Mercantil, 15 - Triplicata de Serviço, 16 - Duplicata Rural, 17 - Recibo, 18 - Fatura, 19 - Nota de Débito, 20 - Apólice de Seguro, 21 - Mensalidade Escolar, 22 - Parcela de Consórcio, 23 - Nota Fiscal, 24 - Documento de Dívida, 25 - Cédula de Produto Rural, 26 - Nota de Crédito à Exportação, 30 - Encargos Condominiais, 31 - Cartão de Crédito, 32 - Boleto Proposta
  ["N", 109, 109], // Identific. de Título Aceito/Não Aceito: Código adotado pela FEBRABAN para identificar se o título de cobrança foi aceito (reconhecimento da dívida pelo Pagador). 'A'  =  Aceite 'N'  =  Não Aceite
  [formatDateDDMMAAAA(dup.emissao), 110, 117], // Data da Emissão do Título
  [dup.tipo_juros_titulo === "V" ? 5 : 6, 118, 118], // Código do Juros de Mora:  1 - Valor por dia - Informar no campo o valor/dia a mora a ser cobrada, 2 - Taxa Mensal - Informar no campo taxa mensal o percentual a ser aplicado sobre valor do boleto que será calculado por dia de atraso, 3 - Isento, 4 - Utilizar comissão permanência do Banco por dia de atraso, 5 - Tolerância valor por dia (cobrar juros a partir de), 6 - Tolerância taxa mensal (cobrar juros a partir de)
  [formatDateDDMMAAAA(dup.data_juros_titulo), 119, 126], // Data indicativa do inicio da cobrança do juros de mora do título. A data informada deverá ser maior que a data de vencimento do título de cobrança. Caso seja inválida, igual a data de vencimento ou não informada será considerada a data do vencimento acrescida de um dia. Utilizar o formato (DD/MM/AAAA), onde: 'DD = Ano' 'MM = Mês'  'AAAA = Ano'.
  [vlrPercJuros, 127, 141], // Juros de Mora por Dia/Taxa ao Mês
  [temDesconto ? 1 : 0, 142, 142], // Código do Desconto 1:  0 = Isento, 1 = Valor fixo até a data informada, 2 = Percentual até a data informada, 3 = Valor por antecipação por dia corrido, 4 = Valor por antecipação dia útil
  [temDesconto ? formatDateDDMMAAAA(dup.data_desconto) : 0, 143, 150], // Data do Desconto 1
  [formatNumberField(dup.vlr_desconto), 151, 165], // Valor/Percentual a ser Concedido
  [0, 166, 180], // Valor do IOF a ser Recolhido
  [0, 181, 195], // Valor do Abatimento
  [dup.numero, 196, 220], // Identificação do Título na Empresa: Campo destinado para uso do Beneficiário para identificação do Título.
  [dadosConta.protestar ? 1 : 0, 221, 221], // Código de Protesto:  0 - Não Protestar, 1 - Protestar Dias Corridos , 2 - Protestar Dias Úteis, 3 - Utilizar Perfil Beneficiário, 9 - Cancelamento de Protesto Automático
  [dadosConta.dias_protesto, 222, 223], // Número de dias para protesto
  [2, 224, 224], // Código para Baixa/Devolução:  1 - Baixar/Devolver, 2 - Não Baixar/Não Devolver, 3 - Utilizar Perfil Beneficiário
  [0, 225, 227], // Número de Dias para Baixa/Devolução: Brancos
  [0, 228, 229], // Código da Moeda: '09'  = Real
  ["", 230, 240], // Uso do Banco: Brancos
];

const rem240SegQSantander = (dup, enderCliente, seq) => [
  ["033", 1, 3], // Código do Banco na Compensação: "033"
  [1, 4, 7], // Número Sequencial do Lote
  [3, 8, 8], // Tipo de Registro: "3"
  [seq, 9, 13], // Nº Sequencial do Registro no Lote
  ["Q", 14, 14], // Cód. Segmento do Registro Detalhe: "Q"
  ["", 15, 15], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [1, 16, 17], // Código de Movimento Remessa: 01 - Entrada de boleto, 02 - Pedido de baixa, 04 - Concessão de abatimento, 05 - Cancelamento de abatimento, 06 - Alteração de vencimento, 07 - Alteração da identificação do boleto na empresa (Controle Participante), 08 - Alteração seu número, 09 - Pedido de Protesto, 18 - Pedido de Sustação de Protesto, 10 - Concessão de Desconto, 11 - Cancelamento de desconto, 12 - Transferência de Titularidade automática, 15 - Transferência da carteira Simples para a carteira Cessão, 16 - Baixa de Cessão por Descaracterização***, 17 - Baixa de Cessão por Pagamento***, 31 - Alteração de outros dados*, 47 - Alteração do Valor Nominal do boleto, 48 - Alteração do Valor Mínimo ou Percentual Mínimo, 49 - Alteração do Valor Máximo ou Percentual Máximo, 98 - Não Protestar (Antes de iniciar o ciclo de protesto)
  [verificaPessoa(dup.cpf_cnpj_cliente), 18, 18], // Pagador - Tipo de Inscrição: '1'  =  CPF '2'  =  CGC / CNPJ
  [formatNumberField(dup.cpf_cnpj_cliente), 19, 33], // Pagador - Número de Inscrição
  [removeSpecialChars(dup.nome_cliente), 34, 73], // Pagador - Nome
  [removeSpecialChars(enderCliente), 74, 113], // Pagador - Endereço
  [removeSpecialChars(dup.bairro_cliente), 114, 128], // Pagador - Bairro
  [formatNumberField(dup.cep_cliente), 129, 136], // Pagador - CEP
  [removeSpecialChars(dup.cidade_cliente), 137, 151], // Pagador - Cidade
  [dup.uf_cliente, 152, 153], // Pagador - UF
  [0, 154, 154], // Sacador/Avalist - Tipo de Inscrição Sacador Avalista: '0' = Isento / Não Informado '1'  =  CPF '2'  =  CGC / CNPJ
  [0, 155, 169], // Sacador/Avalist - Número de Inscrição
  ["", 170, 209], // Sacador/Avalist - Nome do Sacador/Avalista
  [0, 210, 221], // Uso do Banco 0
  ["", 222, 240], // Uso Exclusivo FEBRABAN/CNAB
];

const rem240SegRSantander = (dup, seq) => [
  ["033", 1, 3], // Código do Banco na Compensação: "033"
  [1, 4, 7], // Número Sequencial do Lote
  [3, 8, 8], // Tipo de Registro: "3"
  [seq, 9, 13], // Nº Sequencial do Registro no Lote
  ["R", 14, 14], // Cód. Segmento do Registro Detalhe: "Q"
  ["", 15, 15], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [1, 16, 17], // Código de Movimento Remessa: 01 - Entrada de boleto, 02 - Pedido de baixa, 04 - Concessão de abatimento, 05 - Cancelamento de abatimento, 06 - Alteração de vencimento, 07 - Alteração da identificação do boleto na empresa (Controle Participante), 08 - Alteração seu número, 09 - Pedido de Protesto, 18 - Pedido de Sustação de Protesto, 10 - Concessão de Desconto, 11 - Cancelamento de desconto, 12 - Transferência de Titularidade automática, 15 - Transferência da carteira Simples para a carteira Cessão, 16 - Baixa de Cessão por Descaracterização***, 17 - Baixa de Cessão por Pagamento***, 31 - Alteração de outros dados*, 47 - Alteração do Valor Nominal do boleto, 48 - Alteração do Valor Mínimo ou Percentual Mínimo, 49 - Alteração do Valor Máximo ou Percentual Máximo, 98 - Não Protestar (Antes de iniciar o ciclo de protesto)
  [0, 18, 18], // Código do Desconto 2
  [0, 19, 26], // Data do Desconto 2
  [0, 27, 41], // Valor do Desconto 2
  [0, 42, 42], // Código do Desconto 3
  [0, 43, 50], // Data do Desconto 3
  [0, 51, 65], // Valor do Desconto 3
  ["1", 66, 66], // Código da Multa  - Valor
  [formatDateDDMMAAAA(dup.data_multa_titulo), 67, 74], // Data da Multa
  [formatNumberField(dup.vlr_multa_titulo), 75, 89], // Valor/Perecentual da Multa
  ["", 90, 99], // Uso Exclusivo FEBRABAN/CNAB
  ["", 100, 139], // Mensagem 3
  ["", 140, 179], // Mensagem 4
  ["", 180, 240], // Uso Exclusivo FEBRABAN/CNAB
];

const rem240TrailerLoteSantander = (
  dadosConta,
  registrosLote,
  qtdTitulos,
  totalTitulos
) => [
  ["033", 1, 3], // Código do Banco na Compensação: "033"
  [1, 4, 7], // Número Sequencial do Lote
  [5, 8, 8], // Tipo de Registro: "5"
  ["", 9, 17], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [registrosLote, 18, 23], // Quantidade de Registros no Lote
  ["", 24, 29], // Cobrança Simples - Quantidade de Títulos em Cobrança
  ["", 30, 46], // Cobrança Simples - Valor Total dos Títulos em Carteiras
  ["", 47, 52], // Cobrança Vinculada - Quantidade de Títulos em Cobrança
  ["", 53, 69], // Cobrança Vinculada - Valor Total dos Títulos em Carteiras
  ["", 70, 75], // Cobrança Caucionada - Quantidade de Títulos em Cobrança
  ["", 76, 92], // Cobrança Caucionada - Quantidade de Títulos em Carteiras
  ["", 93, 98], // Cobrança Descontada - Quantidade de Títulos em Cobrança
  ["", 99, 115], // Cobrança Descontada - Valor Total dosTítulos em Carteiras
  ["", 116, 123], // Número do Aviso de Lançamento: Brancos
  ["", 124, 240], // Uso Exclusivo FEBRABAN/CNAB: Brancos
];

const rem240TrailerSantander = (totalRegistros) => [
  ["033", 1, 3], // Código do Banco na Compensação: "033"
  [9999, 4, 7], // Preencher com '9999'.
  [9, 8, 8], // Tipo de Registro: "9"
  ["", 9, 17], // Uso Exclusivo FEBRABAN/CNAB: Brancos
  [1, 18, 23], // Quantidade de Lotes do Arquivo
  [totalRegistros, 24, 29], // Quantidade de Registros do Arquivo
  ["", 30, 35], // Qtde de Contas p/ Conc. (Lotes): "000000"
  ["", 36, 240], // Uso Exclusivo FEBRABAN/CNAB: Brancos
];

export const nomeArq240Santander = (dadosConta) =>
  "E00" + moment(new Date()).format("DD") + dadosConta.nro_remessa + ".240";

export const layout240Santander = (dadosConta, duplicatas) => {
  const header = "".concat(
    formatRow(...rem240HeaderSantander(dadosConta)),
    formatRow(...rem240HeaderLoteSantander(dadosConta))
  );

  const detalhes = "".concat(
    ...duplicatas.map((dup, index) => {
      const seuNumero = `${dup.numero}${dup.parcela}`;

      const enderCliente =
        dup.endereco_cliente + " " + dup.nro_endereco_cliente;

      const vlrPercJuros = formatNumberField(
        dup.tipo_juros_titulo === "V"
          ? dup.vlr_juros_titulo
          : formatarValor(dup.perc_juros_titulo, 5)
      );

      return (
        formatRow(
          ...rem240SegPSantander(
            dadosConta,
            dup,
            seuNumero,
            vlrPercJuros,
            parseFloat(dup.vlr_desconto) > 0,
            index * 2 + 1
          )
        ) +
        formatRow(...rem240SegQSantander(dup, enderCliente, index * 2 + 2)) +
        formatRow(...rem240SegRSantander(dup, index * 2 + 3))
      );
    })
  );

  const trailer = "".concat(
    formatRow(
      ...rem240TrailerLoteSantander(
        dadosConta,
        duplicatas.length * 2 + 3,
        duplicatas.length,
        formatNumberField(
          sumDataField(duplicatas, "vlr_titulo").toFixed(2).toString()
        )
      )
    ),
    formatRow(...rem240TrailerSantander(duplicatas.length * 2 + 5))
  );

  return header + detalhes + trailer;
};
