import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  FiltroPeriodoDatas,
  FormButton,
  PageContainer,
} from "../../../../components";
import { apiGetV2 } from "../../../../apiV2";
import {
  BoletosRecebimentosMaosGrid,
  columnsBoletosRecebimentosMaos,
} from "./components/BoletosRecebimentosMaosGrid";
import {
  exportDataToSheet,
  formatarValor,
  formatDateISO,
} from "../../../../coreUtils";
import { docPrintReport } from "../../../../pdf";
import {
  columnsRotativoRecebimentosMaos,
  RotativosRecebimentosMaosGrid,
} from "./components/RotativosRecebimentosMaosGrid";

export const RecebimentosMaos = () => {
  const [idRota, setIdRota] = useState(null);
  const [idContaBanc, setIdContaBanc] = useState(null);
  const [dataFim, setDataFim] = useState(new Date());
  const [dataIni, setDataIni] = useState(new Date());
  const [boletos, setBoletos] = useState([]);
  const [rotativos, setRotativos] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const limparDados = () => {
    setBoletos([]);
    setRotativos([]);
    setTotais({});
  };

  const buscarDados = async () => {
    const params = {
      id_rota: idRota,
      id_conta_banc: idContaBanc,
      data_fim: dataFim,
      data_ini: dataIni,
    };

    return await apiGetV2(
      "/relatorios/venda_direta/recebimentos_em_maos/",
      params
    );
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    setBoletos(ok ? ret.boletos : []);
    setRotativos(ok ? ret.rotativos : []);
    setTotais(ok ? ret.totais : {});
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      const payload = {
        data_ini: formatDateISO(dataIni),
        data_fim: formatDateISO(dataFim),
        boletos: ret.boletos,
        rotativos: ret.rotativos,
        totais: ret.totais,
      };
      await docPrintReport(
        "/relatorios/pronta_entrega/titulos_recebidos_em_maos/",
        payload,
        "5055"
      );
    }
    setLoadingImprimir(false);
  };

  const exportar = async () => {
    setLoadingExportar(true);
    try {
      const [ok, ret] = await buscarDados();
      if (ok) {
        if (ret.boletos.length > 0) {
          exportDataToSheet(
            ret.boletos,
            "Valores Recebidos por Rota - Boletos",
            {
              columns: columnsBoletosRecebimentosMaos(true),
              dataIni: dataIni,
              dataFim: dataFim,
            }
          );
        }
        if (ret.rotativos.length > 0) {
          exportDataToSheet(
            ret.rotativos,
            "Valores Recebidos por Rota - Rotativos",
            {
              columns: columnsRotativoRecebimentosMaos(true),
              dataIni: dataIni,
              dataFim: dataFim,
            }
          );
        }
      }
    } finally {
      setLoadingExportar(false);
    }
  };

  useEffect(() => {
    limparDados();
  }, [idRota, idContaBanc, dataFim, dataIni]);

  return (
    <PageContainer title="Valores Recebidos em Mãos" number="5055" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            label="Rota"
            md={3}
            isConcatField
            concatModelName="rota_mob"
            defaultOptions
            isSearchable
            isClearable
            onChange={(sel) => setIdRota(sel?.value)}
            hideShortcut
          />
          <AsyncComboBox
            label="Conta Bancária"
            md={5}
            isConcatField
            concatModelName="conta_banc"
            defaultOptions
            isSearchable
            isClearable
            onChange={(sel) => setIdContaBanc(sel?.value)}
            hideShortcut
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
          <FormButton
            md="auto"
            color="primary"
            onClick={exportar}
            loading={loadingExportar}
          >
            Exportar
          </FormButton>
        </Row>
      </Card>
      <CardTotais md={12}>
        <CardTotaisItem
          label="Títulos"
          value={
            <>
              {formatarValor(totais?.qtd_boletos, 2, 0, true)} |{" "}
              {formatarValor(totais.vlr_rec_boletos, 2, 2, true)}
            </>
          }
          format={false}
        />
        <CardTotaisItem
          label="Rotativos"
          value={
            <>
              {formatarValor(totais?.qtd_rotativos, 2, 0, true)} |{" "}
              {formatarValor(totais.vlr_rec_rotativos, 2, 2, true)}
            </>
          }
          format={false}
        />
        <CardTotaisItem
          label="Valor Recebido"
          value={totais?.vlr_tot_recebido}
        />
      </CardTotais>
      {boletos.length > 0 && (
        <Card body>
          <BoletosRecebimentosMaosGrid dados={boletos} />
        </Card>
      )}
      {rotativos.length > 0 && (
        <Card body>
          <RotativosRecebimentosMaosGrid dados={rotativos} />
        </Card>
      )}
    </PageContainer>
  );
};
