import React from "react";
import {
  Divider,
  MenuGroup,
  MenuGroupButton,
  PageContainer,
} from "../../components";
import { HiClipboardDocumentCheck } from "react-icons/hi2";
import { FiTool } from "react-icons/fi";
import { LiaClipboardListSolid } from "react-icons/lia";
import { gerenciamentoOsRoute } from "../../routes/modules/ordem_servico";
import { RiListSettingsLine } from "react-icons/ri";
import {
  centralOSRoute,
  orcRapidoOSRoute,
  vistoriaTecnicaRoute,
} from "../../routes/modules/ordem_servico";

export const OrdensServico = () => {
  return (
    <PageContainer title="Atendimento - Ordens de Serviço">
      <Divider className="pl-3 pr-5">Atendimento</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Central de O.S."
          icon={FiTool}
          pathname={centralOSRoute.path}
          nroTela={centralOSRoute.nroTela}
        />
        <MenuGroupButton
          label="Vistoria Técnica"
          icon={HiClipboardDocumentCheck}
          pathname={vistoriaTecnicaRoute.path}
          nroTela={vistoriaTecnicaRoute.nroTela}
        />
        <MenuGroupButton
          label="Orçamento Rápido"
          icon={LiaClipboardListSolid}
          pathname={orcRapidoOSRoute.path}
          nroTela={orcRapidoOSRoute.nroTela}
        />
      </MenuGroup>
      <Divider className="pl-3 pr-5">Gerenciamento</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Gerenciamento de O.S."
          icon={RiListSettingsLine}
          pathname={gerenciamentoOsRoute.path}
          nroTela={gerenciamentoOsRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
