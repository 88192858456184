import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  forma_impressao: "VIS",
  layout_impr: 2,
  msg_fixa_1: "",
  msg_fixa_2: "",
  msg_fixa_3: "",
  msg_fixa_4: "",
  ecf_nro_vias: 1,
  nome_impressora: "",
  imprimir_vale_por_qtd: false,
  utiliza_vend_externo: false,
  utiliza_cashback: false,
  senha_por_colaborador: false,
  identifica_vendedor_tecn_serv: false,
  solic_senha_reimpr_venda: false,
  considera_limite_cred_cad_cli: "NAO",
  id_organizacao_importacao_broker: null,
  id_plano_conta_importacao_broker: null,
  id_centro_custo_importacao_broker: null,
  id_cfop_vale_presente: null,
  id_produto_vale_presente: null,
  tipo_lista_preco_utilizada: "FIXA",
};

export const vendaSlice = createSlice({
  name: "venda_slice",
  initialState: initialState,
  reducers: {
    setupVenda: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setFormaImpressao: (state, action) => {
      state.forma_impressao = action.payload;
    },
    setLayoutImpr: (state, action) => {
      state.layout_impr = action.payload;
    },
    setMsgFixa_1: (state, action) => {
      state.msg_fixa_1 = action.payload;
    },
    setMsgFixa_2: (state, action) => {
      state.msg_fixa_2 = action.payload;
    },
    setMsgFixa_3: (state, action) => {
      state.msg_fixa_3 = action.payload;
    },
    setMsgFixa_4: (state, action) => {
      state.msg_fixa_4 = action.payload;
    },
    setEcfNroVias: (state, action) => {
      state.ecf_nro_vias = action.payload;
    },
    setNomeImpressora: (state, action) => {
      state.nome_impressora = action.payload;
    },
    setImprimirValePorQtd: (state, action) => {
      state.imprimir_vale_por_qtd = action.payload;
    },
    setUtilizaVendExterno: (state, action) => {
      state.utiliza_vend_externo = action.payload;
    },
    setUtilizaCashback: (state, action) => {
      state.utiliza_cashback = action.payload;
    },
    setSenhaPorColaborador: (state, action) => {
      state.senha_por_colaborador = action.payload;
    },
    setIdentificaVendedorTecnServ: (state, action) => {
      state.identifica_vendedor_tecn_serv = action.payload;
    },
    setSolicSenhaReimprVenda: (state, action) => {
      state.solic_senha_reimpr_venda = action.payload;
    },
    setConsideraLimiteCredCadCli: (state, action) => {
      state.considera_limite_cred_cad_cli = action.payload;
    },
    setIdOrganizacaoImportacaoBroker: (state, action) => {
      state.id_organizacao_importacao_broker = action.payload;
    },
    setIdPlanoContaImportacaoBroker: (state, action) => {
      state.id_plano_conta_importacao_broker = action.payload;
    },
    setIdCentroCustoImportacaoBroker: (state, action) => {
      state.id_centro_custo_importacao_broker = action.payload;
    },
    setIdCfopValePresente: (state, action) => {
      state.id_cfop_vale_presente = action.payload;
    },
    setIdProdutoValePresente: (state, action) => {
      state.id_produto_vale_presente = action.payload;
    },
    setTipoListaPrecoUtilizada: (state, action) => {
      state.tipo_lista_preco_utilizada = action.payload;
    },
  },
});

export const {
  setupVenda,
  setFormaImpressao,
  setLayoutImpr,
  setMsgFixa_1,
  setMsgFixa_2,
  setMsgFixa_3,
  setMsgFixa_4,
  setEcfNroVias,
  setNomeImpressora,
  setImprimirValePorQtd,
  setUtilizaVendExterno,
  setUtilizaCashback,
  setSenhaPorColaborador,
  setIdentificaVendedorTecnServ,
  setSolicSenhaReimprVenda,
  setConsideraLimiteCredCadCli,
  setIdOrganizacaoImportacaoBroker,
  setIdPlanoContaImportacaoBroker,
  setIdCentroCustoImportacaoBroker,
  setIdCfopValePresente,
  setIdProdutoValePresente,
  setTipoListaPrecoUtilizada,
} = vendaSlice.actions;

export default vendaSlice.reducer;
