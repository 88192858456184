import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  operacao_padrao: "V",
  regime_tribut: "",
  utiliza_ambiente: false,
  adiciona_item_grid: "R",
  utiliza_regra_premiacao: false,
  avisa_identif_premiado: false,
  tem_lista_preco: false,
  utiliza_regra_tributacao: false,
  utiliza_cor_prod: false,
  utiliza_tamanho_prod: false,
  identifica_premiado: false,
  permite_faturamento_frente_venda: false,
  id_conta_banc_fat_bol: null,
  identifica_vendedor_final: false,
  informar_perc_comis_frente_venda: false,
  identifica_forma_rec: false,
  utiliza_preco_atacado: false,
  bloq_venda_cliente_classif_8: false,
  bloq_venda_cliente_vlr_pend_rotativo: false,
  bloq_inc_prod_qtd_est_negativo: false,
  bloq_inc_prod_por: "DESC_MAX",
  obriga_autoriz_uso_prod_curinga: false,
};

export const paramsFrenteVendaSlice = createSlice({
  name: "params_frente_venda_slice",
  initialState: initialState,
  reducers: {
    setupParams: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setRegimeTribut: (state, action) => {
      state.regime_tribut = action.payload;
    },
    setUtilizaAmbiente: (state, action) => {
      state.utiliza_ambiente = action.payload;
    },
    setAdicionaItemGrid: (state, action) => {
      state.adiciona_item_grid = action.payload;
    },
    setUtilizaRegraPremiacao: (state, action) => {
      state.utiliza_regra_premiacao = action.payload;
    },
    setAvisaIdentifPremiado: (state, action) => {
      state.avisa_identif_premiado = action.payload;
    },
    setOperacaoPadrao: (state, action) => {
      state.operacao_padrao = action.payload;
    },
    setTemListaPreco: (state, action) => {
      state.tem_lista_preco = action.payload;
    },
    setUtilizaRegraTributacao: (state, action) => {
      state.utiliza_regra_tributacao = action.payload;
    },
    setUtilizaCorProd: (state, action) => {
      state.utiliza_cor_prod = action.payload;
    },
    setUtilizaTamanhoProd: (state, action) => {
      state.utiliza_tamanho_prod = action.payload;
    },
    setIdentificaPremiado: (state, action) => {
      state.identifica_premiado = action.payload;
    },
    setPermiteFaturamentoFrenteVenda: (state, action) => {
      state.permite_faturamento_frente_venda = action.payload;
    },
    setIdContaBancFatBol: (state, action) => {
      state.id_conta_banc_fat_bol = action.payload;
    },
    setIdentificaVendedorFinal: (state, action) => {
      state.identifica_vendedor_final = action.payload;
    },
    setInformarPercComisFrenteVenda: (state, action) => {
      state.informar_perc_comis_frente_venda = action.payload;
    },
    setIdentificaFormaRec: (state, action) => {
      state.identifica_forma_rec = action.payload;
    },
    setUtilizaPrecoAtacado: (state, action) => {
      state.utiliza_preco_atacado = action.payload;
    },
    setBloqVendaClienteClassif8: (state, action) => {
      state.bloq_venda_cliente_classif_8 = action.payload;
    },
    setBloqVendaClienteVlrPendRotativo: (state, action) => {
      state.bloq_venda_cliente_vlr_pend_rotativo = action.payload;
    },
    setBloqIncProdQtdEstNegativo: (state, action) => {
      state.bloq_inc_prod_qtd_est_negativo = action.payload;
    },
    setBloqIncProdPor: (state, action) => {
      state.bloq_inc_prod_por = action.payload;
    },
    setObrigaAutorizUsoProdCuringa: (state, action) => {
      state.obriga_autoriz_uso_prod_curinga = action.payload;
    },
  },
});

export const {
  setupParams,
  setOperacaoPadrao,
  setRegimeTribut,
  setUtilizaAmbiente,
  setAdicionaItemGrid,
  setUtilizaRegraPremiacao,
  setAvisaIdentifPremiado,
  setTemListaPreco,
  setUtilizaRegraTributacao,
  setUtilizaTamanhoProd,
  setUtilizaCorProd,
  setIdentificaPremiado,
  setPermiteFaturamentoFrenteVenda,
  setIdContaBancFatBol,
  setIdentificaVendedorFinal,
  setInformarPercComisFrenteVenda,
  setIdentificaFormaRec,
  setUtilizaPrecoAtacado,
  setBloqVendaClienteClassif8,
  setBloqVendaClienteVlrPendRotativo,
  setBloqIncProdQtdEstNegativo,
  setBloqIncProdPor,
  setObrigaAutorizUsoProdCuringa,
} = paramsFrenteVendaSlice.actions;

export default paramsFrenteVendaSlice.reducer;
