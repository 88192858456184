import React from "react";
import { Card } from "reactstrap";
import { TableCheck } from "../../../../../components/TableCheck";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
  naturalSortDate,
} from "../../../../../coreUtils";
import { MoreDropdown, Table } from "../../../../../components";
import { DropdownItem } from "reactstrap";
import { DetalheOSModal } from "../../../../ordens_servico/gerenciamento_os/components/DetalheOSModal";
import { DetalheVendaModal } from "../../../../vendas/gerenciamento/gerenciamento_vendas/components/DetalheVendaModal";
import { useState } from "react";

export const GerarNfVendaOsGrid = ({
  data,
  reabrirFat,
  defineNaoGeraNf,
  handleSelect,
  handleSelectAll,
}) => {
  const [detalheOSOpen, setDetalheOSOpen] = useState(false);
  const [detalheVendaOpen, setDetalheVendaOpen] = useState(false);
  const [idOrigemSel, setIdOrigemSel] = useState(null);

  const toggleDetalheOS = () => {
    setDetalheOSOpen(!detalheOSOpen);
  };

  const toggleDetalheVenda = () => {
    setDetalheVendaOpen(!detalheVendaOpen);
  };

  const abrirDetalhes = (t, idOrigem) => {
    setIdOrigemSel(idOrigem);
    if (t === "OSV") {
      toggleDetalheOS();
    } else if (t === "VDA") {
      toggleDetalheVenda();
    }
  };

  const columns = [
    {
      dataField: "tipo_mov",
      text: "Tipo",
      align: "center",
      formatter: (cell) => {
        if (cell === "VDA") {
          return "Venda";
        } else if (cell === "OSV") {
          return "Ordem de Serviço";
        } else {
          return cell;
        }
      },
    },
    {
      dataField: "id",
      text: "Número",
      align: "center",
    },
    {
      dataField: "data_reg",
      text: "Data",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      sortable: true,
      formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    },
    {
      dataField: "vlr_desc",
      text: "Desconto",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_tot_frete",
      text: "Frete",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "tot_terceirizacao",
      text: "Terceirizações",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "tot_servicos",
      text: "Serviços",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "tot_produtos",
      text: "Produtos",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      formatter: (cell) => formatNumber(cell, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "gerou_nf",
      text: "NF",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
    {
      dataField: "gerou_nfse",
      text: "NFS",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      selectOnClick: false,
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={(e) => abrirDetalhes(row.tipo_mov, row.id)}>
            Detalhes
          </DropdownItem>
          {(row.gerou_nf || row.gerou_nfse) && (
            <DropdownItem onClick={(e) => reabrirFat(row.id, row.tipo_mov)}>
              Reabrir Geração NF-e, NFS-e
            </DropdownItem>
          )}
          {(!row.gerou_nf || !row.gerou_nfse) && (
            <DropdownItem
              onClick={(e) => defineNaoGeraNf(row.id, row.tipo_mov)}
            >
              Não Gerar NF
            </DropdownItem>
          )}
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Card body>
      <DetalheOSModal
        isOpen={detalheOSOpen}
        toggle={toggleDetalheOS}
        idOS={idOrigemSel}
      />
      <DetalheVendaModal
        isOpen={detalheVendaOpen}
        toggle={toggleDetalheVenda}
        idVenda={idOrigemSel}
      />
      <Table
        columns={columns}
        data={data}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        multiselect
      />
    </Card>
  );
};
