import React from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  Divider,
  MaskedInput,
  TextInput,
  IntegerFormInput,
  ComboBox,
  FixedField,
  HintLabel,
  NumberInput,
} from "../../../../../components";
import { useSelector, useDispatch } from "react-redux";
import {
  setIdTransportadoraFrete,
  setPlacaVeicFrete,
  setFretePorConta,
  setUfVeicFrete,
  setQtdeVolumesFrete,
  setEspecieFrete,
  setMarcaFrete,
  setNumeroVolumeFrete,
  setEnderecoTransportadoraFrete,
  setMunicipioTransportadoraFrete,
  setUfTransportadoraFrete,
  setPesoLiquidoFrete,
  setPesoBrutoFrete,
} from "../store/cabNFSlice";
import { MASK_PLACA_AUTOMOTIVA } from "../../../../../flags";

export const optionsFretePorContaNF = [
  { label: "0 - Emitente", value: "0" },
  { label: "1 - Destinatário", value: "1" },
  { label: "2 - Terceiros", value: "2" },
  { label: "9 - Sem Frete", value: "9" },
];

const DadosFreteNf = ({ setMudouQtdeVolumes }) => {
  const stateFrete = useSelector((state) => state.cabNF.frete);
  const dispatch = useDispatch();

  const handleSetTransportadora = (s) => {
    dispatch(setIdTransportadoraFrete(s?.value ?? null));

    const enderTransp = s ? [s.endereco, s.numero, s.bairro].join(", ") : "";
    dispatch(setEnderecoTransportadoraFrete(enderTransp));
    dispatch(setMunicipioTransportadoraFrete(s?.cidade ?? ""));
    dispatch(setUfTransportadoraFrete(s?.uf ?? ""));
  };

  return (
    <>
      <Divider className="mt-0">Transportadora / Volumes Transportados</Divider>
      <Row className="mb-2">
        <AsyncComboBox
          md={8}
          isConcatField
          concatModelName="transportadora"
          defaultOptions
          isSearchable
          isClearable
          label="Transportadora"
          onChange={handleSetTransportadora}
          defaultValue={stateFrete.idTransportadora}
          disabled={stateFrete.porConta === "9"}
          clearOnDisable
        />
      </Row>
      <Row className="mb-2">
        <ComboBox
          md={4}
          label="Frete Por Conta"
          onChange={(s) => dispatch(setFretePorConta(s?.value))}
          defaultValue={stateFrete.porConta}
          options={optionsFretePorContaNF}
        />
        <MaskedInput
          mask={MASK_PLACA_AUTOMOTIVA}
          label="Placa"
          md={4}
          onChange={(e, v) => dispatch(setPlacaVeicFrete(v))}
          value={stateFrete.placa}
          maskChar={null}
        />
        <MaskedInput
          mask="aa"
          md={3}
          label="UF Veíc."
          onChange={(e, v) => dispatch(setUfVeicFrete(v))}
          value={stateFrete.uf}
          maskChar={null}
        />
      </Row>
      <Row className="mb-2">
        <FixedField
          label="Endereço"
          value={stateFrete.enderecoTransportadora}
          horizontal
        />
      </Row>
      <Row className="mb-2">
        <FixedField
          label="Município"
          value={stateFrete.municipioTransportadora}
          horizontal
        />
        <FixedField label="UF" value={stateFrete.ufTransportadora} horizontal />
      </Row>
      <Row>
        <IntegerFormInput
          md={3}
          label={
            <HintLabel
              label="Qtd. Volume"
              paramHintText={`Campo parametrizado: o sistema pode sugerir
              a quantidade de volumes conforme o corpo da NFe
              ou sempre sugerir preenchimento zerado.`}
            />
          }
          defaultValue={stateFrete.qtdeVolumes}
          onChange={(v) => {
            setMudouQtdeVolumes(true);
            dispatch(setQtdeVolumesFrete(v));
          }}
        />
        <TextInput
          md={3}
          label="Espécie"
          value={stateFrete.especie}
          onChange={(e, v) => dispatch(setEspecieFrete(v))}
          maxLength={12}
        />
        <TextInput
          md={3}
          label="Marca"
          value={stateFrete.marca}
          onChange={(e, v) => dispatch(setMarcaFrete(v))}
          maxLength={12}
        />
        <TextInput
          md={3}
          label="Número"
          value={stateFrete.numero}
          onChange={(e, v) => dispatch(setNumeroVolumeFrete(v))}
          maxLength={12}
        />
      </Row>
      <Row>
        <NumberInput
          md={3}
          label="Peso Bruto"
          value={stateFrete.pesoBruto}
          onChange={(v) => dispatch(setPesoBrutoFrete(v))}
        />
        <NumberInput
          md={3}
          label="Peso Líquido"
          value={stateFrete.pesoLiquido}
          onChange={(v) => dispatch(setPesoLiquidoFrete(v))}
        />
      </Row>
    </>
  );
};

export default DadosFreteNf;
