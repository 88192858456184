import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  FiltroPeriodoDatas,
  PageContainer,
} from "../../../../components";
import { docPrintReport } from "../../../../pdf";
import { formatDateISO } from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";

export const RelacaoOsGeral = () => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [colaborador, setColaborador] = useState();

  const handleDateInput = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const imprimir = async () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_cliente: cliente,
      id_colaborador: colaborador,
    };

    const [ok, ret] = await apiGetV2(
      "/relatorios/ordem_servico/geral/",
      params
    );

    setLoading(true);

    if (ok) {
      const payload = {
        data_ini: formatDateISO(dataIni),
        data_fim: formatDateISO(dataFim),
        ...ret,
      };

      await docPrintReport(
        "/relatorios/ordem_servico/geral",
        payload,
        "5067",
        true
      );
    }

    setLoading(false);
  };

  return (
    <PageContainer title="Relação O.S. Geral" number="5067" canGoBack>
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            isClearable
            isSearchable
            isMulti
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            onChange={(s) => setCliente(s?.value)}
          />
          <AsyncComboBox
            md={3}
            isClearable
            isSearchable
            isMulti
            label="Colaborador"
            isConcatField
            concatModelName="colaborador"
            onChange={(s) => setColaborador(s?.value)}
          />
          <FiltroPeriodoDatas onChange={handleDateInput} />
          <BotaoImprimir onClick={imprimir} loading={loading} />
        </Row>
      </Card>
    </PageContainer>
  );
};
