import { configureStore } from "@reduxjs/toolkit";
import appPedidoVendaSlice from "./app_pedido_venda_slice";
import appVendaDiretaSlice from "./app_venda_direta_slice";
import balancaSlice from "./balanca_slice";
import bancarioSlice from "./bancario_slice";
import cadastroClienteSlice from "./cadastro_cliente_slice";
import cadastroProdutoSlice from "./cadastro_produto_slice";
import cadastroSlice from "./cadastro_slice";
import caixaSlice from "./caixa_slice";
import cartaoRecSlice from "./cartao_rec_slice";
import chequeSlice from "./cheque_slice";
import condicionalSlice from "./condicional_slice";
import contasReceberSlice from "./contas_receber_slice";
import crediarioSlice from "./crediario_slice";
import creditoRotativoSlice from "./credito_rotativo_slice";
import cteOsSlice from "./cte_os_slice";
import cteSlice from "./cte_slice";
import devolucaoSlice from "./devolucao_slice";
import emailSlice from "./email_slice";
import entregaPedidoExtSlice from "./entrega_pedido_ext_slice";
import estoqueSlice from "./estoque_slice";
import faturamentoSlice from "./faturamento_slice";
import frenteCaixaSlice from "./frente_caixa_slice";
import geralSlice from "./geral_slice";
import mdfeSlice from "./mdfe_slice";
import nfEletronicaConsumidorSlice from "./nf_eletronica_consumidor_slice";
import nfEletronicaGeralSlice from "./nf_eletronica_geral_slice";
import nfEletronicaSlice from "./nf_eletronica_slice";
import nfEntradaSlice from "./nf_entrada_slice";
import nfServicoSlice from "./nf_servico_slice";
import orcamentoSlice from "./orcamento_slice";
import osSlice from "./os_slice";
import pdvSlice from "./pdv_slice";
import pedidoExternoSlice from "./pedido_externo_slice";
import pedidoVendaSlice from "./pedido_venda_slice";
import reciboSlice from "./recibo_slice";
import romaneioSlice from "./romaneio_slice";
import sincronismoSlice from "./sincronismo_slice";
import tefSlice from "./tef_slice";
import vendaSlice from "./venda_slice";

export default configureStore({
  reducer: {
    app_pedido_venda: appPedidoVendaSlice,
    app_venda_direta: appVendaDiretaSlice,
    balanca: balancaSlice,
    bancario: bancarioSlice,
    cadastro_cliente: cadastroClienteSlice,
    cadastro_produto: cadastroProdutoSlice,
    cadastro: cadastroSlice,
    caixa: caixaSlice,
    cartao_rec: cartaoRecSlice,
    cheque: chequeSlice,
    condicional: condicionalSlice,
    contas_receber: contasReceberSlice,
    crediario: crediarioSlice,
    credito_rotativo: creditoRotativoSlice,
    cte: cteSlice,
    cte_os: cteOsSlice,
    devolucao: devolucaoSlice,
    email: emailSlice,
    entrega_pedido_ext: entregaPedidoExtSlice,
    estoque: estoqueSlice,
    faturamento: faturamentoSlice,
    frente_caixa: frenteCaixaSlice,
    geral: geralSlice,
    mdfe: mdfeSlice,
    nf_eletronica_consumidor: nfEletronicaConsumidorSlice,
    nf_eletronica_geral: nfEletronicaGeralSlice,
    nf_eletronica: nfEletronicaSlice,
    nf_entrada: nfEntradaSlice,
    nf_servico: nfServicoSlice,
    orcamento: orcamentoSlice,
    os: osSlice,
    pdv: pdvSlice,
    pedido_externo: pedidoExternoSlice,
    pedido_venda: pedidoVendaSlice,
    recibo: reciboSlice,
    romaneio: romaneioSlice,
    sincronismo: sincronismoSlice,
    tef: tefSlice,
    venda: vendaSlice,
  },
});
