import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/bancario/cobranca/remessa";

const RemessaBancariaService = {
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  buscarRemessa: (id, impressao = false) =>
    apiGetV2(`${URL_BASE}/buscar/${id}/?impressao=${impressao}`),
  gerarDadosRemessa: (payload) =>
    apiPostV2(`${URL_BASE}/gerar_dados/`, payload),
  listarTitulos: (contaBanc, params) =>
    apiGetV2(`${URL_BASE}/listar_titulos/${contaBanc}/`, params),
  reabrirRemessa: (payload) =>
    apiPutV2(`${URL_BASE}/reabrir_remessa/`, payload),
  reabrirTitulo: (payload) =>
    apiPutV2(`${URL_BASE}/reabrir_titulo_remessa/`, payload),
  buscarTitulosRemessa: (id) =>
    apiGetV2(`${URL_BASE}/buscar_titulos_remessa/${id}/`),
  naoEnviarTitRemessa: (idTitulo) =>
    apiPutV2(`${URL_BASE}/nao_enviar_tit_remessa/`, {
      id_titulo: idTitulo,
    }),
  salvarCopia: (payload) => apiPostV2(`${URL_BASE}/salvar_copia/`, payload),
};

export default RemessaBancariaService;
