import React from "react";
import { formatNumber } from "../../../../../coreUtils";
import { useState } from "react";
import { BsFileText } from "react-icons/bs";
import { IconButton, ModalBase, Table } from "../../../../../components";
import { apiGetV2 } from "../../../../../apiV2";

const columns = [
  {
    dataField: "tipo",
    text: "Tipo",
    align: "center",
  },
  {
    dataField: "numero",
    text: "Número",
    align: "center",
    formatter: (c, row) =>
      row.tipo === "Título a Receber" ? `${c}-${row.parcela}` : row.id,
  },
  {
    dataField: "desc_origem",
    text: "Origem",
    align: "left",
    formatter: (c, row) => (row.id_origem ? `${c} ${row.id_origem}` : c),
  },
  {
    dataField: "emissao",
    text: "Emissão",
    align: "center",
  },
  {
    dataField: "vencimento",
    text: "Vencimento",
    align: "center",
  },
  {
    dataField: "vlr_orig",
    text: "Vlr. Orig",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_receber",
    text: "A Receber",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_recebido",
    text: "Recebido",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const DetalhesCliInadModal = ({
  idVendedor,
  idCliente,
  nomeCliente,
  dataIni,
  dataFim,
  considerarCredRot,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});

  const limparDados = () => {
    setDados([]);
    setTotais({});
  };

  const onOpen = async () => {
    const params = {
      id_vendedor: idVendedor,
      data_ini: dataIni,
      data_fim: dataFim,
      considerar_cred_rot: considerarCredRot,
    };
    const [ok, ret] = await apiGetV2(
      `/relatorios/financeiro/detalhes_cli_inadimplentes/${idCliente}/`,
      params
    );

    if (ok) {
      setDados(ret.pendencias);
      setTotais(ret.totais);
    }
  };

  const toggle = () => {
    if (!isOpen) {
      if (onOpen) onOpen();
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <IconButton
        icon={BsFileText}
        hint="Ver Detalhes"
        onClick={toggle}
        tooltipPlacement="left"
      />
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        onClosed={limparDados}
        size="xl"
        title={
          <h5 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
            {`${nomeCliente} - Detalhamento de Títulos`}
          </h5>
        }
        autoFocus
        hideCancelButton
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h5>
            Títulos a Receber:{" "}
            <strong>
              <span>{formatNumber(totais?.tot_titulos_rec, true, 2)}</span>
            </strong>
          </h5>
          {considerarCredRot && (
            <h5>
              Rotativo:{" "}
              <strong>
                <span>{formatNumber(totais?.tot_rotativo, true, 2)}</span>
              </strong>
            </h5>
          )}
          <h5>
            Pend. Caixa Loja:{" "}
            <strong>
              <span>{formatNumber(totais?.tot_movs_pends_caixa, true, 2)}</span>
            </strong>
          </h5>
          <h5>
            Total Geral:{" "}
            <strong>
              <span>{formatNumber(totais?.tot_geral, true, 2)}</span>
            </strong>
          </h5>
        </div>
        <Table
          columns={columns}
          data={dados ?? []}
          pageSize={15}
          paginated={false}
        />
      </ModalBase>
    </>
  );
};
