import React, { useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import ImageViewer from "react-simple-image-viewer";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  CardImg,
  CardText,
  Col,
  CustomInput,
  FormGroup,
  FormText,
  Label,
  Row,
  TabPane,
} from "reactstrap";
import {
  DragAndDropArquivos,
  FormButton,
  NumberInput,
  TextInput,
} from "../../../../../components";
import { addLocalInstalacao } from "../store/local_instalacao_modulos_slice";
import { GridLocaisInstalacao } from "./GridLocaisInstalacao";

const initialState_ = {
  descricao: "",
  orientacao: "NAO",
  inclinacao: 0,
  largura: 0,
  comprimento: 0,
  medidaTelhado: 0,
  tipoTelha: "",
  distanciaRipas: 0,
  distanciaCaibros: 0,
  distanciaTercas: 0,
  distanciaVigasMetalicas: 0,
  alturaTelhado: 0,
  acesso: "NAO",
  nomeFotoCroqui: null,
  fotoCroqui: null,
};

const localInstalacaoReducer = (state, action) => {
  const newState = Object.assign({}, state);
  switch (action.target) {
    case "descricao":
      newState["descricao"] = action.payload?.toUpperCase();
      return newState;
    case "orientacao":
      newState["orientacao"] = action.payload;
      return newState;
    case "inclinacao":
      newState["inclinacao"] = action.payload;
      return newState;
    case "largura":
      newState["largura"] = action.payload;
      newState["medidaTelhado"] =
        (newState.comprimento ?? 0) * (action.payload ?? 0);
      return newState;
    case "comprimento":
      newState["comprimento"] = action.payload;
      newState["medidaTelhado"] =
        (newState.largura ?? 0) * (action.payload ?? 0);
      return newState;
    case "tipoTelha":
      newState["tipoTelha"] = action.payload?.toUpperCase();
      return newState;
    case "distanciaRipas":
      newState["distanciaRipas"] = action.payload;
      return newState;
    case "distanciaCaibros":
      newState["distanciaCaibros"] = action.payload;
      return newState;
    case "distanciaTercas":
      newState["distanciaTercas"] = action.payload;
      return newState;
    case "distanciaVigasMetalicas":
      newState["distanciaVigasMetalicas"] = action.payload;
      return newState;
    case "alturaTelhado":
      newState["alturaTelhado"] = action.payload;
      return newState;
    case "acesso":
      newState["acesso"] = action.payload?.toUpperCase();
      return newState;
    case "fotoCroqui":
      newState["nomeFotoCroqui"] = action.payload.nome;
      newState["fotoCroqui"] = action.payload.base64;
      return newState;
    case "reset":
      return initialState_;
    default:
      throw new Error();
  }
};

const validarLocal = (local) => {
  const invalidData = [null, undefined, ""];
  let errCount = 0;

  if (invalidData.includes(local.descricao)) {
    toastr.warning("Atenção", "Por favor, informe a descrição do local");
    errCount++;
  }
  if (invalidData.includes(local.orientacao)) {
    local.orientacao = 0;
  }
  if (invalidData.includes(local.inclinacao)) {
    local.inclinacao = 0;
  }
  if (invalidData.includes(local.largura)) {
    local.largura = 0;
  }
  if (invalidData.includes(local.comprimento)) {
    local.comprimento = 0;
  }
  if (invalidData.includes(local.tipoTelha)) {
    toastr.warning("Atenção", "Por favor, informe o tipo de telha do local");
    errCount++;
  }
  if (invalidData.includes(local.distanciaRipas)) {
    local.distanciaRipas = 0;
  }
  if (invalidData.includes(local.distanciaCaibros)) {
    local.distanciaCaibros = 0;
  }
  if (invalidData.includes(local.distanciaTercas)) {
    local.distanciaTercas = 0;
  }
  if (invalidData.includes(local.distanciaVigasMetalicas)) {
    local.distanciaVigasMetalicas = 0;
  }
  if (invalidData.includes(local.alturaTelhado)) {
    local.alturaTelhado = 0;
  }
  if (invalidData.includes(local.acesso)) {
    toastr.warning("Atenção", "Por favor, informe o acesso do local");
    errCount++;
  }

  return errCount === 0;
};

export const TabDetalhamentoLocais = () => {
  const descricaoInputRef = useRef();
  const [state, dispatch] = useReducer(localInstalacaoReducer, initialState_);
  const dispatchVistoria = useDispatch();
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const handleSubmit = () => {
    if (validarLocal(state)) {
      dispatchVistoria(addLocalInstalacao(state));
      dispatch({ target: "reset" });
      descricaoInputRef.current.focus();
    }
  };

  const onDrop = (file) => {
    var reader = new FileReader();

    reader.addEventListener("load", function (e) {
      dispatch({
        target: "fotoCroqui",
        payload: { nome: file.path, base64: e.target.result },
      });
    });

    reader.readAsDataURL(file);
  };

  const deleteFoto = async () => {
    dispatch({ target: "fotoCroqui", payload: null });
  };

  return (
    <TabPane tabId="5" className="p-2">
      <Row>
        <TextInput
          ref={descricaoInputRef}
          label="Descrição"
          md={4}
          onChange={({ target }) =>
            dispatch({ target: "descricao", payload: target?.value })
          }
          value={state.descricao}
          maxLength={255}
        />
      </Row>
      <Row className="clearfix">
        <Col md="auto">
          <FormGroup
            onChange={({ target }) => {
              dispatch({ target: "orientacao", payload: target?.value });
            }}
          >
            <Label>Orientação</Label>
            <div>
              <CustomInput
                type="radio"
                name="orientacao"
                id="norte_orientacao"
                label="Norte"
                value="N"
                inline
                checked={state.orientacao === "N"}
              />
              <CustomInput
                type="radio"
                name="orientacao"
                id="nordeste_orientacao"
                label="Nordeste"
                value="NE"
                inline
                checked={state.orientacao === "NE"}
              />
              <CustomInput
                type="radio"
                name="orientacao"
                id="leste_orientacao"
                label="Leste"
                value="L"
                inline
                checked={state.orientacao === "L"}
              />
              <CustomInput
                type="radio"
                name="orientacao"
                id="sudeste_orientacao"
                label="Sudeste"
                value="SE"
                inline
                checked={state.orientacao === "SE"}
              />
              <CustomInput
                type="radio"
                name="orientacao"
                id="sul_orientacao"
                label="Sul"
                value="S"
                inline
                checked={state.orientacao === "S"}
              />
              <CustomInput
                type="radio"
                name="orientacao"
                id="sudoeste_orientacao"
                label="Sudoeste"
                value="SO"
                inline
                checked={state.orientacao === "SO"}
              />
              <CustomInput
                type="radio"
                name="orientacao"
                id="oeste_orientacao"
                label="Oeste"
                value="O"
                inline
                checked={state.orientacao === "O"}
              />
              <CustomInput
                type="radio"
                name="orientacao"
                id="noroeste_orientacao"
                label="Noroeste"
                value="NO"
                inline
                checked={state.orientacao === "NO"}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5 style={{ marginTop: "10px", marginBottom: "10px" }}>
            Informe as dimensões do Local de Instalação
          </h5>
        </Col>
      </Row>
      <Row>
        <NumberInput
          disabled={state.fotoCroqui != null}
          label="Inclinação (Graus)"
          md="auto"
          onChange={(v) => dispatch({ target: "inclinacao", payload: v })}
          value={state.inclinacao}
        />
        <NumberInput
          disabled={state.fotoCroqui != null}
          label="Largura (m)"
          md={1}
          onChange={(v) => dispatch({ target: "largura", payload: v })}
          value={state.largura}
        />
        <FormGroup>
          <Label />
          <FormText style={{ marginTop: "10px" }} color="dark">
            X
          </FormText>
        </FormGroup>
        <NumberInput
          disabled={state.fotoCroqui != null}
          label="Comp. (m)"
          md={1}
          onChange={(v) => dispatch({ target: "comprimento", payload: v })}
          value={state.comprimento}
        />
        <NumberInput
          disabled
          label="Medida do Telhado (m²)"
          md={"auto"}
          value={state.medidaTelhado}
        />
      </Row>
      <Row>
        <Col>
          <h5 style={{ marginTop: "10px", marginBottom: "10px" }}>
            Ou, Selecione a foto do Croqui
          </h5>
        </Col>
      </Row>
      <Row style={{ marginBottom: "10px" }}>
        {state.fotoCroqui === null ? (
          <DragAndDropArquivos md={6} onDrop={onDrop} accept="image/*" />
        ) : (
          <>
            <CardGroup>
              <Col>
                <Card>
                  <CardImg
                    alt={state.nomeFotoCroqui}
                    style={{
                      cursor: "pointer",
                      marginBottom: "7px",
                      maxHeight: "150px",
                    }}
                    src={state.fotoCroqui}
                    top
                    width="100%"
                    onClick={() => setIsViewerOpen(true)}
                  />
                  <CardBody>
                    <CardText>{state.nomeFotoCroqui}</CardText>
                    <Button color="danger" onClick={() => deleteFoto()}>
                      Remover Foto
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </CardGroup>

            {isViewerOpen && (
              <ImageViewer
                src={[state.fotoCroqui]}
                currentIndex={0}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={() => setIsViewerOpen(false)}
              />
            )}
          </>
        )}
      </Row>
      <Row>
        <Col md="auto">
          <FormGroup
            onChange={({ target }) => {
              dispatch({ target: "tipoTelha", payload: target?.value });
            }}
          >
            <Label>Tipo de Telha</Label>
            <div>
              <CustomInput
                type="radio"
                name="tipo_telha"
                id="nao_def_tipo_telha"
                label="Não Definido"
                value="NAO"
                inline
                checked={state.tipoTelha === "NAO"}
              />
              <CustomInput
                type="radio"
                name="tipo_telha"
                id="fibrocimento_tipo_telha"
                label="Fibrocimento"
                value="FICI"
                inline
                checked={state.tipoTelha === "FICI"}
              />
              <CustomInput
                type="radio"
                name="tipo_telha"
                id="colonial_tipo_telha"
                label="Colônial"
                value="COLO"
                inline
                checked={state.tipoTelha === "COLO"}
              />
              <CustomInput
                type="radio"
                name="tipo_telha"
                id="aluzinco_tipo_telha"
                label="Aluzinco"
                value="ALUZ"
                inline
                checked={state.tipoTelha === "ALUZ"}
              />
              <CustomInput
                type="radio"
                name="tipo_telha"
                id="concreto_tipo_telha"
                label="Concreto"
                value="CONC"
                inline
                checked={state.tipoTelha === "CONC"}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <NumberInput
          label="Distância entre Ripas (m)"
          md="auto"
          onChange={(v) => dispatch({ target: "distanciaRipas", payload: v })}
          value={state.distanciaRipas}
        />
        <NumberInput
          label="Distância entre Caibros (m)"
          md="auto"
          onChange={(v) => dispatch({ target: "distanciaCaibros", payload: v })}
          value={state.distanciaCaibros}
        />
      </Row>
      <Row>
        <NumberInput
          label="Distância das Terças (m)"
          md="auto"
          onChange={(v) => dispatch({ target: "distanciaTercas", payload: v })}
          value={state.distanciaTercas}
        />
        <NumberInput
          label="Distância Vigas Metálicas (m)"
          md="auto"
          onChange={(v) =>
            dispatch({ target: "distanciaVigasMetalicas", payload: v })
          }
          value={state.distanciaVigasMetalicas}
        />
        <NumberInput
          label="Altura Telhado (m)"
          md="auto"
          onChange={(v) => dispatch({ target: "alturaTelhado", payload: v })}
          value={state.alturaTelhado}
        />
      </Row>
      <Row className="clearfix">
        <Col md="auto">
          <FormGroup
            onChange={({ target }) => {
              dispatch({ target: "acesso", payload: target?.value });
            }}
          >
            <Label>Acesso</Label>
            <div>
              <CustomInput
                type="radio"
                name="acesso_telhado_local"
                id="nao_def_acesso_telhado_local"
                label="Não Definido"
                value="NAO"
                inline
                checked={state.acesso === "NAO"}
              />
              <CustomInput
                type="radio"
                name="acesso_telhado_local"
                id="escada_acesso_telhado_local"
                label="Escada"
                value="ESCA"
                inline
                checked={state.acesso === "ESCA"}
              />
              <CustomInput
                type="radio"
                name="acesso_telhado_local"
                id="andaime_acesso_telhado_local"
                label="Andaime"
                value="ANDA"
                inline
                checked={state.acesso === "ANDA"}
              />
              <CustomInput
                type="radio"
                name="acesso_telhado_local"
                id="plataforma_acesso_telhado_local"
                label="Plataforma"
                value="PLAT"
                inline
                checked={state.acesso === "PLAT"}
              />
            </div>
          </FormGroup>
        </Col>
        <FormButton color="info" md="auto" onClick={handleSubmit}>
          Incluir
        </FormButton>
      </Row>
      <Row>
        <Col md="12">
          <GridLocaisInstalacao />
        </Col>
      </Row>
    </TabPane>
  );
};
