import { toastr } from "react-redux-toastr";
import { docPost } from "../../pdf";
import {
  downloadFileFromBlob,
  formatDateISO,
  viewDownloadPDF,
} from "../../coreUtils";
import unidecode from "unidecode";
import qs from "qs";
import {
  apiDeleteV2,
  apiGetV2,
  apiPostV2,
  apiPutV2,
  apiV2,
  buscarLogoBase64,
} from "../../apiV2";
import { tratarParams } from "../../apiV2";

const URL_BASE = "/nfe";
const URL_BASE_WEB = `${URL_BASE}/web`;
const URL_BASE_CRED_DIFERIMENTO = `${URL_BASE_WEB}/cred_diferimento`;
const URL_BASE_INCLUSAO = `${URL_BASE_WEB}/incluir_nfe`;
const URL_BASE_CARTA_CORRECAO = `${URL_BASE_WEB}/carta_correcao`;

const NotaFiscalService = {
  gerenciamento: {
    alertas: (id) => apiGetV2(`${URL_BASE_WEB}/alertas/${id}/`),
    buscarLogs: (id) => apiGetV2(`${URL_BASE_WEB}/buscar_logs/${id}/`),
    buscarQtdNfesPendEmissao: (dataIni, dataFim) =>
      apiGetV2(`${URL_BASE_WEB}/buscar_qtd_nfs_pend_emissao/`, {
        data_ini: dataIni,
        data_fim: dataFim,
      }),
    imprimirRelacao: (selected) =>
      apiPostV2(
        `${URL_BASE_WEB}/gerar_relacao_canhoto_entrega_nfe/`,
        {
          nfes_relacao: selected.map((e) => ({ id_nfe: e })),
        },
        { successMessage: false }
      ),
    cancelarNFe: (id) => apiPutV2(`${URL_BASE_WEB}/cancelar/`, { id_nfe: id }),
    downloadXML: async (id) =>
      await apiV2
        .get(`${URL_BASE_WEB}/download_xml/${id}/`, {
          responseType: "blob",
        })
        .then(async (ret) => {
          if (ret.data.type === "text/xml") {
            return ret.data;
          } else if (ret.data.type === "application/json") {
            const json_ret = JSON.parse(await ret.data.text());
            toastr.error("Erro", json_ret.msg);
            return false;
          }
        })
        .catch((err) => {
          toastr.error("Erro", err.message);
          return false;
        }),
    downloadXmlContingencia: async (id) =>
      await apiV2
        .get(`${URL_BASE_WEB}/download_xml_contingencia/${id}/`, {
          responseType: "blob",
        })
        .then(async (ret) => {
          if (ret.data.type === "text/xml") {
            return ret.data;
          } else if (ret.data.type === "application/json") {
            const json_ret = JSON.parse(await ret.data.text());
            toastr.error("Erro", json_ret.msg);
            return false;
          }
        })
        .catch((err) => {
          toastr.error("Erro", err.message);
          return false;
        }),
    duplicarNfe: (idNfe) =>
      apiPostV2(`${URL_BASE_WEB}/duplicar_nfe/`, {
        id_nfe: idNfe,
      }),
    listar: (params) => apiGetV2(`${URL_BASE_WEB}/listar/`, params),
    listarImprEtiqueta: (params) =>
      apiGetV2(`${URL_BASE_WEB}/listar_impr_etiqueta/`, params),
    emitir: (id) => apiPostV2(`${URL_BASE_WEB}/emitir/`, { id_nfe: id }),
    emitirCartaCorrecao: (payload) =>
      apiPostV2(`${URL_BASE_WEB}/emitir_carta_correcao/`, payload),
    enviarEmail: (id) =>
      apiPostV2(`${URL_BASE_WEB}/enviar_email/`, {
        id_nfe: id,
      }),
    enviarWhatsapp: (id) =>
      apiPostV2(`${URL_BASE_WEB}/enviar_whatsapp/`, {
        id_nfe: id,
      }),
    exportarXmls: async (dataIni, dataFim, idTipoDoc) => {
      return await apiV2
        .get(`${URL_BASE_WEB}/exportar_xmls/`, {
          responseType: "blob",
          params: tratarParams({
            data_ini: dataIni,
            data_fim: dataFim,
            id_tipo_doc: idTipoDoc,
          }),
          paramsSerializer: (par) =>
            qs.stringify(par, { arrayFormat: "repeat" }),
        })
        .then(async (ret) => {
          if (ret.data.type === "application/zip") {
            return ret.data;
          } else if (ret.data.type === "application/json") {
            const json_ret = JSON.parse(await ret.data.text());
            toastr.error("Erro", json_ret.msg);
            return false;
          }
        })
        .catch((err) => {
          toastr.error("Erro", err.message);
          return false;
        });
    },
    inutilizar: (payload) => apiPutV2(`${URL_BASE_WEB}/inutilizar/`, payload),
  },
  inclusao: {
    atualizarDadosImportacaoItem: (payload) =>
      apiPostV2(
        `${URL_BASE_INCLUSAO}/atualizar_dados_importacao_item/`,
        payload
      ),
    buscarCab: (id) => apiGetV2(`${URL_BASE_INCLUSAO}/buscar_cab/${id}/`),
    buscarChavesRefer: (idNfe) =>
      apiGetV2(`${URL_BASE_INCLUSAO}/buscar_chaves_refer/${idNfe}/`),
    buscarDadosImportacaoItem: (idItem) =>
      apiGetV2(`${URL_BASE_INCLUSAO}/buscar_dados_importacao_item/${idItem}/`),
    buscarItens: (idNfe) =>
      apiGetV2(`${URL_BASE_INCLUSAO}/buscar_itens/${idNfe}/`),
    excluirChaveRefer: (idChaveRefer) =>
      apiDeleteV2(`${URL_BASE_INCLUSAO}/excluir_chave_refer/${idChaveRefer}/`),
    excluirItem: (idItem) =>
      apiDeleteV2(`${URL_BASE_INCLUSAO}/excluir_item/${idItem}/`),
    incluirCab: (payload) =>
      apiPostV2(`${URL_BASE_INCLUSAO}/incluir_cab/`, payload, {
        successMessage: false,
      }),
    incluirChaveRefer: (payload) =>
      apiPostV2(`${URL_BASE_INCLUSAO}/incluir_chave_refer/`, payload, {
        successMessage: false,
      }),
    incluirFaturas: (payload) =>
      apiPostV2(`${URL_BASE_INCLUSAO}/incluir_faturas/`, payload, {
        successMessage: false,
      }),
    incluirItem: (payload) =>
      apiPostV2(`${URL_BASE_INCLUSAO}/incluir_item/`, payload),
    incluirItemIcms: (payload) =>
      apiPostV2(`${URL_BASE_INCLUSAO}/incluir_item_icms/`, payload),
    buscarItem: (idItemNf) =>
      apiGetV2(`${URL_BASE_INCLUSAO}/buscar_item/${idItemNf}/`),
    alterarItem: (payload) =>
      apiPutV2(`${URL_BASE_INCLUSAO}/alterar_item/`, payload, {
        successMessage: false,
      }),
    finalizarInclusao: (payload) =>
      apiPutV2(`${URL_BASE_INCLUSAO}/finalizar_inclusao/`, payload),
    buscarInfoAdic: (idNfe) =>
      apiGetV2(`${URL_BASE_INCLUSAO}/buscar_info_adic/${idNfe}/`),
    atualizarInfoAdic: (payload) =>
      apiPutV2(`${URL_BASE_INCLUSAO}/atualizar_info_adic/`, payload),
  },
  credDiferimento: {
    apurarValores: (dataApuracao) =>
      apiGetV2(`${URL_BASE_CRED_DIFERIMENTO}/apurar_valores/`, {
        data_apuracao: dataApuracao,
      }),
    gerarNfe: (dataApuracao) =>
      apiPostV2(`${URL_BASE_CRED_DIFERIMENTO}/gerar_nfe/`, {
        data_apuracao: formatDateISO(dataApuracao),
      }),
  },
  cartaCorrecao: {
    buscar: (idCartaCorrecao) =>
      apiGetV2(`${URL_BASE_CARTA_CORRECAO}/buscar/${idCartaCorrecao}/`),
    downloadXML: (idCartaCorrecao) =>
      apiV2
        .get(`${URL_BASE_CARTA_CORRECAO}/download_xml/${idCartaCorrecao}/`, {
          responseType: "blob",
        })
        .then(async (ret) => {
          if (ret.data.type === "text/xml") {
            return downloadFileFromBlob(
              ret.data,
              `carta-correcao-${idCartaCorrecao}.xml`
            );
          } else if (ret.data.type === "application/json") {
            const json_ret = JSON.parse(await ret.data.text());
            toastr.error("Erro", json_ret.msg);
            return false;
          }
        })
        .catch((err) => {
          toastr.error("Erro", err.message);
          return false;
        }),
    listar: (idNfe) => apiGetV2(`${URL_BASE_CARTA_CORRECAO}/listar/${idNfe}/`),
    imprimir: async (idCartaCorrecao) => {
      const [ok, ret] = await NotaFiscalService.cartaCorrecao.buscar(
        idCartaCorrecao
      );

      if (ok) {
        const arq = await docPost(
          "/docs_eletron/nota_fiscal/carta_correcao/",
          ret,
          {
            errorMessage: "Erro ao Imprimir Carta de Correção",
          }
        );

        const fileName = `CCe_${ret.dados_carta_correcao.evento}_${ret.dados_carta_correcao.seq_evento}_${ret.dados_nfe.numero}_${ret.dados_nfe.serie}.pdf`;

        viewDownloadPDF(arq, fileName);
      }
    },
  },
  imprimir: async (id) => {
    const [ok, ret] = await apiGetV2(`/nfe/web/buscar_dados_impressao/${id}/`);

    if (!ok) return;

    const logo = await buscarLogoBase64();

    const arq = await docPost(
      "/danfe/",
      { ...ret, logo: logo },
      {
        errorMessage: "Erro ao Gerar DANFe",
      }
    );

    if (!arq) return false;

    const fileName = `DANFe_${ret.numero_nf}_${unidecode(
      ret.destinatario.nome.replaceAll(" ", "_")
    )}.pdf`;

    viewDownloadPDF(arq, fileName);
  },
  imprimirContingencia: async (id) => {
    const [ok, ret] = await apiGetV2(`/nfe/web/buscar_dados_impressao/${id}/`);

    if (!ok) return;

    ret.contingencia = true;

    const logo = await buscarLogoBase64();

    const arq = await docPost(
      "/danfe/",
      { ...ret, logo: logo },
      {
        errorMessage: "Erro ao Gerar DANFe",
      }
    );

    if (!arq) return false;

    const fileName = `DANFe_CONTINGENCIA_${ret.numero_nf}_${unidecode(
      ret.destinatario.nome.replaceAll(" ", "_")
    )}.pdf`;

    viewDownloadPDF(arq, fileName);
  },
  buscarChaveDuplicidade: (id) =>
    apiGetV2(`${URL_BASE_WEB}/busca_chave_duplicidade/${id}/`),
  buscarStatusNFe: (id) => apiGetV2(`${URL_BASE_WEB}/buscar_status_nfe/${id}/`),
  corrigirDuplicidade: (formData) =>
    apiPostV2(`${URL_BASE_WEB}/corrigir_duplicidade/`, formData),
};

export default NotaFiscalService;
