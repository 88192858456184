import React from "react";
import { Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";

export const MarketplacesIntegracoesGrid = ({
  dados,
  excluir,
  selected,
  setSelected,
  onSelectAll,
  openViewer,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "Produto",
      align: "center",
    },
    {
      dataField: "nome",
      text: "Produtos Enviados para o Marketplace",
      align: "left",
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
    },
    {
      dataField: "preco_venda",
      text: "Preço",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "foto_produto",
      text: "Imagem",
      align: "center",
      formatter: (c) => (
        <img
          src={c}
          alt=""
          width={50}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            cursor: "zoom-in",
          }}
          onClick={() => openViewer(c)}
        />
      ),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      excluir={excluir}
      selected={selected}
      onSelectAll={onSelectAll}
      onSelect={setSelected}
      multiselect={true}
    />
  );
};
