import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormCheckbox,
  NumberInput,
} from "../../../../../components";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { showWarning } from "../../../../../components/AlertaModal";
import {
  modalTitleCadastroFlexRegraColaborador,
  routesBaseCadastroFlexRegraColaborador,
} from "../FlexRegraColaborador";

export const CadastroFlexRegraColaboradorModal = ({ 
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [idColaborador, setIdColaborador] = useState(null);
  const [usaLimiteAcresc, setUsaLimiteAcresc] = useState(false);
  const [percLimiteAcresc, setPercLimiteAcresc] = useState(0);
  const [usaLimiteDeduc, setUsaLimiteDeduc] = useState(false); 
  const [percLimiteDeduc, setPercLimiteDeduc] = useState(0);
  


  const limparDados = () => {
    setIdColaborador(null);
    setUsaLimiteAcresc(false);
    setPercLimiteAcresc(0);
    setUsaLimiteDeduc(false);
    setPercLimiteDeduc(0);
  };

  const fetchData = (ret) => { 
    
    setIdColaborador(ret.id_colaborador);
    setUsaLimiteAcresc(ret.usa_limite_acresc);
    setPercLimiteAcresc(parseFloat(ret.perc_limite_acresc));
    setUsaLimiteDeduc(ret.usa_limite_deduc);
    setPercLimiteDeduc(parseFloat(ret.perc_limite_deduc));
  };

  const handleSelectColaborador = (selected) => { 
    setIdColaborador(selected?.value);
  };

  const submitPayload = (action) => { 
    
    if ([0, null, undefined].includes(idColaborador)) {
      showWarning("Selecione o colaborador e tente novamente.", null, null, 'async-colaborador');
      return false;
    }

    if (usaLimiteAcresc === true && [null, undefined].includes(percLimiteAcresc)) {
      showWarning(
        "Informe o limite percentual de acréscimo e tente novamente.", null, null, 'input-limite-acre'
      );
      return false;
    }

    if (usaLimiteDeduc === true && [null, undefined].includes(percLimiteDeduc)) {
      showWarning(
        "Informe o limite percentual de dedução e tente novamente.", null, null, 'input-limite-deduc'
      );
      return false;
    }

    return action === MODAL_ACTIONS.ADD 
      ? {
          id_colaborador: idColaborador,
          usa_limite_acresc: usaLimiteAcresc,
          perc_limite_acresc: percLimiteAcresc,
          usa_limite_deduc: usaLimiteDeduc,
          perc_limite_deduc: percLimiteDeduc,
        }
      : {
          id_regra: selected,
          usa_limite_acresc: usaLimiteAcresc,
          perc_limite_acresc: Number.parseFloat(percLimiteAcresc),
          usa_limite_deduc: usaLimiteDeduc,
          perc_limite_deduc: Number.parseFloat(percLimiteDeduc),
        };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleCadastroFlexRegraColaborador}
      size="sm"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      routesBase={routesBaseCadastroFlexRegraColaborador}
      number="0116_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <AsyncComboBox
          autoFocus
          sm={12}
          isConcatField
          concatModelName="colaborador"
          defaultOptions
          label="Colaborador"
          isSearchable
          isClearable
          name="colaborador"
          onChange={handleSelectColaborador}
          defaultValue={idColaborador}
          inputName={'input-colaborador'}
        />
      </Row>
      <Row>
        <FormCheckbox
          label="Limite de Acréscimo"
          checked={usaLimiteAcresc} 
          onChange={(v) => {
            setUsaLimiteAcresc(!usaLimiteAcresc);
            setPercLimiteAcresc(0); 
          }}
        />
        <NumberInput
          md={5}
          label="Limite de Acréscimo"
          isPercentage
          disabled={!usaLimiteAcresc} 
          value={percLimiteAcresc}
          decimalPlaces={2}
          divClassName="ml-auto"
          onChange={setPercLimiteAcresc} 
          name={'input-limite-acre'}
        />
      </Row>
      <Row>
        <FormCheckbox
          label="Limite de Dedução"
          checked={usaLimiteDeduc} 
          onChange={() => {
            setUsaLimiteDeduc(!usaLimiteDeduc); 
            setPercLimiteDeduc(0) 
          }}
        />
        <NumberInput
          md={5}
          label="Limite de Dedução"
          isPercentage
          disabled={!usaLimiteDeduc}
          value={percLimiteDeduc}
          decimalPlaces={2}
          divClassName="ml-auto"
          onChange={setPercLimiteDeduc} 
          name={'input-limite-deduc'}
        />
      </Row>
    </ModalCadastroV2>
  );
};