import React, { cloneElement, useEffect, useRef, useState } from "react";
import { Edit2 } from "react-feather";
import { IconButton } from "./IconButton";
import FormButton from "./FormButton";

export const UnlockToEdit = ({
  children,
  enabled = true,
  hint = "Clique para editar",
  locked,
  lockedHint,
  tooltipPlacement = "right",
  iconSize = 18,
  onBeforeLock,
  isButton = false,
  buttonLabel,
}) => {
  const [inputLocked, setInputLocked] = useState(true);
  const ref = useRef();

  const toggleLock = () => {
    setInputLocked(!inputLocked);
    if (inputLocked) {
      if (ref.current.focus) {
        ref.current.focus();
      } else if (ref.current.setFocus) {
        ref.current.setFocus();
      }
    }
  };

  const onBlurInternal = async (element, ...args) => {
    if (onBeforeLock) {
      const ok = await onBeforeLock();
      if (ok === false) {
        return;
      }
    }
    setInputLocked(true);
    if (element?.props?.onBlur) {
      element.props.onBlur(...args);
    }
  };

  useEffect(() => {
    if (locked) {
      setInputLocked(true);
    }
  }, [locked]);

  return enabled
    ? cloneElement(children, {
        disabled: inputLocked,
        ref: ref,
        onBlur: (...args) => onBlurInternal(children, ...args),
        additionalButton:
          isButton && buttonLabel ? (
            <FormButton
              color="primary"
              padded={false}
              onClick={toggleLock}
              md="auto"
              hint={hint}
              disabled={locked}
              disabledHint={lockedHint}
            >
              {buttonLabel}
            </FormButton>
          ) : (
            <IconButton
              icon={Edit2}
              size={iconSize}
              onClick={toggleLock}
              style={{ marginBlock: "auto", marginLeft: "0.4rem" }}
              hint={hint}
              disabled={locked}
              disabledHint={lockedHint}
              tooltipPlacement={tooltipPlacement}
            />
          ),
      })
    : children;
};
