import classNames from "classnames";
import React from "react";

export const Divider = ({
  className,
  textStyle,
  textClassName,
  lineStyle,
  children,
}) => (
  <div className={classNames("divider", className)}>
    {children && (
      <div
        className={classNames("divider-text", textClassName)}
        style={textStyle}
      >
        {children}
      </div>
    )}
    <hr className="divider-line" style={lineStyle} />
  </div>
);
