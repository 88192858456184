import React, { useRef, useState } from "react";
import {
  FixedField,
  FormButton,
  ModalBase,
  NumberInput,
  Table,
  TableDelete,
} from "../../../../components";
import FracionamentoNfEntradaService from "../../../../services/compras/FracionamentoNfEntradaService";
import { showWarning } from "../../../../components/AlertaModal";
import { Row } from "reactstrap";
import {
  formatNumber,
  formatValueFromAPI,
  MODAL_ACTIONS,
  roundFloat,
  sumDataField,
  useStateWithRef,
} from "../../../../coreUtils";
import PesqProduto from "../../../../components/form/pesq_produto/PesqProduto";
import { incluirAlterarProdutoRoute } from "../../../../routes/modules/cadastro";
import CadastroProdutosService from "../../../../services/cadastro/CadastroProdutosService";

export const FracionarItemModal = ({
  isOpen,
  toggle,
  idItemFracionar,
  notifyEvent,
}) => {
  const [utilizaPrecoAtacado, setUtilizaPrecoAtacado] = useState(false);
  const [idProdutoNf, setIdProdutoNf] = useState(null);
  const [nomeProdutoNf, setNomeProdutoNf] = useState(null);
  const [qtdItemNf, setQtdItemNf] = useState(null);
  const [vlrTotBrutoItemNf, setVlrTotBrutoItemNf] = useState(null);
  const [idProdutoFrac, setIdProdutoFrac] = useState(null);
  const [nomeProdutoFrac, setNomeProdutoFrac] = useState(null);
  const [unidadeFrac, setUnidadeFrac] = useState(null);
  const [quantidadeFrac, setQuantidadeFrac] = useState(null);
  const [casasDecimaisQtdFrac, setCasasDecimaisQtdFrac] = useState(2);
  const [casasDecimaisVlrFrac, setCasasDecimaisVlrFrac] = useState(4);
  const [custoFrac, setCustoFrac] = useState(null);
  const [precoVendaFrac, setPrecoVendaFrac] = useState(null);
  const [precoAtacadoFrac, setPrecoAtacadoFrac] = useState(null);
  const [produtos, setProdutos, produtosRef] = useStateWithRef([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const [custoRepetir, setCustoRepetir] = useState(null);

  const pesqProdRef = useRef();
  const quantidadeRef = useRef();

  const limparDados = () => {
    setIdProdutoNf(null);
    setNomeProdutoNf(null);
    setQtdItemNf(null);
    setVlrTotBrutoItemNf(null);
    setCustoFrac(null);
    setProdutos([]);
    setUtilizaPrecoAtacado(false);
    limparDadosProdFrac();
    setSelected(null);
  };

  const limparDadosProdFrac = () => {
    setIdProdutoFrac(null);
    setNomeProdutoFrac(null);
    setUnidadeFrac(null);
    setQuantidadeFrac(null);
    setCustoFrac(null);
    setCasasDecimaisQtdFrac(2);
    setCasasDecimaisVlrFrac(4);
    setPrecoVendaFrac(null);
    setPrecoAtacadoFrac(null);
  };

  const carregarDados = async () => {
    const [ok, ret] = await FracionamentoNfEntradaService.buscarItemFracionar(
      idItemFracionar
    );
    if (ok) {
      if (ret.fracionado) {
        showWarning(
          "O Item selecionado já foi fracionado",
          null,
          null,
          "input-prod-fracionado"
        );
        notifyEvent();
        return false;
      }
      setIdProdutoNf(ret.id_produto);
      setNomeProdutoNf(ret.nome_produto);
      setQtdItemNf(parseFloat(ret.quantidade));
      setVlrTotBrutoItemNf(parseFloat(ret.vlr_tot_bruto));
      return true;
    } else {
      return false;
    }
  };

  const handleSelectProduto = async ({
    idProduto: id,
    nomeProduto: nome,
    referencia,
    unidade,
    casasDecimaisQtd,
    casasDecimaisVlrs,
    precoVenda,
    precoAtacado,
  }) => {
    if (id) {
      setIdProdutoFrac(id);
      setNomeProdutoFrac(nome);
      setUnidadeFrac(unidade);
      setCasasDecimaisQtdFrac(casasDecimaisQtd);
      setCasasDecimaisVlrFrac(casasDecimaisVlrs);
      setPrecoVendaFrac(precoVenda);
      setPrecoAtacadoFrac(precoAtacado);
      pesqProdRef.current.setId(String(id));
      pesqProdRef.current.setDescricao(nome);
      pesqProdRef.current.setReferencia(String(referencia));
    } else {
      setIdProdutoFrac(null);
      setNomeProdutoFrac(null);
      setUnidadeFrac(null);
      setCasasDecimaisQtdFrac(2);
      setCasasDecimaisVlrFrac(4);
      setPrecoVendaFrac(null);
      setPrecoAtacadoFrac(null);
      pesqProdRef.current.clear();
    }
  };

  const handleSetQuantidade = (v) => {
    setQuantidadeFrac(v);

    if (v > 0) {
      setCustoFrac(roundFloat(vlrTotBrutoItemNf / v, 4));
    }
  };

  const handleAdd = () => {
    if ([0, null, undefined].includes(idProdutoFrac)) {
      showWarning(
        "Por favor, informe o Produto",
        null,
        null,
        "input-prod-fracionado"
      );
      return;
    }

    if ([0, null, undefined].includes(quantidadeFrac)) {
      showWarning(
        "Por favor, informe a Quantidade",
        null,
        null,
        "input-quantidade"
      );
      return;
    }

    if ([0, null, undefined].includes(custoFrac)) {
      showWarning(
        "Por favor, informe o custo do item",
        null,
        null,
        "input-custo"
      );
      return;
    }

    if (produtos.some((e) => e.id_produto === idProdutoFrac)) {
      showWarning(
        "O Produto informado já foi adicionado",
        null,
        null,
        "input-prod-fracionado"
      );
      return;
    }

    const novoPercLucro = roundFloat((precoVendaFrac / custoFrac - 1) * 100, 2);
    const novoPercLucroAtacado = roundFloat(
      (precoAtacadoFrac / custoFrac - 1) * 100,
      2
    );

    const payload = {
      id_produto: idProdutoFrac,
      nome_produto: nomeProdutoFrac,
      unidade: unidadeFrac,
      quantidade: quantidadeFrac,
      custo: custoFrac,
      custo_total: roundFloat(quantidadeFrac * custoFrac, 2),
      casas_decimais_qtd: casasDecimaisQtdFrac,
      casas_decimais_vlr: casasDecimaisVlrFrac,
      novo_perc_lucro: novoPercLucro,
      preco_venda: precoVendaFrac,
      novo_perc_lucro_atacado: novoPercLucroAtacado,
      preco_atacado: precoAtacadoFrac,
    };

    setProdutos([...produtos, payload]);
    limparDadosProdFrac();
    if (pesqProdRef.current) {
      pesqProdRef.current.clear();
      pesqProdRef.current.focus();
    }
  };

  const handleDelete = (idProduto) => {
    setProdutos(produtos.filter((e) => e.id_produto !== idProduto));
  };

  const handleAlterarProduto = (coluna, novoValor, row) => {
    const casasDecimais =
      coluna === "quantidade" ? row.casas_decimais_qtd : row.casas_decimais_vlr;
    const valorAntesTest = roundFloat(parseFloat(row[coluna]), casasDecimais);
    const novoValorTest = roundFloat(parseFloat(novoValor), casasDecimais);
    if (valorAntesTest === novoValorTest) {
      return;
    }

    if (coluna === "quantidade") {
      row.quantidade = parseFloat(novoValor);
    } else if (coluna === "custo") {
      row.custo = parseFloat(novoValor);

      row.novo_perc_lucro = roundFloat(
        (row.preco_venda / row.custo - 1) * 100,
        2
      );
      row.novo_perc_lucro_atacado = roundFloat(
        (row.preco_atacado / row.custo - 1) * 100,
        2
      );
    }

    row.custo_total = roundFloat(row.quantidade * row.custo, 2);

    setProdutos(
      produtos.map((e) => (e.id_produto === row.id_produto ? row : e))
    );
  };

  const handleSubmit = async () => {
    if (produtos.length === 0) {
      showWarning(
        "Por favor, informe ao menos um Produto para o fracionamento",
        null,
        null,
        "input-prod-fracionado"
      );
      return;
    }

    setLoading(true);
    const payload = {
      id_nf_entrada_item: idItemFracionar,
      produtos: produtos.map((e) => ({
        id_produto: e.id_produto,
        quantidade: e.quantidade,
        custo: e.custo,
      })),
    };
    const [ok] = await FracionamentoNfEntradaService.fracionarItem(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  const onBeforeOpen = async (params) => {
    const ok = await carregarDados();
    setUtilizaPrecoAtacado(params?.usa_preco_atacado ?? false);
    if (!ok) toggle();
  };

  const columns = [
    { dataField: "id_produto", text: "Produto", align: "center" },
    { dataField: "nome_produto", text: "Nome", align: "left" },
    { dataField: "unidade", text: "UN", align: "center" },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      formatter: (c, row) =>
        formatNumber(c, true, row.casas_decimais_qtd, true),
      editable: true,
      editorType: "number",
      onChange: handleAlterarProduto,
      decimalPlaces: (row) => row.casas_decimais_qtd,
      alwaysShowEditor: true,
      colWidth: "65px",
      selectOnClick: false,
    },
    {
      dataField: "custo",
      text: "Custo Unit.",
      align: "right",
      formatter: (c, row) => formatNumber(c, true, row.casas_decimais_vlr),
      editable: true,
      editorType: "number",
      onChange: handleAlterarProduto,
      decimalPlaces: (row) => row.casas_decimais_vlr,
      alwaysShowEditor: true,
      selectOnClick: false,
    },
    {
      dataField: "custo_total",
      text: "Custo Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "novo_perc_lucro",
      text: "Novo % Lucro",
      align: "right",
      formatter: (c) => `${formatNumber(c, true, 2)} %`,
    },
    {
      dataField: "preco_venda",
      text: "Vlr. Venda",
      align: "right",
      formatter: (c, row) => (
        <span
          style={parseFloat(row.novo_perc_lucro) < 0 ? { color: "red" } : {}}
        >
          {formatNumber(c, true, 2)}
        </span>
      ),
    },
    {
      dataField: "novo_perc_lucro_atacado",
      text: "Novo % Lucro Atacado",
      align: "right",
      formatter: (c) => `${formatNumber(c, true, 2)} %`,
      hidden: !utilizaPrecoAtacado,
    },
    {
      dataField: "preco_atacado",
      text: "Vlr. Atacado",
      align: "right",
      formatter: (c, row) => (
        <span
          style={
            parseFloat(row.novo_perc_lucro_atacado) < 0 ? { color: "red" } : {}
          }
        >
          {formatNumber(c, true, 2)}
        </span>
      ),
      hidden: !utilizaPrecoAtacado,
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      selectOnClick: false,
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.id_produto)} />
      ),
    },
  ];

  const repetirCustoTodosItens = () => {
    if ([0, null, undefined].includes(custoRepetir)) {
      showWarning(
        "Por favor, informe o custo a ser repetido em todos os itens",
        "",
        [],
        "input-custo-repetir"
      );
      return;
    }

    const novosProdutos = [];
    for (const produto of produtos) {
      produto.custo = custoRepetir;
      produto.custo_total = roundFloat(custoRepetir * produto.quantidade, 2);

      produto.novo_perc_lucro = roundFloat(
        (produto.preco_venda / produto.custo - 1) * 100,
        2
      );
      produto.novo_perc_lucro_atacado = roundFloat(
        (produto.preco_atacado / produto.custo - 1) * 100,
        2
      );

      novosProdutos.push(produto);
    }

    setProdutos(novosProdutos);
  };

  const atualizarPrecosProduto = (
    idProdutoAjustar,
    precoVendaAjustar,
    precoAtacadoAjustar
  ) => {
    setProdutos([
      ...produtosRef.current.map((row) => {
        if (row.id_produto === idProdutoAjustar) {
          row.preco_venda = precoVendaAjustar;
          row.preco_atacado = precoAtacadoAjustar;

          row.novo_perc_lucro = roundFloat(
            (row.preco_venda / row.custo - 1) * 100,
            2
          );
          row.novo_perc_lucro_atacado = roundFloat(
            (row.preco_atacado / row.custo - 1) * 100,
            2
          );
        }

        return row;
      }),
    ]);
  };

  const alterarCadastroProduto = () => {
    const id = Math.floor(Math.random() * Date.now());
    const cadastro = window.open(incluirAlterarProdutoRoute.path);

    cadastro.window.parameters = JSON.stringify({
      id: id,
      action: MODAL_ACTIONS.EDIT,
      selected: selected,
    });

    const func = async (event) => {
      if (event.origin !== window.location.origin && !event.data?.selected) {
        return;
      }

      if (event.data.id !== id) {
        return;
      }

      const [ok, ret] = await CadastroProdutosService.pesquisa(
        undefined,
        event.data.selected
      );
      if (ok) {
        atualizarPrecosProduto(
          event.data.selected,
          parseFloat(ret[0].preco_venda || 0),
          parseFloat(ret[0].preco_atacado || 0)
        );
      }

      window.removeEventListener("message", func);
    };

    window.addEventListener("message", func);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      title="Fracionar Item da NF Entrada"
      number="0110_1"
      onClosed={limparDados}
      onBeforeOpen={onBeforeOpen}
      onConfirm={handleSubmit}
      paramsName="fracionamento"
      loadingConfirm={loading}
      numberStyle={{ marginRight: "7px", paddingTop: "12px" }}
      paddedButtons
      footerActions={
        <>
          <NumberInput
            md={2}
            label="Custo Unitário"
            value={custoRepetir}
            onChange={setCustoRepetir}
            name="input-custo-repetir"
            divClassName="mx-0"
          />
          <FormButton
            md="auto"
            color="info"
            divClassName="mr-auto ml-0"
            onClick={repetirCustoTodosItens}
          >
            Definir Custo em Todos os Itens
          </FormButton>
        </>
      }
    >
      <Row className="mb-2">
        <FixedField
          label="Produto da NFe"
          value={formatValueFromAPI(nomeProdutoNf, idProdutoNf)}
          horizontal
        />
        <FixedField
          label="Qtd Total na NFe"
          value={formatNumber(qtdItemNf, true, 2, true)}
          horizontal
        />
        <FixedField
          label="Valor Total na NFe"
          value={formatNumber(vlrTotBrutoItemNf, true, 2)}
          horizontal
        />
      </Row>
      <Row>
        <PesqProduto
          mdIdent={3}
          md={10}
          onConfirm={handleSelectProduto}
          ref={pesqProdRef}
          selectNextField={() =>
            quantidadeRef.current && quantidadeRef.current.focus()
          }
          onChangeDescricao={() => {}}
          autoFocus
          name={"input-prod-fracionado"}
        />
        <FixedField
          label="Unidade"
          value={unidadeFrac}
          horizontal
          divClassName="pt-4"
        />
      </Row>
      <Row className="mb-2">
        <NumberInput
          md={2}
          label="Quantidade"
          value={quantidadeFrac}
          onChange={handleSetQuantidade}
          ref={quantidadeRef}
          decimalPlaces={casasDecimaisQtdFrac}
          name={"input-quantidade"}
        />
        <NumberInput
          md={2}
          label="Custo Unitário"
          value={custoFrac}
          onChange={setCustoFrac}
          decimalPlaces={casasDecimaisVlrFrac}
          name={"input-custo"}
        />
        <FormButton color="success" onClick={handleAdd}>
          Incluir
        </FormButton>
        <FormButton
          divClassName="ml-auto pr-0"
          color="warning"
          onClick={alterarCadastroProduto}
          disabled={[0, null, undefined].includes(selected)}
        >
          Alterar Cadastro Produto
        </FormButton>
      </Row>
      <Table
        data={produtos}
        columns={columns}
        paginated={false}
        pageSize={10}
        showRegisterCount={false}
        onSelect={setSelected}
        keyField="id_produto"
      />
      <Row>
        <FixedField
          label="Lançado"
          value={`${formatNumber(
            sumDataField(produtos, "quantidade"),
            true,
            2,
            true
          )} - R$ ${formatNumber(
            sumDataField(produtos, "custo_total"),
            true,
            2
          )}`}
          horizontal
        />
      </Row>
    </ModalBase>
  );
};
