import React from "react";
import { Card, Row } from "reactstrap";
import { Table } from "../../../../../components";
import { TableDelete } from "../../../../../components/TableDelete";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
  naturalSortDate,
  sumDataField,
} from "../../../../../coreUtils";

export const columnsRemessa = [
  {
    dataField: "nome_cliente",
    text: "Cliente",
    align: "left",
    sortable: true,
    formatter: (cell, row) => formatValueFromAPI(cell, row.id_cliente),
  },
  {
    dataField: "numero",
    text: "Número/Parcela",
    align: "center",
    sortable: true,
    formatter: (c, row) => `${c}-${row.parcela}`,
  },
  {
    dataField: "nosso_nro",
    text: "Nosso Número",
    align: "center",
  },
  {
    dataField: "emissao",
    text: "Emissão",
    align: "center",
    sortable: true,
    sortFunc: naturalSortDate,
  },
  {
    dataField: "vencimento",
    text: "Vencimento",
    align: "center",
    sortable: true,
    sortFunc: naturalSortDate,
  },
  {
    dataField: "vlr_receber",
    text: "Valor",
    align: "right",
    sortable: true,
    formatter: (c) => formatNumber(c, true, 2),
    sortFunc: naturalSort,
  },
];

export const TitulosRemessaGrid = ({
  duplicatas = [],
  handleOnSelect,
  handleOnSelectAll,
  selected,
  removeDups,
}) => {
  const qtdTitulos = (duplicatas ?? []).length;

  return (
    <Card body>
      <Row className="mx-0 mb-2">
        <strong className="mt-1">
          EM REMESSA - {qtdTitulos} titulo{qtdTitulos === 1 ? "" : "s"} | Valor
          Total:{" "}
          {formatNumber(sumDataField(duplicatas, "vlr_receber"), true, 2)}
        </strong>
        <TableDelete
          onClick={removeDups}
          disabled={selected?.length === 0}
          color="black"
          className="ml-auto mr-1"
          size={20}
        />
      </Row>
      <Table
        data={duplicatas}
        columns={columnsRemessa}
        multiselect
        onSelect={handleOnSelect}
        onSelectAll={handleOnSelectAll}
        selected={selected}
        pageSize={10}
        showRegisterCount={false}
      />
    </Card>
  );
};
