import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { Row } from "reactstrap";
import {
  FormButton,
  NumberInput,
  TextInput,
  ModalBase,
  FixedField,
  IconButton,
} from "../../../../../../../components";
import {
  formatNumber,
  formatValueFromAPI,
  MODAL_ACTIONS,
  roundFloat,
} from "../../../../../../../coreUtils";
import UteisService from "../../../../../../../services/uteis/UteisService";
import PesqProduto from "../../../../../../../components/form/pesq_produto/PesqProduto";
import ModalAlterarTipoItem from "./ModalAlterarTipoItem";
import { showWarning } from "../../../../../../../components/AlertaModal";
import { PRECO_ATACADO, PRECO_VENDA } from "../../../../../../../flags";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import CadastroProdutosService from "../../../../../../../services/cadastro/CadastroProdutosService";

export const TIPO_ITEM_VENDA = "VDA";
export const TIPO_ITEM_TROCA = "TRO";
export const TIPO_ITEM_BONIFICACAO = "BON";

const DESCRICOES_TIPOS = {
  [TIPO_ITEM_VENDA]: "Venda",
  [TIPO_ITEM_TROCA]: "Troca",
  [TIPO_ITEM_BONIFICACAO]: "Bonificação",
};

const initialProdutoState = {
  tipo: TIPO_ITEM_VENDA,
  idProduto: null,
  nomeProduto: "",
  qtdEstoque: 0,
  revenda: false,
  nroOrdemCompra: null,
  cfop: "",
  quantidade: 0,
  precoVendaProd: 0,
  precoAtacadoProd: 0,
  vlrUnit: 0,
  vlrItem: 0,
  percDesc: 0,
  vlrTotDesc: 0,
  vlrTotAcre: 0,
  vlrIcmsStUnit: 0,
  vlrTotIcmsSt: 0,
  percIpi: 0,
  vlrTotIpi: 0,
  vlrTotProd: 0,
  vlrTotal: 0,
  vlrTotTroca: 0,
  vlrTotBonificacao: 0,
};

const RECALCULAR_POR_QUANTIDADE = "QUANTIDADE";
const RECALCULAR_POR_PERC_DESC = "PERC_DESC";
const RECALCULAR_POR_VLR_ITEM = "VLR_ITEM";
const RECALCULAR_POR_VLR_TOT_PROD = "VLR_TOT_PROD";

export const IncluirAlterarProdutoModal = forwardRef(
  (
    {
      idCliente,
      obrigInfoOrdemCompraNfe,
      vlrFrete,
      handleAdd,
      handleEdit,
      itens,
      permiteManipularVlrUnitario,
      usaModeloNfeCliente,
      modeloNfeCliente,
      usaPrecoAtacado,
    },
    ref
  ) => {
    const [action, setAction] = useState(MODAL_ACTIONS.ADD);
    const [isOpen, setIsOpen] = useState(false);
    const [precoEmUso, setPrecoEmUso] = useState(PRECO_VENDA);
    const [dadosProduto, setDadosProduto] = useState(initialProdutoState);
    const [qtdCasasDecimaisQtd, setQtdCasasDecimaisQtd] = useState(2);
    const [qtdCasasDecimaisVlrs, setQtdCasasDecimaisVlrs] = useState(4);
    const refPesqProd = useRef();
    const quantidadeRef = useRef(null);
    const semProduto = [0, null, undefined].includes(dadosProduto.idProduto);

    const [recalculandoValoresItens, setRecalculandoValoresItens] =
      useState(false);

    const handleChangeProduto = useCallback(
      (novosDados = {}) => {
        setDadosProduto({
          ...dadosProduto,
          ...novosDados,
        });
      },
      [dadosProduto]
    );

    const limparDados = () => {
      setDadosProduto(initialProdutoState);
      if (refPesqProd.current) {
        refPesqProd.current.clear();
      }
    };

    const carregarDados = async (idProdutoEdit, tipoProdutoEdit) => {
      const data = itens.find(
        (e) => e.id_produto === idProdutoEdit && e.tipo === tipoProdutoEdit
      );

      const [ok, ret] = await CadastroProdutosService.buscarDadosConsPreco(
        idProdutoEdit
      );
      if (!ok) {
        toggle();
      }

      handleChangeProduto({
        tipo: data.tipo,
        idProduto: data.id_produto,
        nomeProduto: data.nome_produto,
        revenda: data.revenda,
        nroOrdemCompra: data.nro_ord_compra,
        cfop: data.cfop,
        quantidade: data.quantidade,
        vlrUnit: data.vlr_unit,
        vlrItem: data.vlr_item,
        percDesc: data.perc_desc,
        vlrTotDesc: data.vlr_tot_desc,
        vlrTotAcre: data.vlr_tot_acre,
        vlrIcmsStUnit: data.vlr_icms_st_unit,
        vlrTotIcmsSt: data.vlr_tot_icms_st,
        percIpi: data.perc_ipi,
        vlrTotIpi: data.vlr_tot_ipi,
        vlrTotProd: data.vlr_tot_prod,
        vlrTotal: data.vlr_total,
        vlrTotTroca: data.vlr_tot_troca,
        vlrTotBonificacao: data.vlr_tot_bonif,
        precoAtacadoProd: parseFloat(ret.preco_atacado ?? "0"),
        precoVendaProd: parseFloat(ret.preco_venda ?? "0"),
      });

      setQtdCasasDecimaisQtd(parseInt(ret?.casas_decimais_qtd ?? 2));
      setQtdCasasDecimaisVlrs(parseInt(ret?.casas_decimais_vlr ?? 4));
    };

    const toggle = (action, idProdutoEdit, tipoProdutoEdit) => {
      if (action === MODAL_ACTIONS.EDIT) {
        carregarDados(idProdutoEdit, tipoProdutoEdit);
      }
      setAction(action);
      setIsOpen(!isOpen);
    };

    const calcularImpostos = async (idProduto, quantidade, vlrItem, cfop) => {
      const _vlrTotProd = vlrItem * quantidade;

      const novosDadosProd = {};

      let ok = false;

      if (dadosProduto.tipo === "VDA") {
        let modeloNf = 55;

        if (usaModeloNfeCliente === true && modeloNfeCliente === "NFC") {
          modeloNf = 65;
        }

        const [okImposto, trib] = await UteisService.calcularImpostosProdutoV2(
          idProduto,
          idCliente,
          "VENDA",
          modeloNf,
          true,
          cfop,
          quantidade,
          _vlrTotProd,
          vlrFrete
        );

        ok = okImposto;

        if (okImposto) {
          const _vlrIcmsSt = parseFloat(trib.vlr_icms_st);
          const _vlrIpi = parseFloat(trib.vlr_ipi);
          const _percIpi = parseFloat(trib.perc_ipi);
          const _vlrTotal = parseFloat(trib.vlr_total_com_impostos);

          novosDadosProd.vlrIcmsStUnit =
            quantidade > 0 ? roundFloat(_vlrIcmsSt / quantidade, 2) : 0;
          novosDadosProd.vlrIcmsSt = roundFloat(_vlrIcmsSt, 2);
          novosDadosProd.percIpi = roundFloat(_percIpi, 2);
          novosDadosProd.vlrIpi = roundFloat(_vlrIpi, 2);
          novosDadosProd.vlrTotProd = roundFloat(_vlrTotProd, 2);
          novosDadosProd.vlrTotal = roundFloat(_vlrTotal, 2);
          novosDadosProd.vlrTotTroca = 0;
          novosDadosProd.vlrTotBonificacao = 0;
        }
      } else if (dadosProduto.tipo === "TRO") {
        novosDadosProd.vlrTotTroca = _vlrTotProd;
        ok = true;
      } else if (dadosProduto.tipo === "BON") {
        novosDadosProd.vlrTotBonificacao = _vlrTotProd;
        ok = true;
      }

      return [ok, novosDadosProd];
    };

    const handleSelectProduto = async ({
      idProduto: id,
      nomeProduto: nome,
      precoVenda,
      precoAtacado,
      cfop,
      quantidade,
      casasDecimaisQtd,
      casasDecimaisVlrs,
      curinga,
    }) => {
      // Não deixa alterar o produto na alteração do item
      if (action === MODAL_ACTIONS.EDIT) return;

      if (dadosProduto.idProduto !== id) {
        if (id) {
          const vUnit =
            precoEmUso === PRECO_ATACADO ? precoAtacado : precoVenda;

          const [ok, retImpostos] = await calcularImpostos(id, 1, vUnit, cfop);
          if (ok) {
            setQtdCasasDecimaisQtd(casasDecimaisQtd);
            setQtdCasasDecimaisVlrs(casasDecimaisVlrs);

            const novosDadosProd = {
              idProduto: id,
              nomeProduto: nome,
              qtdEstoque: quantidade,
              precoVendaProd: precoVenda,
              precoAtacadoProd: precoAtacado,
              vlrUnit: roundFloat(vUnit, 10),
              vlrItem: roundFloat(vUnit, 10),
              quantidade: 1,
              percDesc: 0,
              vlrTotDesc: 0,
              vlrTotAcre: 0,
              cfop: cfop,
              ...retImpostos,
            };

            handleChangeProduto(novosDadosProd);

            if (refPesqProd.current) {
              refPesqProd.current.setId(String(id));
              refPesqProd.current.setDescricao(nome);
            }
            if (!curinga) {
              selectNextField();
            }
          }
        } else {
          limparDados();
        }
      }
    };

    const selectNextField = () => {
      setTimeout(() => {
        if (quantidadeRef.current) {
          quantidadeRef.current.focus();
        }
      }, 35);
    };

    const recalcularValoresItem = async (novosValores, recalcularPor) => {
      setRecalculandoValoresItens(true);
      try {
        const quantidade = novosValores.quantidade ?? dadosProduto.quantidade;
        const vlrUnit = novosValores.vlrUnit ?? dadosProduto.vlrUnit;
        const vlrItem = novosValores.vlrItem ?? dadosProduto.vlrItem;
        const percDesc = novosValores.percDesc ?? dadosProduto.percDesc;
        const vlrTotProd = novosValores.vlrTotProd ?? dadosProduto.vlrTotProd;

        let valorIgual = [
          recalcularPor === RECALCULAR_POR_QUANTIDADE &&
            quantidade === dadosProduto.quantidade,
          recalcularPor === RECALCULAR_POR_VLR_ITEM &&
            vlrItem === dadosProduto.vlrItem,
          recalcularPor === RECALCULAR_POR_PERC_DESC &&
            percDesc === dadosProduto.percDesc,
          recalcularPor === RECALCULAR_POR_VLR_TOT_PROD &&
            vlrTotProd === dadosProduto.vlrTotProd,
        ].some((e) => e);

        if (valorIgual) {
          return;
        }

        let novosDadosProd = { vlrUnit: vlrUnit };

        if (recalcularPor === RECALCULAR_POR_QUANTIDADE) {
          novosDadosProd.quantidade = quantidade;

          const vlrTotBruto = roundFloat(vlrUnit * quantidade, 2);
          const vlrTotProd = roundFloat(vlrItem * quantidade, 2);

          if (vlrItem <= vlrUnit) {
            novosDadosProd.vlrTotDesc = vlrTotBruto - vlrTotProd;
            novosDadosProd.vlrAcre = 0;
          } else {
            novosDadosProd.percDesc = 0;
            novosDadosProd.vlrTotDesc = 0;
            novosDadosProd.vlrTotAcre = roundFloat(
              (vlrItem - vlrUnit) * quantidade,
              2
            );
          }
        } else if (recalcularPor === RECALCULAR_POR_VLR_ITEM) {
          novosDadosProd.vlrItem = vlrItem;

          if (vlrItem <= vlrUnit) {
            const vDesc = vlrUnit - vlrItem;
            novosDadosProd.percDesc =
              vlrUnit > 0 ? roundFloat((vDesc / vlrUnit) * 100, 2) : 0;
            novosDadosProd.vlrTotDesc = roundFloat(vDesc * quantidade, 2);
            novosDadosProd.vlrTotAcre = 0;
          } else {
            novosDadosProd.percDesc = 0;
            novosDadosProd.vlrTotDesc = 0;
            novosDadosProd.vlrTotAcre = roundFloat(
              (vlrItem - vlrUnit) * quantidade,
              2
            );
          }
        } else if (recalcularPor === RECALCULAR_POR_PERC_DESC) {
          if (vlrUnit > 0) {
            const vDesc = roundFloat(
              (percDesc / 100) * vlrUnit,
              qtdCasasDecimaisVlrs
            );
            const vItem = roundFloat(vlrUnit - vDesc, qtdCasasDecimaisVlrs);

            novosDadosProd.percDesc = percDesc;
            novosDadosProd.vlrItem = vItem;
            novosDadosProd.vlrTotDesc = roundFloat(vDesc * quantidade, 2);
            novosDadosProd.vlrTotAcre = 0;
          } else {
            novosDadosProd.percDesc = 0;
            novosDadosProd.vlrTotDesc = 0;
            novosDadosProd.vlrTotAcre = vlrItem;
          }
        } else if (recalcularPor === RECALCULAR_POR_VLR_TOT_PROD) {
          const vItem = quantidade > 0 ? vlrTotProd / quantidade : 0;

          novosDadosProd.vlrTotProd = vlrTotProd;
          novosDadosProd.vlrItem = roundFloat(vItem, qtdCasasDecimaisVlrs);

          const vlrTotBruto = roundFloat(vlrUnit * quantidade, 2);
          const vDesc = vlrTotBruto > 0 ? vlrTotBruto - vlrTotProd : 0;
          const pDesc =
            vlrTotBruto > 0 ? roundFloat((vDesc / vlrTotBruto) * 100, 2) : 0;

          if (vlrTotProd <= vlrTotBruto) {
            novosDadosProd.percDesc = roundFloat(pDesc, 2);
            novosDadosProd.vlrTotDesc = roundFloat(vDesc, 2);
            novosDadosProd.vlrTotAcre = 0;
          } else {
            novosDadosProd.percDesc = 0;
            novosDadosProd.vlrTotDesc = 0;
            novosDadosProd.vlrTotAcre = roundFloat(vlrTotProd - vlrTotBruto, 2);
          }
        }

        const [ok, ret] = await calcularImpostos(
          dadosProduto.idProduto,
          novosDadosProd.quantidade ?? quantidade,
          novosDadosProd.vlrItem ?? vlrItem,
          dadosProduto.cfop
        );

        if (!ok) return;

        novosDadosProd = { ...novosDadosProd, ...ret };

        handleChangeProduto(novosDadosProd);
      } finally {
        setRecalculandoValoresItens(false);
      }
    };

    const handleSubmit = () => {
      if (semProduto) {
        return showWarning(
          "Por favor, selecione um produto.",
          null,
          null,
          "input-produto"
        );
      }

      // Verifica se o produto já foi adicionado
      if (action === MODAL_ACTIONS.ADD) {
        const itemJaExiste = itens.find(
          (e) =>
            e.id_produto === dadosProduto.idProduto &&
            e.tipo === dadosProduto.tipo &&
            [null, undefined].includes(e.id_kit)
        );
        if (itemJaExiste) {
          return showWarning(
            "Esse item já existe no pedido. Altere o tipo ou, se necessário, " +
              "feche a janela de inclusão e altere o item " +
              "clicando no ícone ao final da linha correspondente na grade."
          );
        }
      }

      if (dadosProduto.quantidade <= 0) {
        return showWarning(
          "A quantidade não pode ser zero.",
          null,
          null,
          "input-qtd"
        );
      }

      if (dadosProduto.vlrItem < 0) {
        return showWarning(
          "Por favor, verifique o valor do item.",
          null,
          null,
          "input-valor"
        );
      }

      const payload = {
        tipo: dadosProduto.tipo,
        desc_tipo: DESCRICOES_TIPOS[dadosProduto.tipo],
        id_produto: dadosProduto.idProduto,
        nome_produto: dadosProduto.nomeProduto,
        revenda: dadosProduto.revenda,
        cfop: dadosProduto.cfop,
        quantidade: dadosProduto.quantidade,
        vlr_unit: dadosProduto.vlrUnit,
        vlr_item: dadosProduto.vlrItem,
        perc_desc: dadosProduto.percDesc,
        vlr_tot_desc: dadosProduto.vlrTotDesc,
        vlr_tot_acre: dadosProduto.vlrTotAcre,
        vlr_icms_st_unit: dadosProduto.vlrIcmsStUnit,
        vlr_tot_icms_st: dadosProduto.vlrTotIcmsSt,
        perc_ipi: dadosProduto.percIpi,
        vlr_tot_ipi: dadosProduto.vlrTotIpi,
        vlr_tot_prod: dadosProduto.vlrTotProd,
        vlr_total: dadosProduto.vlrTotal,
        vlr_tot_troca: dadosProduto.vlrTotTroca,
        vlr_tot_bonif: dadosProduto.vlrTotBonificacao,
        nro_ord_compra: dadosProduto.nroOrdemCompra ?? null,
        casas_decimais_vlrs: qtdCasasDecimaisVlrs,
        casas_decimais_qtd: qtdCasasDecimaisQtd,
        id_kit: null,
      };

      if (action === MODAL_ACTIONS.ADD) {
        handleAdd(payload);
      } else {
        handleEdit(payload);
      }

      toggle();
    };

    const atuValoresTrocaTipo = () => {
      if (semProduto) return;

      if (dadosProduto.tipo !== TIPO_ITEM_VENDA) {
        let novosDadosProd = {
          precoVendaProd: 0,
          precoAtacadoProd: 0,
          vlrItem: dadosProduto.vlrUnit,
          percDesc: 0,
          vlrTotDesc: 0,
          vlrTotAcre: 0,
          vlrIcmsStUnit: 0,
          vlrTotIcmsSt: 0,
          percIpi: 0,
          vlrTotIpi: 0,
          vlrTotProd: 0,
          vlrTotal: 0,
          vlrTotTroca: 0,
          vlrTotBonificacao: 0,
        };
        if (dadosProduto.tipo === TIPO_ITEM_TROCA) {
          novosDadosProd.vlrTotTroca =
            dadosProduto.vlrUnit * dadosProduto.quantidade;
        } else if (dadosProduto.tipo === TIPO_ITEM_BONIFICACAO) {
          novosDadosProd.vlrTotBonificacao =
            dadosProduto.vlrUnit * dadosProduto.quantidade;
        }
        handleChangeProduto(novosDadosProd);
      } else {
        // Força recalculo dos valores do item
        recalcularValoresItem(
          { quantidade: dadosProduto.quantidade },
          RECALCULAR_POR_QUANTIDADE
        );
      }
    };

    useEffect(atuValoresTrocaTipo, [dadosProduto.tipo]);

    const trocarPrecoEmUso = () => {
      const novosDadosProd = {};
      if (precoEmUso === PRECO_ATACADO) {
        novosDadosProd.vlrUnit = dadosProduto.precoAtacadoProd;
        novosDadosProd.vlrItem = dadosProduto.precoAtacadoProd;
      } else {
        novosDadosProd.vlrUnit = dadosProduto.precoVendaProd;
        novosDadosProd.vlrItem = dadosProduto.precoVendaProd;
      }

      handleChangeProduto(novosDadosProd);

      if (novosDadosProd.vlrItem) {
        recalcularValoresItem(novosDadosProd, RECALCULAR_POR_VLR_ITEM);
      }
    };

    useEffect(trocarPrecoEmUso, [precoEmUso]);

    useImperativeHandle(ref, () => ({
      toggle: toggle,
    }));

    const alterarPrecoPraticado = () => {
      setPrecoEmUso(precoEmUso === PRECO_VENDA ? PRECO_ATACADO : PRECO_VENDA);
    };

    return (
      <>
        <FormButton
          md="auto"
          divClassName="pr-0"
          color="info"
          padded={false}
          onClick={() => toggle(MODAL_ACTIONS.ADD)}
          disabled={[0, null, undefined].includes(idCliente)}
          disabledHint="Selecione o cliente do pedido"
        >
          Adicionar Item
        </FormButton>
        <ModalBase
          isOpen={isOpen}
          toggle={toggle}
          onClosed={limparDados}
          autoFocus={action === MODAL_ACTIONS.EDIT}
          size="lg"
          title={
            action === MODAL_ACTIONS.ADD ? "Adicionar Item" : "Alterar Item"
          }
          number="0034_41"
          numberStyle={{ marginRight: "3px" }}
          footerActions={
            <>
              {usaPrecoAtacado && (
                <>
                  <FixedField
                    label="Preço Praticado"
                    value={precoEmUso === PRECO_ATACADO ? "Atacado" : "Varejo"}
                    horizontal
                    divClassName="pr-2 mt-1"
                  />
                  <IconButton
                    icon={FaArrowRightArrowLeft}
                    hint={`Alterar Preço Praticado para ${
                      precoEmUso === PRECO_ATACADO ? "Varejo" : "Atacado"
                    }`}
                    className="mt-1 mr-auto"
                    onClick={alterarPrecoPraticado}
                  />
                </>
              )}
            </>
          }
          onConfirm={handleSubmit}
          disableOnConfirm={recalculandoValoresItens}
        >
          <Row>
            {action === MODAL_ACTIONS.ADD ? (
              <>
                <PesqProduto
                  md={8}
                  onConfirm={handleSelectProduto}
                  ref={refPesqProd}
                  selectNextField={selectNextField}
                  onChangeDescricao={(v) =>
                    handleChangeProduto({ nomeProduto: v })
                  }
                  mostrarAux={false}
                  idCliente={idCliente}
                  name={"input-produto"}
                />
                {!semProduto && (
                  <FixedField
                    label="Estoque"
                    value={formatNumber(dadosProduto.qtdEstoque, true, 2, true)}
                    divClassName="pt-4"
                    horizontal
                  />
                )}
              </>
            ) : (
              <FixedField
                md={8}
                label="Produto"
                value={formatValueFromAPI(
                  dadosProduto.nomeProduto,
                  dadosProduto.idProduto
                )}
                divClassName="mb-2"
                horizontal
                name={"input-produto"}
              />
            )}
            <FixedField
              md="auto"
              label="Tipo"
              value={DESCRICOES_TIPOS[dadosProduto.tipo]}
              divClassName={`mb-2 ${
                action === MODAL_ACTIONS.ADD ? "pt-4" : ""
              }`}
              horizontal
            />
            {action === MODAL_ACTIONS.ADD && (
              <ModalAlterarTipoItem
                tipo={dadosProduto.tipo}
                setTipo={(v) => handleChangeProduto({ tipo: v })}
              />
            )}
          </Row>
          <Row>
            <NumberInput
              md={2}
              label="Quantidade"
              value={dadosProduto.quantidade}
              onChange={(v) =>
                recalcularValoresItem(
                  { quantidade: v },
                  RECALCULAR_POR_QUANTIDADE
                )
              }
              disabled={semProduto}
              decimalPlaces={qtdCasasDecimaisQtd}
              ref={quantidadeRef}
              name={"input-qtd"}
            />
            <NumberInput
              md={2}
              label="Vlr. Unitário"
              value={dadosProduto.vlrUnit}
              disabled={
                !permiteManipularVlrUnitario ||
                semProduto ||
                dadosProduto.tipo !== "VDA"
              }
              decimalPlaces={qtdCasasDecimaisVlrs}
              onChange={(v) =>
                recalcularValoresItem({ vlrUnit: v }, RECALCULAR_POR_VLR_ITEM)
              }
            />
            <NumberInput
              md={2}
              label="Vlr. Item"
              value={dadosProduto.vlrItem}
              onChange={(v) =>
                recalcularValoresItem({ vlrItem: v }, RECALCULAR_POR_VLR_ITEM)
              }
              disabled={semProduto || dadosProduto.tipo !== "VDA"}
              decimalPlaces={qtdCasasDecimaisVlrs}
              name={"input-valor"}
            />
            <NumberInput
              md={2}
              label="Perc. Desconto"
              value={dadosProduto.percDesc}
              onChange={(v) =>
                recalcularValoresItem({ percDesc: v }, RECALCULAR_POR_PERC_DESC)
              }
              disabled={semProduto || dadosProduto.tipo !== "VDA"}
              isPercentage
            />
            <NumberInput
              md={2}
              label="Vlr. Desconto"
              value={dadosProduto.vlrTotDesc}
              disabled
            />
            <NumberInput
              md={2}
              label="Vlr. Acréscimo"
              value={dadosProduto.vlrTotAcre}
              disabled
            />
          </Row>
          <Row>
            <NumberInput
              md={2}
              label="Vlr. ICMS ST Unit."
              value={dadosProduto.vlrIcmsStUnit}
              disabled
            />
            <NumberInput
              md={2}
              label="Vlr. Tot. ICMS ST"
              value={dadosProduto.vlrIcmsStUnit}
              disabled
            />
            <NumberInput
              md={2}
              label="Perc. IPI"
              value={dadosProduto.percIpi}
              isPercentage
              disabled
            />
            <NumberInput
              md={2}
              label="Vlr. IPI"
              value={dadosProduto.vlrTotIpi}
              disabled
            />
            <NumberInput
              md={2}
              label="Vlr. Tot. Prod."
              value={dadosProduto.vlrTotProd}
              disabled
              decimalPlaces={4}
            />
            <NumberInput
              md={2}
              label="Vlr. Total"
              value={dadosProduto.vlrTotal}
              disabled
            />
          </Row>
          <Row>
            <NumberInput
              md={2}
              label="Vlr. Tot. Troca"
              value={dadosProduto.vlrTotTroca}
              disabled
              decimalPlaces={4}
            />
            <NumberInput
              md={2}
              label="Vlr. Tot Bonificação"
              value={dadosProduto.vlrTotBonificacao}
              disabled
            />
            {obrigInfoOrdemCompraNfe && (
              <TextInput
                md={2}
                label="Nro Ordem Compra"
                value={dadosProduto.nroOrdemCompra}
                onChange={(_, v) =>
                  handleChangeProduto({ nroOrdemCompra: v ?? null })
                }
                disabled={semProduto || dadosProduto.tipo !== "VDA"}
              />
            )}
          </Row>
        </ModalBase>
      </>
    );
  }
);
