import Axios from "axios";
import settings from "../../settings";
import { showError } from "../../components/AlertaModal";
import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/cadastro/ligacao_veic_cli";

const processaRetorno = (ret) => {
  if (ret.data.success) {
    return [true, ret.data.res];
  } else {
    showError(ret.data.msg, ret.data.id_err);
    return [false, ret.data];
  }
};

const processaErro = (err) => {
  showError(err.message, `SERVER_ERROR_${err.response?.status}`);
  return [false, { msg: err.message, id_err: err.response?.status }];
};

const CadastroLigacaoVeicCliService = {
  buscarVeiculosCliente: (idCliente) =>
    apiGetV2(`${URL_BASE}/listar_veiculos_cliente/${idCliente}/`),
  incluir: (payload) => apiPostV2(`${URL_BASE}/incluir/`, payload),
  alterar: (payload) =>
    apiPutV2(`${URL_BASE}/alterar/`, payload, { successMessage: false }),
  excluir: (idLigacao) => apiDeleteV2(`${URL_BASE}/excluir/${idLigacao}/`),
  alterarProprietarioVeic: (payload) =>
    apiPutV2(`${URL_BASE}/alterar_proprietario/`, payload),
  buscarClientePlaca: (placa) =>
    apiGetV2(`${URL_BASE}/buscar_cliente_por_placa/?placa=${placa}`, {}),
  consultaPlaca: (placa, cnpj, siglaUser) =>
    Axios.get(
      `${settings.API_PLACAS}/consultar_placa/${placa}/${cnpj}/${siglaUser}/`
    )
      .then((ret) => processaRetorno(ret))
      .catch((err) => processaErro(err)),
};

export default CadastroLigacaoVeicCliService;
