import React, { useState } from "react";
import { FixedField, ModalBase, Table } from "../../../../../components";
import { ProdServIcone } from "../../../../../components/ProdServIcone";
import {
  formatNumber,
  formatValueFromAPI,
  sumDataField,
} from "../../../../../coreUtils";
import { Row } from "reactstrap";
import OrcamentosService from "../../../../../services/orcamentos/OrcamentosService";

export const DetalhesOrcamentoModal = ({ isOpen, toggle, selected }) => {
  // Dados do Orçamento
  const [idCliente, setIdCliente] = useState(0);
  const [nomeCliente, setNomeCliente] = useState("");
  const [fone1Cliente, setFone1Cliente] = useState("");
  const [whatsCliente, setWhatsCliente] = useState("");
  const [vlrTotDesconto, setVlrTotDesconto] = useState(null);
  const [vlrTotal, setVlrTotal] = useState(null);
  const [obs, setObs] = useState(null);
  const [dataHoraOrc, setDataHoraOrc] = useState(null);
  const [nomeVendedor, setNomeVendedor] = useState(null);
  const [itens, setItens] = useState([]);

  // Parâmetros
  const [utilizaObservItem, setUtilizaObservItem] = useState(false);

  const carregarParametros = async () => {
    const [ok, ret] = await OrcamentosService.buscarParametros();

    if (ok) {
      setUtilizaObservItem(ret.utiliza_observ_item);
    }
    return ok;
  };

  const carregarDados = async () => {
    const [ok, ret] = await OrcamentosService.buscar(selected);
    if (ok) {
      setIdCliente(ret.id_cliente);
      setNomeCliente(ret.id_cliente__nome);
      setFone1Cliente(ret.id_cliente__fone1);
      setWhatsCliente(ret.id_cliente__whatsapp);
      setVlrTotDesconto(parseFloat(ret.vlr_desc ?? 0));
      setVlrTotal(parseFloat(ret.vlr_total));
      setDataHoraOrc(ret.data_hora_orc);
      setNomeVendedor(ret.nome_vendedor);
      setObs(ret.observ);
      setItens(ret.itens);
    }
    return ok;
  };

  const iniciarTela = async () => {
    if (!(await carregarParametros())) {
      toggle();
      return false;
    }

    if (!(await carregarDados())) {
      toggle();
      return false;
    }
  };

  const columns = [
    {
      dataField: "tipo",
      text: "T",
      align: "center",
      headerAlign: "center",
      colWidth: "30px",
      formatter: (c) => <ProdServIcone value={c} />,
    },
    {
      dataField: "id_item",
      text: "# Item",
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      dataField: "descricao",
      text: "Nome",
      align: "left",
      headerAlign: "left",
      colWidth: utilizaObservItem ? "120px" : "350px",
    },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    {
      dataField: "vlr_unit",
      text: "Vlr. Unit",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "observ",
      text: "Observação",
      align: "left",
      headerAlign: "left",
      colWidth: "280px",
      hidden: !utilizaObservItem,
    },
    {
      dataField: "vlr_item",
      text: "Vlr. Item",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "perc_desc",
      text: "Perc. Desc",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_tot_desc",
      text: "Vlr. Desc",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      headerAlign: "right",
      formatter: (v) => formatNumber(v, true, 2),
      footer: () => formatNumber(sumDataField(itens, "vlr_total"), true, 2),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      title={`Detalhes do Orçamento Nº ${selected}`}
      number="0083_2"
      onBeforeOpen={iniciarTela}
    >
      <Row className="mb-2">
        <FixedField
          horizontal
          label="Cliente"
          value={formatValueFromAPI(nomeCliente, idCliente)}
        />
        <FixedField horizontal label="Telefone" value={fone1Cliente} />
        <FixedField horizontal label="WhatsApp" value={whatsCliente} />
      </Row>
      <Row className="mb-2">
        <FixedField horizontal label="Data/Hora" value={dataHoraOrc} />
        <FixedField horizontal label="Vendedor" value={nomeVendedor} />
        <FixedField
          horizontal
          label="Desconto"
          value={formatNumber(vlrTotDesconto ?? 0, true, 2)}
        />
        <FixedField
          horizontal
          label="Total"
          value={formatNumber(vlrTotal ?? 0, true, 2)}
        />
      </Row>
      <Row className="mb-2">
        <FixedField horizontal label="Observação" value={obs} />
      </Row>
      <Table
        columns={columns}
        data={itens}
        showRegisterCount={false}
        paginated={false}
        pageSize={10}
      />
    </ModalBase>
  );
};
