import React, { useState } from "react";
import { Divider, ModalBase, Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";
import CredRotativoService from "../../../../../services/financeiro/CredRotativoService";

const movsColumns = [
  {
    dataField: "origem",
    text: "Origem",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "id_origem",
    text: "Nº Origem",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "historico",
    text: "Histórico",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "data_hora_mov",
    text: "Data/Hora",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "valor_mov",
    text: "Valor",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "status",
    text: "Status",
    align: "center",
    headerAlign: "center",
  },
];

const moedasColumns = [
  {
    dataField: "desc_moeda",
    text: "Descrição",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "historico",
    text: "Histórico",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "data_rec",
    text: "Data/Hora Rec.",
    align: "center",
    headerAlign: "center",
    formatter: (c, row) => `${c} ${row.hora_rec}`,
  },
  {
    dataField: "nome_operador",
    text: "Responsável",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "valor",
    text: "Valor",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const DetalhesLoteCredRotativoModal = ({ isOpen, toggle, lote }) => {
  const [dados, setDados] = useState({});

  const limparDados = () => {
    setDados({});
  };

  const buscarDetalhesLote = async () => {
    const [ok, ret] =
      await CredRotativoService.gerenciamento.buscarDetalhesLote(lote);
    if (ok) {
      setDados(ret);
    } else {
      setDados({});
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Detalhes do Lote do Crédito Rotativo"
      number="0068_51"
      onBeforeOpen={buscarDetalhesLote}
      onClosed={limparDados}
    >
      <Divider className="mt-0">Movimentos</Divider>
      <Table
        data={dados?.movs ?? []}
        columns={movsColumns}
        paginated={false}
        growIntoPageSize
        pageSize={10}
      />
      <Divider>Formas de Pagamento</Divider>
      <Table
        data={dados?.moedas_rec}
        columns={moedasColumns}
        paginated={false}
        growIntoPageSize
        pageSize={5}
      />
    </ModalBase>
  );
};
