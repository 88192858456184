import React from "react";
import { useState } from "react";
import {
  modalTitleTerceirizacao,
  routesBaseTerceirizacao,
} from "../Terceirizacao";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  NumberInput,
  TextInput,
} from "../../../../../components";
import { showWarning } from "../../../../../components/AlertaModal";

export const CadastroTerceirizacaoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [descricao, setDescricao] = useState("");
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [valor, setValor] = useState(0);

  const limparDados = () => {
    setAtivo(true);
    setDescricao("");
    setIdFornecedor(null);
    setValor(0);
  };

  const fetchData = (data) => {
    setDescricao(data.descricao);
    setIdFornecedor(data.id_fornecedor);
    setValor(parseFloat(data.valor));
    setAtivo(data.ativo);
  };

  const submitPayload = (action) => {
    if (["", null, undefined].includes(descricao)) {
      showWarning(
        "Por favor, informe a Descrição da Terceirização",
        null,
        null,
        "input-desc-terc"
      );
      return;
    }

    if ([0, null, undefined].includes(idFornecedor)) {
      showWarning(
        "Por favor, informe o Fornecedor",
        null,
        null,
        "async-id-fornec"
      );
      return;
    }

    const payload = {
      descricao: descricao,
      id_fornecedor: idFornecedor,
      valor: valor,
      ativo: ativo,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleTerceirizacao}
      size="sm"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseTerceirizacao}
      number="0109_1"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          md={12}
          label="Descrição"
          value={descricao}
          onChange={(e, v) => setDescricao(v)}
          maxLength={80}
          name={"input-desc-terc"}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={12}
          label="Fornecedor"
          isConcatField
          concatModelName="fornecedor"
          defaultValue={idFornecedor}
          onChange={(s) => setIdFornecedor(s?.value)}
          inputName={"async-id-fornec"}
        />
      </Row>
      <Row>
        <NumberInput md={6} label="Valor" value={valor} onChange={setValor} />
      </Row>
    </ModalCadastroV2>
  );
};
