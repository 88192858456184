import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  Divider,
  MaskedInput,
  TextInput,
  RadioGroup,
  FormCheckbox,
  FormButton,
} from "../../../../../components";
import {
  MODAL_ACTIONS,
  formatarCep,
  formatarCnpj,
  formatarFone,
  limparNumero,
  validarCNPJ,
} from "../../../../../coreUtils";
import {
  initialState,
  setAtivo,
  setBairro,
  setCep,
  setComplemento,
  setCpfCnpj,
  setEmail,
  setEndereco,
  setFone1,
  setFone2,
  setFone3,
  setFone4,
  setIdCentroCusto,
  setIdCidade,
  setIdCondPag,
  setIdentidade,
  setIdPlanoConta,
  setIdRamoAtividade,
  setInscEst,
  setNome,
  setNomeFant,
  setNumero,
  setObserv,
  setPessoa,
  setReferencia,
  setRepresEmail,
  setRepresFone1,
  setRepresFone2,
  setRepresFone3,
  setRepresNome,
  setSimplesNacional,
  setSite,
  setup,
} from "../store/cadastroFornecedoresSlice";
import CadastroCidadeService from "../../../../../services/cadastro/CadastroCidadeService";
import { RadioItem } from "../../../../../components/RadioGroup";
import { PreencAutoModal } from "../../../clientes/cliente/components/PreencAutoModal";
import { modalTitleFornecedor, routesBaseFornecedor } from "../Fornecedor";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { showWarning } from "../../../../../components/AlertaModal";
import { showConfirmation } from "../../../../../components/ConfirmationModal";
import CadastroClienteService from "../../../../../services/cadastro/CadastroClienteService";
import BotaoPesq from "../../../../../components/form/BotaoPesq";

const pessoa = [
  {
    label: "Física",
    value: "F",
  },
  {
    label: "Jurídica",
    value: "J",
  },
];

const INSC_EST_NAO_CONTRIB = "NAOCONTRIB";

const INSC_EST_ISENTO = "ISENTO";

export const CadastroFornecedorModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.cadastroFornecedores);
  const [identPlanoCtaCentCusto, setIdentPlanoCtaCentCusto] = useState();
  const [forObrigCpf, setForObrigCpf] = useState(false);
  const [forObrigCnpj, setForObrigCnpj] = useState(false);
  const [ieIsentoNaoCont, setIeIsentoNaoCont] = useState("NOR");
  const [loadingAtuDados, setLoadingAtuDados] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);

  const filtrarEstado = (initial) => {
    let state = initial ? { ...initialState } : { ...store };
    return state;
  };

  const limparDados = () => {
    dispatch(setup(filtrarEstado(true)));
  };

  const fetchData = (data) => {
    const res = {
      ativo: data.ativo,
      nome: data.nome,
      nome_fant: data.nome_fant,
      endereco: data.endereco,
      numero: data.numero,
      bairro: data.bairro,
      complemento: data.complemento,
      id_cidade: data.id_cidade,
      referencia: data.referencia,
      cep: data.cep,
      fone1: data.fone1,
      fone2: data.fone2,
      fone3: data.fone3,
      fone4: data.fone4,
      cpf_cnpj: data.cpf_cnpj,
      insc_est: data.insc_est,
      identidade: data.identidade,
      id_ramo_atividade: data.id_ramo_atividade,
      id_plano_conta: data.id_plano_conta,
      id_cond_pag: data.id_cond_pag,
      id_centro_custo: data.id_centro_custo,
      email: data.email,
      site: data.site,
      repres_nome: data.repres_nome,
      repres_fone1: data.repres_fone1,
      repres_fone2: data.repres_fone2,
      repres_fone3: data.repres_fone3,
      repres_email: data.repres_email,
      observ: data.observ,
      simples_nacional: data.simples_nacional ?? false,
    };

    dispatch(setPessoa(data.pessoa));
    dispatch(setup(res));
  };

  const handleSetIsentoNaoCont = (v) => {
    setIeIsentoNaoCont(v);
    dispatch(
      setInscEst(
        v === "ISE" ? INSC_EST_ISENTO : v === "NAO" ? INSC_EST_NAO_CONTRIB : ""
      )
    );
  };

  const carregarDadosPreencAuto = async (ret) => {
    dispatch(setup(filtrarEstado(true)));

    const estabelecimento = ret.estabelecimento;

    const nomeFant =
      estabelecimento.nome_fantasia?.trim().length > 0
        ? estabelecimento.nome_fantasia
        : ret.razao_social;

    const endereco = (
      (estabelecimento.tipo_logradouro ?? "") +
      " " +
      estabelecimento.logradouro
    )
      .trim()
      .toUpperCase();

    const obs = `SITUAÇÃO: ${estabelecimento.situacao_cadastral.toUpperCase()}`;

    const inscEstAtiva = estabelecimento.inscricoes_estaduais.find(
      (item) => item.ativo
    );
    let inscEstEstab = "";
    if (inscEstAtiva) {
      inscEstEstab = inscEstAtiva?.inscricao_estadual ?? "";
    }

    inscEstEstab = inscEstEstab.trim();

    if (inscEstEstab === "") {
      handleSetIsentoNaoCont("NAO");
      inscEstEstab = INSC_EST_NAO_CONTRIB;
    } else if (inscEstEstab === INSC_EST_ISENTO) {
      handleSetIsentoNaoCont("ISE");
    }

    const fone1 =
      (estabelecimento.ddd1?.trim() ?? "") +
      (estabelecimento.telefone1?.trim() ?? "");
    const fone2 =
      (estabelecimento.ddd2?.trim() ?? "") +
      (estabelecimento.telefone2?.trim() ?? "");
    const fax =
      (estabelecimento.ddd_fax?.trim() ?? "") +
      (estabelecimento.fax?.trim() ?? "");

    const res = {
      nome: ret.razao_social?.substring(0, 60).trim().toUpperCase(),
      nome_fant: nomeFant?.substring(0, 60).trim().toUpperCase(),
      endereco: endereco?.substring(0, 60).trim().toUpperCase(),
      numero: estabelecimento.numero?.substring(0, 10).trim().toUpperCase(),
      complemento: estabelecimento.complemento
        ?.substring(0, 40)
        .trim()
        .toUpperCase(),
      bairro: estabelecimento.bairro?.substring(0, 40).trim().toUpperCase(),
      cpf_cnpj: formatarCnpj(estabelecimento.cnpj?.substring(0, 18).trim()),
      insc_est: inscEstEstab?.substring(0, 20),
      cep: formatarCep(estabelecimento.cep?.substring(0, 10).trim()),
      fone1: formatarFone(fone1?.substring(0, 18).trim()),
      fone2: formatarFone(fone2?.substring(0, 18).trim()),
      fone3: formatarFone(fax?.substring(0, 18).trim()),
      email: estabelecimento.email?.substring(0, 80).trim(),
      observ: obs?.trim(),
      simples_nacional: (ret.simples?.simples ?? "").toLowerCase() === "sim",
    };

    dispatch(setPessoa("J"));

    const [ok, idCidade] = await CadastroCidadeService.verificarInserirCidade(
      estabelecimento.cidade.nome,
      estabelecimento.estado.sigla,
      estabelecimento.cidade.ibge_id
    );

    if (ok) {
      dispatch(setIdCidade(idCidade));
    }

    dispatch(setup(res));
  };

  const submitPayload = (action) => {
    const invalidArray = [0, null, undefined, ""];
    if (invalidArray.includes(store.nome)) {
      showWarning(
        "Por favor, informe a Razão Social.",
        null,
        null,
        "input-razao-social"
      );
      return false;
    }

    if (invalidArray.includes(store.id_cidade)) {
      showWarning("Por favor, informe a Cidade.", null, null, "async-cidade");
      return false;
    }

    const cpfCnpjFilter = store.cpf_cnpj.replace(/\D/g, "");
    if (store.pessoa === "F") {
      if (forObrigCpf && cpfCnpjFilter.length !== 11) {
        showWarning(
          "É necessário informar o CPF do Fornecedor.",
          null,
          null,
          "async-cpfcnpj"
        );
        return false;
      }
    } else {
      if (forObrigCnpj && cpfCnpjFilter.length !== 14) {
        showWarning(
          "É necessário informar o CNPJ do Fornecedor.",
          null,
          null,
          "async-cpfcnpj"
        );
        return false;
      }
    }

    const payloadData = {
      ativo: store.ativo,
      nome: store.nome ?? "",
      nome_fant: store.nome_fant ?? "",
      endereco: store.endereco ?? "",
      numero: store.numero ?? "",
      bairro: store.bairro ?? "",
      complemento: store.complemento ?? "",
      id_cidade: store.id_cidade ?? 0,
      referencia: store.referencia ?? "",
      cep: store.cep ?? "",
      fone1: store.fone1 ?? "",
      fone2: store.fone2 ?? "",
      fone3: store.fone3 ?? "",
      fone4: store.fone4 ?? "",
      pessoa: store.pessoa ?? "F",
      cpf_cnpj: store.cpf_cnpj ?? "",
      insc_est: store.insc_est ?? "",
      identidade: store.identidade ?? "",
      id_ramo_atividade: store.id_ramo_atividade ?? null,
      id_plano_conta: store.id_plano_conta ?? null,
      id_cond_pag: store.id_cond_pag ?? null,
      id_centro_custo: store.id_centro_custo ?? null,
      email: store.email ?? "",
      site: store.site ?? "",
      repres_nome: store.repres_nome ?? "",
      repres_fone1: store.repres_fone1 ?? "",
      repres_fone2: store.repres_fone2 ?? "",
      repres_fone3: store.repres_fone3 ?? "",
      repres_email: store.repres_email ?? "",
      observ: store.observ ?? "",
      simples_nacional: store.simples_nacional,
    };

    return action === MODAL_ACTIONS.ADD
      ? { importacao: false, id_cad_imp: null, ...payloadData }
      : { ...payloadData, id: selected };
  };

  const handleSetPessoa = (v) => {
    if (v === store.pessoa) return;
    dispatch(setPessoa(v));
  };

  const onOpen = (act, params) => {
    setIdentPlanoCtaCentCusto(params.identifica_plano_cta_c_custo_por);
    setForObrigCpf(params.for_obrig_cpf);
    setForObrigCnpj(params.for_obrig_cnpj);
  };

  const buscarCep = async () => {
    const cep = limparNumero(store.cep);

    if (cep.length < 8) return false;

    setLoadingCep(true);
    const ret = await CadastroClienteService.buscarCep(cep);

    if (Object.keys(ret).length > 0) {
      if (ret.logradouro) dispatch(setEndereco(ret.logradouro));

      if (ret.bairro) dispatch(setBairro(ret.bairro));

      const [ok, cidade] = await CadastroCidadeService.verificarInserirCidade(
        ret.localidade,
        ret.uf,
        ret.ibge
      );

      if (ok) {
        if (cidade) dispatch(setIdCidade(cidade));
      }
    }
    setLoadingCep(false);
  };

  const onKeyDown = (e) => {
    if (e.key === "F8") {
      const btPreencAuto = document.getElementById("bt-preenc-auto");
      if (btPreencAuto) {
        btPreencAuto.click();
      }
    }
  };

  const atualizarDadosFornecedor = async () => {
    setLoadingAtuDados(true);
    try {
      const ret = await CadastroClienteService.buscarDadosPreencAuto(
        store.cpf_cnpj
      );

      if (ret) {
        carregarDadosPreencAuto(ret);
      }
    } finally {
      setLoadingAtuDados(false);
    }
  };

  const toggleConfirmaAltDados = () =>
    showConfirmation(
      <>
        Atenção!
        <br />
        As informações do fornecedor {store.nome} serão alteradas com base na
        receita federal e estadual.
        <br />
        Deseja continuar?
      </>,
      atualizarDadosFornecedor
    );

  const semCnpj =
    !store.cpf_cnpj || store.pessoa === "F" || !validarCNPJ(store.cpf_cnpj);

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleFornecedor}
      size="lg"
      onOpen={onOpen}
      onClose={limparDados}
      fetchData={fetchData}
      paramsName="cad_fornecedor"
      submitPayload={submitPayload}
      headerCheck={{
        value: store.ativo,
        toggle: () => dispatch(setAtivo(!store.ativo)),
      }}
      routesBase={routesBaseFornecedor}
      number="0033_1"
      selected={selected}
      notifyEvent={notifyEvent}
      onKeyDown={onKeyDown}
      numberStyle={{ marginRight: 0 }}
      footerActions={
        action === MODAL_ACTIONS.EDIT && (
          <FormButton
            md="auto"
            padded={false}
            color="secondary"
            onClick={toggleConfirmaAltDados}
            disabled={semCnpj}
            loading={loadingAtuDados}
            disabledHint={
              "O Fornecedor deve ser Pessoa Jurídica e o CNPJ deve ser válido"
            }
            id="bt-preenc-auto"
            divClassName="mr-auto"
          >
            F8 - Atualizar Dados
          </FormButton>
        )
      }
    >
      {action === MODAL_ACTIONS.EDIT && (
        <Row>
          <TextInput
            name="id"
            label="Código"
            md={2}
            value={(selected ?? "").toString()}
            disabled
          />
        </Row>
      )}
      <Row>
        <TextInput
          label="Razão Social"
          md={action === MODAL_ACTIONS.ADD ? 8 : 12}
          value={store.nome}
          onChange={(e, v) => dispatch(setNome(v))}
          maxLength={60}
          name="input-razao-social"
        />
        {action === MODAL_ACTIONS.ADD && (
          <PreencAutoModal
            carregarDadosPreencAuto={carregarDadosPreencAuto}
            buttonPadded={true}
            buttonTabIndex={2}
          />
        )}
      </Row>
      <Row>
        <TextInput
          name="nome_fant"
          label="Nome Fantasia"
          md={12}
          value={store.nome_fant}
          onChange={(e, v) => dispatch(setNomeFant(v))}
          maxLength={80}
        />
      </Row>
      <Row>
        <TextInput
          name="endereco"
          label="Endereço"
          md={6}
          value={store.endereco}
          onChange={(e, v) => dispatch(setEndereco(v))}
          maxLength={60}
        />
        <TextInput
          name="num_end"
          label="Nro."
          md={2}
          value={store.numero}
          onChange={(e, v) => dispatch(setNumero(v))}
          maxLength={10}
        />
        <TextInput
          name="bairro"
          label="Bairro"
          md={4}
          value={store.bairro}
          onChange={(e, v) => dispatch(setBairro(v))}
          maxLength={45}
        />
      </Row>
      <Row>
        <TextInput
          name="compEnd"
          label="Complemento"
          md={6}
          value={store.complemento}
          onChange={(e, v) => dispatch(setComplemento(v))}
          maxLength={40}
        />
        <TextInput
          name="referencia"
          label="Referência"
          md={6}
          value={store.referencia}
          onChange={(e, v) => dispatch(setReferencia(v))}
          maxLength={30}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={4}
          isConcatField
          concatModelName="cidade"
          label="Cidade"
          isSearchable
          inputName={"async-cidade"}
          defaultValue={store.id_cidade}
          isClearable
          onChange={(s) => dispatch(setIdCidade(s?.value ?? 0))}
        />
        <MaskedInput
          mask="99999-999"
          name="cep"
          label="CEP"
          md={3}
          onChange={(e, v) => dispatch(setCep(v))}
          value={store.cep}
          additionalButton={
            <BotaoPesq
              onClick={buscarCep}
              loading={loadingCep}
              disabled={limparNumero(store.cep ?? "").length < 8}
              secondary
            />
          }
        />
      </Row>
      <Row>
        <TextInput
          name="fone1"
          label="Telefone 1"
          md={3}
          value={store.fone1}
          onChange={(e, v) => dispatch(setFone1(v))}
          maxLength={18}
        />
        <TextInput
          name="fone2"
          label="Telefone 2"
          md={3}
          value={store.fone2}
          onChange={(e, v) => dispatch(setFone2(v))}
          maxLength={18}
        />
        <TextInput
          name="fone3"
          label="Telefone 3"
          md={3}
          value={store.fone3}
          onChange={(e, v) => dispatch(setFone3(v))}
          maxLength={18}
        />
        <TextInput
          name="fone4"
          label="Telefone 4"
          md={3}
          value={store.fone4}
          onChange={(e, v) => dispatch(setFone4(v))}
          maxLength={18}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={4}
          defaultOptions={pessoa}
          isClearable={false}
          isConcatField={false}
          label="Pessoa"
          name="pessoa"
          defaultValue={store.pessoa}
          onChange={(s) => handleSetPessoa(s?.value)}
        />
        <MaskedInput
          mask={store.pessoa === "F" ? "999.999.999-99" : "99.999.999/9999-99"}
          inputName="async-cpfcnpj"
          label={store.pessoa === "F" ? "CPF" : "CNPJ"}
          md={4}
          value={store.cpf_cnpj}
          onChange={(e, v) => dispatch(setCpfCnpj(v))}
        />
        {store.pessoa === "F" && (
          <TextInput
            label="Identidade"
            md={3}
            onChange={(e, v) => dispatch(setIdentidade(v))}
            value={store.identidade}
            maxLength={15}
          />
        )}
      </Row>
      {store.pessoa !== "F" && (
        <Row>
          <RadioGroup
            label="Inscrição Estadual"
            value={ieIsentoNaoCont}
            onChange={handleSetIsentoNaoCont}
            divClassName="mb-2"
          >
            <RadioItem label="Contribuinte" value="NOR" />
            <RadioItem label="Não Contribuinte" value="NAO" />
            <RadioItem label="Isento" value="ISE" />
          </RadioGroup>
          <MaskedInput
            maskChar={null}
            mask="99999999999999"
            md={3}
            onChange={(e, v) => dispatch(setInscEst(v))}
            value={ieIsentoNaoCont === "NOR" ? store.insc_est : ""}
            disabled={ieIsentoNaoCont !== "NOR"}
          />
        </Row>
      )}
      <Row>
        <AsyncComboBox
          md={6}
          label="Ramo de Atividade"
          isConcatField
          concatModelName="ramo_atividade"
          defaultOptions
          isSearchable
          name="ramoAtiv"
          defaultValue={store.id_ramo_atividade}
          onChange={(s) => dispatch(setIdRamoAtividade(s?.value))}
        />
        <FormCheckbox
          label="Optante pelo Simples Nacional"
          checked={store.simples_nacional}
          onChange={() => dispatch(setSimplesNacional(!store.simples_nacional))}
        />
      </Row>
      <Row>
        <AsyncComboBox
          md={4}
          label="Plano de Contas"
          isConcatField
          concatModelName="plano_conta"
          defaultOptions
          isSearchable
          name="plano_contas"
          defaultValue={store.id_plano_conta}
          onChange={(s) => dispatch(setIdPlanoConta(s?.value))}
          disabled={identPlanoCtaCentCusto === "PARAM"}
        />
        <AsyncComboBox
          md={4}
          label="Centro de Custo"
          isConcatField
          concatModelName="centro_custo"
          defaultOptions
          isSearchable
          name="centro_custo"
          defaultValue={store.id_centro_custo}
          onChange={(s) => dispatch(setIdCentroCusto(s?.value))}
          disabled={identPlanoCtaCentCusto === "PARAM"}
        />
        <AsyncComboBox
          md={4}
          label="Condição de Pagamento"
          isConcatField
          concatModelName="cond_pag"
          defaultOptions
          isSearchable
          name="condPagto"
          defaultValue={store.id_cond_pag}
          onChange={(s) => dispatch(setIdCondPag(s?.value))}
        />
      </Row>
      <Row>
        <TextInput
          name="email"
          label="E-Mail"
          md={6}
          value={store.email}
          onChange={(e, v) => dispatch(setEmail(v))}
          maxLength={80}
          forceUppercase={false}
        />
        <TextInput
          name="site"
          label="Site"
          md={6}
          value={store.site}
          onChange={(e, v) => dispatch(setSite(v))}
          maxLength={60}
          forceUppercase={false}
        />
      </Row>
      <Row>
        <TextInput
          type="textarea"
          name="obs"
          label="Observação"
          md={12}
          rows={3}
          style={{ resize: 0 }}
          value={store.observ}
          onChange={(e, v) => dispatch(setObserv(v))}
        />
      </Row>
      <Divider>Informações do Representante</Divider>
      <Row>
        <TextInput
          name="nomeResp"
          label="Nome"
          md={12}
          value={store.repres_nome}
          onChange={(e, v) => dispatch(setRepresNome(v))}
          maxLength={80}
        />
      </Row>
      <Row>
        <TextInput
          name="foneResp1"
          label="Telefone 1"
          md={3}
          value={store.repres_fone1}
          onChange={(e, v) => dispatch(setRepresFone1(v))}
          maxLength={18}
        />
        <TextInput
          name="foneResp2"
          label="Telefone 2"
          md={3}
          maxLength={18}
          value={store.repres_fone2}
          onChange={(e, v) => dispatch(setRepresFone2(v))}
        />
        <TextInput
          name="foneResp3"
          label="Telefone 3"
          md={3}
          value={store.repres_fone3}
          onChange={(e, v) => dispatch(setRepresFone3(v))}
          maxLength={18}
        />
        <TextInput
          name="emailResp"
          label="E-Mail"
          md={4}
          value={store.repres_email}
          onChange={(e, v) => dispatch(setRepresEmail(v))}
          maxLength={80}
          forceUppercase={false}
        />
      </Row>
    </ModalCadastroV2>
  );
};
