import React from "react";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import { Table } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";

export const GerencBancGrid = ({
  dados,
  setSelected,
  alterar,
  excluir,
  tipoMov,
}) => {
  const columns = [
    {
      dataField: "origem",
      text: "Tipo",
      align: "left",
      sortable: true,
    },
    {
      dataField: "desc_mov",
      text: "Informação",
      align: "left",
      sortable: true,
    },
    {
      dataField: "historico",
      text: "Histórico",
      align: "left",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "data_mov",
      text: "Data",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "valor",
      text: "Entrada",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "valor",
      text: "Saída",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "valor",
      text: "Saldo",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    iconeAlterar(alterar, { disabled: (_, row) => row.origem !== "MAN" }),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      columns={columns}
      data={dados}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
      paginated={false}
      pageSize={12}
    />
  );
};
