import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ComboBox,
  Divider,
  FiltroPeriodoDatas,
  FixedField,
  PageContainer,
  TextInput,
} from "../../../../components";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import GerencBancService from "../../../../services/financeiro/GerencBancService";
import { GerencBancGrid } from "./components/GerencBancGrid";
import { formatNumber } from "../../../../coreUtils";
import { IncluirMovModal } from "./components/IncluirMovModal";
import { AlterarMovModal } from "./components/AlterarMovModal";
import { ExcluirMovModal } from "./components/ExcluirMovModal";
import { CompensacaoReceb } from "./components/compensacao_receb/CompensacaoReceb";
import { showWarning } from "../../../../components/AlertaModal";
import { TransfBancModal } from "./components/TransfBancModal";

const periodos = [
  { label: "Hoje", value: "HJ" },
  { label: "Mês Atual", value: "MAT" },
  { label: "Mês Anterior", value: "MANT" },
  { label: "3 Últimos Dias", value: "3D" },
  { label: "7 Últimos Dias", value: "7D" },
  { label: "15 Últimos Dias", value: "15D" },
  { label: "30 Últimos Dias", value: "30D" },
  { label: "45 Últimos Dias", value: "45D" },
  { label: "60 Últimos Dias", value: "60D" },
  { label: "Por Período", value: "PP" },
];

export const GerencBanc = () => {
  const [idConta, setIdConta] = useState(null);
  const [periodo, setPeriodo] = useState();
  const [dataIni, setDataIni] = useState(
    new Date(new Date().setDate(new Date().getDate()))
  );
  const [dataFim, setDataFim] = useState(
    new Date(new Date().setDate(new Date().getDate()))
  );
  const [idOrigem, setIdOrigem] = useState();
  const [saldo, setSaldo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);
  const [alterarOpen, setAlterarOpen] = useState(false);
  const [compensacaoOpen, setCompensacaoOpen] = useState(false);

  const [selected, setSelected] = useState([]);
  const [dados, setDados] = useState([]);

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);
  const toggleAlterar = () => setAlterarOpen(!alterarOpen);
  const toggleCompensacao = () => setCompensacaoOpen(!alterarOpen);

  const alterar = (id) => {
    setSelected(id);
    toggleAlterar();
  };

  const excluir = (id) => {
    setSelected(id);
    toggleExcluir();
  };

  const carregarDados = async () => {
    if ([0, null, undefined].includes(idConta)) {
      showWarning(
        "Por favor, informe uma conta bancária",
        "ValidFront001",
        [],
        "async-combo-conta"
      );
      return;
    }

    setLoading(true);
    try {
      const params = {
        id_conta_banc: idConta,
        data_ini: dataIni,
        data_fim: dataFim,
        id_origem: idOrigem,
      };
      const [ok, ret] = await GerencBancService.listar(params);
      if (ok) {
        setSelected([]);
        setDados(ret);
      }
      buscarSaldo();
    } finally {
      setLoading(false);
    }
  };

  const handleDateInput = (dIni, dFim) => {
    setDataIni(dIni);
    setDataFim(dFim);
  };

  const buscarSaldo = async () => {
    const [ok, ret] = await GerencBancService.buscar_saldo(idConta);
    if (ok) {
      setSaldo(ret.saldo_conta);
    }
  };

  return (
    <PageContainer title="Gerenciamento Bancário" number="0132">
      <Card body>
        <Row className="mb-2">
          <AsyncComboBox
            md={4}
            label="Conta"
            isConcatField
            concatModelName="conta_banc"
            isSearchable
            onChange={(s) => setIdConta(s?.value)}
            defaultOptions
            inputName="async-combo-conta"
          />
          <TextInput
            md={1}
            label={"N° de Origem"}
            onChange={(s) => setIdOrigem(s?.value)}
          />
          <BotaoPesquisar loading={loading} onClick={carregarDados} />
          <IncluirMovModal notifyEvent={carregarDados} />
          <FixedField
            label={"Saldo da Conta Selecionada"}
            value={formatNumber(saldo ?? 0)}
            padded
            divStyle={{ fontSize: "0.9rem", textAlign: "right" }}
            textStyle={saldo < 0 ? { color: "red" } : { color: "green" }}
            divClassName="ml-auto"
          />
        </Row>
        <Row>
          <ComboBox
            md={2}
            label={"Período"}
            options={periodos}
            value={periodo}
            defaultValue={"HJ"}
            onChange={(s) => setPeriodo(s?.value)}
          />
          {periodo === "PP" && (
            <FiltroPeriodoDatas onChange={handleDateInput} />
          )}
        </Row>
      </Card>
      <Card body>
        <GerencBancGrid
          dados={dados}
          setSelected={setSelected}
          alterar={alterar}
          excluir={excluir}
          tipoMov={dados.tipo}
        />
        <AlterarMovModal
          isOpen={alterarOpen}
          toggle={toggleAlterar}
          selected={selected}
        />
        <ExcluirMovModal
          isOpen={excluirOpen}
          toggle={toggleExcluir}
          selected={selected}
        />
      </Card>
      <Card body>
        <Row>
          <Col md={3}>
            <Col className="mb-2">
              <Row>
                <Col>
                  <CardTitle
                    className="mb-0"
                    tag="h6"
                    style={{ fontSize: "0.85rem" }}
                  >
                    Compensação Cheque Próprio
                  </CardTitle>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row>
                    <FixedField label={"Quantidade:"} md={6} value={""} />
                    <FixedField label={"Valor Total:"} md={6} value={""} />
                    <CompensacaoReceb
                      isOpen={compensacaoOpen}
                      toggle={toggleCompensacao}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Row>
                <Col>
                  <CardTitle
                    className="mb-0"
                    tag="h6"
                    style={{ fontSize: "0.85rem" }}
                  >
                    Compensação PIX
                  </CardTitle>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <Row>
                    <FixedField label={"Quantidade:"} md={6} value={""} />
                    <FixedField label={"Valor Total:"} md={6} value={""} />
                    <CompensacaoReceb
                      isOpen={compensacaoOpen}
                      toggle={toggleCompensacao}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Col>
          <Col md={3} className="no-gutters">
            <TransfBancModal notifyEvent={carregarDados} />
          </Col>
          <Col md={6} className="ml-auto">
            <Row>
              <Col>
                <CardTitle
                  className="mb-0"
                  tag="h6"
                  style={{ fontSize: "0.85rem" }}
                >
                  Saldos Disponíveis
                </CardTitle>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <FixedField
                  label={"Saldo em Conta Bancária - {NOME_BANCO} {NRO_CONTA}"}
                  value={11234}
                  md={12}
                  horizontal
                  textStyle={{ marginLeft: "auto" }}
                />
                <Divider />
                <FixedField
                  label={"Saldo em Conta Bancária - {NOME_BANCO} {NRO_CONTA}"}
                  value={11234}
                  md={12}
                  horizontal
                  textStyle={{ marginLeft: "auto" }}
                />
                <Divider />
                <FixedField
                  label={"Caixa Administrativo"}
                  value={11234}
                  md={12}
                  horizontal
                  textStyle={{ marginLeft: "auto" }}
                />
                <Divider />
                <FixedField
                  label={"Boletos a Receber - {NOME_BANCO} {NRO_CONTA}"}
                  value={11234}
                  md={12}
                  horizontal
                  textStyle={{ marginLeft: "auto" }}
                />
                <Divider />
                <FixedField
                  label={"Boletos a Receber - {NOME_BANCO} {NRO_CONTA}"}
                  value={11234}
                  md={12}
                  horizontal
                  textStyle={{ marginLeft: "auto" }}
                />
                <Divider />
                <FixedField
                  label={"Contas a Receber sem Boleto - Saldo em Aberto"}
                  value={11234}
                  md={12}
                  horizontal
                  textStyle={{ marginLeft: "auto" }}
                />
                <Divider />
                <FixedField
                  label={"Crédito Rotativo - Saldo em Aberto"}
                  value={11234}
                  md={12}
                  horizontal
                  textStyle={{ marginLeft: "auto" }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </PageContainer>
  );
};
