import React, { useState } from "react";
import {
  DatePicker,
  FixedField,
  FormButton,
  FormCheckbox,
  ModalBase,
  TextInput,
} from "../../../../components";
import {
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
import OrdemServicoService from "../../../../services/ordem_servico/OrdemServicoService";
import { formatDateISO } from "../../../../coreUtils";
import { toastr } from "react-redux-toastr";
import { CgChevronDown } from "react-icons/cg";
import { UncontrolledTooltip } from "reactstrap";

export const FinalizarOSModal = ({
  isOpen,
  toggle,
  selected,
  nomeClienteOS,
  notifyEvent,
}) => {
  const [reagendar, setReagendar] = useState(false);
  const [dataAgenda, setDataAgenda] = useState(null);
  const [obsReagenda, setObsReagenda] = useState("");
  const [nomeRet, setNomeRet] = useState("");
  const [obsFec, setObsFec] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setReagendar(false);
    setDataAgenda(null);
    setObsReagenda("");
    setNomeRet("");
    setObsFec("");
  };

  const toggleReagendar = () => {
    setReagendar(!reagendar);
    setDataAgenda(null);
    setObsReagenda("");
  };

  const handleSubmit = async () => {
    if (reagendar && !(dataAgenda instanceof Date)) {
      toastr.warning("Atenção", "Por favor, informe a Data de Reagendamento");
      return false;
    }
    setLoading(true);
    const data = {
      id_os: selected,
      data_fec: new Date().toISOString().split("T")[0],
      hora_fec: new Date().toLocaleString().split(" ")[1],
      reagendar: reagendar,
      data_reag: reagendar ? formatDateISO(dataAgenda) : "",
      obs_reagenda: obsReagenda,
      retirante: nomeRet,
      obs_fechamento: obsFec,
    };
    if (await OrdemServicoService.finalizar(data)) {
      if (notifyEvent) {
        notifyEvent();
      }
      toggle();
    }
    setLoading(false);
  };

  const DropdownOptions = ({ options, onChange, disabled, id }) => {
    return (
      <UncontrolledDropdown
        direction="down"
        onToggle={(e) => e.stopPropagation()}
        className="pr-2"
      >
        <DropdownToggle
          className="form-control"
          style={{
            background: "none",
            borderColor: "rgb(194 201 207)",
            marginTop: "1.2rem",
            cursor: "pointer",
            boxShadow: "none",
            paddingInline: "0.25rem",
            paddingTop: "0.2rem",
          }}
          disabled={disabled}
        >
          <CgChevronDown size={25} color="rgb(194 201 207)" />
        </DropdownToggle>
        <DropdownMenu>
          {options.map((e) => (
            <DropdownItem
              onClick={() => onChange(e.value)}
              disabled={e.isDisabled}
              style={{ color: e.isDisabled ? "silver" : "inherit" }}
            >
              {e.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const options = [
    {
      label: "15 Dias",
      value: "15D",
    },
    {
      label: "30 Dias",
      value: "30D",
    },
    {
      label: "3 Meses",
      value: "3M",
    },
    {
      label: "6 Meses",
      value: "6M",
    },
    {
      label: "1 Ano",
      value: "1A",
    },
  ];

  const handleSelect = (value) => {
    if (value === "15D") {
      setDataAgenda(new Date(new Date().setDate(new Date().getDate() + 15)));
    }
    if (value === "30D") {
      setDataAgenda(new Date(new Date().setMonth(new Date().getMonth() + 1)));
    }
    if (value === "3M") {
      setDataAgenda(new Date(new Date().setMonth(new Date().getMonth() + 3)));
    }
    if (value === "6M") {
      setDataAgenda(new Date(new Date().setMonth(new Date().getMonth() + 6)));
    }
    if (value === "1A") {
      setDataAgenda(
        new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      );
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Finalizar Ordem de Serviço"
      number="0006_3"
      headerStyle={{ backgroundColor: "#f5f9fc" }}
      bodyStyle={{ backgroundColor: "#f5f9fc" }}
      footerStyle={{ backgroundColor: "#f5f9fc" }}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      confirmButtonText="Finalizar"
      onClosed={limparDados}
    >
      <Card body>
        <Row>
          <FixedField label="N° O.S." value={selected} horizontal />
          <FixedField label="Cliente" value={nomeClienteOS} horizontal />
        </Row>
      </Card>
      <Card body>
        <Row>
          <FormCheckbox
            md="auto"
            label="Reagendar"
            name="reagendar"
            onChange={toggleReagendar}
            checked={reagendar}
          />
          <DatePicker
            md={2}
            label="Data"
            value={dataAgenda}
            onChange={(v) => {
              setDataAgenda(moment.isMoment(v) ? v.toDate() : v);
            }}
            disabled={!reagendar}
          />

          <div id="dropdown-reagendar">
            <DropdownOptions
              options={options}
              onChange={handleSelect}
              disabled={!reagendar}
            />
          </div>

          <UncontrolledTooltip target="dropdown-reagendar">
            Preenchimento automático da data de reagendamento
          </UncontrolledTooltip>
        </Row>
        <Row>
          <TextInput
            label="Obs. Reagendamento"
            // md={12}
            value={obsReagenda}
            onChange={(e, v) => setObsReagenda(v)}
            maxLength={100}
            disabled={!reagendar}
          />
        </Row>
      </Card>
      <Card body>
        <Row>
          <TextInput
            label="Nome do Retirante"
            md={12}
            value={nomeRet}
            onChange={(e, v) => setNomeRet(v)}
            maxLength={60}
          />
        </Row>
        <Row>
          <TextInput
            md={12}
            label="Obs. Fechamento"
            type="textarea"
            rows={3}
            value={obsFec}
            onChange={(e, v) => setObsFec(v)}
          />
        </Row>
      </Card>
    </ModalBase>
  );
};

export const FinalizarOSButton = ({ onClick, disabled, padded }) => (
  <FormButton
    md="auto"
    color="success"
    onClick={onClick}
    disabled={disabled}
    padded={padded}
  >
    Finalizar O.S.
  </FormButton>
);
