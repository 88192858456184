import React from "react";
import { IconButton, TableCheck, TableDelete } from "../../../../../components";
import { formatNumber, formatarValor } from "../../../../../coreUtils";
import { useSelector } from "react-redux";
import { Table } from "../../../../../components/Table";
import { UncontrolledTooltip } from "reactstrap";
import { Edit } from "react-feather";
import { ProdServIcone } from "../../../../../components/ProdServIcone";

export const ItensOSGrid = ({
  recalcularValoresGrade,
  atualizarDadosItem,
  removerItem,
  handleEditItemOs,
}) => {
  const store = useSelector((state) => state.itensOS);
  const params = useSelector((state) => state.paramsOS);

  const mecanica = params.tipoSist === "MECANICA";

  const columns = [
    {
      dataField: "tipo",
      text: "T",
      align: "left",
      headerAlign: "left",
      editable: false,
      colWidth: "30px",
      cellContentTag: null,
      formatter: (c) => <ProdServIcone value={c} />,
    },
    {
      dataField: "referencia",
      text: "Referência",
      align: "center",
      headerAlign: "center",
      editable: params.permiteManipularReferencia,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "text",
      alwaysShowEditor: true,
      colWidth: "75px",
    },
    {
      dataField: "id_item",
      text: "#",
      align: "center",
      headerAlign: "center",
      editable: false,
      colWidth: "60px",
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      editable: (_, row) => row.curinga || params.permiteAltNomeItemManutOs,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "text",
      colWidth: params.utilizaObservItem ? "120px" : "350px",
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_custo",
      text: "Custo",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
      editable: false,
      colWidth: "60px",
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
      headerAlign: "center",
      editable: false,
      colWidth: "30px",
    },
    {
      dataField: "vlr_unit",
      text: "V Unit.",
      align: "right",
      headerAlign: "right",
      editable: params.permiteManipularVlrUnitario,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: 4,
      formatter: (c) => formatarValor(c, 2),
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dataField: "observ",
      text: "Observação",
      align: "left",
      headerAlign: "left",
      editable: true,
      colWidth: "280px",
      editorType: "textarea",
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      borderless: true,
      hidden: !params.utilizaObservItem,
      editorForceUpperCase: false,
    },
    {
      dataField: "tecnico",
      text: "Técnico",
      align: "left",
      headerAlign: "left",
      editable: false,
      colWidth: "100px",
      hidden: !params.identificaTecnico,
      formatter: (c, row) => {
        if (row.tipo === "Serviço") {
          return (
            <>
              {c}{" "}
              <IconButton
                icon={Edit}
                size={15}
                onClick={() => handleEditItemOs(row.id, row.tipo)}
              />
            </>
          );
        } else {
          return c;
        }
      },
    },
    {
      dataField: "data_item",
      text: "Data Trab.",
      align: "center",
      headerAlign: "center",
      editable: false,
      colWidth: "100px",
      hidden: mecanica,
    },
    {
      dataField: "horas_servico",
      text: "Horas Serv.",
      align: "center",
      headerAlign: "center",
      editable: false,
      colWidth: "100px",
      hidden: !params.identificaTecnico,
    },
    {
      dataField: "garantia",
      text: "G",
      align: "center",
      headerAlign: "center",
      formatter: (c) => {
        if (c) {
          return <TableCheck value={c} />;
        } else {
          return <></>;
        }
      },
      editable: false,
      colWidth: "30px",
    },
    {
      dataField: "retrabalho",
      text: "RT",
      align: "center",
      headerAlign: "center",
      formatter: (c) => {
        if (c) {
          return <TableCheck value={c} />;
        } else {
          return <></>;
        }
      },
      editable: false,
      colWidth: "30px",
    },
    {
      dataField: "quantidade",
      text: "Qtde",
      align: "center",
      headerAlign: "center",
      editable: true,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: 4,
      formatter: (c) => formatarValor(c, 4, 0),
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_item",
      text: "Valor Item",
      align: "right",
      headerAlign: "right",
      editable: (c, row) => !row.garantia,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: 4,
      formatter: (c) => formatarValor(c, 4),
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dataField: "perc_desc",
      text: "% Desc.",
      align: "center",
      headerAlign: "center",
      editable: (c, row) => !row.garantia,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: 2,
      formatter: (c) => formatarValor(c, 2),
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_total",
      text: "Valor Total",
      align: "right",
      headerAlign: "right",
      editable: (c, row) =>
        (parseFloat(row.quantidade) ?? 0) > 0 && !row.garantia,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: 2,
      formatter: (c, row) => (
        <>
          <span id={`vlr_tot_${row.id}`}>{formatarValor(c, 2)}</span>
          <UncontrolledTooltip placement="left" target={`vlr_tot_${row.id}`}>
            Informe a quantidade do item para alterar este valor
          </UncontrolledTooltip>{" "}
        </>
      ),
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dummy: true,
      text: "",
      formatter: (_, row) => (
        <TableDelete onClick={() => removerItem(row.id, row.tipo)} />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      data={store.itens}
      paginated={false}
      growIntoPageSize
    />
  );
};
