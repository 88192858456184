import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { AiOutlineUser } from "react-icons/ai";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { BiPieChartAlt2, BiUserPlus, BiUserX } from "react-icons/bi";
import { BsFileEarmarkDiff } from "react-icons/bs";
import { BsBox, BsDoorClosed, BsListUl } from "react-icons/bs";
import { RiMoneyDollarBoxLine, RiPieChart2Line } from "react-icons/ri";
import { MdMoneyOff, MdStackedLineChart } from "react-icons/md";
import { HiOutlineSquares2X2 } from "react-icons/hi2";
import { GiTakeMyMoney } from "react-icons/gi";
import {
  relVendasClientesNaoVendidosRoute,
  relVendasComparativoGruposRoute,
  relVendasOsFormaRecebimentoNfRoute,
  relVendasParticipacaoVendasRoute,
  relVendaXCompraRoute,
  relVendasAbaixoMargLucroRoute,
  relVendasAgrupadasPorProdutoRoute,
  relVendasGeralRoute,
  relVendasPorGrupoRoute,
  relVendasPorVendedorRoute,
  relVendasClientesNovosRoute,
  relVendasVistasNaoVendaRoute,
  relVendasCustoVsPrecoVendaRoute,
  relProdutosCustoVsPrecoVendaRoute,
  relValorVendidoPorClienteRoute,
} from "../../../routes/modules/relatorios";

export const Vendas = () => {
  return (
    <PageContainer title="Relatórios de Vendas">
      <MenuGroup>
        <MenuGroupButton
          label="Geral"
          icon={BsListUl}
          pathname={relVendasGeralRoute.path}
          nroTela={relVendasGeralRoute.nroTela}
        />
        <MenuGroupButton
          label="Desempenho de Vendas por Meta de Lucro"
          icon={MdMoneyOff}
          pathname={relVendasAbaixoMargLucroRoute.path}
          nroTela={relVendasAbaixoMargLucroRoute.nroTela}
        />
        <MenuGroupButton
          label="Agrupado por Produto"
          icon={BsBox}
          pathname={relVendasAgrupadasPorProdutoRoute.path}
          nroTela={relVendasAgrupadasPorProdutoRoute.nroTela}
        />
        <MenuGroupButton
          label="Clientes Não Vendidos"
          icon={BiUserX}
          pathname={relVendasClientesNaoVendidosRoute.path}
          nroTela={relVendasClientesNaoVendidosRoute.nroTela}
        />
        <MenuGroupButton
          label="Clientes Novos"
          icon={BiUserPlus}
          pathname={relVendasClientesNovosRoute.path}
          nroTela={relVendasClientesNovosRoute.nroTela}
        />
        <MenuGroupButton
          label="Comparativo de Grupos"
          icon={BiPieChartAlt2}
          pathname={relVendasComparativoGruposRoute.path}
          nroTela={relVendasComparativoGruposRoute.nroTela}
        />
        <MenuGroupButton
          label="Participação de Vendas"
          icon={RiPieChart2Line}
          pathname={relVendasParticipacaoVendasRoute.path}
          nroTela={relVendasParticipacaoVendasRoute.nroTela}
        />
        <MenuGroupButton
          label="Produtos - Custo vs Preço Venda"
          icon={RiMoneyDollarBoxLine}
          pathname={relProdutosCustoVsPrecoVendaRoute.path}
          nroTela={relProdutosCustoVsPrecoVendaRoute.nroTela}
        />
        <MenuGroupButton
          label="Vendas - Custo vs Preço Venda"
          icon={BsFileEarmarkDiff}
          pathname={relVendasCustoVsPrecoVendaRoute.path}
          nroTela={relVendasCustoVsPrecoVendaRoute.nroTela}
        />
        <MenuGroupButton
          label="Vendas por Grupo"
          icon={HiOutlineSquares2X2}
          pathname={relVendasPorGrupoRoute.path}
          nroTela={relVendasPorGrupoRoute.nroTela}
        />
        <MenuGroupButton
          label="Vendas por Vendedor"
          icon={AiOutlineUser}
          pathname={relVendasPorVendedorRoute.path}
          nroTela={relVendasPorVendedorRoute.nroTela}
        />
        <MenuGroupButton
          label="Venda X Compra"
          icon={MdStackedLineChart}
          pathname={relVendaXCompraRoute.path}
          nroTela={relVendaXCompraRoute.nroTela}
        />
        <MenuGroupButton
          label="Visitas (Não-vendas)"
          icon={BsDoorClosed}
          pathname={relVendasVistasNaoVendaRoute.path}
          nroTela={relVendasVistasNaoVendaRoute.nroTela}
        />
        <MenuGroupButton
          label="Venda/O.S. - Forma de Recebimento e NF"
          icon={FaRegMoneyBillAlt}
          pathname={relVendasOsFormaRecebimentoNfRoute.path}
          nroTela={relVendasOsFormaRecebimentoNfRoute.nroTela}
        />
        <MenuGroupButton
          label="Valor Vendido por Cliente"
          icon={GiTakeMyMoney}
          pathname={relValorVendidoPorClienteRoute.path}
          nroTela={relValorVendidoPorClienteRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
