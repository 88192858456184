import React from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  FiltroPeriodoDatas,
  PageContainer,
} from "../../../../components";
import {
  formatDateISO,
  formatNumber,
  formatValueFromAPI,
} from "../../../../coreUtils";
import { apiGetV2 } from "../../../../apiV2";
import { docPrintReport } from "../../../../pdf";
import { DetalheVendaModal } from "../../../vendas/gerenciamento/gerenciamento_vendas/components/DetalheVendaModal";

export const ExtratoCreditoRotativo = () => {
  const [cliente, setCliente] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [saldos, setSaldos] = useState({});
  const [idVendaDet, setIdVendaDet] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [detalheVendaOpen, setDetalheVendaOpen] = useState(false);

  const limparDados = () => setDados([]);

  const buscarDados = async () =>
    await apiGetV2(`/relatorios/credito_rotativo/extrato_recebimentos/`, {
      id_cliente: cliente,
      data_ini: dataIni,
      data_fim: dataFim,
    });

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    setDados(ok ? ret.extrato : []);
    setSaldos(ok ? ret.saldos : {});
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      const payload = {
        extrato: ret.extrato,
        saldos: ret.saldos,
        data_ini: formatDateISO(dataIni),
        data_fim: formatDateISO(dataFim),
      };
      await docPrintReport(
        "/relatorios/financeiro_receber/extrato_credito_rotativo/",
        payload,
        "5034"
      );
    }
    setLoadingImprimir(false);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
    limparDados();
  };

  const toggleDetalheVenda = () => setDetalheVendaOpen(!detalheVendaOpen);

  const mostrarDetalhes = (id) => {
    setIdVendaDet(id);
    setTimeout(() => {
      toggleDetalheVenda();
    }, 1);
  };

  return (
    <PageContainer
      title="Extrato de Crédito Rotativo por Cliente"
      number="5034"
      canGoBack
    >
      <Card body>
        <Row>
          <AsyncComboBox
            isConcatField
            concatModelName="cliente"
            label="Cliente"
            md={5}
            onChange={(selected) => {
              setCliente(selected?.value);
              limparDados();
            }}
            isSearchable
            isClearable
          />
          <FiltroPeriodoDatas onChange={handleDate} />
        </Row>
        <Row>
          <BotaoPesquisar
            onClick={carregarDados}
            loading={loading}
            disabled={!cliente}
            disabledHint="Selecione um cliente"
          />
          <BotaoImprimir
            onClick={imprimir}
            loading={loadingImprimir}
            disabled={!cliente}
            disabledHint="Selecione um cliente"
          />
        </Row>
      </Card>
      {dados.length > 0 && (
        <>
          <CardTotais>
            <CardTotaisItem
              label="Crédito do Cliente"
              value={saldos?.vlr_credito}
            />
            <CardTotaisItem
              label="Limite de Crédito"
              value={saldos?.limite_cred}
            />
            <CardTotaisItem
              label="Total em Aberto"
              value={saldos?.vlr_tot_pend}
            />
            <CardTotaisItem
              label="Saldo de Crédito Disponível"
              value={saldos?.saldo_disponivel}
            />
          </CardTotais>

          <Card body>
            <DetalheVendaModal
              isOpen={detalheVendaOpen}
              toggle={toggleDetalheVenda}
              idVenda={idVendaDet}
            />
            {dados.map((lote, index) => (
              <p className="mb-0">
                <b>
                  Lote {lote.id_lote} - {lote.data} -{" "}
                  {formatValueFromAPI(lote.nome_cliente, lote.id_cliente)} -{" "}
                  {formatNumber(lote.valor, true, 2)}
                </b>
                <br />
                <div style={{ display: "flex" }}>
                  <div>
                    <span style={{ marginLeft: "0.5rem" }}>Origens:</span>
                    <table style={{ marginLeft: "1rem", width: "100%" }}>
                      <thead>
                        <tr>
                          <th>Tipo / Número</th>
                          <th style={{ textAlign: "center" }}>Emissão</th>
                          <th style={{ textAlign: "right" }}>Total</th>
                          <th style={{ textAlign: "right" }}>Faturado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lote.origens.map((origem) => (
                          <tr>
                            <td style={{ paddingRight: "2rem" }}>
                              {origem.descricao}{" "}
                              <span
                                style={
                                  origem.descricao === "Venda"
                                    ? {
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }
                                    : {}
                                }
                                onClick={() =>
                                  origem.descricao === "Venda" &&
                                  mostrarDetalhes(origem.id_origem)
                                }
                              >
                                {origem.id_origem}
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                paddingInline: "1rem",
                              }}
                            >
                              {origem.emissao}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                paddingLeft: "2rem",
                              }}
                            >
                              {formatNumber(origem.vlr_original, true, 2)}
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                paddingLeft: "2rem",
                              }}
                            >
                              {formatNumber(origem.vlr_faturado, true, 2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {/* <tfoot>
                        <tr style={{ textAlign: "right" }}>
                          <th />
                          <th />
                          <th />
                          <th>Total: {formatNumber("10", true, 2)}</th>
                        </tr>
                      </tfoot> */}
                    </table>
                  </div>
                  <div style={{ marginLeft: "4rem" }}>
                    <span style={{ marginLeft: "0.5rem" }}>Moedas:</span>
                    <table style={{ marginLeft: "1rem", width: "100%" }}>
                      <thead>
                        <tr>
                          <th style={{ paddingRight: "1rem" }}>Descrição</th>
                          <th
                            style={{
                              textAlign: "center",
                              paddingInline: "2rem",
                            }}
                          >
                            Compensação
                          </th>
                          <th
                            style={{ textAlign: "right", paddingLeft: "2rem" }}
                          >
                            Valor
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {lote.moedas.map((moeda) => (
                          <tr>
                            <td>{moeda.descricao}</td>
                            <td style={{ textAlign: "center" }}>
                              {moeda.data_comp}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {formatNumber(moeda.valor, true, 2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {/* <tfoot>
                        <tr style={{ textAlign: "right" }}>
                          <th />
                          <th />
                          <th>Total: {formatNumber("10", true, 2)}</th>
                        </tr>
                      </tfoot> */}
                    </table>
                  </div>
                </div>
                {index < dados.length - 1 && <hr />}
              </p>
            ))}
          </Card>
        </>
      )}
    </PageContainer>
  );
};
