import React, { forwardRef, useImperativeHandle } from "react";
import {
  AsyncComboBox,
  FormButton,
  IntegerFormInput,
  NumberInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import { useState } from "react";
import { useRef } from "react";
import { roundFloat } from "../../../../../coreUtils";
import { showWarning } from "../../../../../components/AlertaModal";

export const IncluirDetalheMetaForm = forwardRef(
  ({ movs, handleAdd, loadingIncluir }, ref) => {
    const [idTipo, setIdTipo] = useState(0);
    const [nomeTipo, setNomeTipo] = useState("");
    const [precoVendaProd, setPrecoVendaProd] = useState(0);
    const [metaQtdVenda, setMetaQtdVenda] = useState(0);
    const [metaVlrFaturado, setMetaVlrFaturado] = useState(0);
    const [metaPercMaxDevolTroca, setMetaPercMaxDevolTroca] = useState(0);
    const idTipoRef = useRef();

    const limparDados = () => {
      setIdTipo(0);
      setNomeTipo("");
      setPrecoVendaProd(0);
      setMetaQtdVenda(0);
      setMetaVlrFaturado(0);
      setMetaPercMaxDevolTroca(0);
      idTipoRef.current.clearValue();
    };

    const handleAddInternal = () => {
      const descTipo = "Produto";

      if ([0, null, undefined].includes(idTipo)) {
        showWarning(
          `Por favor, selecione o ${descTipo}`,
          null,
          null,
          "async-tipo-produto"
        );
        return false;
      }

      if (movs.some((e) => e.id_tipo === idTipo)) {
        showWarning(
          `O ${descTipo} informado já foi associado à Meta.`,
          null,
          null,
          "async-tipo-produto"
        );
        return false;
      }

      const payload = {
        tipo: "PRODUTO",
        desc_tipo: descTipo,
        id_tipo: idTipo,
        nome_tipo: nomeTipo,
        preco_tipo: precoVendaProd,
        meta_qtd_venda: metaQtdVenda,
        meta_vlr_faturado: metaVlrFaturado,
        meta_perc_max_devol_troca: metaPercMaxDevolTroca,
      };

      handleAdd(payload);
    };

    const handleSetIdTipo = (s) => {
      setIdTipo(s?.value ?? 0);
      setNomeTipo(s?.nome ?? s?.label ?? "");

      if (s) {
        setPrecoVendaProd(s?.preco_venda ?? 0);
        setMetaVlrFaturado(roundFloat(metaQtdVenda * s?.preco_venda ?? 0, 2));
      }
    };

    const handleSetMetaQtdVenda = (v) => {
      setMetaQtdVenda(v);

      setMetaVlrFaturado(roundFloat(v * precoVendaProd, 2));
    };

    useImperativeHandle(ref, () => ({
      limparDados: () => limparDados(),
    }));

    return (
      <>
        <Row className="mb-2">
          <AsyncComboBox
            isConcatField
            concatModelName={"produto"}
            isSearchable
            md={6}
            label={"Produto"}
            onChange={handleSetIdTipo}
            defaultValue={idTipo}
            ref={idTipoRef}
            hideShortcut
            inputName={"async-tipo-produto"}
          />

          <NumberInput
            md={2}
            label="Preço de Venda"
            value={precoVendaProd}
            disabled
          />
        </Row>
        <Row className="mb-2">
          <IntegerFormInput
            md={3}
            label="Qtd. de Venda"
            defaultValue={metaQtdVenda}
            onChange={handleSetMetaQtdVenda}
          />
          <NumberInput
            md={3}
            label="Vlr. de Venda da Meta"
            value={metaVlrFaturado}
            onChange={setMetaVlrFaturado}
            disabled
          />
          <NumberInput
            md={3}
            label="% Máx. Devol. Troca"
            value={metaPercMaxDevolTroca}
            onChange={setMetaPercMaxDevolTroca}
            isPercentage
          />
          <FormButton
            md="auto"
            color="info"
            onClick={handleAddInternal}
            loading={loadingIncluir}
          >
            Incluir
          </FormButton>
        </Row>
      </>
    );
  }
);
