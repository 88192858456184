import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, CustomInput, FormGroup, Label, Row, TabPane } from "reactstrap";
import { NumberInput, TextInput } from "../../../../../components";
import {
  setAmperagemDisjuntorGeral,
  setAmperagemDisjuntorMedicao,
  setBitolaCabo,
  setCondicaoEntrada,
  setNumeroTransformador,
  setTipoRamal,
} from "../store/vistoria_slice";

export const TabPadraoEntrada = () => {
  const vistoriaTecnica = useSelector((state) => state.vistoriaTecnica);
  const dispatch = useDispatch();
  return (
    <TabPane tabId="3" className="p-2">
      <Row>
        <NumberInput
          label="Disjuntor de Medição"
          md={2}
          placeholder="Amperes"
          onChange={(v) => dispatch(setAmperagemDisjuntorMedicao(v))}
          value={vistoriaTecnica.amperagemDisjuntorMedicao ?? 0.0}
        />
        <NumberInput
          label="Disjuntor Geral"
          md={2}
          placeholder="Amperes"
          onChange={(v) => dispatch(setAmperagemDisjuntorGeral(v))}
          value={vistoriaTecnica.amperagemDisjuntorGeral ?? 0.0}
        />
      </Row>
      <Row className="mb-3">
        <Col md="auto">
          <FormGroup
            onChange={({ target }) =>
              dispatch(setCondicaoEntrada(target?.value))
            }
          >
            <Label>Condição do Padrão de Entrada</Label>
            <div>
              <CustomInput
                type="radio"
                name="condicao_padrao_entrada"
                id="boa_padrao_entrada"
                label="Boa"
                value="BOA"
                checked={vistoriaTecnica.condicaoEntrada === "BOA"}
                inline
              />
              <CustomInput
                type="radio"
                name="condicao_padrao_entrada"
                id="adequacao_padrao_entrada"
                label="Adequação"
                value="ADEQ"
                checked={vistoriaTecnica.condicaoEntrada === "ADEQ"}
                inline
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <NumberInput
          label="Bitola do Cabo do Ramal de Entrada"
          md="auto"
          mdInput={5}
          placeholder="mm²"
          onChange={(v) => dispatch(setBitolaCabo(v))}
          value={vistoriaTecnica.bitolaCabo ?? 0.0}
        />
      </Row>
      <Row className="mb-3">
        <Col md="auto">
          <FormGroup
            onChange={({ target }) => dispatch(setTipoRamal(target?.value))}
          >
            <Label>Tipo do Ramal</Label>
            <div>
              <CustomInput
                type="radio"
                name="tipo_ramal_padrao_entrada"
                id="aereo_padrao_entrada"
                label="Aéreo"
                value="AERO"
                checked={vistoriaTecnica.tipoRamal === "AERO"}
                inline
              />
              <CustomInput
                type="radio"
                name="tipo_ramal_padrao_entrada"
                id="subterraneo_padrao_entrada"
                label="Subterrâneo"
                value="SUBT"
                checked={vistoriaTecnica.tipoRamal === "SUBT"}
                inline
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <TextInput
          label="Nº do Transformador"
          md="auto"
          onChange={({ target }) =>
            dispatch(setNumeroTransformador(target?.value))
          }
          value={vistoriaTecnica.numeroTransformador}
          maxLength={50}
        />
      </Row>
    </TabPane>
  );
};
