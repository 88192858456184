import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FormButton,
  LinkButton,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { MarketplacesIntegracoesGrid } from "./components/MarketplacesIntegracoesGrid";
import MarketplacesIntegracoesService from "../../../../services/vendas/MarktetplacesIntegracoesService";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { incluirLayoutMarketplacesRoute } from "../../../../routes/modules/vendas";
import { MaisProdutosModal } from "./components/MaisProdutosModal";
import { BotaoIncluir } from "../../../../components/cadastro";
import ImageViewer from "react-simple-image-viewer";
import { toastr } from "react-redux-toastr";

export const routesBaseMarketplace =
  "integracao/produto_env/adicionar_produtos_enviar";
export const modalTitleMarketplace = "Marketplaces Integrações";

export const MarketplacesIntegracoes = () => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [rowSelected, setRowSelected] = useState([]);
  const [lastRowSelected, setLastRowSelected] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [viewerOptions, setViewerOptions] = useState({
    viewerOpen: false,
    imgSrc: null,
  });

  const closeViewer = () =>
    setViewerOptions({
      imgSrc: null,
      viewerOpen: false,
    });

  const openViewer = (imgSrc) =>
    setViewerOptions({
      viewerOpen: true,
      imgSrc: imgSrc,
    });

  const [subgrupo, setSubgrupo] = useState();
  const [fabricante, setFabricante] = useState();
  const [grupo, setGrupo] = useState();

  const telasfinalizadas = false;

  const buscarDados = async () => {
    setLoading(true);
    try {
      const [ok, ret] = await MarketplacesIntegracoesService.listarEnviados({
        id_grupo: grupo,
        id_sub_grupo: subgrupo,
        id_fabricante: fabricante,
      });
      setDados(ok ? ret : []);
    } finally {
      setLoading(false);
    }
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  const handleSearch = async () => {
    setLoading(true);
    await carregarDados();
    setLoading(false);
  };

  const handleSelectAll = (isSelected) => {
    setRowSelected(isSelected ? dados.map((e) => e.id) : []);
  };

  const handleSetSelected = (v, isSelected, row) => {
    setRowSelected(
      isSelected ? [...rowSelected, v] : rowSelected.filter((e) => e !== v)
    );
    setLastRowSelected(isSelected ? v : null);
  };

  const toggle = (action) => {
    setAction(action);
    setIsModalOpen(!isModalOpen);
  };

  const excluir = async (id) => {
    setLoading(true);

    if (rowSelected.length === 0) {
      setLoading(false);
      toastr.warning("Atenção", "Nenhum produto selecionado.");
    } else {
      try {
        const payload = {
          produtos_remover: rowSelected.map((e) => ({ id_produto: e })),
        };

        const [ok] = await MarketplacesIntegracoesService.excluirProd(payload);

        if (ok) {
          carregarDados();
          setRowSelected([]);
          setLastRowSelected([]);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <PageContainer title="Marketplaces Integrações" number="0124">
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            label="Grupo"
            name="grupo"
            isConcatField
            concatModelName="grupo"
            isSearchable
            isClearable
            onChange={(s) => setGrupo(s?.value)}
          />
          <AsyncComboBox
            md={3}
            label="Sub-Grupo"
            name="subgrupo"
            isConcatField
            concatModelName="sub_grupo"
            isSearchable
            isClearable
            onChange={(s) => setSubgrupo(s?.value)}
          />
          <AsyncComboBox
            md={3}
            label="Fabricante"
            name="fabricante"
            isConcatField
            concatModelName="fabricante"
            isSearchable
            isClearable
            onChange={(s) => setFabricante(s?.value)}
          />
          <BotaoPesquisar onClick={handleSearch} loading={loading} />
        </Row>
      </Card>
      <Card body>
        <MarketplacesIntegracoesGrid
          dados={dados}
          excluir={excluir}
          selected={lastRowSelected}
          setSelected={handleSetSelected}
          onSelectAll={handleSelectAll}
          openViewer={openViewer}
        />
      </Card>
      <Card body>
        <Row>
          <FormButton padded={false} color="danger" onClick={excluir}>
            Remover do Marketplace
          </FormButton>
          <BotaoIncluir
            padded={false}
            color="primary"
            divClassName="ml-auto"
            toggle={toggle}
            text="+ Produtos"
          />
          <MaisProdutosModal
            isOpen={isModalOpen}
            toggle={toggle}
            action={action}
            selected={rowSelected}
            notifyEvent={carregarDados}
          />
          {telasfinalizadas && (
            <FormButton
              block={false}
              padded={false}
              color="primary"
              onClick={{}}
            >
              Integração Marketplace
            </FormButton>
          )}
          {telasfinalizadas && (
            <LinkButton
              pathname={incluirLayoutMarketplacesRoute.path}
              state={{ action: MODAL_ACTIONS.ADD }}
              padded={false}
            >
              Layout de Integração
            </LinkButton>
          )}
        </Row>
        {viewerOptions.viewerOpen && (
          <ImageViewer
            src={[viewerOptions.imgSrc]}
            disableScroll={true}
            closeOnClickOutside={true}
            onClose={closeViewer}
            backgroundStyle={{ backgroundColor: "#00000085" }}
          />
        )}
      </Card>
    </PageContainer>
  );
};
