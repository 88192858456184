import { STATUS_ALERTA } from "../../components/AlertaModal";
import * as types from "../constants";

const initialState = {
  isOpen: false,
  status: 2,
  msg: "",
  idErr: "",
  errorDetails: [],
  elementNameFocusAfterClose: null
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SHOW_ERROR:
      return {
        isOpen: true,
        status: STATUS_ALERTA.ERROR,
        msg: actions.msg,
        idErr: actions.idErr,
        errorDetails:
          actions.errorDetails instanceof Array ? actions.errorDetails : [],
        elementNameFocusAfterClose: actions.elementNameFocusAfterClose,
      };
    case types.SHOW_WARNING:
      return {
        isOpen: true,
        status: STATUS_ALERTA.WARNING,
        msg: actions.msg,
        idErr: actions.id_err ?? "",
        errorDetails:
          actions.errorDetails instanceof Array ? actions.errorDetails : [],
        elementNameFocusAfterClose: actions.elementNameFocusAfterClose,
      };
    case types.SHOW_INFO:
      return {
        isOpen: true,
        status: STATUS_ALERTA.INFO,
        msg: actions.msg,
        idErr: actions.id_err ?? "",
        errorDetails:
          actions.errorDetails instanceof Array ? actions.errorDetails : [],
        elementNameFocusAfterClose: actions.elementNameFocusAfterClose,
      };
    case types.SHOW_SUCCESS:
      return {
        isOpen: true,
        status: STATUS_ALERTA.SUCCESS,
        msg: actions.msg,
        idErr: "",
        errorDetails: [],
        elementNameFocusAfterClose: actions.elementNameFocusAfterClose,
      };
    case types.SHOW_LOADING:
      return {
        isOpen: true,
        status: STATUS_ALERTA.LOADING,
        msg: actions.msg,
        idErr: "",
        errorDetails: [],
        elementNameFocusAfterClose: actions.elementNameFocusAfterClose,
      };
    case types.CLOSE_ALERT:
      return {
        isOpen: false,
        status: "",
        msg: "",
        idErr: "",
        errorDetails: [],
        elementNameFocusAfterClose: null
      };
    default:
      return state;
  }
}
