import React from "react";
import { Table, TableCheck } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { naturalSort } from "../../../../../coreUtils";

export const TipoDocumentoGrid = ({
  data,
  setSelected,
  alterar,
  excluir,
  formaTributacao,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    { dataField: "nome", text: "Nome", align: "left", sortable: true },
    {
      dataField: "serie",
      text: "Série",
      align: "center",
      sortable: true,
    },
    {
      dataField: "desc_tipo_nf",
      text: "Tipo NFe",
      align: "center",
      sortable: true,
    },
    {
      dataField: "exige_info_fatura",
      text: "Ident. Faturas",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
      sortable: true,
    },
    {
      dataField: "desc_finalidade_nfe",
      text: "Finalidade NFe",
      align: "center",
      sortable: true,
    },
    {
      dataField: "desc_fin_operacao",
      text: "Finalidade Oper.",
      align: "center",
      sortable: true,
      hidden: formaTributacao !== "Regra_Tributacao",
    },
    {
      dataField: "desc_ind_presenca",
      text: "Ind. Presença",
      align: "center",
      sortable: true,
    },
    {
      dataField: "nf_importacao",
      text: "Importação",
      align: "center",
      formatter: (c) => (c ? <TableCheck value={c} /> : <></>),
      sortable: true,
    },
    {
      dataField: "ativo",
      text: "Ativo",
      align: "center",
      formatter: (cell) => <TableCheck value={cell} />,
      sortable: true,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
