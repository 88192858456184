import React, { useCallback, useEffect, useState } from "react";
import { Row } from "reactstrap";
import {
  Divider,
  ModalBase,
  NumberInput,
  TextInput,
} from "../../../../../components";
import { PrestacaoContasGrid } from "./PrestacaoContasGrid";
import ConferenciaValoresJornadaService from "../../../../../services/frente_venda_mobile/ConferenciaValoresJornadaService";
import ControleJornadaService from "../../../../../services/relatorios/jornada/ControleJornadaService";
import { BoletosRecebidosGrid } from "../confirmacao_conferencia/components/BoletosRecebidosGrid";
import { LogsGrid } from "./LogsGrid";

export const DetalhesDiaModal = ({ isOpen, toggle, idJornada }) => {
  const [despesasLancadas, setDespesasLancadas] = useState([]);
  const [dadosJornada, setDadosJornada] = useState({});

  const buscarPrestacoes = useCallback(async () => {
    if (idJornada !== null) {
      setDespesasLancadas(
        await ControleJornadaService.buscarPrestacoesJornDiaria(idJornada)
      );
      const [ok, ret] = await ConferenciaValoresJornadaService.buscarDadosDia(
        idJornada
      );
      if (ok) {
        setDadosJornada(ret);
      }
    }
  }, [idJornada]);

  useEffect(() => {
    if (isOpen) {
      buscarPrestacoes();
    }
  }, [isOpen, buscarPrestacoes]);

  useEffect(() => {
    if (!isOpen) {
      setDadosJornada({});
    }
  }, [isOpen]);

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      title={
        <h5 style={{ textTransform: "uppercase", fontWeight: "bold" }}>
          Detalhamento de Jornada Diária
        </h5>
      }
      autoFocus
      hideCancelButton
    >
      <Row>
        <TextInput
          md={4}
          label="Rota"
          value={dadosJornada.id_cab__id_rota_mob__nome}
          disabled
        />
        <TextInput md={4} label="Data" value={dadosJornada.data} disabled />
      </Row>
      <Row>
        <NumberInput
          md={4}
          label="Venda em Boleto do Dia"
          value={dadosJornada.vlr_tot_boleto}
          disabled
          autoFocus
        />
        <NumberInput
          md={4}
          label="Venda em Dinheiro do Dia"
          value={dadosJornada.vlr_din_venda}
          disabled
        />
        <NumberInput
          md={4}
          label="Bonificação do Dia"
          value={dadosJornada.vlr_bonif}
          disabled
        />
      </Row>
      {(dadosJornada.params?.permite_recebimento_pix ||
        dadosJornada.params?.permite_recebimento_cred_rotativo) && (
        <Row>
          {dadosJornada.params?.permite_recebimento_pix && (
            <NumberInput
              md={4}
              label="Venda em PIX do Dia"
              value={dadosJornada.vlr_tot_pix}
              disabled
              autoFocus
            />
          )}
          {dadosJornada.params?.permite_recebimento_cred_rotativo && (
            <NumberInput
              md={4}
              label="Venda em Rotativo do Dia"
              value={dadosJornada.vlr_tot_rotativo}
              disabled
              autoFocus
            />
          )}
        </Row>
      )}
      <Row>
        <NumberInput
          md={4}
          label="Comissão Bruta"
          value={dadosJornada.vlr_comis_bruto}
          disabled
        />
        <NumberInput
          md={4}
          label="Comissão Descontada"
          value={dadosJornada.vlr_comis_descontado}
          disabled
        />
        <NumberInput
          md={4}
          label="Comissão Sacada"
          value={dadosJornada.vlr_comis_sacado}
          disabled
        />
      </Row>
      <Row>
        <NumberInput
          md={4}
          label="Despesa"
          value={dadosJornada.vlr_din_despesa}
          disabled
        />
        <NumberInput
          md={4}
          label="Saldo da Semana"
          value={dadosJornada.id_cab__vlr_saldo_din_encerramento}
          disabled
        />
      </Row>
      <Row>
        <NumberInput
          md={4}
          label="Entregue no Malote"
          value={dadosJornada.vlr_din_malote}
          disabled
        />
        <NumberInput
          md={4}
          label="Quebra do Dia"
          value={dadosJornada.vlr_saldo_din_fechou_dia}
          disabled
        />
      </Row>
      <Row>
        <NumberInput
          md={4}
          label="Valor Conferido"
          disabled
          value={dadosJornada.vlr_conferido_adm}
        />
        <NumberInput
          md={4}
          label="Quebra de Conferência"
          disabled
          value={dadosJornada.vlr_dif_confer_adm}
        />
      </Row>
      <Row className="pb-2">
        <TextInput
          label="Observação"
          value={dadosJornada.observ_conf_adm}
          md={12}
          type="textarea"
          disabled
        />
      </Row>
      <Divider>Boletos Recebidos no Dia</Divider>
      <BoletosRecebidosGrid dados={dadosJornada.boletos} />
      <Divider>Despesas Lançadas no Dia</Divider>
      <PrestacaoContasGrid dados={despesasLancadas.despesas ?? []} />
      <Divider>Logs</Divider>
      <LogsGrid dados={dadosJornada.logs ?? []} />
      <span className="text-muted text-sm pt-4 pb-1">[0025_1]</span>
    </ModalBase>
  );
};
