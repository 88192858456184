import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Eye, EyeOff } from "react-feather";
import {
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Col,
} from "reactstrap";

export const PasswordInput = forwardRef(
  (
    {
      md,
      label = "Senha",
      value,
      onChange,
      placeholder,
      maxLength,
      disabled,
      name = "password",
    },
    ref
  ) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const inputRef = useRef();

    useImperativeHandle(ref, () => ({
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
    }));

    return (
      <Col md={md}>
        <FormGroup>
          <Label>{label}</Label>
          <InputGroup>
            <Input
              type={passwordVisible ? "text" : "password"}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              maxLength={maxLength}
              disabled={disabled}
              innerRef={inputRef}
            />
            {!disabled && (
              <InputGroupAddon addonType="append">
                <InputGroupText
                  style={{ cursor: "pointer", backgroundColor: "white" }}
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? <Eye size={15} /> : <EyeOff size={15} />}
                </InputGroupText>
              </InputGroupAddon>
            )}
          </InputGroup>
        </FormGroup>
      </Col>
    );
  }
);
